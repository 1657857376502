import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import InfoIcon from '../../images/info-icon-donation.png';
import '../../styles/donor-donation-match.css';
import '../../styles/fields.css';
import '../../styles/spacing.css';
import {
  useGetDonationsQuery,
  usePutDonationDonorMatchCompleteMutation,
  usePutDonationDonorMatchOneMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';
import { useCurrentDonorAccountUrl } from 'components/objectDraft/useCurrentDonorAccountUrl';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { SelectButton } from 'components/_shared/forms/SelectButton';
import { FINAL_DELIVERABLES, DONATION_YEAR_TYPES } from 'constants/donations';
import { Tooltip } from 'components/_shared/Tooltip';

const defaultValues = {
  representative: null,
  donorType: {
    individual: null,
    couple: null,
    institution: null,
  },
  creditLine: '',
  donorAddress: {
    lineOne: '',
    lineTwo: null,
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  donationYear: DONATION_YEAR_TYPES.currentYear,
  taxFiling: null,
  finalDeliverable: FINAL_DELIVERABLES.digital,
};

export default function DonorDonationMatch() {
  const { accountId, donationId } = useParams();

  const { data: pastDonations, isLoading: isLoadingDonations } =
    useGetDonationsQuery({
      account: accountId,
      active: true,
      exclude: donationId,
      hasEvent: 'acceptedComplete',
      sort: 'created:-1',
      limit: 1,
    });

  const { data: donation, isLoading: isLoadingDonation } = useDonorGetDonation({
    donationId,
    accountId,
  });
  const { data: object, isLoading: isLoadingObject } = useDonorGetObject({
    accountId,
    objectId: donation?.objectId,
  });

  const user = useSelector(state => state.userState.user);

  const formErrors = useSelector(
    state => state.donorDonationsMatchState.formErrorsOne,
  );

  const [
    putDonationDonorMatchOne,
    { isLoading: isLoadingPutMatch, isSuccess: isSuccessPutMatchThree },
  ] = usePutDonationDonorMatchOneMutation();

  const [
    putDonationDonorMatchComplete,
    {
      isLoading: isLoadingPutComplete,
      isSuccess: isSuccessPutComplete,
      data: putCompleteData,
    },
  ] = usePutDonationDonorMatchCompleteMutation();

  const url = useCurrentDonorAccountUrl();

  const [saved, setSaved] = useState(false);
  const [isRepresentative, setIsRepresentative] = useState(false);
  const [donorKind, setDonorKind] = useState('individual');

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitialized) return;
    if (!donation || !pastDonations || !user) return;

    const pastDonation =
      pastDonations?.donations.length > 0 ? pastDonations.donations[0] : null;

    // Donor/Representative
    // Representative
    if (donation?.donorInfo?.representative) {
      setValue(
        'representative.nameFirst',
        donation.donorInfo.representative?.nameFirst || '',
      );
      setValue(
        'representative.nameLast',
        donation.donorInfo.representative?.nameLast || '',
      );
      setIsRepresentative(true);
    } else if (pastDonation?.donorInfo?.representative) {
      setValue(
        'representative.nameFirst',
        pastDonation.donorInfo.representative?.nameFirst || '',
      );
      setValue(
        'representative.nameLast',
        pastDonation.donorInfo.representative?.nameLast || '',
      );
      setIsRepresentative(true);
    }

    // Donor Type / Name
    setDonorType({ donation, pastDonation, setDonorKind, setValue, user });

    // Donor Primary Address
    if (donation?.donorInfo?.donorAddress?.lineOne) {
      setValue('donorAddress.lineOne', donation.donorInfo.donorAddress.lineOne);
      setValue('donorAddress.lineTwo', donation.donorInfo.donorAddress.lineTwo);
      setValue('donorAddress.city', donation.donorInfo.donorAddress.city);
      setValue('donorAddress.state', donation.donorInfo.donorAddress.state);
      setValue('donorAddress.zip', donation.donorInfo.donorAddress.zip);
      setValue('donorAddress.country', donation.donorInfo.donorAddress.country);
    } else if (pastDonation?.donorInfo?.donorAddress?.lineOne) {
      setValue(
        'donorAddress.lineOne',
        pastDonation.donorInfo.donorAddress.lineOne,
      );
      setValue(
        'donorAddress.lineTwo',
        pastDonation.donorInfo.donorAddress.lineTwo,
      );
      setValue('donorAddress.city', pastDonation.donorInfo.donorAddress.city);
      setValue('donorAddress.state', pastDonation.donorInfo.donorAddress.state);
      setValue('donorAddress.zip', pastDonation.donorInfo.donorAddress.zip);
      setValue(
        'donorAddress.country',
        pastDonation.donorInfo.donorAddress.country,
      );
    }

    // Credit Line
    if (donation?.donorInfo?.creditLine) {
      setValue('creditLine', donation.donorInfo.creditLine);
    } else if (pastDonation?.donorInfo?.creditLine) {
      setValue('creditLine', pastDonation.donorInfo.creditLine);
    }

    // Donation Year
    if (donation?.donorInfo?.donationYear?.type) {
      setValue('donationYear', donation.donorInfo.donationYear.type);
    } else if (pastDonation?.donorInfo?.donationYear?.type) {
      setValue('donationYear', pastDonation.donorInfo.donationYear.type);
    }

    // TaxFiling
    if (donation?.donorInfo?.taxFiling !== undefined) {
      setValue(
        'taxFiling',
        donation.donorInfo?.taxFiling === null
          ? ''
          : donation.donorInfo?.taxFiling === true
          ? 'true'
          : 'false',
      );
    } else if (pastDonation?.donorInfo?.taxFiling !== undefined) {
      setValue(
        'taxFiling',
        pastDonation.donorInfo?.taxFiling === null
          ? ''
          : pastDonation.donorInfo?.taxFiling === true
          ? 'true'
          : 'false',
      );
    }

    // Final Deliverable
    if (donation?.donorInfo?.finalDeliverable) {
      setValue('finalDeliverable', donation.donorInfo.finalDeliverable);
    } else if (pastDonation?.donorInfo?.finalDeliverable) {
      setValue('finalDeliverable', pastDonation.donorInfo.finalDeliverable);
    }

    setIsInitialized(true);
  }, [donation, pastDonations, user, isInitialized]);

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues,
  });

  const { errors, isSubmitting } = formState;

  const donorNameFirst = watch('donorType.individual.nameFirst');
  const donorNameLast = watch('donorType.individual.nameLast');
  const representativeNameFirst = watch('representative.nameFirst');
  const representativeNameLast = watch('representative.nameLast');
  const donationYear = watch('donationYear');
  const taxFiling = watch('taxFiling');
  const finalDeliverable = watch('finalDeliverable');

  const isLoading = isLoadingDonation || isLoadingObject;

  async function onSave(values) {
    if (isSubmitting) return;
    if (isLoadingPutMatch) return;

    try {
      await putDonationDonorMatchOne({
        donationId: donation.id,
        data: values,
      }).unwrap();

      setSaved(true);
    } catch (e) {
      console.error(e);
    }
  }

  async function onSubmit(values) {
    if (isSubmitting) return;
    if (isLoadingPutMatch) return;
    if (isLoadingPutComplete) return;

    try {
      await putDonationDonorMatchOne({
        donationId: donation.id,
        data: values,
      }).unwrap();
      await putDonationDonorMatchComplete({ donationId: donationId }).unwrap();
    } catch (e) {
      console.error(e);
    }
  }

  if (saved) {
    return <Redirect push to={`/donor/folders/${accountId}`} />;
  }
  if (isSuccessPutComplete) {
    return (
      <Redirect
        push
        to={`/donor/folders/${accountId}/donation/${donationId}`}
      />
    );
  }

  if (isLoading) return null;

  if (donation && object) {
    return (
      <div className="gen-background-nav-donor">
        <div className="ddm-header mx-auto d-flex">
          <Link to={url}>
            <img
              src={BackIcon}
              alt="back-arrow"
              className="ddm-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="ddm-header-back-container-content">
            <p className="ddm-header-title">Donor Information</p>
            <div className="ddm-header-text-container">
              <p className="ddm-header-text">
                Please complete this form to begin the donation process
              </p>
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="ddm-form-content mx-auto"
        >
          <div className="ddm-form-content-inner mx-auto">
            <div className="ddm-form-section">
              <p className="ddm-form-section-title tw-mb-4">
                Donor/Representative
              </p>
              <div className="tw-grid tw-gap-5">
                <SelectButton
                  onSelect={_ => {
                    if (representativeNameFirst) {
                      setValue(
                        'donorType.individual.nameFirst',
                        representativeNameFirst,
                      );
                    }
                    if (representativeNameLast) {
                      setValue(
                        'donorType.individual.nameLast',
                        representativeNameLast,
                      );
                    }
                    setIsRepresentative(false);
                  }}
                  text="I am the donor"
                  isActive={!isRepresentative}
                />
                <SelectButton
                  onSelect={_ => {
                    setIsRepresentative(true);

                    if (!representativeNameFirst && !representativeNameLast) {
                      if (donorNameFirst) {
                        setValue('representative.nameFirst', donorNameFirst);
                      }
                      if (donorNameLast) {
                        setValue('representative.nameLast', donorNameLast);
                      }

                      if (donorNameFirst) {
                        setValue('donorType.individual.nameFirst', '');
                      }

                      if (donorNameLast) {
                        setValue('donorType.individual.nameLast', '');
                      }
                    }
                  }}
                  isActive={isRepresentative}
                  text="I am a representative of the donor"
                />
              </div>
              {isRepresentative ? (
                <div
                  className={`spc-mt-17 ${isRepresentative ? '' : 'tw-hidden'}`}
                >
                  <p
                    className={`ddm-field-label ${
                      errors?.representative?.nameFirst ||
                      formErrors.representativeNameFirstError
                        ? 'fd-input-error-txt'
                        : ''
                    }`}
                  >
                    Representative Name
                  </p>
                  <div className="d-flex justify-content-between">
                    <input
                      type="text"
                      className={`fd-input ddm-field-narrow ${
                        errors?.representative?.nameFirst
                          ? 'fd-input-error-field'
                          : ''
                      }`}
                      {...register('representative.nameFirst', {
                        maxLength: 100,
                        required: true,
                        shouldUnregister: true,
                      })}
                      maxLength="100"
                      placeholder="First Name"
                    ></input>
                    <input
                      type="text"
                      className={`fd-input ddm-field-narrow ${
                        formErrors.representativeNameLastError
                          ? 'fd-input-error-field'
                          : ''
                      }`}
                      {...register('representative.nameLast', {
                        maxLength: 100,
                        required: true,
                        shouldUnregister: true,
                      })}
                      maxLength="100"
                      placeholder="Last Name"
                    ></input>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="ddm-form-section">
              <div className="tw-flex">
                <p className="ddm-form-section-title tw-mb-4">
                  Donor Type / Name
                </p>
                <Tooltip content="Please select the appropriate donor type and then enter the donor name in the subsequent field. The donor name will be used for all official documents.">
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-donor-address"
                  />
                </Tooltip>
              </div>
              <div className="tw-grid tw-gap-5">
                <SelectButton
                  onSelect={_ => {
                    setDonorKind('individual');
                    // setValue('donorType.couple', null);
                    // setValue('donorType.institution', null);
                  }}
                  text="Individual"
                  isActive={donorKind === 'individual'}
                />
                <SelectButton
                  onSelect={_ => {
                    setDonorKind('couple');
                    // setValue('donorType.individual', null);
                    // setValue('donorType.institution', null);
                  }}
                  text="Couple"
                  isActive={donorKind === 'couple'}
                />
                <SelectButton
                  onSelect={_ => {
                    setDonorKind('institution');
                    // setValue('donorType.individual', null);
                    // setValue('donorType.couple', null);
                  }}
                  text="Institution/Entity"
                  isActive={donorKind === 'institution'}
                />
              </div>
              {donorKind === 'individual' ? (
                <div className="spc-mt-30">
                  <p
                    className={`ddm-field-label ${
                      formErrors.donorNameFirstError ||
                      formErrors.donorNameLastError
                        ? 'fd-input-error-txt'
                        : ''
                    }`}
                  >
                    Donor Name
                  </p>
                  <div className="d-flex justify-content-between">
                    <input
                      type="text"
                      className={`fd-input ddm-field-narrow ${
                        formErrors.donorNameFirstError
                          ? 'fd-input-error-field'
                          : ''
                      }`}
                      {...register('donorType.individual.nameFirst', {
                        maxLength: 100,
                        required: true,
                        shouldUnregister: true,
                      })}
                      maxLength="100"
                      placeholder="First Name"
                    ></input>
                    <input
                      type="text"
                      className={`fd-input ddm-field-narrow ${
                        formErrors.donorNameLastError
                          ? 'fd-input-error-field'
                          : ''
                      }`}
                      {...register('donorType.individual.nameLast', {
                        maxLength: 100,
                        required: true,
                        shouldUnregister: true,
                      })}
                      maxLength="100"
                      placeholder="Last Name"
                    ></input>
                  </div>
                </div>
              ) : null}

              {donorKind === 'couple' ? (
                <div className="spc-mt-30">
                  <p
                    className={`ddm-field-label ${
                      formErrors.donorNameFirstError ||
                      formErrors.donorNameLastError
                        ? 'fd-input-error-txt'
                        : ''
                    }`}
                  >
                    Donor Names
                  </p>
                  <p className="tw-mb-2 tw-text-xs tw-text-gray-400">
                    List the name of both spouses (i.e. John and Jane Doe, John
                    Smith and Jane Doe).
                  </p>
                  <div className="d-flex justify-content-between">
                    <input
                      type="text"
                      className={`fd-input ddm-field ${
                        formErrors.donorNameFirstError
                          ? 'fd-input-error-field'
                          : ''
                      }`}
                      {...register('donorType.couple', {
                        maxLength: 100,
                        required: true,
                        shouldUnregister: true,
                      })}
                      maxLength="100"
                      placeholder="Donor Names"
                    />
                  </div>
                </div>
              ) : null}
              {donorKind === 'institution' ? (
                <div className="spc-mt-30">
                  <p
                    className={`ddm-field-label ${
                      formErrors.donorNameFirstError ||
                      formErrors.donorNameLastError
                        ? 'fd-input-error-txt'
                        : ''
                    }`}
                  >
                    Institution/Entity Name
                  </p>
                  <p className="tw-mb-2 tw-text-xs tw-text-gray-400">
                    List the name used on official documents.
                  </p>
                  <div className="d-flex justify-content-between">
                    <input
                      type="text"
                      className={`fd-input ddm-field ${
                        formErrors.donorNameFirstError
                          ? 'fd-input-error-field'
                          : ''
                      }`}
                      {...register('donorType.institution', {
                        maxLength: 100,
                        required: true,
                        shouldUnregister: true,
                      })}
                      maxLength="100"
                      placeholder="Institution/Entity Name"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15 d-flex">
                <p className="ddm-form-section-title">Donor Primary Address</p>
                <Tooltip content="This address will be used for all official documents.">
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-donor-address"
                  />
                </Tooltip>
              </div>
              <div className="spc-mb-15">
                <p
                  className={`ddm-field-label ${
                    errors?.lineOne ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Address Line 1
                </p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full ${
                    errors?.lineOne ? 'fd-input-error-field' : ''
                  }`}
                  {...register('donorAddress.lineOne', {
                    maxLength: 150,
                    required: true,
                  })}
                  maxLength="150"
                  placeholder="Address Line 1"
                ></input>
              </div>
              <div className="spc-mb-15">
                <p className={`ddm-field-label`}>Address Line 2</p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full`}
                  {...register('donorAddress.lineTwo', { maxLength: 150 })}
                  maxLength="150"
                  placeholder="Address Line 2"
                ></input>
              </div>
              <div className="spc-mb-15 d-flex justify-content-between">
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.cityError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    City
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.cityError ? 'fd-input-error-field' : ''
                    }`}
                    {...register('donorAddress.city', {
                      maxLength: 100,
                      required: true,
                    })}
                    maxLength="100"
                    placeholder="City"
                  ></input>
                </div>
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.stateError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    State / Province
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.stateError ? 'fd-input-error-field' : ''
                    }`}
                    {...register('donorAddress.state', {
                      minLength: 2,
                      maxLength: 2,
                      required: true,
                    })}
                    maxLength="2"
                    placeholder="State / Province"
                  ></input>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.zipError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Zip / Postal Code
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.zipError ? 'fd-input-error-field' : ''
                    }`}
                    {...register('donorAddress.zip', {
                      maxLength: 7,
                      required: true,
                    })}
                    maxLength="7"
                    placeholder="Zip / Postal Code"
                  ></input>
                </div>
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.countryError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Country
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.countryError ? 'fd-input-error-field' : ''
                    }`}
                    {...register('donorAddress.country', {
                      maxLength: 100,
                      required: true,
                    })}
                    maxLength="100"
                    placeholder="Country"
                  ></input>
                </div>
              </div>
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15">
                <div className="d-flex">
                  <p
                    className={`ddm-form-section-title ${
                      formErrors.creditLineError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Credit Line
                  </p>
                  <Tooltip
                    content="The credit line will appear as labels and captions in
                      exhibitions, publications, and publicity materials (i.e.
                      Gift of John and Jane Doe, Gift of Mr. and Mrs. Doe, Gift
                      of the Doe Family, Gift of Jane Doe in memory of her
                      husband John Doe, Gift in memory of John Doe, Anonymous
                      gift)."
                  >
                    <img
                      src={InfoIcon}
                      alt="info-icon"
                      className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-credit"
                    />
                  </Tooltip>
                  {/* <div className="ddm-hover-element ddm-hover-element-credit">
                    <p className="ddm-hover-element-text">
                      The credit line will appear as labels and captions in
                      exhibitions, publications, and publicity materials (i.e.
                      Gift of John and Jane Doe, Gift of Mr. and Mrs. Doe, Gift
                      of the Doe Family, Gift of Jane Doe in memory of her
                      husband John Doe, Gift in memory of John Doe, Anonymous
                      gift).
                    </p>
                  </div> */}
                </div>
              </div>
              <input
                type="text"
                className={`ddm-field-full fd-input ${
                  formErrors.creditLineError ? 'fd-input-error-field' : ''
                }`}
                {...register('creditLine', { maxLength: 200, required: true })}
                maxLength="200"
                placeholder="Preferred credit line"
              ></input>
            </div>
            <div className="ddm-form-section">
              <div className="tw-flex">
                <p className="ddm-form-section-title tw-mb-4">Donation Year</p>
                <Tooltip content="Please select the desired calendar year for your donation.">
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-donor-address"
                  />
                </Tooltip>
              </div>
              <div className="tw-grid tw-gap-5">
                <input
                  type="hidden"
                  {...register('donationYear', { required: true })}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('donationYear', DONATION_YEAR_TYPES.currentYear);
                  }}
                  text="Current year"
                  isActive={donationYear === DONATION_YEAR_TYPES.currentYear}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('donationYear', DONATION_YEAR_TYPES.nextYear);
                  }}
                  text="Next year"
                  isActive={donationYear === DONATION_YEAR_TYPES.nextYear}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('donationYear', DONATION_YEAR_TYPES.noPreference);
                  }}
                  text="No preference"
                  isActive={donationYear === DONATION_YEAR_TYPES.noPreference}
                />
              </div>
            </div>
            <div className="ddm-form-section">
              <div className="tw-flex">
                <p className="ddm-form-section-title tw-mb-4">Tax Filing</p>
              </div>
              <div className="tw-grid tw-gap-5">
                <input type="hidden" {...register('taxFiling')} />
                <SelectButton
                  onSelect={_ => {
                    setValue('taxFiling', 'true');
                  }}
                  text="I will claim a tax deduction"
                  isActive={taxFiling === 'true'}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('taxFiling', 'false');
                  }}
                  text="I will not claim a tax deduction"
                  isActive={taxFiling === 'false'}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('taxFiling', '');
                  }}
                  text="Unknown"
                  isActive={taxFiling === ''}
                />
              </div>
            </div>
            <div className="ddm-form-section">
              <div className="tw-flex">
                <p className="ddm-form-section-title tw-mb-4">
                  Final Deliverable
                </p>
                <Tooltip content="Please select the preferred format to receive your official documents.">
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-donor-address"
                  />
                </Tooltip>
              </div>
              <div className="tw-grid tw-gap-5">
                <input
                  type="hidden"
                  {...register('finalDeliverable', { required: true })}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('finalDeliverable', FINAL_DELIVERABLES.digital);
                  }}
                  text="Digital copy"
                  isActive={finalDeliverable === FINAL_DELIVERABLES.digital}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('finalDeliverable', FINAL_DELIVERABLES.physical);
                  }}
                  text="Hard copy"
                  isActive={finalDeliverable === FINAL_DELIVERABLES.physical}
                />
                <SelectButton
                  onSelect={_ => {
                    setValue('finalDeliverable', FINAL_DELIVERABLES.both);
                  }}
                  text="Both"
                  isActive={finalDeliverable === FINAL_DELIVERABLES.both}
                />
              </div>
            </div>
            <div className="spc-mt-30 d-flex grid-cols-2 tw-justify-end">
              <input
                type="submit"
                disabled={isSubmitting}
                className={`ddm-btn-next-inactive gen-cursor-hover-link tw-uppercase disabled:tw-opacity-50 ${
                  isEmpty(errors) ? 'ddm-btn-next-active' : ''
                }`}
                value="Submit"
              />
            </div>
            <div className="tw-grid tw-justify-center">
              {!isEmpty(errors) ? (
                <div className="ddm-error-message-container">
                  <p className="ddm-error-message">
                    Please complete required field(s)
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="gen-background-nav-donor">
      <div className="ddm-header mx-auto d-flex">
        <img
          src={BackIcon}
          alt="back-arrow"
          className="ddm-header-icon-back gen-cursor-hover-link"
          onClick={e => history.goBack()}
        />
        <div class-name="ddm-header-back-container-content">
          <p className="ddm-header-title">Donor Information</p>
        </div>
      </div>
      <p className="text-center ddm-error-text">
        <em>Something went wrong.</em>
      </p>
    </div>
  );
}

const setDonorType = ({
  donation,
  pastDonation,
  setDonorKind,
  setValue,
  user,
}) => {
  if (donation?.donorInfo?.donorType?.individual) {
    setValue(
      'donorType.individual.nameFirst',
      donation.donorInfo.donorType.individual.nameFirst,
    );
    setValue(
      'donorType.individual.nameLast',
      donation.donorInfo.donorType.individual.nameLast,
    );
    return setDonorKind('individual');
  }
  if (pastDonation?.donorInfo?.donorType?.individual) {
    setValue(
      'donorType.individual.nameFirst',
      pastDonation.donorInfo.donorType.individual.nameFirst,
    );
    setValue(
      'donorType.individual.nameLast',
      pastDonation.donorInfo.donorType.individual.nameLast,
    );
    return setDonorKind('individual');
  }

  // Couple
  if (donation?.donorInfo?.donorType?.couple) {
    setValue('donorType.couple', donation.donorInfo.donorType.couple);
    return setDonorKind('couple');
  }
  if (pastDonation?.donorInfo?.donorType?.couple) {
    setValue('donorType.couple', pastDonation.donorInfo.donorType.couple);
    return setDonorKind('couple');
  }
  // Institution
  if (donation?.donorInfo?.donorType?.institution) {
    setValue('donorType.institution', donation.donorInfo.donorType.institution);
    return setDonorKind('institution');
  }
  if (pastDonation?.donorInfo?.donorType?.institution) {
    setValue(
      'donorType.institution',
      pastDonation.donorInfo.donorType.institution,
    );

    return setDonorKind('institution');
  }

  // Default to the current user as an individual donor.
  setValue('donorType.individual.nameFirst', user?.nameFirst || '');
  setValue('donorType.individual.nameLast', user?.nameLast || '');
  return setDonorKind('individual');
};
