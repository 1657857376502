import React from 'react';
import { Redirect } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import DocIconGrey from '../../images/doc-icon-grey.png';
import '../../styles/donation.css';
import '../../styles/general.css';
import '../../styles/spacing.css';
import { useCurrentDonorAccountRoleMatches } from 'components/_shared/useDonorAccountRole';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  useLazyGetDonationAppraisalReportQuery,
  usePutDonationAppraisalApproveMutation,
} from 'requests/api';
import { openBase64PDFNewWindow } from '@lib';

export default function DonorDonationAppraisal() {
  const { donationId, accountId } = useParams();
  const { data: donation, isLoading: isLoadingDonation } = useDonorGetDonation({
    accountId,
    donationId,
  });

  const { goBack } = useHistory();

  const canManage = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  const [getAppraisalReport, { isLoading: isLoadingGetAppraisalReport }] =
    useLazyGetDonationAppraisalReportQuery();
  const [putApprove, { isSuccess: isSuccessPutApprove }] =
    usePutDonationAppraisalApproveMutation();

  if (isSuccessPutApprove) {
    return (
      <Redirect
        push
        to={`/donor/folders/${accountId}/donation/${donationId}`}
      />
    );
  }

  if (isLoadingDonation) return null;

  if (donation) {
    return (
      <div className="gen-background-nav-donor">
        <div className="d-header d-header-no-bottom-border mx-auto">
          <div className="d-flex">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="d-header-icon-back gen-cursor-hover-link"
              onClick={e => goBack()}
            />
            <div class-name="d-header-back-container-content">
              <p className="d-header-title">Approve Appraisal</p>
            </div>
          </div>
          <div className="d-appraisal-header-note-container">
            <p className="d-appraisal-header-note-txt">
              Review the appraisal below and approve it to complete the
              appraisal process. Contact a{' '}
              <a
                href={`mailto:donor_liaison@museumexchange.com`}
                target="_blank"
                rel="noopener noreferrer"
                className="tw-text-blue-500 hover:tw-underline"
              >
                Donor Liaison
              </a>{' '}
              if you have any questions or concerns.
              <br />
              <br />
              NOTE: Note only object owners and editors can approve appraisals.
            </p>
          </div>
        </div>
        <div className="d-appraisal-content mx-auto">
          <div className="d-appraisal-card mx-auto d-flex justify-content-between">
            <div className="d-flex spc-mt-11">
              <img
                src={DocIconGrey}
                alt="doc-grey"
                className="d-appraisal-doc-icon"
              />
              <p className="d-appraisal-filename">
                {donation.appraisal.report.fileName}
              </p>
            </div>
            <div
              className="d-appraisal-view-btn gen-cursor-hover-link"
              onClick={async e => {
                e.stopPropagation();

                const appraisalReportData = await getAppraisalReport({
                  donationId: donation.id,
                }).unwrap();
                openBase64PDFNewWindow(appraisalReportData);
              }}
            >
              VIEW APPRAISAL
            </div>
          </div>
          <div className="d-appraisal-break mx-auto"></div>

          {canManage ? (
            <div
              className="d-aappraisal-approve-btn gen-cursor-hover-link mx-auto"
              onClick={e => {
                e.stopPropagation();
                putApprove({ donationId: donation.id });
              }}
            >
              APPROVE APPRAISAL
            </div>
          ) : null}

          <a
            href={`mailto:donor_liaison@museumexchange.com`}
            target="_blank"
            rel="noopener noreferrer"
            className="gen-link d-appraisal-contact-link"
          >
            <p className="text-center">CONTACT A DONOR LIAISON</p>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="gen-background-nav-donor">
      <div className="d-header mx-auto d-flex">
        <img
          src={BackIcon}
          alt="back-arrow"
          className="d-header-icon-back gen-cursor-hover-link"
          onClick={e => goBack()}
        />
        <div class-name="d-header-back-container-content">
          <p className="d-header-title">Approve Appraisal</p>
        </div>
      </div>
      <p className="text-center d-special-text">
        <em>Something went wrong.</em>
      </p>
    </div>
  );
}
