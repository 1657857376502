import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Transition, Menu, Dialog } from '@headlessui/react';
import {
  ChevronDownIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import { useCurrentDonorAccountRoleMatches } from 'components/_shared/useDonorAccountRole';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { useForm } from 'react-hook-form';
import {
  useDeleteAccountMutation,
  useGetDonorAccountActiveObjectsQuery,
  useGetDonorAccountInactiveObjectsQuery,
  useGetDonorAccountQuery,
  useRenameAccountMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';

export default function DonorAccountActionsDropdown() {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const { accountId } = useParams();
  const { data: selectedAccount } = useGetDonorAccountQuery(accountId);

  const canManage = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  const [deleteAccount, { error, isLoading, isError }] =
    useDeleteAccountMutation();

  const { data: activeObjects } =
    useGetDonorAccountActiveObjectsQuery(accountId);

  const { data: inactiveObjects } =
    useGetDonorAccountInactiveObjectsQuery(accountId);

  const hasActiveItems = activeObjects?.length > 0;
  const hasInactiveItems = inactiveObjects?.length > 0;

  const isEmpty = !hasActiveItems && !hasInactiveItems;
  const canDelete = canManage && isEmpty;

  const history = useHistory();

  async function onDeleteAccount() {
    try {
      await deleteAccount({
        id: selectedAccount.id,
      }).unwrap();

      history.push('/donor');
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>
      <Menu as="div" className="tw-relative">
        <Menu.Button className="tw-flex tw-max-w-[320px] tw-cursor-pointer tw-items-center tw-rounded tw-py-2 tw-px-3 tw-text-left tw-font-light tw-text-gray-400 hover:tw-bg-blue-100 hover:tw-text-blue-500">
          <span className="">{selectedAccount.name}</span>
          <span>
            <ChevronDownIcon className="tw-h-8 tw-w-8" />
          </span>
        </Menu.Button>
        <Transition
          enter="tw-transition tw-duration-100 tw-ease-out"
          enterFrom="tw-transform tw-scale-95 tw-opacity-0"
          enterTo="tw-transform tw-scale-100 tw-opacity-100"
          leave="tw-transition tw-duration-75 tw-ease-out"
          leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
          leaveTo="tw-transform tw-scale-95 tw-opacity-0"
        >
          <Menu.Items className="tw-absolute tw-left-0 tw-mt-2 tw-w-80 tw-origin-top-right tw-rounded-sm tw-bg-white tw-py-2 tw-text-sm tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
            {canManage ? (
              <div className="tw-py-1 tw-px-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active
                          ? 'tw-bg-blue-100 tw-text-blue-500'
                          : 'tw-text-gray-500'
                      } tw-group tw-flex tw-w-full tw-items-center tw-gap-3 tw-rounded-sm tw-px-6 tw-py-2 tw-text-sm tw-font-normal`}
                      onClick={() => setIsRenameModalOpen(true)}
                    >
                      <PencilIcon
                        className="tw-h-5 tw-w-5 "
                        aria-hidden="true"
                      />
                      Rename
                    </button>
                  )}
                </Menu.Item>
              </div>
            ) : null}
            <div className="tw-py-1 tw-px-1">
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="mailto:help@museumexchange.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`link link-no-decoration ${
                      active
                        ? 'tw-bg-blue-100 tw-text-blue-500'
                        : 'tw-text-gray-500'
                    } tw-group tw-flex tw-w-full tw-items-center tw-gap-3 tw-rounded-sm tw-px-6 tw-py-2 tw-text-sm tw-font-normal`}
                  >
                    <QuestionMarkCircleIcon
                      className="tw-h-5 tw-w-5 "
                      aria-hidden="true"
                    />
                    Get help with a folder
                  </a>
                )}
              </Menu.Item>
            </div>
            {canDelete ? (
              <div className="tw-py-1 tw-px-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active
                          ? 'tw-bg-blue-100 tw-text-blue-500'
                          : 'tw-text-gray-500'
                      } tw-group tw-flex tw-w-full tw-items-center tw-gap-3 tw-rounded-sm tw-px-6 tw-py-2 tw-text-sm tw-font-normal`}
                      onClick={onDeleteAccount}
                    >
                      <TrashIcon
                        className="tw-h-5 tw-w-5 "
                        aria-hidden="true"
                      />
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            ) : null}
          </Menu.Items>
        </Transition>
      </Menu>
      <RenameModal
        selectedAccount={selectedAccount}
        isOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
      />
    </div>
  );
}

function RenameModal({ selectedAccount, isOpen, closeModal }) {
  const defaultValues = {
    name: selectedAccount.name,
  };

  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues,
  });

  const { errors } = formState;

  // useEffect(() => {
  //   if (formState.isSubmitSuccessful) {
  //     reset(defaultValues);
  //   }
  // }, [formState, reset]);

  useEffect(() => {
    reset({ name: selectedAccount.name });
  }, [selectedAccount.name]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    reset(defaultValues);
  }, [closeModal, reset]);

  const [renameAccount, { error, isLoading, isError }] =
    useRenameAccountMutation();

  async function onSubmit(values) {
    try {
      await renameAccount({
        id: selectedAccount?.id,
        name: values.name,
      }).unwrap();

      closeModal();
    } catch (e) {
      // Show error message notification
      console.log(e);
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
        onClose={handleCloseModal}
      >
        <div className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 tw-text-center">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black/25" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <div className="tw-inline-block tw-w-[530px] tw-transform tw-rounded-lg tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-transition-all">
              <div className="tw-grid tw-gap-8">
                <div className="tw-grid tw-gap-1">
                  <Dialog.Title as="h2" className="tw-mb-0 tw-text-xl">
                    Rename
                  </Dialog.Title>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="tw-grid tw-gap-8"
                >
                  <div className="tw-grid tw-gap-2">
                    <div className="tw-flex tw-gap-1">
                      <div className="tw-grow">
                        <input
                          className="tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm tw-block tw-w-full tw-rounded tw-border tw-border-gray-300 tw-py-2 tw-px-3"
                          placeholder="Add email address"
                          {...register('name', { required: true })}
                        />
                      </div>
                    </div>
                    {errors && (
                      <>
                        {errors.initialName && (
                          <div className="tw-text-red-600">
                            Please enter a valid email
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="tw-flex tw-justify-end tw-gap-5">
                    <button
                      type="button"
                      className="tw-min-w-[120px] tw-rounded tw-border tw-border-blue-800 tw-py-2 tw-px-7 tw-uppercase"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="tw-min-w-[120px] tw-rounded tw-border tw-border-blue-800 tw-bg-blue-800 tw-py-2 tw-px-7 tw-uppercase tw-text-white disabled:tw-border-gray-300 disabled:tw-bg-gray-300"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? '...' : 'Okay'}
                    </button>
                  </div>
                  {isError ? (
                    <div className="tw-text-red-500">
                      Error:{' '}
                      {error?.message ||
                        'Something went wrong. Please try again.'}
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
