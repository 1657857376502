import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';

import {
  authUpdateToken,
  confirmEmailPutConfirmFailure,
  confirmEmailPutConfirmRequest,
  confirmEmailPutConfirmSuccess,
  getUserFailure,
  getUserRequest,
  getUserSuccess,
  postDonorApplicationFailure,
  postDonorApplicationRequest,
  postDonorApplicationSuccess,
  postMuseumApplicationFailure,
  postMuseumApplicationRequest,
  postMuseumApplicationSuccess,
  postTelFailure,
  postTelRequest,
  postTelSuccess,
  postTelResendFailure,
  postTelResendRequest,
  postTelResendSuccess,
  postUserFailure,
  postUserRequest,
  postUserSuccess,
  putEmailFailure,
  putEmailRequest,
  putEmailSuccess,
  putNameFailure,
  putNameRequest,
  putNameSuccess,
  putTelFailure,
  putTelRequest,
  putTelSuccess,
  pageAppReset,
} from '../../actions';

export const getUser = token => {
  return dispatch => {
    dispatch(getUserRequest());
    axios({
      method: 'GET',
      url: `${BASE_URL}/users/${token.userId}`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.setItem('mex_user', JSON.stringify(response.data));
        dispatch(getUserSuccess(response.data));
      })
      .catch(error => {
        if (
          typeof error === 'object' &&
          error &&
          error.message === 'Request failed with status code 403'
        ) {
          localStorage.clear();
          dispatch(pageAppReset());
          window.location.reload(false);
        }
        dispatch(getUserFailure(error.message));
      });
  };
};

export const postDonorApplication = (token, data) => {
  return dispatch => {
    dispatch(postDonorApplicationRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/users/${token.userId}/donor/apply`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.setItem('mex_user', JSON.stringify(response.data));
        dispatch(postDonorApplicationSuccess(response.data));
      })
      .catch(error => {
        dispatch(postDonorApplicationFailure(error.message));
      });
  };
};

export const postMuseumApplication = (token, data) => {
  return dispatch => {
    dispatch(postMuseumApplicationRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/users/${token.userId}/museum/apply`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.setItem('mex_user', JSON.stringify(response.data));
        dispatch(postMuseumApplicationSuccess(response.data));
      })
      .catch(error => {
        dispatch(postMuseumApplicationFailure(error.message));
      });
  };
};

export const postTel = (token, data) => {
  return dispatch => {
    dispatch(postTelRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/users/${token.userId}/tel`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(postTelSuccess(response.data));
      })
      .catch(error => {
        dispatch(postTelFailure(error.message));
      });
  };
};

export const postTelResend = (token, tel) => {
  return dispatch => {
    dispatch(postTelResendRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/users/${token.userId}/tel/${tel.id}/resend`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(postTelResendSuccess(response.data));
      })
      .catch(error => {
        dispatch(postTelResendFailure(error.message));
      });
  };
};

export const postUser = data => {
  return dispatch => {
    dispatch(postUserRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/users`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: { 'content-type': 'application/json' },
    })
      .then(response => {
        localStorage.setItem('mex_user', JSON.stringify(response.data));
        dispatch(postUserSuccess(response.data));
      })
      .catch(error => {
        dispatch(postUserFailure(error.message));
      });
  };
};

export const putConfirmEmail = (token, loginCreds, emailId) => {
  let tokenId = '';
  let data = {};
  if (token !== null) {
    tokenId = token.id;
  } else if (loginCreds !== null) {
    data = JSON.stringify(loginCreds);
  } else {
    return;
  }
  return dispatch => {
    dispatch(confirmEmailPutConfirmRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/emails/${emailId}/confirm`,
      timeout: DEFAULT_TIMEOUT,
      headers: {
        'content-type': 'application/json',
        'x-api-token': tokenId,
      },
      data,
    })
      .then(response => {
        dispatch(confirmEmailPutConfirmSuccess(response.data));
      })
      .catch(error => {
        dispatch(confirmEmailPutConfirmFailure(error.message));
      });
  };
};

export const putEmail = (token, email) => {
  return dispatch => {
    dispatch(putEmailRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/${token.userId}/email`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ email }),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(putEmailSuccess(response.data));
      })
      .catch(error => {
        dispatch(putEmailFailure(error.message));
      });
  };
};

export const putName = (token, data) => {
  return dispatch => {
    dispatch(putNameRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/${token.userId}/name`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(putNameSuccess(response.data));
      })
      .catch(error => {
        dispatch(putNameFailure(error.message));
      });
  };
};

export const putTel = (token, data) => {
  return dispatch => {
    dispatch(putTelRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/${token.userId}/tel`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.setItem('mex_token', JSON.stringify(response.data));
        dispatch(putTelSuccess(response.data));
        dispatch(authUpdateToken({ token: response.data }));
      })
      .catch(error => {
        dispatch(putTelFailure(error.message));
      });
  };
};
