import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  putEmailClear,
  userUpdateNewEmail,
  userUpdateNewEmailError,
} from '../../actions';
import { putEmail } from '../../requests';
import { validateEmail } from '../../lib';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/text.css';

class MuseumUserEmail extends Component {
  static get propTypes() {
    return {
      email: PropTypes.string,
      emailError: PropTypes.bool,
      putEmail: PropTypes.func,
      putEmailClear: PropTypes.func,
      putEmailRequest: PropTypes.object,
      token: PropTypes.object,
      updateEmail: PropTypes.func,
      updateEmailError: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.putEmailClear();
    this.props.updateEmail({ newEmail: '' });
    this.props.updateEmailError({ newEmailError: false });
  }

  handleSaveClick = _ => {
    if (this.props.putEmailRequest.loadiing) {
      return;
    }

    let newEmailError = false;

    if (!this.props.email || !validateEmail(this.props.email)) {
      newEmailError = true;
    }

    this.props.updateEmailError({ newEmailError });

    if (newEmailError) {
      return;
    }

    this.props.putEmail(this.props.token, this.props.email);
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Email Address</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-label-txt">CURRENT EMAIL ADDRESS</p>
            <div className="set-info-edit-current-val-container">
              <p className="set-info-txt">{this.props.user.email}</p>
            </div>
            <p className="set-label-txt">NEW EMAIL ADDRESS</p>
            <input
              type="text"
              className={`set-info-edit-field ${
                this.props.emailError ? 'fd-input-error-field' : ''
              }`}
              maxLength="100"
              value={this.props.email}
              onChange={e =>
                this.props.updateEmail({ newEmail: e.target.value })
              }
            />
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              {this.props.putEmailRequest.error ? (
                <p className="set-special-err-1">Please try again</p>
              ) : null}
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putEmailRequest.data) {
      return <Redirect to="/institution/settings/user" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    email: state.userState.newEmail,
    emailError: state.userState.newEmailError,
    putEmailRequest: state.userState.putEmailRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putEmail: (token, email) => dispatch(putEmail(token, email)),
    putEmailClear: _ => dispatch(putEmailClear()),
    updateEmail: payload => dispatch(userUpdateNewEmail(payload)),
    updateEmailError: payload => dispatch(userUpdateNewEmailError(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumUserEmail);
