import React from 'react';
import PropTypes from 'prop-types';

export function NestedSection({ children }) {
  return (
    <div className="tw-rounded-[1px] tw-border tw-border-[#2e5bff14] tw-bg-[#edeeef] tw-shadow-sm">
      {children}
    </div>
  );
}

NestedSection.propTypes = {
  children: PropTypes.node.isRequired,
};
