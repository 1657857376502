import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  ddonmSetFormTwo,
  ddonmUpdateContactNameLast,
  ddonmUpdateContactNameFirst,
  ddonmUpdateContactTel,
  ddonmUpdateContactEmail,
  ddonmUpdateSAddressCompany,
  ddonmUpdateSAddressLineOne,
  ddonmUpdateSAddressLineTwo,
  ddonmUpdateSAddressCity,
  ddonmUpdateSAddressState,
  ddonmUpdateSAddressZip,
  ddonmUpdateSAddressCountry,
  ddonmUpdateShippingDescription,
  ddonmUpdateFormTwoErrors,
} from '../../actions';
import { formatPhoneNumber } from '../../lib';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/donor-donation-match.css';
import InfoIcon from '../../images/info-icon-donation.png';
import '../../styles/donor-donation-match.css';
import '../../styles/fields.css';
import '../../styles/spacing.css';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';
import { useParams } from 'react-router-dom';
import {
  useGetDonationsQuery,
  usePutDonationPreliminaryShippingMutation,
} from 'requests/api';
import { sanitizeTel, validateEmail } from '../../lib';
import { useHistory } from 'react-router-dom';

export default function DonorDonationPreliminaryShipping() {
  const { accountId, donationId } = useParams();
  const { goBack } = useHistory();

  const { data: pastDonations, isLoading: isLoadingDonations } =
    useGetDonationsQuery({
      account: accountId,
      active: true,
      exclude: donationId,
      hasEvent: 'acceptedComplete',
      sort: 'created:-1',
      limit: 1,
    });
  const {
    data: donation,
    isLoading: isLoadingDonation,
    isError: isErrorGetDonation,
  } = useDonorGetDonation({
    donationId,
    accountId,
  });
  const {
    data: object,
    isLoading: isLoadingObject,
    isError: isErrorGetObject,
  } = useDonorGetObject({
    accountId,
    objectId: donation?.objectId,
  });

  const formData = useSelector(
    state => state.donorDonationsMatchState.formDataTwo,
  );
  const formErrors = useSelector(
    state => state.donorDonationsMatchState.formErrorsTwo,
  );
  const user = useSelector(state => state.userState.user);

  const dispatch = useDispatch();

  const [
    putDonationDonorPreliminaryShipping,
    { isLoading: isLoadingPut, isSuccess },
  ] = usePutDonationPreliminaryShippingMutation();

  const setFormData = payload => dispatch(ddonmSetFormTwo(payload));
  const updateCompany = payload =>
    dispatch(ddonmUpdateSAddressCompany(payload));
  const updateLineOne = payload =>
    dispatch(ddonmUpdateSAddressLineOne(payload));
  const updateLineTwo = payload =>
    dispatch(ddonmUpdateSAddressLineTwo(payload));
  const updateCity = payload => dispatch(ddonmUpdateSAddressCity(payload));
  const updateState = payload => dispatch(ddonmUpdateSAddressState(payload));
  const updateZip = payload => dispatch(ddonmUpdateSAddressZip(payload));
  const updateCountry = payload =>
    dispatch(ddonmUpdateSAddressCountry(payload));
  const updateNameFirst = payload =>
    dispatch(ddonmUpdateContactNameFirst(payload));
  const updateNameLast = payload =>
    dispatch(ddonmUpdateContactNameLast(payload));
  const updateTel = payload => dispatch(ddonmUpdateContactTel(payload));
  const updateEmail = payload => dispatch(ddonmUpdateContactEmail(payload));
  const updateDescription = payload =>
    dispatch(ddonmUpdateShippingDescription(payload));
  const updateErrors = payload => dispatch(ddonmUpdateFormTwoErrors(payload));

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitialized) return;
    if (!donation || !pastDonations || !user) return;

    const pastDonation =
      pastDonations?.donations.length > 0 ? pastDonations.donations[0] : null;

    // Pick Up Address
    if (donation?.donorInfo?.shippingAddress?.lineOne) {
      updateCompany(donation?.donorInfo?.shippingAddress?.company);
      updateLineOne(donation?.donorInfo?.shippingAddress?.lineOne);
      updateLineTwo(donation?.donorInfo?.shippingAddress?.lineTwo);
      updateCity(donation?.donorInfo?.shippingAddress?.city);
      updateState(donation?.donorInfo?.shippingAddress?.state);
      updateZip(donation?.donorInfo?.shippingAddress?.zip);
      updateCountry(donation?.donorInfo?.shippingAddress?.country);
    } else if (pastDonation?.donorInfo?.shippingAddress?.lineOne) {
      updateCompany(pastDonation?.donorInfo?.shippingAddress?.company);
      updateLineOne(pastDonation?.donorInfo?.shippingAddress?.lineOne);
      updateLineTwo(pastDonation?.donorInfo?.shippingAddress?.lineTwo);
      updateCity(pastDonation?.donorInfo?.shippingAddress?.city);
      updateState(pastDonation?.donorInfo?.shippingAddress?.state);
      updateZip(pastDonation?.donorInfo?.shippingAddress?.zip);
      updateCountry(pastDonation?.donorInfo?.shippingAddress?.country);
    }

    // Pick Up Contact
    if (donation?.donorInfo?.shippingContact?.nameFirst) {
      updateNameFirst(donation?.donorInfo?.shippingContact?.nameFirst);
      updateNameLast(donation?.donorInfo?.shippingContact?.nameLast);
      updateEmail(donation?.donorInfo?.shippingContact?.email);
      updateTel(formatPhoneNumber(donation?.donorInfo?.shippingContact?.tel));
    } else if (pastDonation?.donorInfo?.shippingContact?.nameFirst) {
      updateNameFirst(pastDonation?.donorInfo?.shippingContact?.nameFirst);
      updateNameLast(pastDonation?.donorInfo?.shippingContact?.nameLast);
      updateEmail(pastDonation?.donorInfo?.shippingContact?.email);
      updateTel(
        formatPhoneNumber(pastDonation?.donorInfo?.shippingContact?.tel),
      );
    } else {
      updateNameFirst(user.nameFirst);
      updateNameLast(user.nameLast);
      updateEmail(user.email);
      updateTel(formatPhoneNumber(user.tel));
    }

    // General Availability
    if (donation?.donorInfo?.shippingDescription) {
      updateDescription(donation?.donorInfo?.shippingDescription);
    } else if (pastDonation?.donorInfo?.shippingDescription) {
      updateDescription(pastDonation?.donorInfo?.shippingDescription);
    }

    setIsInitialized(true);
  }, [isInitialized, user, donation, pastDonations]);

  const isLoading = isLoadingDonation || isLoadingObject;

  const makeSubmitButton = _ => {
    const errors = matchTwoValidate(formData);

    return (
      <div className="d-flex align-items-end flex-column">
        <input
          className={`ddm-btn-next-inactive gen-cursor-hover-link tw-uppercase disabled:tw-opacity-50 ${
            !errors.formError ? 'ddm-btn-next-active' : ''
          }`}
          disabled={isLoadingPut}
          onClick={async _ => {
            if (isLoadingPut) {
              return;
            }
            updateErrors(errors);
            if (errors.formError) {
              return;
            }
            try {
              await putDonationDonorPreliminaryShipping({
                donationId: donation.id,
                data: matchTwoMakePayload(formData),
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          }}
          value="Submit"
        />
        {formErrors.formError ? (
          <div className="ddm-error-message-container">
            <p className="ddm-error-message">
              Please complete required field(s)
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  if (isSuccess) {
    if (!donation?.objectInfo?.objectInfoSubmitted) {
      return (
        <Redirect
          push
          to={`/donor/folders/${accountId}/donation/${donationId}/preliminary-object-info`}
        />
      );
    } else {
      return (
        <Redirect
          push
          to={`/donor/folders/${accountId}/donation/${donationId}/`}
        />
      );
    }
  }

  if (isLoading) return null;

  if (isErrorGetObject || isErrorGetDonation) {
    return (
      <div className="gen-background-nav-donor">
        <div className="ddm-header mx-auto d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="ddm-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div class-name="ddm-header-back-container-content">
            <p className="ddm-header-title">Pick Up Information</p>
          </div>
        </div>
        <p className="text-center ddm-error-text">
          <em>Something went wrong.</em>
        </p>
      </div>
    );
  }

  if (donation && object) {
    return (
      <div className="gen-background-nav-donor">
        <div className="ddm-header mx-auto d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="ddm-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div class-name="ddm-header-back-container-content">
            <p className="ddm-header-title">Pick Up Information</p>
            <div className="ddm-header-text-container">
              <p className="ddm-header-text">
                Please complete this form for the institution to refer to while
                arranging shipping
              </p>
            </div>
          </div>
        </div>
        <div className="ddm-form-content mx-auto">
          <div className="ddm-form-content-inner mx-auto">
            <div className="ddm-form-section">
              <div className="spc-mb-15">
                <p className="ddm-form-section-title">Pick Up Address</p>
              </div>
              <div className="spc-mb-15">
                <p
                  className={`ddm-field-label ${
                    formErrors.companyError ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Company Name (Optional)
                </p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full ${
                    formErrors.companyError ? 'fd-input-error-field' : ''
                  }`}
                  onChange={event => updateCompany(event.target.value)}
                  value={formData.shippingAddress.company}
                  maxLength="150"
                  placeholder="Company Name"
                ></input>
              </div>
              <div className="spc-mb-15">
                <p
                  className={`ddm-field-label ${
                    formErrors.companyError ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Address Line 1
                </p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full ${
                    formErrors.lineOneError ? 'fd-input-error-field' : ''
                  }`}
                  onChange={event => updateLineOne(event.target.value)}
                  value={formData.shippingAddress.lineOne}
                  maxLength="150"
                  placeholder="Address Line 1"
                ></input>
              </div>
              <div className="spc-mb-15">
                <p className="ddm-field-label">Address Line 2</p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full`}
                  onChange={event => updateLineTwo(event.target.value)}
                  value={formData.shippingAddress.lineTwo || ''}
                  maxLength="150"
                  placeholder="Address Line 2"
                ></input>
              </div>
              <div className="spc-mb-15 d-flex justify-content-between">
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.cityError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    City
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.cityError ? 'fd-input-error-field' : ''
                    }`}
                    onChange={event => updateCity(event.target.value)}
                    value={formData.shippingAddress.city}
                    maxLength="100"
                    placeholder="City"
                  ></input>
                </div>
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.stateError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    State / Province
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.stateError ? 'fd-input-error-field' : ''
                    }`}
                    onChange={event =>
                      updateState(event.target.value.toUpperCase())
                    }
                    value={formData.shippingAddress.state}
                    maxLength="2"
                    placeholder="State / Province"
                  ></input>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.zipError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Zip / Postal Code
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.zipError ? 'fd-input-error-field' : ''
                    }`}
                    onChange={event => updateZip(event.target.value)}
                    value={formData.shippingAddress.zip}
                    maxLength="7"
                    placeholder="Zip / Postal Code"
                  ></input>
                </div>
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.countryError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Country
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.countryError ? 'fd-input-error-field' : ''
                    }`}
                    onChange={event => updateCountry(event.target.value)}
                    value={formData.shippingAddress.country}
                    maxLength="100"
                    placeholder="Country"
                  ></input>
                </div>
              </div>
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15">
                <p className="ddm-form-section-title">Pick Up Contact</p>
              </div>
              <div className="spc-mb-15 d-flex justify-content-between">
                <div>
                  <p
                    className={`ddm-field-label ${
                      formErrors.nameFirstError || formErrors.nameLastError
                        ? 'fd-input-error-txt'
                        : ''
                    }`}
                  >
                    Contact Name
                  </p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.nameFirstError ? 'fd-input-error-field' : ''
                    }`}
                    onChange={event => updateNameFirst(event.target.value)}
                    value={formData.contact.nameFirst}
                    maxLength="100"
                    placeholder="First Name"
                  ></input>
                </div>
                <div>
                  <p className="ddm-field-label">&nbsp;</p>
                  <input
                    type="text"
                    className={`fd-input ddm-field-narrow ${
                      formErrors.nameLastError ? 'fd-input-error-field' : ''
                    }`}
                    onChange={event => updateNameLast(event.target.value)}
                    value={formData.contact.nameLast}
                    maxLength="100"
                    placeholder="Last Name"
                  ></input>
                </div>
              </div>
              <div className="spc-mb-15">
                <p
                  className={`ddm-field-label ${
                    formErrors.telError ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Phone Number
                </p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full ${
                    formErrors.telError ? 'fd-input-error-field' : ''
                  }`}
                  onChange={event => updateTel(event.target.value)}
                  value={formData.contact.tel}
                  maxLength="20"
                  placeholder="Phone Number"
                ></input>
              </div>
              <div>
                <p
                  className={`ddm-field-label ${
                    formErrors.emailError ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Email Address
                </p>
                <input
                  type="text"
                  className={`fd-input ddm-field-full ${
                    formErrors.emailError ? 'fd-input-error-field' : ''
                  }`}
                  onChange={event => updateEmail(event.target.value)}
                  value={formData.contact.email}
                  placeholder="Email Address"
                ></input>
              </div>
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15 d-flex">
                <p
                  className={`ddm-form-section-title ${
                    formErrors.shippingDescriptionError
                      ? 'fd-input-error-txt'
                      : ''
                  }`}
                >
                  General Availability
                </p>
                <img
                  src={InfoIcon}
                  alt="info-icon"
                  className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-availability"
                />
                <div className="ddm-hover-element ddm-hover-element-availability">
                  <p className="ddm-hover-element-text">
                    Please indicate general availability or restrictions for
                    pick up: i.e. preferred days of the week, time of day, etc.
                  </p>
                </div>
              </div>
              <textarea
                className={`fd-input-textarea ddm-input-full ${
                  formErrors.shippingDescriptionError
                    ? 'fd-input-error-field'
                    : ''
                }`}
                onChange={event => updateDescription(event.target.value)}
                value={formData.shippingDescription}
                maxLength="500"
                placeholder="Description of availability"
              ></textarea>
            </div>
            <div className="spc-mt-30 d-flex tw-justify-end">
              {makeSubmitButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

function matchTwoMakePayload(form) {
  const shippingContact = JSON.parse(JSON.stringify(form.contact));
  return {
    shippingAddress: {
      ...form.shippingAddress,
      lineTwo: form.shippingAddress.lineTwo
        ? form.shippingAddress.lineTwo
        : null,
    },
    shippingContact: {
      ...shippingContact,
      tel: sanitizeTel(shippingContact.tel) || null,
    },

    shippingDescription: form.shippingDescription,
  };
}

function matchTwoValidate(payload) {
  const errors = {
    formError: false,
    companyError: false,
    lineOneError: false,
    cityError: false,
    stateError: false,
    zipError: false,
    countryError: false,
    nameFirstError: false,
    nameLastError: false,
    telError: false,
    emailError: false,
    shippingDescriptionError: false,
  };

  if (payload.shippingAddress.lineOne === '') {
    errors.formError = true;
    errors.lineOneError = true;
  }

  if (payload.shippingAddress.city === '') {
    errors.formError = true;
    errors.cityError = true;
  }

  if (payload.shippingAddress.state.length !== 2) {
    errors.formError = true;
    errors.stateError = true;
  }

  if (
    payload.shippingAddress.zip === '' ||
    payload.shippingAddress.zip.length > 7
  ) {
    errors.formError = true;
    errors.zipError = true;
  }

  if (payload.shippingAddress.country === '') {
    errors.formError = true;
    errors.countryError = true;
  }

  if (payload.contact.nameFirst === '') {
    errors.formError = true;
    errors.nameFirstError = true;
  }

  if (payload.contact.nameLast === '') {
    errors.formError = true;
    errors.nameLastError = true;
  }

  if (payload.contact.email === '' || !validateEmail(payload.contact.email)) {
    errors.formError = true;
    errors.emailError = true;
  }
  if (
    payload.contact.tel === '' ||
    sanitizeTel(payload.contact.tel).length !== 10
  ) {
    errors.formError = true;
    errors.telError = true;
  }

  if (payload.shippingDescription === '') {
    errors.formError = true;
    errors.shippingDescriptionError = true;
  }

  return errors;
}
