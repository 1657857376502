import React from 'react';
import PropTypes from 'prop-types';
import SuccessCheck from '@images/success-check.png';
import SuccessClose from '@images/success-close.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  dacctActions,
  selectSuccessMessage,
} from 'features/donorAccounts/donorAccountsSlice';

function SuccessMessage() {
  const successMessage = useSelector(selectSuccessMessage);

  const dispatch = useDispatch();

  if (!successMessage) return null;

  return (
    <div className="tw-flex tw-min-w-[260px] tw-items-center tw-justify-between tw-bg-gray-500 tw-py-5 tw-px-6 tw-shadow-sm">
      <div className="tw-flex tw-items-center tw-gap-3">
        <img
          src={SuccessCheck}
          alt="success-check"
          className="tw-h-[11px] tw-w-[14px]"
        />
        <p className="tw-text-sm tw-text-white">{successMessage}</p>
      </div>
      <div>
        <button
          className="tw-cursor-pointer tw-p-2"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSuccessMessage(null));
          }}
        >
          <img
            src={SuccessClose}
            alt="success-close"
            className="tw-h-3 tw-w-3"
          />
        </button>
      </div>
    </div>
  );
}

function MessageContainer({ children }) {
  return (
    <div className="tw-fixed tw-bottom-6 tw-left-5 tw-z-20 tw-shadow-sm">
      {children}
    </div>
  );
}

MessageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function WrappedSuccessMessage(props) {
  return (
    <MessageContainer>
      <SuccessMessage {...props} />
    </MessageContainer>
  );
}
