export const DSET_PUT_DONOR_LOCATION_CLEAR = 'DSET_PUT_DONOR_LOCATION_CLEAR';
export const DSET_PUT_DONOR_LOCATION_FAILURE =
  'DSET_PUT_DONOR_LOCATION_FAILURE';
export const DSET_PUT_DONOR_LOCATION_REQUEST =
  'DSET_PUT_DONOR_LOCATION_REQUEST';
export const DSET_PUT_DONOR_LOCATION_SUCCESS =
  'DSET_PUT_DONOR_LOCATION_SUCCESS';
export const DSET_PUT_DONOR_TYPE_CLEAR = 'DSET_PUT_DONOR_TYPE_CLEAR';
export const DSET_PUT_DONOR_TYPE_FAILURE = 'DSET_PUT_DONOR_TYPE_FAILURE';
export const DSET_PUT_DONOR_TYPE_REQUEST = 'DSET_PUT_DONOR_TYPE_REQUEST';
export const DSET_PUT_DONOR_TYPE_SUCCESS = 'DSET_PUT_DONOR_TYPE_SUCCESS';
export const DSET_RESET_TYPE_FORM = 'DSET_RESET_TYPE_FORM';
export const DSET_UPDATE_TYPE_ERRORS = 'DSET_UPDATE_TYPE_ERRORS';
export const DSET_UPDATE_TYPE = 'DSET_UPDATE_TYPE';
export const DSET_UPDATE_PROFESSION = 'DSET_UPDATE_PROFESSION';
export const DSET_RESET_LOCATION_FORM = 'DSET_RESET_LOCATION_FORM';
export const DSET_UPDATE_LOCATION_ERRORS = 'DSET_UPDATE_LOCATION_ERRORS';
export const DSET_UPDATE_LOCATION_CITY = 'DSET_UPDATE_LOCATION_CITY';
export const DSET_UPDATE_LOCATION_STATE = 'DSET_UPDATE_LOCATION_STATE';
