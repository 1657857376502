import React from 'react';
import { getDonationDonorNameText } from 'lib/getDonorNameText';
import { DIRECT_CYCLE_NAME } from 'constants/cycles';
import { Link } from 'react-router-dom';
import { getInitials } from '../../lib';

export const ObjectCardMiddle = ({
  cycle,
  donation,
  object,
  list,
  setSelectedObject,
}) => {
  return (
    <>
      <div className="mcv-obj-lst-card-sec-2">
        {donation.events.completed || donation.events.closed ? (
          <div className="mcv-obj-lst-card-sec-2-content">
            <div className="mcv-obj-lst-card-main-txt-container-sec-2">
              <p className="mcv-obj-lst-card-txt">Donation Completed</p>
            </div>
            <p className="mcv-obj-lst-card-txt-xsm">
              Donation from
              <br />
              {getDonationDonorNameText({ donation })}
            </p>
          </div>
        ) : donation.events.confirmedComplete ? (
          <div className="mcv-obj-lst-card-sec-2-content">
            <div className="mcv-obj-lst-card-main-txt-container-sec-2">
              <p className="mcv-obj-lst-card-txt">Donation in Progress</p>
            </div>
            <p className="mcv-obj-lst-card-txt-xsm">
              Donation from
              <br />
              {getDonationDonorNameText({ donation })}
            </p>
          </div>
        ) : donation.events.confirmed ? (
          <div className="mcv-obj-lst-card-sec-2-content">
            <div className="mcv-obj-lst-card-main-txt-container-sec-2">
              <p className="mcv-obj-lst-card-txt">Acceptance Confirmed</p>
            </div>
            <p className="mcv-obj-lst-card-txt-xsm">Complete Donation Form</p>
          </div>
        ) : (
          <div className="mcv-obj-lst-card-sec-2-content">
            <div className="mcv-obj-lst-card-main-txt-container-sec-2">
              <p className="mcv-obj-lst-card-txt">
                Proposal Accepted
                <br />
                by Donor
              </p>
            </div>
          </div>
        )}
      </div>
      {makeSectionThree({ cycle, object, list })}
      <div className="mcv-obj-lst-card-sec-4">
        <div className="mcv-obj-lst-card-sec-4-content">
          {donation.events.confirmedComplete ? (
            <Link
              to={`/institution/donations/${donation.id}`}
              className="gen-link-no-decoration"
              onClick={_ => {
                setSelectedObject(object);
              }}
            >
              <div className="mcv-obj-lst-card-btn">
                <p>DONATION OVERVIEW</p>
              </div>
            </Link>
          ) : donation.events.confirmed ? (
            <Link
              to={`/institution/donations/${donation.id}/match/complete`}
              className="gen-link-no-decoration"
              onClick={_ => {
                setSelectedObject(object);
              }}
            >
              <div className="mcv-obj-lst-card-btn">
                <p>RESUME FORM</p>
              </div>
            </Link>
          ) : (
            <Link
              to={`/institution/donations/${donation.id}/match/confirm`}
              className="gen-link-no-decoration"
              onClick={_ => {
                setSelectedObject(object);
              }}
            >
              <div className="mcv-obj-lst-card-btn">
                <p>CONFIRM DONATION</p>
              </div>
            </Link>
          )}
          <Link to={`/institution/object/${object.id}`} className="gen-link">
            <p className="mcv-obj-lst-card-txt-link text-center">
              View Object Details
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

const makeSectionThree = ({ cycle, object, list }) => {
  if (cycle?.name === DIRECT_CYCLE_NAME) {
    return (
      <div className="mcv-obj-lst-card-sec-3 d-flex">
        <div className="mcv-obj-lst-card-initials-container">
          <p className="mcv-obj-lst-card-initials-txt"></p>
        </div>
        <div>
          <p className="mcv-obj-lst-card-txt-xsm">Added by</p>
          <p className="mcv-obj-lst-card-txt-sm">Direct Donation</p>
        </div>
      </div>
    );
  }

  const unknown = (
    <div className="mcv-obj-lst-card-sec-3 d-flex">
      <div className="mcv-obj-lst-card-initials-container">
        <p className="mcv-obj-lst-card-initials-txt"></p>
      </div>
      <div>
        <p className="mcv-obj-lst-card-txt-xsm">Added by</p>
        <p className="mcv-obj-lst-card-txt-sm">Unknown</p>
      </div>
    </div>
  );
  if (!list || !list.objects) {
    return unknown;
  }

  const listObject = list.objects[object.id];
  if (!listObject) {
    return unknown;
  }
  return (
    <div className="mcv-obj-lst-card-sec-3 d-flex">
      <div className="mcv-obj-lst-card-initials-container">
        <p className="mcv-obj-lst-card-initials-txt">
          {getInitials(listObject.userName)}
        </p>
      </div>
      <div>
        <p className="mcv-obj-lst-card-txt-xsm">Added by</p>
        <p className="mcv-obj-lst-card-txt-sm">{listObject.userName}</p>
      </div>
    </div>
  );
};
