import {
  PAGE_RESET,
  PAGE_ALLOW_REDIRECT,
  PAGE_DISALLOW_REDIRECT,
  PAGE_UPDATE_WINDOW_WIDTH,
  PAGE_SET_MENU_OPEN,
} from '../../actions/actionTypes';

const initialPageState = {
  loading: false,
  menuOpen: false,
  windowWidth: null,
};

export const pageReducer = (state = initialPageState, action) => {
  switch (action.type) {
    case PAGE_RESET:
      return {
        ...state,
        loading: true,
      };

    case PAGE_ALLOW_REDIRECT:
      return {
        ...state,
        loading: false,
      };

    case PAGE_DISALLOW_REDIRECT:
      return {
        ...state,
        loading: true,
      };

    case PAGE_UPDATE_WINDOW_WIDTH:
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
      };

    case PAGE_SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload,
      };

    default:
      return state;
  }
};
