import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ONGOING_CYCLE_POST_PROPOSAL_DAYS } from '../../config';
import {
  listsClearCuratorial,
  listsClearCuratorialPast,
  listsClearInterest,
  listsClearInterestPast,
  listsGetCuratorialClear,
  listsGetCuratorialPastClear,
  listsGetInterestClear,
  listsGetInterestPastClear,
  propClearProposal,
  propResetForm,
  propSetSelectedObject,
  propSetSelectedProposalId,
} from '../../actions';
import { getCurrentCycleLists } from '../../requests';
import {
  addDays,
  convertToMMDDYY,
  findCycleInCollection,
  getInitials,
  validateMuseumHasAdmin,
  validateUserMuseumAdmin,
} from '../../lib';
import GreySpinner from '@images/spinner-grey.png';
import RedAlert from '@images/alert-red.png';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { getLastDateInCatalog } from 'components/museumComponents/ObjectTimeBadge';
import { getDaysLeftInCatalog } from '../../lib';
import { selectCurrentCycle } from 'selectors/cyclesSelectors';
import {
  useGetDonationsMuseumAccountQuery,
  useGetMuseumAccountUserQuery,
  useGetProposalsSimpleQuery,
} from 'requests/api';
import { useMuseumGetObjectSummariesInCurrentCycle } from 'components/_shared/useMuseumGetObjectSummariesInCurrentCycle';
import { cloneDeep } from 'lodash';
// import { isAfter, isBefore, parseISO } from 'date-fns';

export default function ProposalsActive() {
  const cyclesSorted = useSelector(state => state.cyclesState.cyclesSorted);
  const currentCycle = useSelector(state => selectCurrentCycle(state));
  const getListCuratorialRequest = useSelector(
    state => state.listsState.getListCuratorialRequest,
  );
  const getListsCuratorialPastRequest = useSelector(
    state => state.listsState.getListsCuratorialPastRequest,
  );
  const getListInterestRequest = useSelector(
    state => state.listsState.getListInterestRequest,
  );
  const getListsInterestPastRequest = useSelector(
    state => state.listsState.getListsInterestPastRequest,
  );
  const isMuseum = useSelector(selectIsMuseum);

  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const { data: donations, isLoading: isLoadingDonations } =
    useGetDonationsMuseumAccountQuery(museumAccount?.id, {
      skip: !museumAccount,
    });

  const listCuratorial = useSelector(state => state.listsState.listCuratorial);
  const listsCuratorialPast = useSelector(
    state => state.listsState.listsCuratorialPast,
  );

  const {
    activeObjectSummaries = [],
    inactiveObjectSummaries = [],
    isLoading: isLoadingObjectSummaries,
  } = useMuseumGetObjectSummariesInCurrentCycle();

  const { data: proposalsSimple } = useGetProposalsSimpleQuery(
    museumAccount?.id,
    { skip: !museumAccount },
  );

  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const dispatch = useDispatch();

  const setSelectedObject = payload => dispatch(propSetSelectedObject(payload));
  const setSelectedProposalId = payload =>
    dispatch(propSetSelectedProposalId(payload));

  useEffect(() => {
    if (token && user && museumAccount) {
      dispatch(
        getCurrentCycleLists(token, user.museum.museumId, museumAccount.kind),
      );
    }
    dispatch(propClearProposal());
    dispatch(propResetForm());
    setSelectedObject(null);
    setSelectedProposalId(null);
  }, [museumAccount]);

  useEffect(() => {
    return () => {
      dispatch(listsClearCuratorial());
      dispatch(listsClearCuratorialPast());
      dispatch(listsClearInterest());
      dispatch(listsClearInterestPast());
      dispatch(listsGetCuratorialClear());
      dispatch(listsGetCuratorialPastClear());
      dispatch(listsGetInterestClear());
      dispatch(listsGetInterestPastClear());
    };
  }, []);

  const makeProposalCard = ({ obj }) => {
    return (
      <div key={`my_obj_${obj.id}`} className="mcv-obj-lst-card d-flex">
        <img
          src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${obj.imageBucket}.s3.amazonaws.com/${obj.imageId}`}
          alt={`obj_${obj.id}`}
          className="mcv-obj-lst-card-img"
        />
        <div className="mcv-obj-lst-card-sec-1">
          <div className="mcv-obj-lst-card-sec-1-content">
            <div className="mcv-obj-lst-card-main-txt-container">
              <p className="mcv-obj-lst-card-txt gen-crop-txt-3">
                {obj.artistName}
              </p>
            </div>
            <p className="mcv-obj-lst-card-txt-sm">
              <span className="gen-crop-txt-2">
                <i>{obj.title}</i>
                {', '}
              </span>
              {obj.year}
            </p>
          </div>
        </div>
        {makeObjCardMiddle(obj)}
      </div>
    );
  };

  const makeObjCardMiddle = obj => {
    // Note: the clonedeep is because we were trying to mutate redux state. This was the quickest way around it but not ideal.
    const objectFullSummary = cloneDeep(
      getObjectFullSummary({
        objectSummaries: [
          ...activeObjectSummaries,
          // Note: need to include inactiveObjects for non-museum accounts. It's a bit of a workaround for this whole non-museum accounts setup.
          ...inactiveObjectSummaries,
        ],
        currentObject: obj,
      }),
    );

    if (!isMuseum) {
      // We have some extra parameters needed for objects in the ongoing cycle.
      objectFullSummary.daysLeft = getDaysLeftInCatalog({
        dateAddedToCatalog: objectFullSummary?.events?.approved?.timestamp,
        firstProposalDate: objectFullSummary?.firstProposalDate,
      });

      objectFullSummary.lastDateInCatalog = getLastDateInCatalog(
        objectFullSummary.daysLeft,
      );

      const lastDateInCatalog = getLastDateInCatalog(
        objectFullSummary.daysLeft,
      );

      objectFullSummary.donorReviewStartDate = addDays(lastDateInCatalog, 1);

      // TODO: We should really be filtering our items that are past the review end date entirely.
      objectFullSummary.donorReviewEndDate = addDays(
        objectFullSummary.donorReviewStartDate,
        ONGOING_CYCLE_POST_PROPOSAL_DAYS,
      );
    }

    const makeSectionThree = o => {
      return (
        <div className="mcv-obj-lst-card-sec-3 d-flex">
          <div className="mcv-obj-lst-card-initials-container">
            <p className="mcv-obj-lst-card-initials-txt">
              {getInitials(o.userName)}
            </p>
          </div>
          <div>
            <p className="mcv-obj-lst-card-txt-xsm">Added by</p>
            <p className="mcv-obj-lst-card-txt-sm">{o.userName}</p>
          </div>
        </div>
      );
    };

    if (proposalsSimple?.[obj.id]?.events) {
      const accountHasDonations = Array.isArray(donations) && donations.length;

      if (accountHasDonations) {
        const donation = donations?.find(
          donation => donation.objectId === obj.id,
        );

        if (donation && donation.events.acceptedComplete) {
          return (
            <React.Fragment>
              <div className="mcv-obj-lst-card-sec-2">
                {donation.events.confirmedComplete ? null : donation.events
                    .confirmed ? (
                  <div className="mcv-obj-lst-card-sec-2-content">
                    <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                      <p className="mcv-obj-lst-card-txt">
                        Acceptance Confirmed
                      </p>
                    </div>
                    <p className="mcv-obj-lst-card-txt-xsm">
                      Complete Donation Form
                    </p>
                  </div>
                ) : (
                  <div className="mcv-obj-lst-card-sec-2-content">
                    <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                      <p className="mcv-obj-lst-card-txt">
                        Proposal Accepted
                        <br />
                        by Donor
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {makeSectionThree(obj)}
              <div className="mcv-obj-lst-card-sec-4">
                <div className="mcv-obj-lst-card-sec-4-content">
                  {donation.events.confirmedComplete ? null : donation.events
                      .confirmed ? (
                    <Link
                      to={`/institution/donations/${donation.id}/match/complete`}
                      className="gen-link-no-decoration"
                      onClick={_ => {
                        setSelectedObject(obj);
                      }}
                    >
                      <div className="mcv-obj-lst-card-btn">
                        <p>RESUME FORM</p>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      to={`/institution/donations/${donation.id}/match/confirm`}
                      className="gen-link-no-decoration"
                      onClick={_ => {
                        setSelectedObject(obj);
                      }}
                    >
                      <div className="mcv-obj-lst-card-btn">
                        <p>CONFIRM DONATION</p>
                      </div>
                    </Link>
                  )}
                  <Link
                    to={`/institution/object/${obj.id}`}
                    className="gen-link"
                  >
                    <p className="mcv-obj-lst-card-txt-link text-center">
                      View Object Details
                    </p>
                  </Link>
                </div>
              </div>
            </React.Fragment>
          );
        }

        if (donation && donation.events.accepted) {
          return (
            <React.Fragment>
              <div className="mcv-obj-lst-card-sec-2">
                <div className="mcv-obj-lst-card-sec-2-content">
                  <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                    <p className="mcv-obj-lst-card-txt">Proposal Selected</p>
                  </div>
                  <p className="mcv-obj-lst-card-txt-xsm">
                    Awaiting donor information
                  </p>
                </div>
              </div>
              {makeSectionThree(obj)}
              <div className="mcv-obj-lst-card-sec-4">
                <div className="mcv-obj-lst-card-sec-4-content">
                  <Link
                    to="/institution/proposals/review"
                    className="gen-link-no-decoration"
                    onClick={_ => {
                      setSelectedObject(obj);
                      setSelectedProposalId(proposalsSimple[obj.id].id);
                    }}
                  >
                    <div className="mcv-obj-lst-card-btn">
                      <p>VIEW PROPOSAL</p>
                    </div>
                  </Link>
                  <Link
                    to={`/institution/object/${obj.id}`}
                    className="gen-link"
                  >
                    <p className="mcv-obj-lst-card-txt-link text-center">
                      View Object Details
                    </p>
                  </Link>
                </div>
              </div>
            </React.Fragment>
          );
        }
      }

      const objectIsSubmittedForDonorReview =
        proposalsSimple[obj.id].events.approved;

      if (objectIsSubmittedForDonorReview) {
        let dateString = '';

        if (currentCycle) {
          dateString = convertToMMDDYY(addDays(currentCycle.end, 2));
        }

        if (cyclesSorted && Array.isArray(cyclesSorted.past)) {
          const past = findCycleInCollection(
            proposalsSimple?.[obj.id]?.cycleId,
            cyclesSorted.past,
          );
          if (past) {
            dateString = convertToMMDDYY(addDays(past.end, 2));
          }
        }

        if (!isMuseum) {
          dateString = convertToMMDDYY(objectFullSummary.donorReviewStartDate);
        }

        return (
          <React.Fragment>
            <div className="mcv-obj-lst-card-sec-2">
              <div className="mcv-obj-lst-card-sec-2-content">
                <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                  <p className="mcv-obj-lst-card-txt">
                    Submitted for
                    <br />
                    Donor Review
                  </p>
                </div>
                {isMuseum ? (
                  <>
                    <p className="mcv-obj-lst-card-txt-xsm">
                      Donor review begins
                    </p>
                    <p className="mcv-obj-lst-card-txt-xsm">{dateString}</p>
                  </>
                ) : (
                  <>
                    <p className="mcv-obj-lst-card-txt-xsm">
                      Donor review period
                    </p>
                    <p className="mcv-obj-lst-card-txt-xsm">
                      {convertToMMDDYY(objectFullSummary.donorReviewStartDate)}{' '}
                      - {convertToMMDDYY(objectFullSummary.donorReviewEndDate)}
                    </p>
                  </>
                )}
              </div>
            </div>
            {makeSectionThree(obj)}
            <div className="mcv-obj-lst-card-sec-4">
              <div className="mcv-obj-lst-card-sec-4-content">
                <Link
                  to="/institution/proposals/review"
                  className="gen-link-no-decoration"
                  onClick={_ => {
                    setSelectedObject(obj);
                    setSelectedProposalId(proposalsSimple[obj.id].id);
                  }}
                >
                  <div className="mcv-obj-lst-card-btn">
                    <p>VIEW PROPOSAL</p>
                  </div>
                </Link>
                <Link to={`/institution/object/${obj.id}`} className="gen-link">
                  <p className="mcv-obj-lst-card-txt-link text-center">
                    View Object Details
                  </p>
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      }

      const isAwaitingAdminApproval =
        proposalsSimple[obj.id].events.draftSubmitted;

      if (isAwaitingAdminApproval) {
        const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

        let dateString = '';

        if (currentCycle) {
          dateString = convertToMMDDYY(addDays(currentCycle.end, 1));
        }

        if (!isMuseum) {
          dateString = convertToMMDDYY(objectFullSummary.lastDateInCatalog);
        }

        // Note: return to this, but we should really handle this logic before even showing it, and filter it out - should live in ProposalsHistory if in past.
        // const isPastDue = isPast(objectFullSummary.lastDateInCatalog);

        // if (isPastDue) {
        //   return null;
        // }

        return (
          <React.Fragment>
            <div className="mcv-obj-lst-card-sec-2">
              <div className="mcv-obj-lst-card-sec-2-content">
                <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                  <p className="mcv-obj-lst-card-txt">
                    Awaiting
                    <br />
                    Administrator
                    <br />
                    Approval
                  </p>
                </div>
                <p className="mcv-obj-lst-card-txt-xsm">
                  Submit your proposal by
                </p>
                <p className="mcv-obj-lst-card-txt-xsm">{dateString}</p>
              </div>
            </div>
            {makeSectionThree(obj)}
            <div className="mcv-obj-lst-card-sec-4">
              <div className="mcv-obj-lst-card-sec-4-content">
                <Link
                  to="/institution/proposals/review"
                  className="gen-link-no-decoration"
                  onClick={_ => {
                    setSelectedObject(obj);
                    setSelectedProposalId(proposalsSimple[obj.id].id);
                  }}
                >
                  {userIsAdmin ? (
                    <div className="mcv-obj-lst-card-btn mcv-obj-lst-card-btn-two-lines">
                      <p>
                        REVIEW & SUBMIT
                        <br />
                        PROPOSAL
                      </p>
                    </div>
                  ) : (
                    <div className="mcv-obj-lst-card-btn">
                      <p>VIEW PROPOSAL</p>
                    </div>
                  )}
                </Link>
                <Link to={`/institution/object/${obj.id}`} className="gen-link">
                  <p className="mcv-obj-lst-card-txt-link text-center">
                    View Object Details
                  </p>
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      }

      const isProposalDraftInProgress =
        proposalsSimple[obj.id].events.draftCreated;

      if (isProposalDraftInProgress) {
        let dateString = '';

        if (currentCycle) {
          dateString = convertToMMDDYY(addDays(currentCycle.end, 1));
        }

        if (!isMuseum) {
          dateString = convertToMMDDYY(objectFullSummary.lastDateInCatalog);
        }

        return (
          <React.Fragment>
            <div className="mcv-obj-lst-card-sec-2">
              <div className="mcv-obj-lst-card-sec-2-content">
                <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                  <p className="mcv-obj-lst-card-txt">Draft in Progress</p>
                </div>
                <p className="mcv-obj-lst-card-txt-xsm">
                  Submit your proposal by
                </p>
                <p className="mcv-obj-lst-card-txt-xsm">{dateString}</p>
              </div>
            </div>
            {makeSectionThree(obj)}
            <div className="mcv-obj-lst-card-sec-4">
              <div className="mcv-obj-lst-card-sec-4-content">
                <Link
                  to={`/institution/proposals/edit?objectId=${
                    obj.id
                  }&proposalId=${proposalsSimple[obj.id].id}`}
                  className="gen-link-no-decoration"
                  onClick={_ => {
                    setSelectedProposalId(proposalsSimple[obj.id].id);
                  }}
                >
                  <div className="mcv-obj-lst-card-btn">
                    <p>RESUME PROPOSAL</p>
                  </div>
                </Link>
                <Link to={`/institution/object/${obj.id}`} className="gen-link">
                  <p className="mcv-obj-lst-card-txt-link text-center">
                    View Object Details
                  </p>
                </Link>
              </div>
            </div>
          </React.Fragment>
        );
      }
    } else {
      let dateString = '';

      if (currentCycle) {
        dateString = convertToMMDDYY(addDays(currentCycle.end, 1));
      }

      if (!isMuseum) {
        dateString = convertToMMDDYY(objectFullSummary.lastDateInCatalog);
      }

      return (
        <React.Fragment>
          <div className="mcv-obj-lst-card-sec-2">
            <div className="mcv-obj-lst-card-sec-2-content">
              <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                <p className="mcv-obj-lst-card-txt">
                  Approved for
                  <br />
                  Proposal Creation
                </p>
              </div>
              <p className="mcv-obj-lst-card-txt-xsm">
                Submit your proposal by
              </p>
              <p className="mcv-obj-lst-card-txt-xsm">{dateString}</p>
            </div>
          </div>
          {makeSectionThree(obj)}
          <div className="mcv-obj-lst-card-sec-4">
            <div className="mcv-obj-lst-card-sec-4-content">
              <Link
                to={`/institution/proposals/edit?objectId=${obj.id}`}
                className="gen-link-no-decoration"
              >
                <div className="mcv-obj-lst-card-btn">
                  <p>BEGIN PROPOSAL</p>
                </div>
              </Link>
              <Link to={`/institution/object/${obj.id}`} className="gen-link">
                <p className="mcv-obj-lst-card-txt-link text-center">
                  View Object Details
                </p>
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const makeContent = _ => {
    const approvedCuratorialObjects = listCuratorial?.objects
      ? Object.values(listCuratorial.objects).filter(val => val.approved)
      : [];

    const filteredApprovedCuratorialObjects = approvedCuratorialObjects.filter(
      obj => {
        // Note: Need to filter out items that don't show up in the catalogs.
        // See related ticket in notion: https://www.notion.so/Temp-fix-Filter-out-proposals-if-the-object-has-been-removed-from-the-catalogue-40c702e61df24725b548d40653378273
        return !!getObjectFullSummary({
          objectSummaries: [
            ...activeObjectSummaries,
            // Note: need to include inactiveObjects for non-museum accounts. It's a bit of a workaround for this whole non-museum accounts setup.
            ...inactiveObjectSummaries,
          ],
          currentObject: obj,
        });
      },
    );

    const curatorialPastLists = listsCuratorialPast
      ? Object.values(listsCuratorialPast)
      : [];

    const approvedCuratorialObjectsPast = curatorialPastLists.reduce(
      (prev, current) => {
        const currentObjectsArr = current?.objects
          ? Object.values(current?.objects).filter(val => val.approved)
          : [];

        return [...prev, ...currentObjectsArr];
      },
      [],
    );

    const filteredPastObjs = approvedCuratorialObjectsPast
      .filter(obj =>
        filterProposalsThatAreNotApprovedOrAccepted({
          obj,
          proposalsSimple: proposalsSimple,
        }),
      )
      .filter(obj =>
        filterFullyConfirmedDonations({ obj, donations: donations }),
      )
      .filter(obj =>
        filterOutObjectsPastSubmissionDeadline({
          obj,
          cyclesSorted,
          proposalsSimple,
          isMuseum,
          donations,
        }),
      )
      .filter(obj => {
        // Note: I think we only want this condition for non-museum institutions.
        if (isMuseum) return true;

        // Note: Need to filter out items that don't show up in the catalogs.
        // See related ticket in notion: https://www.notion.so/Temp-fix-Filter-out-proposals-if-the-object-has-been-removed-from-the-catalogue-40c702e61df24725b548d40653378273
        return !!getObjectFullSummary({
          objectSummaries: [
            ...activeObjectSummaries,
            // Note: need to include inactiveObjects for non-museum accounts. It's a bit of a workaround for this whole non-museum accounts setup.
            ...inactiveObjectSummaries,
          ],
          currentObject: obj,
        });
      });

    if (
      !filteredApprovedCuratorialObjects?.length &&
      !filteredPastObjs?.length
    ) {
      return (
        <div className="mcv-empty-container">
          <p className="mcv-empty-txt">No Objects</p>
        </div>
      );
    }

    return (
      <React.Fragment>
        {filteredApprovedCuratorialObjects.map(obj =>
          makeProposalCard({ obj }),
        )}
        {filteredPastObjs.map(obj => makeProposalCard({ obj }))}
      </React.Fragment>
    );
  };

  if (
    getListCuratorialRequest.loading ||
    getListsCuratorialPastRequest.loading ||
    getListInterestRequest.loading ||
    getListsInterestPastRequest.loading ||
    isLoadingObjectSummaries ||
    isLoadingMuseumAccount ||
    isLoadingDonations
  ) {
    return <div className="gen-background-nav-museum"></div>;
  }

  if (!listCuratorial || !museumAccount) {
    return <div className="gen-background-nav-museum"></div>;
  }

  const museumHasAdmin = validateMuseumHasAdmin(museumAccount);

  return (
    <div className="gen-background-nav-museum">
      <div className="mcv-content mx-auto mex-mb-30">
        <div>
          <div className="mcv-header d-flex">
            <img
              src={GreySpinner}
              alt="grey-spinner"
              className="mcv-header-icon-1"
            />
            <div>
              <div className="mcv-header-super-txt">
                <p className="txt-16">{isMuseum ? 'MUSEUM' : 'INSTITUTION'}</p>
              </div>
              <p className="txt-header tw-mb-2">Active Proposals</p>
              {!museumHasAdmin ? (
                <div className="tw-flex tw-flex-col tw-space-y-1">
                  <p className="txt-14 lst-left-note-container-txt lst-left-note-container-txt-alert tw-flex tw-items-center">
                    <img
                      src={RedAlert}
                      alt="red-alert"
                      className="lst-left-icon-red-alert"
                    />
                    <span>
                      YOUR INSTITUTION DOES NOT HAVE ANY ADMIN ACCOUNTS YET.
                    </span>
                  </p>
                  <p className="txt-14 lst-left-note-container-txt tw-max-w-md">
                    Only admin members of your institution can approve a
                    proposal for donor submission.{' '}
                    <a
                      href="mailto:museum_liaison@museumexchange.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="gen-link"
                    >
                      Contact us
                    </a>{' '}
                    to assign an admin for your institution.
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          {makeContent()}
        </div>
      </div>
    </div>
  );
}

function getObjectFullSummary({ objectSummaries, currentObject }) {
  return objectSummaries?.find(
    objectSummary => objectSummary.id === currentObject.id,
  );
}

function filterProposalsThatAreNotApprovedOrAccepted({ obj, proposalsSimple }) {
  if (proposalsSimple && typeof proposalsSimple === 'object') {
    if (
      proposalsSimple[obj.id] &&
      !['approved', 'accepted'].includes(proposalsSimple[obj.id].status)
    ) {
      return false;
    }

    if (!proposalsSimple[obj.id]) {
      return false;
    }
  }
  return true;
}

function filterFullyConfirmedDonations({ obj, donations }) {
  if (donations?.length) {
    const donation = donations.find(donation => {
      return donation.objectId === obj.id;
    });

    if (donation && donation.events.confirmedComplete) {
      return false;
    }
  }

  return true;
}

function filterOutObjectsPastSubmissionDeadline({
  obj,
  cyclesSorted,
  proposalsSimple,
  isMuseum,
}) {
  // This should only affect museums because they are constrained to cycle cutoff and end dates.
  if (!isMuseum) return true;

  const proposal = proposalsSimple?.[obj.id];

  // We don't need to filter it out if the proposal is already submitted for donor review (proposal.events.approved), but hasn't been accepted.
  if (proposal?.events?.approved) return true;

  // We don't want to show items from the current cycle that are not ready yet for proposal submissions.
  const pastCycle = findCycleInCollection(proposal?.cycleId, cyclesSorted.past);

  // Note: I don't think we really need this check, since any pastCycle item would meet this criteria. Leaving in for now.
  // function getCanSubmitProposal(cycle) {
  //   return (
  //     // Same logic as back-end for ValidateCycleCurrent which happens on proposal submission.
  //     isBefore(addDays(parseISO(cycle.cutoff), 1), new Date()) &&
  //     isAfter(addDays(parseISO(cycle.end), 1), new Date())
  //   );
  // }

  // if it's an object from a past cycle, where no proposal has been submitted (proposal.events.approved), and we're past the proposal submission deadline, then filter it.
  if (
    pastCycle &&
    !proposal?.events?.approved
    // && !getCanSubmitProposal(pastCycle)
  ) {
    return false;
  }

  return true;
}
