import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  msetPutMuseumClear,
  msetResetForm,
  msetUpdateFormErrors,
  msetUpdateName,
} from '../../actions';
import { putMuseum } from '../../requests';
import BackIcon from '../../images/arrow-back.png';
import MuseumIcon from '../../images/header-museum.png';
import '../../styles/fields.css';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/museum-settings.css';
import '../../styles/settings.css';
import '../../styles/text.css';
import { selectMuseumAccount } from 'features/museumAccounts/museumAccountsSlice';

class MuseumInfoName extends Component {
  static get propTypes() {
    return {
      account: PropTypes.object,
      formErrors: PropTypes.object,
      name: PropTypes.string,
      putMuseum: PropTypes.func,
      putMuseumClear: PropTypes.func,
      putMuseumClearRequest: PropTypes.func,
      resetForm: PropTypes.func,
      token: PropTypes.object,
      updateFormErrors: PropTypes.func,
      updateName: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.putMuseumClear();
    this.props.resetForm();
  }

  handleSaveClick = _ => {
    if (this.props.putMuseumRequest.loading) {
      return;
    }

    const formErrors = {
      formError: false,
      nameError: false,
      locationCityError: false,
      locationStateError: false,
      websiteURLError: false,
    };

    if (!this.props.name) {
      formErrors.formError = true;
      formErrors.nameError = true;
    }

    this.props.updateFormErrors({ formErrors });

    if (formErrors.formError) {
      return;
    }

    this.props.putMuseum(this.props.token, this.props.account.id, {
      name: this.props.name,
    });
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Name</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-label-txt">CURRENT NAME</p>
            <div className="set-info-edit-current-val-container">
              <p className="set-info-txt">{this.props.account.name}</p>
            </div>
            <p className="set-label-txt">NEW NAME</p>
            <input
              type="text"
              className={`set-info-edit-field ${
                this.props.formErrors.nameError ? 'fd-input-error-field' : ''
              }`}
              maxLength="100"
              value={this.props.name}
              onChange={e => this.props.updateName({ name: e.target.value })}
            />
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putMuseumRequest.data) {
      return <Redirect to="/institution/settings/general" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <img src={MuseumIcon} alt="museum" className="mas-museum-icon" />
            <p className="txt-header">Institution Information</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: selectMuseumAccount(state),
    formErrors: state.museumSettingsState.formErrors,
    name: state.museumSettingsState.formData.name,
    putMuseumRequest: state.museumSettingsState.putMuseumRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putMuseum: (token, museumId, data) =>
      dispatch(putMuseum(token, museumId, data)),
    putMuseumClear: _ => dispatch(msetPutMuseumClear()),
    resetForm: _ => dispatch(msetResetForm()),
    updateFormErrors: payload => dispatch(msetUpdateFormErrors(payload)),
    updateName: payload => dispatch(msetUpdateName(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumInfoName);
