import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  postTelResendClear,
  getUserClear,
  putTelClear,
  telResetConfirm,
  telUpdateCode,
  telUpdateConfirmErrors,
  userClearTel,
} from '../../actions';
import { getUser, postTelResend, putTel } from '../../requests';
import { sanitizeCode } from '../../lib';

class ConfirmTel extends Component {
  static get propTypes() {
    return {
      clearTel: PropTypes.func,
      code: PropTypes.string,
      formErrors: PropTypes.object,
      getUser: PropTypes.func,
      getUserClear: PropTypes.func,
      getUserRequest: PropTypes.object,
      postTelResend: PropTypes.func,
      postTelResendClear: PropTypes.func,
      postTelResendRequest: PropTypes.object,
      putTel: PropTypes.func,
      putTelClear: PropTypes.func,
      putTelRequest: PropTypes.object,
      resetConfirm: PropTypes.func,
      telObj: PropTypes.object,
      token: PropTypes.object,
      updateCode: PropTypes.func,
      updateConfirmErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.postTelResendClear();
    this.props.resetConfirm();
    this.props.putTelClear();
    this.props.getUserClear();
    this.props.clearTel();
  }

  handleResendRequest = event => {
    if (this.props.postTelResendRequest.loading) {
      return;
    }

    this.props.postTelResend(this.props.token, this.props.telObj);
  };

  handleUpdateCode = event => {
    this.props.updateCode({ code: event.target.value });
  };

  gettingUser = false;

  validateFormAndSubmit = _ => {
    if (this.props.putTelRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      codeError: false,
    };

    if (sanitizeCode(this.props.code).length !== 6) {
      errors.formError = true;
      errors.codeError = true;
    }

    this.props.updateConfirmErrors({ confirmFormErrors: errors });

    if (errors.formError) {
      return;
    }

    this.props.putTel(this.props.token, {
      code: sanitizeCode(this.props.code),
      telId: this.props.telObj.id,
    });
  };

  render() {
    if (
      this.props.token &&
      this.props.putTelRequest.data &&
      !this.props.putTelRequest.loading &&
      !this.props.getUserRequest.loading &&
      !this.props.getUserRequest.error &&
      !this.gettingUser
    ) {
      this.gettingUser = true;
      this.props.getUser(this.props.token);
    }

    if (
      this.props.putTelRequest.data &&
      this.props.getUserRequest.data &&
      this.props.user &&
      this.props.token
    ) {
      if (this.props.token.role === 'donor') {
        return <Redirect push to="/donor" />;
      } else if (this.props.token.role === 'museum') {
        return <Redirect push to="/institution" />;
      }
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <p className="header text-center mex-mt-47">
          Confirm Your Mobile Number
        </p>
        <div className="sub-header mx-auto mex-mb-30">
          <p className="text text-center">
            Please enter the six-digit verification code sent via SMS
          </p>
        </div>
        <div>
          <div className="misc-content mx-auto">
            <div className="misc-content-inner mx-auto">
              <div className="mex-mb-26">
                <p
                  className={`label ${
                    this.props.formErrors.codeError ? 'label-error' : ''
                  }`}
                >
                  VERIFICATION CODE
                </p>
                <input
                  className={`input ${
                    this.props.formErrors.codeError ? 'input-error' : ''
                  }`}
                  type="input"
                  value={this.props.code}
                  onChange={this.handleUpdateCode}
                  placeholder="Verification Code"
                ></input>
              </div>
              <button
                className={`btn btn-primary mex-btn-primary mex-btn-primary-fill ${
                  !this.props.putTelRequest.error ? 'mex-mb-12' : ''
                }`}
                style={{ width: '100%' }}
                onClick={this.validateFormAndSubmit}
              >
                Submit
              </button>
              {this.props.putTelRequest.error && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                  Please try again.
                </div>
              )}
              <p className="text">
                If you have not received a verification code via SMS,{' '}
                <button
                  className="naked-btn link"
                  onClick={_ => this.handleResendRequest()}
                >
                  click here
                </button>{' '}
                to have it resent
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    code: state.telState.confirmFormData.code,
    formErrors: state.telState.confirmFormErrors,
    getUserRequest: state.userState.getUserRequest,
    postTelResendRequest: state.userState.postTelResendRequest,
    putTelRequest: state.userState.putTelRequest,
    telObj: state.userState.telObj,
    user: state.userState.user,
    token: state.authState.token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearTel: _ => dispatch(userClearTel()),
    getUser: payload => dispatch(getUser(payload)),
    getUserClear: _ => dispatch(getUserClear()),
    postTelResend: (token, payload) => dispatch(postTelResend(token, payload)),
    postTelResendClear: _ => dispatch(postTelResendClear()),
    putTel: (token, payload) => dispatch(putTel(token, payload)),
    putTelClear: _ => dispatch(putTelClear()),
    resetConfirm: _ => dispatch(telResetConfirm()),
    updateCode: payload => dispatch(telUpdateCode(payload)),
    updateConfirmErrors: payload => dispatch(telUpdateConfirmErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmTel);
