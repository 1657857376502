import { BASE_URL, IMAGE_UPLOAD_TIMEOUT } from '@config';
import { sortByArtistLastName, sortByCollection } from '@lib';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import { macctActions } from 'features/museumAccounts/museumAccountsSlice';

export const API_TAGS = {
  DonationConservatorReport: 'DonationConservatorReport',
  DonationDeed: 'DonationDeed',

  DonorAccount: 'DonorAccount',
  DonorAccountObjects: 'DonorAccountObjects',
  DonorAccountActiveObjects: 'DonorAccountActiveObjects',
  DonorAccountInactiveObjects: 'DonorAccountInactiveObjects',
  DonorAccountUsersList: 'DonorAccountUsersList',
  DonorAccountNetwork: 'DonorAccountNetwork',

  DonorProposals: 'DonorProposals',

  DonorDonations: 'DonorDonations',
  DonorDonationConditionReport: 'DonorDonationConditionReport',

  Cycles: 'Cycles',

  MuseumAccounts: 'MuseumAccounts',
  MuseumObjects: 'MuseumObjects',
  MuseumAccountUsersList: 'MuseumAccountUsersList',

  MuseumUsers: 'MuseumUsers',
  MuseumDonations: 'MuseumDonations',
  MuseumDonationsWithObjects: 'MuseumDonationsWithObjects',

  MuseumProposals: 'MuseumProposals',
};

const baseBq = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const UPLOAD_ENDPOINTS = [
      'putDonationShippingReceipt',
      'putDonationDeedDeed',
      'putDonationConservatorReport',
      'putDonationLoanLoan',
      'putDonationConditionReportMatch',
    ];

    if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
      headers.set('content-type', 'application/json');
    }

    const token = getState().authState?.token?.id;
    if (token) {
      headers.set('x-api-token', token);
    }
    return headers;
  },
});

const baseQuery = (args, api, extraOptions) => {
  // Note: see discussion for why we set baseQuery/timeout this way. https://github.com/reduxjs/redux-toolkit/discussions/2079
  // Eventually this is set to be included in redux-toolkit.
  return Promise.race([
    baseBq(args, api, extraOptions),
    new Promise(resolve =>
      setTimeout(
        () => resolve({ error: 'timed out' }),
        extraOptions?.timeout ?? 10000,
      ),
    ),
  ]);
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  refetchOnFocus: true,
  tagTypes: Object.values(API_TAGS),
  endpoints: () => ({}),
});

export const { usePrefetch } = api;

// DONOR ACCOUNTS
export const donorAccountsApi = api.injectEndpoints({
  endpoints: build => ({
    getDonorAccounts: build.query({
      query: userId => ({
        url: `/donors/accounts/users/${userId}`,
      }),
      transformResponse: (response, meta, arg) => response.accounts,
      providesTags: (results = [], error, arg) => {
        return [
          API_TAGS.DonorAccount,
          { type: API_TAGS.DonorAccount, id: 'LIST' },
        ];
      },
    }),

    getDonorAccount: build.query({
      query: accountId => ({
        url: `/donors/accounts/${accountId}`,
      }),
      providesTags: (result, error, accountId) => [
        API_TAGS.DonorAccount,
        { type: API_TAGS.DonorAccount, id: accountId },
      ],
    }),

    getDonorAccountGeneral: build.query({
      query: () => ({
        url: '/donors/accounts/general',
      }),
      providesTags: (result, error, arg) => [
        'DonorAccount',
        // Note: should this just be the id of the account?
        { type: API_TAGS.DonorAccount, id: 'GENERAL' },
      ],
    }),

    getDonorAccountActiveObjects: build.query({
      query: accountId => ({
        url: `/donors/accounts/${accountId}/objects/status/active`,
      }),
      transformResponse: (result, error, args) => result.objects,
      providesTags: (result, error, accountId) => [
        { type: API_TAGS.DonorAccountObjects, id: accountId },
        { type: API_TAGS.DonorAccountActiveObjects, id: accountId },
      ],
    }),

    getDonorAccountInactiveObjects: build.query({
      query: accountId => ({
        url: `/donors/accounts/${accountId}/objects/status/inactive`,
      }),
      transformResponse: (result, error, args) => result.objects,
      providesTags: (result, error, accountId) => [
        { type: API_TAGS.DonorAccountObjects, id: accountId },
        { type: API_TAGS.DonorAccountInactiveObjects, accountId },
      ],
    }),

    getDonorAccountUsers: build.query({
      query: accountId => ({
        url: `/donors/accounts/${accountId}/users`,
      }),
      providesTags: (result, error, accountId) => [
        { type: API_TAGS.DonorAccountUsersList, id: accountId },
      ],
    }),

    getNetwork: build.query({
      query: userId => ({
        url: `/donors/accounts/users/${userId}/network`,
      }),
      providesTags: (result, error) => [API_TAGS.DonorAccountNetwork],
    }),

    postDonorAccount: build.mutation({
      query: data => ({
        url: `/donors/accounts`,
        method: 'POST',
        body: data,
      }),
      // Note: I don't think we need to provide any tags here
      // providesTags: (result, error, arg) => [
      //   { type: API_TAGS.DonorAccount, id: result.id },
      // ],
      invalidatesTags: [{ type: API_TAGS.DonorAccount, id: 'LIST' }],
    }),

    putDonorAccount: build.mutation({
      query: ({ accountId, ...patch }) => ({
        url: `/donors/accounts/${accountId}/users`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { accountId }) => [
        // Note: not sure if this actually needs to invalidate the network? TBD
        API_TAGS.DonorAccountNetwork,
        { type: API_TAGS.DonorAccount, id: accountId },
        { type: API_TAGS.DonorAccountUsersList, id: accountId },
      ],
      async onQueryStarted({ action }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          switch (action) {
            case 'remove': {
              dispatch(
                dacctActions.dacctSetSuccessMessageWithTimeout({
                  message: 'Folder access removed',
                }),
              );
              break;
            }
            case 'update': {
              dispatch(
                dacctActions.dacctSetSuccessMessageWithTimeout({
                  message: 'Member access updated',
                }),
              );
              break;
            }
            case 'invite': {
              dispatch(
                dacctActions.dacctSetSuccessMessageWithTimeout({
                  message: 'Person added',
                }),
              );
              break;
            }
          }
        } catch (error) {}
      },
    }),

    putMoveObject: build.mutation({
      query: ({ accountId, objectId, destId }) => ({
        url: `/donors/accounts/${accountId}/objects/${objectId}/move/${destId}`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: (result, error, { accountId, destId }) => [
        // Note: this could just invalidate active or inactive ones depending on which one it is?
        { type: API_TAGS.DonorAccount, id: 'LIST' },
        { type: API_TAGS.DonorAccountObjects, id: accountId },
        { type: API_TAGS.DonorAccountObjects, id: destId },
      ],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // TODO: this could all move to local component state
          dispatch(dacctActions.dacctSetDestFolder(null));
          dispatch(dacctActions.dacctSetMoveModalOpen(false));

          dispatch(
            dacctActions.dacctSetSuccessMessageWithTimeout({
              message: 'Object has been moved',
            }),
          );
        } catch {}
      },
    }),

    putTransferObject: build.mutation({
      query: ({ objectId, data, acountId }) => ({
        url: `/objects/${objectId}/move`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { accountId }) => [
        { type: API_TAGS.DonorAccount, id: 'LIST' },
        { type: API_TAGS.DonorAccountObjects, id: accountId },
      ],
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          // TODO: should move these all to local state.
          dispatch(dacctActions.dacctSetTransferEmailModalOpen(false));
          dispatch(dacctActions.dacctSetSelectedObject(null));
          dispatch(dacctActions.dacctSetTransferModalOpen(false));
          dispatch(dacctActions.dacctSetTransferTarget(null));
          dispatch(dacctActions.dacctResetTransferEmail());

          if (response.data === 'invitation requested') {
            dispatch(
              dacctActions.dacctSetSuccessMessageWithTimeout({
                message: 'Transfer request submitted',
              }),
            );
          } else {
            dispatch(
              dacctActions.dacctSetSuccessMessageWithTimeout({
                message: 'Object ownership has been transferred',
              }),
            );
          }
        } catch {}
      },
    }),

    renameAccount: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/donors/accounts/${id}/name`,
        method: 'PUT',
        body: patch,
      }),
      providesTags: (result, error, { id }) => [{ type: 'DonorAccount', id }],
      invalidatesTags: (result, error, { id }) => [
        { type: API_TAGS.DonorAccount, id: 'LIST' },
        { type: API_TAGS.DonorAccount, id: id },
      ],
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          dispatch(
            dacctActions.dacctSetSuccessMessageWithTimeout({
              message: `Renamed folder "${response.data.name}"`,
            }),
          );
        } catch {}
      },
    }),

    deleteAccount: build.mutation({
      query: ({ id }) => ({
        url: `/donors/accounts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: API_TAGS.DonorAccount, id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          dispatch(
            dacctActions.dacctSetSuccessMessageWithTimeout({
              message: `Deleted folder "${response.data.name}"`,
            }),
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetDonorAccountsQuery,
  useGetDonorAccountQuery,
  useGetDonorAccountGeneralQuery,
  useDeleteAccountMutation,
  useRenameAccountMutation,
  useGetDonorAccountUsersQuery,
  useGetDonorAccountActiveObjectsQuery,
  useGetDonorAccountInactiveObjectsQuery,
  useGetNetworkQuery,
  usePostDonorAccountMutation,
  usePutDonorAccountMutation,
  usePutTransferObjectMutation,
  usePutMoveObjectMutation,
} = donorAccountsApi;

// DONOR PROPOSALS
export const donorProposalsApi = api.injectEndpoints({
  endpoints: build => ({
    getProposalsDonorAccount: build.query({
      query: accountId => ({
        url: `/proposals/donors/accounts/${accountId}`,
      }),
      providesTags: (results = {}, error, accountId) => {
        return [
          API_TAGS.DonorProposals,
          { type: API_TAGS.DonorProposals, id: accountId },
          ...Object.keys(results).map(id => ({
            type: API_TAGS.DonorProposals,
            id,
          })),
        ];
      },
    }),
  }),
});
export const { useGetProposalsDonorAccountQuery } = donorProposalsApi;

// DONOR DONATIONS
export const donorDonationsApi = api.injectEndpoints({
  endpoints: build => ({
    getDonorDonations: build.query({
      query: accountId => ({
        url: `/donations/donors/accounts/${accountId}`,
      }),
      providesTags: (results = {}, error, accountId) => {
        return [
          API_TAGS.DonorDonations,
          { type: API_TAGS.DonorDonations, id: 'LIST' },
        ];
      },
    }),

    getDonationConditionReportMatch: build.query({
      query: donationId => ({
        url: `/donations/${donationId}/donors/match/report/condition`,
        responseHandler: 'text',
        // responseHandler: (response) => response.blob().then(blob => URL.createObjectURL(blob))
      }),

      providesTags: (results = {}, error, donationId) => {
        return [
          API_TAGS.DonorDonationConditionReport,
          { type: API_TAGS.DonorDonationConditionReport, id: donationId },
        ];
      },
    }),

    deleteDonationConditionReportMatch: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/donors/match/report/condition`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: (result, error, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonationConditionReport, id: donationId },
      ],
    }),

    postDonation: build.mutation({
      query: data => ({
        url: `/donations`,
        method: 'POST',
        body: data,
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],
    }),

    putDonationDonorMatchOne: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/donors/match/1`,
        method: 'PUT',
        body: data,
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],

      // Note: previously did this: how to replicate?
      // dispatch(ddonmSetFormOne(matchOneParsePayload(response.data)));
    }),

    putDonationDonorMatchComplete: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/donors/match/complete`,
        method: 'PUT',
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [
        API_TAGS.DonorProposals,
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
      onQueryStarted: sendMessageOnSuccess({ message: 'Donation accepted' }),
    }),

    putDonationPreliminaryObject: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/donors/preliminary/object`,
        method: 'PUT',
        body: data,
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],
      onQueryStarted: sendMessageOnSuccess({
        message: 'Object information submitted',
      }),
    }),

    putDonationPreliminaryShipping: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/donors/preliminary/shipping`,
        method: 'PUT',
        body: data,
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],
      onQueryStarted: sendMessageOnSuccess({
        message: 'Shipping information submitted',
      }),
    }),

    putDonationShippingInfoConfirm: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/shipping/info/confirm/donor`,
        method: 'PUT',
        body: data,
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],
      onQueryStarted: sendMessageOnSuccess({
        message: 'Pick Up Approved',
      }),
    }),

    putDonationShippingInfoDecline: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/shipping/info/decline/donor`,
        method: 'PUT',
        body: data,
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],
      onQueryStarted: sendMessageOnSuccess({
        message: 'Pick Up Declined',
      }),
    }),

    putDonationAppraisalAppraiserApprove: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/appraisal/appraiser/approve`,
        method: 'PUT',
      }),
      // Note: if we pass the account id, or if it is returned in the result, we could avoid "LIST", and use the account id. But this should be fine for now.
      invalidatesTags: [{ type: API_TAGS.DonorDonations, id: 'LIST' }],
      onQueryStarted: sendMessageOnSuccess({
        message: 'Appraiser Approved',
      }),
    }),

    putDonationAppraisalApprove: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/appraisal/approve`,
        // TODO: set a timeout when issue resolved: https://github.com/reduxjs/redux-toolkit/pull/2143
        // related: https://github.com/reduxjs/redux-toolkit/discussions/2079
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),
  }),
});
export const {
  useGetDonorDonationsQuery,
  usePostDonationMutation,
  useLazyGetDonationConditionReportMatchQuery,
  useDeleteDonationConditionReportMatchMutation,
  usePutDonationDonorMatchOneMutation,
  usePutDonationDonorMatchCompleteMutation,
  usePutDonationPreliminaryObjectMutation,
  usePutDonationPreliminaryShippingMutation,
  usePutDonationShippingInfoConfirmMutation,
  usePutDonationShippingInfoDeclineMutation,
  usePutDonationAppraisalAppraiserApproveMutation,
  usePutDonationAppraisalApproveMutation,
} = donorDonationsApi;

// MUSEUM DONATIONS
export const museumDonationsApi = api.injectEndpoints({
  endpoints: build => ({
    getDonationsMuseumAccount: build.query({
      query: accountId => ({
        url: `/donations/museums/accounts/${accountId}`,
      }),
      providesTags: (results = {}, error, accountId) => {
        return [
          API_TAGS.MuseumDonations,
          { type: API_TAGS.MuseumDonations, id: 'LIST' },
        ];
      },
    }),
    getDonationsWithObjectsMuseumAccount: build.query({
      query: accountId => ({
        url: `/donations/museums/accounts/${accountId}`,
        params: { objects: true },
      }),
      providesTags: (results = {}, error, accountId) => {
        return [
          API_TAGS.MuseumDonationsWithObjects,
          { type: API_TAGS.MuseumDonationsWithObjects, id: 'LIST' },
        ];
      },
    }),

    // Note: this step has been merged into "complete" - used to be separate. We can delete this in the future, keeping in case we change back.
    // putDonationMuseumMatchConfirm: build.mutation({
    //   query: ({ donationId }) => ({
    //     url: `/donations/${donationId}/museums/match/confirm`,
    //     method: 'PUT',
    //     body: {},
    //   }),
    //   invalidatesTags: [API_TAGS.MuseumDonations, { type: API_TAGS.MuseumDonations, id: 'LIST' }],
    // }),

    putDonationMuseumMatchComplete: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/museums/match/complete`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        API_TAGS.MuseumDonations,
        API_TAGS.MuseumDonationsWithObjects,
        API_TAGS.MuseumProposals,
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.MuseumDonationsWithObjects, id: 'LIST' },
      ],
      onQueryStarted: sendMessageOnSuccess({
        message: 'Donation Confirmed',
      }),
    }),

    putDonationMuseumPreshipping: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/museums/preshipping`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [{ type: API_TAGS.MuseumDonations, id: 'LIST' }],
    }),

    putDonationMuseumApprovalDates: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/museums/approval/dates`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [{ type: API_TAGS.MuseumDonations, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDonationsMuseumAccountQuery,
  useGetDonationsWithObjectsMuseumAccountQuery,
  // usePutDonationMuseumMatchConfirmMutation,
  usePutDonationMuseumMatchCompleteMutation,
  usePutDonationMuseumPreshippingMutation,
  usePutDonationMuseumApprovalDatesMutation,
} = museumDonationsApi;

// DONATIONS
export const donationsApi = api.injectEndpoints({
  endpoints: build => ({
    deleteDonationConservatorReport: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/conservator/report`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        ({ type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
        { type: API_TAGS.DonationConservatorReport, id: donationId }),
      ],
    }),

    deleteDonationDeedDeed: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/deed/deed`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        ({ type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
        { type: API_TAGS.DonationDeed, id: donationId }),
      ],
    }),

    deleteDonationLoanLoan: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/loan/loan`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
        // Note: we may not need these ones since they are only ever "lazy" fetched.
        // { type: API_TAGS.DonationLoan, id: donationId }),
      ],
    }),

    deleteDonationShippingReceipt: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/shipping/receipt`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
        // Note: we may not need these ones since they are only ever "lazy" fetched.
        // { type: API_TAGS.DonationLoan, id: donationId }),
      ],
    }),

    getDonations: build.query({
      query: ({ account, active, exclude, hasEvent, sort, limit }) => ({
        url: `/donations/`,
        params: { account, active, exclude, hasEvent, sort, limit },
      }),
    }),

    getDonationAppraisalReport: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/appraisal/report`,
        responseHandler: 'text',
      }),
    }),

    getDonationConservatorReport: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/conservator/report`,
        responseHandler: 'text',
      }),
    }),

    getDonationDeedDeed: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/deed/deed`,
        responseHandler: 'text',
      }),
    }),

    getDonationDeedFinal: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/deed/final`,
        responseHandler: 'text',
      }),
    }),

    getDonationFormIRSForm: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/form/irs/form`,
        responseHandler: 'text',
      }),
    }),

    getDonationLoanLoan: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/loan/loan`,
        responseHandler: 'text',
      }),
    }),

    getDonationLoanFinal: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/loan/final`,
        responseHandler: 'text',
      }),
    }),

    getDonationShippingReceipt: build.query({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/shipping/receipt`,
        responseHandler: 'text',
      }),
    }),

    putMuseumRegistrar: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/museum/registrar`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationConditionReportMatch: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/donors/match/report/condition`,
        // TODO: set a timeout when issue resolved: https://github.com/reduxjs/redux-toolkit/pull/2143
        // related: https://github.com/reduxjs/redux-toolkit/discussions/2079
        method: 'PUT',
        body: data,
      }),
      extraOptions: { timeout: IMAGE_UPLOAD_TIMEOUT },
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationConservatorReport: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/conservator/report`,
        // TODO: set a timeout when issue resolved: https://github.com/reduxjs/redux-toolkit/pull/2143
        // related: https://github.com/reduxjs/redux-toolkit/discussions/2079
        method: 'PUT',
        body: data,
      }),
      extraOptions: {
        timeout: IMAGE_UPLOAD_TIMEOUT,
      },
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationDeedDeed: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/deed/deed`,
        // TODO: set a timeout when issue resolved: https://github.com/reduxjs/redux-toolkit/pull/2143
        // related: https://github.com/reduxjs/redux-toolkit/discussions/2079
        method: 'PUT',
        body: data,
      }),
      extraOptions: { timeout: IMAGE_UPLOAD_TIMEOUT },
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationDeedSend: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/deed/send`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationLoanLoan: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/loan/loan`,
        method: 'PUT',
        body: data,
      }),
      extraOptions: {
        timeout: IMAGE_UPLOAD_TIMEOUT,
      },
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationLoanSend: build.mutation({
      query: ({ donationId }) => ({
        url: `/donations/${donationId}/loan/send`,
        method: 'PUT',
        body: {},
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationShippingReceipt: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/shipping/receipt`,
        method: 'PUT',
        body: data,
      }),
      extraOptions: {
        timeout: IMAGE_UPLOAD_TIMEOUT,
      },
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),

    putDonationShippingInfo: build.mutation({
      query: ({ donationId, data }) => ({
        url: `/donations/${donationId}/shipping/info/museum`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (_, __, { donationId }) => [
        { type: API_TAGS.MuseumDonations, id: 'LIST' },
        { type: API_TAGS.DonorDonations, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useDeleteDonationConservatorReportMutation,
  useDeleteDonationDeedDeedMutation,
  useDeleteDonationLoanLoanMutation,
  useDeleteDonationShippingReceiptMutation,
  useGetDonationsQuery,
  useLazyGetDonationAppraisalReportQuery,
  useLazyGetDonationConservatorReportQuery,
  useLazyGetDonationDeedDeedQuery,
  useLazyGetDonationDeedFinalQuery,
  useLazyGetDonationFormIRSFormQuery,
  useLazyGetDonationLoanLoanQuery,
  useLazyGetDonationLoanFinalQuery,
  useLazyGetDonationShippingReceiptQuery,
  usePutMuseumRegistrarMutation,
  usePutDonationConditionReportMatchMutation,
  usePutDonationConservatorReportMutation,
  usePutDonationDeedDeedMutation,
  usePutDonationDeedSendMutation,
  usePutDonationLoanLoanMutation,
  usePutDonationLoanSendMutation,
  usePutDonationShippingReceiptMutation,
  usePutDonationShippingInfoMutation,
} = donationsApi;

// PROPOSALS
export const proposalsApi = api.injectEndpoints({
  endpoints: build => ({
    getProposalsSimple: build.query({
      query: museumId => ({
        url: `/proposals/museums/${museumId}/simple`,
      }),
      transformResponse: (result, error, args) => {
        const payload = {};
        result.proposals.forEach(v => (payload[v.objectId] = v));
        return payload;
      },
      providesTags: (results = [], error, arg) => {
        return [API_TAGS.MuseumProposals];
      },
    }),
    getProposal: build.query({
      query: proposalId => ({
        url: `/proposals/${proposalId}`,
      }),
      providesTags: (results = [], error, arg) => {
        return [
          API_TAGS.MuseumProposals,
          { type: API_TAGS.MuseumProposals, id: arg },
        ];
      },
    }),
  }),
});
export const { useGetProposalsSimpleQuery, useGetProposalQuery } = proposalsApi;

// CYCLES
export const cyclesApi = api.injectEndpoints({
  endpoints: build => ({
    getCyclesSorted: build.query({
      query: () => ({
        url: `/cycles/sorted`,
      }),
      providesTags: (results = [], error, arg) => {
        return [API_TAGS.Cycles];
      },
    }),
  }),
});
export const { useGetCyclesSortedQuery } = cyclesApi;

// MUSEUM ACCOUNTS
export const museumAccountsApi = api.injectEndpoints({
  endpoints: build => ({
    getMuseumAccountUser: build.query({
      query: () => ({
        url: `/museums`,
      }),
      providesTags: (res, error, accountId) => {
        return [
          API_TAGS.MuseumAccountUsersList,
          // { type: API_TAGS.MuseumAccountUsersList, id: accountId },
        ];
      },
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          dispatch(macctActions.macctSetAccount(response.data));
        } catch {}
      },
    }),
    getMuseumAccountsSimple: build.query({
      query: () => ({
        url: `/museums/simple`,
      }),
      transformResponse: (result, error, args) =>
        result.museums.sort((a, b) => a.name.localeCompare(b.name)),
      providesTags: (res, error, accountId) => {
        return [API_TAGS.MuseumAccounts];
      },
    }),
  }),
});
export const { useGetMuseumAccountUserQuery, useGetMuseumAccountsSimpleQuery } =
  museumAccountsApi;

// MUSEUM USERS
export const museumUsersApi = api.injectEndpoints({
  endpoints: build => ({
    getUsersMuseum: build.query({
      query: museumId => ({
        url: `/users/museums/${museumId}`,
      }),
      transformResponse: (result, error, args) => result.users,
      providesTags: (res, error, accountId) => {
        return [API_TAGS.MuseumUsers];
      },
    }),
  }),
});
export const { useGetUsersMuseumQuery } = museumUsersApi;

// MUSEUM OBJECTS
export const museumObjectsApi = api.injectEndpoints({
  endpoints: build => ({
    getObjSummaries: build.query({
      query: ({ cycleId }) => ({
        url: `/objects/cycles/${cycleId}/summary`,
      }),
      transformResponse: (result, error, args) => {
        return {
          activeObjects: result.objects
            .sort(sortByArtistLastName)
            .sort(sortByCollection),
          inactiveObjects: result.inactiveObjects
            .sort(sortByArtistLastName)
            .sort(sortByCollection),
        };
      },
      providesTags: (results = [], error, cycleId) => {
        return [
          API_TAGS.MuseumObjects,
          { type: API_TAGS.MuseumObjects, id: 'LIST' },
        ];
      },
    }),
    getObjMuseum: build.query({
      query: ({ objectId }) => ({
        url: `/objects/${objectId}/museum`,
      }),
      providesTags: (res, error, objectId) => {
        return [
          API_TAGS.MuseumObjects,
          { type: API_TAGS.MuseumObjects, id: objectId },
        ];
      },
    }),
  }),
});

export const { useGetObjSummariesQuery, useGetObjMuseumQuery } =
  museumObjectsApi;

function sendMessageOnSuccess({ message }) {
  return async function onQueryStarted(
    { action },
    { dispatch, queryFulfilled },
  ) {
    try {
      await queryFulfilled;
      dispatch(
        dacctActions.dacctSetSuccessMessageWithTimeout({
          message,
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
}
