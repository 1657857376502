import {
  DDONM_RESET_ALL,
  DDONM_SET_FORM_ONE,
  DDONM_RESET_FORM_ONE,
  DDONM_RESET_FORM_ONE_ERRORS,
  DDONM_UPDATE_DONOR_NAME_FIRST,
  DDONM_UPDATE_DONOR_NAME_LAST,
  DDONM_UPDATE_REPRESENTATIVE,
  DDONM_UPDATE_REPRESENTATIVE_NAME_FIRST,
  DDONM_UPDATE_REPRESENTATIVE_NAME_LAST,
  DDONM_UPDATE_DADDRESS_LINE_ONE,
  DDONM_UPDATE_DADDRESS_LINE_TWO,
  DDONM_UPDATE_DADDRESS_CITY,
  DDONM_UPDATE_DADDRESS_STATE,
  DDONM_UPDATE_DADDRESS_ZIP,
  DDONM_UPDATE_DADDRESS_COUNTRY,
  DDONM_UPDATE_CREDIT_LINE,
  DDONM_UPDATE_FORM_ONE_ERRORS,
  DDONM_SET_FORM_TWO,
  DDONM_RESET_FORM_TWO,
  DDONM_RESET_FORM_TWO_ERRORS,
  DDONM_UPDATE_CONTACT_NAME_FIRST,
  DDONM_UPDATE_CONTACT_NAME_LAST,
  DDONM_UPDATE_CONTACT_TEL,
  DDONM_UPDATE_CONTACT_EMAIL,
  DDONM_UPDATE_SADDRESS_COMPANY,
  DDONM_UPDATE_SADDRESS_LINE_ONE,
  DDONM_UPDATE_SADDRESS_LINE_TWO,
  DDONM_UPDATE_SADDRESS_CITY,
  DDONM_UPDATE_SADDRESS_STATE,
  DDONM_UPDATE_SADDRESS_ZIP,
  DDONM_UPDATE_SADDRESS_COUNTRY,
  DDONM_UPDATE_SHIPPING_DESCRIPTION,
  DDONM_UPDATE_SHIPPING_INSTRUCTIONS,
  DDONM_UPDATE_FORM_TWO_ERRORS,
  DDONM_SET_FORM_THREE,
  DDONM_RESET_FORM_THREE,
  DDONM_RESET_FORM_THREE_ERRORS,
  DDONM_UPDATE_INSURANCE_VALUE,
  DDONM_UPDATE_WEIGHT,
  DDONM_UPDATE_WEIGHT_WEIGHT,
  DDONM_UPDATE_WEIGHT_SYSTEM,
  DDONM_UPDATE_FRAME,
  DDONM_UPDATE_FRAME_HEIGHT,
  DDONM_UPDATE_FRAME_WIDTH,
  DDONM_UPDATE_FRAME_DEPTH,
  DDONM_UPDATE_FRAME_SYSTEM,
  DDONM_UPDATE_CRATE,
  DDONM_UPDATE_CRATE_HEIGHT,
  DDONM_UPDATE_CRATE_WIDTH,
  DDONM_UPDATE_CRATE_DEPTH,
  DDONM_UPDATE_CRATE_SYSTEM,
  DDONM_UPDATE_CAN_PACK,
  DDONM_UPDATE_CONDITION,
  DDONM_UPDATE_FORM_THREE_ERRORS,
  DDONM_SET_FINISH_MODAL,
} from '../actionTypes';

export const ddonmResetAll = _ => {
  return {
    type: DDONM_RESET_ALL,
  };
};

export const ddonmSetFormOne = payload => {
  return {
    type: DDONM_SET_FORM_ONE,
    payload,
  };
};

export const ddonmResetFormOne = _ => {
  return {
    type: DDONM_RESET_FORM_ONE,
  };
};

export const ddonmResetFormOneErrors = _ => {
  return {
    type: DDONM_RESET_FORM_ONE_ERRORS,
  };
};

export const ddonmUpdateDonorNameFirst = payload => {
  return {
    type: DDONM_UPDATE_DONOR_NAME_FIRST,
    payload,
  };
};

export const ddonmUpdateDonorNameLast = payload => {
  return {
    type: DDONM_UPDATE_DONOR_NAME_LAST,
    payload,
  };
};

export const ddonmUpdateRepresentative = payload => {
  return {
    type: DDONM_UPDATE_REPRESENTATIVE,
    payload,
  };
};

export const ddonmUpdateRepresentativeNameFirst = payload => {
  return {
    type: DDONM_UPDATE_REPRESENTATIVE_NAME_FIRST,
    payload,
  };
};

export const ddonmUpdateRepresentativeNameLast = payload => {
  return {
    type: DDONM_UPDATE_REPRESENTATIVE_NAME_LAST,
    payload,
  };
};

export const ddonmUpdateDAddressLineOne = payload => {
  return {
    type: DDONM_UPDATE_DADDRESS_LINE_ONE,
    payload,
  };
};

export const ddonmUpdateDAddressLineTwo = payload => {
  return {
    type: DDONM_UPDATE_DADDRESS_LINE_TWO,
    payload,
  };
};

export const ddonmUpdateDAddressCity = payload => {
  return {
    type: DDONM_UPDATE_DADDRESS_CITY,
    payload,
  };
};

export const ddonmUpdateDAddressState = payload => {
  return {
    type: DDONM_UPDATE_DADDRESS_STATE,
    payload,
  };
};

export const ddonmUpdateDAddressZip = payload => {
  return {
    type: DDONM_UPDATE_DADDRESS_ZIP,
    payload,
  };
};

export const ddonmUpdateDAddressCountry = payload => {
  return {
    type: DDONM_UPDATE_DADDRESS_COUNTRY,
    payload,
  };
};

export const ddonmUpdateCreditLine = payload => {
  return {
    type: DDONM_UPDATE_CREDIT_LINE,
    payload,
  };
};

export const ddonmUpdateFormOneErrors = payload => {
  return {
    type: DDONM_UPDATE_FORM_ONE_ERRORS,
    payload,
  };
};

export const ddonmSetFormTwo = payload => {
  return {
    type: DDONM_SET_FORM_TWO,
    payload,
  };
};

export const ddonmResetFormTwo = _ => {
  return {
    type: DDONM_RESET_FORM_TWO,
  };
};

export const ddonmResetFormTwoErrors = _ => {
  return {
    type: DDONM_RESET_FORM_TWO_ERRORS,
  };
};

export const ddonmUpdateContactNameFirst = payload => {
  return {
    type: DDONM_UPDATE_CONTACT_NAME_FIRST,
    payload,
  };
};

export const ddonmUpdateContactNameLast = payload => {
  return {
    type: DDONM_UPDATE_CONTACT_NAME_LAST,
    payload,
  };
};

export const ddonmUpdateContactTel = payload => {
  return {
    type: DDONM_UPDATE_CONTACT_TEL,
    payload,
  };
};

export const ddonmUpdateContactEmail = payload => {
  return {
    type: DDONM_UPDATE_CONTACT_EMAIL,
    payload,
  };
};

export const ddonmUpdateSAddressCompany = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_COMPANY,
    payload,
  };
};

export const ddonmUpdateSAddressLineOne = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_LINE_ONE,
    payload,
  };
};

export const ddonmUpdateSAddressLineTwo = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_LINE_TWO,
    payload,
  };
};

export const ddonmUpdateSAddressCity = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_CITY,
    payload,
  };
};

export const ddonmUpdateSAddressState = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_STATE,
    payload,
  };
};

export const ddonmUpdateSAddressZip = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_ZIP,
    payload,
  };
};

export const ddonmUpdateSAddressCountry = payload => {
  return {
    type: DDONM_UPDATE_SADDRESS_COUNTRY,
    payload,
  };
};

export const ddonmUpdateShippingDescription = payload => {
  return {
    type: DDONM_UPDATE_SHIPPING_DESCRIPTION,
    payload,
  };
};

export const ddonmUpdateShippingInstructions = payload => {
  return {
    type: DDONM_UPDATE_SHIPPING_INSTRUCTIONS,
    payload,
  };
};

export const ddonmUpdateFormTwoErrors = payload => {
  return {
    type: DDONM_UPDATE_FORM_TWO_ERRORS,
    payload,
  };
};

export const ddonmSetFormThree = payload => {
  return {
    type: DDONM_SET_FORM_THREE,
    payload,
  };
};

export const ddonmResetFormThree = _ => {
  return {
    type: DDONM_RESET_FORM_THREE,
  };
};

export const ddonmResetFormThreeErrors = _ => {
  return {
    type: DDONM_RESET_FORM_THREE_ERRORS,
  };
};

export const ddonmUpdateInsuranceValue = payload => {
  return {
    type: DDONM_UPDATE_INSURANCE_VALUE,
    payload,
  };
};

export const ddonmUpdateWeight = payload => {
  return {
    type: DDONM_UPDATE_WEIGHT,
    payload,
  };
};

export const ddonmUpdateWeightWeight = payload => {
  return {
    type: DDONM_UPDATE_WEIGHT_WEIGHT,
    payload,
  };
};

export const ddonmUpdateWeightSystem = payload => {
  return {
    type: DDONM_UPDATE_WEIGHT_SYSTEM,
    payload,
  };
};

export const ddonmUpdateFrame = payload => {
  return {
    type: DDONM_UPDATE_FRAME,
    payload,
  };
};

export const ddonmUpdateFrameHeight = payload => {
  return {
    type: DDONM_UPDATE_FRAME_HEIGHT,
    payload,
  };
};

export const ddonmUpdateFrameWidth = payload => {
  return {
    type: DDONM_UPDATE_FRAME_WIDTH,
    payload,
  };
};

export const ddonmUpdateFrameDepth = payload => {
  return {
    type: DDONM_UPDATE_FRAME_DEPTH,
    payload,
  };
};

export const ddonmUpdateFrameSystem = payload => {
  return {
    type: DDONM_UPDATE_FRAME_SYSTEM,
    payload,
  };
};

export const ddonmUpdateCrate = payload => {
  return {
    type: DDONM_UPDATE_CRATE,
    payload,
  };
};

export const ddonmUpdateCrateHeight = payload => {
  return {
    type: DDONM_UPDATE_CRATE_HEIGHT,
    payload,
  };
};

export const ddonmUpdateCrateWidth = payload => {
  return {
    type: DDONM_UPDATE_CRATE_WIDTH,
    payload,
  };
};

export const ddonmUpdateCrateDepth = payload => {
  return {
    type: DDONM_UPDATE_CRATE_DEPTH,
    payload,
  };
};

export const ddonmUpdateCrateSystem = payload => {
  return {
    type: DDONM_UPDATE_CRATE_SYSTEM,
    payload,
  };
};

export const ddonmUpdateCanPack = payload => {
  return {
    type: DDONM_UPDATE_CAN_PACK,
    payload,
  };
};

export const ddonmUpdateCondition = payload => {
  return {
    type: DDONM_UPDATE_CONDITION,
    payload,
  };
};

export const ddonmUpdateFormThreeErrors = payload => {
  return {
    type: DDONM_UPDATE_FORM_THREE_ERRORS,
    payload,
  };
};

export const ddonmSetFinishModal = payload => {
  return {
    type: DDONM_SET_FINISH_MODAL,
    payload,
  };
};
