import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  authPostLoginClear,
  getUserClear,
  loginReset,
  loginUpdateEmail,
  loginUpdateFormErrors,
  loginUpdatePassword,
} from '../../actions';
import { authPostLogin } from '../../requests';
import { validateEmail } from '../../lib';
import sha256 from 'crypto-js/sha256';
import LogoStacked from '../../images/logo-stacked.png';

class Login extends Component {
  static get propTypes() {
    return {
      email: PropTypes.string,
      formErrors: PropTypes.object,
      getUserClear: PropTypes.func,
      getUserRequest: PropTypes.object,
      password: PropTypes.string,
      postLogin: PropTypes.func,
      postLoginClear: PropTypes.func,
      postLoginRequest: PropTypes.object,
      reset: PropTypes.func,
      updateEmail: PropTypes.func,
      updateFormErrors: PropTypes.func,
      updatePassword: PropTypes.func,
      token: PropTypes.object,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.postLoginClear();
    this.props.getUserClear();
  }

  handleEmailChange = event => {
    this.props.updateEmail({ email: event.target.value });
  };

  handlePasswordChange = event => {
    this.props.updatePassword({ password: event.target.value });
  };

  gettingUser = false;

  validateFormAndSubmitLogin = _ => {
    if (
      this.props.postLoginRequest.loading ||
      this.props.getUserRequest.loading
    ) {
      return;
    }

    const errors = {
      formError: false,
      emailError: false,
      passwordError: false,
    };

    if (this.props.email === '' || !validateEmail(this.props.email)) {
      errors.formError = true;
      errors.emailError = true;
    }

    if (this.props.password === '') {
      errors.formError = true;
      errors.passwordError = true;
    }

    this.props.updateFormErrors(errors);

    if (errors.formError) {
      return;
    }

    this.props.postLogin({
      email: this.props.email,
      passwordHash: sha256(this.props.password).toString(),
    });
  };

  render() {
    if (this.props.token && this.props.user) {
      window.location.reload(false);
    }

    return (
      <div className="mex-signup-signin-container">
        <div className="row">
          <div className="col-md-5 d-flex justify-content-center mex-signup-signin-scrollable px-0">
            <div className="signup-content">
              <img
                src={LogoStacked}
                alt="logo-stacked"
                className="logo-login gen-logo-stacked logo-signup-signin"
              />
              <p className="header mex-mb-6">Welcome Back</p>
              <p className="text mex-mb-30">Explore donation opportunities</p>
              <div className="mex-mb-26">
                <p
                  className={`label ${
                    this.props.formErrors.emailError ? 'label-error' : ''
                  }`}
                >
                  EMAIL ADDRESS
                </p>
                <input
                  className={`input ${
                    this.props.formErrors.emailError ? 'input-error' : ''
                  }`}
                  type="text"
                  value={this.props.email}
                  onChange={this.handleEmailChange}
                  placeholder="Email Address"
                ></input>
              </div>
              <div className="mex-mb-26">
                <p
                  className={`label ${
                    this.props.formErrors.passwordError ? 'label-error' : ''
                  }`}
                >
                  PASSWORD
                </p>
                <input
                  className={`input ${
                    this.props.formErrors.passwordError ? 'input-error' : ''
                  }`}
                  type="password"
                  value={this.props.password}
                  onChange={this.handlePasswordChange}
                  placeholder="Password"
                ></input>
              </div>
              <button
                className={`btn btn-primary mex-btn-primary mex-btn-primary-fill ${
                  !this.props.postLoginRequest.error &&
                  !this.props.getUserRequest.error
                    ? 'mex-mb-12'
                    : ''
                }`}
                style={{ width: '100%' }}
                onClick={this.validateFormAndSubmitLogin}
              >
                Log In
              </button>
              {(this.props.postLoginRequest.error ||
                this.props.getUserRequest.error) && (
                <div
                  className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12"
                  style={{ height: '62px' }}
                >
                  The user information you entered is incorrect. Please try
                  again.
                </div>
              )}
              <p className="text mex-mb-6">
                Don't have an account?{' '}
                <Link className="link" to="/signup">
                  Create account
                </Link>
              </p>
              <p className="text mex-mb-30">
                Forgot your password?{' '}
                <Link className="link" to="/reset">
                  Reset password
                </Link>
              </p>
            </div>
          </div>
          <div className="col-md-7 px-0 mex-signup-signin-right">
            <img
              src="https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://extechimagesmisc.s3.amazonaws.com/Greg+Breda_Flood_2018_detail.jpg"
              alt="greg-brenda-flood"
              className="mex-signup-signin-right-img"
            />
            <div className="mex-signup-signin-right-img-title-container">
              <p className="mex-signup-signin-right-img-title">
                Greg Breda, <i>Flood</i> (detail), 2018. Courtesy of the artist
                and PATRON, Chicago. Photo: Aron Gent.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    email: state.loginState.formData.email,
    formErrors: state.loginState.formErrors,
    getUserRequest: state.userState.getUserRequest,
    password: state.loginState.formData.password,
    postLoginRequest: state.authState.postLoginRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserClear: _ => dispatch(getUserClear()),
    reset: _ => dispatch(loginReset()),
    postLogin: payload => dispatch(authPostLogin(payload)),
    postLoginClear: _ => dispatch(authPostLoginClear()),
    updateEmail: payload => dispatch(loginUpdateEmail(payload)),
    updateFormErrors: payload => dispatch(loginUpdateFormErrors(payload)),
    updatePassword: payload => dispatch(loginUpdatePassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
