const { DONOR_ACCESS_ROLES } = require('constants/donors');

export function getRoleDetails({ role }) {
  return {
    [DONOR_ACCESS_ROLES.owner]: {
      displayName: 'Owner',
      info: 'The owner of the shared folder is also the owner of all objects in this folder. The owner is the only one who has the ability to transfer ownership of an object to another user.',
    },
    [DONOR_ACCESS_ROLES.editor]: {
      displayName: 'Editor',
      info: 'Editors can view all objects, create new objects, delete drafts, and complete all actions for objects in this shared folder. They cannot move objects out of this shared folder.',
    },
    [DONOR_ACCESS_ROLES.viewer]: {
      displayName: 'View Only',
      info: 'View only users can view all object information and donation activity for objects in this folder.',
    },
  }[role];
}
