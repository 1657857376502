import { openBase64PDFNewWindow } from '@lib';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyGetDonationDeedFinalQuery } from 'requests/api';
import {
  ActionLink,
  DonationRow,
  DonationSection,
  NoAction,
} from './DonationSections';

export function DeedOfGiftSection({
  donation,
  setIsDeedUploadOpen = () => {},
}) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );
  const [getDeedFinal] = useLazyGetDonationDeedFinalQuery();

  if (!donation) return null;
  if (donation?.events?.completed && !donation?.deed?.finalDocument) {
    return null;
  }
  if (!['donor', 'museum'].includes(userAccountType)) return null;

  return (
    <DonationSection
      title="Deed of Gift"
      learnMoreModalContent={
        <div>
          A Deed of Gift is required for any object entering the institution's
          collection. The Deed of Gift transfers title and ownership from the
          donor to the institution and specifies the donor’s preferred credit
          line. The tax receipt will be issued for the year in which the Deed of
          Gift is fully-executed.
        </div>
      }
      completedAction={
        <ActionLink
          href={`#`}
          secondary
          onClick={async _ => {
            const deedFinalData = await getDeedFinal({
              donationId: donation.id,
            }).unwrap();
            openBase64PDFNewWindow(deedFinalData);
          }}
          text="View Deed of Gift"
        />
      }
      icon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 4a2 2 0 0 1 2-2h8a1 1 0 0 1 .707.293l5 5A1 1 0 0 1 20 8v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4zm13.586 4L14 4.414V8h3.586zM12 4H6v16h12V10h-5a1 1 0 0 1-1-1V4zm-4.235 7.615H7.15v1.231h.615v-1.23zm7.385 1.231h.615v-1.23h-.615v1.23zm-7.385 0h7.385v-1.23H7.765v1.23zm0-4.923H7.15v1.23h.615v-1.23zm0 1.23v-1.23h3.077v1.23H7.765zm1.902 6.083c.91-.245 1.956-.02 2.842.867l-.121-.114.034-.01c1.024-.29 1.932-.037 2.665.292l.154.072c.214.1.419.21.603.31.19.102.355.193.512.274.335.169.524.227.64.227v1.23c-.436 0-.856-.188-1.196-.359-.177-.09-.364-.193-.542-.289a12.787 12.787 0 0 0-.546-.283c-.507-.241-1.006-.403-1.53-.365l-.146.016.007.117-.009.14c-.043.368-.246.648-.46.844-.412.374-1.018.576-1.486.647-.237.036-.5.048-.73.003a1.02 1.02 0 0 1-.406-.171.751.751 0 0 1-.313-.576.868.868 0 0 1 .172-.54c.091-.123.199-.233.32-.328.239-.193.587-.405 1.051-.636l-.041.02.026-.019-.073-.041a1.511 1.511 0 0 0-.999-.164l-.11.025c-.495.133-.884.513-1.02.923h-.001l-1.168-.389c.256-.766.9-1.397 1.698-1.67zm1.916 2.17-.068.04a2.192 2.192 0 0 1-.612.189c-.1.02-.204.024-.307.012a.328.328 0 0 1 .086.046c.161.124.226.336.162.53a.257.257 0 0 1-.029.057c-.002.002.019-.025.089-.082.14-.114.399-.279.83-.493l-.151-.299zm.105-.38.046.055.04.055c.02.032.03.055.034.07l.002.012-.008.015a.356.356 0 0 1-.056.061l-.111.083-.008.003.2.266c.197-.15.41-.277.637-.378.185-.08.365-.131.542-.16l.03-.004v-.021a1.51 1.51 0 0 0-.33-.756l-.019.013.02-.014a2.394 2.394 0 0 0-.198-.222l-.43.428.046-.047.384-.382-.121-.114-.188.06a4.223 4.223 0 0 0-.89.454l-.143.102.004.003-.03.016-.053.038.368.49-.267-.534.04.024c.135.086.272.196.408.332l.05.052z"
            fill="#2E384D"
            fillRule="nonzero"
          />
        </svg>
      }
    >
      <DonationRow
        tenant="Institution"
        rowTitle="Upload & Send Deed of Gift"
        action={
          <ActionLink
            onClick={() => setIsDeedUploadOpen(true)}
            href="#"
            text="Upload & Send"
          />
        }
        isCompleted={
          donation.events.completed ||
          donation.deed.finalDocument ||
          donation.deed.deed
        }
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Sign Deed of Gift"
        tooltipContent="Deed of Gift must be signed by the donor prior to the Ratification Meeting and within 60 days of receiving the completed appraisal."
        action={
          <NoAction>Deed of Gift has been emailed via HelloSign</NoAction>
        }
        isUpcoming={!donation.deed.deed}
        isCompleted={
          donation.events.completed ||
          donation.deed.finalDocument ||
          donation.deed.signedDonor
        }
      />
      <DonationRow
        tenant="Institution"
        rowTitle="Countersign Deed of Gift"
        action={
          <NoAction>Deed of Gift has been emailed via HelloSign</NoAction>
        }
        isUpcoming={!donation.deed.signedDonor}
        isCompleted={donation.events.completed || donation.deed.finalDocument}
      />
    </DonationSection>
  );
}
