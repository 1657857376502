import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useGetMuseumAccountUserQuery } from 'requests/api';
import { getUserClear } from '../../actions';
import { getUser } from '../../requests';
import '../../styles/general.css';
import '../../styles/spacing.css';
import '../../styles/text.css';

export default function MuseumLanding() {
  const getUserRequest = useSelector(state => state.userState.getUserRequest);
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const dispatch = useDispatch();

  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  useEffect(() => {
    if (!token) return;

    dispatch(getUser(token));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(getUserClear());
    };
  }, []);

  if (
    !isLoadingMuseumAccount &&
    museumAccount &&
    !getUserRequest.loading &&
    getUserRequest.data &&
    user
  ) {
    if (museumAccount.approved) {
      return <Redirect push to="/institution/catalogue/1" />;
    } else {
      return <Redirect push to="/user/institution/pending" />;
    }
  }

  return (
    <div className={'gen-background'}>
      <p className="txt-header text-center spc-mt-120">Loading...</p>
    </div>
  );
}
