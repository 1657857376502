import {
  MSET_PUT_MUSEUM_CLEAR,
  MSET_PUT_MUSEUM_FAILURE,
  MSET_PUT_MUSEUM_REQUEST,
  MSET_PUT_MUSEUM_SUCCESS,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_CLEAR,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_FAILURE,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_REQUEST,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_SUCCESS,
  MSET_PUT_MUSEUM_USER_POSITION_CLEAR,
  MSET_PUT_MUSEUM_USER_POSITION_FAILURE,
  MSET_PUT_MUSEUM_USER_POSITION_REQUEST,
  MSET_PUT_MUSEUM_USER_POSITION_SUCCESS,
  MSET_SET_PERMISSIONS_CARD_HOVER,
  MSET_SET_SELECTED_USER,
  MSET_SET_SELECTED_USER_ADMIN_TOGGLE,
  MSET_UPDATE_NEW_POSITION,
  MSET_UPDATE_NEW_POSITION_ERROR,
  MSET_RESET_FORM,
  MSET_UPDATE_FORM_ERRORS,
  MSET_UPDATE_NAME,
  MSET_UPDATE_LOCATION_CITY,
  MSET_UPDATE_LOCATION_STATE,
  MSET_UPDATE_URL,
} from '../../actions/actionTypes';

const initialMuseumSettingsState = {
  permissionsCardHover: false,
  selectedUser: null,
  selectedUserAdminToggle: null,
  newPosition: '',
  newPositionError: false,
  formData: {
    name: '',
    locationCity: '',
    locationState: '',
    websiteURL: '',
  },
  formErrors: {
    formError: false,
    nameError: false,
    locationCityError: false,
    locationStateError: false,
    websiteURLError: false,
  },
  putMuseumRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putMuseumUserPermissionsRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putMuseumUserPositionRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const museumSettingsReducer = (
  state = initialMuseumSettingsState,
  action,
) => {
  switch (action.type) {
    case MSET_PUT_MUSEUM_CLEAR:
      return {
        ...state,
        putMuseumRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_FAILURE:
      return {
        ...state,
        putMuseumRequest: {
          ...state.putMuseumRequest,
          loading: false,
          error: action.payload,
        },
      };

    case MSET_PUT_MUSEUM_REQUEST:
      return {
        ...state,
        putMuseumRequest: {
          ...state.putMuseumRequest,
          loading: true,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_SUCCESS:
      return {
        ...state,
        putMuseumRequest: {
          ...state.putMuseumRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_USER_PERMISSIONS_CLEAR:
      return {
        ...state,
        putMuseumUserPermissionsRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        putMuseumUserPermissionsRequest: {
          ...state.putMuseumUserPermissionsRequest,
          loading: false,
          error: action.payload,
        },
      };

    case MSET_PUT_MUSEUM_USER_PERMISSIONS_REQUEST:
      return {
        ...state,
        putMuseumUserPermissionsRequest: {
          ...state.putMuseumUserPermissionsRequest,
          loading: true,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        putMuseumUserPermissionsRequest: {
          ...state.putMuseumUserPermissionsRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_USER_POSITION_CLEAR:
      return {
        ...state,
        putMuseumUserPositionRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_USER_POSITION_FAILURE:
      return {
        ...state,
        putMuseumUserPositionRequest: {
          ...state.putMuseumUserPositionRequest,
          loading: false,
          error: action.payload,
        },
      };

    case MSET_PUT_MUSEUM_USER_POSITION_REQUEST:
      return {
        ...state,
        putMuseumUserPositionRequest: {
          ...state.putMuseumUserPositionRequest,
          loading: true,
          error: '',
        },
      };

    case MSET_PUT_MUSEUM_USER_POSITION_SUCCESS:
      return {
        ...state,
        putMuseumUserPositionRequest: {
          ...state.putMuseumUserPositionRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case MSET_SET_PERMISSIONS_CARD_HOVER:
      return {
        ...state,
        permissionsCardHover: action.payload,
      };

    case MSET_SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };

    case MSET_SET_SELECTED_USER_ADMIN_TOGGLE:
      return {
        ...state,
        selectedUserAdminToggle: action.payload,
      };

    case MSET_UPDATE_NEW_POSITION:
      return {
        ...state,
        newPosition: action.payload.newPosition,
      };

    case MSET_UPDATE_NEW_POSITION_ERROR:
      return {
        ...state,
        newPositionError: action.payload.newPositionError,
      };

    case MSET_RESET_FORM:
      return {
        ...state,
        formData: {
          name: '',
          locationCity: '',
          locationState: '',
          websiteURL: '',
        },
        formErrors: {
          formError: false,
          nameError: false,
          locationCityError: false,
          locationStateError: false,
          websiteURLError: false,
        },
      };

    case MSET_UPDATE_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload.formErrors,
      };

    case MSET_UPDATE_NAME:
      return {
        ...state,
        formData: {
          ...state.formData,
          name: action.payload.name,
        },
      };

    case MSET_UPDATE_LOCATION_CITY:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationCity: action.payload.locationCity,
        },
      };

    case MSET_UPDATE_LOCATION_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationState: action.payload.locationState,
        },
      };

    case MSET_UPDATE_URL:
      return {
        ...state,
        formData: {
          ...state.formData,
          websiteURL: action.payload.websiteURL,
        },
      };

    default:
      return state;
  }
};
