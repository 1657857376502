import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  objDraftPostClear,
  objDraftPutClear,
  objDraftUpdateProvenance,
  objDraftUpdateExhibitionHistory,
  objDraftUpdateLiterature,
  objDraftUpdateConservationHistory,
  objDraftUpdateNotes,
  objDraftUpdateDraftErrors,
  objDraftUpdateSubmitErrors,
} from '../../actions';
import { postObjectDraft, putObjectDraft } from '../../requests';
import {
  makeObjectDraftPayload,
  validateFormDraft,
  validateFormSubmit,
} from '../../glue';
import { BackToObjectsLink, NextDestination } from './ObjectDraftOne';

class ObjectDraftTwo extends Component {
  static get propTypes() {
    return {
      formData: PropTypes.object,
      formDraftErrors: PropTypes.object,
      formSubmitAttempted: PropTypes.bool,
      formSubmitErrors: PropTypes.object,
      postObjectDraft: PropTypes.func,
      postObjectDraftClear: PropTypes.func,
      postObjectDraftRequest: PropTypes.object,
      putObjectDraft: PropTypes.func,
      putObjectDraftClear: PropTypes.func,
      putObjectDraftRequest: PropTypes.object,
      token: PropTypes.object,
      updateProvenance: PropTypes.func,
      updateExhibitionHistory: PropTypes.func,
      updateLiterature: PropTypes.func,
      updateConservationHistory: PropTypes.func,
      updateNotes: PropTypes.func,
      updateDraftErrors: PropTypes.func,
      updateSubmitErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
  }

  componentWillUnmount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
  }

  handleUpdateProvenance = provenance => {
    this.props.updateProvenance({ provenance });
  };

  handleUpdateExhibitionHistory = exhibitionHistory => {
    this.props.updateExhibitionHistory({ exhibitionHistory });
  };

  handleUpdateLiterature = literature => {
    this.props.updateLiterature({ literature });
  };

  handleUpdateConservationHistory = conservationHistory => {
    this.props.updateConservationHistory({ conservationHistory });
  };

  handleUpdateNotes = notes => {
    this.props.updateNotes({ notes });
  };

  destination = '';
  handleNextStepClick = _ => {
    if (
      this.props.postObjectDraftRequest.loading ||
      this.props.putObjectDraftRequest.loading
    ) {
      return;
    }

    const formDraftErrors = validateFormDraft(this.props.formData);
    this.props.updateDraftErrors({ formDraftErrors });

    if (this.props.formSubmitAttempted) {
      const formSubmitErrors = validateFormSubmit(this.props.formData);
      this.props.updateSubmitErrors({ formSubmitErrors });
    }

    if (formDraftErrors.formError) {
      return;
    }

    let payload = {};
    try {
      payload = makeObjectDraftPayload(this.props.formData);
    } catch (e) {
      console.error(e);
      return;
    }

    if (this.props.formData.id) {
      this.props.putObjectDraft(
        this.props.token,
        this.props.formData.id,
        payload,
      );
    } else {
      this.props.postObjectDraft(this.props.token, payload, this.props.user.id);
    }
  };

  render() {
    const { accountId } = this.props.match.params;

    if (
      (this.props.postObjectDraftRequest.data &&
        !this.props.postObjectDraftRequest.loading &&
        !this.props.postObjectDraftRequest.error) ||
      (this.props.putObjectDraftRequest.data &&
        !this.props.putObjectDraftRequest.loading &&
        !this.props.putObjectDraftRequest.error)
    ) {
      return (
        <NextDestination destination={this.destination} nextPageNumber={3} />
      );
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <div className="back-to-folders-link-container">
          <BackToObjectsLink />
        </div>
        <p className="header text-center mex-mt-47">Submit a New Object</p>
        <div className="sub-header sub-header-wide mx-auto mex-mb-30">
          <p className="text text-center">
            Please complete this form to list your object for donation
            consideration on Museum Exchange
          </p>
        </div>
        <div className="new-object-steps-container mx-auto d-flex justify-content-between mex-mb-26">
          <Link
            className="link-no-decoration"
            to={`/donor/folders/${accountId}/object/draft/1`}
          >
            <div
              className={`new-object-step new-object-step-complete d-flex justify-content-center align-items-center ${
                this.props.formDraftErrors.errorPages.one ||
                this.props.formSubmitErrors.errorPages.one
                  ? 'new-object-step-error'
                  : ''
              }`}
            >
              <p
                className={`new-object-step-text ${
                  this.props.formDraftErrors.errorPages.one ||
                  this.props.formSubmitErrors.errorPages.one
                    ? 'new-object-step-current-text'
                    : ''
                }`}
              >
                OBJECT DETAILS
              </p>
            </div>
          </Link>
          <div
            className={`new-object-step new-object-step-current d-flex justify-content-center align-items-center ${
              this.props.formSubmitErrors.errorPages.two
                ? 'new-object-step-error'
                : ''
            }`}
          >
            <p className="new-object-step-text new-object-step-current-text">
              CATALOGUING
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p
              className={`new-object-step-text ${
                this.props.formDraftErrors.errorPages.three ||
                this.props.formSubmitErrors.errorPages.three
                  ? 'new-object-step-error-text'
                  : ''
              }`}
            >
              DONATION INFORMATION
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p
              className={`new-object-step-text ${
                this.props.formSubmitErrors.errorPages.four
                  ? 'new-object-step-error-text'
                  : ''
              }`}
            >
              UPLOAD IMAGES
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p className="new-object-step-text">REVIEW & SUBMIT</p>
          </div>
        </div>
        <div className="new-object-content-container d-flex justify-content-center mx-auto">
          <div className="new-object-content">
            <div className="new-object-content-inner mx-auto">
              <div className="mex-mb-22">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.provenanceError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Provenance
                </p>
                <textarea
                  className={`input-textarea ${
                    this.props.formSubmitErrors.provenanceError
                      ? 'input-error'
                      : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateProvenance(event.target.value)
                  }
                  value={this.props.formData.provenance}
                  maxLength="1000"
                  placeholder="List Provenance"
                ></textarea>
              </div>
              <div className="mex-mb-22">
                <p className="label-bold mex-mb-6">Exhibition History</p>
                <p className="title-small mex-mb-12" style={{ width: '371px' }}>
                  OPTIONAL
                </p>
                <textarea
                  className="input-textarea"
                  onChange={event =>
                    this.handleUpdateExhibitionHistory(event.target.value)
                  }
                  value={this.props.formData.exhibitionHistory}
                  maxLength="1000"
                  placeholder="List Exhibition History"
                ></textarea>
              </div>
              <div className="mex-mb-22">
                <p className="label-bold mex-mb-6">Literature</p>
                <p className="title-small mex-mb-12" style={{ width: '371px' }}>
                  OPTIONAL
                </p>
                <textarea
                  className="input-textarea"
                  onChange={event =>
                    this.handleUpdateLiterature(event.target.value)
                  }
                  value={this.props.formData.literature}
                  maxLength="1000"
                  placeholder="List Literature"
                ></textarea>
              </div>
              <div className="mex-mb-22">
                <p className="label-bold mex-mb-6">Conservation History</p>
                <p className="title-small mex-mb-12" style={{ width: '371px' }}>
                  OPTIONAL
                </p>
                <textarea
                  className="input-textarea"
                  onChange={event =>
                    this.handleUpdateConservationHistory(event.target.value)
                  }
                  value={this.props.formData.conservationHistory}
                  maxLength="1000"
                  placeholder="Describe Conservation History"
                ></textarea>
              </div>
              <div className="mex-mb-22">
                <p className="label-bold mex-mb-6">Catalogue Notes</p>
                <p className="title-small mex-mb-12" style={{ width: '371px' }}>
                  OPTIONAL
                </p>
                <textarea
                  className="input-textarea"
                  onChange={event => this.handleUpdateNotes(event.target.value)}
                  value={this.props.formData.notes}
                  maxLength="5000"
                  placeholder="Enter Any Additional Notes"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar fixed-bottom d-flex justify-content-center new-object-nav-bottom align-items-end">
          <div className="new-object-nav-bottom-container d-flex justify-content-end">
            <div className="new-object-nav-bottom-alert-container">
              {(this.props.putObjectDraftRequest.error ||
                this.props.postObjectDraftRequest.error ||
                this.props.formDraftErrors.formError ||
                this.props.formSubmitErrors.formError) && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                  {this.props.putObjectDraftRequest.error ||
                  this.props.postObjectDraftRequest.error
                    ? 'Please try again'
                    : 'Please complete missing field(s)'}
                </div>
              )}
            </div>
            <button
              className="btn btn-primary new-object-nav-bottom-save-button"
              onClick={_ => {
                this.destination = 'folders';
                this.handleNextStepClick();
              }}
            >
              SAVE DRAFT & EXIT
            </button>
          </div>
          <div className="new-object-nav-bottom-container d-flex justify-content-start">
            <div>
              <p className="title-small text-center mex-mb-10">NEXT STEP</p>
              <button
                className="btn btn-primary new-object-nav-bottom-next-button"
                onClick={_ => {
                  this.destination = 'next';
                  this.handleNextStepClick();
                }}
              >
                DONATION INFORMATION
              </button>
            </div>
            <div className="new-object-nav-bottom-note-container"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formData: state.objectDraftState.formData,
    formDraftErrors: state.objectDraftState.formDraftErrors,
    formSubmitAttempted: state.objectDraftState.formSubmitAttempted,
    formSubmitErrors: state.objectDraftState.formSubmitErrors,
    postObjectDraftRequest: state.objectDraftState.objDraftPostRequest,
    putObjectDraftRequest: state.objectDraftState.objDraftPutRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postObjectDraft: (token, payload, userId) =>
      dispatch(postObjectDraft(token, payload, userId)),
    postObjectDraftClear: _ => dispatch(objDraftPostClear()),
    putObjectDraft: (token, id, payload) =>
      dispatch(putObjectDraft(token, id, payload)),
    putObjectDraftClear: _ => dispatch(objDraftPutClear()),
    updateProvenance: payload => dispatch(objDraftUpdateProvenance(payload)),
    updateExhibitionHistory: payload =>
      dispatch(objDraftUpdateExhibitionHistory(payload)),
    updateLiterature: payload => dispatch(objDraftUpdateLiterature(payload)),
    updateConservationHistory: payload =>
      dispatch(objDraftUpdateConservationHistory(payload)),
    updateNotes: payload => dispatch(objDraftUpdateNotes(payload)),
    updateDraftErrors: payload => dispatch(objDraftUpdateDraftErrors(payload)),
    updateSubmitErrors: payload =>
      dispatch(objDraftUpdateSubmitErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDraftTwo);
