export const MSET_PUT_MUSEUM_CLEAR = 'MSET_PUT_MUSEUM_CLEAR';
export const MSET_PUT_MUSEUM_FAILURE = 'MSET_PUT_MUSEUM_FAILURE';
export const MSET_PUT_MUSEUM_REQUEST = 'MSET_PUT_MUSEUM_REQUEST';
export const MSET_PUT_MUSEUM_SUCCESS = 'MSET_PUT_MUSEUM_SUCCESS';
export const MSET_PUT_MUSEUM_USER_PERMISSIONS_CLEAR =
  'MSET_PUT_MUSEUM_USER_PERMISSIONS_CLEAR';
export const MSET_PUT_MUSEUM_USER_PERMISSIONS_FAILURE =
  'MSET_PUT_MUSEUM_USER_PERMISSIONS_FAILURE';
export const MSET_PUT_MUSEUM_USER_PERMISSIONS_REQUEST =
  'MSET_PUT_MUSEUM_USER_PERMISSIONS_REQUEST';
export const MSET_PUT_MUSEUM_USER_PERMISSIONS_SUCCESS =
  'MSET_PUT_MUSEUM_USER_PERMISSIONS_SUCCESS';
export const MSET_PUT_MUSEUM_USER_POSITION_CLEAR =
  'MSET_PUT_MUSEUM_USER_POSITION_CLEAR';
export const MSET_PUT_MUSEUM_USER_POSITION_FAILURE =
  'MSET_PUT_MUSEUM_USER_POSITION_FAILURE';
export const MSET_PUT_MUSEUM_USER_POSITION_REQUEST =
  'MSET_PUT_MUSEUM_USER_POSITION_REQUEST';
export const MSET_PUT_MUSEUM_USER_POSITION_SUCCESS =
  'MSET_PUT_MUSEUM_USER_POSITION_SUCCESS';
export const MSET_SET_PERMISSIONS_CARD_HOVER =
  'MSET_SET_PERMISSIONS_CARD_HOVER';
export const MSET_SET_SELECTED_USER = 'MSET_SET_SELECTED_USER';
export const MSET_SET_SELECTED_USER_ADMIN_TOGGLE =
  'MSET_SET_SELECTED_USER_ADMIN_TOGGLE';
export const MSET_UPDATE_NEW_POSITION = 'MSET_UPDATE_NEW_POSITION';
export const MSET_UPDATE_NEW_POSITION_ERROR = 'MSET_UPDATE_NEW_POSITION_ERROR';
export const MSET_RESET_FORM = 'MSET_RESET_FORM';
export const MSET_UPDATE_FORM_ERRORS = 'MSET_UPDATE_FORM_ERRORS';
export const MSET_UPDATE_NAME = 'MSET_UPDATE_NAME';
export const MSET_UPDATE_LOCATION_CITY = 'MSET_UPDATE_LOCATION_CITY';
export const MSET_UPDATE_LOCATION_STATE = 'MSET_UPDATE_LOCATION_STATE';
export const MSET_UPDATE_URL = 'MSET_UPDATE_URL';
