import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  postTelClear,
  telResetAdd,
  telUpdateAddErrors,
  telUpdateTel,
  telUpdateTelConsent,
} from '../../actions';
import { postTel } from '../../requests';
import { sanitizeTel } from '../../lib';

class AddTel extends Component {
  static get propTypes() {
    return {
      formErrors: PropTypes.object,
      postTel: PropTypes.func,
      postTelClear: PropTypes.func,
      postTelRequest: PropTypes.object,
      resetAdd: PropTypes.func,
      tel: PropTypes.string,
      consentTel: PropTypes.bool,
      token: PropTypes.object,
      updateAddErrors: PropTypes.func,
      updateTel: PropTypes.func,
      updateTelConsent: PropTypes.func,
    };
  }

  componentWillUnmount() {
    this.props.resetAdd();
    this.props.postTelClear();
  }

  handleUpdateTel = event => {
    this.props.updateTel({ tel: event.target.value });
  };

  handleUpdateConsent = _ => {
    this.props.updateTelConsent({ consentTel: !this.props.consentTel });
  };

  validateFormAndSubmit = _ => {
    if (this.props.postTelRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      telError: false,
      consentTelError: false,
    };

    if (sanitizeTel(this.props.tel).length !== 10) {
      errors.formError = true;
      errors.telError = true;
    }

    if (!this.props.consentTel) {
      errors.formError = true;
      errors.consentTelError = true;
    }

    this.props.updateAddErrors({ addFormErrors: errors });

    if (errors.formError) {
      return;
    }

    this.props.postTel(this.props.token, {
      tel: sanitizeTel(this.props.tel),
      consentTel: this.props.consentTel,
    });
  };

  render() {
    if (this.props.postTelRequest.data) {
      return <Redirect push to="/user/tel/confirm" />;
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <p className="header text-center mex-mt-47">Secure Your Account</p>
        <div className="sub-header mx-auto mex-mb-30">
          <p className="text text-center">
            To secure your account and confirm your identity, please enter your
            10-digit US or Canadian mobile number
          </p>
        </div>
        <div>
          <div className="misc-content mx-auto">
            <div className="misc-content-inner mx-auto">
              <div className="mex-mb-26">
                <p
                  className={`label ${
                    this.props.formErrors.telError ? 'label-error' : ''
                  }`}
                >
                  MOBILE NUMBER
                </p>
                <input
                  type="tel"
                  className={`input ${
                    this.props.formErrors.telError ? 'input-error' : ''
                  }`}
                  placeholder="212-555-4321"
                  value={this.props.tel}
                  onChange={this.handleUpdateTel}
                ></input>
              </div>
              <div className="mex-mb-30 d-flex">
                <input
                  type="checkbox"
                  // need to change these
                  checked={this.props.consentTel}
                  onChange={this.handleUpdateConsent}
                  className="donor-museum-application-checkbox"
                />

                <p
                  className={`text ${
                    this.props.formErrors.consentTelError ? 'label-error' : ''
                  }`}
                  onClick={this.handleUpdateConsent}
                >
                  I authorize Museum Exchange to send text messages for the
                  purposes of my account's two-factor authentication. Message
                  and data rates apply. Please view Museum Exchange's{' '}
                  <a
                    href="https://www.museumexchange.com/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                    className="gen-link"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.museumexchange.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                    className="gen-link"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              <button
                className="btn btn-primary mex-btn-primary mex-btn-primary-fill"
                style={{ width: '100%' }}
                onClick={this.validateFormAndSubmit}
              >
                Submit
              </button>
              {this.props.postTelRequest.error && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9">
                  Please try again.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formErrors: state.telState.addFormErrors,
    postTelRequest: state.userState.postTelRequest,
    tel: state.telState.addFormData.tel,
    consentTel: state.telState.addFormData.consentTel,
    token: state.authState.token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postTel: (token, payload) => dispatch(postTel(token, payload)),
    postTelClear: _ => dispatch(postTelClear()),
    resetAdd: _ => dispatch(telResetAdd()),
    updateAddErrors: payload => dispatch(telUpdateAddErrors(payload)),
    updateTel: payload => dispatch(telUpdateTel(payload)),
    updateTelConsent: payload => dispatch(telUpdateTelConsent(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTel);
