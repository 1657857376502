import React from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parseJSON from 'date-fns/parseJSON';
import { addDays } from 'date-fns';
import { getDaysLeftInCatalog } from '../../lib';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { useSelector } from 'react-redux';

export function ObjectTimeBadge({
  dateAddedToCatalog,
  firstProposalDate,
  isObjectOfInterestPage,
}) {
  const isMuseum = useSelector(state => selectIsMuseum(state));

  const shouldDisplayNewBadge = getShouldDisplayNewBadge({
    dateAddedToCatalog,
    isMuseum,
    isObjectOfInterestPage,
  });

  const shouldDisplayDaysRemaining = getShouldDisplayDaysRemainingBadge({
    dateAddedToCatalog,
    firstProposalDate,
    isMuseum,
  });

  const daysRemaining = getDaysLeftInCatalog({
    dateAddedToCatalog,
    firstProposalDate,
  });

  if (daysRemaining < 1) return null;

  const daysText =
    daysRemaining === 1 ? 'last day' : `${daysRemaining} days left`;

  if (shouldDisplayDaysRemaining) {
    return (
      <div className="tw-absolute tw-bottom-3 tw-left-3 tw-z-10 tw-rounded-full tw-bg-white/90 tw-px-2 tw-text-sm tw-uppercase tw-text-red-500 tw-shadow">
        {daysText}
      </div>
    );
  } else if (shouldDisplayNewBadge) {
    return (
      <div className="tw-absolute tw-bottom-3 tw-left-3 tw-z-10 tw-rounded-full tw-bg-white/90 tw-px-2 tw-text-sm tw-uppercase tw-text-blue-500 tw-shadow">
        new
      </div>
    );
  }
  return null;
}

function getDaysSinceAddedToOngoingCycle(dateAddedToCatalog) {
  return differenceInCalendarDays(new Date(), parseJSON(dateAddedToCatalog));
}

function getShouldDisplayNewBadge({
  dateAddedToCatalog,
  isMuseum,
  isObjectOfInterestPage,
}) {
  if (isMuseum) return false;
  if (isObjectOfInterestPage) return false;

  const daysSinceAddedToOngoingCycle =
    getDaysSinceAddedToOngoingCycle(dateAddedToCatalog);

  return daysSinceAddedToOngoingCycle <= 30;
}

export function getLastDateInCatalog(daysLeft) {
  return addDays(new Date().setHours(0, 0, 0, 0), daysLeft - 1);
}

function getShouldDisplayDaysRemainingBadge({
  dateAddedToCatalog,
  firstProposalDate,
  isMuseum,
}) {
  if (isMuseum) return false;

  const daysLeftInCatalog = getDaysLeftInCatalog({
    dateAddedToCatalog,
    firstProposalDate,
  });

  return daysLeftInCatalog <= 30;
}
