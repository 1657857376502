import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';

import {
  dsetPutDonorLocationFailure,
  dsetPutDonorLocationRequest,
  dsetPutDonorLocationSuccess,
  dsetPutDonorTypeFailure,
  dsetPutDonorTypeRequest,
  dsetPutDonorTypeSuccess,
  userUpdateUser,
} from '../../actions';

export const putDonorLocation = (token, userId, data) => {
  return dispatch => {
    dispatch(dsetPutDonorLocationRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/${userId}/donor/location`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(dsetPutDonorLocationSuccess(response.data));
        dispatch(userUpdateUser({ user: response.data }));
      })
      .catch(error => {
        dispatch(dsetPutDonorLocationFailure(error.message));
      });
  };
};

export const putDonorType = (token, userId, data) => {
  return dispatch => {
    dispatch(dsetPutDonorTypeRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/${userId}/donor/type`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(dsetPutDonorTypeSuccess(response.data));
        dispatch(userUpdateUser({ user: response.data }));
      })
      .catch(error => {
        dispatch(dsetPutDonorTypeFailure(error.message));
      });
  };
};
