import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  confirmEmailReset,
  confirmEmailUpdateEmail,
  confirmEmailUpdateEmailId,
  confirmEmailUpdateFormErrors,
  confirmEmailUpdateLoading,
  confirmEmailUpdatePassword,
} from '../../actions';
import { putConfirmEmail } from '../../requests';
import { validateEmail } from '../../lib';
import sha256 from 'crypto-js/sha256';

class ConfirmEmail extends Component {
  static get propTypes() {
    return {
      confirmEmailReset: PropTypes.func,
      email: PropTypes.string,
      emailId: PropTypes.string,
      formErrors: PropTypes.object,
      loading: PropTypes.bool,
      match: PropTypes.shape({
        params: PropTypes.shape({
          emailId: PropTypes.string,
        }),
      }),
      password: PropTypes.string,
      putConfirmEmail: PropTypes.func,
      putConfirmEmailRequest: PropTypes.object,
      token: PropTypes.object,
      updateEmail: PropTypes.func,
      updateEmailId: PropTypes.func,
      updateFormErrors: PropTypes.func,
      updateLoading: PropTypes.func,
      updatePassword: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    const { emailId } = this.props.match.params;
    this.props.updateEmailId({ emailId });
    if (this.props.token) {
      const expiry = new Date(this.props.token.expires).getTime();
      const now = new Date().getTime();
      if (expiry > now) {
        this.props.putConfirmEmail(this.props.token, null, emailId);
        return;
      }
    }
    this.props.updateLoading({ loading: false });
  }

  componentWillUnmount() {
    this.props.confirmEmailReset();
  }

  handleEmailChange = event => {
    this.props.updateEmail({ email: event.target.value });
  };

  handlePasswordChange = event => {
    this.props.updatePassword({ password: event.target.value });
  };

  validateFormAndSubmit = _ => {
    if (this.props.putConfirmEmailRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      emailError: false,
      passwordError: false,
    };

    if (this.props.email === '' || !validateEmail(this.props.email)) {
      errors.formError = true;
      errors.emailError = true;
    }

    if (this.props.password === '') {
      errors.formError = true;
      errors.passwordError = true;
    }

    this.props.updateFormErrors(errors);

    if (errors.formError) {
      return;
    }

    this.props.putConfirmEmail(
      null,
      {
        email: this.props.email,
        passwordHash: sha256(this.props.password).toString(),
      },
      this.props.emailId,
    );
  };

  makeContent = _ => {
    if (this.props.putConfirmEmailRequest.data) {
      return (
        <p className="text text-center">
          Thank you for confirming your email address.
        </p>
      );
    }

    if (this.props.putConfirmEmailRequest.error) {
      return (
        <p className="text text-center label-error">
          Unable to confirm your email address. Please try again.
        </p>
      );
    }

    if (!this.props.loading) {
      return (
        <React.Fragment>
          <div className="mex-mb-26">
            <p
              className={`label ${
                this.props.formErrors.emailError ? 'label-error' : ''
              }`}
            >
              EMAIL ADDRESS
            </p>
            <input
              className={`input ${
                this.props.formErrors.emailError ? 'input-error' : ''
              }`}
              type="text"
              value={this.props.email}
              onChange={this.handleEmailChange}
              placeholder="Email Address"
            ></input>
          </div>
          <div className="mex-mb-26">
            <p
              className={`label ${
                this.props.formErrors.passwordError ? 'label-error' : ''
              }`}
            >
              PASSWORD
            </p>
            <input
              className={`input ${
                this.props.formErrors.passwordError ? 'input-error' : ''
              }`}
              type="password"
              value={this.props.password}
              onChange={this.handlePasswordChange}
              placeholder="Password"
            ></input>
          </div>
          <button
            className="btn btn-primary mex-btn-primary mex-btn-primary-fill mex-mb-12"
            style={{ width: '100%' }}
            onClick={this.validateFormAndSubmit}
          >
            Submit
          </button>
        </React.Fragment>
      );
    }

    return <p className="text text-center">Loading...</p>;
  };

  render() {
    return (
      <div className="background">
        <p className="header text-center mex-mt-47 mex-mb-30">
          Confirm Your Email
        </p>
        <div className="misc-content mx-auto">
          <div className="misc-content-inner mx-auto">{this.makeContent()}</div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state, props) => {
  return {
    email: state.confirmEmailState.formData.email,
    emailId: state.confirmEmailState.emailId,
    formErrors: state.confirmEmailState.formErrors,
    loading: state.confirmEmailState.loading,
    password: state.confirmEmailState.formData.password,
    putConfirmEmailRequest: state.confirmEmailState.putConfirmEmailRequest,
    token: state.authState.token,
    user: state.authState.user,
  };
};

export const mapDispatchToProps = (dispatch, props) => {
  return {
    confirmEmailReset: _ => dispatch(confirmEmailReset()),
    putConfirmEmail: (token, loginCreds, emailId) =>
      dispatch(putConfirmEmail(token, loginCreds, emailId)),
    updateEmail: payload => dispatch(confirmEmailUpdateEmail(payload)),
    updateEmailId: payload => dispatch(confirmEmailUpdateEmailId(payload)),
    updateFormErrors: payload =>
      dispatch(confirmEmailUpdateFormErrors(payload)),
    updateLoading: payload => dispatch(confirmEmailUpdateLoading(payload)),
    updatePassword: payload => dispatch(confirmEmailUpdatePassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
