import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'donorAccounts',
  initialState: {
    destFolder: null,
    draftDeleteModalOpen: false,
    moveMenuOpen: false,
    moveModalOpen: false,
    network: null,
    newAccountModalOpen: false,
    selectedObject: null,
    successMessage: null,
    transferModalOpen: false,
    transferEmailModalOpen: false,
    transferTarget: null,
    invitedDonorAccount: null,
    deleteDraftRequest: {
      loading: false,
      data: null,
      error: '',
    },
    newAccountFormData: {
      name: '',
    },
    newAccountFormErrors: {
      formError: false,
      nameError: false,
    },
    postAccountRequest: {
      loading: false,
      data: null,
      error: '',
    },
    putMoveObjectRequest: {
      loading: false,
      data: null,
      error: '',
    },
    putTransferObjectRequest: {
      loading: false,
      data: null,
      error: '',
    },
    transferEmailFormData: {
      email: '',
    },
    transferEmailFormErrors: {
      formError: false,
      emailError: false,
    },
  },
  reducers: {
    dacctDeleteDraftClear: state => {
      state.deleteDraftRequest = {
        loading: false,
        data: null,
        error: '',
      };
    },
    dacctDeleteDraftFailure: (state, { payload }) => {
      state.deleteDraftRequest = {
        ...state.deleteDraftRequest,
        loading: false,
        error: payload,
      };
    },
    dacctDeleteDraftRequest: (state, { payload }) => {
      state.deleteDraftRequest = {
        ...state.deleteDraftRequest,
        loading: true,
        error: '',
      };
    },
    dacctDeleteDraftSuccess: (state, { payload }) => {
      state.deleteDraftRequest = {
        ...state.deleteDraftRequest,
        loading: false,
        data: payload,
        error: '',
      };
    },
    dacctUpdateNewAccountFormErrors: (state, { payload }) => {
      state.newAccountFormErrors = payload.formErrors;
    },
    dacctUpdateNewAccountName: (state, { payload: { name } }) => {
      state.newAccountFormData.name = name;
    },
    dacctClearNewAccountForm: state => {
      state.newAccountFormData.name = '';
      state.newAccountFormErrors = {
        formError: false,
        nameError: false,
      };
    },
    dacctSetSelectedObject: (state, { payload }) => {
      state.selectedObject = payload;
    },
    dacctSetSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    dacctSetActiveObjects: (state, { payload }) => {
      state.selectedAccountObjects.activeObjects = payload;
    },
    dacctSetInactiveObjects: (state, { payload }) => {
      state.selectedAccountObjects.inactiveObjects = payload;
    },
    dacctSetDraftDeleteModalOpen: (state, { payload }) => {
      state.draftDeleteModalOpen = payload;
    },
    dacctSetNewAccountModalOpen: (state, { payload }) => {
      state.newAccountModalOpen = payload;
    },
    dacctSetMoveMenuOpen: (state, { payload }) => {
      state.moveMenuOpen = payload;
    },
    dacctSetMoveModalOpen: (state, { payload }) => {
      state.moveModalOpen = payload;
    },
    dacctSetTransferModalOpen: (state, { payload }) => {
      state.transferModalOpen = payload;
    },
    dacctSetTransferEmailModalOpen: (state, { payload }) => {
      state.transferEmailModalOpen = payload;
    },
    dacctSetTransferTarget: (state, { payload }) => {
      state.transferTarget = payload;
    },
    dacctSetDestFolder: (state, { payload }) => {
      state.destFolder = payload;
    },
    dacctResetTransferEmail: state => {
      state.transferEmailFormData = {
        email: '',
      };
      state.transferEmailFormErrors = {
        formError: false,
        emailError: false,
      };
    },
    dacctUpdateTransferEmail: (state, { payload }) => {
      state.transferEmailFormData = {
        ...state.newAccountFormData,
        email: payload.email,
      };
    },
    dacctUpdateTransferEmailFormErrors: (state, { payload }) => {
      state.transferEmailFormErrors = payload.formErrors;
    },
  },
});

export const dacctSetSuccessMessageWithTimeout = ({
  message,
  timeout = 5000,
}) => {
  return dispatch => {
    dispatch(dacctActions.dacctSetSuccessMessage(message));
    setTimeout(
      _ => dispatch(dacctActions.dacctSetSuccessMessage(null)),
      timeout,
    );
  };
};

export const { reducer: dacctReducer, actions } = slice;

export const dacctActions = { ...actions, dacctSetSuccessMessageWithTimeout };

export const selectSuccessMessage = state =>
  state.donorAccountsState.successMessage;
