import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ddonmResetAll, objDraftReset } from '../../actions';
import { getNaturallySortedAccounts, validateAccountOwner } from '@lib';
import AddObjBtnIcon from '../../images/add-obj-btn-icon.png';
import CloseIcon from '../../images/grey-close-icon.png';
import NewFolderIcon from '../../images/new-folder-icon.png';
import '../../styles/donor-account.css';
import '../../styles/general.css';
import '../../styles/modal.css';
import '../../styles/people-picker-modal.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { DonorAccountCard } from './DonorAccount/DonorAccountCard';
import { NestedSection } from 'components/_shared/NestedSection';
import DonorAccountTransferObjectModal from './DonorAccount/DonorAccountTransferObject/DonorAccountTransferObjectModal';
import DonorAccountTransferEmailModal from './DonorAccount/DonorAccountTransferObject/DonorAccountTransferEmailModal';
import MoveObjectModal from './DonorAccount/MoveObjectModal';
import { DeleteObjectDraftModal } from './DonorAccount/DeleteObjectDraftModal';
import { DonorObjectsList } from './DonorAccount/DonorObjectsList';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';

import {
  useGetCyclesSortedQuery,
  useGetDonorDonationsQuery,
  useGetDonorAccountActiveObjectsQuery,
  useGetDonorAccountGeneralQuery,
  useGetDonorAccountInactiveObjectsQuery,
  useGetDonorAccountsQuery,
  useGetMuseumAccountsSimpleQuery,
  useGetProposalsDonorAccountQuery,
  usePostDonorAccountMutation,
} from 'requests/api';

export default function DonorAccountsLanding() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.authState.token);

  const moveModalOpen = useSelector(
    state => state.donorAccountsState.moveModalOpen,
  );

  const newAccountModalOpen = useSelector(
    state => state.donorAccountsState.newAccountModalOpen,
  );

  const newAccountFormData = useSelector(
    state => state.donorAccountsState.newAccountFormData,
  );
  const newAccountFormErrors = useSelector(
    state => state.donorAccountsState.newAccountFormErrors,
  );

  const transferModalOpen = useSelector(
    state => state.donorAccountsState.transferModalOpen,
  );
  const transferEmailModalOpen = useSelector(
    state => state.donorAccountsState.transferEmailModalOpen,
  );
  const userId = useSelector(state => state.authState.token.userId);

  useEffect(
    function resetState() {
      dispatch(ddonmResetAll());

      dispatch(objDraftReset());
      dispatch(dacctActions.dacctSetSelectedObject(null));
    },
    [dispatch],
  );

  const { data: cyclesSorted } = useGetCyclesSortedQuery();

  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isFetching: isFetchingAccounts,
    error: errorAccounts,
  } = useGetDonorAccountsQuery(userId);

  const {
    data: selectedAccount,
    isLoading: isLoadingAccount,
    isErrorAccount,
  } = useGetDonorAccountGeneralQuery();

  const { data: donations, isLoading: isDonationsLoading } =
    useGetDonorDonationsQuery(selectedAccount?.id, {
      skip: !selectedAccount,
    });

  const { data: activeObjects } = useGetDonorAccountActiveObjectsQuery(
    selectedAccount?.id,
    { skip: !selectedAccount },
  );

  const { data: proposals, isLoading: isLoadingProposals } =
    useGetProposalsDonorAccountQuery(selectedAccount?.id, {
      skip: !selectedAccount,
    });

  const { data: inactiveObjects } = useGetDonorAccountInactiveObjectsQuery(
    selectedAccount?.id,
    { skip: !selectedAccount },
  );

  const { data: museums } = useGetMuseumAccountsSimpleQuery();

  const [postDonorAccount, { isLoading: isLoadingPostDonorMutation }] =
    usePostDonorAccountMutation();

  useEffect(() => {
    return () => {
      // note: clean up
      dispatch(dacctActions.dacctDeleteDraftClear());
      dispatch(dacctActions.dacctSetDestFolder(null));

      // this means to set the draft delete modal closed
      dispatch(dacctActions.dacctSetDraftDeleteModalOpen());
      dispatch(dacctActions.dacctSetNewAccountModalOpen(false));

      dispatch(dacctActions.dacctSetMoveMenuOpen(false));
      dispatch(dacctActions.dacctSetMoveModalOpen(false));
      dispatch(dacctActions.dacctSetTransferModalOpen(false));
      dispatch(dacctActions.dacctSetTransferTarget(null));
      dispatch(dacctActions.dacctResetTransferEmail());
    };
  }, [dispatch]);

  function makeProcessOverview() {
    if (Array.isArray(donations) && donations.length > 3) {
      return null;
    }

    return (
      <div className="da-process-container">
        <div className="d-flex">
          <div className="da-process-title-container">
            <p className="da-process-title">
              Process
              <br />
              Overview
            </p>
          </div>
          <div className="da-process-step-container d-flex">
            <div className="da-process-step-container-left">
              <p className="da-process-step-txt-number">1</p>
            </div>
            <div className="da-process-step-container-right">
              <div className="spc-mb-2">
                <p className="da-process-step-txt-title-sm">SUBMIT AN OBJECT</p>
              </div>
              <p className="da-process-step-txt">
                Provide object information and photography to be featured in the
                next Museum Exchange catalogue. Our team will review the object
                before approving it for the upcoming catalogue.
              </p>
            </div>
          </div>
          <div className="da-process-step-container d-flex">
            <div className="da-process-step-container-left">
              <p className="da-process-step-txt-number">2</p>
            </div>
            <div className="da-process-step-container-right">
              <div className="spc-mb-2">
                <p className="da-process-step-txt-title-sm">
                  DISPLAY IN OUR CATALOGUE
                </p>
              </div>
              <p className="da-process-step-txt">
                Your object is viewed by curators and directors at the top
                museums across the U.S. and Canada to be considered for
                acquisition.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex spc-mt-30">
          <div className="da-process-step-container d-flex">
            <div className="da-process-step-container-left">
              <p className="da-process-step-txt-number">3</p>
            </div>
            <div className="da-process-step-container-right">
              <div className="spc-mb-2">
                <p className="da-process-step-txt-title-sm">
                  COLLECT PROPOSALS
                </p>
              </div>
              <p className="da-process-step-txt">
                Museums submit proposals to argue why they will make the best
                permanent home for your donated object.
              </p>
            </div>
          </div>
          <div className="da-process-step-container d-flex">
            <div className="da-process-step-container-left">
              <p className="da-process-step-txt-number">4</p>
            </div>
            <div className="da-process-step-container-right">
              <div className="spc-mb-2">
                <p className="da-process-step-txt-title-sm">
                  MATCH WITH A MUSEUM
                </p>
              </div>
              <p className="da-process-step-txt">
                Once the catalogue display period ends, you will review museums'
                proposals to select the best fit for your donation.
              </p>
            </div>
          </div>
          <div className="da-process-step-container d-flex">
            <div className="da-process-step-container-left">
              <p className="da-process-step-txt-number">5</p>
            </div>
            <div className="da-process-step-container-right">
              <div className="spc-mb-2">
                <p className="da-process-step-txt-title-sm">DONATE WITH EASE</p>
              </div>
              <p className="da-process-step-txt">
                Use our digital platform to simplify and expedite the donation
                process. Museum Exchange will manage your appraisal and all
                required tax forms.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function makeFolders() {
    const folders = [];

    if (Array.isArray(accounts)) {
      getNaturallySortedAccounts(accounts).forEach(f => {
        if (validateAccountOwner(f.account.users, token.userId)) {
          folders.push(
            <Link
              key={`folder_card_${f.account.id}`}
              to={`/donor/folders/${f.account.id}`}
              className="gen-link-no-decoration"
            >
              <DonorAccountCard
                account={f.account}
                numberOfObjects={f.numberOfObjects}
              />
            </Link>,
          );
        }
      });
    }
    return folders;
  }

  function makeContent() {
    const isLoading =
      isLoadingAccount ||
      isLoadingAccounts ||
      isLoadingProposals ||
      isDonationsLoading;

    if (isLoading) return null;

    if (isErrorAccount) {
      return (
        <div>
          <p className="da-error-txt text-center">
            Error getting donor information. Please try again.
          </p>
        </div>
      );
    }

    const cannotFindAccount = !selectedAccount;

    if (cannotFindAccount) {
      // TODO: What to return here? Nothing? Same error as above?
      return (
        <div>
          <p className="da-error-txt text-center">
            Error getting donor information. Please try again.
          </p>
        </div>
      );
    }

    // TODO: how to fix this
    const isDonorAccountsListEmpty = errorAccounts?.status === 404;

    const hasActiveItems = activeObjects?.length > 0;
    const hasInactiveItems = inactiveObjects?.length > 0;

    const isEmpty =
      isDonorAccountsListEmpty && !hasActiveItems && !hasInactiveItems;

    const hasObjectsNotWithinCollection = hasActiveItems || hasInactiveItems;

    if (isEmpty) {
      return (
        <div>
          <div className="d-flex da-tabs-container mx-auto">
            <div className="da-tab da-tab-active gen-cursor-hover-link">
              <p className="da-tab-text-active">My Objects</p>
            </div>
            <Link to="/donor/shared" className="gen-link-no-decoration">
              <div className="da-tab gen-cursor-hover-link">
                <p className="da-tab-text-inactive">Shared with Me</p>
              </div>
            </Link>
          </div>
          <div className="da-empty-container d-flex">
            <div className="da-empty-left">
              <p className="da-empty-title">
                Welcome to
                <br />
                Museum Exchange
              </p>
              <div className="spc-mt-25 spc-mb-30">
                <p className="da-empty-txt-lg">
                  Upload your first object to be featured in our next catalogue.
                  Museums across the United States and Canada will be able to
                  view your object and submit a proposal for your donation.
                </p>
              </div>
              <div className="d-flex tw-gap-7">
                <Link
                  to={`/donor/folders/${selectedAccount.id}/object/draft/1`}
                  className="gen-link-no-decoration"
                >
                  <div className="da-header-new-obj-btn-container">
                    <div className="da-btn-new-obj d-flex justify-content-center">
                      <p>NEW OBJECT</p>
                      <img
                        src={AddObjBtnIcon}
                        alt="add-object"
                        className="da-btn-new-obj-icon"
                      />
                    </div>
                  </div>
                </Link>
                <div
                  className="da-header-new-obj-btn-container"
                  onClick={_ =>
                    dispatch(dacctActions.dacctSetNewAccountModalOpen(true))
                  }
                >
                  <div className="da-btn-new-obj d-flex justify-content-center">
                    <p>NEW FOLDER</p>
                    <img
                      src={NewFolderIcon}
                      alt="new-folder"
                      className="da-new-folder-icon"
                    />
                  </div>
                </div>
              </div>
              <div className="da-empty-steps-container">
                <p className="da-empty-steps-title">Process Overview</p>
                <div className="da-empty-step-container d-flex">
                  <div className="da-empty-step-container-left">
                    <p className="da-empty-step-txt-number">1</p>
                  </div>
                  <div className="da-empty-step-container-right">
                    <div className="spc-mb-2">
                      <p className="da-empty-step-txt-title-sm">
                        SUBMIT AN OBJECT
                      </p>
                    </div>
                    <p className="da-empty-step-txt">
                      Provide object information and photography to be featured
                      in the next Museum Exchange catalogue. Our team will
                      review the object before approving it for the upcoming
                      catalogue.
                    </p>
                  </div>
                </div>
                <div className="da-empty-step-container d-flex">
                  <div className="da-empty-step-container-left">
                    <p className="da-empty-step-txt-number">2</p>
                  </div>
                  <div className="da-empty-step-container-right">
                    <div className="spc-mb-2">
                      <p className="da-empty-step-txt-title-sm">
                        DISPLAY IN OUR CATALOGUE
                      </p>
                    </div>
                    <p className="da-empty-step-txt">
                      Your object is viewed by curators and directors at the top
                      museums across the U.S. and Canada to be considered for
                      acquisition.
                    </p>
                  </div>
                </div>
                <div className="da-empty-step-container d-flex">
                  <div className="da-empty-step-container-left">
                    <p className="da-empty-step-txt-number">3</p>
                  </div>
                  <div className="da-empty-step-container-right">
                    <div className="spc-mb-2">
                      <p className="da-empty-step-txt-title-sm">
                        COLLECT PROPOSALS
                      </p>
                    </div>
                    <p className="da-empty-step-txt">
                      Museums submit proposals to argue why they will make the
                      best permanent home for your donated object.
                    </p>
                  </div>
                </div>
                <div className="da-empty-step-container d-flex">
                  <div className="da-empty-step-container-left">
                    <p className="da-empty-step-txt-number">4</p>
                  </div>
                  <div className="da-empty-step-container-right">
                    <div className="spc-mb-2">
                      <p className="da-empty-step-txt-title-sm">
                        MATCH WITH A MUSEUM
                      </p>
                    </div>
                    <p className="da-empty-step-txt">
                      Once the catalogue display period ends, you will review
                      museums' proposals to select the best fit for your
                      donation.
                    </p>
                  </div>
                </div>
                <div className="da-empty-step-container d-flex">
                  <div className="da-empty-step-container-left">
                    <p className="da-empty-step-txt-number">5</p>
                  </div>
                  <div className="da-empty-step-container-right">
                    <div className="spc-mb-2">
                      <p className="da-empty-step-txt-title-sm">
                        DONATE WITH EASE
                      </p>
                    </div>
                    <p className="da-empty-step-txt">
                      Use our digital platform to simplify and expedite the
                      donation process. Museum Exchange will manage your
                      appraisal and all required tax forms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="https://res.cloudinary.com/ex-tech/image/fetch/w_1569,h_2196,q_auto/https://extechimagesmisc.s3.amazonaws.com/donor-catalogue-empty-2.png"
              alt="mock-catalogue"
              className="da-empty-img-ctg"
            />
          </div>
        </div>
      );
    }

    let folders = null;
    if (accounts) {
      folders = makeFolders();
    }

    return (
      <div>
        <div className="d-flex da-tabs-container">
          <div className="da-tab da-tab-active gen-cursor-hover-link">
            <p className="da-tab-text-active">My Objects</p>
          </div>
          <Link to="/donor/shared" className="gen-link-no-decoration">
            <div className="da-tab gen-cursor-hover-link">
              <p className="da-tab-text-inactive">Shared with Me</p>
            </div>
          </Link>
        </div>

        <div>
          <div className="d-flex da-header justify-content-between mex-mb-20">
            <p className="da-sec-txt-header">Folders</p>
            <div className="d-flex">
              <div className="da-header-new-obj-btn-container da-header-new-folder-btn-contatiner">
                <div
                  className="da-btn-new-obj d-flex justify-content-center gen-cursor-hover-link"
                  onClick={_ =>
                    dispatch(dacctActions.dacctSetNewAccountModalOpen(true))
                  }
                >
                  <p>NEW FOLDER</p>
                  <img
                    src={NewFolderIcon}
                    alt="new-folder"
                    className="da-new-folder-icon"
                  />
                </div>
              </div>
            </div>
          </div>
          {folders && folders.length ? (
            <div className={`tw-relative tw-mb-12`}>
              <NestedSection>
                <div
                  className={`tw-grid tw-grid-cols-3 tw-gap-3 tw-p-4 ${
                    isFetchingAccounts ? 'tw-opacity-40' : ''
                  }`}
                >
                  {folders}
                </div>
                {isFetchingAccounts ? (
                  <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
                    <svg
                      role="status"
                      className="tw-mr-2 tw-h-16 tw-w-16 tw-animate-spin tw-fill-blue-400 tw-text-gray-200"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : null}
              </NestedSection>
            </div>
          ) : (
            <div className="tw-mb-12">
              <NestedSection>
                <div className="tw-mx-auto tw-grid tw-max-w-sm tw-grid-cols-1 tw-gap-3 tw-p-10 tw-text-center">
                  <div className="tw-text-2xl tw-font-light tw-italic tw-text-gray-600">
                    No Folders
                  </div>
                  <div className="tw-max-w-xs tw-text-sm tw-text-gray-600">
                    Create a folder to organize your objects and share access
                    with other users.
                  </div>
                </div>
              </NestedSection>
            </div>
          )}
        </div>
        <div>
          <div className="d-flex da-header justify-content-between mex-mb-20">
            <p className="da-sec-txt-header">Objects</p>
            <div className="d-flex">
              <Link
                to={`/donor/folders/${selectedAccount.id}/object/draft/1`}
                className="gen-link-no-decoration"
              >
                <div className="da-header-new-obj-btn-container">
                  <div className="da-btn-new-obj d-flex justify-content-center">
                    <p>NEW OBJECT</p>
                    <img
                      src={AddObjBtnIcon}
                      alt="add-object"
                      className="da-btn-new-obj-icon"
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <NestedSection>
            {hasObjectsNotWithinCollection ? (
              <div className="tw-p-5">
                <DonorObjectsList
                  selectedAccount={selectedAccount}
                  inactiveObjects={inactiveObjects}
                  activeObjects={activeObjects}
                  cycles={cyclesSorted}
                  donations={donations}
                  museums={museums}
                  proposals={proposals}
                />
              </div>
            ) : (
              <div className="mx-auto tw-mx-auto tw-grid tw-max-w-sm tw-gap-3 tw-p-10 tw-text-center">
                <div className="tw-text-2xl tw-font-light tw-italic tw-text-gray-600">
                  No objects
                </div>
                <div className="tw-text-gray-600">
                  Objects not placed in a folder will be shown here.
                </div>
              </div>
            )}
          </NestedSection>
          {makeProcessOverview()}
        </div>
      </div>
    );
  }

  const content = makeContent();

  return (
    <div
      className="gen-background-nav-donor"
      onClick={_ => {
        dispatch(dacctActions.dacctSetSelectedObject(null));
        dispatch(dacctActions.dacctSetMoveMenuOpen(false));
      }}
    >
      <div className="da-landing-content mx-auto mex-mb-30">{content}</div>
      <DeleteObjectDraftModal selectedAccount={selectedAccount} />
      {newAccountModalOpen ? (
        <div className="mod">
          <div
            className="da-new-folder-modal mx-auto"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className="d-flex da-new-folder-modal-top-container">
              <p className="da-new-folder-modal-title">New Folder</p>
              <img
                src={CloseIcon}
                alt="close-icon"
                className="da-new-folder-modal-close-icon gen-cursor-hover-link"
                onClick={_ => {
                  // TODO: is this necessary?
                  // postDonorAccountClear();
                  dispatch(dacctActions.dacctSetNewAccountModalOpen(false));
                  dispatch(dacctActions.dacctClearNewAccountForm());
                }}
              />
            </div>
            <input
              type="text"
              className={`input input-no-bg ${
                newAccountFormErrors.nameError ? 'input-error' : ''
              }`}
              onChange={event =>
                dispatch(
                  dacctActions.dacctUpdateNewAccountName({
                    name: event.target.value,
                  }),
                )
              }
              value={newAccountFormData.name}
              maxLength="100"
              placeholder="Folder Name"
            ></input>
            <div className="d-flex da-new-folder-modal-bottom-container">
              <div
                className="da-new-folder-modal-cancel-btn gen-cursor-hover-link"
                onClick={_ => {
                  dispatch(dacctActions.dacctSetNewAccountModalOpen(false));
                  dispatch(dacctActions.dacctClearNewAccountForm());
                }}
              >
                <p>CANCEL</p>
              </div>
              <div
                className="da-new-folder-modal-ok-btn gen-cursor-hover-link"
                onClick={async _ => {
                  if (isLoadingPostDonorMutation) {
                    return;
                  }

                  const formErrors = {
                    formError: false,
                    nameError: false,
                  };

                  if (!newAccountFormData.name) {
                    formErrors.formError = true;
                    formErrors.nameError = true;
                  }

                  dispatch(
                    dacctActions.dacctUpdateNewAccountFormErrors({
                      formErrors,
                    }),
                  );

                  if (formErrors.formError) {
                    return;
                  }
                  try {
                    const result = await postDonorAccount(newAccountFormData);
                    dispatch(dacctActions.dacctSetNewAccountModalOpen(false));
                    dispatch(dacctActions.dacctClearNewAccountForm());
                  } catch {}
                }}
              >
                <p>OK</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {moveModalOpen && <MoveObjectModal />}
      {transferModalOpen && <DonorAccountTransferObjectModal />}
      {transferEmailModalOpen && <DonorAccountTransferEmailModal />}
    </div>
  );
}
