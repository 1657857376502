import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  authPutResetClear,
  resetResetConfirm,
  resetUpdatePassword,
  resetUpdateResetId,
  resetUpdateConfirmFormErrors,
} from '../../actions';
import { authPutReset } from '../../requests';
import { validatePassword } from '../../lib';
import sha256 from 'crypto-js/sha256';

class ConfirmReset extends Component {
  static get propTypes() {
    return {
      formErrors: PropTypes.object,
      match: PropTypes.shape({
        params: PropTypes.shape({
          resetId: PropTypes.string,
        }),
      }),
      password: PropTypes.string,
      resetId: PropTypes.string,
      putReset: PropTypes.func,
      putResetClear: PropTypes.func,
      putResetRequest: PropTypes.object,
      resetConfirm: PropTypes.func,
      updatePassword: PropTypes.func,
      updateResetId: PropTypes.func,
      updateConfirmFormErrors: PropTypes.func,
    };
  }

  componentDidMount() {
    const { resetId } = this.props.match.params;
    this.props.updateResetId({ resetId });
  }

  componentWillUnmount() {
    this.props.resetConfirm();
    this.props.putResetClear();
  }

  handlePasswordChange = event => {
    this.props.updatePassword({ password: event.target.value });
  };

  validateFormAndSubmitReset = _ => {
    if (this.props.putResetRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      passwordError: false,
    };

    this.setState({ formErrors: errors }, _ => {
      if (
        this.props.password === '' ||
        !validatePassword(this.props.password)
      ) {
        errors.formError = true;
        errors.passwordError = true;
      }

      this.props.updateConfirmFormErrors(errors);

      if (errors.formError) {
        return;
      }

      this.props.putReset({
        resetId: this.props.resetId,
        passwordHash: sha256(this.props.password).toString(),
      });
    });
  };

  render() {
    let content = (
      <React.Fragment>
        <div className="mex-mb-26">
          <p
            className={`label ${
              this.props.formErrors.passwordError ? 'label-error' : ''
            }`}
          >
            PASSWORD
          </p>
          <input
            className={`input ${
              this.props.formErrors.passwordError ? 'input-error' : ''
            }`}
            type="password"
            value={this.props.password}
            onChange={this.handlePasswordChange}
            placeholder="Password"
          ></input>
          {this.props.formErrors.passwordError && (
            <div
              className="alert alert-danger mex-alert-danger mex-mt-9"
              style={{ height: '110px' }}
            >
              Your password must be 8-32 characters long and contain one upper
              case letter, one lower case letter, a number, and a symbol.
            </div>
          )}
        </div>
        <button
          className="btn btn-primary mex-btn-primary mex-btn-primary-fill"
          style={{ width: '100%' }}
          onClick={this.validateFormAndSubmitReset}
        >
          Reset Password
        </button>
        {this.props.putResetRequest.error && (
          <div className="alert alert-danger mex-alert-danger mex-mt-9">
            Please try again.
          </div>
        )}
      </React.Fragment>
    );

    if (this.props.putResetRequest.data) {
      content = (
        <React.Fragment>
          <p className="text mex-mb-12">Your password has been reset.</p>
          <Link className="link" to="/login">
            Return to Login
          </Link>
        </React.Fragment>
      );
    }

    return (
      <div className="background">
        <p className="header text-center mex-mt-47">Reset Password</p>
        <div className="sub-header mx-auto mex-mb-30">
          <p className="text text-center">Please enter a new password</p>
        </div>
        <div className="misc-content mx-auto">
          <div className="misc-content-inner mx-auto">{content}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formErrors: state.resetState.resetConfirmFormErrors,
    password: state.resetState.resetConfirmFormData.password,
    resetId: state.resetState.resetConfirmFormData.resetId,
    putResetRequest: state.authState.putResetRequest,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putReset: payload => dispatch(authPutReset(payload)),
    putResetClear: _ => dispatch(authPutResetClear()),
    resetConfirm: _ => dispatch(resetResetConfirm()),
    updatePassword: payload => dispatch(resetUpdatePassword(payload)),
    updateResetId: payload => dispatch(resetUpdateResetId(payload)),
    updateConfirmFormErrors: payload =>
      dispatch(resetUpdateConfirmFormErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmReset);
