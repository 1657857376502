import React from 'react';
import { useSelector } from 'react-redux';
import {
  ActionLink,
  DonationIcon,
  DonationRow,
  DonationSection,
} from './DonationSections';

export function DonationInformationSection({ donation }) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );

  if (!donation) return null;
  if (!['donor', 'museum'].includes(userAccountType)) return null;

  const lookupMap = {
    donor: {
      donationDetailsLink: `/donor/folders/${donation.donorAccountId}/donations/${donation.id}/details`,
    },
    museum: {
      donationDetailsLink: `/institution/donations/${donation.id}/details`,
    },
  };

  const vars = lookupMap[userAccountType];

  return (
    <DonationSection
      title="Donation Information"
      completedAction={
        <ActionLink
          secondary
          href={vars.donationDetailsLink}
          text="View Donation Details"
        />
      }
      icon={<DonationIcon />}
    >
      <DonationRow
        tenant="Institution"
        action={
          <ActionLink
            href={`/institution/donations/${donation.id}/preliminary/approval-dates`}
            text="Complete Form"
          />
        }
        rowTitle="Approval Dates"
        isCompleted={
          donation.events.completed ||
          donation.museumInfo.approvalDatesSubmitted
        }
        // assignee={donation.museumRegistrar}
      />
      <DonationRow
        tenant="Institution"
        action={
          <ActionLink
            href={`/institution/donations/${donation.id}/preliminary/preshipping`}
            text="Complete Form"
          />
        }
        rowTitle="Pre-Shipping Requirements"
        isCompleted={
          donation.events.completed ||
          donation.museumInfo.preshippingRequirementsSubmitted
        }
        // assignee={donation.museumRegistrar}
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Pick Up Information"
        action={
          <ActionLink
            href={`/donor/folders/${donation.donorAccountId}/donation/${donation.id}/preliminary-shipping-info`}
            text="Complete Form"
          />
        }
        isCompleted={
          donation.events.completed || donation.donorInfo.shippingInfoSubmitted
        }
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Packing Information"
        action={
          <ActionLink
            href={`/donor/folders/${donation.donorAccountId}/donation/${donation.id}/preliminary-object-info`}
            text="Complete Form"
          />
        }
        isCompleted={
          donation.events.completed || donation.objectInfo.objectInfoSubmitted
        }
      />
    </DonationSection>
  );
}
