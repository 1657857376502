import { useParams } from 'react-router-dom';
import { useGetDonorAccountQuery } from 'requests/api';

export function useCurrentDonorAccountUrl() {
  const { accountId } = useParams();
  const { data: account } = useGetDonorAccountQuery(accountId);
  const isGeneral = account?.general;

  if (!account) return '/donor';

  const currentDonorAccountUrl = isGeneral
    ? '/donor'
    : `/donor/folders/${account?.id}`;

  return currentDonorAccountUrl;
}
