export function getNaturallySortedAccounts(accounts) {
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  });

  return accounts
    .slice()
    .sort((a, b) => collator.compare(a.account.name, b.account.name));
}
