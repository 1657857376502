import {
  LOGIN_RESET,
  LOGIN_UPDATE_EMAIL,
  LOGIN_UPDATE_FORM_ERRORS,
  LOGIN_UPDATE_PASSWORD,
  LOGIN_VERIFY_RESET,
  LOGIN_VERIFY_UPDATE_CODE,
  LOGIN_VERIFY_UPDATE_FORM_ERRORS,
} from '../../actions/actionTypes';

const initialLoginState = {
  formData: {
    email: '',
    password: '',
  },
  formErrors: {
    formError: false,
    emailError: false,
    passwordError: false,
  },
  verifyFormData: {
    code: '',
  },
  verifyFormErrors: {
    formError: false,
    codeError: false,
  },
};

export const loginReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case LOGIN_RESET:
      return {
        ...state,
        formData: {
          email: '',
          password: '',
        },
        formErrors: {
          formError: false,
          emailError: false,
          passwordError: false,
        },
      };

    case LOGIN_UPDATE_EMAIL:
      return {
        ...state,
        formData: { ...state.formData, email: action.payload.email },
      };

    case LOGIN_UPDATE_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };

    case LOGIN_UPDATE_PASSWORD:
      return {
        ...state,
        formData: { ...state.formData, password: action.payload.password },
      };

    case LOGIN_VERIFY_RESET:
      return {
        ...state,
        verifyFormData: {
          code: '',
        },
        verifyFormErrors: {
          formError: false,
          codeError: false,
        },
      };

    case LOGIN_VERIFY_UPDATE_CODE:
      return {
        ...state,
        verifyFormData: {
          ...state.verifyFormData,
          code: action.payload.code,
        },
      };

    case LOGIN_VERIFY_UPDATE_FORM_ERRORS:
      return {
        ...state,
        verifyFormErrors: action.payload.formErrors,
      };

    default:
      return state;
  }
};
