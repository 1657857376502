import {
  SIGNUP_RESET,
  SIGNUP_SELECT_ACCOUNT_TYPE,
  SIGNUP_UPDATE_EMAIL,
  SIGNUP_UPDATE_FORM_ERRORS,
  SIGNUP_UPDATE_NAME_FIRST,
  SIGNUP_UPDATE_NAME_LAST,
  SIGNUP_UPDATE_PASSWORD,
} from '../actionTypes';

export const signupReset = _ => {
  return {
    type: SIGNUP_RESET,
  };
};

export const signupSelectAccountType = payload => {
  return {
    type: SIGNUP_SELECT_ACCOUNT_TYPE,
    payload,
  };
};

export const signupUpdateEmail = payload => {
  return {
    type: SIGNUP_UPDATE_EMAIL,
    payload,
  };
};

export const signupUpdateFormErrors = payload => {
  return {
    type: SIGNUP_UPDATE_FORM_ERRORS,
    payload,
  };
};

export const signupUpdateNameFirst = payload => {
  return {
    type: SIGNUP_UPDATE_NAME_FIRST,
    payload,
  };
};

export const signupUpdateNameLast = payload => {
  return {
    type: SIGNUP_UPDATE_NAME_LAST,
    payload,
  };
};

export const signupUpdatePassword = payload => {
  return {
    type: SIGNUP_UPDATE_PASSWORD,
    payload,
  };
};
