import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import { setSelectedImg } from '../../actions';
import 'react-image-lightbox/style.css';
import '../../styles/general.css';
import '../../styles/object-details.css';
import '../../styles/object-detail-catalogue.css';

export default function ObjectDetailsImages({ obj }) {
  const selectedImg = useSelector(
    state => state.imageCarouselState.selectedImg,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedImg(null));
    };
  }, []);

  const makeImages = _ => {
    const makeImage = img => {
      return (
        <a
          key={img.imageId}
          href="# "
          className="gen-link-no-decoration odc-img-zoom"
          onClick={e => {
            e.preventDefault();
            dispatch(setSelectedImg(img));
          }}
        >
          <img
            src={`https://res.cloudinary.com/ex-tech/image/fetch/h_398,w_450,c_pad,b_white,q_auto/https://${img.bucket}.s3.amazonaws.com/${img.imageId}`}
            alt={img.fileName}
            className="od-img-img"
          />
        </a>
      );
    };

    const elements = [];

    if (obj.primaryImage) {
      elements.push(makeImage(obj.primaryImage));
    }

    if (Array.isArray(obj.supportingImages)) {
      return elements.concat(obj.supportingImages.map(v => makeImage(v)));
    }

    return elements;
  };

  return (
    <div className="od-img-content-imgs d-flex flex-wrap">
      {makeImages()}
      {selectedImg && (
        <Lightbox
          mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${selectedImg.bucket}.s3.amazonaws.com/${selectedImg.imageId}`}
          onCloseRequest={_ => dispatch(setSelectedImg(null))}
          imagePadding={65}
          clickOutsideToClose={false}
        />
      )}
    </div>
  );
}
