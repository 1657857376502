import { openBase64PDFNewWindow } from '@lib';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyGetDonationAppraisalReportQuery } from 'requests/api';
import { ActionLink, DonationRow, DonationSection } from './DonationSections';

export function AppraisalSection({ donation }) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );
  const [getAppraisalReport, { isLoading: isLoadingGetAppraisalReport }] =
    useLazyGetDonationAppraisalReportQuery();

  if (!donation) return null;
  if (!['donor', 'museum'].includes(userAccountType)) return null;
  if (donation?.events?.completed && !donation?.appraisal?.report) {
    return null;
  }
  if (donation?.donorInfo?.taxFiling == false) return null;

  const lookupMap = {
    donor: {
      liaison: `/donor/folders/${donation.donorAccountId}/donations/${donation.id}/details`,
    },
    museum: {
      liaison: `/institution/donations/${donation.id}/details`,
    },
  };

  const vars = lookupMap[userAccountType];

  return (
    <DonationSection
      title="Appraisal"
      learnMoreModalContent={
        <div>
          An appraisal report written by a qualified appraiser is required by
          the IRS for any donated object (or group of similar objects) valued
          over $5,000. The appraisal must be signed and dated no earlier than 60
          days before the date of contribution. The cost of the appraisal is
          covered by the donor and invoiced directly by Museum Exchange.{' '}
          <a
            href="https://www.irs.gov/pub/irs-pdf/p561.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="tw-text-blue-500 hover:tw-underline"
          >
            See here
          </a>{' '}
          (pages 8-11) for further information about the IRS requirements.
        </div>
      }
      completedAction={
        <ActionLink
          href={`#`}
          secondary
          onClick={async _ => {
            const appraisalReportData = await getAppraisalReport({
              donationId: donation.id,
            }).unwrap();
            openBase64PDFNewWindow(appraisalReportData);
          }}
          text="View Appraisal"
        />
      }
      icon={<AppraisalIcon />}
    >
      <DonationRow
        tenant="MuseumExchange"
        rowTitle="Appoint Appraiser"
        tooltipContent={
          <div>
            Museum Exchange will appoint a qualified appraiser from our
            preferred network and manage the entire appraisal process on behalf
            of the donor. The appraisal will be prepared using the images and
            information previously provided. The appraiser will not require
            access to the object unless otherwise noted. If you would prefer to
            use an appraiser outside our preferred network or will not require
            an appraisal, please contact a{' '}
            <a
              href={`mailto:donor_liaison@museumexchange.com`}
              target="_blank"
              rel="noopener noreferrer"
              className="gen-link d-hover-element-link"
            >
              Donor Liaison
            </a>
            .
          </div>
        }
        isCompleted={
          donation.events.completed ||
          donation.appraisal.report ||
          donation.appraisal.appraiser
        }
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Approve Appraiser"
        // tooltipContent="The appraiser estimates that the completion of the appraisal will require the stated number of hours at their hourly rate, and will not deviate by more than one hour without your prior approval."
        action={
          <ActionLink
            href={`/donor/folders/${donation.donorAccountId}/donation/${donation.id}/appraiser-review`}
            text="Review & Approve"
          />
        }
        isUpcoming={!donation.appraisal.appraiser}
        isCompleted={
          donation.events.completed ||
          donation.appraisal.report ||
          donation.appraisal.appraiserApproved
        }
      />
      <DonationRow
        tenant="MuseumExchange"
        rowTitle="Upload Appraisal"
        isUpcoming={!donation.appraisal.appraiserApproved}
        isCompleted={donation.events.completed || donation.appraisal.report}
        status="completed"
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Approve Appraisal"
        action={
          <ActionLink
            href={`/donor/folders/${donation.donorAccountId}/donation/${donation.id}/appraisal`}
            text="Review & Approve"
          />
        }
        isUpcoming={!donation.appraisal.report}
        isCompleted={donation.events.completed || donation.appraisal.approved}
      />
    </DonationSection>
  );
}

function AppraisalIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M4 4a2 2 0 0 1 2-2h8a1 1 0 0 1 .707.293l5 5A1 1 0 0 1 20 8v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4zm13.586 4L14 4.414V8h3.586zM12 4H6v16h12V10h-5a1 1 0 0 1-1-1V4zm3.707 8.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 1 1 1.414-1.414L11 15.586l3.293-3.293a1 1 0 0 1 1.414 0z"
          fill="#2E384D"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
