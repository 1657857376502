import {
  CYCLES_CLEAR_CYCLES_SORTED,
  CYCLES_GET_CYCLES_SORTED_CLEAR,
  CYCLES_GET_CYCLES_SORTED_FAILURE,
  CYCLES_GET_CYCLES_SORTED_REQUEST,
  CYCLES_GET_CYCLES_SORTED_SUCCESS,
} from '../../actions/actionTypes';

const initialCyclesState = {
  cyclesSorted: null,
  getCyclesSortedRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const cyclesReducer = (state = initialCyclesState, action) => {
  switch (action.type) {
    case CYCLES_CLEAR_CYCLES_SORTED:
      return {
        ...state,
        cycles: null,
      };

    case CYCLES_GET_CYCLES_SORTED_CLEAR:
      return {
        ...state,
        getCyclesSortedRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case CYCLES_GET_CYCLES_SORTED_FAILURE:
      return {
        ...state,
        getCyclesSortedRequest: {
          ...state.getCyclesSortedRequest,
          loading: false,
          error: action.payload,
        },
      };

    case CYCLES_GET_CYCLES_SORTED_REQUEST:
      return {
        ...state,
        getCyclesSortedRequest: {
          ...state.getCyclesSortedRequest,
          loading: true,
          error: '',
        },
      };

    case CYCLES_GET_CYCLES_SORTED_SUCCESS:
      return {
        ...state,
        cyclesSorted: action.payload,
        getCyclesSortedRequest: {
          ...state.getCyclesSortedRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    default:
      return state;
  }
};
