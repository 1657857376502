export const BASE_URL = 'https://staging.museumexchange.com';
export const CURRENT_PRIVACY_POLICY_HASH =
  '2e4a997698cea7377a290c608bd455533e7de440b68e48a2760400923df96aec';
export const CURRENT_TOS_HASH =
  '76c22af0d66cef9021a652876295d64c105fc00d2d2324804d7acfa7c37e267f';
export const CURRENT_OBJECT_SUBMISSION_HASH =
  '75e3d52d5962162cc5986a62ffa5ee573d86b50acb268e84b70542435c041958';
export const CURRENT_OBJECT_SUBMISSION_LANGUAGE =
  'User represents and warrants to Museum Exchange as of this date, that i) user is either the sole and exclusive owner of the object or has the authority to act on such owner’s behalf; ii) user, or the owner on whose behalf user is acting, has good, valid, and marketable title to the object; iii) user has full authority to give, transfer, and assign all right, title, and interest in and to the object, whether on user’s own behalf or on behalf of the object’s owner, as applicable; iv) the object is free and clear of all liens, claims, and other encumbrances and restrictions of any kind; and v) to the best of user’s knowledge, the object has not been imported or exported into or out of any country contrary to applicable law or regulation.';
export const DEFAULT_TIMEOUT = 7000;
export const IMAGE_UPLOAD_TIMEOUT = 60000;
export const ENV = 'staging';
export const ART_PROFESSIONS = [
  'Accountant',
  'Appraiser',
  'Art Advisor',
  'Art Dealer/Gallerist',
  'Attorney',
  'Collection Manager',
  'Executor/Trustee',
  'Financial Advisor',
  'Foundation Administrator',
  'Other',
];
export const CLASSIFICATIONS = [
  'Decorative Arts',
  'Drawing',
  'Film & Video',
  'Installation',
  'Mixed Media',
  'New Media',
  'Painting',
  'Performance',
  'Photography',
  'Prints & Multiples',
  'Sculpture',
];
export const COLLECTING_AREAS = [
  'African and Oceanic Art',
  'American Art',
  'Ancient and Byzantine Art',
  'Architecture and Design',
  'Arms and Armor',
  'Asian Art',
  'Canadian Art',
  'Contemporary Art',
  'Costume and Textiles',
  'Egyptian Art',
  'European Art',
  'Indigenous Art',
  'Islamic Art',
  'Latin American Art',
  'Modern Art',
  'Photography',
];
export const MUSEUM_POSITIONS = [
  'Administrator',
  'Chief Curator',
  'Collection Manager',
  'Curator',
  'Curatorial Assistant',
  'Development Officer',
  'Director',
  'Facilities Manager',
  'Registrar',
];
export const ONGOING_CYCLE_POST_PROPOSAL_DAYS = 15;
