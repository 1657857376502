import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { validateAccountOwner } from '../../lib';
import '../../styles/donor-account.css';
import '../../styles/general.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { DonorAccountCard } from './DonorAccount/DonorAccountCard';
import { NestedSection } from 'components/_shared/NestedSection';
import { useGetDonorAccountsQuery } from 'requests/api';

export default function DonorAccountsShared() {
  const userId = useSelector(state => state.authState.token.userId);

  const { data: accounts, isError } = useGetDonorAccountsQuery(userId);

  function makeFolders() {
    const folders = [];
    if (Array.isArray(accounts)) {
      accounts.forEach(f => {
        if (!validateAccountOwner(f.account.users, userId)) {
          folders.push(
            <Link
              key={`folder_card_${f.account.id}`}
              to={`/donor/folders/${f.account.id}`}
              className="gen-link-no-decoration"
            >
              <DonorAccountCard
                account={f.account}
                numberOfObjects={f.numberOfObjects}
                isShared
                ownerName={f.ownerName}
              />
            </Link>,
          );
        }
      });
    }
    return folders;
  }

  function makeContent() {
    if (isError || accounts) {
      let folders = null;
      if (accounts) {
        folders = makeFolders();
      }

      return (
        <div>
          <div className="d-flex da-header justify-content-between mex-mb-20">
            <p className="da-sec-txt-header">Folders</p>
          </div>
          {folders && folders.length ? (
            <NestedSection>
              <div className="tw-grid tw-grid-cols-3 tw-gap-3 tw-py-4 tw-px-5">
                {folders}
              </div>
            </NestedSection>
          ) : (
            <NestedSection>
              <div className="tw-mx-auto tw-grid tw-max-w-sm tw-grid-cols-1 tw-gap-4 tw-py-12 tw-px-5 tw-text-center">
                <p className="tw-text-2xl tw-font-light tw-italic tw-text-gray-400">
                  No Folders
                </p>
                <p className="da-shared-empty-state-text">
                  No folders have been shared with you. Folders owned by other
                  users that are shared with you will appear here.
                </p>
              </div>
            </NestedSection>
          )}
        </div>
      );
    }
  }

  return (
    <div className="gen-background-nav-donor">
      <div>
        <div className="d-flex da-tabs-container mx-auto spc-mt-44">
          <Link to="/donor" className="gen-link-no-decoration">
            <div className="da-tab gen-cursor-hover-link">
              <p className="da-tab-text-inactive">My Objects</p>
            </div>
          </Link>
          <div className="da-tab da-tab-active gen-cursor-hover-link">
            <p className="da-tab-text-active">Shared with Me</p>
          </div>
        </div>
      </div>
      <div className="da-landing-content mx-auto mex-mb-30">
        {makeContent()}
      </div>
    </div>
  );
}
