import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { logger } from 'redux-logger';
import { ENV } from '../config';
import rootReducer from './rootReducer';
import { api } from 'requests/api';

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware();
      middleware.push(api.middleware);

      if (ENV !== 'production') {
        middleware.push(logger);
      }

      return middleware;
    },
    devTools: ENV !== 'production',
    preloadedState,
    // enhancers: [monitorReducersEnhancer]
  });

  if (ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  }

  setupListeners(store.dispatch);

  return store;
}
