import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  postTelClear,
  telResetAdd,
  telUpdateAddErrors,
  telUpdateTel,
} from '../../actions';
import { postTel } from '../../requests';
import { formatPhoneNumber, sanitizeTel } from '../../lib';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/text.css';

class DonorUserTelAdd extends Component {
  static get propTypes() {
    return {
      formErrors: PropTypes.object,
      postTel: PropTypes.func,
      postTelClear: PropTypes.func,
      postTelRequest: PropTypes.object,
      resetAdd: PropTypes.func,
      tel: PropTypes.string,
      token: PropTypes.object,
      updateAddErrors: PropTypes.func,
      updateTel: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.resetAdd();
    this.props.postTelClear();
  }

  handleSaveClick = _ => {
    if (this.props.postTelRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      telError: false,
    };

    if (sanitizeTel(this.props.tel).length !== 10) {
      errors.formError = true;
      errors.telError = true;
    }

    this.props.updateAddErrors({ addFormErrors: errors });

    if (errors.formError) {
      return;
    }

    this.props.postTel(this.props.token, {
      consentTel: true,
      tel: sanitizeTel(this.props.tel),
    });
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Mobile Number</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-label-txt">CURRENT MOBILE NUMBER</p>
            <div className="set-info-edit-current-val-container">
              <p className="set-info-txt">
                {formatPhoneNumber(this.props.user.tel)}
              </p>
            </div>
            <p className="set-label-txt">NEW MOBILE NUMBER</p>
            <input
              type="tel"
              className={`set-info-edit-field ${
                this.props.formErrors.telError ? 'fd-input-error-field' : ''
              }`}
              maxLength="100"
              value={this.props.tel}
              onChange={e => this.props.updateTel({ tel: e.target.value })}
            />
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              {this.props.postTelRequest.error ? (
                <p className="set-special-err-1">Please try again</p>
              ) : null}
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save set-info-edit-btn-save-lg"
                onClick={_ => this.handleSaveClick()}
              >
                SEND VERIFICATION CODE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.postTelRequest.data) {
      return <Redirect to="/donor/settings/user/tel/verify" />;
    }

    return (
      <div className="gen-background-nav-donor">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formErrors: state.telState.addFormErrors,
    postTelRequest: state.userState.postTelRequest,
    tel: state.telState.addFormData.tel,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postTel: (token, payload) => dispatch(postTel(token, payload)),
    postTelClear: _ => dispatch(postTelClear()),
    resetAdd: _ => dispatch(telResetAdd()),
    updateAddErrors: payload => dispatch(telUpdateAddErrors(payload)),
    updateTel: payload => dispatch(telUpdateTel(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorUserTelAdd);
