import {
  CONFIRM_EMAIL_RESET,
  CONFIRM_EMAIL_UPDATE_EMAIL,
  CONFIRM_EMAIL_UPDATE_EMAIL_ID,
  CONFIRM_EMAIL_UPDATE_FORM_ERRORS,
  CONFIRM_EMAIL_UPDATE_LOADING,
  CONFIRM_EMAIL_UPDATE_PASSWORD,
  CONFIRM_EMAIL_PUT_CONFIRM_CLEAR,
  CONFIRM_EMAIL_PUT_CONFIRM_FAILURE,
  CONFIRM_EMAIL_PUT_CONFIRM_REQUEST,
  CONFIRM_EMAIL_PUT_CONFIRM_SUCCESS,
} from '../../actions/actionTypes';

const initialConfirmEmailState = {
  loading: true,
  emailId: '',
  formData: {
    email: '',
    password: '',
  },
  formErrors: {
    formError: false,
    emailError: false,
    passwordError: false,
  },
  putConfirmEmailRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const confirmEmailReducer = (
  state = initialConfirmEmailState,
  action,
) => {
  switch (action.type) {
    case CONFIRM_EMAIL_RESET:
      return {
        loading: true,
        emailId: '',
        formData: {
          email: '',
          password: '',
        },
        formErrors: {
          formError: false,
          emailError: false,
          passwordError: false,
        },
        putConfirmEmailRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case CONFIRM_EMAIL_UPDATE_EMAIL:
      return {
        ...state,
        formData: { ...state.formData, email: action.payload.email },
      };

    case CONFIRM_EMAIL_UPDATE_EMAIL_ID:
      return {
        ...state,
        emailId: action.payload.emailId,
      };

    case CONFIRM_EMAIL_UPDATE_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };

    case CONFIRM_EMAIL_UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case CONFIRM_EMAIL_UPDATE_PASSWORD:
      return {
        ...state,
        formData: { ...state.formData, password: action.payload.password },
      };

    case CONFIRM_EMAIL_PUT_CONFIRM_CLEAR:
      return {
        ...state,
        putConfirmEmailRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case CONFIRM_EMAIL_PUT_CONFIRM_FAILURE:
      return {
        ...state,
        putConfirmEmailRequest: {
          ...state.putConfirmEmailRequest,
          loading: false,
          error: action.payload,
        },
      };

    case CONFIRM_EMAIL_PUT_CONFIRM_REQUEST:
      return {
        ...state,
        putConfirmEmailRequest: {
          ...state.putConfirmEmailRequest,
          loading: true,
          error: '',
        },
      };

    case CONFIRM_EMAIL_PUT_CONFIRM_SUCCESS:
      return {
        ...state,
        token: null,
        putConfirmEmailRequest: {
          ...state.putConfirmEmailRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    default:
      return state;
  }
};
