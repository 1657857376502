import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUserClear } from '../../actions';
import { getUser } from '../../requests';
import '../../styles/general.css';
import '../../styles/text.css';

class MuseumPending extends Component {
  static get propTypes() {
    return {
      getUser: PropTypes.func,
      getUserClear: PropTypes.func,
      getUserRequest: PropTypes.object,
      token: PropTypes.object,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    if (this.props.token) {
      this.props.getUser(this.props.token);
    }
  }

  componentWillUnmount() {
    this.props.getUserClear();
  }

  render() {
    if (this.props.getUserRequest.loading) {
      return <p>Loading...</p>;
    } else if (
      !this.props.getUserRequest.loading &&
      !this.props.getUserRequest.error &&
      this.props.user.approved &&
      !this.props.user.tel
    ) {
      return <Redirect push to="/user/tel/add" />;
    }

    return (
      <div
        className={`${
          this.props.header === 'basic'
            ? 'gen-background-nav-basic'
            : 'gen-background'
        }`}
      >
        <p className="txt-header text-center mex-mt-47 mex-mb-30">
          Thank You for Your Application
        </p>
        <div className="misc-content mx-auto">
          <div className="misc-content-inner-wide mx-auto">
            <p className="txt-15" style={{ lineHeight: '1.47' }}>
              Thank you for submitting your application to join Museum Exchange.
              We are reviewing your application and a member of our team will be
              in touch by email within two business days. Please reach out to{' '}
              <a
                href="mailto:help@museumexchange.com"
                target="_blank"
                rel="noopener noreferrer"
                className="gen-link"
              >
                help@museumexchange.com
              </a>{' '}
              with any questions in the meantime.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    getUserRequest: state.userState.getUserRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUser: payload => dispatch(getUser(payload)),
    getUserClear: _ => dispatch(getUserClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumPending);
