import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Prompt, Redirect } from 'react-router-dom';
import {
  propClearProposal,
  propDeleteImageClear,
  propPostDraftClear,
  propPutDraftClear,
  propPutSubmitClear,
  propPutImageClear,
  propUpdateErrorsDraft,
  propUpdateErrorsSubmit,
  propUpdateConditionsClarification,
  propUpdateCycleId,
  propUpdateLetterOfInterest,
  propUpdateMuseumId,
  propUpdateObjectId,
  propUpdatePOCId,
  propUpdatePOCTel,
  propResetErrors,
  propResetForm,
  propSetForm,
  propSetContactModal,
  propSetDiscardModal,
  propSetSubmitModal,
  propSetTmpContactId,
  propSetSelectedProposalId,
  propUpdateShippingCost,
} from '../../actions';
import {
  postProposalDraft,
  putProposalDraft,
  putProposalSubmit,
  putProposalDraftAndDeleteProposalImage,
  putProposalDraftAndPutProposalImage,
} from '../../requests';
import {
  convertToMMDDYY,
  stripProtocol,
  validateUserMuseumAdmin,
} from '../../lib';
import {
  dimensionsMapping,
  makeProposalPayload,
  parseProposalIntoFormData,
  validateProposalDraft,
  validateProposalSubmit,
} from '../../glue';
import ApprovedCheck from '../../images/approved-check.png';
import GreyCloseIcon from '../../images/grey-close-icon.png';
import ImageIcon from '../../images/image-icon.png';
import MuseumIcon from '../../images/museum-icon-blue.png';
import '../../styles/fields.css';
import '../../styles/general.css';
import '../../styles/modal.css';
import '../../styles/proposal-edit.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { selectCurrentCycle } from 'selectors/cyclesSelectors';
import { useHistory } from 'react-router-dom';
import {
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
  useGetProposalQuery,
  useGetUsersMuseumQuery,
} from 'requests/api';
import { SelectButton } from 'components/_shared/forms/SelectButton';
import { useQuery } from 'components/_shared/useQuery';
import { cloneDeep } from 'lodash';

export default function ProposalEdit() {
  const [mounted, setMounted] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true);
  const [reviewNextDestination, setReviewNextDestination] = useState(false);

  const { goBack } = useHistory();
  const query = useQuery();
  const objectId = query.get('objectId');

  const confirmDiscardModalOpen = useSelector(
    state => state.proposalsState.confirmDiscardModalOpen,
  );
  const confirmSubmitModalOpen = useSelector(
    state => state.proposalsState.confirmSubmitModalOpen,
  );
  const contactModalOpen = useSelector(
    state => state.proposalsState.contactModalOpen,
  );
  const currentCycle = useSelector(state => selectCurrentCycle(state));
  const formData = useSelector(state => state.proposalsState.formData);
  const formDraftErrors = useSelector(
    state => state.proposalsState.formDraftErrors,
  );
  const formSubmitErrors = useSelector(
    state => state.proposalsState.formSubmitErrors,
  );
  const deleteProposalImageRequest = useSelector(
    state => state.proposalsState.deleteProposalImageRequest,
  );
  const selectedProposalId = useSelector(
    state => state.proposalsState.selectedProposalId,
  );

  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const isMuseum = useSelector(state => selectIsMuseum(state));

  const postProposalDraftRequest = useSelector(
    state => state.proposalsState.postProposalDraftRequest,
  );

  const { data: proposal, isLoading: isLoadingProposal } = useGetProposalQuery(
    selectedProposalId,
    { skip: !selectedProposalId },
  );

  const { data: obj, isLoading: isLoadingObj } = useGetObjMuseumQuery(
    { objectId: objectId },
    { skip: !objectId },
  );

  const putProposalDraftRequest = useSelector(
    state => state.proposalsState.putProposalDraftRequest,
  );
  const putProposalSubmitRequest = useSelector(
    state => state.proposalsState.putProposalSubmitRequest,
  );
  const putProposalImageRequest = useSelector(
    state => state.proposalsState.putProposalImageRequest,
  );

  const tmpContactId = useSelector(state => state.proposalsState.tmpContactId);
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const { data: museumUsers, isLoading: isLoadingMuseumUsers } =
    useGetUsersMuseumQuery(user.museum.museumId);

  const dispatch = useDispatch();

  const deleteProposalImage = (token, proposalId, data, imgId) =>
    dispatch(
      putProposalDraftAndDeleteProposalImage(token, proposalId, data, imgId),
    );
  const resetErrors = _ => dispatch(propResetErrors());
  const putProposalImage = (token, proposalId, data, formData) =>
    dispatch(
      putProposalDraftAndPutProposalImage(token, proposalId, data, formData),
    );
  const updateErrorsDraft = payload => dispatch(propUpdateErrorsDraft(payload));
  const updateErrorsSubmit = payload =>
    dispatch(propUpdateErrorsSubmit(payload));
  const updateConditionsClarification = payload =>
    dispatch(propUpdateConditionsClarification(payload));
  const updateCycleId = payload => dispatch(propUpdateCycleId(payload));
  const updateLetterOfInterest = payload =>
    dispatch(propUpdateLetterOfInterest(payload));
  const updateMuseumId = payload => dispatch(propUpdateMuseumId(payload));
  const updateShippingCost = payload =>
    dispatch(propUpdateShippingCost(payload));

  const [isInitialized, setIsInitialized] = useState(false);

  const formDataRef = useRef(formData);
  useEffect(() => {
    // Note: this is a bit of a workaround so we can use the current formData values at mount time in the "postProposalDraft()" call.
    // This whole component was migrated to be functional, but this was one quirk that was left over.

    formDataRef.current = formData; // cache current value for next render
  });

  useEffect(() => {
    window.onbeforeunload = _ => true;

    if (!token) return;
    if (!user) return;
    if (!obj) return;
    if (isInitialized) return;

    if (token && user && obj) {
      setIsInitialized(true);
      if (!selectedProposalId) {
        updateMuseumId({
          museumId: user.museum.museumId,
        });
        dispatch(propUpdateObjectId({ objectId: obj?.id }));
        dispatch(propUpdatePOCId({ pointOfContactId: user.id }));
        if (currentCycle) {
          updateCycleId({
            cycleId: currentCycle?.id,
          });
          setTimeout(_ => {
            dispatch(
              postProposalDraft(
                token,
                makeProposalPayload(formDataRef.current),
              ),
            );
          }, 1);
        }
      }
    }
  }, [token, user, obj, isInitialized, dispatch]);

  useEffect(() => {
    if (!proposal) return;
    dispatch(propSetForm({ formData: parseProposalIntoFormData(proposal) }));
  }, [proposal]);

  useEffect(() => {
    if (!putProposalDraftRequest.data) return;
    setShouldBlockNavigation(false);
  }, [putProposalDraftRequest.data]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = undefined;

      const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

      if (putProposalSubmitRequest.data && userIsAdmin) {
        dispatch(propSetSelectedProposalId(putProposalSubmitRequest.data.id));
      }

      if (
        proposal &&
        proposal?.status === 'draft' &&
        !putProposalSubmitRequest.data &&
        !userIsAdmin
      ) {
        dispatch(propSetSelectedProposalId(null));
        dispatch(propClearProposal());
      }

      dispatch(propDeleteImageClear());
      dispatch(propPostDraftClear());
      dispatch(propPutDraftClear());
      dispatch(propPutSubmitClear());
      dispatch(propPutImageClear());
      dispatch(propResetForm());
      dispatch(propSetContactModal(false));
      dispatch(propSetDiscardModal(false));
      dispatch(propSetSubmitModal(false));
      dispatch(propSetTmpContactId(null));
    };
  }, []);

  useEffect(() => {
    if (postProposalDraftRequest.data) {
      dispatch(propSetSelectedProposalId(postProposalDraftRequest.data.id));
    }
  }, [postProposalDraftRequest.data]);

  const handleSaveClick = _ => {
    if (putProposalDraftRequest.loading) {
      return;
    }
    resetErrors();
    const formDraftErrors = validateProposalDraft(formData);
    updateErrorsDraft({ formDraftErrors });
    if (formDraftErrors.formError) {
      return;
    }
    if (proposal?.id) {
      dispatch(
        putProposalDraft(token, proposal.id, makeProposalPayload(formData)),
      );
    }
  };

  const handleSubmitClick = _ => {
    if (putProposalDraftRequest.loading || putProposalSubmitRequest.loading) {
      return;
    }

    resetErrors();
    const formSubmitErrors = validateProposalSubmit(formData);

    updateErrorsSubmit({ formSubmitErrors });

    if (formSubmitErrors.formError) {
      return;
    }

    dispatch(propSetSubmitModal(true));
  };

  const handleSubmitConfirm = _ => {
    if (proposal?.id) {
      dispatch(
        putProposalSubmit(token, proposal.id, makeProposalPayload(formData)),
      );
    }
  };

  const makeSummary = _ => {
    const makeDimensions = _ => {
      let key = '';
      dimensionsMapping.forEach(v => {
        if (!key && obj.dimensions[v.key]) {
          key = v.key;
        }
      });

      let dimensionsString = '';

      if (key === 'dimensionsVariable' || key === 'duration') {
        dimensionsString = (
          <p className="prop-edit-summary-txt-sm-light">
            {obj.dimensions[key]}
          </p>
        );
      } else {
        dimensionsString = (
          <p className="prop-edit-summary-txt-sm-light">
            {`${obj.dimensions[key].heightImperial} x ${
              obj.dimensions[key].widthImperial
            }${
              obj.dimensions[key].depthImperial
                ? ` x ${obj.dimensions[key].depthImperial}`
                : ``
            } in`}
            &nbsp; &nbsp; &nbsp;
            <span className="prop-edit-summary-txt-sm-xlight">
              {`(${obj.dimensions[key].heightMetric} x ${
                obj.dimensions[key].widthMetric
              }${
                obj.dimensions[key].depthMetric
                  ? ` x ${obj.dimensions[key].depthMetric}`
                  : ``
              } cm)`}
            </span>
          </p>
        );
      }

      return (
        <div className="prop-edit-dimensions-container">{dimensionsString}</div>
      );
    };

    return (
      <div className="prop-edit-summary-container d-flex">
        <div className="prop-edit-summary-obj-container">
          <div className="spc-mb-10">
            <p className="prop-edit-summary-header">Object Summary</p>
          </div>
          <div className="d-flex">
            <img
              src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${obj.primaryImage.bucket}.s3.amazonaws.com/${obj.primaryImage.imageId}`}
              alt="primary"
              className="prop-edit-summary-img"
            />
            <div className="prop-edit-obj-content">
              <div className="prop-edit-summary-artist-container">
                <p className="prop-edit-summary-txt">{obj.artistName}</p>
              </div>
              <p className="prop-edit-summary-txt-sm-light">
                {obj.title}, <i>{obj.year}</i>
              </p>
              {makeDimensions()}
            </div>
          </div>
        </div>
        <div className="prop-edit-summary-museum-container">
          <div className="spc-mb-5">
            <p className="prop-edit-summary-header">Institution Information</p>
          </div>
          <div className="d-flex spc-mt-25">
            <img
              src={MuseumIcon}
              alt="museum"
              className="prop-edit-summary-museum-icon"
            />
            <div>
              <p className="prop-edit-summary-txt">{museumAccount.name}</p>
              <div className="prop-edit-summary-museum-loc-container">
                {museumAccount.locationCity && museumAccount.locationState ? (
                  <p className="prop-edit-summary-txt-sm-light">{`${museumAccount.locationCity}, ${museumAccount.locationState}`}</p>
                ) : null}
              </div>
              {museumAccount.websiteURL ? (
                <a
                  href={museumAccount.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link"
                >
                  <p className="prop-edit-summary-link">
                    {stripProtocol(museumAccount.websiteURL)}
                  </p>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const findContactFromId = id => {
    let contact = null;

    if (museumUsers && Array.isArray(museumUsers)) {
      museumUsers.forEach(v => {
        if (v.id === id) {
          contact = v;
        }
      });
    }

    return contact;
  };

  const makeContactCard = (contact, purpose) => {
    if (!contact) {
      return null;
    }

    const content = (
      <div className="d-flex">
        <div className="pro-edit-contact-card-initials-container">
          <p className="txt-15 text-center">
            {contact.nameFirst[0].toUpperCase()}
            {contact.nameLast[0].toUpperCase()}
          </p>
        </div>
        <div>
          <div className="pro-edit-contact-card-name-container">
            <p className="pro-edit-contact-card-name-txt">{`${contact.nameFirst} ${contact.nameLast}`}</p>
          </div>
          <p className="pro-edit-contact-card-sub-txt">
            {contact.museum.position}
          </p>
          <p className="pro-edit-contact-card-sub-txt">{contact.email}</p>
        </div>
      </div>
    );

    if (purpose === 'list') {
      if (contact.id === formData.pointOfContactId) {
        return null;
      }

      return (
        <a
          key={`contact_card_${contact.id}`}
          href="# "
          className="gen-link-no-decoration"
          onClick={e => {
            e.preventDefault();
            dispatch(propSetTmpContactId(contact.id));
          }}
        >
          <div
            className={`mod-ct-contact-card mod-ct-contact-card-list ${
              contact.id === tmpContactId ? 'mod-ct-contact-card-selected' : ''
            }`}
          >
            {content}
          </div>
        </a>
      );
    }

    if (purpose === 'modalDisplay') {
      return <div className="mod-ct-contact-card">{content}</div>;
    }

    return <div className="pro-edit-contact-card">{content}</div>;
  };

  const makeSectionOne = _ => {
    let contact = null;

    if (formData.pointOfContactId) {
      contact = findContactFromId(formData.pointOfContactId);
    }

    let card = null;

    if (contact && typeof contact === 'object') {
      card = makeContactCard(contact);
    }

    return (
      <div className="prop-edit-section prop-edit-section-1">
        <div className="pro-edit-section-inner pro-edit-section-inner-contact-container d-flex">
          <div className="pro-edit-contact-container-left">
            <div className="pro-edit-section-header">
              <p className="txt-dark-15">Contact Information</p>
              <div className="pro-edit-section-note pro-edit-contact-note">
                <p className="txt-12 pro-edit-section-note-txt">
                  Select the staff member who will be the primary point of
                  contact.
                </p>
              </div>
              <div className="pro-edit-section-note pro-edit-contact-note">
                <p className="txt-12 pro-edit-section-note-txt">
                  <span className="prop-edit-note">NOTE:</span> This information
                  will only be shared with the donor if the
                  <br />
                  {isMuseum ? 'museum' : 'institution'} is selected as the
                  recipient for the donation.
                </p>
              </div>
              <div className="spc-mt-25">
                <p className="pro-edit-contact-label">
                  {isMuseum ? 'CURATORIAL ' : null}POINT OF CONTACT
                </p>
              </div>
            </div>
            {card}
            <a
              href="# "
              onClick={e => {
                e.preventDefault();
                dispatch(propSetContactModal(true));
              }}
              className="gen-link-no-decoration"
            >
              <div className="pro-edit-contact-change-btn">
                <p>
                  {!card
                    ? 'SELECT POINT OF CONTACT'
                    : 'CHANGE POINT OF CONTACT'}
                </p>
              </div>
            </a>
          </div>
          <div className="pro-edit-contact-container-right">
            <p className="pro-edit-contact-label">
              <span
                className={
                  formDraftErrors.pointOfContactTelError ||
                  formSubmitErrors.pointOfContactTelError
                    ? 'fd-input-error-txt'
                    : ''
                }
              >
                PHONE NUMBER
              </span>
              &nbsp;&nbsp;&nbsp;
              <span className="pro-edit-contact-label-light">(OPTIONAL)</span>
            </p>
            <input
              type="tel"
              className={`fd-input pro-edit-contact-tel-field ${
                formDraftErrors.pointOfContactTelError ||
                formSubmitErrors.pointOfContactTelError
                  ? 'fd-input-error-field'
                  : ''
              }`}
              onChange={event =>
                dispatch(
                  propUpdatePOCTel({
                    pointOfContactTel: event.target.value,
                  }),
                )
              }
              value={formData.pointOfContactTel}
              maxLength="16"
              placeholder="Phone Number"
            ></input>
          </div>
        </div>
      </div>
    );
  };

  const handleNewImage = event => {
    if (putProposalDraftRequest.loading || !formData.id) {
      return;
    }

    resetErrors();
    const formDraftErrors = validateProposalDraft(formData);

    updateErrorsDraft({ formDraftErrors });

    if (formDraftErrors.formError) {
      return;
    }

    const file = event.target.files[0];

    const data = new FormData();
    data.append('file', file);
    data.append('fileName', file.name);
    data.append('primaryImage', 'false');

    if (proposal?.id) {
      putProposalImage(token, formData.id, makeProposalPayload(formData), data);
    }
  };

  const handleRemoveImage = imageId => {
    if (
      deleteProposalImageRequest.loading ||
      putProposalDraftRequest.loading ||
      !formData.id
    ) {
      return;
    }

    resetErrors();
    const formDraftErrors = validateProposalDraft(formData);

    updateErrorsDraft({ formDraftErrors });

    if (formDraftErrors.formError) {
      return;
    }

    if (proposal?.id) {
      deleteProposalImage(
        token,
        formData.id,
        makeProposalPayload(formData),
        imageId,
      );
    }
  };

  const updateImageDescription = (imageId, description) => {
    const clonedFormData = cloneDeep(formData);
    clonedFormData.images.forEach(image => {
      if (image.imageId === imageId) {
        image.description = description;
      }
    });
    dispatch(propSetForm({ formData: clonedFormData }));
  };

  const makeImages = _ => {
    if (Array.isArray(formData.images)) {
      const makeImageCards = _ => {
        return formData.images.map(v => {
          return (
            <div key={`{img_${v.imageId}`} className="prop-edit-img-card">
              <div className="d-flex">
                <img
                  src={ImageIcon}
                  alt="contextual"
                  className="prop-edit-img-card-icon"
                />
                <p className="prop-edit-img-card-file-name text-truncate">
                  {v.fileName}
                </p>
                <a
                  href="# "
                  className="gen-link-no-decoration"
                  onClick={e => {
                    e.preventDefault();
                    handleRemoveImage(v.imageId);
                  }}
                >
                  <div className="prop-edit-image-card-btn-rm">
                    <p>REMOVE</p>
                  </div>
                </a>
              </div>
              <textarea
                onChange={event =>
                  updateImageDescription(v.imageId, event.target.value)
                }
                value={v.description}
                maxLength="500"
                placeholder="Add a desciption for this photo"
                className="fd-input-textarea prop-edit-image-card-textarea"
              />
            </div>
          );
        });
      };

      return (
        <div className="prop-edit-img-cards-container">{makeImageCards()}</div>
      );
    }
  };

  const makeImageBtn = _ => {
    if (
      formData.images === null ||
      (formData.images &&
        Array.isArray(formData.images) &&
        formData.images.length < 5)
    ) {
      if (putProposalImageRequest.loading) {
        return (
          <div className="prop-edit-img-add-uploading-conatiner">
            <p className="txt-15">
              <i>Uploading image...</i>
            </p>
          </div>
        );
      }
      return (
        <div className="prop-edit-img-add-btn-container">
          {putProposalImageRequest.error ? (
            <div className="prop-edit-img-add-btn-err-container">
              <p className="txt-14 fd-input-error-txt">
                Error uploading photo. Please try again.
              </p>
            </div>
          ) : null}
          <React.Fragment>
            <input
              type="file"
              accept="image/jpeg,image/jpg,image/png,image/x-png"
              onChange={event => handleNewImage(event)}
              className="hidden"
              id="photo"
            />
            <label className="prop-edit-img-add-btn" htmlFor="photo">
              ADD IMAGE +
            </label>
          </React.Fragment>
        </div>
      );
    }
  };

  const makeSectionTwo = _ => {
    return (
      <div className="prop-edit-section prop-edit-section-2">
        <div className="pro-edit-section-inner pro-edit-section-inner-letter-container">
          <div className="pro-edit-section-header">
            <p
              className={`txt-dark-15 ${
                formSubmitErrors.letterOfInterestError
                  ? 'fd-input-error-txt'
                  : ''
              }`}
            >
              {isMuseum ? 'Letter of Interest' : 'Background Information'}
            </p>
          </div>
          <div className="pro-edit-section-note">
            {isMuseum ? (
              <p className="txt-12 pro-edit-section-note-txt">
                Submit a letter of interest for the potential donation,
                including but not limited to the object’s appropriateness for
                the museum’s collection, exhibition program, and audience. (5000
                characters maximum)
              </p>
            ) : (
              <p className="txt-12 pro-edit-section-note-txt">
                Provide brief background information about your institution.
                This can include, but is not limited to, your mission, history,
                facilities, and art program (if applicable). (bullet point or
                paragraph form accepted, 2-3 paragraphs suggested, 5000
                characters maximum)
              </p>
            )}
          </div>
          <div className="prop-edit-date-container">
            <p className="txt-12">
              <span className="prop-edit-note">DATE</span>
              {'  '}
              {convertToMMDDYY(new Date())}
            </p>
          </div>
          <textarea
            className={`fd-input-textarea prop-edit-letter-input ${
              formSubmitErrors.letterOfInterestError
                ? 'fd-input-error-field'
                : ''
            }`}
            onChange={event =>
              updateLetterOfInterest({
                letterOfInterest: event.target.value,
              })
            }
            value={formData.letterOfInterest}
            maxLength="5000"
            placeholder={
              isMuseum ? 'Letter of Interest' : 'Background Information'
            }
          ></textarea>
        </div>
        <div className="pro-edit-section-inner pro-edit-section-inner-images-container">
          <div className="pro-edit-section-header">
            <p className="txt-dark-15">
              Contextual Images&nbsp;&nbsp;
              <span className="txt-light-normal-12">(OPTIONAL)</span>
            </p>
          </div>
          <div className="pro-edit-section-note">
            {isMuseum ? (
              <p className="txt-12 pro-edit-section-note-txt">
                Upload up to five images to provide the donor with context for
                the potential donation. These can include related artworks in
                the museum’s collection or exhibition/collection galleries.
                (JPEGs and PNGs only, up to 20MB each)
              </p>
            ) : (
              <p className="txt-12 pro-edit-section-note-txt">
                Upload up to five images. These can include facilities and/or
                artworks in the collection. (JPEGs only, up to 20MB each)
              </p>
            )}
          </div>
          {makeImages()}
          {makeImageBtn()}
        </div>
        {obj.restrictions ? (
          <div className="pro-edit-section-inner pro-edit-section-inner-restrictions-container">
            <p
              className={`txt-dark-15 ${
                formDraftErrors.conditionsClarificationError ||
                formSubmitErrors.conditionsClarificationError
                  ? 'fd-input-error-txt'
                  : ''
              }`}
            >
              Donor Conditions
            </p>
            <div className="pro-edit-restrictions-txt-container">
              <p className="pro-edit-restrictions-txt">{obj.restrictions}</p>
            </div>
            <p className="txt-12 pro-edit-section-note-txt">
              The institution’s response to the above stated donor conditions is
              as follows:
            </p>
            <div className="pro-edit-restrictions-btns">
              <div className="spc-mb-15">
                <a
                  href="# "
                  className="gen-link-no-decoration"
                  onClick={e => {
                    e.preventDefault();
                    updateConditionsClarification({
                      conditionsClarification: null,
                    });
                  }}
                >
                  <div className="d-flex">
                    <div
                      className={
                        formData.conditionsClarification === null
                          ? 'pro-edit-restrictions-btn-active'
                          : 'pro-edit-restrictions-btn-inactive'
                      }
                    ></div>
                    <p
                      className={
                        formData.conditionsClarification === null
                          ? 'pro-edit-restrictions-btn-txt-active'
                          : 'pro-edit-restrictions-btn-txt-inactive'
                      }
                    >
                      APPROVE
                    </p>
                  </div>
                </a>
              </div>
              <a
                href="# "
                className="gen-link-no-decoration"
                onClick={e => {
                  e.preventDefault();
                  if (formData.conditionsClarification === null) {
                    updateConditionsClarification({
                      conditionsClarification: '',
                    });
                  }
                }}
              >
                <div className="d-flex">
                  <div
                    className={
                      formData.conditionsClarification !== null
                        ? 'pro-edit-restrictions-btn-active'
                        : 'pro-edit-restrictions-btn-inactive'
                    }
                  ></div>
                  <p
                    className={
                      formData.conditionsClarification !== null
                        ? 'pro-edit-restrictions-btn-txt-active'
                        : 'pro-edit-restrictions-btn-txt-inactive'
                    }
                  >
                    REQUIRES CLARIFICATION
                  </p>
                </div>
              </a>
            </div>
            {formData.conditionsClarification !== null ? (
              <textarea
                className={`fd-input-textarea prop-edit-restrictions-input ${
                  formDraftErrors.conditionsClarificationError ||
                  formSubmitErrors.conditionsClarificationError
                    ? 'fd-input-error-field'
                    : ''
                }`}
                onChange={event =>
                  updateConditionsClarification({
                    conditionsClarification: event.target.value,
                  })
                }
                value={formData.conditionsClarification}
                maxLength="500"
                placeholder="Describe the clarification required"
              ></textarea>
            ) : null}
          </div>
        ) : null}
        <div className="pro-edit-section-inner pro-edit-section-inner-restrictions-container tw-grid tw-gap-3">
          <div>
            <p
              className={`txt-dark-15 ${
                formDraftErrors.conditionsClarificationError ||
                formSubmitErrors.conditionsClarificationError
                  ? 'fd-input-error-txt'
                  : ''
              }`}
            >
              Will the museum require the donor to cover the cost of shipping?
            </p>
            <p className="txt-12 pro-edit-section-note-txt">
              Please note that the donor may take this into account when
              considering multiple proposals.
            </p>
          </div>
          <div className="tw-grid tw-max-w-[50%] tw-gap-3">
            <SelectButton
              text="No"
              isActive={!formData.museumRequiresDonorPayShipping}
              onSelect={() => updateShippingCost(false)}
            />
            <SelectButton
              text="Yes"
              isActive={formData.museumRequiresDonorPayShipping}
              onSelect={() => updateShippingCost(true)}
            />
          </div>
        </div>
      </div>
    );
  };

  const makeSubmitBtnEdit = _ => {
    const formSubmitErrors = validateProposalSubmit(formData);
    if (formSubmitErrors.formError) {
      return (
        <a
          href="# "
          className="gen-link-no-decoration"
          onClick={e => {
            e.preventDefault();
            resetErrors();
            updateErrorsSubmit({ formSubmitErrors });
          }}
        >
          <div className="pro-edit-btn-submit-inactive">
            <p>
              SAVE CHANGES &
              <br />
              REVIEW PROPOSAL
            </p>
          </div>
        </a>
      );
    } else {
      return (
        <a
          href="# "
          className="gen-link-no-decoration"
          onClick={e => {
            e.preventDefault();
            setReviewNextDestination(true);
            handleSaveClick();
          }}
        >
          <div className="pro-edit-btn-submit-active">
            <p>
              SAVE CHANGES &
              <br />
              REVIEW PROPOSAL
            </p>
          </div>
        </a>
      );
    }
  };

  const makeSubmitBtn = _ => {
    const formSubmitErrors = validateProposalSubmit(formData);

    const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

    if (formSubmitErrors.formError) {
      return (
        <a
          href="# "
          className="gen-link-no-decoration"
          onClick={e => {
            e.preventDefault();
            resetErrors();
            updateErrorsSubmit({ formSubmitErrors });
          }}
        >
          <div className="pro-edit-btn-submit-inactive">
            {userIsAdmin ? (
              <p style={{ paddingTop: '7px' }}>REVIEW & SEND TO DONOR</p>
            ) : (
              <p>
                SUBMIT PROPOSAL FOR
                <br />
                ADMINISTRATOR APPROVAL
              </p>
            )}
          </div>
        </a>
      );
    } else {
      return (
        <a
          href="# "
          className="gen-link-no-decoration"
          onClick={e => {
            e.preventDefault();
            handleSubmitClick();
          }}
        >
          <div className="pro-edit-btn-submit-active">
            {userIsAdmin ? (
              <p style={{ paddingTop: '7px' }}>REVIEW & SEND TO DONOR</p>
            ) : (
              <p>
                SUBMIT PROPOSAL FOR
                <br />
                ADMINISTRATOR APPROVAL
              </p>
            )}
          </div>
        </a>
      );
    }
  };

  const makeFooter = _ => {
    const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

    if (proposal?.status === 'review' && userIsAdmin) {
      return (
        <div className="pro-edit-footer d-flex justify-content-center">
          <div className="pro-edit-footer-inner d-flex justify-content-end">
            {formSubmitErrors.formError ? (
              <div className="prop-edit-footer-err-submit">
                <div className="alert alert-danger err-alert">
                  Please complete missing field(s)
                </div>
              </div>
            ) : null}
            {putProposalDraftRequest.error ? (
              <div className="prop-edit-footer-err-network">
                <div className="alert alert-danger err-alert">
                  An error occured. Please try again.
                </div>
              </div>
            ) : null}
            <a
              href="# "
              className="gen-link-no-decoration"
              onClick={e => {
                e.preventDefault();
                dispatch(propSetDiscardModal(true));
              }}
            >
              <div className="pro-edit-btn-save">
                <p>DISCARD CHANGES</p>
              </div>
            </a>
          </div>
          <div className="pro-edit-footer-inner d-flex">
            {makeSubmitBtnEdit()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="pro-edit-footer d-flex justify-content-center">
          <div className="pro-edit-footer-inner d-flex justify-content-end">
            {formDraftErrors.formError || formSubmitErrors.formError ? (
              <div className="prop-edit-footer-err-submit">
                <div className="alert alert-danger err-alert">
                  Please complete missing field(s)
                </div>
              </div>
            ) : null}
            {putProposalDraftRequest.error || putProposalSubmitRequest.error ? (
              <div className="prop-edit-footer-err-network">
                <div className="alert alert-danger err-alert">
                  An error occured. Please try again.
                </div>
              </div>
            ) : null}
            <a
              href="# "
              className="gen-link-no-decoration"
              onClick={e => {
                e.preventDefault();
                handleSaveClick();
              }}
            >
              <div className="pro-edit-btn-save">
                <p>SAVE DRAFT</p>
              </div>
            </a>
          </div>
          <div className="pro-edit-footer-inner d-flex">{makeSubmitBtn()}</div>
        </div>
      );
    }
  };

  const makeModalContent = _ => {
    const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

    // TODO: here we want to show one modal or another depending if they are an admin or not. See museumproposalreview.js for "putProposalApprove()" function for admins.

    if (putProposalSubmitRequest.data) {
      return (
        <div className="prop-edit-modal-content mx-auto">
          <div className="d-flex justify-content-center mx-auto">
            <img
              src={ApprovedCheck}
              alt="approved-check"
              className="mod-approved-check"
            />
            <p className="mod-txt-header">Proposal submitted</p>
          </div>
          <div className="spc-mb-50">
            <p className="mod-txt-header">for administrator approval</p>
          </div>
          <div className="spc-mb-50">
            <p className="mod-txt-18">
              Proposal will be submitted for donor review
              <br />
              upon administrator approval
            </p>
          </div>
          <div className="mod-btn-container mx-auto d-flex justify-content-center">
            <Link
              to={`${
                userIsAdmin
                  ? '/institution/proposals/review'
                  : '/institution/proposals/active'
              }`}
            >
              <button className="btn mod-btn-dark mx-auto">OKAY</button>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="prop-edit-modal-content prop-edit-modal-content-padding mx-auto">
          <p className="mod-txt-header">
            Are you sure you would like to submit
            <br />
            this proposal for administrator approval?
          </p>
          <div className="mod-btn-container mx-auto d-flex justify-content-between prop-edit-modal-btns-container">
            <button
              className="btn mod-btn-light"
              onClick={_ => dispatch(propSetSubmitModal(false))}
            >
              CANCEL
            </button>
            <button
              className="btn mod-btn-dark"
              onClick={_ => handleSubmitConfirm()}
            >
              CONFIRM & SUBMIT
            </button>
          </div>
        </div>
      );
    }
  };

  if (
    putProposalDraftRequest.data &&
    proposal &&
    proposal?.status === 'review' &&
    reviewNextDestination &&
    !shouldBlockNavigation
  ) {
    return <Redirect push to="/institution/proposals/review" />;
  }

  if (
    isLoadingMuseumAccount ||
    isLoadingMuseumUsers ||
    isLoadingObj ||
    isLoadingProposal
  ) {
    return <div className="gen-background-nav-museum"></div>;
  }

  if (
    obj &&
    museumAccount &&
    museumUsers &&
    ((!selectedProposalId && postProposalDraftRequest.data) ||
      (selectedProposalId && proposal))
  ) {
    return (
      <div className="gen-background-nav-museum">
        <div className="pro-edit-content mx-auto">
          <div className="pro-edit-header">
            <p className="txt-header">Donation Proposal</p>
            <div className="pro-edit-header-txt-sm-container">
              <p className="pro-edit-header-txt-sm">
                Please submit a proposal for the donor to review
              </p>
            </div>
          </div>
          {makeSummary()}
          {makeSectionOne()}
          {makeSectionTwo()}
        </div>
        {makeFooter()}
        {contactModalOpen ? (
          <div className="mod">
            <div className="mod-ct-container mx-auto">
              <a
                href="# "
                onClick={e => {
                  e.preventDefault();
                  dispatch(propSetContactModal(false));
                  dispatch(propSetTmpContactId(null));
                }}
                className="gen-link-no-decoration"
              >
                <img
                  src={GreyCloseIcon}
                  alt="close"
                  className="modal-ct-btn-close"
                />
              </a>
              <div className="mod-ct-content-inner d-flex">
                <div className="mod-ct-content-inner-left">
                  <p className="txt-dark-15">Contact Information</p>
                  <div className="mod-ct-sub-header-left">
                    <p className="mod-ct-sub-header-txt">
                      Provide the contact information for the staff member who
                      will manage the donation. This information will only be
                      shared with the donor if the{' '}
                      {isMuseum ? 'museum' : 'institution'} is selected as the
                      recipient for the donation.
                    </p>
                  </div>
                  <p className="mod-ct-header-sm">
                    SELECT A NEW POINT OF CONTACT
                  </p>
                  <div className="mod-ct-contacts-container">
                    {Array.isArray(museumUsers)
                      ? museumUsers.map(v => makeContactCard(v, 'list'))
                      : null}
                  </div>
                </div>
                <div className="mod-ct-content-inner-right">
                  {formData.pointOfContactId ? (
                    <div className="spc-mb-30">
                      <div className="mod-ct-header-right-container">
                        <p className="mod-ct-header-sm">
                          CURRENT POINT OF CONTACT
                        </p>
                      </div>
                      <div className="spc-mb-10">
                        {makeContactCard(
                          findContactFromId(formData.pointOfContactId),
                          'modalDisplay',
                        )}
                      </div>
                    </div>
                  ) : null}
                  {tmpContactId ? (
                    <React.Fragment>
                      <div className="mod-ct-header-right-container">
                        <p className="mod-ct-header-sm">NEW POINT OF CONTACT</p>
                      </div>
                      <div className="spc-mb-10">
                        {makeContactCard(
                          findContactFromId(tmpContactId),
                          'modalDisplay',
                        )}
                      </div>
                      <a
                        href="# "
                        className="gen-link-no-decoration"
                        onClick={e => {
                          e.preventDefault();
                          dispatch(
                            propUpdatePOCId({
                              pointOfContactId: tmpContactId,
                            }),
                          );
                          dispatch(propSetTmpContactId(null));
                          dispatch(propSetContactModal(false));
                        }}
                      >
                        <div className="mod-ct-btn-save mx-auto">
                          <p>CONFIRM & CHANGE POINT OF CONTACT</p>
                        </div>
                      </a>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {confirmSubmitModalOpen ? (
          <div className="mod">
            <div className="mod-content-container mx-auto">
              {makeModalContent()}
            </div>
          </div>
        ) : null}
        {confirmDiscardModalOpen ? (
          <div className="mod">
            <div className="mod-content-container mx-auto">
              <div className="prop-edit-modal-content-short mx-auto">
                <p className="mod-txt-header">
                  Are you sure you would like to
                  <br />
                  discard these changes?
                </p>
                <div className="mod-btn-container mx-auto d-flex justify-content-between">
                  <button
                    className="btn mod-btn-light"
                    onClick={_ => dispatch(propSetDiscardModal(false))}
                  >
                    CANCEL
                  </button>
                  <button
                    className="btn mod-btn-dark"
                    onClick={_ => {
                      setShouldBlockNavigation(false);
                      goBack();
                    }}
                  >
                    CONFIRM & DISCARD
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <Prompt
          when={shouldBlockNavigation && !putProposalSubmitRequest.data}
          message="Are you sure you want to leave this page?"
        />
      </div>
    );
  }

  if (!obj && mounted) {
    return (
      <div className="gen-background-nav-museum">
        <div className="pro-edit-content mx-auto">
          <div className="prop-edit-empty-container">
            <p className="prop-edit-empty-txt">
              Please select a proposal to edit from the previous page
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <div className="gen-background-nav-museum"></div>;
}
