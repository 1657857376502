import {
  CURRENT_OBJECT_SUBMISSION_HASH,
  CURRENT_OBJECT_SUBMISSION_LANGUAGE,
  CURRENT_PRIVACY_POLICY_HASH,
  CURRENT_TOS_HASH,
} from '@config';
import { dimensionsMapping } from '@glue';
import { useCurrentDonorAccountRoleMatches } from 'components/_shared/useDonorAccountRole';
import ImageIcon from '@images/image-icon.png';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import React from 'react';
import { Link } from 'react-router-dom';

export function ApproveTermsOfServiceCard({
  formSubmitErrors,
  formData,
  handleUpdateSubmissionHash,
  handleUpdatePrivacyTOSHash,
}) {
  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  return (
    <div className="new-object-summary-card mx-auto">
      <div className="new-object-summary-card-header d-flex justify-content-between mex-mb-26">
        <p
          className={`new-object-summary-card-title ${
            formSubmitErrors.submissionHashError ? 'label-error' : ''
          }`}
        >
          APPROVE TERMS OF SERVICE
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <input
          type="checkbox"
          checked={formData.submissionHash === CURRENT_OBJECT_SUBMISSION_HASH}
          onChange={handleUpdateSubmissionHash}
          disabled={!canEdit}
          className="donor-museum-application-checkbox"
          style={{ marginTop: '3px' }}
        />
        <p
          className={`new-object-submission-hash-text ${
            formSubmitErrors.submissionHashError ? 'label-error' : ''
          }`}
        >
          {CURRENT_OBJECT_SUBMISSION_LANGUAGE}
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <input
          type="checkbox"
          checked={
            formData.privacyPolicyHash === CURRENT_PRIVACY_POLICY_HASH &&
            formData.tosHash === CURRENT_TOS_HASH
          }
          disabled={!canEdit}
          onChange={handleUpdatePrivacyTOSHash}
          className="donor-museum-application-checkbox"
          style={{ marginTop: '3px' }}
        />
        <p
          className={`new-object-submission-hash-text ${
            formSubmitErrors.privacyTOSHashError ? 'label-error' : ''
          }`}
        >
          User agrees to Museum Exchange's{' '}
          <a
            href="https://www.museumexchange.com/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
            className="gen-link"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://www.museumexchange.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
            className="gen-link"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export function ObjectDetailsCard({
  formDraftErrors,
  formSubmitErrors,
  formData,
  accountId,
}) {
  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  return (
    <div className="new-object-summary-card mx-auto">
      <div className="new-object-summary-card-header d-flex justify-content-between mex-mb-26">
        <p
          className={`new-object-summary-card-title ${
            formDraftErrors.errorPages.one || formSubmitErrors.errorPages.one
              ? 'label-error'
              : ''
          }`}
        >
          OBJECT DETAILS
        </p>
        {canEdit ? (
          <Link to={`/donor/folders/${accountId}/object/draft/1`}>
            <button className="btn new-object-summary-card-edit-btn">
              EDIT
            </button>
          </Link>
        ) : null}
      </div>
      <div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.collectingAreaError ? 'label-error' : ''
            }`}
          >
            COLLECTING AREA
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.collectingAreaError ? 'label-error' : ''
            }`}
          >
            {formData.collectingArea || <i>required for submission</i>}
          </p>
        </div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.artistNameError ? 'label-error' : ''
            }`}
          >
            ARTIST
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.artistNameError ? 'label-error' : ''
            }`}
          >
            {formData.artistName || <i>required for submission</i>}
          </p>
        </div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.yearError ? 'label-error' : ''
            }`}
          >
            YEAR
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.yearError ? 'label-error' : ''
            }`}
          >
            {formData.year || <i>required for submission</i>}
          </p>
        </div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formDraftErrors.titleError || formSubmitErrors.titleError
                ? 'label-error'
                : ''
            }`}
          >
            TITLE
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formDraftErrors.titleError || formSubmitErrors.titleError
                ? 'label-error'
                : ''
            }`}
          >
            {formData.title || <i>required for submission</i>}
          </p>
        </div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.classificationError ? 'label-error' : ''
            }`}
          >
            CLASSIFICATION
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.classificationError ? 'label-error' : ''
            }`}
          >
            {formData.classification || <i>required for submission</i>}
          </p>
        </div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.mediumError ? 'label-error' : ''
            }`}
          >
            MEDIUM
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.mediumError ? 'label-error' : ''
            }`}
          >
            {formData.medium || <i>required for submission</i>}
          </p>
        </div>
        <div className="new-object-summary-row new-object-summary-row-special d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formDraftErrors.dimensionsError ||
              formSubmitErrors.dimensionsError
                ? 'label-error'
                : ''
            }`}
          >
            DIMENSIONS
          </p>
          <div>
            <DisplayDimensions
              formData={formData}
              formDraftErrors={formDraftErrors}
              formSubmitErrors={formSubmitErrors}
            />
          </div>
        </div>
        <DisplayWeight rawWeight={formData.rawWeight} />
        <DisplayEdition
          formData={formData}
          formDraftErrors={formDraftErrors}
          formSubmitErrors={formSubmitErrors}
        />
        {formData.inscriptions && (
          <div className="new-object-summary-row d-flex justify-content-between">
            <p className="new-object-summary-row-key">
              SIGNATURE / INSCRIPTIONS
            </p>
            <p className="new-object-summary-row-value new-object-summary-row-value-special-2">
              {formData.inscriptions}
            </p>
          </div>
        )}
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.conditionError ? 'label-error' : ''
            }`}
          >
            CONDITION
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.conditionError ? 'label-error' : ''
            }`}
          >
            {formData.condition || <i>required for submission</i>}
          </p>
        </div>
      </div>
    </div>
  );
}

export function CataloguingCard({ accountId, formSubmitErrors, formData }) {
  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  return (
    <div className="new-object-summary-card mx-auto">
      <div className="new-object-summary-card-header d-flex justify-content-between mex-mb-26">
        <p
          className={`new-object-summary-card-title ${
            formSubmitErrors.errorPages.two ? 'label-error' : ''
          }`}
        >
          CATALOGUING
        </p>
        {canEdit ? (
          <Link to={`/donor/folders/${accountId}/object/draft/2`}>
            <button className="btn new-object-summary-card-edit-btn">
              EDIT
            </button>
          </Link>
        ) : null}
      </div>
      <div>
        <div className="new-object-summary-row d-flex justify-content-between">
          <p
            className={`new-object-summary-row-key ${
              formSubmitErrors.provenanceError ? 'label-error' : ''
            }`}
          >
            PROVENANCE
          </p>
          <p
            className={`new-object-summary-row-value new-object-summary-row-value-special-2 ${
              formSubmitErrors.provenanceError ? 'label-error' : ''
            }`}
          >
            {formData.provenance || <i>required for submission</i>}
          </p>
        </div>
        {formData.exhibitionHistory && (
          <div className="new-object-summary-row d-flex justify-content-between">
            <p className="new-object-summary-row-key">EXHIBITION HISTORY</p>
            <p className="new-object-summary-row-value new-object-summary-row-value-special-2">
              {formData.exhibitionHistory}
            </p>
          </div>
        )}
        {formData.literature && (
          <div className="new-object-summary-row d-flex justify-content-between">
            <p className="new-object-summary-row-key">LITERATURE</p>
            <p className="new-object-summary-row-value new-object-summary-row-value-special-2">
              {formData.literature}
            </p>
          </div>
        )}
        {formData.conservationHistory && (
          <div className="new-object-summary-row d-flex justify-content-between">
            <p className="new-object-summary-row-key">CONSERVATION HISTORY</p>
            <p className="new-object-summary-row-value new-object-summary-row-value-special-2">
              {formData.conservationHistory}
            </p>
          </div>
        )}
        {formData.notes && (
          <div className="new-object-summary-row d-flex justify-content-between">
            <p className="new-object-summary-row-key">CATALOGUE NOTES</p>
            <p className="new-object-summary-row-value new-object-summary-row-value-special-2">
              {formData.notes}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export function DonationInformationCard({
  accountId,
  formData,
  formDraftErrors,
  formSubmitErrors,
}) {
  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  return (
    <div className="new-object-summary-card mx-auto">
      <div className="new-object-summary-card-header d-flex justify-content-between mex-mb-26">
        <p
          className={`new-object-summary-card-title ${
            formDraftErrors.errorPages.three ||
            formSubmitErrors.errorPages.three
              ? 'label-error'
              : ''
          }`}
        >
          DONATION INFORMATION
        </p>
        {canEdit ? (
          <Link to={`/donor/folders/${accountId}/object/draft/3`}>
            <button className="btn new-object-summary-card-edit-btn">
              EDIT
            </button>
          </Link>
        ) : null}
      </div>
      <div>
        <DisplayRestrictions
          formData={formData}
          formDraftErrors={formDraftErrors}
          formSubmitErrors={formSubmitErrors}
        />

        <DisplayLocation
          formData={formData}
          formDraftErrors={formDraftErrors}
          formSubmitErrors={formSubmitErrors}
        />
      </div>
    </div>
  );
}

export function UploadedImagesCard({
  accountId,
  formSubmitErrors,
  formData,
  setSelectedImg,
}) {
  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);
  return (
    <div className="new-object-summary-card mx-auto">
      <div className="new-object-summary-card-header d-flex justify-content-between mex-mb-26">
        <p
          className={`new-object-summary-card-title ${
            formSubmitErrors.errorPages.four ? 'label-error' : ''
          }`}
        >
          UPLOADED IMAGES
        </p>
        {canEdit ? (
          <Link to={`/donor/folders/${accountId}/object/draft/4`}>
            <button className="btn new-object-summary-card-edit-btn">
              EDIT
            </button>
          </Link>
        ) : null}
      </div>
      <div>
        <DisplayPrimaryImage
          formData={formData}
          formSubmitErrors={formSubmitErrors}
          setSelectedImg={setSelectedImg}
        />
        <DisplaySupportingImages
          formData={formData}
          setSelectedImg={setSelectedImg}
        />
      </div>
    </div>
  );
}

function DisplayWeight({ rawWeight }) {
  if (rawWeight && rawWeight.weightImperial && rawWeight.weightMetric) {
    return (
      <div className="new-object-summary-row d-flex justify-content-between">
        <p className="new-object-summary-row-key">WEIGHT</p>
        <p className="new-object-summary-row-value">
          {`${rawWeight.weightImperial} lb`} <br />
          {`${rawWeight.weightMetric} kg`}
        </p>
        <div className="new-object-summary-row-note"></div>
      </div>
    );
  }
  return null;
}

function DisplayEdition({ formDraftErrors, formSubmitErrors, formData }) {
  const makeEditionInfo = _ => {
    if (formDraftErrors.editionError || formSubmitErrors.editionError) {
      return (
        <p className="new-object-summary-row-value label-error">
          <i>please complete edition information</i>
        </p>
      );
    } else {
      return (
        <p className="new-object-summary-row-value">
          {formData.editionDescription}
        </p>
      );
    }
  };
  if (formData.edition) {
    return (
      <div className="new-object-summary-row d-flex justify-content-between">
        <p
          className={`new-object-summary-row-key ${
            formDraftErrors.editionError || formSubmitErrors.editionError
              ? 'label-error'
              : ''
          }`}
        >
          EDITION
        </p>
        {makeEditionInfo()}
        <div className="new-object-summary-row-note"></div>
      </div>
    );
  }

  return null;
}

function DisplayDimensions({ formData, formDraftErrors, formSubmitErrors }) {
  if (formData.rawDimensions) {
    const elements = [];
    dimensionsMapping.forEach((v, i) => {
      if (formData.rawDimensions[v.key]) {
        if (v.key === 'dimensionsVariable' || v.key === 'duration') {
          elements.push(
            <div className="d-flex new-object-summary-row-value-special">
              <span className="new-object-summary-row-value-special-large">
                {`${v.display.toUpperCase()}:`}&nbsp;&nbsp;
              </span>
              <span className="new-object-summary-row-value-special-medium">
                {formData.rawDimensions[v.key]}
              </span>
            </div>,
          );
        } else {
          elements.push(
            <div className="d-flex new-object-summary-row-value-special">
              <span className="new-object-summary-row-value-special-large">
                {`${v.display.toUpperCase()}:`}&nbsp;&nbsp;
              </span>
              <span className="new-object-summary-row-value-special-medium">
                {`${formData.rawDimensions[v.key].heightImperial} x ${
                  formData.rawDimensions[v.key].widthImperial
                }${
                  formData.rawDimensions[v.key].depthImperial
                    ? ` x ${formData.rawDimensions[v.key].depthImperial}`
                    : ``
                } in`}
                &nbsp;
              </span>
              <span className="new-object-summary-row-value-special-small">
                {`${formData.rawDimensions[v.key].heightMetric} x ${
                  formData.rawDimensions[v.key].widthMetric
                }${
                  formData.rawDimensions[v.key].depthMetric
                    ? ` x ${formData.rawDimensions[v.key].depthMetric}`
                    : ``
                } cm`}
              </span>
            </div>,
          );
        }
      }
    });
    if (!elements.length) {
      return (
        <p
          className={`new-object-summary-row-value-special`}
          style={{ marginTop: '-4px', height: '32px' }}
        >
          <i
            className={`new-object-summary-row-value-special-medium ${
              formDraftErrors.dimensionsError ||
              formSubmitErrors.dimensionsError
                ? 'label-error'
                : ''
            }`}
          >
            required for submission
          </i>
        </p>
      );
    }
    return elements;
  }

  return (
    <p
      className={`new-object-summary-row-value-special`}
      style={{ marginTop: '-4px', height: '32px' }}
    >
      <i
        className={`new-object-summary-row-value-special-medium ${
          formDraftErrors.dimensionsError || formSubmitErrors.dimensionsError
            ? 'label-error'
            : ''
        }`}
      >
        required for submission
      </i>
    </p>
  );
}

function DisplayRestrictions({ formDraftErrors, formSubmitErrors, formData }) {
  const makeRestrictionInfo = _ => {
    if (
      formDraftErrors.restrictionsError ||
      formSubmitErrors.restrictionsError
    ) {
      return (
        <p className="new-object-summary-row-value label-error">
          <i>please specify donor conditions</i>
        </p>
      );
    } else {
      return (
        <p className="new-object-summary-row-value">
          {formData.restrictionsDescription}
        </p>
      );
    }
  };
  if (formData.restrictions) {
    return (
      <div className="new-object-summary-row d-flex justify-content-between">
        <p
          className={`new-object-summary-row-key ${
            formDraftErrors.restrictionsError ||
            formSubmitErrors.restrictionsError
              ? 'label-error'
              : ''
          }`}
        >
          DONOR CONDITIONS
        </p>
        {makeRestrictionInfo()}
        <div className="new-object-summary-row-note"></div>
      </div>
    );
  }
  return null;
}

function DisplayLocation({ formSubmitErrors, formData }) {
  const makeLocationInfo = _ => {
    if (
      formSubmitErrors.locationCityError ||
      formSubmitErrors.locationStateError ||
      formSubmitErrors.locationZipError ||
      !formData.locationCity ||
      !formData.locationState ||
      !formData.locationZip
    ) {
      return (
        <p
          className={`new-object-summary-row-value ${
            formSubmitErrors.locationCityError ||
            formSubmitErrors.locationStateError ||
            formSubmitErrors.locationZipError
              ? 'label-error'
              : ''
          }`}
        >
          <i>required for submission</i>
        </p>
      );
    } else {
      return (
        <p className="new-object-summary-row-value">
          {`${formData.locationCity}, ${formData.locationState.toUpperCase()} ${
            formData.locationZip
          }`}
        </p>
      );
    }
  };
  return (
    <div className="new-object-summary-row d-flex justify-content-between">
      <p
        className={`new-object-summary-row-key ${
          formSubmitErrors.locationCityError ||
          formSubmitErrors.locationStateError ||
          formSubmitErrors.locationZipError
            ? 'label-error'
            : ''
        }`}
      >
        OBJECT LOCATION
      </p>
      {makeLocationInfo()}
      <div className="new-object-summary-row-note"></div>
    </div>
  );
}

function DisplayPrimaryImage({ formSubmitErrors, formData, setSelectedImg }) {
  const makeImage = _ => {
    if (formSubmitErrors.primaryImageError || !formData.primaryImage) {
      return (
        <div>
          <p
            className={`new-object-summary-row-value ${
              formSubmitErrors.primaryImageError ? 'label-error' : ''
            }`}
          >
            <i>required for submission</i>
          </p>
        </div>
      );
    } else {
      return (
        <div className="new-object-summary-row-image-card">
          <div className="d-flex justify-content-start">
            <img
              src={ImageIcon}
              alt="icon"
              className="new-object-image-icon"
              style={{ marginRight: '10px' }}
            />
            <a
              href="# "
              className="gen-link-no-decoration"
              onClick={e => {
                e.preventDefault();
                setSelectedImg(formData.primaryImage);
              }}
            >
              <p className="new-object-image-name text-truncate">
                {formData.primaryImage.fileName}
              </p>
            </a>
          </div>
          <p className="text mex-mt-7">
            {formData.primaryImage.description || ''}
          </p>
        </div>
      );
    }
  };
  return (
    <div className="new-object-summary-row d-flex justify-content-start">
      <p
        className={`new-object-summary-row-key ${
          formSubmitErrors.primaryImageError ? 'label-error' : ''
        }`}
      >
        {' '}
        PRIMARY IMAGE
      </p>
      <div className="new-object-summary-row-photo-container">
        {makeImage()}
      </div>
    </div>
  );
}

function DisplaySupportingImages({ formData, setSelectedImg }) {
  if (
    Array.isArray(formData.supportingImages) &&
    formData.supportingImages.length
  ) {
    return (
      <div className="new-object-summary-row d-flex justify-content-start">
        <p className="new-object-summary-row-key">SUPPORTING IMAGES</p>
        <div className="new-object-summary-row-photo-container">
          {formData.supportingImages.map(img => {
            return (
              <div
                className="new-object-summary-row-image-card mex-mb-14"
                key={`supporting_${img.imageId}`}
              >
                <div className="d-flex justify-content-start">
                  <img
                    src={ImageIcon}
                    alt="icon"
                    className="new-object-image-icon"
                    style={{ marginRight: '10px' }}
                  />
                  <a
                    href="# "
                    className="gen-link-no-decoration"
                    onClick={e => {
                      e.preventDefault();
                      setSelectedImg(img);
                    }}
                  >
                    <p className="new-object-image-name text-truncate">
                      {img.fileName}
                    </p>
                  </a>
                </div>
                <p className="text mex-mt-7">{img.description || ''}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}
