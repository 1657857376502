import React from 'react';
import CloseIcon from '@images/grey-close-icon.png';
import TransferModalIcon from '@images/transfer-modal-icon.png';
import PPMInfoIcon from '@images/ppm-info-icon.png';
import ContactCard from './DonorAccountContactCard';
import { useDispatch, useSelector } from 'react-redux';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetNetworkQuery, usePutTransferObjectMutation } from 'requests/api';

export default function DonorAccountTransferObjectModal() {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.authState.token.userId);
  const { data: network } = useGetNetworkQuery(userId);

  const selectedObject = useSelector(
    state => state.donorAccountsState.selectedObject,
  );
  const [putTransferObject, { isLoading }] = usePutTransferObjectMutation();

  const { accountId } = useParams();

  const transferTarget = useSelector(
    state => state.donorAccountsState.transferTarget,
  );

  function onClose(e) {
    dispatch(dacctActions.dacctSetTransferModalOpen(false));
    dispatch(dacctActions.dacctSetTransferTarget(null));
  }

  function onClickConfirmTransfer(e) {
    e.stopPropagation();
    e.preventDefault();

    if (isLoading) {
      return;
    }

    putTransferObject({
      objectId: selectedObject.id,
      data: { userId: transferTarget.id },
      accountId,
    });
  }

  return (
    <div
      className="mod"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="mx-auto d-flex justify-content-center ppm-container">
        <div className="ppm-left">
          <button onClick={onClose}>
            <img
              src={CloseIcon}
              alt="close"
              className="ppm-btn-close gen-cursor-hover-link"
            />
          </button>
          <div className="ppm-left-inner">
            <div className="d-flex">
              <p className="ppm-title-text">Transfer Object Ownership</p>
              <img
                src={TransferModalIcon}
                alt="transfer"
                className="ppm-transfer-modal-icon"
              />
            </div>
            <p className="ppm-sub-title-text">
              Assign a new owner to this object. Please note, by transferring
              ownership you will no longer have access to the object unless the
              new owner adds it to a folder you have been shared on.
            </p>
            <div className="d-flex ppm-small-header-container">
              <p className="ppm-small-header">SELECT A USER IN YOUR NETWORK</p>
              <img
                src={PPMInfoIcon}
                alt="info"
                className="ppm-info-icon gen-cursor-hover-link"
              />
              <div className="ppm-hover-element">
                <p className="ppm-hover-element-text">
                  Users in your network are users that have been shared on your
                  folders, or have shared folders with you.
                  <br />
                  <br /> To transfer ownership to someone outside of your
                  network, tap the email link on the right.
                </p>
              </div>
            </div>
            <div className="ppm-contacts-container">
              {Array.isArray(network)
                ? network.map(v => (
                    <ContactCard
                      key={`network-contacts-${v.id}`}
                      contact={v}
                      purpose="list"
                      onClick={() => {
                        dispatch(dacctActions.dacctSetTransferTarget(v));
                      }}
                      isSelected={v.id === transferTarget?.id}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="ppm-right">
          {transferTarget ? (
            <div className="ppm-right-transfer-target-container">
              <div className="spc-mb-15">
                <p className="ppm-small-header">NEW OBJECT OWNER</p>
              </div>
              <ContactCard contact={transferTarget} purpose="modalDisplay" />
              <button
                className="ppm-right-confirm-btn gen-cursor-hover-link tw-uppercase"
                onClick={onClickConfirmTransfer}
              >
                Transfer Ownership
              </button>
            </div>
          ) : (
            <div className="ppm-right-placeholder"></div>
          )}
          <div className="tw-mx-auto tw-max-w-xs">
            <p className="ppm-right-empty-container-top">
              Don't see the user you are looking for in your network?
            </p>
            <p className="ppm-right-empty-container-bottom">
              Request transfer of object ownership by{' '}
              <button
                className="gen-cursor-hover-link tw-text-center tw-text-sm tw-text-blue"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(dacctActions.dacctSetTransferEmailModalOpen(true));
                }}
              >
                entering their email address
              </button>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
