import {
  RESET_RESET_CONFIRM,
  RESET_RESET_SUBMIT,
  RESET_UPDATE_EMAIL,
  RESET_UPDATE_PASSWORD,
  RESET_UPDATE_RESETID,
  RESET_UPDATE_CONFIRM_FORM_ERRORS,
  RESET_UPDATE_SUBMIT_FORM_ERRORS,
} from '../actionTypes';

export const resetResetConfirm = _ => {
  return {
    type: RESET_RESET_CONFIRM,
  };
};

export const resetResetSubmit = _ => {
  return {
    type: RESET_RESET_SUBMIT,
  };
};

export const resetUpdateEmail = payload => {
  return {
    type: RESET_UPDATE_EMAIL,
    payload,
  };
};

export const resetUpdatePassword = payload => {
  return {
    type: RESET_UPDATE_PASSWORD,
    payload,
  };
};

export const resetUpdateResetId = payload => {
  return {
    type: RESET_UPDATE_RESETID,
    payload,
  };
};

export const resetUpdateConfirmFormErrors = payload => {
  return {
    type: RESET_UPDATE_CONFIRM_FORM_ERRORS,
    payload,
  };
};

export const resetUpdateSubmitFormErrors = payload => {
  return {
    type: RESET_UPDATE_SUBMIT_FORM_ERRORS,
    payload,
  };
};
