import React from 'react';
import { formatPhoneNumber } from '../../lib';
import { dimensionsMapping } from '../../glue';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/donation.css';
import '../../styles/general.css';
import '../../styles/spacing.css';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function DonorDonationShipping() {
  const { accountId, donationId } = useParams();
  const { data: donation } = useDonorGetDonation({ donationId, accountId });
  const { data: object } = useDonorGetObject({
    accountId,
    objectId: donation?.objectId,
  });

  const { goBack } = useHistory();

  function getDimensions() {
    const elements = [];
    dimensionsMapping.forEach((v, i) => {
      if (object.dimensions[v.key]) {
        if (v.key === 'dimensionsVariable' || v.key === 'duration') {
          elements.push(null);
        } else {
          let dimensions = object.dimensions[v.key];

          if (v.key === 'framed' && donation.objectInfo.frame) {
            dimensions = donation.objectInfo.frame;
          }

          elements.push(
            <div
              key={`dimensions_elem_${i}`}
              className="d-shipping-content-row-dimension d-flex justify-content-center mx-auto"
            >
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">{v.display}</p>
              </div>
              <div className="d-shipping-content-row-right">
                <p className="d-shipping-value-txt">
                  {`${dimensions.heightImperial} x ${dimensions.widthImperial}${
                    dimensions.depthImperial
                      ? ` x ${dimensions.depthImperial}`
                      : ``
                  } in`}
                </p>
                <div className="spc-mt-5">
                  <p className="d-shipping-value-txt-lt">
                    {`(${dimensions.heightMetric} x ${dimensions.widthMetric}${
                      dimensions.depthMetric
                        ? ` x ${dimensions.depthMetric}`
                        : ``
                    } cm)`}
                  </p>
                </div>
              </div>
            </div>,
          );
        }
      }
    });

    if (elements.length) {
      return (
        <div className="d-shipping-content-row-dimensions mx-auto">
          <div className="d-shipping-content-row-dimensions-title d-flex justify-content-center mx-auto">
            <div className="d-shipping-content-row-left">
              <p className="d-shipping-key-txt">Dimensions</p>
            </div>
            <div className="d-shipping-content-row-left"></div>
          </div>
          {elements}
        </div>
      );
    } else {
      return null;
    }
  }

  if (object && donation) {
    return (
      <div className="gen-background-nav-donor">
        <div className="d-header mx-auto">
          <div className="d-flex">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="d-header-icon-back gen-cursor-hover-link"
              onClick={e => goBack()}
            />
            <div class-name="d-header-back-container-content">
              <p className="d-header-title">Pick Up Information</p>
            </div>
          </div>
        </div>
        <div className="d-shipping-content mx-auto">
          <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
            <div className="d-shipping-content-row-left">
              <p className="d-shipping-key-txt">Pick Up Address</p>
            </div>
            <div className="d-shipping-content-row-right">
              <p className="d-shipping-value-txt">
                {donation.donorInfo.shippingAddress.lineOne}
              </p>
              {donation.donorInfo.shippingAddress.lineTwo ? (
                <p className="d-shipping-value-txt">
                  {donation.donorInfo.shippingAddress.lineTwo}
                </p>
              ) : null}
              <p className="d-shipping-value-txt">
                {`${donation.donorInfo.shippingAddress.city}, ${donation.donorInfo.shippingAddress.state} ${donation.donorInfo.shippingAddress.zip}`}
              </p>
              <p className="d-shipping-value-txt">
                {donation.donorInfo.shippingAddress.country}
              </p>
            </div>
          </div>
          <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
            <div className="d-shipping-content-row-left">
              <p className="d-shipping-key-txt">Contact Information</p>
            </div>
            <div className="d-shipping-content-row-right">
              <p className="d-shipping-value-txt">
                {`${donation.donorInfo.shippingContact.nameFirst} ${donation.donorInfo.shippingContact.nameLast}`}
              </p>
              <p className="d-shipping-value-txt">
                {formatPhoneNumber(donation.donorInfo.shippingContact.tel)}
              </p>
            </div>
          </div>
          <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
            <div className="d-shipping-content-row-left">
              <p className="d-shipping-key-txt">Pick Up Availability</p>
            </div>
            <div
              className="d-shipping-content-row-right"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              <p className="d-shipping-value-txt">
                {donation.donorInfo.shippingDescription}
              </p>
            </div>
          </div>
          {donation.objectInfo.insuranceValue ? (
            <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">Insurance Value</p>
              </div>
              <div className="d-shipping-content-row-right">
                <p className="d-shipping-value-txt">
                  {`$${donation.objectInfo.insuranceValue / 100}`}
                </p>
              </div>
            </div>
          ) : null}
          {getDimensions()}
          {donation.objectInfo.weight ? (
            <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">Weight</p>
              </div>
              <div className="d-shipping-content-row-right">
                <p className="d-shipping-value-txt">
                  {`${donation.objectInfo.weight.weightImperial} lb`}
                </p>
                <div className="spc-mt-5">
                  <p className="d-shipping-value-txt-lt">
                    {`(${donation.objectInfo.weight.weightMetric} kg)`}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
            <div className="d-shipping-content-row-left">
              <p className="d-shipping-key-txt">Existing Crate</p>
            </div>
            <div className="d-shipping-content-row-right">
              <p className="d-shipping-value-txt">
                {donation.objectInfo.crate ? 'Yes' : 'No'}
              </p>
            </div>
          </div>
          {donation.objectInfo.crate ? (
            <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">Crate Dimensions</p>
              </div>
              <div className="d-shipping-content-row-right">
                <p className="d-shipping-value-txt">
                  {`${donation.objectInfo.crate.heightImperial} x ${
                    donation.objectInfo.crate.widthImperial
                  }${
                    donation.objectInfo.crate.depthImperial
                      ? ` x ${donation.objectInfo.crate.depthImperial}`
                      : ``
                  } in`}
                </p>
                <div className="spc-mt-5">
                  <p className="d-shipping-value-txt-lt">
                    {`(${donation.objectInfo.crate.heightMetric} x ${
                      donation.objectInfo.crate.widthMetric
                    }${
                      donation.objectInfo.crate.depthMetric
                        ? ` x ${donation.objectInfo.crate.depthMetric}`
                        : ``
                    } cm)`}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
            <div className="d-shipping-content-row-left">
              <p className="d-shipping-key-txt">
                If required, can the shipper
                <br />
                pack the object on site?
              </p>
            </div>
            <div className="d-shipping-content-row-right">
              <p className="d-shipping-value-txt">
                {donation.objectInfo.canPackOnSite ? 'Yes' : 'No'}
              </p>
            </div>
          </div>
          {donation.donorInfo.shippingInstructions ? (
            <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">Special Instructions</p>
              </div>
              <div
                className="d-shipping-content-row-right"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                <p className="d-shipping-value-txt">
                  {donation.donorInfo.shippingInstructions}
                </p>
              </div>
            </div>
          ) : null}
          {donation.objectInfo.condition ? (
            <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">Condition</p>
              </div>
              <div
                className="d-shipping-content-row-right"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                <p className="d-shipping-value-txt">
                  {donation.objectInfo.condition}
                </p>
              </div>
            </div>
          ) : null}
          {donation.donorInfo.creditLine ? (
            <div className="d-shipping-content-row d-flex justify-content-center mx-auto">
              <div className="d-shipping-content-row-left">
                <p className="d-shipping-key-txt">Credit Line</p>
              </div>
              <div className="d-shipping-content-row-right">
                <p className="d-shipping-value-txt">
                  {donation.donorInfo.creditLine}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="gen-background-nav-donor">
      <div className="d-header mx-auto d-flex">
        <img
          src={BackIcon}
          alt="back-arrow"
          className="d-header-icon-back gen-cursor-hover-link"
          onClick={e => history.goBack()}
        />
        <div class-name="d-header-back-container-content">
          <p className="d-header-title">Pick Up Information</p>
        </div>
      </div>
      <p className="text-center d-special-text">
        <em>Please select a donation from the previous page</em>
      </p>
    </div>
  );
}
