import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import sha256 from 'crypto-js/sha256';
import {
  authCAResetForm,
  authCAUpdateError,
  authCAUpdatePassword,
  authCAPutChangeClear,
} from '../../actions';
import { authCAPutChange } from '../../requests';
import '../../styles/fields.css';
import '../../styles/settings.css';
import '../../styles/spacing.css';
import '../../styles/text.css';

class DonorUserPasswordAuthorize extends Component {
  static get propTypes() {
    return {
      password: PropTypes.string,
      passwordError: PropTypes.bool,
      putChange: PropTypes.func,
      putChangeClear: PropTypes.func,
      putChangeRequest: PropTypes.object,
      resetForm: PropTypes.func,
      token: PropTypes.object,
      updatePassword: PropTypes.func,
      updatePasswordError: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.resetForm();
    this.props.putChangeClear();
  }

  handleSaveClick = _ => {
    if (this.props.putChangeRequest.loading) {
      return;
    }

    let caPasswordError = false;

    if (this.props.password === '') {
      caPasswordError = true;
    }

    this.props.updatePasswordError({ caPasswordError });

    if (caPasswordError) {
      return;
    }

    this.props.putChange(
      this.props.token,
      sha256(this.props.password).toString(),
    );
  };

  makeContent = _ => {
    return (
      <div className="set-info-container mx-auto">
        <p className="txt-header text-center">{`Hi ${this.props.user.nameFirst} ${this.props.user.nameLast}`}</p>
        <div className="d-flex justify-content-center spc-mb-33 spc-mt-20">
          <div className="set-initials-pw-container">
            <p className="set-initials-pw-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
          </div>
          <p className="set-pw-email-txt">{this.props.user.email}</p>
        </div>
        <p className="set-txt-copy">To continue, first verify it’s you</p>
        <div className="spc-mt-20">
          <p className="set-label-txt">ENTER YOUR PASSWORD</p>
          <input
            type="password"
            className={`set-info-edit-field ${
              this.props.passwordError ? 'fd-input-error-field' : ''
            }`}
            maxLength="100"
            value={this.props.password}
            onChange={e =>
              this.props.updatePassword({ caPassword: e.target.value })
            }
          />
        </div>
        <div className="set-info-edit-btns-container d-flex justify-content-end">
          {this.props.putChangeRequest.error ? (
            <p className="set-special-err-2">Please try again</p>
          ) : null}
          <div
            className="set-info-edit-btn-save"
            onClick={_ => this.handleSaveClick()}
          >
            SUBMIT
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.putChangeRequest.data) {
      return <Redirect to="/donor/settings/user/password/confirm" />;
    }

    return (
      <div className="gen-background-nav-donor">
        <div className="mcv-content mx-auto mex-mb-30">
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    password: state.authState.caPassword,
    passwordError: state.authState.caPasswordError,
    putChangeRequest: state.authState.caPutChangeRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetForm: _ => dispatch(authCAResetForm()),
    putChange: (token, passwordHash) =>
      dispatch(authCAPutChange(token, passwordHash)),
    putChangeClear: _ => dispatch(authCAPutChangeClear()),
    updatePassword: payload => dispatch(authCAUpdatePassword(payload)),
    updatePasswordError: payload => dispatch(authCAUpdateError(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DonorUserPasswordAuthorize);
