import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  msetPutMuseumUserPermissionsClear,
  msetSetPermissionsCardHover,
  msetSetSelectedUser,
  msetSetSelectedUserAdminToggle,
} from '../../actions';
import { validateMuseumHasAdmin, validateUserMuseumAdmin } from '../../lib';
import RedAlert from '../../images/alert-red.png';
import StaffIcon from '../../images/staff-icon.png';
import WhiteCheckIcon from '../../images/white-check-icon.png';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/museum-settings.css';
import '../../styles/text.css';
import {
  useGetMuseumAccountUserQuery,
  useGetUsersMuseumQuery,
} from 'requests/api';
import { putMuseumUserPermissions } from '../../requests';

export default function MuseumStaff() {
  const permissionsCardHover = useSelector(
    state => state.museumSettingsState.permissionsCardHover,
  );
  const putMuseumUserPermissionsRequest = useSelector(
    state => state.museumSettingsState.putMuseumUserPermissionsRequest,
  );
  const selectedUser = useSelector(
    state => state.museumSettingsState.selectedUser,
  );
  const selectedUserAdminToggle = useSelector(
    state => state.museumSettingsState.selectedUserAdminToggle,
  );
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();
  const { data: museumUsers, isLoading: isLoadingMuseumUsers } =
    useGetUsersMuseumQuery(user.museum.museumId);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(msetPutMuseumUserPermissionsClear());
      dispatch(msetSetPermissionsCardHover(false));
      dispatch(msetSetSelectedUser(null));
      dispatch(msetSetSelectedUserAdminToggle(null));
    };
  }, []);

  const makeStaffContent = _ => {
    const elements = [];
    const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

    museumUsers.forEach(v => {
      const contact = museumUsers.find(user => user.id === v.id);

      elements.push(
        <div
          key={`card_${v.id}`}
          className={`mas-contact-card d-flex ${
            userIsAdmin ? 'mas-contact-card-hover gen-cursor-hover-link' : ''
          } ${
            selectedUser &&
            selectedUser.id === contact.id &&
            !putMuseumUserPermissionsRequest.data
              ? 'mas-contact-card-selected'
              : ''
          }`}
          onClick={e => {
            if (userIsAdmin) {
              e.stopPropagation();
              dispatch(msetSetPermissionsCardHover(false));
              dispatch(msetSetSelectedUserAdminToggle(null));
              dispatch(msetPutMuseumUserPermissionsClear());
              dispatch(msetSetSelectedUser(contact));
            }
          }}
        >
          <div className="mas-contact-card-initials-container">
            <p className="txt-15 text-center">
              {contact.nameFirst[0].toUpperCase()}
              {contact.nameLast[0].toUpperCase()}
            </p>
          </div>
          <div className="mas-contact-card-info-container">
            <div className="mas-contact-card-name-container">
              <p className="mas-contact-card-name-txt">{`${contact.nameFirst} ${contact.nameLast}`}</p>
            </div>
            <p className="mas-contact-card-sub-txt">
              {contact.museum.position}
            </p>
            <p className="mas-contact-card-sub-txt text-truncate">
              {contact.email}
            </p>
          </div>
          {validateUserMuseumAdmin(v.id, museumAccount) ? (
            <div className="mas-contact-card-badge">
              <p className="mas-contact-card-badge-txt">ADMIN</p>
            </div>
          ) : null}
        </div>,
      );
    });

    if (elements.length) {
      return (
        <div className="mas-content">
          <div className="d-flex justify-content-between flex-wrap mas-content-outer">
            {elements}
          </div>
        </div>
      );
    }
  };

  const makeContent = _ => {
    if (museumAccount && museumUsers) {
      return <React.Fragment>{makeStaffContent()}</React.Fragment>;
    }
  };

  const makeModalIcon = _ => {
    if (
      (validateUserMuseumAdmin(selectedUser.id, museumAccount) &&
        selectedUserAdminToggle === null) ||
      selectedUserAdminToggle === true
    ) {
      return (
        <div className="mas-staff-permissions-modal-card-active-icon">
          <img
            src={WhiteCheckIcon}
            alt="white-check"
            className="mas-staff-permissions-modal-card-check-icon"
          />
        </div>
      );
    } else if (permissionsCardHover) {
      return (
        <div className="mas-staff-permissions-modal-card-inactive-icon">
          <img
            src={WhiteCheckIcon}
            alt="white-check"
            className="mas-staff-permissions-modal-card-check-icon"
          />
        </div>
      );
    } else {
      return (
        <div className="mas-staff-permissions-modal-card-empty-icon"></div>
      );
    }
  };

  const museumHasAdmin = validateMuseumHasAdmin(museumAccount);

  if (isLoadingMuseumAccount || isLoadingMuseumUsers) return null;

  return (
    <div className="gen-background-nav-museum-settings">
      <div className="mcv-content mx-auto mex-mb-30">
        <div className="mcv-header d-flex">
          <img src={StaffIcon} alt="staff" className="mas-staff-icon" />
          <div>
            <p className="txt-header">Staff & Permissions</p>
            <div className="mas-content-sub-header">
              <p className="txt-12 mas-content-sub-header-txt">
                Admins can modify staff member permissions.
                <br />
                If you need to remove a staff member, please{' '}
                <a
                  href="mailto:museum_liaison@museumexchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link"
                >
                  contact us
                </a>
                .
              </p>
            </div>
            {!museumHasAdmin ? (
              <div className="mas-content-sub-header">
                <div className="d-flex spc-mt-15">
                  <img
                    src={RedAlert}
                    alt="red-alert"
                    className="mas-staff-icon-alert"
                  />
                  <p className="txt-12 mas-content-sub-header-txt mas-content-sub-header-txt-alert">
                    Your institution does not have any admin accounts yet.
                  </p>
                </div>
                <p className="txt-12 mas-content-sub-header-txt">
                  An admin will be required to approve an object for proposal
                  creation.
                </p>
                <p className="txt-12 mas-content-sub-header-txt">
                  <a
                    href="mailto:museum_liaison@museumexchange.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="gen-link"
                  >
                    Contact us
                  </a>{' '}
                  to assign an admin for your institution.
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {makeContent()}
      </div>
      {selectedUser && !putMuseumUserPermissionsRequest.data ? (
        <div className="mod">
          <div className="mas-staff-permission-modal mx-auto">
            <div className="spc-mb-5">
              <p className="txt-11 txt-weight-500">MODIFY PERMISSIONS FOR</p>
            </div>
            <div className="spc-mb-45">
              <p className="txt-dark-11 txt-weight-500">{`${selectedUser.nameFirst} ${selectedUser.nameLast}`}</p>
            </div>
            <p className="txt-dark-15">Permissions</p>
            <div
              className="mas-staff-permissions-modal-card gen-cursor-hover-link"
              onMouseEnter={_ => {
                dispatch(msetSetPermissionsCardHover(true));
              }}
              onMouseLeave={_ => {
                dispatch(msetSetPermissionsCardHover(false));
              }}
              onClick={e => {
                e.stopPropagation();
                if (validateUserMuseumAdmin(selectedUser.id, museumAccount)) {
                  if (selectedUserAdminToggle === null) {
                    dispatch(msetSetSelectedUserAdminToggle(false));
                    dispatch(msetSetPermissionsCardHover(false));
                  } else {
                    dispatch(msetSetSelectedUserAdminToggle(null));
                  }
                } else {
                  if (selectedUserAdminToggle === null) {
                    dispatch(msetSetSelectedUserAdminToggle(true));
                  } else {
                    dispatch(msetSetSelectedUserAdminToggle(null));
                    dispatch(msetSetPermissionsCardHover(false));
                  }
                }
              }}
            >
              <div className="d-flex">
                {makeModalIcon()}
                <div>
                  <p className="mas-staff-permissions-modal-card-title-txt">
                    Administrator
                  </p>
                  <div className="mas-staff-permissions-modal-card-txt-block">
                    <p className="txt-12 mas-staff-permissions-modal-card-txt-block-txt">
                      Have full permission to access everything on the platform.
                      <br />
                      Admins are able to approve objects for proposal creation,
                      submit proposals, and manage other institution employee’s
                      permissions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="mas-staff-permissions-modal-btn-cancel gen-cursor-hover-link"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(msetSetSelectedUser(null));
                }}
              >
                CANCEL
              </div>
              <div
                className="mas-staff-permissions-modal-btn-confirm gen-cursor-hover-link"
                onClick={e => {
                  e.stopPropagation();
                  if (putMuseumUserPermissionsRequest.loading) {
                    return;
                  }
                  if (selectedUserAdminToggle === null) {
                    dispatch(msetSetSelectedUser(null));
                  } else if (selectedUserAdminToggle === false) {
                    dispatch(
                      putMuseumUserPermissions(
                        token,
                        museumAccount.id,
                        selectedUser.id,
                        'staff',
                      ),
                    );
                  } else if (selectedUserAdminToggle === true) {
                    dispatch(
                      putMuseumUserPermissions(
                        token,
                        museumAccount.id,
                        selectedUser.id,
                        'admin',
                      ),
                    );
                  }
                }}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
