import {
  MUSEUM_APPLICATION_RESET,
  MUSEUM_APPLICATION_UPDATE_CONSENT,
  MUSEUM_APPLICATION_UPDATE_ERRORS,
  MUSEUM_APPLICATION_UPDATE_MUSEUM,
  MUSEUM_APPLICATION_UPDATE_ROLE,
} from '../actionTypes';

export const museumApplicationReset = _ => {
  return {
    type: MUSEUM_APPLICATION_RESET,
  };
};

export const museumApplicationUpdateConsent = payload => {
  return {
    type: MUSEUM_APPLICATION_UPDATE_CONSENT,
    payload,
  };
};

export const museumApplicationUpdateErrors = payload => {
  return {
    type: MUSEUM_APPLICATION_UPDATE_ERRORS,
    payload,
  };
};

export const museumApplicationUpdateMuseum = payload => {
  return {
    type: MUSEUM_APPLICATION_UPDATE_MUSEUM,
    payload,
  };
};

export const museumApplicationUpdateRole = payload => {
  return {
    type: MUSEUM_APPLICATION_UPDATE_ROLE,
    payload,
  };
};
