export const DONOR_APPLICATION_RESET = 'DONOR_APPLICATION_RESET';
export const DONOR_APPLICATION_UPDATE_CITY = 'DONOR_APPLICATION_UPDATE_CITY';
export const DONOR_APPLICATION_UPDATE_CONSENT =
  'DONOR_APPLICATION_UPDATE_CONSENT';
export const DONOR_APPLICATION_UPDATE_ERRORS =
  'DONOR_APPLICATION_UPDATE_ERRORS';
export const DONOR_APPLICATION_UPDATE_PROFESSION =
  'DONOR_APPLICATION_UPDATE_PROFESSION';
export const DONOR_APPLICATION_UPDATE_STATE = 'DONOR_APPLICATION_UPDATE_STATE';
export const DONOR_APPLICATION_UPDATE_TYPE = 'DONOR_APPLICATION_UPDATE_TYPE';
