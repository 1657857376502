import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  putNameClear,
  userResetNameForm,
  userUpdateNameFirst,
  userUpdateNameLast,
  userUpdateNameErrors,
} from '../../actions';
import { putName } from '../../requests';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/text.css';

class MuseumUserName extends Component {
  static get propTypes() {
    return {
      formErrors: PropTypes.object,
      nameFirst: PropTypes.string,
      nameLast: PropTypes.string,
      putName: PropTypes.func,
      putNameClear: PropTypes.func,
      putNameRequest: PropTypes.object,
      resetForm: PropTypes.func,
      token: PropTypes.object,
      updateNameErrors: PropTypes.func,
      updateNameFirst: PropTypes.func,
      updateNameLast: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.updateNameFirst({ nameFirst: this.props.user.nameFirst });
    this.props.updateNameLast({ nameLast: this.props.user.nameLast });
  }

  componentWillUnmount() {
    this.props.putNameClear();
    this.props.resetForm();
  }

  handleSaveClick = _ => {
    if (this.props.putNameRequest.loading) {
      return;
    }

    const nameFormErrors = {
      formError: false,
      nameFirstError: false,
      nameLastError: false,
    };

    if (!this.props.nameFirst) {
      nameFormErrors.formError = true;
      nameFormErrors.nameFirstError = true;
    }

    if (!this.props.nameLast) {
      nameFormErrors.formError = true;
      nameFormErrors.nameLastError = true;
    }

    this.props.updateNameErrors({ nameFormErrors });

    if (nameFormErrors.formError) {
      return;
    }

    this.props.putName(this.props.token, {
      nameFirst: this.props.nameFirst,
      nameLast: this.props.nameLast,
    });
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Name</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <div className="spc-mb-20">
              <p className="set-label-txt">FIRST NAME</p>
              <input
                type="text"
                className={`set-info-edit-field ${
                  this.props.formErrors.nameFirstError
                    ? 'fd-input-error-field'
                    : ''
                }`}
                maxLength="100"
                value={this.props.nameFirst}
                onChange={e =>
                  this.props.updateNameFirst({ nameFirst: e.target.value })
                }
              />
            </div>
            <p className="set-label-txt">LAST NAME</p>
            <input
              type="text"
              className={`set-info-edit-field ${
                this.props.formErrors.nameLastError
                  ? 'fd-input-error-field'
                  : ''
              }`}
              maxLength="100"
              value={this.props.nameLast}
              onChange={e =>
                this.props.updateNameLast({ nameLast: e.target.value })
              }
            />
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putNameRequest.data) {
      return <Redirect to="/institution/settings/user" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formErrors: state.userState.nameFormErrors,
    nameFirst: state.userState.nameFormData.nameFirst,
    nameLast: state.userState.nameFormData.nameLast,
    putNameRequest: state.userState.putNameRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putName: (token, data) => dispatch(putName(token, data)),
    putNameClear: _ => dispatch(putNameClear()),
    resetForm: _ => dispatch(userResetNameForm()),
    updateNameErrors: payload => dispatch(userUpdateNameErrors(payload)),
    updateNameFirst: payload => dispatch(userUpdateNameFirst(payload)),
    updateNameLast: payload => dispatch(userUpdateNameLast(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumUserName);
