export const USER_CLEAR_TEL = 'USER_CLEAR_TEL';
export const USER_CLEAR_USER = 'USER_CLEAR_USER';
export const USER_GET_USER_CLEAR = 'USER_GET_CLEAR';
export const USER_GET_USER_FAILURE = 'USER_GET_FAILURE';
export const USER_GET_USER_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_USER_SUCCESS = 'USER_GET_SUCCESS';
export const USER_POST_DONOR_APPLICATION_CLEAR =
  'USER_POST_DONOR_APPLICAATION_CLEAR';
export const USER_POST_DONOR_APPLICATION_FAILURE =
  'USER_POST_DONOR_APPLICATION_FAILURE';
export const USER_POST_DONOR_APPLICATION_REQUEST =
  'USER_POST_DONOR_APPLICATION_REQUEST';
export const USER_POST_DONOR_APPLICATION_SUCCESS =
  'USER_POST_DONOR_APPLICATION_SUCCESS';
export const USER_POST_MUSEUM_APPLICATION_CLEAR =
  'USER_POST_MUSEUM_APPLICATION_CLEAR';
export const USER_POST_MUSEUM_APPLICATION_FAILURE =
  'USER_POST_MUSEUM_APPLICATION_FAILURE';
export const USER_POST_MUSEUM_APPLICATION_REQUEST =
  'USER_POST_MUSEUM_APPLICATION_REQUEST';
export const USER_POST_MUSEUM_APPLICATION_SUCCESS =
  'USER_POST_MUSEUM_APPLICATION_SUCCESS';
export const USER_POST_TEL_CLEAR = 'USER_POST_TEL_CLEAR';
export const USER_POST_TEL_FAILURE = 'USER_POST_TEL_FAILURE';
export const USER_POST_TEL_REQUEST = 'USER_POST_TEL_REQUEST';
export const USER_POST_TEL_SUCCESS = 'USER_POST_TEL_SUCCESS';
export const USER_POST_TEL_RESEND_CLEAR = 'USER_POST_TEL_RESEND_CLEAR';
export const USER_POST_TEL_RESEND_FAILURE = 'USER_POST_TEL_RESEND_FAILURE';
export const USER_POST_TEL_RESEND_REQUEST = 'USER_POST_TEL_RESEND_REQUEST';
export const USER_POST_TEL_RESEND_SUCCESS = 'USER_POST_TEL_RESEND_SUCCESS';
export const USER_POST_USER_CLEAR = 'USER_POST_USER_CLEAR';
export const USER_POST_USER_FAILURE = 'USER_POST_USER_FAILURE';
export const USER_POST_USER_REQUEST = 'USER_POST_USER_REQUEST';
export const USER_POST_USER_SUCCESS = 'USER_POST_USER_SUCCESS';
export const USER_PUT_EMAIL_CLEAR = 'USER_PUT_EMAIL_CLEAR';
export const USER_PUT_EMAIL_FAILURE = 'USER_PUT_EMAIL_FAILURE';
export const USER_PUT_EMAIL_REQUEST = 'USER_PUT_EMAIL_REQUEST';
export const USER_PUT_EMAIL_SUCCESS = 'USER_PUT_EMAIL_SUCCESS';
export const USER_PUT_NAME_CLEAR = 'USER_PUT_NAME_CLEAR';
export const USER_PUT_NAME_FAILURE = 'USER_PUT_NAME_FAILURE';
export const USER_PUT_NAME_REQUEST = 'USER_PUT_NAME_REQUEST';
export const USER_PUT_NAME_SUCCESS = 'USER_PUT_NAME_SUCCESS';
export const USER_PUT_TEL_CLEAR = 'USER_PUT_TEL_CLEAR';
export const USER_PUT_TEL_FAILURE = 'USER_PUT_TEL_FAILURE';
export const USER_PUT_TEL_REQUEST = 'USER_PUT_TEL_REQUEST';
export const USER_PUT_TEL_SUCCESS = 'USER_PUT_TEL_SUCCESS';
export const USER_UPDATE_USER = 'USER_UPDATE_USER';
export const USER_RESET_NAME_FORM = 'USER_RESET_NAME_FORM';
export const USER_UPDATE_NAME_ERRORS = 'USER_UPDATE_NAME_ERRORS';
export const USER_UPDATE_NAME_FIRST = 'USER_UPDATE_NAME_FIRST';
export const USER_UPDATE_NAME_LAST = 'USER_UPDATE_NAME_LAST';
export const USER_UPDATE_NEW_EMAIL = 'USER_UPDATE_NEW_EMAIL';
export const USER_UPDATE_NEW_EMAIL_ERROR = 'USER_UPDATE_NEW_EMAIL_ERROR';
