import { useSelector } from 'react-redux';
import { useCurrentDonorAccount } from './useCurrentDonorAccount';

export function useCurrentDonorAccountRole() {
  const currentAccount = useCurrentDonorAccount();
  const token = useSelector(state => state.authState.token);

  const currentDonorAccountRole = currentAccount?.users.find(
    user => user.userId === token.userId,
  )?.role;

  return currentDonorAccountRole;
}

export function useCurrentDonorAccountRoleMatches(roles = []) {
  const currentDonorAccountRole = useCurrentDonorAccountRole();
  return roles.includes(currentDonorAccountRole);
}
