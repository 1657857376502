import {
  SET_CUR_IMG_IDX,
  SET_HVR_L,
  SET_HVR_R,
  SET_SELECTED_IMG,
} from '../../actions/actionTypes';

const initialImageCarouselState = {
  curImgIdx: 0,
  hvrL: false,
  hvrR: false,
  selectedImg: null,
};

export const imageCarouselReducer = (
  state = initialImageCarouselState,
  action,
) => {
  switch (action.type) {
    case SET_CUR_IMG_IDX:
      return {
        ...state,
        curImgIdx: action.payload,
      };

    case SET_HVR_L:
      return {
        ...state,
        hvrL: action.payload,
      };

    case SET_HVR_R:
      return {
        ...state,
        hvrR: action.payload,
      };

    case SET_SELECTED_IMG:
      return {
        ...state,
        selectedImg: action.payload,
      };

    default:
      return state;
  }
};
