export const MEETING_TYPES = {
  unspecified: 'unspecified',
  specified: 'specified',
  notApplicable: 'notApplicable',
};

export const INSPECTION_TYPES = {
  notApplicable: 'notApplicable',
  conditionReport: 'conditionReport',
  conservatorInspection: 'conservatorInspection',
  shipperSiteVisit: 'shipperSiteVisit',
};

export const DONATION_YEAR_TYPES = {
  noPreference: 'noPreference',
  currentYear: 'currentYear',
  nextYear: 'nextYear',
};

export const FINAL_DELIVERABLES = {
  digital: 'digital',
  physical: 'physical',
  both: 'both',
};
