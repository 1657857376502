import React from 'react';
import ObjectDetailsCatalogue from '../objectDetails/ObjectDetailsCatalogue';
import '../../styles/general.css';
import { useParams } from 'react-router-dom';
import { useGetObjMuseumQuery } from 'requests/api';

export default function MuseumCatalogueDetails() {
  const { objectId } = useParams();
  const { data: obj } = useGetObjMuseumQuery({ objectId });

  if (obj) {
    return (
      <div className="gen-background-nav-museum-white">
        <ObjectDetailsCatalogue object={obj} />
      </div>
    );
  } else {
    return <div className="gen-background-nav-museum-white"></div>;
  }
}
