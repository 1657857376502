import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TransferModalIcon from '@images/transfer-modal-icon.png';
import CloseIcon from '@images/grey-close-icon.png';
import { validateEmail } from '@lib';

import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import { usePutTransferObjectMutation } from 'requests/api';
import { useParams } from 'react-router-dom';

export default function DonorAccountTransferEmailModal() {
  const [putTransferObject, { isLoading }] = usePutTransferObjectMutation();
  const dispatch = useDispatch();

  const transferEmailFormErrors = useSelector(
    state => state.donorAccountsState.transferEmailFormErrors,
  );
  const transferEmailFormData = useSelector(
    state => state.donorAccountsState.transferEmailFormData,
  );

  const { accountId } = useParams();

  const selectedObject = useSelector(
    state => state.donorAccountsState.selectedObject,
  );

  return (
    <div
      className="mod-no-bg"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="ppm-mod-overlay-container mx-auto">
        <div className="ppm-transfer-email-mod mx-auto">
          <button
            onClick={() => {
              dispatch(dacctActions.dacctSetTransferEmailModalOpen(false));
              dispatch(dacctActions.dacctResetTransferEmail());
            }}
          >
            <img
              src={CloseIcon}
              alt="close-icon"
              className="ppm-transfer-email-mod-close-icon gen-cursor-hover-link"
            />
          </button>
          <div className="d-flex">
            <p className="ppm-title-text">Transfer Object Ownership</p>
            <img
              src={TransferModalIcon}
              alt="transfer"
              className="ppm-transfer-modal-icon"
            />
          </div>
          <p className="ppm-sub-title-text">
            Assign a new owner to this object. Please note, by transferring
            ownership you will no longer have access to the object unless the
            new owner adds it to a folder you have been shared on. A Donor
            Liaison will process this request for transfer.
          </p>
          <div className="tw-grid tw-gap-5">
            <input
              type="text"
              className={`input input-no-bg spc-mt-25 ${
                transferEmailFormErrors.emailError ? 'input-error' : ''
              }`}
              onChange={event =>
                dispatch(
                  dacctActions.dacctUpdateTransferEmail({
                    email: event.target.value,
                  }),
                )
              }
              value={transferEmailFormData.email}
              maxLength="100"
              placeholder="Enter email address"
            ></input>
            <div className="tw-flex tw-justify-end tw-gap-3">
              <button
                className="tw-min-w-[100px] tw-rounded tw-border tw-border-gray-400 tw-py-2 tw-px-5 tw-text-xs tw-uppercase tw-text-gray-600"
                onClick={_ => {
                  dispatch(dacctActions.dacctSetTransferEmailModalOpen(false));
                  dispatch(dacctActions.dacctResetTransferEmail());
                }}
              >
                Cancel
              </button>

              <button
                // className="tw-uppercase ppm-transfer-modal-send-btn gen-cursor-hover-link"
                className="tw-min-w-[100px] tw-rounded tw-border tw-border-blue-800 tw-bg-blue-800 tw-py-2 tw-px-5 tw-text-xs tw-uppercase tw-text-white"
                disabled={isLoading}
                onClick={_ => {
                  if (isLoading) {
                    return;
                  }

                  const formErrors = {
                    formError: false,
                    emailError: false,
                  };

                  if (
                    !transferEmailFormData.email ||
                    !validateEmail(transferEmailFormData.email)
                  ) {
                    formErrors.formError = true;
                    formErrors.emailError = true;
                  }

                  dispatch(
                    dacctActions.dacctUpdateTransferEmailFormErrors({
                      formErrors,
                    }),
                  );

                  if (formErrors.formError) {
                    return;
                  }

                  putTransferObject({
                    objectId: selectedObject.id,
                    data: { userEmail: transferEmailFormData.email },
                    accountId,
                  });
                }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
