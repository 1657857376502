import { openBase64PDFNewWindow } from '@lib';
import { INSPECTION_TYPES } from 'constants/donations';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyGetDonationConservatorReportQuery } from 'requests/api';
import {
  ActionLink,
  DonationRow,
  DonationSection,
  NoAction,
} from './DonationSections';

export function PreShippingInspectionSection({
  donation,
  setIsConservatorUploadOpen = () => {},
}) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );
  const [getConservatorReport] = useLazyGetDonationConservatorReportQuery();

  if (!donation) return null;
  if (donation?.events?.completed && !donation?.conservator?.report) {
    return null;
  }
  if (!['donor', 'museum'].includes(userAccountType)) return null;

  if (
    ![
      INSPECTION_TYPES.conservatorInspection,
      INSPECTION_TYPES.shipperSiteVisit,
    ].includes(donation.museumInfo.inspectionType)
  ) {
    return null;
  }

  return (
    <DonationSection
      title="Pre-Shipping Inspection"
      learnMoreModalContent={
        <div>
          A pre-shipping inspection is required for any object that has a
          condition issue or is costly and/or fragile to ship. The institution
          will arrange for a conservator or art shipper to inspect the object
          prior to it being shipped to the institution. The cost of the
          inspection is covered by the institution, unless agreed otherwise.
        </div>
      }
      completedAction={
        <ActionLink
          href={`#`}
          secondary
          onClick={async _ => {
            const conservatorReportData = await getConservatorReport({
              donationId: donation.id,
            }).unwrap();
            openBase64PDFNewWindow(conservatorReportData);
          }}
          text="View Condition Report"
        />
      }
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="25"
          viewBox="0 0 20 25"
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#2E384D" fillRule="nonzero">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M5.056 0c2.792 0 5.055 2.425 5.055 5.417 0 1.341-.459 2.575-1.213 3.525l.21.225h.614l3.89 4.166-1.168 1.25-3.888-4.166v-.659l-.21-.225c-.918.84-2.084 1.3-3.29 1.3C2.263 10.833 0 8.408 0 5.417 0 2.425 2.263 0 5.056 0zm0 1.667c-1.945 0-3.5 1.666-3.5 3.75 0 2.083 1.555 3.75 3.5 3.75 1.944 0 3.5-1.667 3.5-3.75 0-2.084-1.556-3.75-3.5-3.75z"
                        transform="translate(-307 -442) translate(286 411) translate(21 21) translate(0 10) translate(0 10)"
                      />
                    </g>
                    <path
                      d="M10.826.26c.37-.349.968-.346 1.336.005l4.457 4.206h2.437c.521 0 .944.4.944.895v10.74c0 .493-.423.894-.944.894l-8.556.001c.112-.465.17-.947.17-1.436 0-.12-.004-.238-.01-.355h7.451V6.26H4.89v3.133c-.15-.01-.3-.016-.453-.016-.495 0-.975.057-1.436.165V5.366c0-.494.423-.895.945-.895h2.436L10.821.265zm2.563 7.79l2.833 5.37h-5.937c-.202-.544-.48-1.052-.82-1.512l.619-1.173.944 1.79 2.361-4.474zm-4.722 0c.522 0 .945.401.945.896 0 .494-.423.895-.945.895-.521 0-.944-.401-.944-.895 0-.495.423-.895.944-.895zm2.834-5.888L9.064 4.471h4.873l-2.436-2.309z"
                      transform="translate(-307 -442) translate(286 411) translate(21 21) translate(0 10)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      }
    >
      <DonationRow
        tenant="Institution"
        rowTitle="Schedule Inspection Date"
        action={<NoAction>Check your email to coordinate scheduling</NoAction>}
        isCompleted={
          donation.events.completed ||
          donation.conservator.report ||
          donation.conservator.confirmedMuseum
        }
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Approve Inspection Date"
        action={<NoAction>Check your email to coordinate scheduling</NoAction>}
        isUpcoming={!donation.conservator.confirmedMuseum}
        isCompleted={
          donation.events.completed ||
          donation.conservator.report ||
          donation.conservator.confirmedDonor
        }
      />
      <DonationRow
        tenant="MuseumExchange"
        rowTitle="Upload Condition Report"
        isUpcoming={!donation.conservator.confirmedDonor}
        isCompleted={donation.events.completed || donation.conservator.report}
      />
    </DonationSection>
  );
}
