import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import { usePostDonorAccountMutation } from 'requests/api';
import { useHistory } from 'react-router-dom';

export default function DonorAccountsNew({ header }) {
  const [postDonorAccount, { isLoading, isSuccess, isError }] =
    usePostDonorAccountMutation();
  const formErrors = useSelector(
    state => state.donorAccountsState.newAccountFormErrors,
  );
  const name = useSelector(
    state => state.donorAccountsState.newAccountFormData.name,
  );
  const token = useSelector(state => state.authState.token);
  const dispatch = useDispatch();
  function handleUpdateName(event) {
    dispatch(
      dacctActions.dacctUpdateNewAccountName({ name: event.target.value }),
    );
  }

  const history = useHistory();

  function validateFormAndSubmit() {
    if (isLoading) {
      return;
    }

    const errors = {
      formError: false,
      nameError: false,
    };

    if (name === '') {
      errors.formError = true;
      errors.nameError = true;
    }

    dispatch(
      dacctActions.dacctUpdateNewAccountFormErrors({ formErrors: errors }),
    );

    if (errors.formError) {
      return;
    }

    postDonorAccount(token, { name: name });
  }

  if (isSuccess) {
    return <Redirect push to="/donor" />;
  }

  return (
    <div className={`${header ? 'background-nav' : 'background'}`}>
      <p className="header text-center mex-mt-47 mex-mb-30">Create a Folder</p>
      <div>
        <div className="misc-content mx-auto">
          <div className="misc-content-inner mx-auto">
            <div className="mex-mb-26">
              <p
                className={`label ${formErrors.nameError ? 'label-error' : ''}`}
              >
                FOLDER NAME
              </p>
              <input
                className={`input ${formErrors.nameError ? 'input-error' : ''}`}
                type="input"
                value={name}
                onChange={handleUpdateName}
                placeholder="Folder Name"
              ></input>
            </div>
            <button
              className={`btn btn-primary mex-btn-primary mex-btn-primary-fill ${
                !isError ? 'mex-mb-12' : ''
              }`}
              style={{ width: '100%' }}
              onClick={validateFormAndSubmit}
            >
              Create Folder
            </button>
            {isError && (
              <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                Please try again.
              </div>
            )}
            <div
              className="d-flex justify-content-center"
              style={{ width: '100%' }}
            >
              <button
                className="naked-btn link"
                onClick={_ => history.goBack()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
