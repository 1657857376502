import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  msetPutMuseumClear,
  msetResetForm,
  msetUpdateFormErrors,
  msetUpdateLocationCity,
  msetUpdateLocationState,
} from '../../actions';
import { putMuseum } from '../../requests';
import BackIcon from '../../images/arrow-back.png';
import MuseumIcon from '../../images/header-museum.png';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/museum-settings.css';
import '../../styles/settings.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { selectMuseumAccount } from 'features/museumAccounts/museumAccountsSlice';

class MuseumInfoLocation extends Component {
  static get propTypes() {
    return {
      account: PropTypes.object,
      formErrors: PropTypes.object,
      locationCity: PropTypes.string,
      locationState: PropTypes.string,
      putMuseum: PropTypes.func,
      putMuseumClear: PropTypes.func,
      putMuseumRequest: PropTypes.object,
      resetForm: PropTypes.func,
      token: PropTypes.object,
      updateFormErrors: PropTypes.func,
      updateLocationCity: PropTypes.func,
      updateLocationState: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    if (
      this.props.account &&
      this.props.account.locationCity &&
      this.props.account.locationState
    ) {
      this.props.updateLocationCity({
        locationCity: this.props.account.locationCity,
      });
      this.props.updateLocationState({
        locationState: this.props.account.locationState,
      });
    }
  }

  componentWillUnmount() {
    this.props.putMuseumClear();
    this.props.resetForm();
  }

  handleSaveClick = _ => {
    if (this.props.putMuseumRequest.loading) {
      return;
    }

    const formErrors = {
      formError: false,
      nameError: false,
      locationCityError: false,
      locationStateError: false,
      websiteURLError: false,
    };

    if (!this.props.locationCity) {
      formErrors.formError = true;
      formErrors.locationCityError = true;
    }

    if (
      !this.props.locationState ||
      (typeof this.props.locationState === 'string' &&
        this.props.locationState.length !== 2)
    ) {
      formErrors.formError = true;
      formErrors.locationStateError = true;
    }

    this.props.updateFormErrors({ formErrors });

    if (formErrors.formError) {
      return;
    }

    this.props.putMuseum(this.props.token, this.props.account.id, {
      locationCity: this.props.locationCity,
      locationState: this.props.locationState,
    });
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Location</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <div className="spc-mb-20">
              <p className="set-label-txt">CITY</p>
              <input
                type="text"
                className={`set-info-edit-field ${
                  this.props.formErrors.locationCityError
                    ? 'fd-input-error-field'
                    : ''
                }`}
                maxLength="100"
                value={this.props.locationCity}
                onChange={e =>
                  this.props.updateLocationCity({
                    locationCity: e.target.value,
                  })
                }
              />
            </div>
            <p className="set-label-txt">STATE / PROVINCE</p>
            <input
              type="text"
              className={`set-info-edit-field ${
                this.props.formErrors.locationStateError
                  ? 'fd-input-error-field'
                  : ''
              }`}
              maxLength="2"
              value={this.props.locationState}
              onChange={e =>
                this.props.updateLocationState({
                  locationState: e.target.value
                    ? e.target.value.toUpperCase()
                    : e.target.value,
                })
              }
            />
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putMuseumRequest.data) {
      return <Redirect to="/institution/settings/general" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <img src={MuseumIcon} alt="museum" className="mas-museum-icon" />
            <p className="txt-header">Institution Information</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: selectMuseumAccount(state),
    formErrors: state.museumSettingsState.formErrors,
    locationCity: state.museumSettingsState.formData.locationCity,
    locationState: state.museumSettingsState.formData.locationState,
    putMuseumRequest: state.museumSettingsState.putMuseumRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putMuseum: (token, museumId, data) =>
      dispatch(putMuseum(token, museumId, data)),
    putMuseumClear: _ => dispatch(msetPutMuseumClear()),
    resetForm: _ => dispatch(msetResetForm()),
    updateFormErrors: payload => dispatch(msetUpdateFormErrors(payload)),
    updateLocationCity: payload => dispatch(msetUpdateLocationCity(payload)),
    updateLocationState: payload => dispatch(msetUpdateLocationState(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumInfoLocation);
