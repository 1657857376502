import {
  SET_CUR_IMG_IDX,
  SET_HVR_L,
  SET_HVR_R,
  SET_SELECTED_IMG,
} from '../actionTypes';

export const setCurImgIdx = payload => {
  return {
    type: SET_CUR_IMG_IDX,
    payload,
  };
};

export const setHvrL = payload => {
  return {
    type: SET_HVR_L,
    payload,
  };
};

export const setHvrR = payload => {
  return {
    type: SET_HVR_R,
    payload,
  };
};

export const setSelectedImg = payload => {
  return {
    type: SET_SELECTED_IMG,
    payload,
  };
};
