import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { dimensionsMapping } from '../../glue';
import { setSelectedImg } from '../../actions';
import '../../styles/object-details.css';
import '../../styles/object-detail-catalogue.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { useParams } from 'react-router-dom';

export default function ObjectDetails({ obj }) {
  const selectedImg = useSelector(
    state => state.imageCarouselState.selectedImg,
  );
  const { accountId } = useParams();

  const token = useSelector(state => state.authState.token);
  const dispatch = useDispatch();
  const setSelectedImage = payload => dispatch(setSelectedImg(payload));

  useEffect(() => {
    return () => {
      setSelectedImage(null);
    };
  }, []);

  const getDimensions = _ => {
    const elements = [];
    if (typeof obj === 'object') {
      dimensionsMapping.forEach((v, i) => {
        if (
          (v.key === 'dimensionsVariable' || v.key === 'duration') &&
          obj.dimensions[v.key]
        ) {
          elements.push(
            <div key={`dimensions_elem_${i}`} className="spc-mb-20">
              <p className="odc-small-caps">{v.display.toUpperCase()}</p>
              <div className="odc-dimensions-row d-flex">
                <p className="odc-txt">{obj.dimensions[v.key]}</p>
              </div>
            </div>,
          );
        } else if (obj.dimensions[v.key]) {
          elements.push(
            <div key={`dimensions_elem_${i}`} className="spc-mb-20">
              <p className="odc-small-caps">{v.display.toUpperCase()}</p>
              <div className="odc-dimensions-row d-flex">
                <span className="odc-txt">
                  {`${obj.dimensions[v.key].heightImperial} x ${
                    obj.dimensions[v.key].widthImperial
                  }${
                    obj.dimensions[v.key].depthImperial
                      ? ` x ${obj.dimensions[v.key].depthImperial}`
                      : ``
                  } in`}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </span>
                <span className="odc-txt-light">
                  {`(${obj.dimensions[v.key].heightMetric} x ${
                    obj.dimensions[v.key].widthMetric
                  }${
                    obj.dimensions[v.key].depthMetric
                      ? ` x ${obj.dimensions[v.key].depthMetric}`
                      : ``
                  } cm)`}
                </span>
              </div>
            </div>,
          );
        }
      });
    }
    return elements;
  };

  const getWeight = _ => {
    if (typeof obj === 'object' && obj.weightImperial && obj.weightMetric) {
      return (
        <div key={`weight_elem_${obj.id}`} className="spc-mb-20">
          <p className="odc-small-caps">WEIGHT</p>
          <div className="odc-dimensions-row d-flex">
            <span className="odc-txt">
              {`${obj.weightImperial} lb`}
              &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
            <span className="odc-txt-light">{`(${obj.weightMetric} kg)`}</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="od-content">
      <div className="od-content-card od-content-card-1 d-flex">
        <div className="od-content-card-1-left">
          <p className="txt-header-meadium">{obj.artistName}</p>
          <div className="od-content-card-left-1-break"></div>
          <div>
            <div className="spc-mb-20">
              <p className="odc-txt">
                <i>{obj.title}</i>, {obj.year}
              </p>
            </div>
            <div className="spc-mb-20">
              <p className="odc-txt">{obj.medium}</p>
            </div>
            {getDimensions()}
            {getWeight()}
          </div>
        </div>
        <div>
          {obj.primaryImage ? (
            <a
              href="# "
              className="gen-link-no-decoration odc-img-zoom"
              onClick={e => {
                e.preventDefault();
                setSelectedImage(obj.primaryImage);
              }}
            >
              <img
                src={`https://res.cloudinary.com/ex-tech/image/fetch/h_508,w_574,c_pad,b_white,q_auto/https://${obj.primaryImage.bucket}.s3.amazonaws.com/${obj.primaryImage.imageId}`}
                alt="primary"
                className="od-content-card-1-img"
              />
            </a>
          ) : null}
          {Array.isArray(obj.supportingImages) &&
          obj.supportingImages.length ? (
            <div className="od-content-card-1-img-link d-flex justify-content-center">
              <Link
                to={
                  token && token.role === 'donor'
                    ? `/donor/folders/${accountId}/object/${obj.id}/details/images`
                    : `/institution/object/${obj.id}/images`
                }
                className="gen-link-no-decoration"
              >
                <p className="txt-link-14">View All Images</p>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <div className="od-content-card">
        <div className="spc-mb-20">
          <p className="txt-header-small">Object Details</p>
        </div>
        {obj.inscriptions && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">
                Signature / Inscriptions
              </p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">{obj.inscriptions}</p>
            </div>
          </div>
        )}
        {obj.edition && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">Edition</p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">{obj.edition}</p>
            </div>
          </div>
        )}
        {obj.condition && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">Condition</p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">{obj.condition}</p>
            </div>
          </div>
        )}
        {obj.restrictions && (
          <div className="odc-content-left-sub-row">
            <div className="spc-mb-10">
              <p className="odc-content-left-sub-row-key">Donor Conditions</p>
            </div>
            <div className="od-content-card-val-restrictions-container">
              <p className="odc-content-left-sub-row-val">{obj.restrictions}</p>
            </div>
          </div>
        )}
      </div>
      <div className="od-content-card">
        <div className="spc-mb-20">
          <p className="txt-header-small">Cataloguing Information</p>
        </div>
        <div className="odc-content-left-sub-row d-flex">
          <div className="odc-content-left-sub-row-key-container">
            <p className="odc-content-left-sub-row-key">Provenance</p>
          </div>
          <div className="od-content-card-val-container">
            <p className="odc-content-left-sub-row-val">{obj.provenance}</p>
          </div>
        </div>
        {obj.exhibitionHistory && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">Exhibition History</p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">
                {obj.exhibitionHistory}
              </p>
            </div>
          </div>
        )}
        {obj.literature && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">Literature</p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">{obj.literature}</p>
            </div>
          </div>
        )}
        {obj.conservationHistory && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">
                Conservation History
              </p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">
                {obj.conservationHistory}
              </p>
            </div>
          </div>
        )}
        {obj.notes && (
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">Catalogue Notes</p>
            </div>
            <div className="od-content-card-val-container">
              <p className="odc-content-left-sub-row-val">{obj.notes}</p>
            </div>
          </div>
        )}
      </div>
      {selectedImg && (
        <Lightbox
          mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${selectedImg.bucket}.s3.amazonaws.com/${selectedImg.imageId}`}
          onCloseRequest={_ => setSelectedImage(null)}
          imagePadding={65}
          clickOutsideToClose={false}
        />
      )}
    </div>
  );
}
