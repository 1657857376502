import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  authPostResetClear,
  resetResetSubmit,
  resetUpdateEmail,
  resetUpdateSubmitFormErrors,
} from '../../actions';
import { authPostReset } from '../../requests';
import { validateEmail } from '../../lib';

class SubmitReset extends Component {
  static get propTypes() {
    return {
      email: PropTypes.string,
      formErrors: PropTypes.object,
      postReset: PropTypes.func,
      postResetClear: PropTypes.func,
      postResetRequest: PropTypes.object,
      resetSubmit: PropTypes.func,
      updateEmail: PropTypes.func,
      updateSubmitFormErrors: PropTypes.func,
    };
  }

  componentWillUnmount() {
    this.props.resetSubmit();
    this.props.postResetClear();
  }

  handleEmailChange = event => {
    this.props.updateEmail({ email: event.target.value });
  };

  validateFormAndSubmitReset = _ => {
    if (this.props.postResetRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      emailError: false,
    };

    this.setState({ formErrors: errors }, _ => {
      if (this.props.email === '' || !validateEmail(this.props.email)) {
        errors.formError = true;
        errors.emailError = true;
      }

      this.props.updateSubmitFormErrors(errors);

      if (errors.formError) {
        return;
      }

      this.props.postReset(this.props.email);
    });
  };

  render() {
    let content = (
      <React.Fragment>
        <div className="mex-mb-26">
          <p
            className={`label ${
              this.props.formErrors.emailError ? 'label-error' : ''
            }`}
          >
            EMAIL ADDRESS
          </p>
          <input
            className={`input ${
              this.props.formErrors.emailError ? 'input-error' : ''
            }`}
            type="text"
            value={this.props.email}
            onChange={this.handleEmailChange}
            placeholder="Email Address"
          ></input>
        </div>
        <button
          className={`btn btn-primary mex-btn-primary mex-btn-primary-fill ${
            !this.props.postResetRequest.error ? 'mex-mb-12' : ''
          }`}
          style={{ width: '100%' }}
          onClick={this.validateFormAndSubmitReset}
        >
          Submit
        </button>
        {this.props.postResetRequest.error && (
          <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
            Please try again.
          </div>
        )}
      </React.Fragment>
    );

    if (this.props.postResetRequest.data) {
      content = (
        <React.Fragment>
          <p className="text mex-mb-12">
            Please check your email. We'll send you a link to reset your
            password if we can find an account associated with that email
            address.
          </p>
        </React.Fragment>
      );
    }

    return (
      <div className="background">
        <p className="header text-center mex-mt-47">Reset Password</p>
        <div className="sub-header mx-auto mex-mb-30">
          <p className="text text-center">
            Enter the email address associated with your account and we'll send
            you a link to reset your password
          </p>
        </div>
        <div className="misc-content mx-auto">
          <div className="misc-content-inner mx-auto">
            {content}
            <div
              className="d-flex justify-content-center"
              style={{ width: '100%' }}
            >
              <Link className="link" to="/login">
                Return to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    email: state.resetState.resetSubmitFormData.email,
    formErrors: state.resetState.resetSubmitFormErrors,
    postResetRequest: state.authState.postResetRequest,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetSubmit: _ => dispatch(resetResetSubmit()),
    postReset: payload => dispatch(authPostReset(payload)),
    postResetClear: _ => dispatch(authPostResetClear()),
    updateEmail: payload => dispatch(resetUpdateEmail(payload)),
    updateSubmitFormErrors: payload =>
      dispatch(resetUpdateSubmitFormErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitReset);
