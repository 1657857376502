import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  listsClearCuratorial,
  listsClearCuratorialPast,
  listsClearInterest,
  listsClearInterestPast,
  listsGetCuratorialClear,
  listsGetCuratorialPastClear,
  listsGetInterestClear,
  listsGetInterestPastClear,
  propClearProposal,
  propResetForm,
  propSetSelectedObject,
  propSetSelectedProposalId,
} from '../../actions';
import { getCurrentCycleLists } from '../../requests';
import { convertToMMDDYY, getInitials } from '../../lib';
import HistoryHeader from '../../images/history-header.png';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import {
  useGetDonationsMuseumAccountQuery,
  useGetMuseumAccountUserQuery,
  useGetProposalsSimpleQuery,
} from 'requests/api';

export default function ProposalsHistory() {
  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const { data: donations, isLoading: isLoadingDonations } =
    useGetDonationsMuseumAccountQuery(museumAccount?.id, {
      skip: !museumAccount,
    });

  const getListCuratorialRequest = useSelector(
    state => state.listsState.getListCuratorialRequest,
  );
  const getListsCuratorialPastRequest = useSelector(
    state => state.listsState.getListsCuratorialPastRequest,
  );
  const getListInterestRequest = useSelector(
    state => state.listsState.getListInterestRequest,
  );
  const getListsInterestPastRequest = useSelector(
    state => state.listsState.getListsInterestPastRequest,
  );

  const isMuseum = useSelector(state => selectIsMuseum(state));
  const listCuratorial = useSelector(state => state.listsState.listCuratorial);
  const listsCuratorialPast = useSelector(
    state => state.listsState.listsCuratorialPast,
  );

  const { data: proposalsSimple } = useGetProposalsSimpleQuery(
    museumAccount?.id,
    { skip: !museumAccount },
  );

  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const dispatch = useDispatch();

  const setSelectedObject = payload => dispatch(propSetSelectedObject(payload));
  const setSelectedProposalId = payload =>
    dispatch(propSetSelectedProposalId(payload));

  useEffect(() => {
    if (token && user && museumAccount) {
      dispatch(
        getCurrentCycleLists(token, user.museum.museumId, museumAccount.kind),
      );
    }
    dispatch(propClearProposal());
    dispatch(propResetForm());
    setSelectedProposalId(null);
  }, [museumAccount]);

  useEffect(() => {
    return () => {
      dispatch(listsClearCuratorial());
      dispatch(listsClearCuratorialPast());

      dispatch(listsClearInterest());
      dispatch(listsClearInterestPast());
      dispatch(listsGetCuratorialClear());
      dispatch(listsGetCuratorialPastClear());
      dispatch(listsGetInterestClear());
      dispatch(listsGetInterestPastClear());
    };
  }, []);

  const makeObjCardMiddle = obj => {
    const makeSectionThree = o => {
      return (
        <div className="mcv-obj-lst-card-sec-3 d-flex">
          <div className="mcv-obj-lst-card-initials-container">
            <p className="mcv-obj-lst-card-initials-txt">
              {getInitials(o.userName)}
            </p>
          </div>
          <div>
            <p className="mcv-obj-lst-card-txt-xsm">Added by</p>
            <p className="mcv-obj-lst-card-txt-sm">{o.userName}</p>
          </div>
        </div>
      );
    };

    if (
      proposalsSimple &&
      typeof proposalsSimple === 'object' &&
      proposalsSimple[obj.id] &&
      typeof proposalsSimple[obj.id].events === 'object'
    ) {
      if (Array.isArray(donations) && donations.length) {
        let donation = null;
        donations.forEach(d => {
          if (d.objectId === obj.id) {
            donation = d;
          }
        });
        if (
          donation &&
          donation.events.acceptedComplete &&
          proposalsSimple[obj.id].events.accepted
        ) {
          let dateString = '';

          dateString = convertToMMDDYY(
            proposalsSimple[obj.id].events.accepted.timestamp,
          );

          return (
            <React.Fragment>
              <div className="mcv-obj-lst-card-sec-2">
                {donation.events.confirmedComplete ? (
                  <div className="mcv-obj-lst-card-sec-2-content">
                    <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                      <p className="mcv-obj-lst-card-txt">Accepted</p>
                    </div>
                    <p className="mcv-obj-lst-card-txt-xsm">{dateString}</p>
                  </div>
                ) : null}
              </div>
              {makeSectionThree(obj)}
              <div className="mcv-obj-lst-card-sec-4">
                <div className="mcv-obj-lst-card-sec-4-content">
                  <Link
                    to="/institution/proposals/review"
                    className="gen-link-no-decoration"
                    onClick={_ => {
                      setSelectedObject(obj);
                      setSelectedProposalId(proposalsSimple[obj.id].id);
                    }}
                  >
                    <div className="mcv-obj-lst-card-btn">
                      <p>VIEW PROPOSAL</p>
                    </div>
                  </Link>
                  <Link
                    to={`/institution/object/${obj.id}`}
                    className="gen-link"
                  >
                    <p className="mcv-obj-lst-card-txt-link text-center">
                      View Object Details
                    </p>
                  </Link>
                </div>
              </div>
            </React.Fragment>
          );
        }
      }
    }

    if (proposalsSimple[obj.id].events.rejected) {
      let dateString = '';

      dateString = convertToMMDDYY(
        proposalsSimple[obj.id].events.rejected.timestamp,
      );

      return (
        <React.Fragment>
          <div className="mcv-obj-lst-card-sec-2">
            <div className="mcv-obj-lst-card-sec-2-content">
              <div className="mcv-obj-lst-card-main-txt-container-sec-2">
                <p className="mcv-obj-lst-card-txt">Declined</p>
              </div>
              <p className="mcv-obj-lst-card-txt-xsm">{dateString}</p>
            </div>
          </div>
          {makeSectionThree(obj)}
          <div className="mcv-obj-lst-card-sec-4">
            <div className="mcv-obj-lst-card-sec-4-content">
              <Link
                to="/institution/proposals/review"
                className="gen-link-no-decoration"
                onClick={_ => {
                  setSelectedObject(obj);
                  setSelectedProposalId(proposalsSimple[obj.id].id);
                }}
              >
                <div className="mcv-obj-lst-card-btn">
                  <p>VIEW PROPOSAL</p>
                </div>
              </Link>
              <Link to={`/institution/object/${obj.id}`} className="gen-link">
                <p className="mcv-obj-lst-card-txt-link text-center">
                  View Object Details
                </p>
              </Link>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const makeMuseumObjects = (keys, pastLists) => {
    const pastObjects = keys => {
      const elements = [];

      keys.forEach(key => {
        const list = listsCuratorialPast[key];

        if (list.objects) {
          const objs = Object.keys(list.objects);

          objs.forEach(o => {
            const obj = list.objects[o];

            if (proposalsSimple && typeof proposalsSimple === 'object') {
              if (
                proposalsSimple[obj.id] &&
                proposalsSimple[obj.id].status !== 'rejected' &&
                proposalsSimple[obj.id].status !== 'accepted'
              ) {
                return null;
              }

              if (!proposalsSimple[obj.id]) {
                return null;
              }
            }

            if (Array.isArray(donations) && donations.length) {
              let donation = null;
              donations.forEach(d => {
                if (d.objectId === obj.id) {
                  donation = d;
                }
              });
              if (donation && !donation.events.confirmedComplete) {
                return;
              }
            }

            if (obj.approved) {
              elements.push(
                <div
                  key={`my_obj_${obj.id}`}
                  className="mcv-obj-lst-card d-flex"
                >
                  <img
                    src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${obj.imageBucket}.s3.amazonaws.com/${obj.imageId}`}
                    alt={`obj_${obj.id}`}
                    className="mcv-obj-lst-card-img"
                  />
                  <div className="mcv-obj-lst-card-sec-1">
                    <div className="mcv-obj-lst-card-sec-1-content">
                      <div className="mcv-obj-lst-card-main-txt-container">
                        <p className="mcv-obj-lst-card-txt gen-crop-txt-3">
                          {obj.artistName}
                        </p>
                      </div>
                      <p className="mcv-obj-lst-card-txt-sm">
                        <span className="gen-crop-txt-2">
                          <i>{obj.title}</i>
                          {', '}
                        </span>
                        {obj.year}
                      </p>
                    </div>
                  </div>
                  {makeObjCardMiddle(obj)}
                </div>,
              );
            }
          });
        }
      });

      return elements;
    };

    let objCards = [];

    if (Array.isArray(pastLists) && pastLists.length) {
      objCards = objCards.concat(pastObjects(pastLists));
    }

    if (objCards.length) {
      return (
        <div className="mcv-obj-lst-container">
          <div className="mcv-obj-lst-outer">{objCards}</div>
        </div>
      );
    }
  };

  const makeContent = _ => {
    let curatorialKeys = null;
    if (
      listCuratorial &&
      typeof listCuratorial === 'object' &&
      listCuratorial.objects &&
      typeof listCuratorial.objects === 'object'
    ) {
      curatorialKeys = Object.keys(listCuratorial.objects);
    }

    let curatorialPastKeys = null;
    if (listsCuratorialPast && typeof listsCuratorialPast === 'object') {
      curatorialPastKeys = Object.keys(listsCuratorialPast);
    }

    let content = (
      <div className="mcv-empty-container">
        <p className="mcv-empty-txt">No Objects</p>
      </div>
    );

    let objectApproved = false;

    if (Array.isArray(curatorialKeys) && curatorialKeys.length) {
      curatorialKeys.forEach(v => {
        if (listCuratorial.objects[v].approved) {
          objectApproved = true;
        }
      });
    }

    if (Array.isArray(curatorialPastKeys) && curatorialPastKeys.length) {
      curatorialPastKeys.forEach(v => {
        if (listsCuratorialPast[v].objects) {
          const tmpKeys = Object.keys(listsCuratorialPast[v].objects);
          tmpKeys.forEach(o => {
            if (listsCuratorialPast[v].objects[o].approved) {
              objectApproved = true;
            }
          });
        }
      });
    }

    if (objectApproved) {
      const museumObjects = makeMuseumObjects(
        curatorialKeys,
        curatorialPastKeys,
      );

      if (museumObjects) {
        content = <React.Fragment>{museumObjects}</React.Fragment>;
      } else {
        content = (
          <div className="mcv-empty-container">
            <p className="mcv-empty-txt">No Objects</p>
          </div>
        );
      }
    } else {
      content = (
        <div className="mcv-empty-container">
          <p className="mcv-empty-txt">No Objects</p>
        </div>
      );
    }

    return (
      <div>
        <div className="mcv-header d-flex">
          <img
            src={HistoryHeader}
            alt="history"
            className="mcv-header-icon-2"
          />
          <div>
            <div className="mcv-header-super-txt">
              <p className="txt-16">{isMuseum ? 'MUSEUM' : 'INSTITUTION'}</p>
            </div>
            <p className="txt-header">Proposal History</p>
          </div>
        </div>
        {content}
      </div>
    );
  };

  if (
    !getListCuratorialRequest.loading &&
    !getListsCuratorialPastRequest.loading &&
    !getListInterestRequest.loading &&
    !getListsInterestPastRequest.loading &&
    listCuratorial &&
    typeof listCuratorial === 'object' &&
    museumAccount &&
    typeof museumAccount === 'object' &&
    !isLoadingDonations
  ) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mcv-content mx-auto mex-mb-30">{makeContent()}</div>
      </div>
    );
  }
  return <div className="gen-background-nav-museum"></div>;
}
