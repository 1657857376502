import { dpropSetSelectedProposals, objDraftSetForm } from '@actions';
import MoreIcon from '@images/more-icon.png';
import ObjectDraftImagePlaceholder from '@images/object_draft_image_placeholder.png';
import { parseDraftIntoFormData } from '@glue';
import { convertToMMDDYY, findCycleInCollection, addDays } from '@lib';
import { getDaysLeftInCatalog } from '../../../lib';
import { ONGOING_CYCLE_NAME } from 'constants/cycles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DonorAccountMoveObjectPopover } from '../DonorAccountObjectPopover/DonorAccountMoveObjectPopover';
import { DonorAccountActiveObjectActionsPopover } from '../DonorAccountObjectPopover/DonorAccountObjectActionsPopover';
import { partition } from 'lodash';
import { useCurrentDonorAccountRoleMatches } from 'components/_shared/useDonorAccountRole';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import {
  useGetDonorAccountsQuery,
  useGetNetworkQuery,
  usePrefetch,
} from 'requests/api';
const defaultProps = {
  cycles: [],
  donations: [],
  museums: [],
  proposals: [],
};
export function DonorObjectsList({
  selectedAccount,
  inactiveObjects: _inactiveObjects,
  activeObjects: _activeObjects,
  cycles = defaultProps.cycles,
  donations = defaultProps.donations,
  museums = defaultProps.museums,
  proposals = defaultProps.proposals,
}) {
  const { inactiveObjects, activeObjects } = reconcileActiveAndInactiveObjects({
    inactiveObjects: _inactiveObjects,
    activeObjects: _activeObjects,
    cycles,
    donations,
    proposals,
  });

  const filteredActiveObjects = filterAndTransformObjectsToRender({
    objects: activeObjects,
    cycles,
    donations,
    museums,
    proposals,
  });

  const filteredInactiveObjects = filterAndTransformObjectsToRender({
    objects: inactiveObjects,
    cycles,
    donations,
    museums,
    proposals,
  });

  return (
    <>
      {filteredActiveObjects.length ? (
        <div>
          <div className="d-flex mex-mb-22 da-active-inactive-top">
            <div className="mex-mr-8">
              <p className="da-active-inactive-title">ACTIVE</p>
            </div>
            <div>
              <p className="da-obj-count-txt">{`${
                filteredActiveObjects.length
              } OBJECT${filteredActiveObjects.length > 1 ? 'S' : ''}`}</p>
            </div>
          </div>
          <div>
            {filteredActiveObjects.map(({ objRenderer, objState }) => (
              <ObjectsListRow
                key={`active-row-${objState.object.id}`}
                selectedAccount={selectedAccount}
                objRenderer={objRenderer}
                objState={objState}
              />
            ))}
          </div>
        </div>
      ) : null}
      {filteredInactiveObjects.length ? (
        <div className="spc-mt-20">
          <div className="d-flex mex-mb-22 da-active-inactive-top">
            <div className="mex-mr-8">
              <p className="da-active-inactive-title">INACTIVE</p>
            </div>
            <div>
              <p className="da-obj-count-txt">{`${
                filteredInactiveObjects.length
              } OBJECT${filteredInactiveObjects.length > 1 ? 'S' : ''}`}</p>
            </div>
          </div>
          <div>
            {filteredInactiveObjects.map(({ objRenderer, objState }) => (
              <ObjectsListRow
                key={`active-row-${objState.object.id}`}
                selectedAccount={selectedAccount}
                objRenderer={objRenderer}
                objState={objState}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

function ObjectsListRow({ objRenderer, objState, selectedAccount }) {
  const object = objState.object;
  const dispatch = useDispatch();
  const selectedObject = useSelector(
    state => state.donorAccountsState.selectedObject,
  );
  const moveMenuOpen = useSelector(
    state => state.donorAccountsState.moveMenuOpen,
  );
  const userId = useSelector(state => state.authState.token.userId);

  const { data: network, isLoading: isLoadingNetwork } =
    useGetNetworkQuery(userId);

  const { data: accounts } = useGetDonorAccountsQuery(userId);

  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  return (
    <div
      className={`da-obj-lst-card d-flex mex-mb-14 ${
        selectedObject && selectedObject.id === object.id
          ? 'da-obj-lst-card-active'
          : ''
      }`}
      key={object.id}
    >
      {object.primaryImage ? (
        <img
          src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${object.primaryImage.bucket}.s3.amazonaws.com/${object.primaryImage.imageId}`}
          alt="primary"
          className="da-obj-lst-card-img"
        />
      ) : Array.isArray(object.supportingImages) &&
        object.supportingImages[0] ? (
        <img
          src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${object.supportingImages[0].bucket}.s3.amazonaws.com/${object.supportingImages[0].imageId}`}
          alt="primary"
          className="da-obj-lst-card-img"
        />
      ) : (
        <img
          src={ObjectDraftImagePlaceholder}
          alt="object_draft_image_placeholder"
          className="da-obj-list-card-img-placeholder"
        />
      )}
      <div className="da-obj-lst-card-sec-1">
        <div className="da-obj-lst-card-sec-1-content">
          {object.artistName && (
            <div className="da-obj-lst-card-main-txt-container">
              <p className="da-obj-lst-card-txt gen-crop-txt-3">
                {object.artistName || ''}
              </p>
            </div>
          )}
          <p className="da-obj-lst-card-txt-sm">
            <span className="gen-crop-txt-2">
              <i>{object.title}</i>
              {object.year ? ',' : ''}
            </span>{' '}
            {object.year || ''}
          </p>
        </div>
      </div>
      <div className="da-obj-lst-card-sec-2 d-flex justify-content-between">
        <div className="da-obj-lst-card-sec-2-content">
          <div className="da-obj-lst-card-sec-2-content-inner">
            {objRenderer.renderStatusContent(objState)}
          </div>
        </div>
        <div className="da-obj-lst-card-sec-2-btn-container">
          {objRenderer.renderButton({
            ...objState,
            selectedAccount,
            canEdit,
            dispatch,
          })}
        </div>
      </div>
      <div
        className="da-obj-list-card-img-more-container d-flex justify-content-center"
        onClick={e => {
          e.stopPropagation();
          dispatch(dacctActions.dacctSetSelectedObject(object));
        }}
      >
        <img src={MoreIcon} alt="more" className="da-obj-list-card-img-more" />
      </div>
      {selectedObject && selectedObject.id === object.id ? (
        moveMenuOpen ? (
          <DonorAccountMoveObjectPopover selectedAccount={selectedAccount} />
        ) : (
          <DonorAccountActiveObjectActionsPopover
            object={object}
            network={network}
            accounts={accounts}
            accountId={selectedAccount.id}
          />
        )
      ) : null}
    </div>
  );
}

function filterAndTransformObjectsToRender({
  objects,
  cycles,
  donations,
  museums,
  proposals,
}) {
  return objects
    .map(object =>
      deriveObjectState({ object, cycles, donations, museums, proposals }),
    )
    .map(objState => {
      // get the proper renderers
      const objRenderer = ORDERED_STATES.find(obj =>
        Boolean(obj.match(objState)),
      );

      if (!objRenderer) {
        console.log('no renderer matched', objState.currentCycle?.name, {
          objState,
        });
      }

      return { objRenderer, objState };
    })
    .filter(({ objRenderer }) => {
      // filter out any objects that don't match a render template
      return Boolean(objRenderer);
    });
}

const STATES_OBJ = {
  DRAFT: {
    match: ({ object }) => object.status === 'draft',
    renderStatusContent: () => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">New Object</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">Draft</p>
        </React.Fragment>
      );
    },
    renderButton: ({ object, selectedAccount, canEdit, dispatch }) => {
      if (canEdit) {
        return (
          <Link
            to={`/donor/folders/${selectedAccount.id}/object/draft/1`}
            className="gen-link-no-decoration"
            onClick={e => {
              e.stopPropagation();
              dispatch(
                objDraftSetForm({ formData: parseDraftIntoFormData(object) }),
              );
            }}
          >
            <div className="da-obj-lst-card-btn">
              <p>RESUME NEW OBJECT</p>
            </div>
          </Link>
        );
      }
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/draft/view`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(
              objDraftSetForm({ formData: parseDraftIntoFormData(object) }),
            );
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p className="tw-uppercase">View Draft</p>
          </div>
        </Link>
      );
    },
  },
  SUBMITTED_FOR_REVIEW: {
    match: ({ object }) => object.status === 'review',
    renderStatusContent: () => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Object Submitted</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">
            Awaiting Museum Exchange approval
          </p>
        </React.Fragment>
      );
    },
    renderButton: () => {
      return (
        <a
          href="mailto:help@museumexchange.com"
          target="_blank"
          rel="noopener noreferrer"
          className="gen-link-no-decoration"
        >
          <div className="da-obj-lst-card-btn">
            <p>CONTACT US</p>
          </div>
        </a>
      );
    },
  },
  APPROVED_FOR_UPCOMING_MUSEUM_CYCLE: {
    match: ({ object, upcomingCycle }) => {
      return (
        object.status === 'approved' &&
        upcomingCycle &&
        upcomingCycle.name !== ONGOING_CYCLE_NAME
      );
    },
    renderStatusContent: ({ dateRange }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Upcoming Catalogue</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{dateRange}</p>
        </React.Fragment>
      );
    },
    renderButton: ({ object, selectedAccount }) => {
      const dispatch = useDispatch();
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/${object.id}/preview`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>CATALOGUE PREVIEW</p>
          </div>
        </Link>
      );
    },
  },
  LIVE_IN_CURRENT_MUSEUM_CYCLE: {
    match: ({ object, currentCycle }) =>
      object.status === 'approved' &&
      currentCycle &&
      currentCycle.name !== ONGOING_CYCLE_NAME,
    renderStatusContent: ({ dateRange }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Live in Catalogue</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{dateRange}</p>
        </React.Fragment>
      );
    },
    renderButton: ({ object, selectedAccount, dispatch }) => {
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/${object.id}/preview`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>CATALOGUE PREVIEW</p>
          </div>
        </Link>
      );
    },
  },
  LIVE_IN_CURRENT_ONGOING_CYCLE: {
    match: ({ object, currentCycle, isLiveInOngoingCatalog }) => {
      return (
        object.status === 'approved' &&
        currentCycle &&
        currentCycle.name === ONGOING_CYCLE_NAME &&
        isLiveInOngoingCatalog
      );
    },
    renderStatusContent: ({ dateRange }) => {
      return (
        <>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Live in Catalogue</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{dateRange}</p>
        </>
      );
    },
    renderButton: ({ object, selectedAccount, dispatch }) => {
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/${object.id}/preview`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>CATALOGUE PREVIEW</p>
          </div>
        </Link>
      );
    },
  },
  IN_PROPOSAL_REVIEW_ONGOING_CYCLE: {
    match: ({ currentCycle, object, donation, proposals }) => {
      return (
        currentCycle?.name === ONGOING_CYCLE_NAME &&
        !donation &&
        !!proposals?.[object.id]
      );
    },
    renderStatusContent: ({ dateRangeStart }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Proposal Review</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{`Begins ${dateRangeStart}`}</p>
        </React.Fragment>
      );
    },
    renderButton: ({ object, proposals, selectedAccount, dispatch }) => {
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/${object.id}/proposals`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>VIEW PROPOSALS</p>
          </div>
        </Link>
      );
    },
  },
  IN_PROPOSAL_REVIEW_MUSEUM_CYCLE: {
    // TODO: set past from ongoing catalog
    match: ({ pastCycle, currentCycle, object, donation, proposals }) => {
      return pastCycle && !donation && !!proposals?.[object.id];
    },
    renderStatusContent: ({ dateRangeStart }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Proposal Review</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{`Begins ${dateRangeStart}`}</p>
        </React.Fragment>
      );
    },
    renderButton: ({ object, proposals, selectedAccount, dispatch }) => {
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/${object.id}/proposals`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>VIEW PROPOSALS</p>
          </div>
        </Link>
      );
    },
  },
  // TODO: not sure if needed.
  //   INSTITUTION_IN_PROPOSAL_REVIEW: {
  //     // TODO: set past from ongoing catalog
  //     match: ({ past, donation }) => past && !donation,
  //     renderStatusContent: ({ dateRangeStart }) => {
  //       return (
  //         <React.Fragment>
  //           <div className="da-obj-lst-card-main-txt-container">
  //             <p className="da-obj-lst-card-txt">Proposal Review</p>
  //           </div>
  //           <p className="da-obj-lst-card-txt-sm">{`Begins ${dateRangeStart}`}</p>
  //         </React.Fragment>
  //       );
  //     },
  //     renderButton: ({ object, proposals }) => {
  //       const dispatch = useDispatch();
  //       return (
  //         <Link
  //           to={`/donor/object/${object.id}/proposals`}
  //           className="gen-link-no-decoration"
  //           onClick={e => {
  //             e.stopPropagation();
  //             dispatch(dacctActions.dacctSetSelectedObject(object));
  //             dispatch(dpropSetSelectedProposals(proposals[object.id]));
  //             // this.handleSetSelectedObject(v);
  //             // this.handleSetSelectedProposals(this.props.proposals[v.id]);
  //           }}
  //         >
  //           <div className="da-obj-lst-card-btn">
  //             <p>VIEW PROPOSALS</p>
  //           </div>
  //         </Link>
  //       );
  //     },
  //   },
  // PROPOSAL_SELECTED_AWAITING_INSTITUTION_CONFIRMATION: {
  //   match: ({ donation }) => {
  //     return (
  //       donation &&
  //       donation.events.accepted &&
  //       donation.events.acceptedComplete &&
  //       !donation.events.confirmedComplete
  //     );
  //   },
  //   renderStatusContent: () => {
  //     return (
  //       <React.Fragment>
  //          <div className="da-obj-lst-card-main-txt-container">
  //            <p className="da-obj-lst-card-txt">Donor Form Submitted</p>
  //          </div>
  //          <p className="da-obj-lst-card-txt-sm">
  //            Awaiting Institution Confirmation
  //          </p>
  //        </React.Fragment>
  //      );
  //    },
  //    renderButton: () => {
  //     return (
  //       <a
  //         href="mailto:help@museumexchange.com"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         className="gen-link-no-decoration"
  //       >
  //         <div className="da-obj-lst-card-btn">
  //            <p>CONTACT US</p>
  //          </div>
  //        </a>
  //      );
  //    },
  //  },
  DONATION_COMPLETED: {
    match: ({ donation, museum }) =>
      donation &&
      museum &&
      (donation.events.completed || donation.events.closed),
    renderStatusContent: ({ donation, museum }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">
              {donation.events.closed
                ? 'Donation Abandoned'
                : 'Donation Completed'}
            </p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{museum.name}</p>
        </React.Fragment>
      );
    },
    renderButton: ({ donation, object, selectedAccount, dispatch }) => {
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/donation/${donation.id}`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>DONATION OVERVIEW</p>
          </div>
        </Link>
      );
    },
  },
  PROPOSAL_SELECTED: {
    match: ({ donation }) => {
      return (
        donation &&
        donation.events.accepted &&
        !donation.events.acceptedComplete
      );
    },
    renderStatusContent: () => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Proposal Selected</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">Complete Donation Form</p>
        </React.Fragment>
      );
    },
    renderButton: ({
      donation,
      object,
      selectedAccount,
      dispatch,
      canEdit,
    }) => {
      if (!canEdit) {
        return (
          // Note: consider abstracting tailwind button styles to @apply component.
          <button
            className="tw-h-9 tw-w-full tw-rounded-[4px] tw-bg-[#424b5a] tw-pt-[10px] tw-pb-3 tw-text-xs tw-uppercase tw-text-white disabled:tw-cursor-not-allowed disabled:tw-bg-[#d8d8d8]"
            disabled
          >
            Resume Form
          </button>
        );
      }
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/donations/${donation.id}/match`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>RESUME FORM</p>
          </div>
        </Link>
      );
    },
  },
  DONATION_IN_PROGRESS: {
    match: ({ donation, museum }) =>
      donation && museum && donation.events.acceptedComplete,
    renderStatusContent: ({ museum }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Donation in Progress</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">{museum.name}</p>
        </React.Fragment>
      );
    },
    renderButton: ({ donation, object, selectedAccount, dispatch }) => {
      return (
        <Link
          to={`/donor/folders/${selectedAccount.id}/donation/${donation.id}`}
          className="gen-link-no-decoration"
          onClick={e => {
            e.stopPropagation();
            dispatch(dacctActions.dacctSetSelectedObject(object));
          }}
        >
          <div className="da-obj-lst-card-btn">
            <p>DONATION OVERVIEW</p>
          </div>
        </Link>
      );
    },
  },

  RESTING: {
    // TODO: update for ongoing catalog to show "resting" objects.
    match: ({ isResting }) => isResting,
    renderStatusContent: ({ dateRange }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Resting</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">Shown in Catalogue</p>
          <p className="da-obj-lst-card-txt-sm">{dateRange}</p>
        </React.Fragment>
      );
    },
    renderButton: () => {
      return (
        <a
          href="mailto:help@museumexchange.com"
          target="_blank"
          rel="noopener noreferrer"
          className="gen-link-no-decoration"
        >
          <div className="da-obj-lst-card-btn">
            <p>CONTACT US</p>
          </div>
        </a>
      );
    },
  },
  REJECTED_INSUFFICIENT_DETAILS: {
    match: ({ object }) => object?.events?.rejected?.timestamp,
    renderStatusContent: ({ object }) => {
      return (
        <React.Fragment>
          <div className="da-obj-lst-card-main-txt-container">
            <p className="da-obj-lst-card-txt">Declined</p>
          </div>
          <p className="da-obj-lst-card-txt-sm">Insufficient details</p>
          <p className="da-obj-lst-card-txt-sm">
            {convertToMMDDYY(object.events.rejected.timestamp)}
          </p>
        </React.Fragment>
      );
    },
    renderButton: () => {
      return (
        <a
          href="mailto:help@museumexchange.com"
          target="_blank"
          rel="noopener noreferrer"
          className="gen-link-no-decoration"
        >
          <div className="da-obj-lst-card-btn">
            <p>CONTACT US</p>
          </div>
        </a>
      );
    },
  },
  //   REJECTED: {
  //     match: true,
  //     renderStatusContent: () => {
  //       return null;
  //     },
  //     renderButton: () => null,
  //   },
};

const ORDERED_STATES = [
  STATES_OBJ.DRAFT,
  STATES_OBJ.SUBMITTED_FOR_REVIEW,
  STATES_OBJ.APPROVED_FOR_UPCOMING_MUSEUM_CYCLE,
  STATES_OBJ.LIVE_IN_CURRENT_MUSEUM_CYCLE,
  STATES_OBJ.LIVE_IN_CURRENT_ONGOING_CYCLE,
  STATES_OBJ.IN_PROPOSAL_REVIEW_MUSEUM_CYCLE,
  STATES_OBJ.IN_PROPOSAL_REVIEW_ONGOING_CYCLE,
  STATES_OBJ.PROPOSAL_SELECTED,
  STATES_OBJ.DONATION_COMPLETED,
  STATES_OBJ.DONATION_IN_PROGRESS,
  STATES_OBJ.RESTING,
  STATES_OBJ.REJECTED_INSUFFICIENT_DETAILS,
];

function reconcileActiveAndInactiveObjects({
  // Why does this reconcile function exist? Well, the backend does not properly return everything as inactive, so we need to move some things around.
  activeObjects = [],
  inactiveObjects = [],
  donations,
  proposals,
  cycles,
}) {
  const [moreInactiveObjects, actualActiveObjects] = partition(
    activeObjects,
    object => {
      return getIsObjectResting({ object, donations, proposals, cycles });
    },
  );

  const actualInactiveObjects = [...inactiveObjects, ...moreInactiveObjects];

  return {
    activeObjects: actualActiveObjects,
    inactiveObjects: actualInactiveObjects,
  };
}

function deriveObjectState({ object, cycles, donations, museums, proposals }) {
  let dateRange = '';
  let dateRangeStart = '';
  let upcomingCycle = null;
  let currentCycle = null;
  let directCycle = null;
  let pastCycle = null;
  let donation = null;
  let museum = null;
  let isLiveInOngoingCatalog = false;

  const isResting = getIsObjectResting({
    donations,
    object,
    cycles,
    proposals,
  });
  if (object.status === 'approved') {
    if (cycles && Array.isArray(cycles.upcoming)) {
      upcomingCycle = findCycleInCollection(object.cycleId, cycles.upcoming);
    }

    if (cycles && Array.isArray(cycles.current)) {
      currentCycle = findCycleInCollection(object.cycleId, cycles.current);
    }

    if (cycles && Array.isArray(cycles.direct)) {
      directCycle = findCycleInCollection(object.cycleId, cycles.direct);
    }

    if (cycles && Array.isArray(cycles.past)) {
      pastCycle = findCycleInCollection(object.cycleId, cycles.past);
    }

    if (upcomingCycle) {
      const start = convertToMMDDYY(addDays(upcomingCycle.cutoff, 2));
      const end = convertToMMDDYY(addDays(upcomingCycle.end, 1));
      dateRangeStart = start;
      dateRange = `${start} - ${end}`;
    }

    if (currentCycle) {
      const start = convertToMMDDYY(addDays(currentCycle.cutoff, 2));
      const end = convertToMMDDYY(addDays(currentCycle.end, 1));
      dateRangeStart = start;
      dateRange = `${start} - ${end}`;

      // Note: Handling the case for institution ongoing cycle.
      if (currentCycle?.name === ONGOING_CYCLE_NAME) {
        const daysLeft = getDaysLeftInCatalog({
          dateAddedToCatalog: object.events.approved.timestamp,
          firstProposalDate: object.firstProposalDate,
        });

        const start = convertToMMDDYY(object.events.approved.timestamp);
        // The end is exclusive, so the last day in catalog is really the
        // preceding day.
        const end = convertToMMDDYY(addDays(Date.now(), daysLeft - 1));
        dateRangeStart = start;
        dateRange = `${start} - ${end}`;

        donation = donations?.find(donation => donation.objectId === object.id);

        isLiveInOngoingCatalog = daysLeft > 0;

        // If an ongoing catalog object has a proposal and is no longer live,
        // use the end date as the start of proposal review.
        if (object.firstProposalDate && !isLiveInOngoingCatalog) {
          const reviewStart = convertToMMDDYY(addDays(Date.now(), daysLeft));
          dateRangeStart = `${reviewStart}`;
        }
        if (donation) {
          // Note: if there's already a donation then it's not in the catalog.
          isLiveInOngoingCatalog = false;
        }
      }
    }

    if (pastCycle) {
      const start = convertToMMDDYY(addDays(pastCycle.end, 2));
      const end = convertToMMDDYY(addDays(pastCycle.end, 32));
      dateRangeStart = start;
      dateRange = `${start} - ${end}`;
    }
    if (currentCycle?.name !== ONGOING_CYCLE_NAME && isResting) {
      pastCycle = findCycleInCollection(object.cycleId, cycles.past);
      if (pastCycle) {
        const start = convertToMMDDYY(addDays(pastCycle.cutoff, 2));
        const end = convertToMMDDYY(addDays(pastCycle.end, 1));
        dateRange = `${start} - ${end}`;
      }
    }
  }

  if (Array.isArray(donations) && donations.length && Array.isArray(museums)) {
    donations.forEach(d => {
      if (d.objectId === object.id) {
        donation = d;
        museums.forEach(m => {
          if (d.museumId === m.id) {
            museum = m;
          }
        });
      }
    });
  }

  return {
    dateRange,
    hasDonation: !!pastCycle & !!donation,
    dateRangeStart,
    upcomingCycle,
    currentCycle,
    directCycle,
    pastCycle,
    isLiveInOngoingCatalog,
    donation,
    isResting,
    museum,
    object,
    cycles,
    donations,
    museums,
    proposals,
    proposalsForObject: proposals?.[object?.id],
    acceptedProposalForObject: proposals?.[object?.id]?.find(
      proposal => !!proposal.events.accepted,
    ),
  };
}

function getIsObjectResting({ object, donations, cycles, proposals }) {
  if (object.events.rejected) {
    return true;
  }

  const past = findCycleInCollection(object.cycleId, cycles.past);
  const donation = donations?.find(donation => donation.objectId === object.id);
  if (past && !donation && !proposals?.[object.id]) {
    return true;
  }
  if (donation?.events?.completed || donation?.events?.closed) {
    return true;
  }

  const currentCycle = findCycleInCollection(object.cycleId, cycles.current);
  if (currentCycle?.name === ONGOING_CYCLE_NAME) {
    const daysLeft = getDaysLeftInCatalog({
      dateAddedToCatalog: object.events.approved.timestamp,
      firstProposalDate: object.firstProposalDate,
    });

    if (daysLeft < 1 && !donation && !proposals?.[object.id]) {
      return true;
    }
  }

  return false;
}
