import {
  DONOR_APPLICATION_RESET,
  DONOR_APPLICATION_UPDATE_CITY,
  DONOR_APPLICATION_UPDATE_CONSENT,
  DONOR_APPLICATION_UPDATE_ERRORS,
  DONOR_APPLICATION_UPDATE_PROFESSION,
  DONOR_APPLICATION_UPDATE_STATE,
  DONOR_APPLICATION_UPDATE_TYPE,
} from '../../actions/actionTypes';

const initialDonorApplicationState = {
  formData: {
    consent: false,
    donorType: '',
    locationCity: '',
    locationState: '',
    profession: '',
  },
  formErrors: {
    formError: false,
    consentError: false,
    donorTypeError: false,
    locationCityError: false,
    locationStateError: false,
    professionError: false,
  },
};

export const donorApplicationReducer = (
  state = initialDonorApplicationState,
  action,
) => {
  switch (action.type) {
    case DONOR_APPLICATION_RESET:
      return {
        ...state,
        formData: {
          consent: false,
          donorType: '',
          locationCity: '',
          locationState: '',
          profession: '',
        },
        formErrors: {
          formError: false,
          consentError: false,
          donorTypeError: false,
          locationCityError: false,
          locationStateError: false,
          professionError: false,
        },
      };

    case DONOR_APPLICATION_UPDATE_CITY:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationCity: action.payload.locationCity,
        },
      };

    case DONOR_APPLICATION_UPDATE_CONSENT:
      return {
        ...state,
        formData: {
          ...state.formData,
          consent: action.payload.consent,
        },
      };

    case DONOR_APPLICATION_UPDATE_ERRORS:
      return {
        ...state,
        formErrors: action.payload.formErrors,
      };

    case DONOR_APPLICATION_UPDATE_PROFESSION:
      return {
        ...state,
        formData: {
          ...state.formData,
          profession: action.payload.profession,
        },
      };

    case DONOR_APPLICATION_UPDATE_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationState: action.payload.locationState,
        },
      };

    case DONOR_APPLICATION_UPDATE_TYPE:
      return {
        ...state,
        formData: {
          ...state.formData,
          donorType: action.payload.donorType,
        },
      };

    default:
      return state;
  }
};
