import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import {
  objDraftDeletePrimaryImageClear,
  objDraftDeleteSupportingImageClear,
  objDraftPostClear,
  objDraftPutClear,
  objDraftPutPrimaryImageClear,
  objDraftPutSupportingImageClear,
  objDraftUpdateDraftErrors,
  objDraftUpdatePrimaryImage,
  objDraftUpdateSupportingImages,
  objDraftUpdateSubmitErrors,
  objDraftSetSelectedImg,
} from '../../actions';
import {
  deleteObjectImage,
  postObjectDraft,
  putObjectDraft,
  putObjectImage,
} from '../../requests';
import {
  makeObjectDraftPayload,
  validateFormDraft,
  validateFormSubmit,
} from '../../glue';
import ImageIcon from '../../images/image-icon.png';
import 'react-image-lightbox/style.css';
import '../../styles/general.css';
import { BackToObjectsLink, NextDestination } from './ObjectDraftOne';

class ObjectDraftFour extends Component {
  static get propTypes() {
    return {
      deleteObjectDraftPrimaryImageClear: PropTypes.func,
      deleteObjectDraftPrimaryImageRequest: PropTypes.object,
      deleteObjectDraftSupportingImageClear: PropTypes.func,
      deleteObjectDraftSupportingImageRequest: PropTypes.object,
      deleteObjectImage: PropTypes.func,
      formData: PropTypes.object,
      formDraftErrors: PropTypes.object,
      formSubmitAttempted: PropTypes.bool,
      formSubmitErrors: PropTypes.object,
      postObjectDraft: PropTypes.func,
      postObjectDraftClear: PropTypes.func,
      postObjectDraftRequest: PropTypes.object,
      putObjectDraft: PropTypes.func,
      putObjectDraftClear: PropTypes.func,
      putObjectDraftRequest: PropTypes.object,
      putObjectDraftPrimaryImageClear: PropTypes.func,
      putObjectDraftPrimaryImageRequest: PropTypes.object,
      putObjectDraftSupportingImageClear: PropTypes.func,
      putObjectDraftSupportingImageRequest: PropTypes.object,
      putObjectImage: PropTypes.func,
      selectedImg: PropTypes.object,
      setSelectedImg: PropTypes.func,
      token: PropTypes.object,
      updateDraftErrors: PropTypes.func,
      updatePrimaryImage: PropTypes.func,
      updateSupportingImages: PropTypes.func,
      updateSubmitErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.deleteObjectDraftPrimaryImageClear();
    this.props.deleteObjectDraftSupportingImageClear();
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
    this.props.putObjectDraftPrimaryImageClear();
    this.props.putObjectDraftSupportingImageClear();
  }

  componentWillUnmount() {
    this.props.deleteObjectDraftPrimaryImageClear();
    this.props.deleteObjectDraftSupportingImageClear();
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
    this.props.putObjectDraftPrimaryImageClear();
    this.props.putObjectDraftSupportingImageClear();
    this.props.setSelectedImg(null);
  }

  handleNewPrimaryImage = event => {
    if (
      this.props.putObjectDraftPrimaryImageRequest.loading ||
      this.props.deleteObjectDraftPrimaryImageRequest.loading
    ) {
      return;
    }

    const file = event.target.files[0];

    const data = new FormData();
    data.append('file', file);
    data.append('fileName', file.name);
    data.append('primaryImage', 'true');

    this.props.putObjectImage(
      this.props.token,
      this.props.formData.id,
      data,
      'true',
      this.props.match.params.accountId,
    );
  };

  removePrimaryImage = _ => {
    if (
      this.props.putObjectDraftPrimaryImageRequest.loading ||
      this.props.deleteObjectDraftPrimaryImageRequest.loading
    ) {
      return;
    }

    this.props.deleteObjectImage(
      this.props.token,
      this.props.formData.id,
      this.props.formData.primaryImage.imageId,
      true,
      this.props.match.params.accountId,
    );
  };

  handleUpdatePrimaryImage = description => {
    const primaryImage = JSON.parse(
      JSON.stringify(this.props.formData.primaryImage),
    );
    primaryImage.description = description;
    this.props.updatePrimaryImage({ primaryImage });
  };

  handleNewSupportingImage = event => {
    if (this.props.putObjectDraftSupportingImageRequest.loading) {
      return;
    }

    const file = event.target.files[0];

    const data = new FormData();
    data.append('file', file);
    data.append('fileName', file.name);
    data.append('primaryImage', 'false');

    this.props.putObjectImage(
      this.props.token,
      this.props.formData.id,
      data,
      'false',
      this.props.match.params.accountId,
    );
  };

  imageToRemove = '';
  removeSupportingImage = imageId => {
    if (this.props.deleteObjectDraftSupportingImageRequest.loading) {
      return;
    }

    this.imageToRemove = imageId;

    this.props.deleteObjectImage(
      this.props.token,
      this.props.formData.id,
      imageId,
      false,
      this.props.match.params.accountId,
    );
  };

  handleUpdateSupportingImages = (imageId, description) => {
    const supportingImages = JSON.parse(
      JSON.stringify(this.props.formData.supportingImages),
    );
    supportingImages.forEach(image => {
      if (image.imageId === imageId) {
        image.description = description;
      }
    });
    this.props.updateSupportingImages({ supportingImages });
  };

  destination = '';
  handleNextStepClick = _ => {
    if (
      this.props.postObjectDraftRequest.loading ||
      this.props.putObjectDraftRequest.loading
    ) {
      return;
    }

    const formDraftErrors = validateFormDraft(this.props.formData);
    this.props.updateDraftErrors({ formDraftErrors });

    if (this.props.formSubmitAttempted) {
      const formSubmitErrors = validateFormSubmit(this.props.formData);
      this.props.updateSubmitErrors({ formSubmitErrors });
    }

    if (formDraftErrors.formError) {
      return;
    }

    let payload = {};
    try {
      payload = makeObjectDraftPayload(this.props.formData);
    } catch (e) {
      console.error(e);
      return;
    }

    if (this.props.formData.id) {
      this.props.putObjectDraft(
        this.props.token,
        this.props.formData.id,
        payload,
      );
    } else {
      this.props.postObjectDraft(this.props.token, payload, this.props.user.id);
    }
  };

  makePrimaryPhotoContent() {
    if (this.props.putObjectDraftPrimaryImageRequest.loading) {
      return (
        <p className="text" style={{ fontStyle: 'italic' }}>
          uploading photo...
        </p>
      );
    } else if (this.props.deleteObjectDraftPrimaryImageRequest.loading) {
      return (
        <p className="text" style={{ fontStyle: 'italic' }}>
          deleting photo...
        </p>
      );
    } else if (this.props.formData.primaryImage) {
      return (
        <div className="new-object-image-card mex-mb-14">
          {this.props.deleteObjectDraftPrimaryImageRequest.error ? (
            <p className="text label-error mex-mb-12">
              Error deleting photo. Please try again.
            </p>
          ) : null}
          <div className="d-flex justify-content-between mex-mb-14">
            <img src={ImageIcon} alt="icon" className="new-object-image-icon" />
            <a
              href="# "
              className="gen-link-no-decoration"
              onClick={e => {
                e.preventDefault();
                this.props.setSelectedImg(this.props.formData.primaryImage);
              }}
            >
              <p className="new-object-image-name text-truncate">
                {this.props.formData.primaryImage.fileName}
              </p>
            </a>
            <button
              className="btn btn-primary new-object-remove-image-btn"
              onClick={_ => this.removePrimaryImage()}
            >
              REMOVE
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {this.props.putObjectDraftPrimaryImageRequest.error ? (
            <p className="text label-error mex-mb-12">
              Error uploading photo. Please try again.
            </p>
          ) : null}
          <input
            type="file"
            accept="image/jpeg,image/jpg,image/png,image/x-png"
            onChange={event => this.handleNewPrimaryImage(event)}
            className="hidden"
            id="primaryPhoto"
          />
          <label
            className="btn btn-primary mex-upload-image-btn"
            htmlFor="primaryPhoto"
          >
            ADD IMAGE +
          </label>
        </div>
      );
    }
  }

  makeSupportingPhotos() {
    if (
      this.props.formData.supportingImages &&
      Array.isArray(this.props.formData.supportingImages) &&
      this.props.formData.supportingImages.length
    ) {
      return this.props.formData.supportingImages.map((photo, index) => {
        if (
          this.props.deleteObjectDraftSupportingImageRequest.loading &&
          this.imageToRemove === photo.imageId
        ) {
          return (
            <p
              key={`delete_photo_msg_${index}`}
              className="text"
              style={{ fontStyle: 'italic' }}
            >
              deleting photo...
            </p>
          );
        } else {
          return (
            <div
              className="new-object-image-card mex-mb-14"
              key={`supportingPhoto_${photo.imageId}`}
            >
              {this.props.deleteObjectDraftSupportingImageRequest.error &&
              this.imageToRemove === photo.imageId ? (
                <p className="text label-error mex-mb-12">
                  Error deleting photo. Please try again.
                </p>
              ) : null}
              <div className="d-flex justify-content-between mex-mb-14">
                <img
                  src={ImageIcon}
                  alt="icon"
                  className="new-object-image-icon"
                />
                <a
                  href="# "
                  className="gen-link-no-decoration"
                  onClick={e => {
                    e.preventDefault();
                    this.props.setSelectedImg(photo);
                  }}
                >
                  <p className="new-object-image-name text-truncate">
                    {photo.fileName}
                  </p>
                </a>
                <button
                  className="btn btn-primary new-object-remove-image-btn"
                  onClick={_ => this.removeSupportingImage(photo.imageId)}
                >
                  REMOVE
                </button>
              </div>
            </div>
          );
        }
      });
    }
  }

  makeSupportingPhotoUpload() {
    if (this.props.putObjectDraftSupportingImageRequest.loading) {
      return (
        <p className="text" style={{ fontStyle: 'italic' }}>
          uploading photo...
        </p>
      );
    } else {
      return (
        <div>
          {this.props.putObjectDraftSupportingImageRequest.error ? (
            <p className="text label-error mex-mb-12">
              Error uploading photo. Please try again.
            </p>
          ) : null}
          {!this.props.formData.supportingImages ||
          (Array.isArray(this.props.formData.supportingImages) &&
            this.props.formData.supportingImages.length) < 9 ? (
            <React.Fragment>
              <input
                type="file"
                accept="image/jpeg,image/jpg,image/png,image/x-png"
                onChange={event => this.handleNewSupportingImage(event)}
                className="hidden"
                id="supportingPhoto"
              />
              <label
                className="btn btn-primary mex-upload-image-btn"
                htmlFor="supportingPhoto"
              >
                ADD IMAGE +
              </label>
            </React.Fragment>
          ) : null}
        </div>
      );
    }
  }

  render() {
    const { accountId } = this.props.match.params;

    if (
      (this.props.postObjectDraftRequest.data &&
        !this.props.postObjectDraftRequest.loading &&
        !this.props.postObjectDraftRequest.error) ||
      (this.props.putObjectDraftRequest.data &&
        !this.props.putObjectDraftRequest.loading &&
        !this.props.putObjectDraftRequest.error)
    ) {
      return (
        <NextDestination destination={this.destination} nextPageNumber={5} />
      );
    }

    const primaryPhotoContent = this.makePrimaryPhotoContent();
    const supportingPhotos = this.makeSupportingPhotos();
    const supportingPhotoUpload = this.makeSupportingPhotoUpload();

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <div className="back-to-folders-link-container">
          <BackToObjectsLink />
        </div>
        <p className="header text-center mex-mt-47">Submit a New Object</p>
        <div className="sub-header sub-header-wide mx-auto mex-mb-30">
          <p className="text text-center">
            Please complete this form to list your object for donation
            consideration on Museum Exchange
          </p>
        </div>
        <div className="new-object-steps-container mx-auto d-flex justify-content-between mex-mb-26">
          <Link
            className="link-no-decoration"
            to={`/donor/folders/${accountId}/object/draft/1`}
          >
            <div
              className={`new-object-step new-object-step-complete d-flex justify-content-center align-items-center ${
                this.props.formDraftErrors.errorPages.one ||
                this.props.formSubmitErrors.errorPages.one
                  ? 'new-object-step-error'
                  : ''
              }`}
            >
              <p
                className={`new-object-step-text ${
                  this.props.formDraftErrors.errorPages.one ||
                  this.props.formSubmitErrors.errorPages.one
                    ? 'new-object-step-current-text'
                    : ''
                }`}
              >
                OBJECT DETAILS
              </p>
            </div>
          </Link>
          <Link
            className="link-no-decoration"
            to={`/donor/folders/${accountId}/object/draft/2`}
          >
            <div
              className={`new-object-step new-object-step-complete d-flex justify-content-center align-items-center ${
                this.props.formSubmitErrors.errorPages.two
                  ? 'new-object-step-error'
                  : ''
              }`}
            >
              <p
                className={`new-object-step-text ${
                  this.props.formSubmitErrors.errorPages.two
                    ? 'new-object-step-current-text'
                    : ''
                }`}
              >
                CATALOGUING
              </p>
            </div>
          </Link>
          <Link
            className="link-no-decoration"
            to={`/donor/folders/${accountId}/object/draft/3`}
          >
            <div
              className={`new-object-step new-object-step-complete d-flex justify-content-center align-items-center ${
                this.props.formDraftErrors.errorPages.three ||
                this.props.formSubmitErrors.errorPages.three
                  ? 'new-object-step-error'
                  : ''
              }`}
            >
              <p
                className={`new-object-step-text ${
                  this.props.formDraftErrors.errorPages.three ||
                  this.props.formSubmitErrors.errorPages.three
                    ? 'new-object-step-current-text'
                    : ''
                }`}
              >
                DONATION INFORMATION
              </p>
            </div>
          </Link>
          <div
            className={`new-object-step new-object-step-current d-flex justify-content-center align-items-center ${
              this.props.formSubmitErrors.errorPages.four
                ? 'new-object-step-error'
                : ''
            }`}
          >
            <p className="new-object-step-text new-object-step-current-text">
              UPLOAD IMAGES
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p className="new-object-step-text">REVIEW & SUBMIT</p>
          </div>
        </div>
        <div className="new-object-content-container d-flex justify-content-center mx-auto">
          <div className="new-object-content">
            <div className="new-object-image-content-inner mx-auto d-flex justify-content-between">
              <div className="new-object-image-content-form">
                <p className="label-bold mex-mb-6">Upload Images</p>
                <p className="title-small mex-mb-30">
                  Upload up to 10 JPEG or PNG images (up to 20 MB each)
                </p>
                <div className="horizontal-break mex-mb-30"></div>
                <div className="mex-mb-30">
                  <p
                    className={`text-small text-bold mex-mb-3 ${
                      this.props.formSubmitErrors.primaryImageError
                        ? 'label-error'
                        : ''
                    }`}
                  >
                    Primary Image
                  </p>
                  <p className="title-small mex-mb-14">
                    Primary image will be used as the display image for the
                    object
                  </p>
                  {primaryPhotoContent}
                </div>
                <div className="horizontal-break mex-mb-30"></div>
                <div className="mex-mb-30">
                  <p className="text-small text-bold ">Supporting Images</p>
                  <p className="title-small mex-mb-3">OPTIONAL</p>
                  <p className="title-small mex-mb-14">
                    Supporting images can include photographs of the front,
                    back, signature/inscriptions, condition issues, or details
                    of the object
                  </p>
                  {supportingPhotos}
                  {supportingPhotoUpload}
                </div>
              </div>
              <div className="new-object-image-photography-message-container">
                <p className="new-object-image-photography-message-text new-object-image-photography-message-title mex-mb-12">
                  Need a professional photographer?
                </p>
                <p className="new-object-image-photography-message-text mex-mb-12">
                  Use our Preferred Network to connect with professionals for
                  all your listing needs
                </p>
                <a
                  href="mailto:photography@museumexchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-no-decoration"
                >
                  <p className="new-object-image-photography-message-text new-object-image-photography-message-cta">
                    Contact a Professional
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar fixed-bottom d-flex justify-content-center new-object-nav-bottom align-items-end">
          <div className="new-object-nav-bottom-container d-flex justify-content-end">
            <div className="new-object-nav-bottom-alert-container">
              {(this.props.putObjectDraftRequest.error ||
                this.props.postObjectDraftRequest.error ||
                this.props.formDraftErrors.formError ||
                this.props.formSubmitErrors.formError) && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                  {this.props.putObjectDraftRequest.error ||
                  this.props.postObjectDraftRequest.error
                    ? 'Please try again'
                    : 'Please complete missing field(s)'}
                </div>
              )}
            </div>
            <button
              className="btn btn-primary new-object-nav-bottom-save-button"
              onClick={_ => {
                this.destination = 'folders';
                this.handleNextStepClick();
              }}
            >
              SAVE DRAFT & EXIT
            </button>
          </div>
          <div className="new-object-nav-bottom-container d-flex justify-content-start">
            <div>
              <p className="title-small text-center mex-mb-10">NEXT STEP</p>
              <button
                className="btn btn-primary new-object-nav-bottom-next-button"
                onClick={_ => {
                  this.destination = 'next';
                  this.handleNextStepClick();
                }}
              >
                REVIEW & SUBMIT
              </button>
            </div>
            <div className="new-object-nav-bottom-note-container"></div>
          </div>
        </div>
        {this.props.selectedImg && (
          <Lightbox
            mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${this.props.selectedImg.bucket}.s3.amazonaws.com/${this.props.selectedImg.imageId}`}
            onCloseRequest={_ => this.props.setSelectedImg(null)}
            imagePadding={65}
            clickOutsideToClose={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    deleteObjectDraftPrimaryImageRequest:
      state.objectDraftState.objDraftDeletePrimaryImageRequest,
    deleteObjectDraftSupportingImageRequest:
      state.objectDraftState.objDraftDeleteSupportingImageRequest,
    formData: state.objectDraftState.formData,
    formDraftErrors: state.objectDraftState.formDraftErrors,
    formSubmitAttempted: state.objectDraftState.formSubmitAttempted,
    formSubmitErrors: state.objectDraftState.formSubmitErrors,
    postObjectDraftRequest: state.objectDraftState.objDraftPostRequest,
    putObjectDraftRequest: state.objectDraftState.objDraftPutRequest,
    putObjectDraftPrimaryImageRequest:
      state.objectDraftState.objDraftPutPrimaryImageRequest,
    putObjectDraftSupportingImageRequest:
      state.objectDraftState.objDraftPutSupportingImageRequest,
    selectedImg: state.objectDraftState.selectedImg,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    deleteObjectDraftPrimaryImageClear: _ =>
      dispatch(objDraftDeletePrimaryImageClear()),
    deleteObjectDraftSupportingImageClear: _ =>
      dispatch(objDraftDeleteSupportingImageClear()),
    deleteObjectImage: (token, objId, imgId, primary, accountId) =>
      dispatch(deleteObjectImage(token, objId, imgId, primary, accountId)),
    postObjectDraft: (token, payload, userId) =>
      dispatch(postObjectDraft(token, payload, userId)),
    postObjectDraftClear: _ => dispatch(objDraftPostClear()),
    putObjectDraft: (token, id, payload) =>
      dispatch(putObjectDraft(token, id, payload)),
    putObjectDraftClear: _ => dispatch(objDraftPutClear()),
    putObjectDraftPrimaryImageClear: _ =>
      dispatch(objDraftPutPrimaryImageClear()),
    putObjectDraftSupportingImageClear: _ =>
      dispatch(objDraftPutSupportingImageClear()),
    putObjectImage: (token, id, payload, primary, accountId) =>
      dispatch(putObjectImage(token, id, payload, primary, accountId)),
    setSelectedImg: payload => dispatch(objDraftSetSelectedImg(payload)),
    updateDraftErrors: payload => dispatch(objDraftUpdateDraftErrors(payload)),
    updatePrimaryImage: payload =>
      dispatch(objDraftUpdatePrimaryImage(payload)),
    updateSupportingImages: payload =>
      dispatch(objDraftUpdateSupportingImages(payload)),
    updateSubmitErrors: payload =>
      dispatch(objDraftUpdateSubmitErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDraftFour);
