import {
  DSET_PUT_DONOR_LOCATION_CLEAR,
  DSET_PUT_DONOR_LOCATION_FAILURE,
  DSET_PUT_DONOR_LOCATION_REQUEST,
  DSET_PUT_DONOR_LOCATION_SUCCESS,
  DSET_PUT_DONOR_TYPE_CLEAR,
  DSET_PUT_DONOR_TYPE_FAILURE,
  DSET_PUT_DONOR_TYPE_REQUEST,
  DSET_PUT_DONOR_TYPE_SUCCESS,
  DSET_RESET_TYPE_FORM,
  DSET_UPDATE_TYPE_ERRORS,
  DSET_UPDATE_TYPE,
  DSET_UPDATE_PROFESSION,
  DSET_RESET_LOCATION_FORM,
  DSET_UPDATE_LOCATION_ERRORS,
  DSET_UPDATE_LOCATION_CITY,
  DSET_UPDATE_LOCATION_STATE,
} from '../actionTypes';

export const dsetPutDonorLocationClear = _ => {
  return {
    type: DSET_PUT_DONOR_LOCATION_CLEAR,
  };
};

export const dsetPutDonorLocationFailure = payload => {
  return {
    type: DSET_PUT_DONOR_LOCATION_FAILURE,
    payload,
  };
};

export const dsetPutDonorLocationRequest = _ => {
  return {
    type: DSET_PUT_DONOR_LOCATION_REQUEST,
  };
};

export const dsetPutDonorLocationSuccess = payload => {
  return {
    type: DSET_PUT_DONOR_LOCATION_SUCCESS,
    payload,
  };
};

export const dsetPutDonorTypeClear = _ => {
  return {
    type: DSET_PUT_DONOR_TYPE_CLEAR,
  };
};

export const dsetPutDonorTypeFailure = payload => {
  return {
    type: DSET_PUT_DONOR_TYPE_FAILURE,
    payload,
  };
};

export const dsetPutDonorTypeRequest = _ => {
  return {
    type: DSET_PUT_DONOR_TYPE_REQUEST,
  };
};

export const dsetPutDonorTypeSuccess = payload => {
  return {
    type: DSET_PUT_DONOR_TYPE_SUCCESS,
    payload,
  };
};

export const dsetResetTypeForm = _ => {
  return {
    type: DSET_RESET_TYPE_FORM,
  };
};

export const dsetUpdateTypeErrors = payload => {
  return {
    type: DSET_UPDATE_TYPE_ERRORS,
    payload,
  };
};

export const dsetUpdateType = payload => {
  return {
    type: DSET_UPDATE_TYPE,
    payload,
  };
};

export const dsetUpdateProfession = payload => {
  return {
    type: DSET_UPDATE_PROFESSION,
    payload,
  };
};

export const dsetResetLocationForm = _ => {
  return {
    type: DSET_RESET_LOCATION_FORM,
  };
};

export const dsetUpdateLocationErrors = payload => {
  return {
    type: DSET_UPDATE_LOCATION_ERRORS,
    payload,
  };
};

export const dsetUpdateLocationCity = payload => {
  return {
    type: DSET_UPDATE_LOCATION_CITY,
    payload,
  };
};

export const dsetUpdateLocationState = payload => {
  return {
    type: DSET_UPDATE_LOCATION_STATE,
    payload,
  };
};
