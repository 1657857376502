import { sanitizeTel } from '../../lib';

export const makeProposalPayload = formData => {
  const payload = {
    museumId: formData.museumId,
    objectId: formData.objectId,
    cycleId: formData.cycleId,
    pointOfContactId: formData.pointOfContactId,
    pointOfContactTel: formData.pointOfContactTel,
    letterOfInterest: formData.letterOfInterest,
    images: formData.images,
    conditionsClarification: formData.conditionsClarification,
    museumRequiresDonorPayShipping: formData.museumRequiresDonorPayShipping,
  };

  if (
    typeof formData.pointOfContactTel === 'string' &&
    formData.pointOfContactTel
  ) {
    payload.pointOfContactTel = sanitizeTel(formData.pointOfContactTel);
  } else {
    payload.pointOfContactTel = null;
  }

  if (
    typeof formData.letterOfInterest === 'string' &&
    formData.letterOfInterest
  ) {
    payload.letterOfInterest = formData.letterOfInterest;
  } else {
    payload.letterOfInterest = null;
  }

  if (
    typeof formData.conditionsClarification === 'string' &&
    formData.conditionsClarification
  ) {
    payload.conditionsClarification = formData.conditionsClarification;
  } else {
    payload.conditionsClarification = null;
  }

  return payload;
};
