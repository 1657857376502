import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ddonmSetFormThree,
  ddonmUpdateCanPack,
  ddonmUpdateCondition,
  ddonmUpdateCrate,
  ddonmUpdateCrateHeight,
  ddonmUpdateCrateWidth,
  ddonmUpdateCrateDepth,
  ddonmUpdateCrateSystem,
  ddonmUpdateFrame,
  ddonmUpdateFrameHeight,
  ddonmUpdateFrameWidth,
  ddonmUpdateFrameDepth,
  ddonmUpdateFrameSystem,
  ddonmUpdateInsuranceValue,
  ddonmUpdateShippingInstructions,
  ddonmUpdateWeight,
  ddonmUpdateWeightWeight,
  ddonmUpdateWeightSystem,
  ddonmUpdateFormThreeErrors,
  donPutConditionReportMatchClear,
} from '../../actions';
import ApprovedCheck from '../../images/approved-check.png';
import BackIcon from '../../images/arrow-back.png';
import InfoIcon from '../../images/info-icon-donation.png';
import '../../styles/donor-donation-match.css';
import '../../styles/modal.css';
import '../../styles/fields.css';
import '../../styles/spacing.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import {
  useDeleteDonationConditionReportMatchMutation,
  useGetMuseumAccountsSimpleQuery,
  useLazyGetDonationConditionReportMatchQuery,
  usePutDonationConditionReportMatchMutation,
  usePutDonationPreliminaryObjectMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';
import { useHistory } from 'react-router-dom';
import { openBase64PDFNewWindow } from '@lib';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export default function DonorDonationPreliminaryObject() {
  const { accountId, donationId } = useParams();
  const { goBack } = useHistory();

  const {
    data: donation,
    isLoading: isLoadingDonation,
    isError: isErrorGetDonation,
  } = useDonorGetDonation({
    donationId,
    accountId,
  });
  const {
    data: object,
    isLoading: isLoadingObject,
    isError: isErrorGetObject,
  } = useDonorGetObject({
    accountId,
    objectId: donation?.objectId,
  });

  // TODO: need to rip out the sucess complete part here. We will put back to match 1.
  const [
    putDonationPreliminaryObject,
    { isLoading: isLoadingPut, isSuccess: isSuccessPut, data: putCompleteData },
  ] = usePutDonationPreliminaryObjectMutation();

  const formData = useSelector(
    state => state.donorDonationsMatchState.formDataThree,
  );
  const formErrors = useSelector(
    state => state.donorDonationsMatchState.formErrorsThree,
  );
  const isMuseum = useSelector(selectIsMuseum);

  const dispatch = useDispatch();

  const [deleteConditionReport, { isLoading: isLoadingDeleteConditionReport }] =
    useDeleteDonationConditionReportMatchMutation();

  const [getConditionReport, { isLoading: isLoadingGetConditionReport }] =
    useLazyGetDonationConditionReportMatchQuery();

  const [putConditionReport, { isLoading: isLoadingPutConditionReport }] =
    usePutDonationConditionReportMatchMutation();

  const setFormData = payload => dispatch(ddonmSetFormThree(payload));
  const updateCanPack = payload => dispatch(ddonmUpdateCanPack(payload));
  const updateCondition = payload => dispatch(ddonmUpdateCondition(payload));
  const updateCrate = payload => dispatch(ddonmUpdateCrate(payload));
  const updateCrateHeight = payload =>
    dispatch(ddonmUpdateCrateHeight(payload));
  const updateCrateWidth = payload => dispatch(ddonmUpdateCrateWidth(payload));
  const updateCrateDepth = payload => dispatch(ddonmUpdateCrateDepth(payload));
  const updateCrateSystem = payload =>
    dispatch(ddonmUpdateCrateSystem(payload));
  const updateFrame = payload => dispatch(ddonmUpdateFrame(payload));
  const updateFrameHeight = payload =>
    dispatch(ddonmUpdateFrameHeight(payload));
  const updateFrameWidth = payload => dispatch(ddonmUpdateFrameWidth(payload));
  const updateFrameDepth = payload => dispatch(ddonmUpdateFrameDepth(payload));
  const updateFrameSystem = payload =>
    dispatch(ddonmUpdateFrameSystem(payload));
  const updateInsuranceValue = payload =>
    dispatch(ddonmUpdateInsuranceValue(payload));
  const updateShippingInstructions = payload =>
    dispatch(ddonmUpdateShippingInstructions(payload));
  const updateWeight = payload => dispatch(ddonmUpdateWeight(payload));
  const updateWeightWeight = payload =>
    dispatch(ddonmUpdateWeightWeight(payload));
  const updateWeightSystem = payload =>
    dispatch(ddonmUpdateWeightSystem(payload));
  const updateErrors = payload => dispatch(ddonmUpdateFormThreeErrors(payload));

  const { data: museums } = useGetMuseumAccountsSimpleQuery();

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitialized) return;

    if (donation && object) {
      if (donation.objectInfo.canPackOnSite === null) {
        if (
          object &&
          (object.classification === 'Decorative Arts' ||
            object.classification === 'Installation' ||
            object.classification === 'Sculpture') &&
          object.weightImperial &&
          !formData.weight &&
          !donation.objectInfo.weight
        ) {
          updateWeight({
            weight: String(object.weightImperial),
            system: 'imperial',
          });
        }
        if (
          object &&
          object.dimensions &&
          object.dimensions.framed &&
          !formData.frame &&
          !donation.objectInfo.frame
        ) {
          updateFrame({
            height: String(object.dimensions.framed.heightImperial),
            width: String(object.dimensions.framed.widthImperial),
            depth: object.dimensions.framed.depthImperial
              ? String(object.dimensions.framed.depthImperial)
              : '',
            system: 'imperial',
          });
        }
        formData.canPackOnSite = true;
      } else {
        setFormData(matchThreeParsePayload(donation));
      }
      setIsInitialized(true);
    }
  }, [isInitialized, donation, object]);

  const isLoading = isLoadingDonation || isLoadingObject;

  const makeSubmitButton = _ => {
    const errors = matchThreeValidate(formData);

    return (
      <div className="d-flex align-items-end flex-column">
        <button
          className={`ddm-btn-next-inactive gen-cursor-hover-link tw-uppercase disabled:tw-opacity-50 ${
            !errors.formError ? 'ddm-btn-next-active' : ''
          }`}
          disabled={isLoadingPut}
          onClick={async _ => {
            if (isLoadingPut) {
              return;
            }
            updateErrors(errors);
            if (errors.formError) {
              return;
            }
            try {
              await putDonationPreliminaryObject({
                donationId: donationId,
                data: matchThreeMakePayload(formData),
              }).unwrap();
            } catch (e) {
              console.log(e);
            }
          }}
        >
          Submit
        </button>
        {formErrors.formError ? (
          <div className="ddm-error-message-container">
            <p className="ddm-error-message">
              Please complete required field(s)
            </p>
          </div>
        ) : null}
      </div>
    );
  };

  const makeFinishModal = _ => {
    let museum = null;
    museums.forEach(m => {
      if (donation.museumId === m.id) {
        museum = m;
      }
    });

    return (
      <div className="mod">
        <div className="mod-content-container mx-auto">
          <div className="d-flex justify-content-center mx-auto">
            <img
              src={ApprovedCheck}
              alt="approved-check"
              className="mod-approved-check"
            />
            <p className="mod-txt-header">Donation Form Submitted</p>
          </div>
          <div className="spc-mt-30">
            <p className="mod-txt-18">
              {`${museum.name} will be notified`}
              <br /> that they have been selected as the recipient for this
              donation.
              <br />
              The donation process will begin after the{' '}
              {isMuseum ? 'museum' : 'institution'}
              <br />
              has confirmed their acceptance.
            </p>
          </div>
          <div className="mod-btn-container mx-auto d-flex justify-content-center">
            <Link
              to={`/donor`}
              className=" btn mod-btn-dark gen-link-no-decoration dp-button-special"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              OKAY
            </Link>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <div className="gen-background-nav-donor"></div>;

  if (isSuccessPut) {
    if (!donation?.donorInfo?.shippingInfoSubmitted) {
      return (
        <Redirect
          push
          to={`/donor/folders/${accountId}/donation/${donationId}/preliminary-shipping-info`}
        />
      );
    } else {
      return (
        <Redirect
          push
          to={`/donor/folders/${accountId}/donation/${donationId}/`}
        />
      );
    }
  }

  if (isErrorGetDonation || isErrorGetObject) {
    return (
      <div className="gen-background-nav-donor">
        <div className="ddm-header mx-auto d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="ddm-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div class-name="ddm-header-back-container-content">
            <p className="ddm-header-title">Packing Information</p>
          </div>
        </div>
        <p className="text-center ddm-error-text">
          <em>Something went wrong.</em>
        </p>
      </div>
    );
  }

  if (donation && object && Array.isArray(museums) && museums.length) {
    return (
      <div className="gen-background-nav-donor">
        <div className="ddm-header mx-auto d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="ddm-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div class-name="ddm-header-back-container-content">
            <p className="ddm-header-title">Packing Information</p>
            <div className="ddm-header-text-container">
              <p className="ddm-header-text">
                Please complete this form for the institution to refer to while
                arranging shipping
              </p>
            </div>
          </div>
        </div>
        <div className="ddm-form-content mx-auto">
          <div className="ddm-form-content-inner mx-auto">
            <div className="ddm-form-section">
              <p className="ddm-form-section-title">
                Is the object framed? If yes, please provide dimensions.
              </p>
              <div className="spc-mt-15 d-flex justify-content-between">
                <div
                  className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                    formData.frame ? 'ddm-half-selector-active' : ''
                  }`}
                  onClick={_ =>
                    updateFrame({
                      height: '',
                      width: '',
                      depth: '',
                      system: '',
                    })
                  }
                >
                  <div
                    className={`ddm-half-selector-button ${
                      formData.frame ? 'ddm-half-selector-button-active' : ''
                    }`}
                  ></div>
                  <p
                    className={`ddm-half-selector-text ${
                      formData.frame ? 'ddm-half-selector-text-active' : ''
                    }`}
                  >
                    Yes
                  </p>
                </div>
                <div
                  className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                    !formData.frame ? 'ddm-half-selector-active' : ''
                  }`}
                  onClick={_ => updateFrame(null)}
                >
                  <div
                    className={`ddm-half-selector-button ${
                      !formData.frame ? 'ddm-half-selector-button-active' : ''
                    }`}
                  ></div>
                  <p
                    className={`ddm-half-selector-text ${
                      !formData.frame ? 'ddm-half-selector-text-active' : ''
                    }`}
                  >
                    No
                  </p>
                </div>
              </div>
              {formData.frame ? (
                <div className="spc-mt-15 d-flex justify-content-between">
                  <div className="ddm-small-field-container-outer">
                    <p
                      className={`ddm-field-label ${
                        formErrors.frameHeightError ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Height
                    </p>
                    <div className="ddm-small-field-container-inner">
                      <input
                        className={`fd-input ${
                          formErrors.frameHeightError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateFrameHeight(event.target.value);
                          }
                        }}
                        value={formData.frame ? formData.frame.height : ''}
                        placeholder="Height"
                      />
                    </div>
                  </div>
                  <div className="ddm-small-field-container-outer">
                    <p
                      className={`ddm-field-label ${
                        formErrors.frameWidthError ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Width
                    </p>
                    <div className="ddm-small-field-container-inner">
                      <input
                        className={`fd-input ${
                          formErrors.frameWidthError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateFrameWidth(event.target.value);
                          }
                        }}
                        value={formData.frame ? formData.frame.width : ''}
                        placeholder="Width"
                      />
                    </div>
                  </div>
                  <div className="ddm-small-field-container-outer">
                    <p className="ddm-field-label">Depth (optional)</p>
                    <div className="ddm-small-field-container-inner">
                      <input
                        className={`fd-input`}
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateFrameDepth(event.target.value);
                          }
                        }}
                        value={
                          formData.frame && formData.frame.depth
                            ? formData.frame.depth
                            : ''
                        }
                        placeholder="Depth"
                      />
                    </div>
                  </div>
                  <div className="ddm-small-field-container-outer">
                    <p
                      className={`ddm-field-label ${
                        formErrors.frameSystemError ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Units
                    </p>
                    <div className="ddm-small-field-container-inner">
                      <select
                        className={`btn dropdown-toggle mex-dropdown ${
                          formErrors.frameSystemError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        onChange={event =>
                          updateFrameSystem(event.target.value)
                        }
                        value={formData.frame ? formData.frame.system : ''}
                        style={{ width: '100%' }}
                      >
                        <option disabled value=""></option>
                        <option value={'metric'}>cm</option>
                        <option value={'imperial'}>in</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {object.classification === 'Decorative Arts' ||
            object.classification === 'Installation' ||
            object.classification === 'Sculpture' ? (
              <div className="ddm-form-section">
                <p className="ddm-form-section-title">
                  Do you know the weight of the object?
                </p>
                <div className="spc-mt-15 d-flex justify-content-between">
                  <div
                    className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                      formData.weight ? 'ddm-half-selector-active' : ''
                    }`}
                    onClick={_ => updateWeight({ weight: '', system: '' })}
                  >
                    <div
                      className={`ddm-half-selector-button ${
                        formData.weight ? 'ddm-half-selector-button-active' : ''
                      }`}
                    ></div>
                    <p
                      className={`ddm-half-selector-text ${
                        formData.weight ? 'ddm-half-selector-text-active' : ''
                      }`}
                    >
                      Yes
                    </p>
                  </div>
                  <div
                    className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                      !formData.weight ? 'ddm-half-selector-active' : ''
                    }`}
                    onClick={_ => updateWeight(null)}
                  >
                    <div
                      className={`ddm-half-selector-button ${
                        !formData.weight
                          ? 'ddm-half-selector-button-active'
                          : ''
                      }`}
                    ></div>
                    <p
                      className={`ddm-half-selector-text ${
                        !formData.weight ? 'ddm-half-selector-text-active' : ''
                      }`}
                    >
                      No
                    </p>
                  </div>
                </div>
                {formData.weight ? (
                  <div className="spc-mt-15 d-flex">
                    <div>
                      <p
                        className={`ddm-field-label ${
                          formErrors.weightWeightError
                            ? 'fd-input-error-txt'
                            : ''
                        }`}
                      >
                        Weight
                      </p>
                      <input
                        type="text"
                        className={`fd-input ddm-field-narrow ${
                          formErrors.weightWeightError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateWeightWeight(event.target.value);
                          }
                        }}
                        value={formData.weight ? formData.weight.weight : ''}
                        placeholder="Weight"
                      ></input>
                    </div>
                    <div
                      className="ddm-small-field-container-outer"
                      style={{ marginLeft: '20px' }}
                    >
                      <p
                        className={`ddm-field-label ${
                          formErrors.weightSystemError
                            ? 'fd-input-error-txt'
                            : ''
                        }`}
                      >
                        Units
                      </p>
                      <div className="ddm-small-field-container-inner">
                        <select
                          className={`btn dropdown-toggle mex-dropdown ${
                            formErrors.weightSystemError
                              ? 'fd-input-error-field'
                              : ''
                          }`}
                          onChange={event =>
                            updateWeightSystem(event.target.value)
                          }
                          value={formData.weight ? formData.weight.system : ''}
                          style={{ width: '100%' }}
                        >
                          <option disabled value=""></option>
                          <option value={'metric'}>kg</option>
                          <option value={'imperial'}>lb</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="ddm-form-section">
              <p className="ddm-form-section-title">
                Is the object packed/crated?
              </p>
              <div className="spc-mt-15 d-flex justify-content-between">
                <div
                  className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                    formData.crate ? 'ddm-half-selector-active' : ''
                  }`}
                  onClick={_ => {
                    updateCrate({
                      height: '',
                      width: '',
                      depth: '',
                      system: '',
                    });
                    updateCanPack(null);
                  }}
                >
                  <div
                    className={`ddm-half-selector-button ${
                      formData.crate ? 'ddm-half-selector-button-active' : ''
                    }`}
                  ></div>
                  <p
                    className={`ddm-half-selector-text ${
                      formData.crate ? 'ddm-half-selector-text-active' : ''
                    }`}
                  >
                    Yes
                  </p>
                </div>
                <div
                  className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                    !formData.crate ? 'ddm-half-selector-active' : ''
                  }`}
                  onClick={_ => updateCrate(null)}
                >
                  <div
                    className={`ddm-half-selector-button ${
                      !formData.crate ? 'ddm-half-selector-button-active' : ''
                    }`}
                  ></div>
                  <p
                    className={`ddm-half-selector-text ${
                      !formData.crate ? 'ddm-half-selector-text-active' : ''
                    }`}
                  >
                    No
                  </p>
                </div>
              </div>
              {formData.crate ? (
                <div className="spc-mt-15 d-flex justify-content-between">
                  <div className="ddm-small-field-container-outer">
                    <p
                      className={`ddm-field-label ${
                        formErrors.crateHeightError ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Height
                    </p>
                    <div className="ddm-small-field-container-inner">
                      <input
                        className={`fd-input ${
                          formErrors.crateHeightError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateCrateHeight(event.target.value);
                          }
                        }}
                        value={formData.crate ? formData.crate.height : ''}
                        placeholder="Height"
                      />
                    </div>
                  </div>
                  <div className="ddm-small-field-container-outer">
                    <p
                      className={`ddm-field-label ${
                        formErrors.crateWidthError ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Width
                    </p>
                    <div className="ddm-small-field-container-inner">
                      <input
                        className={`fd-input ${
                          formErrors.crateWidthError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateCrateWidth(event.target.value);
                          }
                        }}
                        value={formData.crate ? formData.crate.width : ''}
                        placeholder="Width"
                      />
                    </div>
                  </div>
                  <div className="ddm-small-field-container-outer">
                    <p className="ddm-field-label">Depth (optional)</p>
                    <div className="ddm-small-field-container-inner">
                      <input
                        className={`fd-input`}
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => {
                          if (event.target.validity.valid) {
                            updateCrateDepth(event.target.value);
                          }
                        }}
                        value={formData.crate ? formData.crate.depth : ''}
                        placeholder="Depth"
                      />
                    </div>
                  </div>
                  <div className="ddm-small-field-container-outer">
                    <p
                      className={`ddm-field-label ${
                        formErrors.crateSystemError ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Units
                    </p>
                    <div className="ddm-small-field-container-inner">
                      <select
                        className={`btn dropdown-toggle mex-dropdown ${
                          formErrors.crateSystemError
                            ? 'fd-input-error-field'
                            : ''
                        }`}
                        onChange={event =>
                          updateCrateSystem(event.target.value)
                        }
                        value={formData.crate ? formData.crate.system : ''}
                        style={{ width: '100%' }}
                      >
                        <option disabled value=""></option>
                        <option value={'metric'}>cm</option>
                        <option value={'imperial'}>in</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <p
                    className={`ddm-form-section-title tw-mt-4 ${
                      formErrors.canPackOnSiteError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Can the shipper pack the object on site?
                  </p>
                  <div className="spc-mt-15 d-flex justify-content-between">
                    <div
                      className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                        formData.canPackOnSite === true
                          ? 'ddm-half-selector-active'
                          : ''
                      }`}
                      onClick={_ => updateCanPack(true)}
                    >
                      <div
                        className={`ddm-half-selector-button ${
                          formData.canPackOnSite === true
                            ? 'ddm-half-selector-button-active'
                            : ''
                        }`}
                      ></div>
                      <p
                        className={`ddm-half-selector-text ${
                          formData.canPackOnSite === true
                            ? 'ddm-half-selector-text-active'
                            : ''
                        }`}
                      >
                        Yes
                      </p>
                    </div>
                    <div
                      className={`ddm-half-selector d-flex gen-cursor-hover-link ${
                        formData.canPackOnSite === false
                          ? 'ddm-half-selector-active'
                          : ''
                      }`}
                      onClick={_ => updateCanPack(false)}
                    >
                      <div
                        className={`ddm-half-selector-button ${
                          formData.canPackOnSite === false
                            ? 'ddm-half-selector-button-active'
                            : ''
                        }`}
                      ></div>
                      <p
                        className={`ddm-half-selector-text ${
                          formData.canPackOnSite === false
                            ? 'ddm-half-selector-text-active'
                            : ''
                        }`}
                      >
                        No
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15">
                <div className="d-flex">
                  <p className={`ddm-form-section-title`}>Packing Notes</p>
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-instructions"
                  />
                  <div className="ddm-hover-element ddm-hover-element-instructions">
                    <p className="ddm-hover-element-text">
                      Please indicate any special instructions for the handling,
                      packing, shipping, and/or installation of the object.
                    </p>
                  </div>
                </div>
                <p className="title-small">OPTIONAL</p>
              </div>
              <textarea
                className={`fd-input-textarea ddm-input-full`}
                onChange={event =>
                  updateShippingInstructions(event.target.value)
                }
                value={formData.shippingInstructions}
                maxLength="500"
                placeholder="Special Instructions"
              ></textarea>
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15">
                <div className="d-flex">
                  <p className={`ddm-form-section-title`}>Condition Notes</p>
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-condition"
                  />
                  <div className="ddm-hover-element ddm-hover-element-condition">
                    <p className="ddm-hover-element-text">
                      Please note any existing breaks, tears, scratches,
                      abrasions, paint losses, scuffs, or other insecurities or
                      defects in the object. If you have a condition report
                      available, please upload it using the option below.
                    </p>
                  </div>
                </div>
                <p className="title-small">OPTIONAL</p>
              </div>
              <textarea
                className={`fd-input-textarea ddm-input-full spc-mb-10`}
                onChange={event => updateCondition(event.target.value)}
                value={formData.condition}
                maxLength="500"
                placeholder="Description of condition"
              ></textarea>
              {isLoadingPutConditionReport || isLoadingDeleteConditionReport ? (
                <div className="ddm-upload-container-sm">
                  <p className="ddm-upload-updating-txt">
                    <em>Updating...</em>
                  </p>
                </div>
              ) : !donation.objectInfo.conditionReport ? (
                <div className="ddm-upload-container-sm d-flex">
                  <input
                    type="file"
                    accept="application/pdf"
                    onClick={e => {
                      e.target.value = null;
                    }}
                    onChange={e => {
                      if (isLoadingPutConditionReport) {
                        return;
                      }

                      const file = e.target.files[0];

                      const data = new FormData();
                      data.append('file', file);
                      data.append('fileName', file.name);

                      putConditionReport({ donationId: donation.id, data });
                    }}
                    className="hidden"
                    id="conditionReport"
                  />
                  <label
                    className="ddm-btn-upload gen-cursor-hover-link"
                    htmlFor="conditionReport"
                  >
                    UPLOAD CONDITION REPORT
                  </label>
                  <div className="ddm-upload-note">
                    <p className="ddm-field-label">
                      <em>PDFs only, file cannot exceed 20MB</em>
                    </p>
                  </div>
                </div>
              ) : (
                <div className="ddm-file-row d-flex justify-content-between">
                  <div className="ddm-upload-file-name-container text-truncate">
                    <p
                      className="ddm-file-name gen-link gen-cursor-hover-link"
                      onClick={async _ => {
                        if (isLoadingGetConditionReport) {
                          return;
                        }
                        try {
                          const conditionReportData = await getConditionReport(
                            donation.id,
                          ).unwrap();
                          openBase64PDFNewWindow(conditionReportData);
                        } catch {}
                      }}
                    >
                      {donation.objectInfo.conditionReport.fileName}
                    </p>
                  </div>
                  <div
                    className="ddm-btn-remove gen-cursor-hover-link"
                    onClick={_ => {
                      if (isLoadingDeleteConditionReport) {
                        return;
                      }

                      deleteConditionReport({ donationId: donation.id });
                    }}
                  >
                    REMOVE
                  </div>
                </div>
              )}
            </div>
            <div className="ddm-form-section">
              <div className="spc-mb-15">
                <div className="d-flex">
                  <p
                    className={`ddm-form-section-title ${
                      formErrors.insuranceValueError ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Insurance Value
                  </p>
                  <img
                    src={InfoIcon}
                    alt="info-icon"
                    className="ddm-info-icon gen-cursor-hover-link ddm-info-icon-insurance"
                  />
                  <div className="ddm-hover-element gen-cursor-hover-link ddm-hover-element-insurance">
                    <p className="ddm-hover-element-text">
                      This is required for shipping. The object will be insured
                      at this U.S. Dollar value. If you are unsure of the value,
                      please{' '}
                      <a
                        href={`mailto:donor_liaison@museumexchange.com`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gen-link d-hover-element-link"
                      >
                        contact us
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <input
                type="number"
                className={`ddm-field-full fd-input ${
                  formErrors.insuranceValueError ? 'fd-input-error-field' : ''
                }`}
                min="1"
                step="1"
                onKeyDown={e => {
                  const characterCode = e.key;
                  if (characterCode === 'Backspace') return;

                  const characterNumber = Number(characterCode);
                  if (characterNumber >= 0 && characterNumber <= 9) {
                    if (e.currentTarget.value && e.currentTarget.value.length) {
                      return;
                    } else if (characterNumber === 0) {
                      e.preventDefault();
                    }
                  } else {
                    e.preventDefault();
                  }
                }}
                onChange={event => updateInsuranceValue(event.target.value)}
                value={formData.insuranceValue}
                maxLength="10"
                placeholder="Insurance Value"
              ></input>
            </div>
            <div className="spc-mt-30 d-flex justify-content-end">
              {makeSubmitButton()}
            </div>
          </div>
        </div>
        {putCompleteData ? makeFinishModal() : null}
      </div>
    );
  }

  return null;
}

function matchThreeMakePayload(form) {
  const payload = {};

  if (form.weight) {
    payload.weight = {
      weight: Number(form.weight.weight),
      system: form.weight.system,
    };
  }

  if (form.frame) {
    payload.frame = {
      height: Number(form.frame.height),
      width: Number(form.frame.width),
      depth: form.frame.depth ? Number(form.frame.depth) : null,
      system: form.frame.system,
    };
  }

  if (form.crate) {
    payload.crate = {
      height: Number(form.crate.height),
      width: Number(form.crate.width),
      depth: form.crate.depth ? Number(form.crate.depth) : null,
      system: form.crate.system,
    };
  }

  payload.canPackOnSite = form.canPackOnSite;
  payload.condition = form.condition;
  payload.insuranceValue = Number(form.insuranceValue) * 100;
  payload.shippingInstructions = form.shippingInstructions;

  return payload;
}

function matchThreeParsePayload(payload) {
  const formData = {};

  if (payload.objectInfo.weight) {
    formData.weight = {
      weight: String(payload.objectInfo.weight.weightImperial),
      system: 'imperial',
    };
  }

  if (payload.objectInfo.frame) {
    formData.frame = {
      height: String(payload.objectInfo.frame.heightImperial),
      width: String(payload.objectInfo.frame.widthImperial),
      depth: payload.objectInfo.frame.depthImperial
        ? String(payload.objectInfo.frame.depthImperial)
        : null,
      system: 'imperial',
    };
  }

  if (payload.objectInfo.crate) {
    formData.crate = {
      height: String(payload.objectInfo.crate.heightImperial),
      width: String(payload.objectInfo.crate.widthImperial),
      depth: payload.objectInfo.crate.depthImperial
        ? String(payload.objectInfo.crate.depthImperial)
        : null,
      system: 'imperial',
    };
  }

  formData.canPackOnSite = payload.objectInfo.canPackOnSite;
  formData.condition = payload.objectInfo.condition;
  formData.insuranceValue = payload.objectInfo.insuranceValue
    ? payload.objectInfo.insuranceValue / 100
    : '';
  formData.shippingInstructions = payload.donorInfo.shippingInstructions;

  return formData;
}

function matchThreeValidate(payload) {
  const errors = {
    formError: false,
    insuranceValueError: false,
    weightWeightError: false,
    weightSystemError: false,
    frameHeightError: false,
    frameWidthError: false,
    frameSystemError: false,
    crateHeightError: false,
    crateWidthError: false,
    crateSystemError: false,
    canPackOnSiteError: false,
  };

  if (!payload.insuranceValue) {
    errors.formError = true;
    errors.insuranceValueError = true;
  }

  if (payload.weight) {
    if (!payload.weight.weight) {
      errors.formError = true;
      errors.weightWeightError = true;
    }

    if (!payload.weight.system) {
      errors.formError = true;
      errors.weightSystemError = true;
    }
  }

  if (payload.frame) {
    if (!payload.frame.height) {
      errors.formError = true;
      errors.frameHeightError = true;
    }

    if (!payload.frame.width) {
      errors.formError = true;
      errors.frameWidthError = true;
    }

    if (!payload.frame.system) {
      errors.formError = true;
      errors.frameSystemError = true;
    }
  }

  if (payload.crate) {
    if (!payload.crate.height) {
      errors.formError = true;
      errors.crateHeightError = true;
    }

    if (!payload.crate.width) {
      errors.formError = true;
      errors.crateWidthError = true;
    }

    if (!payload.crate.system) {
      errors.formError = true;
      errors.crateSystemError = true;
    }
  }

  if (!payload.crate) {
    if (payload.canPackOnSite === null) {
      errors.formError = true;
      errors.canPackOnSiteError = true;
    }
  }

  return errors;
}
