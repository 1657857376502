import { convertToFloat } from '../../lib';
import { dimensionsMapping } from '../';

export const makeObjectDraftPayload = formData => {
  const payload = {
    accountId: formData.account,
    collectingArea: formData.collectingArea,
    artistName: formData.artistName,
    title: formData.title,
    year: formData.year,
    classification: formData.classification,
    medium: formData.medium,
    condition: formData.condition,
    dimensions: {},
    provenance: formData.provenance,
    locationCity: formData.locationCity,
    locationState: formData.locationState,
    locationZip: formData.locationZip,
    primaryImage: formData.primaryImage,
    supportingImages: formData.supportingImages,
    submissionHash: formData.submissionHash,
    privacyPolicyHash: formData.privacyPolicyHash,
    tosHash: formData.tosHash,
  };

  dimensionsMapping.forEach(v => {
    let obj = null;
    formData.dimensions.forEach(o => {
      if (o.key === v.key) {
        obj = o;
      }
    });
    if (v.key === 'dimensionsVariable' || v.key === 'duration') {
      if (obj && obj.value) {
        payload.dimensions[v.key] = obj.value;
      } else {
        payload.dimensions[v.key] = null;
      }
    } else {
      if (
        obj &&
        obj.height &&
        obj.height !== '0' &&
        obj.width &&
        obj.width !== '0' &&
        obj.system
      ) {
        payload.dimensions[v.key] = {
          height: convertToFloat(obj.height),
          width: convertToFloat(obj.width),
          system: obj.system,
        };
        if (obj.depth && obj.depth !== '0') {
          payload.dimensions[v.key].depth = convertToFloat(obj.depth);
        }
        console.log(obj, payload.dimensions[v.key]);
      } else {
        payload.dimensions[v.key] = null;
      }
    }
  });

  if (formData.weight && formData.weight !== '0') {
    payload.weight = {
      weight: convertToFloat(formData.weight),
      system: formData.weightSystem,
    };
  } else {
    payload.weight = null;
  }

  if (formData.edition && formData.editionDescription) {
    payload.edition = formData.editionDescription;
  } else {
    payload.edition = null;
  }

  if (formData.inscriptions) {
    payload.inscriptions = formData.inscriptions;
  } else {
    payload.inscriptions = null;
  }

  if (formData.exhibitionHistory) {
    payload.exhibitionHistory = formData.exhibitionHistory;
  } else {
    payload.exhibitionHistory = null;
  }

  if (formData.literature) {
    payload.literature = formData.literature;
  } else {
    payload.literature = null;
  }

  if (formData.conservationHistory) {
    payload.conservationHistory = formData.conservationHistory;
  } else {
    payload.conservationHistory = null;
  }

  if (formData.restrictions && formData.restrictionsDescription) {
    payload.restrictions = formData.restrictionsDescription;
  } else {
    payload.restrictions = null;
  }

  if (formData.notes) {
    payload.notes = formData.notes;
  } else {
    payload.notes = null;
  }

  return payload;
};
