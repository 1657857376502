export const dimensionsMapping = [
  { display: 'Unframed', key: 'unframed' },
  { display: 'Framed', key: 'framed' },
  { display: 'Overall', key: 'overall' },
  { display: 'Each', key: 'each' },
  { display: 'Component', key: 'component' },
  { display: 'Base', key: 'base' },
  { display: 'Dimensions Variable', key: 'dimensionsVariable' },
  { display: 'Image', key: 'image' },
  { display: 'Sheet', key: 'sheet' },
  { display: 'Duration', key: 'duration' },
];
