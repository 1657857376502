import '../../styles/donation.css';
import '../../styles/general.css';
import '../../styles/modal.css';
import '../../styles/spacing.css';
import AssignRegistrarModal from './AssignRegistrarModal';
import BackIcon from '../../images/arrow-back.png';
import DocIcon from '../../images/doc-icon-blue.png';
import GreyCloseIcon from '../../images/grey-close-icon.png';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMuseumGetDonation } from 'components/_shared/useMuseumGetDonation';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useDeleteDonationConservatorReportMutation,
  useDeleteDonationDeedDeedMutation,
  useDeleteDonationLoanLoanMutation,
  useDeleteDonationShippingReceiptMutation,
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
  useLazyGetDonationConservatorReportQuery,
  useLazyGetDonationDeedDeedQuery,
  useLazyGetDonationLoanLoanQuery,
  useLazyGetDonationShippingReceiptQuery,
  usePutDonationConservatorReportMutation,
  usePutDonationDeedDeedMutation,
  usePutDonationDeedSendMutation,
  usePutDonationLoanLoanMutation,
  usePutDonationLoanSendMutation,
  usePutDonationShippingReceiptMutation,
  usePutMuseumRegistrarMutation,
} from 'requests/api';
import { AppraisalSection } from 'components/_shared/Donations/AppraisalSection';
import { DeedOfGiftSection } from 'components/_shared/Donations/DeedOfGiftSection';
import { DonationInformationSection } from 'components/_shared/Donations/DonationInformationSection';
import { DonationTopSection } from 'components/_shared/DonationTopSection';
import { FormIRSSection } from 'components/_shared/Donations/FormIRSSection';
import { LoanAgreementSection } from 'components/_shared/Donations/LoanAgreementSection';
import { PreShippingInspectionSection } from 'components/_shared/Donations/PreShippingInspectionSection';
import { ShippingArrangementsSection } from 'components/_shared/Donations/ShippingArrangementsSection';
import { openBase64PDFNewWindow } from '@lib';

export default function MuseumDonation() {
  const [mounted, setMounted] = useState(false);
  const { donationId } = useParams();

  const { data: museumAccount } = useGetMuseumAccountUserQuery();

  const { data: donation } = useMuseumGetDonation({
    donationId,
    accountId: museumAccount?.id,
  });

  const { data: object } = useGetObjMuseumQuery(
    { objectId: donation?.objectId },
    { skip: !donation },
  );

  const token = useSelector(state => state.authState.token);

  const [
    deleteConservatorReport,
    { isLoading: isLoadingDeleteConservatorReport },
  ] = useDeleteDonationConservatorReportMutation();

  const [deleteDeedDeed, { isLoading: isLoadingDeleteDeedDeed }] =
    useDeleteDonationDeedDeedMutation();

  const [deleteLoanLoan, { isLoading: isLoadingDeleteLoanLoan }] =
    useDeleteDonationLoanLoanMutation();

  const [deleteShippingReceipt, { isLoading: isLoadingDeleteShippingReceipt }] =
    useDeleteDonationShippingReceiptMutation();
  const [getConservatorReport] = useLazyGetDonationConservatorReportQuery();
  const [getDeedDeed] = useLazyGetDonationDeedDeedQuery();
  const [getLoanLoan] = useLazyGetDonationLoanLoanQuery();
  const getShippingReceipt = useLazyGetDonationShippingReceiptQuery();

  const [putMuseumRegistrar, { isLoading: isLoadingPutMuseumRegistrar }] =
    usePutMuseumRegistrarMutation();

  const [putConservatorReport, { isLoading: isLoadingPutConservatorReport }] =
    usePutDonationConservatorReportMutation();
  const [putDeedDeed, { isLoading: isLoadingPutDeedDeed }] =
    usePutDonationDeedDeedMutation();
  const [putDeedSend, { isLoading: isLoadingPutDeedSend }] =
    usePutDonationDeedSendMutation();
  const [putLoanLoan, { isLoading: isLoadingPutLoanLoan }] =
    usePutDonationLoanLoanMutation();
  const [putLoanSend, { isLoading: isLoadingPutLoanSend }] =
    usePutDonationLoanSendMutation();
  const [
    putDonationShippingReceipt,
    { isLoading: isLoadingPutShippingReceipt },
  ] = usePutDonationShippingReceiptMutation();

  const [isAssignRegistrarModalOpen, setIsAssignRegistrarModalOpen] =
    useState(false);
  const [isConservatorUploadOpen, setIsConservatorUploadOpen] = useState(false);
  const [isDeedUploadOpen, setIsDeedUploadOpen] = useState(false);
  const [isLoanUploadOpen, setIsLoanUploadOpen] = useState(false);
  const [isShippingUploadOpen, setIsShippingUploadOpen] = useState(false);
  const [museumRegistrar, setMuseumRegistrar] = useState(null);

  useEffect(() => {
    setTimeout(_ => setMounted(true), 1000);
  }, []);

  const makeConservatorUploadModal = _ => {
    return (
      <div className="mod">
        <div className="d-upload-modal mx-auto">
          <img
            src={GreyCloseIcon}
            alt="grey-close"
            className="d-upload-modal-close-icon gen-cursor-hover-link"
            onClick={_ => {
              if (donation.conservator.report) {
                deleteConservatorReport({ donationId: donation.id });
              }
              setTimeout(_ => {
                setIsConservatorUploadOpen(false);
              }, 300);
            }}
          />
          <div className="d-modal-upload-title-container">
            <p className="d-modal-upload-title">Upload Condition Report</p>
          </div>
          <p className="d-txt-sm-md">
            Upload the condition report. (PDFs only, file cannot exceed 20MB)
          </p>
          <div className="d-modal-upload-inner">
            {donation.conservator.report ? (
              <div className="d-upload-modal-file-card d-flex justify-content-between">
                <div className="d-flex spc-mt-20">
                  <img src={DocIcon} alt="doc" className="d-icon-doc" />
                  <p
                    className="d-upload-modal-file-card-txt gen-cursor-hover-link"
                    onClick={_ => getConservatorReport(token, donation.id)}
                  >
                    {donation.conservator.report.fileName}
                  </p>
                </div>
                <div
                  className="d-upload-modal-file-card-remove-btn gen-cursor-hover-link"
                  onClick={_ =>
                    deleteConservatorReport({ donationId: donation.id })
                  }
                >
                  REMOVE
                </div>
              </div>
            ) : (
              <React.Fragment>
                <input
                  type="file"
                  accept="application/pdf"
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    if (
                      isLoadingPutConservatorReport ||
                      isLoadingDeleteConservatorReport
                    ) {
                      return;
                    }

                    const file = e.target.files[0];

                    const data = new FormData();
                    data.append('file', file);
                    data.append('fileName', file.name);

                    putConservatorReport({ donationId: donation.id, data });
                  }}
                  className="hidden"
                  id="conservatorReport"
                />
                <label
                  className="d-modal-upload-btn gen-cursor-hover-link"
                  htmlFor="conservatorReport"
                >
                  SELECT FILE FROM YOUR DEVICE
                </label>
              </React.Fragment>
            )}
          </div>
          <div className="spc-mt-30 d-flex">
            {donation.conservator.report ? (
              <div
                className="d-modal-upload-btn-active gen-cursor-hover-link"
                onClick={_ => {
                  setIsConservatorUploadOpen(false);
                }}
              >
                SUBMIT
              </div>
            ) : (
              <div className="d-modal-upload-btn-inactive gen-cursor-hover-link">
                SUBMIT
              </div>
            )}
            <div
              className="d-modal-upload-btn-cancel gen-cursor-hover-link"
              onClick={_ => {
                if (donation.conservator.report) {
                  deleteConservatorReport({ donationId: donation.id });
                }
                setTimeout(_ => {
                  setIsConservatorUploadOpen(false);
                }, 300);
              }}
            >
              CANCEL
            </div>
            {isLoadingPutConservatorReport ||
            isLoadingDeleteConservatorReport ? (
              <div className="spc-mt-10">
                <p className="d-txt-dk">Updating...</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const makeLoanUploadModal = _ => {
    return (
      <div className="mod">
        <div className="d-upload-modal mx-auto">
          <img
            src={GreyCloseIcon}
            alt="grey-close"
            className="d-upload-modal-close-icon gen-cursor-hover-link"
            onClick={_ => {
              if (donation.loan.loan) {
                deleteLoanLoan({ donationId: donation.id });
              }
              setTimeout(_ => {
                setIsLoanUploadOpen(false);
              }, 300);
            }}
          />
          <div className="d-modal-upload-title-container">
            <p className="d-modal-upload-title">Upload Loan Agreement</p>
          </div>
          <p className="d-txt-sm-md">
            Upload the loan agreement for donor signature. (PDFs only, file
            cannot exceed 20MB)
          </p>
          <div className="d-modal-upload-inner">
            {donation.loan.loan ? (
              <div className="d-upload-modal-file-card d-flex justify-content-between">
                <div className="d-flex spc-mt-20">
                  <img src={DocIcon} alt="doc" className="d-icon-doc" />
                  <p
                    className="d-upload-modal-file-card-txt gen-cursor-hover-link"
                    onClick={async _ => {
                      const loanLoanData = await getLoanLoan({
                        donationId: donation.id,
                      }).unwrap();
                      openBase64PDFNewWindow(loanLoanData);
                    }}
                  >
                    {donation.loan.loan.fileName}
                  </p>
                </div>
                <div
                  className="d-upload-modal-file-card-remove-btn gen-cursor-hover-link"
                  onClick={_ => deleteLoanLoan(token, donation.id)}
                >
                  REMOVE
                </div>
              </div>
            ) : (
              <React.Fragment>
                <input
                  type="file"
                  accept="application/pdf"
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    if (isLoadingPutLoanLoan || isLoadingDeleteLoanLoan) {
                      return;
                    }

                    const file = e.target.files[0];

                    const data = new FormData();
                    data.append('file', file);
                    data.append('fileName', file.name);

                    putLoanLoan({ donationId: donation.id, data });
                  }}
                  className="hidden"
                  id="conservatorReport"
                />
                <label
                  className="d-modal-upload-btn gen-cursor-hover-link"
                  htmlFor="conservatorReport"
                >
                  SELECT FILE FROM YOUR DEVICE
                </label>
              </React.Fragment>
            )}
          </div>
          <div className="spc-mt-30 d-flex">
            {donation.loan.loan ? (
              <div
                className="d-modal-upload-btn-active gen-cursor-hover-link"
                onClick={_ => {
                  if (
                    isLoadingPutLoanLoan ||
                    isLoadingDeleteLoanLoan ||
                    isLoadingPutLoanSend
                  ) {
                    return;
                  }

                  putLoanSend({ donationId: donation.id });

                  setTimeout(_ => {
                    setIsLoanUploadOpen(false);
                  }, 1200);
                }}
              >
                SUBMIT
              </div>
            ) : (
              <div className="d-modal-upload-btn-inactive gen-cursor-hover-link">
                SUBMIT
              </div>
            )}
            <div
              className="d-modal-upload-btn-cancel gen-cursor-hover-link"
              onClick={_ => {
                if (donation.loan.loan) {
                  deleteLoanLoan({ donationId: donation.id });
                }
                setTimeout(_ => {
                  setIsLoanUploadOpen(false);
                }, 300);
              }}
            >
              CANCEL
            </div>
            {isLoadingPutLoanLoan ||
            isLoadingDeleteLoanLoan ||
            isLoadingPutLoanSend ? (
              <div className="spc-mt-10">
                <p className="d-txt-dk">Updating...</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const makeShippingUploadModal = _ => {
    return (
      <div className="mod">
        <div className="d-upload-modal mx-auto">
          <img
            src={GreyCloseIcon}
            alt="grey-close"
            className="d-upload-modal-close-icon gen-cursor-hover-link"
            onClick={_ => {
              if (donation.shipping.receipt) {
                deleteShippingReceipt({ donationId: donation.id });
              }
              setTimeout(_ => {
                setIsShippingUploadOpen(false);
              }, 300);
            }}
          />
          <div className="d-modal-upload-title-container">
            <p className="d-modal-upload-title">Upload Delivery Receipt</p>
          </div>
          <p className="d-txt-sm-md">
            Upload the delivery receipt. (PDFs only, file cannot exceed 20MB)
          </p>
          <div className="d-modal-upload-inner">
            {donation.shipping.receipt ? (
              <div className="d-upload-modal-file-card d-flex justify-content-between">
                <div className="d-flex spc-mt-20">
                  <img src={DocIcon} alt="doc" className="d-icon-doc" />
                  <p
                    className="d-upload-modal-file-card-txt gen-cursor-hover-link"
                    onClick={_ => getShippingReceipt(token, donation.id)}
                  >
                    {donation.shipping.receipt.fileName}
                  </p>
                </div>
                <div
                  className="d-upload-modal-file-card-remove-btn gen-cursor-hover-link"
                  onClick={_ =>
                    deleteShippingReceipt({ donationId: donation.id })
                  }
                >
                  REMOVE
                </div>
              </div>
            ) : (
              <React.Fragment>
                <input
                  type="file"
                  accept="application/pdf"
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={async e => {
                    if (
                      isLoadingPutShippingReceipt ||
                      isLoadingDeleteShippingReceipt
                    ) {
                      return;
                    }

                    const file = e.target.files[0];

                    const data = new FormData();
                    data.append('file', file);
                    data.append('fileName', file.name);

                    try {
                      putDonationShippingReceipt({
                        donationId: donation.id,
                        data,
                      }).unwrap();
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  className="hidden"
                  id="conservatorReport"
                />
                <label
                  className="d-modal-upload-btn gen-cursor-hover-link"
                  htmlFor="conservatorReport"
                >
                  SELECT FILE FROM YOUR DEVICE
                </label>
              </React.Fragment>
            )}
          </div>
          <div className="spc-mt-30 d-flex">
            {donation.shipping.receipt ? (
              <div
                className="d-modal-upload-btn-active gen-cursor-hover-link"
                onClick={_ => {
                  setIsShippingUploadOpen(false);
                }}
              >
                SUBMIT
              </div>
            ) : (
              <div className="d-modal-upload-btn-inactive gen-cursor-hover-link">
                SUBMIT
              </div>
            )}
            <div
              className="d-modal-upload-btn-cancel gen-cursor-hover-link"
              onClick={_ => {
                if (donation.shipping.receipt) {
                  deleteShippingReceipt({ donationId: donation.id });
                }
                setTimeout(_ => {
                  setIsShippingUploadOpen(false);
                }, 300);
              }}
            >
              CANCEL
            </div>
            {isLoadingPutShippingReceipt || isLoadingDeleteShippingReceipt ? (
              <div className="spc-mt-10">
                <p className="d-txt-dk">Updating...</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const makeDeedUploadModal = _ => {
    return (
      <div className="mod">
        <div className="d-upload-modal mx-auto">
          <img
            src={GreyCloseIcon}
            alt="grey-close"
            className="d-upload-modal-close-icon gen-cursor-hover-link"
            onClick={_ => {
              if (donation.deed.deed) {
                deleteDeedDeed({ donationId: donation.id });
              }
              setTimeout(_ => {
                setIsDeedUploadOpen(false);
              }, 300);
            }}
          />
          <div className="d-modal-upload-title-container">
            <p className="d-modal-upload-title">Upload Deed of Gift</p>
          </div>
          <p className="d-txt-sm-md">
            Upload a cover letter (if applicable) and the Deed of Gift for donor
            signature. (Attach as a single PDF, file cannot exceed 20MB)
          </p>
          <div className="d-modal-upload-inner">
            {donation.deed.deed ? (
              <div className="d-upload-modal-file-card d-flex justify-content-between">
                <div className="d-flex spc-mt-20">
                  <img src={DocIcon} alt="doc" className="d-icon-doc" />
                  <p
                    className="d-upload-modal-file-card-txt gen-cursor-hover-link"
                    onClick={async _ => {
                      const deedDeedData = await getDeedDeed({
                        donationId: donation.id,
                      }).unwrap();
                      openBase64PDFNewWindow(deedDeedData);
                    }}
                  >
                    {donation.deed.deed.fileName}
                  </p>
                </div>
                <div
                  className="d-upload-modal-file-card-remove-btn gen-cursor-hover-link"
                  onClick={_ => deleteDeedDeed({ donationId: donation.id })}
                >
                  REMOVE
                </div>
              </div>
            ) : (
              <React.Fragment>
                <input
                  type="file"
                  accept="application/pdf"
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    if (isLoadingPutDeedDeed || isLoadingDeleteDeedDeed) {
                      return;
                    }

                    const file = e.target.files[0];

                    const data = new FormData();
                    data.append('file', file);
                    data.append('fileName', file.name);

                    putDeedDeed({ donationId: donation.id, data });
                  }}
                  className="hidden"
                  id="conservatorReport"
                />
                <label
                  className="d-modal-upload-btn gen-cursor-hover-link"
                  htmlFor="conservatorReport"
                >
                  SELECT FILE FROM YOUR DEVICE
                </label>
              </React.Fragment>
            )}
          </div>
          <div className="spc-mt-30 d-flex">
            {donation.deed.deed ? (
              <div
                className="d-modal-upload-btn-active gen-cursor-hover-link"
                onClick={_ => {
                  if (
                    isLoadingPutDeedDeed ||
                    isLoadingDeleteDeedDeed ||
                    isLoadingPutDeedSend
                  ) {
                    return;
                  }

                  putDeedSend({ donationId: donation.id });

                  setTimeout(_ => {
                    setIsDeedUploadOpen(false);
                  }, 1200);
                }}
              >
                SUBMIT
              </div>
            ) : (
              <div className="d-modal-upload-btn-inactive gen-cursor-hover-link">
                SUBMIT
              </div>
            )}
            <div
              className="d-modal-upload-btn-cancel gen-cursor-hover-link"
              onClick={_ => {
                if (donation.deed.deed) {
                  deleteDeedDeed({ donationId: donation.id });
                }
                setTimeout(_ => {
                  setIsDeedUploadOpen(false);
                }, 300);
              }}
            >
              CANCEL
            </div>
            {isLoadingPutDeedDeed ||
            isLoadingDeleteDeedDeed ||
            isLoadingPutDeedSend ? (
              <div className="spc-mt-10">
                <p className="d-txt-dk">Updating...</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const makeContent = museum => {
    if (donation.events.confirmedComplete) {
      const isPreliminaryInfoComplete =
        donation.museumInfo.approvalDatesSubmitted &&
        donation.museumInfo.preshippingRequirementsSubmitted &&
        donation.objectInfo.objectInfoSubmitted &&
        donation.donorInfo.shippingInfoSubmitted;

      return (
        <React.Fragment>
          <div className="tw-mb-16 tw-grid tw-gap-16">
            <DonationInformationSection donation={donation} />
          </div>
          {isPreliminaryInfoComplete ? (
            <div className="tw-grid tw-gap-16 tw-pb-24">
              <PreShippingInspectionSection
                donation={donation}
                setIsConservatorUploadOpen={setIsConservatorUploadOpen}
              />
              <LoanAgreementSection
                donation={donation}
                setIsLoanUploadOpen={setIsLoanUploadOpen}
              />
              <ShippingArrangementsSection
                donation={donation}
                setIsShippingUploadOpen={setIsShippingUploadOpen}
              />
              <AppraisalSection donation={donation} />
              <DeedOfGiftSection
                donation={donation}
                setIsDeedUploadOpen={setIsDeedUploadOpen}
              />
              <FormIRSSection donation={donation} />
            </div>
          ) : null}
        </React.Fragment>
      );
    }
  };

  if (object && donation) {
    return (
      <div className="gen-background-nav-museum">
        <div className="d-header mx-auto">
          <div className="d-flex">
            <Link to="/institution/donations/active">
              <img
                src={BackIcon}
                alt="back-arrow"
                className="d-header-icon-back gen-cursor-hover-link"
              />
            </Link>
            <div class-name="d-header-back-container-content">
              <p className="d-header-title">Donation Overview</p>
            </div>
          </div>
          <DonationTopSection
            object={object}
            donation={donation}
            museum={museumAccount}
            isMuseum={true}
            setIsAssignRegistrarModalOpen={setIsAssignRegistrarModalOpen}
          />
        </div>
        {makeContent()}
        {isAssignRegistrarModalOpen && (
          <AssignRegistrarModal
            museum={museumAccount}
            setIsAssignRegistrarModalOpen={setIsAssignRegistrarModalOpen}
            defaultRegistrar={museumRegistrar}
            onSelectRegistrar={async registrar => {
              if (isLoadingPutMuseumRegistrar) {
                return;
              }

              setMuseumRegistrar(registrar);
              try {
                if (registrar) {
                  await putMuseumRegistrar({
                    donationId,
                    data: {
                      userId: registrar.id,
                    },
                  }).unwrap();
                }
              } catch (e) {
                console.log('something went wrong', e);
              }

              setIsAssignRegistrarModalOpen(false);
            }}
          />
        )}
        {isConservatorUploadOpen ? makeConservatorUploadModal() : null}
        {isShippingUploadOpen ? makeShippingUploadModal() : null}
        {isDeedUploadOpen ? makeDeedUploadModal() : null}
        {isLoanUploadOpen ? makeLoanUploadModal() : null}
      </div>
    );
  }

  if (mounted) {
    return (
      <div className="gen-background-nav-museum">
        <div className="d-header mx-auto d-flex">
          <Link to="/institution/donations/active">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="d-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="d-header-back-container-content">
            <p className="d-header-title">Donation Overview</p>
          </div>
        </div>
        <p className="text-center d-special-text">
          <em>Please select a donation from the previous page</em>
        </p>
      </div>
    );
  }

  return (
    <div className="gen-background-nav-museum">
      <div className="d-header mx-auto d-flex">
        <Link to="/institution/donations/active">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="d-header-icon-back gen-cursor-hover-link"
          />
        </Link>
        <div class-name="d-header-back-container-content">
          <p className="d-header-title">Donation Overview</p>
        </div>
      </div>
    </div>
  );
}
