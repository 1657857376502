import {
  TEL_RESET_ADD,
  TEL_RESET_CONFIRM,
  TEL_UPDATE_ADD_ERRORS,
  TEL_UPDATE_CODE,
  TEL_UPDATE_CONFIRM_ERRORS,
  TEL_UPDATE_CONSENT,
  TEL_UPDATE_TEL,
} from '../actionTypes';

export const telResetAdd = _ => {
  return {
    type: TEL_RESET_ADD,
  };
};

export const telResetConfirm = _ => {
  return {
    type: TEL_RESET_CONFIRM,
  };
};

export const telUpdateAddErrors = payload => {
  return {
    type: TEL_UPDATE_ADD_ERRORS,
    payload,
  };
};

export const telUpdateCode = payload => {
  return {
    type: TEL_UPDATE_CODE,
    payload,
  };
};

export const telUpdateConfirmErrors = payload => {
  return {
    type: TEL_UPDATE_CONFIRM_ERRORS,
    payload,
  };
};

export const telUpdateTel = payload => {
  return {
    type: TEL_UPDATE_TEL,
    payload,
  };
};

export const telUpdateTelConsent = payload => {
  return {
    type: TEL_UPDATE_CONSENT,
    payload,
  };
};
