export const findCycleInCollection = (cycleId, collection) => {
  let cycle = null;
  if (typeof cycleId === 'string' && Array.isArray(collection)) {
    collection.forEach(c => {
      if (cycleId === c.id) {
        cycle = c;
      }
    });
  }
  return cycle;
};
