import {
  MUSEUM_APPLICATION_RESET,
  MUSEUM_APPLICATION_UPDATE_CONSENT,
  MUSEUM_APPLICATION_UPDATE_ERRORS,
  MUSEUM_APPLICATION_UPDATE_MUSEUM,
  MUSEUM_APPLICATION_UPDATE_ROLE,
} from '../../actions/actionTypes';

const initialMuseumApplicationState = {
  formData: {
    consent: false,
    museum: null,
    role: '',
  },
  formErrors: {
    formError: false,
    consentError: false,
    museumError: false,
    roleError: false,
  },
};

export const museumApplicationReducer = (
  state = initialMuseumApplicationState,
  action,
) => {
  switch (action.type) {
    case MUSEUM_APPLICATION_RESET:
      return {
        ...state,
        formData: {
          consent: false,
          museum: null,
          role: '',
        },
        formErrors: {
          formError: false,
          consentError: false,
          museumError: false,
          roleError: false,
        },
      };

    case MUSEUM_APPLICATION_UPDATE_CONSENT:
      return {
        ...state,
        formData: {
          ...state.formData,
          consent: action.payload.consent,
        },
      };

    case MUSEUM_APPLICATION_UPDATE_ERRORS:
      return {
        ...state,
        formErrors: action.payload.formErrors,
      };

    case MUSEUM_APPLICATION_UPDATE_MUSEUM:
      return {
        ...state,
        formData: {
          ...state.formData,
          museum: action.payload.museum,
        },
      };

    case MUSEUM_APPLICATION_UPDATE_ROLE:
      return {
        ...state,
        formData: {
          ...state.formData,
          role: action.payload.role,
        },
      };

    default:
      return state;
  }
};
