import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { XIcon } from '@heroicons/react/solid';
import { getInitials } from '@lib';
import InfoIcon from '@images/info-icon.png';
import SharePermissionsDropdown from './DonorAccountPermissionsDropdown';
import { partition, sortBy } from 'lodash';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { getRoleDetails } from 'lib/getRoleDetails/getRoleDetails';
import { Tooltip } from 'components/_shared/Tooltip';
import { useCurrentDonorAccountRole } from 'components/_shared/useDonorAccountRole';

const styles = {
  userCard: 'tw-py-7 tw-px-4 tw-bg-white tw-rounded-sm tw-border tw-shadow-md',
};

const defaultProps = {
  selectedAccountUsers: [],
};
export default function DonorAccountEditAccessModal({
  isOpen,
  closeModal,
  accountName,
  onChangeRole,
  onRemoveUser,
  selectedAccountUsers = defaultProps.selectedAccountUsers,
}) {
  const currentDonorAccountRole = useCurrentDonorAccountRole();

  const [[owner], nonOwners] = partition(
    selectedAccountUsers,
    user => user.role === 'owner',
  );

  const sortedNonOwners = sortBy(nonOwners, ['nameFirst']);

  const modalTitle = [
    DONOR_ACCESS_ROLES.owner,
    DONOR_ACCESS_ROLES.editor,
  ].includes(currentDonorAccountRole)
    ? 'Edit Access to'
    : 'Users with Access to';

  function handleRoleChange({ role, user }) {
    const data = {
      userEmail: user.email,
      userId: user.id,
      action: 'update',
      role,
    };

    onChangeRole(data);
  }

  const [userToRemove, setUserToRemove] = useState(null);
  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
          onClose={closeModal}
        >
          <div className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 tw-text-center">
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black/25" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-scale-95"
              enterTo="tw-opacity-100 tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-scale-100"
              leaveTo="tw-opacity-0 tw-scale-95"
            >
              <div className="tw-relative tw-inline-block tw-w-[975px] tw-max-w-full tw-transform tw-rounded-2xl tw-bg-white tw-p-6 tw-py-14 tw-px-20 tw-text-left tw-align-middle tw-transition-all">
                <div className="tw-absolute tw-top-3 tw-left-3 tw-h-20 tw-w-20">
                  <button className="tw-p-4" onClick={closeModal}>
                    <XIcon className="tw-h-5 tw-w-5 tw-text-gray-400" />
                  </button>
                </div>
                <div className="tw-grid tw-gap-8">
                  <Dialog.Title
                    as="h2"
                    className="tw-mb-0 tw-text-sm tw-font-normal"
                  >
                    {modalTitle}{' '}
                    <span className="tw-font-bold">{accountName}</span>
                  </Dialog.Title>

                  <div
                    className={`tw-grid tw-gap-3 tw-rounded-sm  tw-border tw-border-gray-200 tw-bg-gray-100 tw-py-2 tw-px-3 ${
                      sortedNonOwners.length > 4
                        ? 'tw-max-h-[600px] tw-overflow-x-hidden tw-overflow-y-scroll'
                        : ''
                    }`}
                  >
                    <div className={styles.userCard}>
                      <div className="tw-flex tw-items-center tw-justify-between">
                        <UserInfo user={owner} />
                        <div>
                          <ViewerPermissionsInfo
                            role={DONOR_ACCESS_ROLES.owner}
                          />
                        </div>
                      </div>
                    </div>
                    {sortedNonOwners.map(user => {
                      return (
                        <div
                          key={`edit-access-modal-users-list-${user.id}`}
                          className={styles.userCard}
                        >
                          <div className="tw-flex tw-justify-between">
                            <UserInfo user={user} />
                            {currentDonorAccountRole ===
                              DONOR_ACCESS_ROLES.owner && (
                              <SharePermissionsDropdown
                                value={user.role}
                                onChange={role =>
                                  handleRoleChange({ role, user })
                                }
                                canRemoveUser={true}
                                onRemove={role => {
                                  setUserToRemove(user);
                                  setIsRemoveUserModalOpen(true);
                                }}
                              />
                            )}
                            {currentDonorAccountRole ===
                              DONOR_ACCESS_ROLES.editor && (
                              <SharePermissionsDropdown
                                value={user.role}
                                onChange={role =>
                                  handleRoleChange({ role, user })
                                }
                                canRemoveUser={false}
                              />
                            )}
                            {currentDonorAccountRole ===
                              DONOR_ACCESS_ROLES.viewer && (
                              <ViewerPermissionsInfo role={user.role} />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
          <DonorAccountConfirmRemovalModal
            // Note: This Dialog/Modal needs to be rendered within the parent Dialog to properly handle click outside events.
            isOpen={isRemoveUserModalOpen}
            userToRemove={userToRemove}
            closeModal={() => {
              setIsRemoveUserModalOpen(false);

              // Delay for animation purposes
              setTimeout(() => setUserToRemove(null), 300);
            }}
            accountName={accountName}
            onConfirmRemoveUser={() => {
              if (!userToRemove) return;

              const data = {
                userId: userToRemove.id,
                action: 'remove',
              };

              onRemoveUser(data);

              setIsRemoveUserModalOpen(false);

              // Delay for animation purposes
              setTimeout(() => setUserToRemove(null), 300);
            }}
          />
        </Dialog>
      </Transition>
    </div>
  );
}

DonorAccountEditAccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  accountName: PropTypes.string.isRequired,
  selectedAccountUsers: PropTypes.array,
};

function ViewerPermissionsInfo({ role }) {
  const roleDetails = getRoleDetails({ role });

  return (
    <div className="tw-flex tw-min-w-[160px] tw-gap-4">
      <p className="tw-text-sm tw-text-gray-400">{roleDetails.displayName}</p>
      <Tooltip content={<div>{roleDetails.info}</div>}>
        <img src={InfoIcon} alt="details" className="tw-h-5 tw-w-5" />
      </Tooltip>
    </div>
  );
}

ViewerPermissionsInfo.propTypes = {
  role: PropTypes.string.isRequired,
};

function UserInfo({ user }) {
  return (
    <div className="tw-flex tw-content-start tw-gap-3">
      <div
        className={`tw-relative tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-gray-200 tw-bg-gray-100`}
      >
        <span>{getInitials(`${user.nameFirst} ${user.nameLast}`)}</span>
      </div>
      <div>
        <div className="tw-text-gray-600">
          {user.nameFirst} {user.nameLast}
        </div>
        <div className="tw-text-sm tw-text-gray-400">{user.email}</div>
      </div>
    </div>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

function DonorAccountConfirmRemovalModal({
  isOpen,
  closeModal,
  onConfirmRemoveUser,
  userToRemove,
  accountName,
}) {
  const userFullName = `${userToRemove?.nameFirst} ${userToRemove?.nameLast}`;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
        onClose={closeModal}
      >
        <div className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 tw-text-center">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black/25" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <div className="tw-inline-block tw-max-h-[600px] tw-w-[833px] tw-max-w-full tw-transform tw-rounded-lg tw-bg-gray-100 tw-p-6 tw-py-24 tw-px-40 tw-text-left tw-align-middle tw-transition-all">
              <div className="tw-grid tw-gap-8">
                <div className="tw-grid tw-gap-10 tw-text-center">
                  <Dialog.Title
                    as="h2"
                    className="tw-mb-0 tw-text-3xl tw-font-normal"
                  >
                    Are you sure you would like to remove {userFullName}?
                  </Dialog.Title>
                  <Dialog.Description className="tw-text-lg">
                    If you remove{' '}
                    <span className="tw-font-bold">{userFullName}</span>, they
                    will no longer have access to objects in the shared folder{' '}
                    <span className="tw-font-bold">{accountName}</span>.
                  </Dialog.Description>
                  <div className="tw-flex tw-items-center tw-justify-center tw-gap-16">
                    <button
                      className="rounded-lg tw-min-w-[200px] tw-bg-gray-300 tw-py-3 tw-text-gray-800"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="rounded-lg tw-min-w-[200px] tw-bg-[#424b5a] tw-py-3 tw-text-white"
                      onClick={() => {
                        onConfirmRemoveUser();
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

DonorAccountConfirmRemovalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onConfirmRemoveUser: PropTypes.func.isRequired,
  userToRemove: PropTypes.object,
  accountName: PropTypes.string.isRequired,
};
