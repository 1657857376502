export const DDONM_RESET_ALL = 'DDONM_RESET_ALL';
export const DDONM_SET_FORM_ONE = 'DDONM_SET_FORM_ONE';
export const DDONM_RESET_FORM_ONE = 'DDONM_RESET_FORM_ONE';
export const DDONM_RESET_FORM_ONE_ERRORS = 'DDONM_RESET_FORM_ONE_ERRORS';
export const DDONM_UPDATE_DONOR_NAME_FIRST = 'DDONM_UPDATE_DONOR_NAME_FIRST';
export const DDONM_UPDATE_DONOR_NAME_LAST = 'DDONM_UPDATE_DONOR_NAME_LAST';
export const DDONM_UPDATE_REPRESENTATIVE = 'DDONM_UPDATE_REPRESENTATIVE';
export const DDONM_UPDATE_REPRESENTATIVE_NAME_FIRST =
  'DDONM_UPDATE_REPRESENTATIVE_NAME_FIRST';
export const DDONM_UPDATE_REPRESENTATIVE_NAME_LAST =
  'DDONM_UPDATE_REPRESENTATIVE_NAME_LAST';
export const DDONM_UPDATE_DADDRESS_LINE_ONE = 'DDONM_UPDATE_DADDRESS_LINE_ONE';
export const DDONM_UPDATE_DADDRESS_LINE_TWO = 'DDONM_UPDATE_DADDRESS_LINE_TWO';
export const DDONM_UPDATE_DADDRESS_CITY = 'DDONM_UPDATE_DADDRESS_CITY';
export const DDONM_UPDATE_DADDRESS_STATE = 'DDONM_UPDATE_DADDRESS_STATE';
export const DDONM_UPDATE_DADDRESS_ZIP = 'DDONM_UPDATE_DADDRESS_ZIP';
export const DDONM_UPDATE_DADDRESS_COUNTRY = 'DDONM_UPDATE_DADDRESS_COUNTRY';
export const DDONM_UPDATE_CREDIT_LINE = 'DDONM_UPDATE_CREDIT_LINE';
export const DDONM_UPDATE_FORM_ONE_ERRORS = 'DDONM_UPDATE_FORM_ONE_ERRORS';
export const DDONM_SET_FORM_TWO = 'DDONM_SET_FORM_TWO';
export const DDONM_RESET_FORM_TWO = 'DDONM_RESET_FORM_TWO';
export const DDONM_RESET_FORM_TWO_ERRORS = 'DDONM_RESET_FORM_TWO_ERRORS';
export const DDONM_UPDATE_CONTACT_NAME_FIRST =
  'DDONM_UPDATE_CONTACT_NAME_FIRST';
export const DDONM_UPDATE_CONTACT_NAME_LAST = 'DDONM_UPDATE_CONTACT_NAME_LAST';
export const DDONM_UPDATE_CONTACT_TEL = 'DDONM_UPDATE_CONTACT_TEL';
export const DDONM_UPDATE_CONTACT_EMAIL = 'DDONM_UPDATE_CONTACT_EMAIL';
export const DDONM_UPDATE_SADDRESS_COMPANY = 'DDONM_UPDATE_SADDRESS_COMPANY';
export const DDONM_UPDATE_SADDRESS_LINE_ONE = 'DDONM_UPDATE_SADDRESS_LINE_ONE';
export const DDONM_UPDATE_SADDRESS_LINE_TWO = 'DDONM_UPDATE_SADDRESS_LINE_TWO';
export const DDONM_UPDATE_SADDRESS_CITY = 'DDONM_UPDATE_SADDRESS_CITY';
export const DDONM_UPDATE_SADDRESS_STATE = 'DDONM_UPDATE_SADDRESS_STATE';
export const DDONM_UPDATE_SADDRESS_ZIP = 'DDONM_UPDATE_SADDRESS_ZIP';
export const DDONM_UPDATE_SADDRESS_COUNTRY = 'DDONM_UPDATE_SADDRESS_COUNTRY';
export const DDONM_UPDATE_SHIPPING_DESCRIPTION =
  'DDONM_UPDATE_SHIPPING_DESCRIPTION';
export const DDONM_UPDATE_SHIPPING_INSTRUCTIONS =
  'DDONM_UPDATE_SHIPPING_INSTRUCTIONS';
export const DDONM_UPDATE_FORM_TWO_ERRORS = 'DDONM_UPDATE_FORM_TWO_ERRORS';
export const DDONM_SET_FORM_THREE = 'DDONM_SET_FORM_THREE';
export const DDONM_RESET_FORM_THREE = 'DDONM_RESET_FORM_THREE';
export const DDONM_RESET_FORM_THREE_ERRORS = 'DDONM_RESET_FORM_THREE_ERRORS';
export const DDONM_UPDATE_INSURANCE_VALUE = 'DDONM_UPDATE_INSURANCE_VALUE';
export const DDONM_UPDATE_WEIGHT = 'DDONM_UPDATE_WEIGHT';
export const DDONM_UPDATE_WEIGHT_WEIGHT = 'DDONM_UPDATE_WEIGHT_WEIGHT';
export const DDONM_UPDATE_WEIGHT_SYSTEM = 'DDONM_UPDATE_WEIGHT_SYSTEM';
export const DDONM_UPDATE_FRAME = 'DDONM_UPDATE_FRAME';
export const DDONM_UPDATE_FRAME_HEIGHT = 'DDONM_UPDATE_FRAME_HEIGHT';
export const DDONM_UPDATE_FRAME_WIDTH = 'DDONM_UPDATE_FRAME_WIDTH';
export const DDONM_UPDATE_FRAME_DEPTH = 'DDONM_UPDATE_FRAME_DEPTH';
export const DDONM_UPDATE_FRAME_SYSTEM = 'DDONM_UPDATE_FRAME_SYSTEM';
export const DDONM_UPDATE_CRATE = 'DDONM_UPDATE_CRATE';
export const DDONM_UPDATE_CRATE_HEIGHT = 'DDONM_UPDATE_CRATE_HEIGHT';
export const DDONM_UPDATE_CRATE_WIDTH = 'DDONM_UPDATE_CRATE_WIDTH';
export const DDONM_UPDATE_CRATE_DEPTH = 'DDONM_UPDATE_CRATE_DEPTH';
export const DDONM_UPDATE_CRATE_SYSTEM = 'DDONM_UPDATE_CRATE_SYSTEM';
export const DDONM_UPDATE_CAN_PACK = 'DDONM_UPDATE_CAN_PACK';
export const DDONM_UPDATE_CONDITION = 'DDONM_UPDATE_CONDITION';
export const DDONM_UPDATE_FORM_THREE_ERRORS = 'DDONM_UPDATE_FORM_THREE_ERRORS';
export const DDONM_SET_FINISH_MODAL = 'DDONM_SET_FINISH_MODAL';
