import {
  USER_CLEAR_TEL,
  USER_CLEAR_USER,
  USER_GET_USER_CLEAR,
  USER_GET_USER_FAILURE,
  USER_GET_USER_REQUEST,
  USER_GET_USER_SUCCESS,
  USER_POST_DONOR_APPLICATION_CLEAR,
  USER_POST_DONOR_APPLICATION_FAILURE,
  USER_POST_DONOR_APPLICATION_REQUEST,
  USER_POST_DONOR_APPLICATION_SUCCESS,
  USER_POST_MUSEUM_APPLICATION_CLEAR,
  USER_POST_MUSEUM_APPLICATION_FAILURE,
  USER_POST_MUSEUM_APPLICATION_REQUEST,
  USER_POST_MUSEUM_APPLICATION_SUCCESS,
  USER_POST_TEL_CLEAR,
  USER_POST_TEL_FAILURE,
  USER_POST_TEL_REQUEST,
  USER_POST_TEL_SUCCESS,
  USER_POST_TEL_RESEND_CLEAR,
  USER_POST_TEL_RESEND_FAILURE,
  USER_POST_TEL_RESEND_REQUEST,
  USER_POST_TEL_RESEND_SUCCESS,
  USER_POST_USER_CLEAR,
  USER_POST_USER_FAILURE,
  USER_POST_USER_REQUEST,
  USER_POST_USER_SUCCESS,
  USER_PUT_EMAIL_CLEAR,
  USER_PUT_EMAIL_FAILURE,
  USER_PUT_EMAIL_REQUEST,
  USER_PUT_EMAIL_SUCCESS,
  USER_PUT_NAME_CLEAR,
  USER_PUT_NAME_FAILURE,
  USER_PUT_NAME_REQUEST,
  USER_PUT_NAME_SUCCESS,
  USER_PUT_TEL_CLEAR,
  USER_PUT_TEL_FAILURE,
  USER_PUT_TEL_REQUEST,
  USER_PUT_TEL_SUCCESS,
  USER_UPDATE_USER,
  USER_RESET_NAME_FORM,
  USER_UPDATE_NAME_ERRORS,
  USER_UPDATE_NAME_FIRST,
  USER_UPDATE_NAME_LAST,
  USER_UPDATE_NEW_EMAIL,
  USER_UPDATE_NEW_EMAIL_ERROR,
} from '../actionTypes';

export const clearUser = _ => {
  return {
    type: USER_CLEAR_USER,
  };
};

export const getUserClear = _ => {
  return {
    type: USER_GET_USER_CLEAR,
  };
};

export const getUserFailure = payload => {
  return {
    type: USER_GET_USER_FAILURE,
    payload,
  };
};

export const getUserRequest = _ => {
  return {
    type: USER_GET_USER_REQUEST,
  };
};

export const getUserSuccess = payload => {
  return {
    type: USER_GET_USER_SUCCESS,
    payload,
  };
};

export const postDonorApplicationClear = _ => {
  return {
    type: USER_POST_DONOR_APPLICATION_CLEAR,
  };
};

export const postDonorApplicationFailure = payload => {
  return {
    type: USER_POST_DONOR_APPLICATION_FAILURE,
    payload,
  };
};

export const postDonorApplicationRequest = _ => {
  return {
    type: USER_POST_DONOR_APPLICATION_REQUEST,
  };
};

export const postDonorApplicationSuccess = payload => {
  return {
    type: USER_POST_DONOR_APPLICATION_SUCCESS,
    payload,
  };
};

export const postMuseumApplicationClear = _ => {
  return {
    type: USER_POST_MUSEUM_APPLICATION_CLEAR,
  };
};

export const postMuseumApplicationFailure = payload => {
  return {
    type: USER_POST_MUSEUM_APPLICATION_FAILURE,
    payload,
  };
};

export const postMuseumApplicationRequest = _ => {
  return {
    type: USER_POST_MUSEUM_APPLICATION_REQUEST,
  };
};

export const postMuseumApplicationSuccess = payload => {
  return {
    type: USER_POST_MUSEUM_APPLICATION_SUCCESS,
    payload,
  };
};

export const postTelClear = _ => {
  return {
    type: USER_POST_TEL_CLEAR,
  };
};

export const postTelFailure = payload => {
  return {
    type: USER_POST_TEL_FAILURE,
    payload,
  };
};

export const postTelRequest = _ => {
  return {
    type: USER_POST_TEL_REQUEST,
  };
};

export const postTelSuccess = payload => {
  return {
    type: USER_POST_TEL_SUCCESS,
    payload,
  };
};

export const postTelResendClear = _ => {
  return {
    type: USER_POST_TEL_RESEND_CLEAR,
  };
};

export const postTelResendFailure = payload => {
  return {
    type: USER_POST_TEL_RESEND_FAILURE,
    payload,
  };
};

export const postTelResendRequest = _ => {
  return {
    type: USER_POST_TEL_RESEND_REQUEST,
  };
};

export const postTelResendSuccess = payload => {
  return {
    type: USER_POST_TEL_RESEND_SUCCESS,
    payload,
  };
};

export const postUserClear = _ => {
  return {
    type: USER_POST_USER_CLEAR,
  };
};

export const postUserFailure = payload => {
  return {
    type: USER_POST_USER_FAILURE,
    payload,
  };
};

export const postUserRequest = _ => {
  return {
    type: USER_POST_USER_REQUEST,
  };
};

export const postUserSuccess = payload => {
  return {
    type: USER_POST_USER_SUCCESS,
    payload,
  };
};

export const putEmailClear = _ => {
  return {
    type: USER_PUT_EMAIL_CLEAR,
  };
};

export const putEmailFailure = payload => {
  return {
    type: USER_PUT_EMAIL_FAILURE,
    payload,
  };
};

export const putEmailRequest = _ => {
  return {
    type: USER_PUT_EMAIL_REQUEST,
  };
};

export const putEmailSuccess = payload => {
  return {
    type: USER_PUT_EMAIL_SUCCESS,
    payload,
  };
};

export const putNameClear = _ => {
  return {
    type: USER_PUT_NAME_CLEAR,
  };
};

export const putNameFailure = payload => {
  return {
    type: USER_PUT_NAME_FAILURE,
    payload,
  };
};

export const putNameRequest = _ => {
  return {
    type: USER_PUT_NAME_REQUEST,
  };
};

export const putNameSuccess = payload => {
  return {
    type: USER_PUT_NAME_SUCCESS,
    payload,
  };
};

export const putTelClear = _ => {
  return {
    type: USER_PUT_TEL_CLEAR,
  };
};

export const putTelFailure = payload => {
  return {
    type: USER_PUT_TEL_FAILURE,
    payload,
  };
};

export const putTelRequest = _ => {
  return {
    type: USER_PUT_TEL_REQUEST,
  };
};

export const putTelSuccess = payload => {
  return {
    type: USER_PUT_TEL_SUCCESS,
    payload,
  };
};

export const userClearTel = _ => {
  return {
    type: USER_CLEAR_TEL,
  };
};

export const userUpdateUser = payload => {
  return {
    type: USER_UPDATE_USER,
    payload,
  };
};

export const userResetNameForm = _ => {
  return {
    type: USER_RESET_NAME_FORM,
  };
};

export const userUpdateNameErrors = payload => {
  return {
    type: USER_UPDATE_NAME_ERRORS,
    payload,
  };
};

export const userUpdateNameFirst = payload => {
  return {
    type: USER_UPDATE_NAME_FIRST,
    payload,
  };
};

export const userUpdateNameLast = payload => {
  return {
    type: USER_UPDATE_NAME_LAST,
    payload,
  };
};

export const userUpdateNewEmail = payload => {
  return {
    type: USER_UPDATE_NEW_EMAIL,
    payload,
  };
};

export const userUpdateNewEmailError = payload => {
  return {
    type: USER_UPDATE_NEW_EMAIL_ERROR,
    payload,
  };
};
