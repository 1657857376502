import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import sha256 from 'crypto-js/sha256';
import {
  authCCPutChangeClear,
  authCCResetForm,
  authCCUpdateErrors,
  authCCUpdatePasswordOne,
  authCCUpdatePasswordTwo,
} from '../../actions';
import { authCCPutChange } from '../../requests';
import { validatePassword } from '../../lib';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/text.css';

class MuseumUserPasswordConfirm extends Component {
  static get propTypes() {
    return {
      formErrors: PropTypes.object,
      passwordOne: PropTypes.string,
      passwordTwo: PropTypes.string,
      putChange: PropTypes.func,
      putChangeClear: PropTypes.func,
      putChangeRequest: PropTypes.object,
      resetForm: PropTypes.func,
      token: PropTypes.object,
      updateErrors: PropTypes.func,
      updatePasswordOne: PropTypes.func,
      updatePasswordTwo: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.putChangeClear();
    this.props.resetForm();
  }

  handleSaveClick = _ => {
    if (this.props.putChangeRequest.loading) {
      return;
    }

    const ccFormErrors = {
      formError: false,
      passwordOneError: false,
      passwordTwoError: false,
    };

    if (!this.props.passwordOne || !validatePassword(this.props.passwordOne)) {
      ccFormErrors.formError = true;
      ccFormErrors.passwordOneError = true;
    }

    if (!this.props.passwordTwo || !validatePassword(this.props.passwordTwo)) {
      ccFormErrors.formError = true;
      ccFormErrors.passwordTwoError = true;
    }

    if (this.props.passwordOne !== this.props.passwordTwo) {
      ccFormErrors.formError = true;
      ccFormErrors.passwordOneError = true;
      ccFormErrors.passwordTwoError = true;
    }

    this.props.updateErrors({ ccFormErrors });

    if (ccFormErrors.formError) {
      return;
    }

    this.props.putChange(
      this.props.token,
      sha256(this.props.passwordOne).toString(),
    );
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.go(-1)}
          />
          <p className="set-back-txt">Change Password</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-label-txt">NEW PASSWORD</p>
            <input
              type="password"
              className={`set-info-edit-field ${
                this.props.formErrors.passwordOneError
                  ? 'fd-input-error-field'
                  : ''
              }`}
              maxLength="32"
              value={this.props.passwordOne}
              onChange={e =>
                this.props.updatePasswordOne({ passwordOne: e.target.value })
              }
            />
            <div className="set-pw-note">
              <p className="set-txt-copy set-pw-note-line-ht">
                <strong>Password strength:</strong> Your password must be 8-32
                characters long and contain one upper case letter, one lower
                case letter, a number, and a symbol.
              </p>
            </div>
            <p className="set-label-txt">CONFIRM NEW PASSWORD</p>
            <input
              type="password"
              className={`set-info-edit-field ${
                this.props.formErrors.passwordTwoError
                  ? 'fd-input-error-field'
                  : ''
              }`}
              maxLength="32"
              value={this.props.passwordTwo}
              onChange={e =>
                this.props.updatePasswordTwo({ passwordTwo: e.target.value })
              }
            />
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              {/* {false ? (
                <p className="set-special-err-1">Please try again</p>
              ) : null} */}
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.go(-1)}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                CHANGE PASSWORD
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putChangeRequest.data) {
      return <Redirect to="/institution/settings/user" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formErrors: state.authState.ccFormErrors,
    passwordOne: state.authState.ccFormData.passwordOne,
    passwordTwo: state.authState.ccFormData.passwordTwo,
    putChangeRequest: state.authState.ccPutChangeRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putChange: (token, passwordHash) =>
      dispatch(authCCPutChange(token, passwordHash)),
    putChangeClear: _ => dispatch(authCCPutChangeClear()),
    resetForm: _ => dispatch(authCCResetForm()),
    updateErrors: payload => dispatch(authCCUpdateErrors(payload)),
    updatePasswordOne: payload => dispatch(authCCUpdatePasswordOne(payload)),
    updatePasswordTwo: payload => dispatch(authCCUpdatePasswordTwo(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MuseumUserPasswordConfirm);
