import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/general.css';
import '../../styles/museum-donation-match.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import {
  usePutDonationShippingInfoConfirmMutation,
  usePutDonationShippingInfoDeclineMutation,
} from 'requests/api';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

const defaultValues = {
  schedulingNotes: '',
  shippingContact: { nameFirst: '', nameLast: '', tel: '', email: '' },
};

export default function DonorDonationsScheduleShippingApprove() {
  const { donationId, accountId } = useParams();
  const { goBack } = useHistory();
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const { data: donation } = useDonorGetDonation({ donationId, accountId });

  const [
    putDonorDonationShippingInfoConfirm,
    {
      isLoading: isLoadingPutDonorDonationShippingInfoConfirm,
      isSuccess: isSuccessPutDonorDonationShippingInfoConfirm,
    },
  ] = usePutDonationShippingInfoConfirmMutation();

  const { register, handleSubmit, setValue, formState } = useForm({
    defaultValues,
  });

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (isInitialized) return;
    if (!donation) return;
    if (donation.donorInfo.shippingContact) {
      setValue(
        'shippingContact.nameFirst',
        donation.donorInfo.shippingContact.nameFirst,
      );
      setValue(
        'shippingContact.nameLast',
        donation.donorInfo.shippingContact.nameLast,
      );
      setValue('shippingContact.tel', donation.donorInfo.shippingContact.tel);
      setValue(
        'shippingContact.email',
        donation.donorInfo.shippingContact.email,
      );
    }
    setIsInitialized(true);

    setIsInitialized(true);
  }, [donation, isInitialized]);

  async function onSubmit(values) {
    if (isSubmitting) return;
    if (isLoadingPutDonorDonationShippingInfoConfirm) return;

    try {
      await putDonorDonationShippingInfoConfirm({
        donationId: donation.id,
        data: values,
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  const { errors, isSubmitting } = formState;

  if (isSuccessPutDonorDonationShippingInfoConfirm) {
    return (
      <Redirect
        push
        to={`/donor/folders/${accountId}/donation/${donation.id}`}
      />
    );
  }

  if (!donation) return null;

  return (
    <div className="gen-background-nav-museum">
      <div className="d-header mx-auto">
        <div className="d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="d-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div className="tw-grid tw-gap-3">
            <p className="tw-text-3xl tw-font-light tw-text-[#2e384d]">
              Approve Pick Up
            </p>
            <div className="tw-grid tw-max-w-[540px] tw-gap-4 tw-text-sm tw-text-[#8798ad]">
              <div>
                Please approve the date/date range of scheduled pick up, or
                decline the date and restart the scheduling process. Contact a{' '}
                <a
                  href={`mailto:donor_liaison@museumexchange.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tw-text-blue-500 hover:tw-underline"
                >
                  Donor Liaison
                </a>{' '}
                if you have any questions or concerns.
              </div>
              <div>
                <b>Note:</b> In advance of the scheduled pick up, the shipping
                company will contact the Pick Up Contact to provide a specific
                pick up date and/or time.
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-mx-auto tw-w-[892px]"
      >
        <div className="tw-mb-20 tw-grid tw-gap-10 tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#e7ebec] tw-p-7">
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-6 tw-p-4">
              <div className="tw-grid tw-gap-10">
                <p className="tw-text-xl tw-font-light tw-text-[#2e384d]">
                  Proposed Pick Up Schedule
                </p>
                <div className="tw-flex tw-gap-24">
                  <div className="tw-grid tw-gap-3">
                    <div className="tw-mb-0 tw-font-bold">Date/Date Range</div>
                    <div>
                      <div className="tw-text-sm">Start Date</div>
                      <div>{donation.shipping.startDate}</div>
                    </div>
                    <div>
                      <div className="tw-text-sm">End Date</div>
                      <div>{donation.shipping.endDate || '--'}</div>
                    </div>
                  </div>
                  <div className="tw-grid tw-gap-3">
                    <div className="tw-mb-0 tw-font-bold">
                      Art Shipping Company
                    </div>
                    <div>
                      <div className="tw-text-sm">Company Name</div>
                      <div>{donation.shipping.shippingCompany}</div>
                    </div>
                    <div>
                      <div className="tw-text-sm">Subcontractor</div>
                      <div>
                        {donation.shipping.shippingSubcontractor || '--'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-6 tw-p-4">
              <div className="tw-grid">
                <div className="tw-mb-0 tw-font-bold">Scheduling Notes</div>
                <div className="tw-max-w-[450px] tw-text-sm tw-uppercase">
                  Optional
                </div>
              </div>
              <textarea
                className={`fd-input-textarea tw-h-24 tw-w-full tw-pt-4`}
                maxLength="500"
                placeholder="Notes"
              />
            </div>
          </div>
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-6 tw-p-4">
              <div className="tw-mb-0 tw-font-bold">Pick Up Contact</div>

              <div className="tw-grid tw-gap-6">
                <div className="tw-flex tw-gap-6">
                  <div className="tw-w-full">
                    <div
                      className={`${
                        errors?.shippingCompany ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      First Name
                    </div>
                    <input
                      type="text"
                      className={`fd-input ${
                        errors?.shippingCompany ? 'fd-input-error-field' : ''
                      }`}
                      placeholder="First Name"
                      {...register('shippingContact.nameFirst', {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="tw-w-full">
                    <div
                      className={`${
                        errors?.shippingCompany ? 'fd-input-error-txt' : ''
                      }`}
                    >
                      Last Name
                    </div>
                    <input
                      type="text"
                      className={`fd-input ${
                        errors?.shippingCompany ? 'fd-input-error-field' : ''
                      }`}
                      placeholder="Last Name"
                      {...register('shippingContact.nameLast', {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className={`${
                      errors?.shippingSubcontractor ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Phone Number
                  </div>
                  <input
                    className={`fd-input ${
                      errors?.shippingSubcontractor
                        ? 'fd-input-error-field'
                        : ''
                    }`}
                    placeholder="(555)555-5555"
                    type="text"
                    {...register('shippingContact.tel')}
                  />
                </div>
                <div>
                  <div
                    className={`${
                      errors?.shippingSubcontractor ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Email Address
                  </div>
                  <input
                    className={`fd-input ${
                      errors?.shippingSubcontractor
                        ? 'fd-input-error-field'
                        : ''
                    }`}
                    placeholder="Email Address"
                    type="email"
                    {...register('shippingContact.email')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-justify-center tw-gap-10">
            <button
              className="tw-min-w-[260px] tw-rounded tw-border tw-border-blue-500 tw-bg-transparent tw-px-6 tw-py-4 tw-uppercase tw-text-blue-500 disabled:tw-bg-[#bfc5d2]"
              disabled={isSubmitting}
              onClick={() => setIsDeclineModalOpen(true)}
              type="button"
            >
              <div>Decline Pick Up</div>
              <div className="tw-text-sm">Restart Scheduling Process</div>
            </button>
            <button
              className="tw-min-w-[260px] tw-rounded tw-bg-blue-500 tw-px-6 tw-py-4 tw-uppercase tw-text-white disabled:tw-bg-[#bfc5d2]"
              disabled={isSubmitting}
              type="submit"
            >
              Approve Pick Up
            </button>
          </div>
        </div>
      </form>

      <SimpleModal
        isOpen={isDeclineModalOpen}
        closeModal={() => setIsDeclineModalOpen(false)}
        title="What is the reason you are declining the proposed pick up schedule?"
        description={
          <DeclineModalContent
            donation={donation}
            closeModal={() => setIsDeclineModalOpen(false)}
          />
        }
        hasCloseIcon={true}
      />
    </div>
  );
}

function SimpleModal({
  isOpen,
  closeModal,
  title,
  description,
  hasCloseIcon = true,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
        onClose={closeModal}
      >
        <div className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 tw-text-center">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black/25" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <div className="tw-inline-block tw-w-[530px] tw-transform tw-rounded-lg tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-transition-all">
              <div className="tw-grid tw-gap-8">
                <div className="tw-grid tw-gap-4">
                  {hasCloseIcon ? (
                    <div className="tw-flex tw-justify-end">
                      <button onClick={closeModal} className="tw--m-3 tw-p-3">
                        <XIcon className="tw-h-4 tw-w-4" />
                      </button>
                    </div>
                  ) : null}
                  <Dialog.Title as="h2" className="tw-mb-0 tw-text-xl">
                    {title}
                  </Dialog.Title>
                  <Dialog.Description as="div" className="tw-text-gray-500">
                    {description}
                  </Dialog.Description>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function DeclineModalContent({ closeModal, donation }) {
  const { accountId } = useParams();
  const { register, handleSubmit, setValue, formState, watch } = useForm({
    defaultValues: {
      reschedulingNotes: '',
    },
  });

  const { isSubmitting, errors } = formState;

  const [
    putDonorDonationShippingInfoDecline,
    {
      isLoading: isLoadingPutDonorDonationShippingInfoDecline,
      isSuccess: isSuccessPutDonorDonationShippingInfoDecline,
    },
  ] = usePutDonationShippingInfoDeclineMutation();

  async function onSubmit(values) {
    if (isSubmitting) return;
    if (isLoadingPutDonorDonationShippingInfoDecline) return;

    try {
      await putDonorDonationShippingInfoDecline({
        donationId: donation.id,
        data: values,
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  if (isSuccessPutDonorDonationShippingInfoDecline) {
    return (
      <Redirect
        push
        to={`/donor/folders/${accountId}/donation/${donation.id}`}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="tw-grid tw-gap-4">
      <div>Note: This will restart the scheduling process</div>
      <div className="tw-grid">
        <div
          className={`tw-mb-0 tw-font-bold ${
            errors.reschedulingNotes ? 'fd-input-error-txt' : ''
          }`}
        >
          Rescheduling Notes (Required)
        </div>
      </div>
      <textarea
        className={`fd-input-textarea tw-h-24 tw-w-full tw-pt-4 ${
          errors.reschedulingNotes ? 'fd-input-error-field' : ''
        }`}
        maxLength="500"
        {...register('reschedulingNotes', { required: true })}
        placeholder="Describe your reason for reqesting new scheduling options"
      />
      <div className="tw-flex tw-justify-between tw-gap-10">
        <button
          className="tw-min-w-[200px] tw-rounded tw-border tw-bg-blue-200 tw-px-6 tw-py-4 tw-uppercase tw-text-gray-800 disabled:tw-bg-[#bfc5d2]"
          disabled={isSubmitting}
          onClick={closeModal}
          type="button"
        >
          Cancel
        </button>
        <button
          className="tw-min-w-[200px] tw-rounded tw-bg-[#bfc5d2] tw-px-6 tw-py-4 tw-uppercase tw-text-white disabled:tw-bg-[#bfc5d2]"
          disabled={isSubmitting}
          type="submit"
        >
          Finish & Reschedule
        </button>
      </div>
    </form>
  );
}
