import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ART_PROFESSIONS } from '../../config';
import {
  dsetPutDonorTypeClear,
  dsetResetTypeForm,
  dsetUpdateProfession,
  dsetUpdateType,
  dsetUpdateTypeErrors,
} from '../../actions';
import { putDonorType } from '../../requests';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/spacing.css';
import '../../styles/text.css';

// TODO: update to use hook.
// export default function DonorUserCollection(){

//   useEffect(()=>{
//     // NOTE: this could be handled by react-hook-form defaults
//     if (this.props.user && this.props.user.donor) {
//       this.props.updateType({ donorType: this.props.user.donor.donorType });
//       if (this.props.user.donor.profession) {
//         this.props.updateProfession({
//           profession: this.props.user.donor.profession,
//         });
//       }
//     }
//   },[])

//   const  handleSaveClick = _ => {
//     if (this.props.putDonorTypeRequest.loading) {
//       return;
//     }

//     const donorTypeFormErrors = {
//       formError: false,
//       donorTypeFormError: false,
//       professionFormError: false,
//     };

//     if (!this.props.type) {
//       donorTypeFormErrors.formError = true;
//       donorTypeFormErrors.donorTypeFormError = true;
//     }

//     if (this.props.type === 'representative' && !this.props.profession) {
//       donorTypeFormErrors.formError = true;
//       donorTypeFormErrors.professionFormError = true;
//     }

//     this.props.updateFormErrors({ donorTypeFormErrors });

//     if (donorTypeFormErrors.formError) {
//       return;
//     }

//     this.props.putDonorType(this.props.token, this.props.user.id, {
//       donorType: this.props.type,
//       profession: this.props.profession || null,
//     });
//   };

//   if (this.props.putDonorTypeRequest.data) {
//     return <Redirect to="/donor/settings/user" />;
//   }

//   return (
//     <div className="gen-background-nav-donor">
//       <div className="mcv-content mx-auto mex-mb-30">
//         <div className="mcv-header d-flex">
//           <div className="set-header-initials-container">
//             <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
//           </div>
//           <p className="txt-header">Manage your Account</p>
//         </div>

//         <React.Fragment>
//         <div className="set-back-container d-flex">
//           <img
//             src={BackIcon}
//             alt="back-arrow"
//             className="set-back-icon gen-cursor-hover-link"
//             onClick={e => this.props.history.goBack()}
//           />
//           <p className="set-back-txt">Change Collection Type</p>
//         </div>
//         <div className="set-content-outer">
//           <div className="set-info-container mx-auto">
//             <p className="set-label-txt">COLLECTION TYPE</p>
//             <select
//               className={`btn dropdown-toggle set-info-edit-field set-info-edit-field-select ${
//                 this.props.type ? '' : 'set-info-edit-field-select-no-selection'
//               } ${
//                 this.props.formErrors.donorTypeFormError
//                   ? 'fd-input-error-field'
//                   : ''
//               }`}
//               style={{ width: '100%' }}
//               onChange={e => {
//                 if (e.target.value === 'collector') {
//                   this.props.updateProfession({ profession: '' });
//                 }
//                 this.props.updateType({ donorType: e.target.value });
//               }}
//               value={this.props.type}
//             >
//               <option disabled value="">
//                 Select collection type
//               </option>
//               <option value="representative">
//                 Others' Collections (Representative/Agent)
//               </option>
//               <option value="collector">My Collection</option>
//               ))}
//             </select>
//             {this.props.type === 'representative' ? (
//               <div className="spc-mt-30">
//                 <p className="set-label-txt">CURRENT PROFESSION</p>
//                 <select
//                   className={`btn dropdown-toggle set-info-edit-field set-info-edit-field-select ${
//                     this.props.profession
//                       ? ''
//                       : 'set-info-edit-field-select-no-selection'
//                   } ${
//                     this.props.formErrors.professionFormError
//                       ? 'fd-input-error-field'
//                       : ''
//                   }`}
//                   style={{ width: '100%' }}
//                   onChange={e =>
//                     this.props.updateProfession({ profession: e.target.value })
//                   }
//                   value={this.props.profession}
//                 >
//                   <option disabled value="">
//                     Select your profession
//                   </option>
//                   {ART_PROFESSIONS.map((v, i) => (
//                     <option key={`artProfession_${i}`} value={v}>
//                       {v}
//                     </option>
//                   ))}
//                   ))}
//                 </select>
//               </div>
//             ) : null}
//             <div className="set-info-edit-btns-container d-flex justify-content-end">
//               <p
//                 className="set-info-edit-btn-cancel"
//                 onClick={e => this.props.history.goBack()}
//               >
//                 CANCEL
//               </p>
//               <div
//                 className="set-info-edit-btn-save"
//                 onClick={_ => this.handleSaveClick()}
//               >
//                 SAVE
//               </div>
//             </div>
//           </div>
//         </div>
//       </React.Fragment>
//       </div>
//     </div>
//   );
// }

class DonorUserCollection extends Component {
  static get propTypes() {
    return {
      formErrors: PropTypes.object,
      profession: PropTypes.string,
      type: PropTypes.string,
      token: PropTypes.object,
      putDonorType: PropTypes.func,
      putDonorTypeClear: PropTypes.func,
      putDonorTypeRequest: PropTypes.object,
      resetForm: PropTypes.func,
      updateFormErrors: PropTypes.func,
      updateProfession: PropTypes.func,
      updateType: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.donor) {
      this.props.updateType({ donorType: this.props.user.donor.donorType });
      if (this.props.user.donor.profession) {
        this.props.updateProfession({
          profession: this.props.user.donor.profession,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.putDonorTypeClear();
    this.props.resetForm();
  }

  handleSaveClick = _ => {
    if (this.props.putDonorTypeRequest.loading) {
      return;
    }

    const donorTypeFormErrors = {
      formError: false,
      donorTypeFormError: false,
      professionFormError: false,
    };

    if (!this.props.type) {
      donorTypeFormErrors.formError = true;
      donorTypeFormErrors.donorTypeFormError = true;
    }

    if (this.props.type === 'representative' && !this.props.profession) {
      donorTypeFormErrors.formError = true;
      donorTypeFormErrors.professionFormError = true;
    }

    this.props.updateFormErrors({ donorTypeFormErrors });

    if (donorTypeFormErrors.formError) {
      return;
    }

    this.props.putDonorType(this.props.token, this.props.user.id, {
      donorType: this.props.type,
      profession: this.props.profession || null,
    });
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Collection Type</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-label-txt">COLLECTION TYPE</p>
            <select
              className={`btn dropdown-toggle set-info-edit-field set-info-edit-field-select ${
                this.props.type ? '' : 'set-info-edit-field-select-no-selection'
              } ${
                this.props.formErrors.donorTypeFormError
                  ? 'fd-input-error-field'
                  : ''
              }`}
              style={{ width: '100%' }}
              onChange={e => {
                if (e.target.value === 'collector') {
                  this.props.updateProfession({ profession: '' });
                }
                this.props.updateType({ donorType: e.target.value });
              }}
              value={this.props.type}
            >
              <option disabled value="">
                Select collection type
              </option>
              <option value="representative">
                Others' Collections (Representative/Agent)
              </option>
              <option value="collector">My Collection</option>
              ))}
            </select>
            {this.props.type === 'representative' ? (
              <div className="spc-mt-30">
                <p className="set-label-txt">CURRENT PROFESSION</p>
                <select
                  className={`btn dropdown-toggle set-info-edit-field set-info-edit-field-select ${
                    this.props.profession
                      ? ''
                      : 'set-info-edit-field-select-no-selection'
                  } ${
                    this.props.formErrors.professionFormError
                      ? 'fd-input-error-field'
                      : ''
                  }`}
                  style={{ width: '100%' }}
                  onChange={e =>
                    this.props.updateProfession({ profession: e.target.value })
                  }
                  value={this.props.profession}
                >
                  <option disabled value="">
                    Select your profession
                  </option>
                  {ART_PROFESSIONS.map((v, i) => (
                    <option key={`artProfession_${i}`} value={v}>
                      {v}
                    </option>
                  ))}
                  ))}
                </select>
              </div>
            ) : null}
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putDonorTypeRequest.data) {
      return <Redirect to="/donor/settings/user" />;
    }

    return (
      <div className="gen-background-nav-donor">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formErrors: state.donorSettingsState.donorTypeFormErrors,
    profession: state.donorSettingsState.donorTypeFormData.profession,
    type: state.donorSettingsState.donorTypeFormData.donorType,
    putDonorTypeRequest: state.donorSettingsState.putDonorTypeRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putDonorType: (token, userId, data) =>
      dispatch(putDonorType(token, userId, data)),
    putDonorTypeClear: _ => dispatch(dsetPutDonorTypeClear()),
    resetForm: _ => dispatch(dsetResetTypeForm()),
    updateFormErrors: payload => dispatch(dsetUpdateTypeErrors(payload)),
    updateProfession: payload => dispatch(dsetUpdateProfession(payload)),
    updateType: payload => dispatch(dsetUpdateType(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DonorUserCollection);
