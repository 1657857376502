import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/general.css';
import '../../styles/museum-donation-match.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import {
  useGetMuseumAccountUserQuery,
  usePutDonationShippingInfoMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';
import { useMuseumGetDonation } from 'components/_shared/useMuseumGetDonation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

const defaultValues = {
  startDate: '',
  endDate: '',
  shippingCompany: '',
  shippingSubcontractor: '',
};

export default function MuseumDonationsScheduleShipping() {
  const { donationId } = useParams();
  const { goBack } = useHistory();

  const { data: museumAccount, isError: isErrorMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const { data: donation, isError: isErrorDonation } = useMuseumGetDonation({
    donationId,
    accountId: museumAccount?.id,
  });

  const [
    putDonationShippingInfo,
    {
      isLoading: isLoadingPutDonationShippingInfo,
      isSuccess: isSuccessPutDonationShippingInfo,
    },
  ] = usePutDonationShippingInfoMutation();

  const { register, handleSubmit, setValue, formState, watch } = useForm({
    defaultValues,
  });

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!donation) return;
    if (isInitialized) return;

    if (donation.shipping.shippingCompany) {
      setValue('shippingCompany', donation.shipping.shippingCompany);
    }
    if (donation.shipping.shippingSubcontractor) {
      setValue(
        'shippingSubcontractor',
        donation.shipping.shippingSubcontractor,
      );
    }
  }, [donation, isInitialized]);

  async function onSubmit(values) {
    if (isSubmitting) return;
    if (isLoadingPutDonationShippingInfo) return;

    function parseDateInputString(dateString) {
      return new Date(dateString.replaceAll('-', '/'));
    }

    const startDate = format(
      parseDateInputString(values.startDate),
      'MM/dd/yy',
    );
    const endDate = values.endDate
      ? format(parseDateInputString(values.endDate), 'MM/dd/yy')
      : null;

    try {
      await putDonationShippingInfo({
        donationId: donation.id,
        data: { ...values, startDate, endDate },
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  const { errors, isSubmitting } = formState;

  const startDate = watch('startDate');

  if (isSuccessPutDonationShippingInfo) {
    return <Redirect push to={`/institution/donations/${donationId}`} />;
  }

  return (
    <div className="gen-background-nav-museum">
      <div className="d-header mx-auto">
        <div className="d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="d-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div className="tw-grid tw-gap-3">
            <p className="tw-text-3xl tw-font-light tw-text-[#2e384d]">
              Schedule Pick Up
            </p>
            <div className="tw-grid tw-max-w-[540px] tw-gap-4 tw-text-sm tw-text-[#8798ad]">
              <div className="">
                Please contact the institution’s preferred art shipper(s) to
                schedule the pick up of the object within the general
                availability indicated by the donor. Contact an{' '}
                <a
                  href={`mailto:museum_liaison@museumexchange.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tw-text-blue-500 hover:tw-underline"
                >
                  Institution Liaison
                </a>{' '}
                if you have any questions or concerns.
              </div>
              <div>
                <b>Note:</b> The cost of shipping and wall-to-wall insurance are
                covered by the institution, unless agreed otherwise.
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-mx-auto tw-w-[892px]"
      >
        <div className="tw-mb-20 tw-grid tw-gap-10 tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#e7ebec] tw-p-7">
          {donation?.shipping?.reschedulingNotes ? (
            <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
              <div className="tw-grid tw-gap-3 tw-p-4">
                <div className="fd-input-error-txt tw-mb-0 tw-font-bold">
                  Donor – Rescheduling Notes
                </div>
                <div className="fd-input-error-txt">
                  {donation?.shipping?.reschedulingNotes}
                </div>
              </div>
            </div>
          ) : null}
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-3 tw-p-4">
              <div className="tw-mb-0 tw-font-bold">
                Donor - General Availability
              </div>
              <div className="">{donation?.donorInfo?.shippingDescription}</div>
            </div>
          </div>
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-6 tw-p-4">
              <div className="tw-grid tw-gap-3">
                <div className="tw-mb-0 tw-font-bold">Date/Date Range</div>
                <div className="tw-max-w-[450px] tw-text-sm">
                  Please enter the proposed pick up date (or range of dates) for
                  the donor’s approval. An exact pick up date and/or time can be
                  specified at a later stage.
                </div>
              </div>
              <div className="tw-flex tw-gap-16">
                <div>
                  <div
                    className={`${
                      errors?.startDate ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Start Date
                  </div>
                  <input
                    type="date"
                    className={`tw-rounded tw-border tw-border-[#c2d1d9] tw-bg-[#eef3f5] tw-p-2 tw-px-4 tw-uppercase tw-text-[#8798ad] ${
                      errors?.startDate ? 'fd-input-error-field' : ''
                    }`}
                    {...register('startDate', { required: true })}
                  />
                </div>

                <div>
                  <div
                    className={`${errors?.endDate ? 'fd-input-error-txt' : ''}`}
                  >
                    End Date
                  </div>
                  <input
                    type="date"
                    className={`tw-rounded tw-border tw-border-[#c2d1d9] tw-bg-[#eef3f5] tw-p-2 tw-px-4 tw-uppercase tw-text-[#8798ad] ${
                      errors?.endDate ? 'fd-input-error-field' : ''
                    }`}
                    {...register('endDate', {
                      min: {
                        value: startDate,
                        message: 'End Date cannot be before Start Date',
                      },
                    })}
                  />
                  {errors.endDate ? (
                    <div className="fd-input-error-txt">
                      {errors.endDate?.message}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-6 tw-p-4">
              <div className="tw-grid tw-gap-3">
                <div className="tw-mb-0 tw-font-bold">Art Shipping Company</div>
                <div className="tw-max-w-[450px] tw-text-sm">
                  Please enter the company name of the selected art shipper, if
                  the art shipper has sub-contracted another company for the
                  local pick up, please enter the name of the other company in
                  the optional field.
                </div>
              </div>
              <div className="tw-grid tw-gap-6">
                <div>
                  <div
                    className={`${
                      errors?.shippingCompany ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Company Name
                  </div>
                  <input
                    type="text"
                    className={`fd-input ${
                      errors?.shippingCompany ? 'fd-input-error-field' : ''
                    }`}
                    placeholder="Company Name"
                    {...register('shippingCompany', { required: true })}
                  />
                </div>
                <div>
                  <div
                    className={`${
                      errors?.shippingSubcontractor ? 'fd-input-error-txt' : ''
                    }`}
                  >
                    Subcontractor (optional)
                  </div>
                  <input
                    className={`fd-input ${
                      errors?.shippingSubcontractor
                        ? 'fd-input-error-field'
                        : ''
                    }`}
                    placeholder="Subcontractor Name"
                    type="text"
                    {...register('shippingSubcontractor')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-justify-end">
            <button
              className="tw-min-w-[260px] tw-rounded tw-bg-blue-500 tw-px-6 tw-py-4 tw-uppercase tw-text-white disabled:tw-bg-[#bfc5d2]"
              disabled={isSubmitting}
              type="submit"
            >
              Schedule Pick Up
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
