import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { propSetSelectedImage } from '../../actions';
import { convertToMMDDYYYY, stripProtocol } from '../../lib';
import { dimensionsMapping } from '../../glue';
import MuseumIcon from '../../images/museum-icon-blue.png';
import '../../styles/proposal-review.css';
import '../../styles/spacing.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';

export default function ProposalReview({ obj, proposal, museumAccount }) {
  const dispatch = useDispatch();

  const isMuseum = useSelector(selectIsMuseum);
  const selectedImage = useSelector(
    state => state.proposalsState.selectedImage,
  );

  useEffect(() => {
    return () => {
      dispatch(propSetSelectedImage(null));
    };
  }, []);

  const makeDimensions = _ => {
    let key = '';
    dimensionsMapping.forEach(v => {
      if (!key && obj.dimensions[v.key]) {
        key = v.key;
      }
    });

    let dimensionsString = '';

    if (key === 'dimensionsVariable' || key === 'duration') {
      dimensionsString = (
        <p className="pro-rev-pro-summary-txt-sm-light">
          {obj.dimensions[key]}
        </p>
      );
    } else {
      dimensionsString = (
        <p className="pro-rev-pro-summary-txt-sm-light">
          {`${obj.dimensions[key].heightImperial} x ${
            obj.dimensions[key].widthImperial
          }${
            obj.dimensions[key].depthImperial
              ? ` x ${obj.dimensions[key].depthImperial}`
              : ``
          } in`}
          &nbsp; &nbsp; &nbsp;
          <span className="pro-rev-pro-summary-txt-sm-xlight">
            {`(${obj.dimensions[key].heightMetric} x ${
              obj.dimensions[key].widthMetric
            }${
              obj.dimensions[key].depthMetric
                ? ` x ${obj.dimensions[key].depthMetric}`
                : ``
            } cm)`}
          </span>
        </p>
      );
    }

    return (
      <div className="prop-rev-pro-summary-dimensions-container">
        {dimensionsString}
      </div>
    );
  };

  const makeImages = _ => {
    const makeImageCards = _ => {
      const imgsPlain = [];
      const imgsWithDescription = [];

      proposal.images.forEach(v => {
        if (!v.description) {
          imgsPlain.push(
            <img
              key={`img_${v.imageId}`}
              src={`https://res.cloudinary.com/ex-tech/image/fetch/h_380,w_410,c_fill,q_auto/https://${v.bucket}.s3.amazonaws.com/${v.imageId}`}
              alt={v.fileName}
              className="pro-rev-pro-img"
              onClick={_ => dispatch(propSetSelectedImage(v))}
            />,
          );
        } else {
          imgsWithDescription.push(
            <div key={`img_${v.imageId}`} className="d-flex">
              <img
                src={`https://res.cloudinary.com/ex-tech/image/fetch/h_380,w_410,c_fill,q_auto/https://${v.bucket}.s3.amazonaws.com/${v.imageId}`}
                alt={v.fileName}
                className="pro-rev-pro-img-with-description"
                onClick={_ => dispatch(propSetSelectedImage(v))}
              />
              <div className="pro-rev-pro-img-description">
                <p className="pro-rev-pro-txt">{v.description}</p>
              </div>
            </div>,
          );
        }
      });

      return imgsPlain.concat(imgsWithDescription);
    };

    if (
      proposal &&
      typeof proposal === 'object' &&
      Array.isArray(proposal.images) &&
      proposal.images.length
    ) {
      return (
        <div className="pro-review-pro-content-inner-border">
          <p className="pro-rev-pro-summary-header">Contextual Images</p>
          <div className="pro-rev-pro-imgs-container d-flex flex-wrap">
            {makeImageCards()}
          </div>
        </div>
      );
    }
  };

  const makeConditionsInfo = _ => {
    if (obj.restrictions) {
      return (
        <div className="pro-review-pro-content-inner-border">
          <p className="pro-rev-pro-summary-header">Donor Conditions</p>
          <div className="pro-rev-pro-restrictions-container">
            <p className="pro-rev-pro-restrictions-container-txt">
              {obj.restrictions}
            </p>
          </div>
          <div className="pro-rev-pro-restrictions-content">
            <p className="pro-rev-pro-restrictions-txt-sm">
              The {isMuseum ? 'museum' : 'institution'}’s response to the above
              stated donor conditions is as follows:
            </p>
            <div className="spc-mt-15">
              <p className="pro-rev-pro-restrictions-txt-lg">
                {proposal.conditionsClarification
                  ? 'REQUIRES CLARIFICATION'
                  : 'APPROVE'}
              </p>
            </div>
            {proposal.conditionsClarification ? (
              <div className="pro-rev-pro-restrictions-clarification">
                <p className="pro-rev-pro-restrictions-txt">
                  {proposal.conditionsClarification}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="pro-review-pro-content">
      <div className="pro-review-pro-content-inner">
        <p className="pro-rev-pro-summary-header">Object Summary</p>
        <div className="d-flex spc-mt-10">
          <img
            src={`https://res.cloudinary.com/ex-tech/image/fetch/h_162,w_122,c_fill,q_auto/https://${obj.primaryImage.bucket}.s3.amazonaws.com/${obj.primaryImage.imageId}`}
            alt="primary"
            className="pro-rev-pro-summary-img"
          />
          <div className="prop-rev-obj-content">
            <div className="pro-rev-pro-summary-artist-container">
              <p className="pro-rev-pro-summary-txt">{obj.artistName}</p>
            </div>
            <p className="pro-rev-pro-summary-txt-sm-light">
              {obj.title}, <i>{obj.year}</i>
            </p>
            {makeDimensions()}
          </div>
        </div>
      </div>
      <div className="pro-review-pro-content-inner-border">
        <div className="d-flex">
          <img
            src={MuseumIcon}
            alt="museum"
            className="pro-rev-pro-summary-museum-icon"
          />
          <div>
            <p className="pro-rev-pro-summary-txt">{museumAccount.name}</p>
            <div className="pro-rev-pro-summary-museum-loc-container">
              {museumAccount.locationCity && museumAccount.locationState ? (
                <p className="pro-rev-pro-summary-txt-sm-light">{`${museumAccount.locationCity}, ${museumAccount.locationState}`}</p>
              ) : null}
            </div>
            {museumAccount.websiteURL ? (
              <div className="dp-prop-museum-url-container">
                <a
                  href={museumAccount.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link"
                >
                  <p className="dp-prop-museum-url">
                    {stripProtocol(museumAccount.websiteURL)}
                  </p>
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <div className="pro-rev-pro-date-container">
          {proposal.events?.draftSubmitted?.timestamp ? (
            <p className="pro-rev-pro-date">
              {convertToMMDDYYYY(proposal.events.draftSubmitted.timestamp)}
            </p>
          ) : null}
        </div>
        <div className="pro-rev-pro-letter-container">
          <p className="pro-rev-pro-txt">{proposal.letterOfInterest}</p>
        </div>
      </div>
      {makeImages()}
      {makeConditionsInfo()}
      {proposal?.museumRequiresDonorPayShipping !== null ? (
        <div className="pro-review-pro-content-inner-border">
          <p className="pro-rev-pro-summary-header">Cost of Shipping</p>
          <p className="tw-text-[#69707f]">
            {proposal?.museumRequiresDonorPayShipping
              ? 'As a condition of the proposal, the institution asks that the cost of shipping be covered by the donor. Generally, this can be made as a tax-deductible charitable contribution to the institution.'
              : 'The cost of shipping will be covered by the institution'}
          </p>
        </div>
      ) : null}
      {selectedImage && (
        <Lightbox
          mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${selectedImage.bucket}.s3.amazonaws.com/${selectedImage.imageId}`}
          onCloseRequest={_ => dispatch(propSetSelectedImage(null))}
          imagePadding={65}
          clickOutsideToClose={false}
        />
      )}
    </div>
  );
}
