import {
  CYCLES_CLEAR_CYCLES_SORTED,
  CYCLES_GET_CYCLES_SORTED_CLEAR,
  CYCLES_GET_CYCLES_SORTED_FAILURE,
  CYCLES_GET_CYCLES_SORTED_REQUEST,
  CYCLES_GET_CYCLES_SORTED_SUCCESS,
} from '../actionTypes';

export const cyclesClearCyclesSorted = _ => {
  return {
    type: CYCLES_CLEAR_CYCLES_SORTED,
  };
};

export const cyclesGetCyclesSortedClear = _ => {
  return {
    type: CYCLES_GET_CYCLES_SORTED_CLEAR,
  };
};

export const cyclesGetCyclesSortedFailure = payload => {
  return {
    type: CYCLES_GET_CYCLES_SORTED_FAILURE,
    payload,
  };
};

export const cyclesGetCyclesSortedRequest = _ => {
  return {
    type: CYCLES_GET_CYCLES_SORTED_REQUEST,
  };
};

export const cyclesGetCyclesSortedSuccess = payload => {
  return {
    type: CYCLES_GET_CYCLES_SORTED_SUCCESS,
    payload,
  };
};
