import '../../styles/donor-proposals.css';
import '../../styles/modal.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import 'react-image-lightbox/style.css';
import ApprovedCheck from '../../images/approved-check.png';
import BackIcon from '../../images/arrow-back.png';
import LeftDisabled from '../../images/prop-view-left-disabled.png';
import LeftEnabled from '../../images/prop-view-left-enabled.png';
import Lightbox from 'react-image-lightbox';
import MuseumIcon from '../../images/prop-list-museum-icon.png';
import MuseumIconLarge from '../../images/museum-icon-large.png';
import React, { useState } from 'react';
import RightDisabled from '../../images/prop-view-right-disabled.png';
import RightEnabled from '../../images/prop-view-right-enabled.png';
import SmallBackIcon from '../../images/small-back-icon.png';
import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import { convertToMMDDYYYY, stripProtocol } from '../../lib';
import { dimensionsMapping } from '../../glue';
import { propPutRejectProposalsClear } from '../../actions';
import { putRejectProposals } from '../../requests/proposalsRequests/proposalsRequests';
import { useCurrentDonorAccountRoleMatches } from 'components/_shared/useDonorAccountRole';
import { useDispatch, useSelector } from 'react-redux';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';
import {
  useGetMuseumAccountsSimpleQuery,
  useGetProposalsDonorAccountQuery,
  usePostDonationMutation,
} from 'requests/api';

export default function DonorProposalsView() {
  const { objectId, accountId } = useParams();
  const { goBack } = useHistory();
  const { data: obj, isLoading: isLoadingObj } = useDonorGetObject({
    accountId,
    objectId,
  });
  const dispatch = useDispatch();
  const token = useSelector(state => state.authState.token);

  const { data: accountProposalsMap, isLoading: isLoadingAccountProposalsMap } =
    useGetProposalsDonorAccountQuery(accountId);
  const proposals = accountProposalsMap?.[objectId];

  const { data: museums, isLoading: isLoadingMuseums } =
    useGetMuseumAccountsSimpleQuery();

  const canManage = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  const isLoading =
    isLoadingObj || isLoadingMuseums || isLoadingAccountProposalsMap;

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [proposalIndex, setProposalIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const proposal = proposals?.[proposalIndex];

  if (isLoading) return <div className="gen-background-nav-donor"></div>;

  if (!proposals) {
    return (
      <div className="gen-background-nav-donor">
        <div className="dp-header-error d-flex mx-auto">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="dp-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <p className="dp-header-title">Proposals</p>
        </div>
        <p className="text-center dp-error-text">
          <em>There are no proposals for the selected object.</em>
        </p>
      </div>
    );
  }

  if (proposals && obj && proposals?.length && museums?.length) {
    return (
      <div className="gen-background-nav-donor">
        <div className="dp-header d-flex mx-auto">
          <div className="dp-header-back-container d-flex">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="dp-header-icon-back gen-cursor-hover-link"
              onClick={e => goBack()}
            />
            <div class-name="dp-header-back-container-content">
              <p className="dp-header-title">Proposals</p>
              <div className="dp-header-text-container">
                <p className="dp-header-text">
                  Review proposals submitted by institutions interested in
                  receiving your object as a donation.
                  <br />
                  <br />
                  NOTE: Only object owners and editors can accept proposals.
                </p>
              </div>
            </div>
          </div>
          <div className="dp-header-object-card">
            <p className="dp-header-object-card-title">Object Summary</p>
            <div className="dp-header-object-card-content d-flex">
              <img
                src={`https://res.cloudinary.com/ex-tech/image/fetch/h_243,w_183,c_fill,q_auto/https://${obj.primaryImage.bucket}.s3.amazonaws.com/${obj.primaryImage.imageId}`}
                alt="primary"
                className="dp-header-object-card-img"
              />
              <div className="dp-header-object-card-content-right">
                <div className="dp-header-object-card-artist-container">
                  <p className="dp-header-object-card-text gen-crop-txt-1">
                    {obj.artistName}
                  </p>
                </div>
                <p className="dp-header-object-card-text-small gen-crop-txt-2">
                  {obj.title}
                  {', '}
                  {obj.year}
                </p>
                <Dimensions obj={obj} />

                <Link
                  to={`/donor/folders/${accountId}/object/${objectId}/details`}
                  className="gen-link dp-header-object-card-link"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  View Object Details
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="dp-content mx-auto">
          {proposalIndex !== null ? (
            <ProposalsDetail
              proposal={proposal}
              museums={museums}
              obj={obj}
              setIsAcceptModalOpen={setIsAcceptModalOpen}
              setProposalIndex={setProposalIndex}
              proposals={proposals}
              proposalIndex={proposalIndex}
              canManage={canManage}
            />
          ) : (
            <ListCards
              proposals={proposals}
              museums={museums}
              setProposalIndex={setProposalIndex}
              setRejectModalOpen={setRejectModalOpen}
            />
          )}
        </div>
        {rejectModalOpen ? (
          <RejectAllModal
            setRejectModalOpen={setRejectModalOpen}
            accountId={accountId}
            obj={obj}
            proposals={proposals}
            token={token}
          />
        ) : null}
        {isAcceptModalOpen ? (
          <AcceptModal
            setIsAcceptModalOpen={setIsAcceptModalOpen}
            proposal={proposal}
            accountId={accountId}
            museums={museums}
            obj={obj}
          />
        ) : null}
        {selectedImage && (
          <Lightbox
            mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${selectedImage.bucket}.s3.amazonaws.com/${selectedImage.imageId}`}
            onCloseRequest={_ => setSelectedImage(null)}
            imagePadding={65}
            clickOutsideToClose={false}
          />
        )}
      </div>
    );
  }

  return null;
}

function Dimensions({ obj }) {
  let key = '';
  dimensionsMapping.forEach(v => {
    if (!key && obj.dimensions[v.key]) {
      key = v.key;
    }
  });

  let dimensionsString = '';

  if (key === 'dimensionsVariable' || key === 'duration') {
    dimensionsString = (
      <p className="dp-header-object-card-text-small">{obj.dimensions[key]}</p>
    );
  } else {
    dimensionsString = (
      <p className="dp-header-object-card-text-small">
        {`${obj.dimensions[key].heightImperial} x ${
          obj.dimensions[key].widthImperial
        }${
          obj.dimensions[key].depthImperial
            ? ` x ${obj.dimensions[key].depthImperial}`
            : ``
        } in`}
        &nbsp; &nbsp; &nbsp;
        <span className="dp-header-object-card-text-small-light">
          {`(${obj.dimensions[key].heightMetric} x ${
            obj.dimensions[key].widthMetric
          }${
            obj.dimensions[key].depthMetric
              ? ` x ${obj.dimensions[key].depthMetric}`
              : ``
          } cm)`}
        </span>
      </p>
    );
  }

  return dimensionsString;
}

function ListCards({
  museums,
  proposals,
  setProposalIndex,
  setRejectModalOpen,
}) {
  if (Array.isArray(proposals)) {
    const list = proposals.map((v, i) => {
      let museum = null;
      museums.forEach(m => {
        if (v.museumId === m.id) {
          museum = m;
        }
      });

      return (
        <div
          key={`prop_card_${v.id}`}
          className="dp-list-card d-flex justify-content-start"
        >
          <img
            src={MuseumIcon}
            alt="museum-icon"
            className="dp-list-card-icon"
          />
          <div>
            <div className="dp-list-card-content">
              <div className="dp-list-card-title-container">
                <p className="dp-list-card-title">{museum.name}</p>
              </div>
              {museum.websiteURL ? (
                <a
                  href={museum.websiteURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link"
                >
                  <p className="dp-list-card-link">
                    {stripProtocol(museum.websiteURL)}
                  </p>
                </a>
              ) : null}
            </div>
          </div>
          <a
            href="# "
            className="gen-link-no-decoration"
            onClick={e => {
              e.preventDefault();
              setProposalIndex(i);
            }}
          >
            <div className="dp-list-card-button">
              <p>VIEW PROPOSAL</p>
            </div>
          </a>
        </div>
      );
    });

    return (
      <div className="dp-list">
        {list}
        <button
          className="d-flex dp-reject-all-btn justify-content-center mt-60"
          onClick={e => {
            e.preventDefault();
            setRejectModalOpen(true);
          }}
        >
          <span className="align-self-center dp-reject-all-text">
            REJECT ALL PROPOSALS
          </span>
        </button>
      </div>
    );
  }
}

function ProposalsDetail({
  proposal,
  museums,
  obj,
  setIsAcceptModalOpen,
  setProposalIndex,
  proposals,
  proposalIndex,
  canManage,
}) {
  const museum =
    museums?.find(museum => museum.id === proposal?.museumId) || null;

  return (
    <div className="dp-prop-container-outer">
      <Nav
        proposalIndex={proposalIndex}
        setProposalIndex={setProposalIndex}
        proposals={proposals}
      />
      <div className="dp-prop-container">
        <div id="pdf">
          <div className="dp-prop-section">
            <p className="dp-prop-section-title">Institution Information</p>
            <div className="dp-prop-museum-info d-flex">
              <img
                src={MuseumIconLarge}
                alt="museum-icon-large"
                className="dp-prop-museum-icon"
              />
              <div className="dp-prop-museum-info-right">
                <p className="dp-prop-text">{museum.name}</p>
                {museum.locationCity && museum.locationState ? (
                  <div className="dp-prop-museum-location-container">
                    <p className="dp-prop-text-small-light">{`${museum.locationCity}, ${museum.locationState}`}</p>
                  </div>
                ) : null}
                {museum.websiteURL ? (
                  <a
                    href={museum.websiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="gen-link"
                  >
                    <p className="dp-prop-museum-url">
                      {stripProtocol(museum.websiteURL)}
                    </p>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          <div className="dp-prop-section">
            <p className="dp-prop-section-title">Letter of Interest</p>
            <div className="dp-prop-letter-container">
              <div className="dp-prop-letter-date-container">
                <p className="dp-prop-text-light">
                  {convertToMMDDYYYY(proposal.events.draftSubmitted.timestamp)}
                </p>
              </div>
              <p className="dp-prop-text dp-prop-letter-text">
                {proposal.letterOfInterest}
              </p>
            </div>
          </div>
          <Images obj={obj} proposal={proposal} />
          <Conditions obj={obj} proposal={proposal} />
          <MuseumRequiresDonorPayShipping obj={obj} proposal={proposal} />
        </div>
        {canManage ? (
          <button
            className="dp-prop-accept-button mx-auto tw-block tw-uppercase"
            onClick={e => {
              e.preventDefault();
              setIsAcceptModalOpen(true);
            }}
          >
            Accept this Proposal
          </button>
        ) : null}
      </div>
      <Nav
        proposalIndex={proposalIndex}
        proposals={proposals}
        setProposalIndex={setProposalIndex}
      />
    </div>
  );
}

function Images({ obj, proposal, setSelectedImage }) {
  if (Array.isArray(proposal.images) && proposal.images.length) {
    const images = proposal.images.map(v => {
      return (
        <div
          key={`prop_image_${v.imageId}`}
          className="dp-prop-image-row d-flex"
        >
          <img
            src={`https://res.cloudinary.com/ex-tech/image/fetch/h_380,w_410,c_fill,q_auto/https://${v.bucket}.s3.amazonaws.com/${v.imageId}`}
            alt={v.fileName}
            className="dp-prop-image gen-cursor-hover-link"
            onClick={_ => setSelectedImage(v)}
          />
          {v.description ? (
            <div className="dp-prop-image-description-container">
              <p className="dp-prop-image-description-text">{v.description}</p>
            </div>
          ) : null}
        </div>
      );
    });

    return (
      <div className="dp-prop-section">
        <p className="dp-prop-section-title">Contextual Images</p>
        <div className="dp-prop-images-container">{images}</div>
      </div>
    );
  }
  return null;
}

function Conditions({ obj, proposal }) {
  if (obj.restrictions) {
    return (
      <div className="dp-prop-section">
        <p className="dp-prop-section-title">Donor Conditions</p>
        <div className="dp-restrictions-container">
          <p className="dp-restrictions-container-txt">{obj.restrictions}</p>
        </div>
        <div className="dp-restrictions-content">
          <p className="dp-restrictions-txt-sm">
            The institution’s response to the above stated donor conditions is
            as follows:
          </p>
          <div className="spc-mt-15">
            <p className="dp-restrictions-txt-lg">
              {proposal.conditionsClarification
                ? 'REQUIRES CLARIFICATION'
                : 'APPROVE'}
            </p>
          </div>
          {proposal.conditionsClarification ? (
            <div className="dp-restrictions-clarification">
              <p className="dp-restrictions-txt">
                {proposal.conditionsClarification}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return null;
}

function MuseumRequiresDonorPayShipping({ obj, proposal }) {
  if (proposal.museumRequiresDonorPayShipping == null) return null;

  return (
    <div className="dp-prop-section tw-grid tw-gap-3">
      <p className="dp-prop-section-title">Cost of Shipping</p>
      <p className="dp-restrictions-txt">
        {proposal?.museumRequiresDonorPayShipping
          ? 'As a condition of the proposal, the institution asks that the cost of shipping be covered by the donor. Generally, this can be made as a tax-deductible charitable contribution to the institution.'
          : 'The cost of shipping will be covered by the institution'}
      </p>
    </div>
  );
}

function AcceptModal({
  setIsAcceptModalOpen,
  proposal,
  accountId,
  museums,
  obj,
}) {
  const museum =
    museums?.find(museum => museum.id === proposal?.museumId) || null;

  const [postDonation, { isSuccess, isLoading, data: donation }] =
    usePostDonationMutation();

  if (isSuccess && donation) {
    return (
      <div className="mod">
        <div className="mod-content-container mx-auto">
          <div className="d-flex justify-content-center mx-auto">
            <img
              src={ApprovedCheck}
              alt="approved-check"
              className="mod-approved-check"
            />
            <p className="mod-txt-header">Proposal accepted</p>
          </div>
          <div className="spc-mt-30">
            <p className="mod-txt-18">
              Please complete the forms on the following <br /> page to begin
              the donation process.
            </p>
          </div>
          <div className="mod-btn-container mx-auto d-flex justify-content-center">
            <Link
              to={`/donor/folders/${accountId}/donations/${donation.id}/match`}
              className=" btn mod-btn-dark gen-link-no-decoration dp-button-special"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              OKAY
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mod">
      <div className="mod-content-container mx-auto">
        <div className="prop-edit-modal-content-short mx-auto">
          <p className="mod-txt-header">
            Are you sure you would like to accept this
            <br />
            proposal and initiate the donation process with
            <br />
            {museum.name}?
          </p>
          <div className="mod-btn-container mx-auto d-flex justify-content-between">
            <button
              className="btn mod-btn-light"
              onClick={_ => setIsAcceptModalOpen(false)}
            >
              CANCEL
            </button>
            <button
              className="btn mod-btn-dark"
              onClick={_ => {
                if (isLoading) {
                  return;
                }
                postDonation({
                  objectId: obj.id,
                  proposalId: proposal.id,
                });
              }}
            >
              CONFIRM & ACCEPT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function RejectAllModal({
  setRejectModalOpen,
  accountId,
  obj,
  proposals,
  token,
}) {
  const dispatch = useDispatch();
  const ids = proposals
    ? proposals.reduce((acc, v) => {
        acc.push(v.id);
        return acc;
      }, [])
    : [];
  return (
    <div className="mod">
      <div className="mod-content-container mx-auto">
        <div className="prop-edit-modal-content-short mx-auto">
          <p className="mod-txt-header">
            Are you sure you would like to reject all proposals?
          </p>
          <div className="align-self-center d-flex mod-btn-container mx-auto justify-content-between">
            <button
              className="btn mod-btn-light"
              onClick={_ => setRejectModalOpen(false)}
            >
              CANCEL
            </button>
            <button
              className="btn mod-btn-dark"
              onClick={async () => {
                axios({
                  method: 'PUT',
                  url: `${BASE_URL}/objects/${obj.id}/proposals`,
                  timeout: DEFAULT_TIMEOUT,
                  data: JSON.stringify({ reject: ids }),
                  headers: {
                    'content-type': 'application/json',
                    'x-api-token': token.id,
                  },
                })
                  .then(response => {
                    setRejectModalOpen(false);
                    window.location.replace(`/donor/folders/${accountId}/`);
                  })
                  .catch(error => {});
              }}
            >
              REJECT ALL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function Nav({ setProposalIndex, proposalIndex, proposals }) {
  return (
    <div className="align-items-center d-flex justify-content-between">
      <div
        className="d-flex gen-cursor-hover-link"
        onClick={e => setProposalIndex(null)}
      >
        <img
          src={SmallBackIcon}
          alt="small-back-arrow"
          className="dp-prop-container-back-icon"
        />
        <p className="dp-prop-nav-text">All Proposals</p>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <button
          aria-label="Print"
          className="btn"
          onClick={() => window.print()}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fillRule="nonzero" fill="none">
              <path
                d="M5.952 15.6v1.342h7.143V15.6H5.952zm0-2.24v1.342h7.143V13.36H5.952zm-4.285 1.342h1.428v3.585c-.005 1.089.261 1.343 1.429 1.343h10c1.167 0 1.428-.202 1.428-1.343v-3.585h1.429c1.214.018 1.442-.236 1.429-1.343V8.431c0-1.052-.181-1.344-1.429-1.344h-1.429v-2.24c.014-1.106-.214-1.344-1.428-1.344h-10c-1.143 0-1.434.256-1.429 1.345v2.239H1.667C.438 7.087.238 7.338.238 8.43v4.928c-.005 1.089.286 1.361 1.429 1.343zm12.857 3.584h-10v-5.824h10v5.824zM15.419 10c0-.403.343-.725.771-.725.429 0 .772.322.772.725 0 .404-.343.726-.772.726-.428 0-.771-.322-.771-.726zM4.524 4.847h10v2.241h-10V4.847z"
                fill="#69707F"
              />
              <path d="M0 .994h20v18.815H0z" />
            </g>
          </svg>
        </button>
        {proposals.length > 1 && (
          <>
            <div className="pro-rev-line ml-2 mr-4"></div>
            <div className="d-flex">
              <img
                src={proposalIndex !== 0 ? LeftEnabled : LeftDisabled}
                alt="small-back-arrow"
                className="dp-prop-nav-arrow gen-cursor-hover-link"
                onClick={_ => {
                  if (proposalIndex !== 0) {
                    setProposalIndex(proposalIndex - 1);
                  }
                }}
              />
              <p className="dp-prop-nav-text-light">{`${proposalIndex + 1} OF ${
                proposals.length
              }`}</p>
              <img
                src={
                  proposalIndex !== proposals.length - 1
                    ? RightEnabled
                    : RightDisabled
                }
                alt="small-back-arrow"
                className="dp-prop-nav-arrow gen-cursor-hover-link"
                onClick={_ => {
                  if (proposalIndex !== proposals.length - 1) {
                    setProposalIndex(proposalIndex + 1);
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
