import React from 'react';
import PrivateFolder from '@images/folder-private.png';
import SharedFolder from '@images/folder-shared.png';
import SharedWithMe from '@images/folder-shared-with-me.png';
import PropTypes from 'prop-types';

export function DonorAccountCard({
  account,
  numberOfObjects,
  isShared,
  ownerName,
}) {
  return (
    <div className="tw-w-full tw-rounded-sm tw-border tw-bg-white tw-shadow-sm">
      <div className="d-flex">
        {isShared && (
          <img
            src={SharedWithMe}
            alt="shared-with-me-folder"
            className="da-folder-card-icon"
          />
        )}
        {!isShared && (
          <>
            {account.users.length === 1 ? (
              <img
                src={PrivateFolder}
                alt="private-folder"
                className="da-folder-card-icon"
              />
            ) : (
              <img
                src={SharedFolder}
                alt="shared-folder"
                className="da-folder-card-icon"
              />
            )}
          </>
        )}
        <div className="tw-py-3">
          <p className="da-folder-card-name text-truncate">{account.name}</p>
          <p className="da-folder-card-count">{`${numberOfObjects} object${
            numberOfObjects === 1 ? '' : 's'
          }`}</p>
          {isShared && (
            <p className="da-folder-card-count spc-mt-5">{`Owner: ${ownerName}`}</p>
          )}
        </div>
      </div>
    </div>
  );
}

DonorAccountCard.propTypes = {
  account: PropTypes.object.isRequired,
  numberOfObjects: PropTypes.number.isRequired,
  isShared: PropTypes.bool,
  ownerName: PropTypes.string,
};
