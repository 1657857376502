import {
  CTG_SET_CLASSIFICATIONS,
  CTG_SET_CLASSIFICATIONS_OPEN,
} from '../actionTypes';

export const ctgSetClassifications = payload => {
  return {
    type: CTG_SET_CLASSIFICATIONS,
    payload,
  };
};

export const ctgSetClassificationsOpen = payload => {
  return {
    type: CTG_SET_CLASSIFICATIONS_OPEN,
    payload,
  };
};
