import CloseIcon from '@images/grey-close-icon.png';
import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';
import { validateEmail } from '@lib';
import { useSelector } from 'react-redux';

export default function MuseumInviteUserByEmailModal({ closeModal, museum }) {
  const token = useSelector(state => state.authState.token);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async e => {
    e.stopPropagation();
    e.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      if (!email || !validateEmail(email)) {
        setIsSubmitting(false);
        setEmailError(true);
        return;
      }

      setEmailError(false);
      await axios({
        method: 'PUT',
        url: `${BASE_URL}/museums/${museum.id}/invites`,
        timeout: DEFAULT_TIMEOUT,
        data: JSON.stringify({ email, type: 'new-registrar' }),
        headers: {
          'content-type': 'application/json',
          'x-api-token': token.id,
        },
      });
      setIsSubmitting(false);
      closeModal(true);
    } catch (e) {
      setIsSubmitting(false);
      setEmailError(true);
      console.log(e);
    }
  };
  return (
    <div
      className="mod-no-bg"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="ppm-mod-overlay-container mx-auto">
        <div className="mx-auto tw-mt-[170px] tw-w-[530px] tw-rounded-md tw-bg-white tw-px-6 tw-py-6">
          <button
            onClick={() => {
              closeModal();
            }}
          >
            <img
              src={CloseIcon}
              alt="close-icon"
              className="ppm-transfer-email-mod-close-icon gen-cursor-hover-link"
            />
          </button>
          <div className="d-flex">
            <p className="ppm-title-text">
              Invite a staff member to create an account
            </p>
          </div>
          <form className="tw-grid tw-gap-5">
            <input
              type="email"
              className={`input input-no-bg spc-mt-25 ${
                emailError ? 'input-error' : ''
              }`}
              value={email}
              onChange={e => setEmail(e.target.value)}
              maxLength="100"
              placeholder="Enter email address"
            />
            <div className="tw-flex tw-justify-end tw-gap-3">
              <button
                type="button"
                className="tw-min-w-[100px] tw-rounded tw-border tw-border-gray-400 tw-py-2 tw-px-5 tw-text-xs tw-uppercase tw-text-gray-600"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="tw-min-w-[100px] tw-rounded tw-border tw-border-blue-800 tw-bg-blue-800 tw-py-2 tw-px-5 tw-text-xs tw-uppercase tw-text-white"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
