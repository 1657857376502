import React, { Fragment, useState } from 'react';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Tooltip } from '../Tooltip';

export function DonationSection({
  title,
  icon,
  sectionHeader,
  children,
  completedAction,
  learnMoreModalContent,
}) {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isUpcomingOpen, setIsUpcomingOpen] = useState(false);
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);

  // Note: should probably use an enum instead of isCompleted, current, and isUpcoming.
  const completedSteps = children.filter(
    child =>
      getRowStatus({
        isUpcoming: child.props.isUpcoming,
        isCompleted: child.props.isCompleted,
      }) === 'completed',
  );
  const currentSteps = children.filter(
    child =>
      getRowStatus({
        isUpcoming: child.props.isUpcoming,
        isCompleted: child.props.isCompleted,
      }) === 'current',
  );
  const upcomingSteps = children.filter(
    child =>
      getRowStatus({
        isUpcoming: child.props.isUpcoming,
        isCompleted: child.props.isCompleted,
      }) === 'upcoming',
  );

  const isSectionCompleted = completedSteps.length === children.length;

  const renderTitle = (
    <div className="tw-flex tw-gap-3">
      {icon}
      <div className="d-component-heading-title">{title}</div>
    </div>
  );

  return (
    <div className="tw-mx-auto tw-grid tw-w-[892px] tw-gap-4">
      <div className="tw-flex tw-gap-3">
        {renderTitle}
        {learnMoreModalContent ? (
          <button
            onClick={() => setIsLearnMoreOpen(s => !s)}
            className="tw-text-xs tw-text-blue-400 hover:tw-underline"
          >
            Learn More
          </button>
        ) : null}
      </div>
      {sectionHeader ? <div>{sectionHeader}</div> : null}
      {isSectionCompleted ? (
        <div className="tw-divide-y tw-divide-gray-200 tw-rounded-sm tw-bg-white">
          <CompletedSectionRow action={completedAction} />
          <div>
            <div className="tw-rounded-b-md tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#edeeef] tw-py-3 tw-text-sm">
              <div className="tw-grid tw-gap-2">
                <div className="tw-px-10">
                  <button
                    onClick={() => setIsHistoryOpen(s => !s)}
                    className="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-uppercase hover:tw-underline"
                  >
                    View history ({children.length}){' '}
                    {isHistoryOpen ? (
                      <ChevronDownIcon className="tw-h-4 tw-w-4" />
                    ) : (
                      <ChevronRightIcon className="tw-h-4 tw-w-4" />
                    )}
                  </button>
                </div>
                {isHistoryOpen && (
                  <div className="tw-px-3">
                    <div className="tw-divide-y tw-divide-gray-200 tw-bg-white">
                      {children}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="tw-divide-y tw-divide-gray-200 tw-rounded-sm tw-bg-white">
            {completedSteps?.length ? (
              <div>
                <div className="tw-rounded-t-md tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#edeeef] tw-py-3 tw-text-sm">
                  <div className="tw-grid tw-gap-2">
                    <div className="tw-px-10">
                      <button
                        onClick={() => setIsHistoryOpen(s => !s)}
                        className="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-uppercase hover:tw-underline"
                      >
                        Completed Steps ({completedSteps.length}){' '}
                        {isHistoryOpen ? (
                          <ChevronDownIcon className="tw-h-4 tw-w-4" />
                        ) : (
                          <ChevronRightIcon className="tw-h-4 tw-w-4" />
                        )}
                      </button>
                    </div>
                    {isHistoryOpen && (
                      <div className="tw-px-3">
                        <div className="tw-divide-y tw-divide-gray-200 tw-bg-white">
                          {completedSteps}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {currentSteps}
            {upcomingSteps?.length > 0 ? (
              <div>
                <div className="tw-rounded-b-md tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#e3e5e7] tw-py-3 tw-text-sm">
                  <div className="tw-grid tw-gap-2">
                    <div className="tw-px-10">
                      <button
                        onClick={() => setIsUpcomingOpen(s => !s)}
                        className="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-uppercase  tw-uppercase hover:tw-underline"
                      >
                        Upcoming Steps ({upcomingSteps.length}){' '}
                        {isUpcomingOpen ? (
                          <ChevronDownIcon className="tw-h-4 tw-w-4" />
                        ) : (
                          <ChevronRightIcon className="tw-h-4 tw-w-4" />
                        )}
                      </button>
                    </div>
                    {isUpcomingOpen && (
                      <div className="tw-px-3 tw-opacity-60">
                        <div className="tw-divide-y tw-divide-gray-200 tw-bg-white">
                          {upcomingSteps}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
      <SimpleModal
        isOpen={isLearnMoreOpen}
        closeModal={() => setIsLearnMoreOpen(false)}
        title={renderTitle}
        description={learnMoreModalContent}
      />
    </div>
  );
}

export function CompletedSectionRow({ action }) {
  return (
    <div className={`tw-flex tw-min-h-[80px]`}>
      <div
        className={`tw-flex tw-min-h-full tw-w-6 tw-items-center tw-justify-center tw-bg-[#33ac2e]`}
      >
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.228 1.622 3.796 7.388a.858.858 0 0 1-1.265 0L.26 4.98a.994.994 0 0 1 0-1.344.86.86 0 0 1 1.266 0l1.636 1.737L7.963.28a.858.858 0 0 1 1.265 0 .99.99 0 0 1 0 1.343"
            fill="#F8F9FA"
            fillRule="evenodd"
          />
        </svg>
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-5">
        <div className="tw-min-w-[220px] ">
          <div className="tw-text-xs tw-font-medium tw-uppercase tw-text-[#33ac2e]">
            Complete
          </div>
        </div>
        <div>{action}</div>
      </div>
    </div>
  );
}

export function DonationRow({
  isCompleted,
  tenant = 'Institution',
  rowTitle,
  tooltipContent,
  assignee,
  action,
  isUpcoming,
}) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );

  function getMatchAccountType({ userAccountType }) {
    if (userAccountType === 'museum') {
      return 'Institution';
    }
    if (userAccountType === 'donor') {
      return 'Donor';
    }
    throw new Error('Invalid user type');
  }
  const matchAccountType = getMatchAccountType({ userAccountType });

  const varsObj = {
    Donor: {
      bg: 'tw-bg-[#65999b]',
      text: 'tw-text-[#65999b]',
      displayName: 'Donor',
    },
    Institution: {
      bg: 'tw-bg-[#a0916d]',
      text: 'tw-text-[#a0916d]',
      displayName: 'Institution',
    },
    MuseumExchange: {
      bg: 'tw-bg-[#5c6b9c]',
      text: 'tw-text-[#5c6b9c]',
      displayName: 'Museum Exchange',
    },
  };

  const vars = varsObj[tenant];

  return (
    <div className={`tw-flex tw-min-h-[80px]`}>
      <div
        className={`tw-flex tw-min-h-full tw-w-6 tw-items-center tw-justify-center ${
          vars.bg
        } ${isCompleted ? 'tw-opacity-60' : ''}`}
      >
        {isCompleted ? (
          <svg
            width="10"
            height="8"
            viewBox="0 0 10 8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.228 1.622 3.796 7.388a.858.858 0 0 1-1.265 0L.26 4.98a.994.994 0 0 1 0-1.344.86.86 0 0 1 1.266 0l1.636 1.737L7.963.28a.858.858 0 0 1 1.265 0 .99.99 0 0 1 0 1.343"
              fill="#F8F9FA"
              fillRule="evenodd"
            />
          </svg>
        ) : null}
      </div>
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-p-5">
        <div className="tw-min-w-[220px] ">
          <div
            className={`tw-text-xs tw-font-medium tw-uppercase ${vars.text} ${
              isCompleted ? 'tw-opacity-60' : ''
            }`}
          >
            {vars.displayName}
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <div className={`${isCompleted ? 'tw-opacity-60' : ''}`}>
              {rowTitle}
            </div>
            {tooltipContent ? (
              <Tooltip content={tooltipContent}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`tw-h-4 tw-w-4 ${
                    isCompleted ? 'tw-opacity-60' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </Tooltip>
            ) : null}
          </div>
        </div>
        {/* TODO Not sure if this is necessary. We may not want to add permissions internally */}
        {userAccountType === 'museum' && assignee ? (
          <div className="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-border tw-border-gray-300 tw-bg-[#b7b1a5] tw-text-[#766f5d]">
            <p className="text-center">
              {assignee.nameFirst[0].toUpperCase()}
              {assignee.nameLast[0].toUpperCase()}
            </p>
          </div>
        ) : null}
        {/* TODO Not sure if this is necessary. We may not want to add permissions internally */}
        {matchAccountType === tenant ? (
          <div className="tw-min-w-[231px]">
            {isCompleted || isUpcoming ? null : action}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function NoAction({ children }) {
  return (
    <div className="tw-max-w-[200px] tw-text-center tw-text-xs tw-font-medium tw-uppercase tw-text-gray-400">
      {children}
    </div>
  );
}

export function ActionLink({ href = '#', text, secondary, ...rest }) {
  return (
    <Link
      to={href}
      className={`tw-min-h-7 tw-inline-block tw-w-full tw-rounded tw-p-2 tw-px-5 tw-text-center tw-text-sm tw-uppercase ${
        secondary
          ? 'tw-border tw-border-[#4d85cb] tw-bg-transparent tw-font-medium tw-text-[#4d85cb]'
          : 'tw-bg-[#4d85cb] tw-text-white'
      }`}
      {...rest}
    >
      {text}
    </Link>
  );
}

export function DonationIcon() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fillRule="nonzero" fill="none">
          <path
            d="m18.16 7.054-5.365-5.098a1.175 1.175 0 0 0-1.606-.007l-.007.007L5.84 7.054H2.909c-.628 0-1.136.485-1.136 1.084v13.017c0 .599.508 1.085 1.136 1.085h18.182c.628 0 1.136-.486 1.136-1.085V8.138c0-.599-.508-1.084-1.136-1.084h-2.932zM12 4.255l2.932 2.799H9.068L12 4.255zm7.955 15.815H4.045V9.223h15.91V20.07zM9.727 12.477c0 .6-.508 1.085-1.136 1.085-.628 0-1.136-.486-1.136-1.085s.508-1.084 1.136-1.084c.628 0 1.136.485 1.136 1.084zm7.955 5.424H8.59l1.704-3.254 1.137 2.17 2.84-5.424 3.41 6.508z"
            fill="#2E384D"
          />
          <path d="M0 0h24v24H0z" />
        </g>
      </svg>
    </div>
  );
}

export function ShippingIcon() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.2 6.6h-2.4V5.4A2.4 2.4 0 0 0 14.4 3h-12A2.4 2.4 0 0 0 0 5.4v12h2.4a3.6 3.6 0 1 0 7.2 0h4.8a3.6 3.6 0 1 0 7.2 0H24v-6l-4.8-4.8zM6 19.2a1.8 1.8 0 1 1 .001-3.601A1.8 1.8 0 0 1 6 19.2zm-3.6-5.4V5.4h12v8.4h-12zM18 19.2a1.8 1.8 0 1 1 .001-3.601A1.8 1.8 0 0 1 18 19.2z"
          fill="#2E384D"
          fillRule="nonzero"
        />
      </svg>
    </div>
  );
}

export function SimpleModal({
  isOpen,
  closeModal,
  title,
  description,
  hasCloseIcon = true,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
        onClose={closeModal}
      >
        <div className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 tw-text-center">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black/25" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <div className="tw-inline-block tw-w-[530px] tw-transform tw-rounded-lg tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-transition-all">
              <div className="tw-grid tw-gap-8">
                <div className="tw-grid tw-gap-4">
                  {hasCloseIcon ? (
                    <div className="tw-flex tw-justify-end">
                      <button onClick={closeModal} className="tw--m-3 tw-p-3">
                        <XIcon className="tw-h-4 tw-w-4" />
                      </button>
                    </div>
                  ) : null}
                  <Dialog.Title as="h2" className="tw-mb-0 tw-text-xl">
                    {title}
                  </Dialog.Title>
                  <Dialog.Description as="div" className="tw-text-gray-500">
                    {description}
                  </Dialog.Description>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function getRowStatus({ isUpcoming, isCompleted }) {
  // This is to avoid a step being in multiple states at once.
  if (isCompleted) return 'completed';
  if (isUpcoming) return 'upcoming';
  return 'current';
}
