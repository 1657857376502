import { openBase64PDFNewWindow } from '@lib';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyGetDonationLoanFinalQuery } from 'requests/api';
import {
  ActionLink,
  DonationRow,
  DonationSection,
  NoAction,
} from './DonationSections';

export function LoanAgreementSection({
  donation,
  setIsLoanUploadOpen = () => {},
}) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );
  const [getLoanFinal] = useLazyGetDonationLoanFinalQuery();

  if (!donation) return null;
  if (donation?.events?.completed && !donation?.loan?.finalDocument) {
    return null;
  }
  if (!['donor', 'museum'].includes(userAccountType)) return null;

  if (!donation.museumInfo.requireLoanAgreement) return null;

  return (
    <DonationSection
      title="Loan Agreement"
      learnMoreModalContent={
        <div>
          A Loan Agreement is required for any object being shipped to the
          institution for donation consideration. The object will retain its
          status as a loan until it has been approved for donation.
        </div>
      }
      completedAction={
        <ActionLink
          href={`#`}
          secondary
          onClick={async _ => {
            const loanFinalData = await getLoanFinal({
              donationId: donation.id,
            }).unwrap();
            openBase64PDFNewWindow(loanFinalData);
          }}
          text="View Loan Agreement"
        />
      }
      icon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12.54 18.5v-2h-4v-2h4v-2l3 3-3 3zM4 4a2 2 0 0 1 2-2h8a1 1 0 0 1 .707.293l5 5A1 1 0 0 1 20 8v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4zm13.586 4L14 4.414V8h3.586zM12 4H6v16h12V10h-5a1 1 0 0 1-1-1V4z"
              fill="#2E384D"
              fillRule="nonzero"
            />
          </g>
        </svg>
      }
    >
      <DonationRow
        tenant="Institution"
        rowTitle="Upload & Send Loan Agreement"
        action={
          <ActionLink
            onClick={() => setIsLoanUploadOpen(true)}
            href="#"
            text="Upload & Send"
          />
        }
        isCompleted={
          donation.events.completed ||
          donation.loan.finalDocument ||
          donation.loan.loan
        }
      />
      <DonationRow
        tenant="Donor"
        rowTitle="Sign Loan Agreement"
        tooltipContent="Loan Agreement must be signed by the donor before the object is shipped to the institution."
        action={
          <NoAction>Loan agreement has been emailed via HelloSign</NoAction>
        }
        isUpcoming={!donation.loan.loan}
        isCompleted={
          donation.events.completed ||
          donation.loan.finalDocument ||
          donation.loan.signedDonor
        }
      />
      <DonationRow
        tenant="Institution"
        rowTitle="Countersign Loan Agreement"
        tooltipContent="Loan Agreement must be countersigned by the institution before the object is shipped to the institution."
        action={
          <NoAction>Loan agreement has been emailed via HelloSign</NoAction>
        }
        isUpcoming={!donation.loan.signedDonor}
        isCompleted={
          donation.events.completed ||
          donation.loan.finalDocument ||
          donation.loan.signedMuseum
        }
      />
    </DonationSection>
  );
}
