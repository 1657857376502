import { useGetDonorDonationsQuery } from 'requests/api';

export function useDonorGetDonation({ donationId, accountId }) {
  // Note: we should eventually add an actual "getDonation" endpoint for donors
  const {
    data: donations,
    isLoading,
    isError,
    isFetching,
  } = useGetDonorDonationsQuery(accountId, { skip: !accountId });

  const donation = donations?.find(donation => donation.id === donationId);

  return { data: donation || null, isLoading, isError, isFetching };
}
