import { openBase64PDFNewWindow } from '@lib';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLazyGetDonationFormIRSFormQuery } from 'requests/api';
import {
  ActionLink,
  DonationRow,
  DonationSection,
  NoAction,
} from './DonationSections';

export function FormIRSSection({ donation }) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );
  const [getFormIRS] = useLazyGetDonationFormIRSFormQuery();

  if (!donation) return null;
  if (!['donor', 'museum'].includes(userAccountType)) return null;
  if (donation?.donorInfo?.taxFiling == false) return null;
  if (donation?.events?.completed && !donation?.formIRS?.form) {
    return null;
  }

  return (
    <DonationSection
      title="Form 8283"
      learnMoreModalContent={
        <div>
          Generally, a Form 8283 is required by the IRS for any donated object
          (or group of similar objects) valued over $500. If Museum Exchange
          managed the appraisal for the donation, it will coordinate electronic
          signatures from the appraiser and donee.{' '}
          <a
            href="https://www.irs.gov/pub/irs-pdf/i8283.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="tw-text-blue-500 hover:tw-underline"
          >
            See here
          </a>{' '}
          for further information about the IRS requirements.
        </div>
      }
      completedAction={
        <ActionLink
          href={`#`}
          secondary
          onClick={async _ => {
            const formIRSData = await getFormIRS({
              donationId: donation.id,
            }).unwrap();
            openBase64PDFNewWindow(formIRSData);
          }}
          text="View Form 8283"
        />
      }
      icon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 4v15.429h24V4H0zm1.714 1.714h20.572v12H1.714v-12zM3.43 7.43v1.714H12V7.429H3.429zm13.714 0v.91c-.964.161-1.714.978-1.714 1.982 0 .647.358 1.239.937 1.527l2.491 1.26a.306.306 0 0 1-.321.32h-1.072a.306.306 0 0 1-.321-.32v-.537h-1.714v.536c0 1.005.75 1.822 1.714 1.983V16h1.714v-.911c.964-.16 1.714-.977 1.714-1.982 0-.646-.358-1.235-.937-1.526l-2.491-1.26c0-.19.13-.321.321-.321h1.072c.19 0 .321.13.321.321v.536h1.714v-.536c0-1.004-.75-1.821-1.714-1.982v-.91h-1.714zM3.429 10v1.714h6V10h-6zm2.142 2.571c-.518 0-.883.295-1.125.563-.24.268-.418.565-.562.884-.288.639-.455 1.329-.455 1.982h1.714c0-.274.134-.87.321-1.286.054-.12.107-.16.161-.24.064.086.11.143.187.267.125.201.262.442.429.67.168.227.372.603 1.018.616.51.01.666-.174.83-.294.164-.121.278-.235.402-.35.084-.076.15-.133.214-.186.024.033.118.09.59.294.572.251 1.44.509 2.705.509v-1.714c-1.054 0-1.604-.172-2.01-.348-.404-.178-.712-.51-1.419-.51-.516 0-.646.201-.804.322-.14.107-.237.221-.348.321-.05-.08-.073-.12-.133-.214a4.013 4.013 0 0 0-.563-.75c-.248-.251-.639-.536-1.152-.536z"
            fill="#2E384D"
            fillRule="nonzero"
          />
        </svg>
      }
    >
      <DonationRow
        tenant="MuseumExchange"
        rowTitle="Upload Form 8283"
        tooltipContent="Appraiser to enter donor name(s) at the top of pages 1 & 2 and complete Section B, Part I, Information on Donated Property, Line 2 & Line 3, Columns (a)-(c) and sign Section B, Part IV, Declaration of Appraiser."
        action={<NoAction>Awaiting Museum Exchange Action</NoAction>}
        isCompleted={
          donation.events.completed ||
          donation.formIRS.form ||
          donation.formIRS.sentToMuseum
        }
      />
      <DonationRow
        tenant="Institution"
        rowTitle="Sign Form 8283"
        tooltipContent="Institution to complete and sign Section B, Part V, Donee"
        action={<NoAction>Awaiting Museum Action</NoAction>}
        isUpcoming={!donation.formIRS.sentToMuseum}
        isCompleted={
          donation.events.completed ||
          donation.formIRS.form ||
          donation.formIRS.receivedFromMuseum
        }
      />
      <DonationRow
        tenant="MuseumExchange"
        rowTitle="Send Donation Package"
        tooltipContent="Museum Exchange to verify completeness and send Form 8283 to the donor for tax filing. Donor to enter identifying number at the top of pages 1 & 2 and complete Section B, Part I, Line 3, Columns (d)-(i) and, if applicable, Parts II or III."
        action={<NoAction>Awaiting Museum Exchange Action</NoAction>}
        isUpcoming={!donation.formIRS.receivedFromMuseum}
        isCompleted={
          donation.events.completed ||
          (donation.formIRS.sentToDonor && donation.formIRS.form)
        }
      />
    </DonationSection>
  );
}
