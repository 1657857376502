import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  authClearTel,
  authPostVerifyClear,
  authPostVerifyResendClear,
  authPutVerifyClear,
  loginVerifyReset,
  loginVerifyUpdateCode,
  loginVerifyUpdateFormErrors,
} from '../../actions';
import {
  authPostVerify,
  authPostVerifyResend,
  authPutVerify,
} from '../../requests';
import { sanitizeCode } from '../../lib';

class LoginVerify extends Component {
  static get propTypes() {
    return {
      clearTel: PropTypes.func,
      code: PropTypes.string,
      formErrors: PropTypes.object,
      postVerify: PropTypes.func,
      postVerifyClear: PropTypes.func,
      postVerifyRequest: PropTypes.object,
      postVerifyResend: PropTypes.func,
      postVerifyResendClear: PropTypes.func,
      postVerifyResendRequest: PropTypes.object,
      putVerify: PropTypes.func,
      putVerifyClear: PropTypes.func,
      putVerifyRequest: PropTypes.object,
      telObj: PropTypes.object,
      token: PropTypes.object,
      user: PropTypes.object,
      updateCode: PropTypes.func,
      updateFormErrors: PropTypes.func,
      verifyReset: PropTypes.func,
    };
  }

  handleUpdateCode = event => {
    this.props.updateCode({ code: event.target.value });
  };

  componentDidMount() {
    if (this.props.token) {
      this.props.postVerify(this.props.token);
    }
  }

  componentWillUnmount() {
    this.props.verifyReset();
    this.props.clearTel();
    this.props.postVerifyClear();
    this.props.postVerifyResendClear();
    this.props.putVerifyClear();
  }

  handleResendRequest = _ => {
    if (
      this.props.telObj &&
      this.props.token &&
      !this.props.postVerifyRequest.loading &&
      !this.props.postVerifyResendRequest.loading
    ) {
      this.props.postVerifyResend(this.props.token, this.props.telObj);
    }
  };

  validateFormAndSubmit = _ => {
    if (this.props.putVerifyRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      codeError: false,
    };

    if (sanitizeCode(this.props.code).length !== 6) {
      errors.formError = true;
      errors.codeError = true;
    }

    this.props.updateFormErrors({ formErrors: errors });

    if (errors.formError) {
      return;
    }

    this.props.putVerify(this.props.token, {
      code: sanitizeCode(this.props.code),
      telId: this.props.telObj.id,
    });
  };

  render() {
    if (
      this.props.putVerifyRequest.data &&
      this.props.token.role !== 'tmp_h' &&
      this.props.token.role !== 'tmp_d'
    ) {
      if (this.props.token.role === 'donor') {
        return <Redirect push to="/donor" />;
      } else if (this.props.token.role === 'museum') {
        return <Redirect push to="/institution" />;
      } else {
        localStorage.clear();
        window.location.reload(false);
      }
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <p className="header text-center mex-mt-47">Confirm Your Identity</p>
        <div className="sub-header mx-auto mex-mb-30">
          <p className="text text-center">
            Please enter the six-digit verification code sent via SMS
          </p>
        </div>
        <div>
          <div className="misc-content mx-auto">
            <div className="misc-content-inner mx-auto">
              <div className="mex-mb-26">
                <p
                  className={`label ${
                    this.props.formErrors.codeError ? 'label-error' : ''
                  }`}
                >
                  VERIFICATION CODE
                </p>
                <input
                  className={`input ${
                    this.props.formErrors.codeError ? 'input-error' : ''
                  }`}
                  type="input"
                  value={this.props.code}
                  onChange={this.handleUpdateCode}
                  placeholder="Verification Code"
                ></input>
              </div>
              <button
                className={`btn btn-primary mex-btn-primary mex-btn-primary-fill ${
                  !this.props.putVerifyRequest.error ? 'mex-mb-12' : ''
                }`}
                style={{ width: '100%' }}
                onClick={this.validateFormAndSubmit}
              >
                Submit
              </button>
              {this.props.putVerifyRequest.error && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                  Please try again.
                </div>
              )}
              <p className="text">
                If you have not received a verification code via SMS,{' '}
                <button
                  className="naked-btn link"
                  onClick={_ => this.handleResendRequest()}
                >
                  click here
                </button>{' '}
                to have it resent
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    code: state.loginState.verifyFormData.code,
    formErrors: state.loginState.verifyFormErrors,
    postVerifyRequest: state.authState.postVerifyRequest,
    postVerifyResendRequest: state.authState.postVerifyResendRequest,
    putVerifyRequest: state.authState.putVerifyRequest,
    telObj: state.authState.telObj,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearTel: _ => dispatch(authClearTel()),
    postVerify: payload => dispatch(authPostVerify(payload)),
    postVerifyClear: _ => dispatch(authPostVerifyClear()),
    postVerifyResend: (token, payload) =>
      dispatch(authPostVerifyResend(token, payload)),
    postVerifyResendClear: _ => dispatch(authPostVerifyResendClear()),
    putVerify: (token, payload) => dispatch(authPutVerify(token, payload)),
    putVerifyClear: _ => dispatch(authPutVerifyClear()),
    updateCode: payload => dispatch(loginVerifyUpdateCode(payload)),
    updateFormErrors: payload => dispatch(loginVerifyUpdateFormErrors(payload)),
    verifyReset: _ => dispatch(loginVerifyReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginVerify);
