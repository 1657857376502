export const sortByArtistLastName = (a, b) => {
  const aNameElements = a.artistName.split(' ');
  const aLastName = aNameElements[aNameElements.length - 1];

  const bNameElements = b.artistName.split(' ');
  const bLastName = bNameElements[bNameElements.length - 1];

  if (aLastName < bLastName) {
    return -1;
  }
  if (aLastName > bLastName) {
    return 1;
  }
  return 0;
};
