import {
  DDONM_RESET_ALL,
  DDONM_SET_FORM_ONE,
  DDONM_RESET_FORM_ONE,
  DDONM_RESET_FORM_ONE_ERRORS,
  DDONM_UPDATE_DONOR_NAME_FIRST,
  DDONM_UPDATE_DONOR_NAME_LAST,
  DDONM_UPDATE_REPRESENTATIVE,
  DDONM_UPDATE_REPRESENTATIVE_NAME_FIRST,
  DDONM_UPDATE_REPRESENTATIVE_NAME_LAST,
  DDONM_UPDATE_DADDRESS_LINE_ONE,
  DDONM_UPDATE_DADDRESS_LINE_TWO,
  DDONM_UPDATE_DADDRESS_CITY,
  DDONM_UPDATE_DADDRESS_STATE,
  DDONM_UPDATE_DADDRESS_ZIP,
  DDONM_UPDATE_DADDRESS_COUNTRY,
  DDONM_UPDATE_CREDIT_LINE,
  DDONM_UPDATE_FORM_ONE_ERRORS,
  DDONM_SET_FORM_TWO,
  DDONM_RESET_FORM_TWO,
  DDONM_RESET_FORM_TWO_ERRORS,
  DDONM_UPDATE_CONTACT_NAME_FIRST,
  DDONM_UPDATE_CONTACT_NAME_LAST,
  DDONM_UPDATE_CONTACT_TEL,
  DDONM_UPDATE_CONTACT_EMAIL,
  DDONM_UPDATE_SADDRESS_COMPANY,
  DDONM_UPDATE_SADDRESS_LINE_ONE,
  DDONM_UPDATE_SADDRESS_LINE_TWO,
  DDONM_UPDATE_SADDRESS_CITY,
  DDONM_UPDATE_SADDRESS_STATE,
  DDONM_UPDATE_SADDRESS_ZIP,
  DDONM_UPDATE_SADDRESS_COUNTRY,
  DDONM_UPDATE_SHIPPING_DESCRIPTION,
  DDONM_UPDATE_SHIPPING_INSTRUCTIONS,
  DDONM_UPDATE_FORM_TWO_ERRORS,
  DDONM_SET_FORM_THREE,
  DDONM_RESET_FORM_THREE,
  DDONM_RESET_FORM_THREE_ERRORS,
  DDONM_UPDATE_INSURANCE_VALUE,
  DDONM_UPDATE_WEIGHT,
  DDONM_UPDATE_WEIGHT_WEIGHT,
  DDONM_UPDATE_WEIGHT_SYSTEM,
  DDONM_UPDATE_FRAME,
  DDONM_UPDATE_FRAME_HEIGHT,
  DDONM_UPDATE_FRAME_WIDTH,
  DDONM_UPDATE_FRAME_DEPTH,
  DDONM_UPDATE_FRAME_SYSTEM,
  DDONM_UPDATE_CRATE,
  DDONM_UPDATE_CRATE_HEIGHT,
  DDONM_UPDATE_CRATE_WIDTH,
  DDONM_UPDATE_CRATE_DEPTH,
  DDONM_UPDATE_CRATE_SYSTEM,
  DDONM_UPDATE_CAN_PACK,
  DDONM_UPDATE_CONDITION,
  DDONM_UPDATE_FORM_THREE_ERRORS,
  DDONM_SET_FINISH_MODAL,
} from '../../actions/actionTypes';

const initialDonorDonationsMatchState = {
  finishModalOpen: false,
  formDataOne: {
    donor: {
      nameFirst: '',
      nameLast: '',
    },
    representative: null,
    donorAddress: {
      lineOne: '',
      lineTwo: null,
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    creditLine: '',
  },
  formErrorsOne: {
    formError: false,
    donorNameFirstError: false,
    donorNameLastError: false,
    representativeNameFirstError: false,
    representativeNameLastError: false,
    lineOneError: false,
    cityError: false,
    stateError: false,
    zipError: false,
    countryError: false,
    creditLineError: false,
  },
  formDataTwo: {
    shippingAddress: {
      company: null,
      lineOne: '',
      lineTwo: null,
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    contact: {
      nameFirst: '',
      nameLast: '',
      tel: '',
      email: '',
    },
    shippingDescription: '',
  },
  formErrorsTwo: {
    formError: false,
    companyError: false,
    lineOneError: false,
    cityError: false,
    stateError: false,
    zipError: false,
    countryError: false,
    nameFirstError: false,
    nameLastError: false,
    telError: false,
    emailError: false,
    shippingDescriptionError: false,
  },
  formDataThree: {
    insuranceValue: null,
    weight: null,
    frame: null,
    crate: null,
    canPackOnSite: null,
    condition: '',
    shippingInstructions: '',
  },
  formErrorsThree: {
    formError: false,
    insuranceValueError: false,
    weightWeightError: false,
    weightSystemError: false,
    frameHeightError: false,
    frameWidthError: false,
    frameSystemError: false,
    crateHeightError: false,
    crateWidthError: false,
    crateSystemError: false,
    canPackOnSiteError: false,
  },
};

export const donorDonationsMatchReducer = (
  state = initialDonorDonationsMatchState,
  action,
) => {
  switch (action.type) {
    case DDONM_RESET_ALL:
      return {
        formDataOne: {
          donor: {
            nameFirst: '',
            nameLast: '',
          },
          representative: null,
          donorAddress: {
            lineOne: '',
            lineTwo: null,
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          creditLine: '',
        },
        formErrorsOne: {
          formError: false,
          donorNameFirstError: false,
          donorNameLastError: false,
          representativeNameFirstError: false,
          representativeNameLastError: false,
          lineOneError: false,
          cityError: false,
          stateError: false,
          zipError: false,
          countryError: false,
          creditLineError: false,
        },
        formDataTwo: {
          shippingAddress: {
            company: null,
            lineOne: '',
            lineTwo: null,
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          contact: {
            nameFirst: '',
            nameLast: '',
            tel: '',
            email: '',
          },
          shippingDescription: '',
        },
        formErrorsTwo: {
          formError: false,
          companyError: false,
          lineOneError: false,
          cityError: false,
          stateError: false,
          zipError: false,
          countryError: false,
          nameFirstError: false,
          nameLastError: false,
          telError: false,
          emailError: false,
          shippingDescriptionError: false,
        },
        formDataThree: {
          insuranceValue: null,
          weight: null,
          frame: null,
          crate: null,
          canPackOnSite: null,
          condition: '',
          shippingInstructions: '',
        },
        formErrorsThree: {
          formError: false,
          insuranceValueError: false,
          weightWeightError: false,
          weightSystemError: false,
          frameHeightError: false,
          frameWidthError: false,
          frameSystemError: false,
          crateHeightError: false,
          crateWidthError: false,
          crateSystemError: false,
          canPackOnSiteError: false,
        },
      };

    case DDONM_SET_FORM_ONE:
      return {
        ...state,
        formDataOne: action.payload,
      };

    case DDONM_RESET_FORM_ONE:
      return {
        ...state,
        formDataOne: {
          donor: {
            nameFirst: '',
            nameLast: '',
          },
          representative: null,
          donorAddress: {
            lineOne: '',
            lineTwo: null,
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          creditLine: '',
        },
      };

    case DDONM_RESET_FORM_ONE_ERRORS:
      return {
        ...state,
        formErrorsOne: {
          formError: false,
          donorNameFirstError: false,
          donorNameLastError: false,
          representativeNameFirstError: false,
          representativeNameLastError: false,
          lineOneError: false,
          cityError: false,
          stateError: false,
          zipError: false,
          countryError: false,
          creditLineError: false,
        },
      };

    case DDONM_UPDATE_DONOR_NAME_FIRST:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donor: {
            ...state.formDataOne.donor,
            nameFirst: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DONOR_NAME_LAST:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donor: {
            ...state.formDataOne.donor,
            nameLast: action.payload,
          },
        },
      };

    case DDONM_UPDATE_REPRESENTATIVE:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          representative: action.payload,
        },
      };

    case DDONM_UPDATE_REPRESENTATIVE_NAME_FIRST:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          representative: {
            ...state.formDataOne.representative,
            nameFirst: action.payload,
          },
        },
      };

    case DDONM_UPDATE_REPRESENTATIVE_NAME_LAST:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          representative: {
            ...state.formDataOne.representative,
            nameLast: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DADDRESS_LINE_ONE:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donorAddress: {
            ...state.formDataOne.donorAddress,
            lineOne: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DADDRESS_LINE_TWO:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donorAddress: {
            ...state.formDataOne.donorAddress,
            lineTwo: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DADDRESS_CITY:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donorAddress: {
            ...state.formDataOne.donorAddress,
            city: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DADDRESS_STATE:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donorAddress: {
            ...state.formDataOne.donorAddress,
            state: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DADDRESS_ZIP:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donorAddress: {
            ...state.formDataOne.donorAddress,
            zip: action.payload,
          },
        },
      };

    case DDONM_UPDATE_DADDRESS_COUNTRY:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          donorAddress: {
            ...state.formDataOne.donorAddress,
            country: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CREDIT_LINE:
      return {
        ...state,
        formDataOne: {
          ...state.formDataOne,
          creditLine: action.payload,
        },
      };

    case DDONM_UPDATE_FORM_ONE_ERRORS:
      return {
        ...state,
        formErrorsOne: action.payload,
      };

    case DDONM_SET_FORM_TWO:
      return {
        ...state,
        formDataTwo: action.payload,
      };

    case DDONM_RESET_FORM_TWO:
      return {
        ...state,
        formDataTwo: {
          shippingAddress: {
            company: null,
            lineOne: '',
            lineTwo: null,
            city: '',
            state: '',
            zip: '',
            country: '',
          },
          contact: {
            nameFirst: '',
            nameLast: '',
            tel: '',
            email: '',
          },
          shippingDescription: '',
        },
      };

    case DDONM_RESET_FORM_TWO_ERRORS:
      return {
        ...state,
        formErrorsTwo: {
          formError: false,
          companyError: false,
          lineOneError: false,
          cityError: false,
          stateError: false,
          zipError: false,
          countryError: false,
          nameFirstError: false,
          nameLastError: false,
          telError: false,
          emailError: false,
          shippingDescriptionError: false,
        },
      };

    case DDONM_UPDATE_CONTACT_NAME_FIRST:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          contact: {
            ...state.formDataTwo.contact,
            nameFirst: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CONTACT_NAME_LAST:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          contact: {
            ...state.formDataTwo.contact,
            nameLast: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CONTACT_TEL:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          contact: {
            ...state.formDataTwo.contact,
            tel: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CONTACT_EMAIL:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          contact: {
            ...state.formDataTwo.contact,
            email: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_COMPANY:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            company: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_LINE_ONE:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            lineOne: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_LINE_TWO:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            lineTwo: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_CITY:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            city: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_STATE:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            state: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_ZIP:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            zip: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SADDRESS_COUNTRY:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingAddress: {
            ...state.formDataTwo.shippingAddress,
            country: action.payload,
          },
        },
      };

    case DDONM_UPDATE_SHIPPING_DESCRIPTION:
      return {
        ...state,
        formDataTwo: {
          ...state.formDataTwo,
          shippingDescription: action.payload,
        },
      };

    case DDONM_UPDATE_SHIPPING_INSTRUCTIONS:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          shippingInstructions: action.payload,
        },
      };

    case DDONM_UPDATE_FORM_TWO_ERRORS:
      return {
        ...state,
        formErrorsTwo: action.payload,
      };

    case DDONM_SET_FORM_THREE:
      return {
        ...state,
        formDataThree: action.payload,
      };

    case DDONM_RESET_FORM_THREE:
      return {
        ...state,
        formDataThree: {
          insuranceValue: null,
          weight: null,
          frame: null,
          crate: null,
          canPackOnSite: null,
          condition: '',
          shippingInstructions: '',
        },
      };

    case DDONM_RESET_FORM_THREE_ERRORS:
      return {
        ...state,
        formErrorsThree: {
          formError: false,
          insuranceValueError: false,
          weightWeightError: false,
          weightSystemError: false,
          frameHeightError: false,
          frameWidthError: false,
          frameSystemError: false,
          crateHeightError: false,
          crateWidthError: false,
          crateSystemError: false,
          canPackOnSiteError: false,
        },
      };

    case DDONM_UPDATE_INSURANCE_VALUE:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          insuranceValue: action.payload,
        },
      };

    case DDONM_UPDATE_WEIGHT:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          weight: action.payload,
        },
      };

    case DDONM_UPDATE_WEIGHT_WEIGHT:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          weight: {
            ...state.formDataThree.weight,
            weight: action.payload,
          },
        },
      };

    case DDONM_UPDATE_WEIGHT_SYSTEM:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          weight: {
            ...state.formDataThree.weight,
            system: action.payload,
          },
        },
      };

    case DDONM_UPDATE_FRAME:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          frame: action.payload,
        },
      };

    case DDONM_UPDATE_FRAME_HEIGHT:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          frame: {
            ...state.formDataThree.frame,
            height: action.payload,
          },
        },
      };

    case DDONM_UPDATE_FRAME_WIDTH:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          frame: {
            ...state.formDataThree.frame,
            width: action.payload,
          },
        },
      };

    case DDONM_UPDATE_FRAME_DEPTH:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          frame: {
            ...state.formDataThree.frame,
            depth: action.payload,
          },
        },
      };

    case DDONM_UPDATE_FRAME_SYSTEM:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          frame: {
            ...state.formDataThree.frame,
            system: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CRATE:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          crate: action.payload,
        },
      };

    case DDONM_UPDATE_CRATE_HEIGHT:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          crate: {
            ...state.formDataThree.crate,
            height: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CRATE_WIDTH:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          crate: {
            ...state.formDataThree.crate,
            width: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CRATE_DEPTH:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          crate: {
            ...state.formDataThree.crate,
            depth: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CRATE_SYSTEM:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          crate: {
            ...state.formDataThree.crate,
            system: action.payload,
          },
        },
      };

    case DDONM_UPDATE_CAN_PACK:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          canPackOnSite: action.payload,
        },
      };

    case DDONM_UPDATE_CONDITION:
      return {
        ...state,
        formDataThree: {
          ...state.formDataThree,
          condition: action.payload,
        },
      };

    case DDONM_UPDATE_FORM_THREE_ERRORS:
      return {
        ...state,
        formErrorsThree: action.payload,
      };

    case DDONM_SET_FINISH_MODAL:
      return {
        ...state,
        finishModalOpen: action.payload,
      };

    default:
      return state;
  }
};
