import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  msetPutMuseumUserPositionClear,
  msetUpdateNewPosition,
  msetUpdateNewPositionError,
} from '../../actions';
import { putMuseumUserPosition } from '../../requests';
import { MUSEUM_POSITIONS } from '../../config';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/text.css';

class MuseumUserRole extends Component {
  static get propTypes() {
    return {
      newPosition: PropTypes.string,
      newPositionError: PropTypes.bool,
      putMuseumUserPosition: PropTypes.func,
      putMuseumUserPositionClear: PropTypes.func,
      putMuseumUserPositionRequest: PropTypes.object,
      token: PropTypes.object,
      updateNewPosition: PropTypes.func,
      updateNewPositionError: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.putMuseumUserPositionClear();
    this.props.updateNewPosition({ newPosition: '' });
    this.props.updateNewPositionError({ newPositionError: false });
  }

  handleSaveClick = _ => {
    if (this.props.putMuseumUserPositionRequest.loadiing) {
      return;
    }

    let newPositionError = false;
    if (!this.props.newPosition) {
      newPositionError = true;
    }

    this.props.updateNewPositionError({ newPositionError });

    if (newPositionError) {
      return;
    }

    this.props.putMuseumUserPosition(
      this.props.token,
      this.props.user.id,
      this.props.newPosition,
    );
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.goBack()}
          />
          <p className="set-back-txt">Change Role</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-label-txt">CURRENT ROLE</p>
            <div className="set-info-edit-current-val-container">
              <p className="set-info-txt">{this.props.user.museum.position}</p>
            </div>
            <p className="set-label-txt">NEW ROLE</p>
            <select
              className={`btn dropdown-toggle set-info-edit-field set-info-edit-field-select ${
                this.props.newPosition
                  ? ''
                  : 'set-info-edit-field-select-no-selection'
              } ${this.props.newPositionError ? 'fd-input-error-field' : ''}`}
              style={{ width: '100%' }}
              onChange={e =>
                this.props.updateNewPosition({ newPosition: e.target.value })
              }
              value={this.props.newPosition}
            >
              <option disabled value="">
                Select new museum role
              </option>
              {MUSEUM_POSITIONS.map((v, i) => (
                <option key={`museumPosition_${i}`} value={v}>
                  {v}
                </option>
              ))}
            </select>
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              {this.props.putMuseumUserPositionRequest.error ? (
                <p className="set-special-err-1">Please try again</p>
              ) : null}
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.goBack()}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SAVE
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putMuseumUserPositionRequest.data) {
      return <Redirect to="/institution/settings/user" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    newPosition: state.museumSettingsState.newPosition,
    newPositionError: state.museumSettingsState.newPositionError,
    putMuseumUserPositionRequest:
      state.museumSettingsState.putMuseumUserPositionRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putMuseumUserPosition: (token, userId, position) =>
      dispatch(putMuseumUserPosition(token, userId, position)),
    putMuseumUserPositionClear: _ => dispatch(msetPutMuseumUserPositionClear()),
    updateNewPosition: payload => dispatch(msetUpdateNewPosition(payload)),
    updateNewPositionError: payload =>
      dispatch(msetUpdateNewPositionError(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumUserRole);
