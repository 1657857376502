import {
  PROP_CLEAR_PROP,
  PROP_DELETE_IMAGE_CLEAR,
  PROP_DELETE_IMAGE_FAILURE,
  PROP_DELETE_IMAGE_REQUEST,
  PROP_DELETE_IMAGE_SUCCESS,
  PROP_POST_DRAFT_CLEAR,
  PROP_POST_DRAFT_FAILURE,
  PROP_POST_DRAFT_REQUEST,
  PROP_POST_DRAFT_SUCCESS,
  PROP_PUT_APPROVE_CLEAR,
  PROP_PUT_APPROVE_FAILURE,
  PROP_PUT_APPROVE_REQUEST,
  PROP_PUT_APPROVE_SUCCESS,
  PROP_PUT_DRAFT_CLEAR,
  PROP_PUT_DRAFT_FAILURE,
  PROP_PUT_DRAFT_REQUEST,
  PROP_PUT_DRAFT_SUCCESS,
  PROP_PUT_IMAGE_CLEAR,
  PROP_PUT_IMAGE_FAILURE,
  PROP_PUT_IMAGE_REQUEST,
  PROP_PUT_IMAGE_SUCCESS,
  PROP_PUT_REJECT_PROPOSALS_CLEAR,
  PROP_PUT_REJECT_PROPOSALS_FAILURE,
  PROP_PUT_REJECT_PROPOSALS_REQUEST,
  PROP_PUT_REJECT_PROPOSALS_SUCCESS,
  PROP_PUT_SUBMIT_CLEAR,
  PROP_PUT_SUBMIT_FAILURE,
  PROP_PUT_SUBMIT_REQUEST,
  PROP_PUT_SUBMIT_SUCCESS,
  PROP_RESET_ERRORS,
  PROP_RESET_FORM,
  PROP_SET_FORM,
  PROP_SET_CONTACT_MODAL,
  PROP_SET_APPROVE_MODAL,
  PROP_SET_DISCARD_MODAL,
  PROP_SET_SUBMIT_MODAL,
  PROP_SET_TMP_CONTACT_ID,
  PROP_UPDATE_CONDITIONS_CLARIFICATION,
  PROP_UPDATE_CYCLE_ID,
  PROP_UPDATE_LETTER_OF_INTEREST,
  PROP_UPDATE_MUSEUM_ID,
  PROP_UPDATE_OBJECT_ID,
  PROP_UPDATE_POC_ID,
  PROP_UPDATE_POC_TEL,
  PROP_UPDATE_SHIPPING_COST,
  PROP_UPDATE_ERRORS_DRAFT,
  PROP_UPDATE_ERRORS_SUBMIT,
  PROP_SET_SELECTED_OBJECT,
  PROP_SET_SELECTED_PROPOSAL_ID,
  PROP_SET_SELECTED_IMG,
} from '../../actions/actionTypes';

const initialProposalsState = {
  confirmApproveModalOpen: false,
  confirmDiscardModalOpen: false,
  confirmSubmitModalOpen: false,
  contactModalOpen: false,
  proposal: null,
  proposalsSimple: null,
  selectedObject: null,
  selectedProposalId: null,
  selectedImage: null,
  tmpContactId: null,
  formData: {
    id: '',
    museumId: '',
    objectId: '',
    cycleId: '',
    pointOfContactId: '',
    pointOfContactTel: '',
    letterOfInterest: '',
    images: null,
    conditionsClarification: null,
    museumRequiresDonorPayShipping: false,
  },
  formDraftErrors: {
    formError: false,
    pointOfContactTelError: false,
    conditionsClarificationError: false,
  },
  formSubmitErrors: {
    formError: false,
    pointOfContactTelError: false,
    letterOfInterestError: false,
    conditionsClarificationError: false,
  },
  deleteProposalImageRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postProposalDraftRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postProposalSubmitRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putProposalApproveRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putProposalDraftRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putProposalImageRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putProposalSubmitRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putRejectProposalsRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const proposalsReducer = (state = initialProposalsState, action) => {
  switch (action.type) {
    case PROP_CLEAR_PROP:
      return {
        ...state,
        proposal: null,
      };

    case PROP_DELETE_IMAGE_CLEAR:
      return {
        ...state,
        deleteProposalImageRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_DELETE_IMAGE_FAILURE:
      return {
        ...state,
        deleteProposalImageRequest: {
          ...state.deleteProposalImageRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_DELETE_IMAGE_REQUEST:
      return {
        ...state,
        deleteProposalImageRequest: {
          ...state.deleteProposalImageRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        deleteProposalImageRequest: {
          ...state.deleteProposalImageRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case PROP_POST_DRAFT_CLEAR:
      return {
        ...state,
        postProposalDraftRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_POST_DRAFT_FAILURE:
      return {
        ...state,
        postProposalDraftRequest: {
          ...state.postProposalDraftRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_POST_DRAFT_REQUEST:
      return {
        ...state,
        postProposalDraftRequest: {
          ...state.postProposalDraftRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_POST_DRAFT_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        postProposalDraftRequest: {
          ...state.postProposalDraftRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case PROP_PUT_APPROVE_CLEAR:
      return {
        ...state,
        putProposalApproveRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_PUT_APPROVE_FAILURE:
      return {
        ...state,
        putProposalApproveRequest: {
          ...state.putProposalApproveRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_PUT_APPROVE_REQUEST:
      return {
        ...state,
        putProposalApproveRequest: {
          ...state.putProposalApproveRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_PUT_APPROVE_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        putProposalApproveRequest: {
          ...state.putProposalApproveRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case PROP_PUT_DRAFT_CLEAR:
      return {
        ...state,
        putProposalDraftRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_PUT_DRAFT_FAILURE:
      return {
        ...state,
        putProposalDraftRequest: {
          ...state.putProposalDraftRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_PUT_DRAFT_REQUEST:
      return {
        ...state,
        putProposalDraftRequest: {
          ...state.putProposalDraftRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_PUT_DRAFT_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        putProposalDraftRequest: {
          ...state.putProposalDraftRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case PROP_PUT_IMAGE_CLEAR:
      return {
        ...state,
        putProposalImageRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_PUT_IMAGE_FAILURE:
      return {
        ...state,
        putProposalImageRequest: {
          ...state.putProposalImageRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_PUT_IMAGE_REQUEST:
      return {
        ...state,
        putProposalImageRequest: {
          ...state.putProposalImageRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_PUT_IMAGE_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        putProposalImageRequest: {
          ...state.putProposalImageRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case PROP_PUT_SUBMIT_CLEAR:
      return {
        ...state,
        putProposalSubmitRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_PUT_SUBMIT_FAILURE:
      return {
        ...state,
        putProposalSubmitRequest: {
          ...state.putProposalSubmitRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_PUT_SUBMIT_REQUEST:
      return {
        ...state,
        putProposalSubmitRequest: {
          ...state.putProposalSubmitRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_PUT_SUBMIT_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        putProposalSubmitRequest: {
          ...state.putProposalSubmitRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case PROP_RESET_ERRORS:
      return {
        ...state,
        formDraftErrors: {
          formError: false,
          pointOfContactTelError: false,
          conditionsClarificationError: false,
        },
        formSubmitErrors: {
          formError: false,
          pointOfContactTelError: false,
          letterOfInterestError: false,
          conditionsClarificationError: false,
        },
      };

    case PROP_RESET_FORM:
      return {
        ...state,
        formData: {
          id: '',
          museumId: '',
          objectId: '',
          cycleId: '',
          pointOfContactId: '',
          pointOfContactTel: '',
          letterOfInterest: '',
          images: null,
          conditionsClarification: null,
          museumRequiresDonorPayShipping: false,
        },
        formDraftErrors: {
          formError: false,
          pointOfContactTelError: false,
          conditionsClarificationError: false,
        },
        formSubmitErrors: {
          formError: false,
          pointOfContactTelError: false,
          letterOfInterestError: false,
          conditionsClarificationError: false,
        },
      };

    case PROP_SET_FORM:
      return {
        ...state,
        formData: action.payload.formData,
      };

    case PROP_SET_CONTACT_MODAL:
      return {
        ...state,
        contactModalOpen: action.payload,
      };

    case PROP_SET_APPROVE_MODAL:
      return {
        ...state,
        confirmApproveModalOpen: action.payload,
      };

    case PROP_SET_DISCARD_MODAL:
      return {
        ...state,
        confirmDiscardModalOpen: action.payload,
      };

    case PROP_SET_SUBMIT_MODAL:
      return {
        ...state,
        confirmSubmitModalOpen: action.payload,
      };

    case PROP_SET_TMP_CONTACT_ID:
      return {
        ...state,
        tmpContactId: action.payload,
      };

    case PROP_UPDATE_CONDITIONS_CLARIFICATION:
      return {
        ...state,
        formData: {
          ...state.formData,
          conditionsClarification: action.payload.conditionsClarification,
        },
      };

    case PROP_UPDATE_CYCLE_ID:
      return {
        ...state,
        formData: {
          ...state.formData,
          cycleId: action.payload.cycleId,
        },
      };

    case PROP_UPDATE_SHIPPING_COST:
      return {
        ...state,
        formData: {
          ...state.formData,
          museumRequiresDonorPayShipping: action.payload,
        },
      };

    case PROP_UPDATE_LETTER_OF_INTEREST:
      return {
        ...state,
        formData: {
          ...state.formData,
          letterOfInterest: action.payload.letterOfInterest,
        },
      };

    case PROP_UPDATE_MUSEUM_ID:
      return {
        ...state,
        formData: {
          ...state.formData,
          museumId: action.payload.museumId,
        },
      };

    case PROP_UPDATE_OBJECT_ID:
      return {
        ...state,
        formData: {
          ...state.formData,
          objectId: action.payload.objectId,
        },
      };

    case PROP_UPDATE_POC_ID:
      return {
        ...state,
        formData: {
          ...state.formData,
          pointOfContactId: action.payload.pointOfContactId,
        },
      };

    case PROP_UPDATE_POC_TEL:
      return {
        ...state,
        formData: {
          ...state.formData,
          pointOfContactTel: action.payload.pointOfContactTel,
        },
      };

    case PROP_UPDATE_ERRORS_DRAFT:
      return {
        ...state,
        formDraftErrors: action.payload.formDraftErrors,
      };

    case PROP_UPDATE_ERRORS_SUBMIT:
      return {
        ...state,
        formSubmitErrors: action.payload.formSubmitErrors,
      };

    case PROP_SET_SELECTED_OBJECT:
      return {
        ...state,
        selectedObject: action.payload,
      };

    case PROP_SET_SELECTED_PROPOSAL_ID:
      return {
        ...state,
        selectedProposalId: action.payload,
      };

    case PROP_SET_SELECTED_IMG:
      return {
        ...state,
        selectedImage: action.payload,
      };

    case PROP_PUT_REJECT_PROPOSALS_CLEAR:
      return {
        ...state,
        putRejectProposalsRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case PROP_PUT_REJECT_PROPOSALS_FAILURE:
      return {
        ...state,
        putRejectProposalsRequest: {
          ...state.putRejectProposalsRequest,
          loading: false,
          error: action.payload,
        },
      };

    case PROP_PUT_REJECT_PROPOSALS_REQUEST:
      return {
        ...state,
        putRejectProposalsRequest: {
          ...state.putRejectProposalsRequest,
          loading: true,
          error: '',
        },
      };

    case PROP_PUT_REJECT_PROPOSALS_SUCCESS:
      return {
        ...state,
        proposal: action.payload,
        putRejectProposalsRequest: {
          ...state.putRejectProposalsRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    default:
      return state;
  }
};
