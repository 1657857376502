import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { validateUserMuseumAdmin } from '../../lib';
import MuseumIcon from '../../images/header-museum.png';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/museum-settings.css';
import '../../styles/settings.css';
import '../../styles/text.css';
import { useGetMuseumAccountUserQuery } from 'requests/api';

export default function MuseumInfo() {
  const { data: museumAccount } = useGetMuseumAccountUserQuery();

  const user = useSelector(state => state.userState.user);

  const makeContent = _ => {
    const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

    if (museumAccount) {
      return (
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <div className="set-info-row set-info-row-border">
              <p className="set-label-txt">NAME</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">{museumAccount.name}</p>
                {userIsAdmin ? (
                  <Link
                    to="/institution/settings/general/name"
                    className="gen-link-no-decoration"
                  >
                    <p className="set-info-row-link">EDIT</p>
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="set-info-row set-info-row-border">
              <p className="set-label-txt">LOCATION</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">
                  {museumAccount.locationCity && museumAccount.locationState
                    ? `${museumAccount.locationCity}, ${museumAccount.locationState}`
                    : ' '}
                </p>
                {userIsAdmin ? (
                  <Link
                    to="/institution/settings/general/location"
                    className="gen-link-no-decoration"
                  >
                    <p className="set-info-row-link">EDIT</p>
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="set-info-row">
              <p className="set-label-txt">WEBSITE</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">
                  {museumAccount.websiteURL ? museumAccount.websiteURL : ' '}
                </p>
                {userIsAdmin ? (
                  <Link
                    to="/institution/settings/general/website"
                    className="gen-link-no-decoration"
                  >
                    <p className="set-info-row-link">EDIT</p>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="gen-background-nav-museum-settings">
      <div className="mcv-content mx-auto mex-mb-30">
        <div className="mcv-header d-flex">
          <img src={MuseumIcon} alt="museum" className="mas-museum-icon" />
          <p className="txt-header">Institution Information</p>
        </div>
        {makeContent()}
      </div>
    </div>
  );
}
