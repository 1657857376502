import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  authPostLoginClear,
  postUserClear,
  signupReset,
  signupSelectAccountType,
  signupUpdateEmail,
  signupUpdateFormErrors,
  signupUpdateNameFirst,
  signupUpdateNameLast,
  signupUpdatePassword,
} from '../../actions';
import { postUser, authPostLogin } from '../../requests';
import { validateEmail, validatePassword } from '../../lib';
import sha256 from 'crypto-js/sha256';
import LogoStacked from '../../images/logo-stacked.png';
import DonorIcon from '../../images/donor-icon.png';
import MuseumIcon from '../../images/museum-icon.png';
import CheckedIcon from '../../images/checked-icon.png';

class Signup extends Component {
  static get propTypes() {
    return {
      accountType: PropTypes.string,
      email: PropTypes.string,
      formErrors: PropTypes.object,
      nameFirst: PropTypes.string,
      nameLast: PropTypes.string,
      password: PropTypes.string,
      postLogin: PropTypes.func,
      postLoginClear: PropTypes.func,
      postLoginRequest: PropTypes.object,
      postUser: PropTypes.func,
      postUserClear: PropTypes.func,
      postUserRequest: PropTypes.object,
      reset: PropTypes.func,
      selectAccountType: PropTypes.func,
      updateEmail: PropTypes.func,
      updateFormErrors: PropTypes.func,
      updateNameFirst: PropTypes.func,
      updateNameLast: PropTypes.func,
      updatePassword: PropTypes.func,
      token: PropTypes.object,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.postUserClear();
    this.props.postLoginClear();
  }

  selectAccountType = accountType => {
    this.props.selectAccountType({ accountType });
  };

  handleEmailChange = event => {
    this.props.updateEmail({ email: event.target.value });
  };

  handleNameFirstChange = event => {
    this.props.updateNameFirst({ nameFirst: event.target.value });
  };

  handleNameLastChange = event => {
    this.props.updateNameLast({ nameLast: event.target.value });
  };

  handlePasswordChange = event => {
    this.props.updatePassword({ password: event.target.value });
  };

  gettingToken = false;
  getToken = _ => {
    this.props.postLogin({
      email: this.props.email,
      passwordHash: sha256(this.props.password).toString(),
    });
  };

  validateFormAndSubmitSignup = _ => {
    if (
      this.props.postUserRequest.loading ||
      this.props.postLoginRequest.loading
    ) {
      return;
    }

    const errors = {
      formError: false,
      accountTypeError: false,
      nameFirstError: false,
      nameLastError: false,
      emailError: false,
      passwordError: false,
    };

    this.setState({ errors: errors }, _ => {
      if (this.props.accountType === '') {
        errors.formError = true;
        errors.accountTypeError = true;
      }

      if (this.props.nameFirst === '') {
        errors.formError = true;
        errors.nameFirstError = true;
      }

      if (this.props.nameLast === '') {
        errors.formError = true;
        errors.nameLastError = true;
      }

      if (this.props.email === '' || !validateEmail(this.props.email)) {
        errors.formError = true;
        errors.emailError = true;
      }

      if (
        this.props.password === '' ||
        !validatePassword(this.props.password)
      ) {
        errors.formError = true;
        errors.passwordError = true;
      }

      this.props.updateFormErrors(errors);

      if (errors.formError) {
        return;
      }

      this.props.postUser({
        accountType: this.props.accountType,
        nameFirst: this.props.nameFirst,
        nameLast: this.props.nameLast,
        email: this.props.email,
        passwordHash: sha256(this.props.password).toString(),
      });
    });
  };

  render() {
    if (
      this.props.user &&
      !this.props.token &&
      !this.props.postLoginRequest.loading &&
      !this.gettingToken
    ) {
      this.gettingToken = true;
      this.getToken();
    }

    if (this.props.user && this.props.token) {
      window.location.reload(false);
    }

    return (
      <div className="mex-signup-signin-container">
        <div className="row">
          <div className="col-md-5 d-flex justify-content-center mex-signup-signin-scrollable px-0">
            <div className="signup-content">
              <img
                src={LogoStacked}
                alt="logo-stacked"
                className="gen-logo-stacked logo-signup-signin logo-signup"
              />
              <p className="header mex-mb-6">Join the platform today</p>
              <p className="text mex-mb-30">
                Begin to explore donation opportunities
              </p>
              <div className="signup-content-inner">
                <p
                  className={`label ${
                    this.props.formErrors.accountTypeError ? 'label-error' : ''
                  }`}
                >
                  ACCOUNT TYPE
                </p>
                <div className="d-flex justify-content-between mex-mb-26">
                  <a
                    onClick={e => e.preventDefault()}
                    href="# "
                    className="link-no-decoration"
                  >
                    {this.props.accountType === 'donor' && (
                      <img
                        src={CheckedIcon}
                        alt="checked-icon"
                        className="checked-icon"
                      />
                    )}
                    <div
                      className={`signup-half-row account-type-selector d-flex ${
                        this.props.accountType === 'donor'
                          ? 'account-type-selector-selected'
                          : ''
                      }`}
                      onClick={_ => this.selectAccountType('donor')}
                    >
                      <div className="mx-auto account-type-selector-content">
                        <img
                          src={DonorIcon}
                          alt="donor-icon"
                          className="account-type-selector-icon"
                        />
                        <p className="text-center account-type-selector-title">
                          Donor
                        </p>
                        <p className="text-center text-small">List objects</p>
                      </div>
                    </div>
                  </a>
                  <a
                    onClick={e => e.preventDefault()}
                    href="# "
                    className="link-no-decoration"
                  >
                    {this.props.accountType === 'museum' && (
                      <img
                        src={CheckedIcon}
                        alt="checked-icon"
                        className="checked-icon"
                      />
                    )}
                    <div
                      className={`signup-half-row account-type-selector ${
                        this.props.accountType === 'museum'
                          ? 'account-type-selector-selected'
                          : ''
                      }`}
                      onClick={_ => this.selectAccountType('museum')}
                    >
                      <div className="mx-auto account-type-selector-content">
                        <img
                          src={MuseumIcon}
                          alt="museum-icon"
                          className="account-type-selector-icon"
                        />
                        <p className="text-center account-type-selector-title">
                          Institution
                        </p>
                        <p className="text-center text-small">Select objects</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="d-flex justify-content-between mex-mb-26">
                  <div className="signup-half-row">
                    <p
                      className={`label ${
                        this.props.formErrors.nameFirstError
                          ? 'label-error'
                          : ''
                      }`}
                    >
                      FIRST NAME
                    </p>
                    <input
                      className={`input ${
                        this.props.formErrors.nameFirstError
                          ? 'input-error'
                          : ''
                      }`}
                      type="text"
                      value={this.props.nameFirst}
                      onChange={this.handleNameFirstChange}
                      placeholder="First Name"
                    ></input>
                  </div>
                  <div className="signup-half-row">
                    <p
                      className={`label ${
                        this.props.formErrors.nameLastError ? 'label-error' : ''
                      }`}
                    >
                      LAST NAME
                    </p>
                    <input
                      className={`input ${
                        this.props.formErrors.nameLastError ? 'input-error' : ''
                      }`}
                      type="text"
                      value={this.props.nameLast}
                      onChange={this.handleNameLastChange}
                      placeholder="Last Name"
                    ></input>
                  </div>
                </div>
                <div className="mex-mb-26">
                  <p
                    className={`label ${
                      this.props.formErrors.emailError ? 'label-error' : ''
                    }`}
                  >
                    EMAIL ADDRESS
                  </p>
                  <input
                    className={`input ${
                      this.props.formErrors.emailError ? 'input-error' : ''
                    }`}
                    type="text"
                    value={this.props.email}
                    onChange={this.handleEmailChange}
                    placeholder="Email Address"
                  ></input>
                </div>
                <div className="mex-mb-26">
                  <p
                    className={`label ${
                      this.props.formErrors.passwordError ? 'label-error' : ''
                    }`}
                  >
                    PASSWORD
                  </p>
                  <input
                    className={`input ${
                      this.props.formErrors.passwordError ? 'input-error' : ''
                    }`}
                    type="password"
                    value={this.props.password}
                    onChange={this.handlePasswordChange}
                    placeholder="Password"
                  ></input>
                  {this.props.formErrors.formError && (
                    <div
                      className="alert alert-danger mex-alert-danger mex-mt-9"
                      style={{ height: '42px' }}
                    >
                      Please complete the missing field(s).
                    </div>
                  )}
                  {this.props.formErrors.passwordError && (
                    <div
                      className="alert alert-danger mex-alert-danger mex-mt-9"
                      style={{ height: '110px' }}
                    >
                      Your password must be 8-32 characters long and contain one
                      upper case letter, one lower case letter, a number, and a
                      symbol.
                    </div>
                  )}
                </div>
                <button
                  className={`btn btn-primary mex-btn-primary mex-btn-primary-fill ${
                    !this.props.postLoginRequest.error &&
                    !this.props.postUserRequest.error
                      ? 'mex-mb-12'
                      : ''
                  }`}
                  style={{ width: '100%' }}
                  onClick={this.validateFormAndSubmitSignup}
                >
                  Create Account
                </button>
                {(this.props.postLoginRequest.error ||
                  this.props.postUserRequest.error) && (
                  <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                    An error has occurred. Please try again.
                  </div>
                )}
                <p className="text text-center mex-mb-30">
                  Already have an account?{' '}
                  <Link className="link" to="/login">
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-7 px-0 mex-signup-signin-right">
            <img
              src="https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://extechimagesmisc.s3.amazonaws.com/Greg+Breda_Flood_2018_detail.jpg"
              alt="greg-brenda-flood"
              className="mex-signup-signin-right-img"
            />
            <div className="mex-signup-signin-right-img-title-container">
              <p className="mex-signup-signin-right-img-title">
                Greg Breda, <i>Flood</i> (detail), 2018. Courtesy of the artist
                and PATRON, Chicago. Photo: Aron Gent.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    accountType: state.signupState.formData.accountType,
    email: state.signupState.formData.email,
    formErrors: state.signupState.formErrors,
    nameFirst: state.signupState.formData.nameFirst,
    nameLast: state.signupState.formData.nameLast,
    password: state.signupState.formData.password,
    postLoginRequest: state.authState.postLoginRequest,
    postUserRequest: state.userState.postUserRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postLogin: payload => dispatch(authPostLogin(payload)),
    postLoginClear: _ => dispatch(authPostLoginClear()),
    postUser: payload => dispatch(postUser(payload)),
    postUserClear: _ => dispatch(postUserClear()),
    reset: _ => dispatch(signupReset()),
    selectAccountType: payload => dispatch(signupSelectAccountType(payload)),
    updateEmail: payload => dispatch(signupUpdateEmail(payload)),
    updateFormErrors: payload => dispatch(signupUpdateFormErrors(payload)),
    updateNameFirst: payload => dispatch(signupUpdateNameFirst(payload)),
    updateNameLast: payload => dispatch(signupUpdateNameLast(payload)),
    updatePassword: payload => dispatch(signupUpdatePassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
