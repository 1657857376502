import {
  useGetDonorAccountActiveObjectsQuery,
  useGetDonorAccountInactiveObjectsQuery,
} from 'requests/api';

export function useDonorGetObject({ objectId, accountId }) {
  // Note: we should really add a "getObject" endpoint for donors.
  const {
    data: activeObjects,
    isLoading: isLoadingActiveObjects,
    isError: isErrorActiveObjects,
  } = useGetDonorAccountActiveObjectsQuery(accountId, {
    skip: !objectId || !accountId,
  });
  const {
    data: inactiveObjects,
    isLoading: isLoadingInactiveObjects,
    isError: isErrorInactiveObjects,
  } = useGetDonorAccountInactiveObjectsQuery(accountId, {
    skip: !objectId || !accountId,
  });

  let object = activeObjects?.find(obj => obj.id === objectId);

  if (!object) {
    object = inactiveObjects?.find(obj => obj.id === objectId);
  }

  return {
    data: object || null,
    isLoading: isLoadingActiveObjects || isLoadingInactiveObjects,
    isError: !object && (isErrorActiveObjects || isErrorInactiveObjects),
  };
}
