import {
  RESET_RESET_CONFIRM,
  RESET_RESET_SUBMIT,
  RESET_UPDATE_EMAIL,
  RESET_UPDATE_PASSWORD,
  RESET_UPDATE_RESETID,
  RESET_UPDATE_CONFIRM_FORM_ERRORS,
  RESET_UPDATE_SUBMIT_FORM_ERRORS,
} from '../../actions/actionTypes';

const initialResetState = {
  resetConfirmFormData: {
    password: '',
    resetId: '',
  },
  resetConfirmFormErrors: {
    formError: false,
    passwordError: false,
  },
  resetSubmitFormData: {
    email: '',
  },
  resetSubmitFormErrors: {
    formError: false,
    emailError: false,
  },
};

export const resetReducer = (state = initialResetState, action) => {
  switch (action.type) {
    case RESET_RESET_CONFIRM:
      return {
        ...state,
        resetConfirmFormData: {
          password: '',
        },
        resetConfirmFormErrors: {
          formError: false,
          passwordError: false,
        },
      };

    case RESET_RESET_SUBMIT:
      return {
        ...state,
        resetSubmitFormData: {
          email: '',
        },
        resetSubmitFormErrors: {
          formError: false,
          emailError: false,
        },
      };

    case RESET_UPDATE_EMAIL:
      return {
        ...state,
        resetSubmitFormData: {
          ...state.resetSubmitFormData,
          email: action.payload.email,
        },
      };

    case RESET_UPDATE_PASSWORD:
      return {
        ...state,
        resetConfirmFormData: {
          ...state.resetConfirmFormData,
          password: action.payload.password,
        },
      };

    case RESET_UPDATE_RESETID:
      return {
        ...state,
        resetConfirmFormData: {
          ...state.resetConfirmFormData,
          resetId: action.payload.resetId,
        },
      };

    case RESET_UPDATE_CONFIRM_FORM_ERRORS:
      return {
        ...state,
        resetConfirmFormErrors: action.payload,
      };

    case RESET_UPDATE_SUBMIT_FORM_ERRORS:
      return {
        ...state,
        resetSubmitFormErrors: action.payload,
      };

    default:
      return state;
  }
};
