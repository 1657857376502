import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  postTelResendClear,
  putTelClear,
  telResetConfirm,
  telUpdateCode,
  telUpdateConfirmErrors,
  userClearTel,
} from '../../actions';
import { postTelResend, putTel } from '../../requests';
import { sanitizeCode } from '../../lib';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/fields.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/spacing.css';
import '../../styles/text.css';

class MuseumUserTelVerify extends Component {
  static get propTypes() {
    return {
      clearTel: PropTypes.func,
      code: PropTypes.string,
      formErrors: PropTypes.object,
      postTelResend: PropTypes.func,
      postTelResendClear: PropTypes.func,
      postTelResendRequest: PropTypes.object,
      putTel: PropTypes.func,
      putTelClear: PropTypes.func,
      putTelRequest: PropTypes.object,
      resetConfirm: PropTypes.func,
      telObj: PropTypes.object,
      token: PropTypes.object,
      updateCode: PropTypes.func,
      updateConfirmErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.postTelResendClear();
    this.props.resetConfirm();
    this.props.putTelClear();
    this.props.clearTel();
  }

  handleResendRequest = _ => {
    if (this.props.postTelResendRequest.loading) {
      return;
    }

    this.props.postTelResend(this.props.token, this.props.telObj);
  };

  handleSaveClick = _ => {
    if (this.props.putTelRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      codeError: false,
    };

    if (sanitizeCode(this.props.code).length !== 6) {
      errors.formError = true;
      errors.codeError = true;
    }

    this.props.updateConfirmErrors({ confirmFormErrors: errors });

    if (errors.formError) {
      return;
    }

    this.props.putTel(this.props.token, {
      code: sanitizeCode(this.props.code),
      telId: this.props.telObj.id,
    });
  };

  makeContent = _ => {
    return (
      <React.Fragment>
        <div className="set-back-container d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="set-back-icon gen-cursor-hover-link"
            onClick={e => this.props.history.go(-1)}
          />
          <p className="set-back-txt">Change Mobile Number</p>
        </div>
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <p className="set-txt-copy">
              To verify your new mobile number, please enter the six-digit
              verification code we sent via SMS.
            </p>
            <div className="spc-mt-30 spc-mb-30">
              <p className="set-label-txt">VERIFICATION CODE</p>
              <input
                type="input"
                className={`set-info-edit-field ${
                  this.props.formErrors.codeError ? 'fd-input-error-field' : ''
                }`}
                maxLength="10"
                value={this.props.code}
                onChange={e => this.props.updateCode({ code: e.target.value })}
              />
            </div>
            <p className="set-txt-copy">
              If you have not received a verification code via SMS,{' '}
              <a
                href="# "
                className="gen-link"
                onClick={e => {
                  e.stopPropagation();
                  this.handleResendRequest();
                }}
              >
                clck here
              </a>{' '}
              to have it resent.
            </p>
            <div className="set-info-edit-btns-container d-flex justify-content-end">
              {this.props.putTelRequest.error ? (
                <p className="set-special-err-1">Please try again</p>
              ) : null}
              <p
                className="set-info-edit-btn-cancel"
                onClick={e => this.props.history.go(-1)}
              >
                CANCEL
              </p>
              <div
                className="set-info-edit-btn-save"
                onClick={_ => this.handleSaveClick()}
              >
                SUBMIT
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (this.props.putTelRequest.data) {
      return <Redirect to="/institution/settings/user" />;
    }

    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    code: state.telState.confirmFormData.code,
    formErrors: state.telState.confirmFormErrors,
    postTelResendRequest: state.userState.postTelResendRequest,
    putTelRequest: state.userState.putTelRequest,
    telObj: state.userState.telObj,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearTel: _ => dispatch(userClearTel()),
    postTelResend: (token, payload) => dispatch(postTelResend(token, payload)),
    postTelResendClear: _ => dispatch(postTelResendClear()),
    putTel: (token, payload) => dispatch(putTel(token, payload)),
    putTelClear: _ => dispatch(putTelClear()),
    resetConfirm: _ => dispatch(telResetConfirm()),
    updateCode: payload => dispatch(telUpdateCode(payload)),
    updateConfirmErrors: payload => dispatch(telUpdateConfirmErrors(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MuseumUserTelVerify);
