import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  mdonmReset,
  mdonmUpdateCommitteeMeeting,
  mdonmUpdateRatificationMeeting,
  mdonmUpdateInspection,
  mdonmUpdateFormErrors,
} from '../../actions';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/general.css';
import '../../styles/museum-donation-match.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import {
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
  usePutDonationMuseumPreshippingMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';
import { useMuseumGetDonation } from 'components/_shared/useMuseumGetDonation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SelectButton } from 'components/_shared/forms/SelectButton';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { INSPECTION_TYPES } from 'constants/donations';

const defaultValues = {
  inspectionType: INSPECTION_TYPES.notApplicable,
  requireLoanAgreement: true,
};

export default function MuseumDonationsPreliminaryPreshippingRequirements() {
  const { donationId } = useParams();
  const { goBack } = useHistory();

  const { data: museumAccount, isError: isErrorMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const { data: donation, isError: isErrorDonation } = useMuseumGetDonation({
    donationId,
    accountId: museumAccount?.id,
  });

  const { data: obj, isError: isErrorObj } = useGetObjMuseumQuery(
    { objectId: donation?.objectId },
    { skip: !donation },
  );

  const [
    putDonationMuseumPreshipping,
    {
      isLoading: isLoadingPutDonationMuseumPreshipping,
      isSuccess: isSuccessPutDonationMuseumPreshipping,
    },
  ] = usePutDonationMuseumPreshippingMutation();

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues,
  });

  async function onSubmit(values) {
    if (isSubmitting) return;
    if (isLoadingPutDonationMuseumPreshipping) return;

    try {
      await putDonationMuseumPreshipping({
        donationId: donation.id,
        data: values,
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  const { errors, isSubmitting } = formState;

  const inspectionType = watch('inspectionType');
  const requireLoanAgreement = watch('requireLoanAgreement');

  if (isSuccessPutDonationMuseumPreshipping) {
    if (!donation?.museumInfo?.approvalDatesSubmitted) {
      return (
        <Redirect
          push
          to={`/institution/donations/${donationId}/preliminary/approval-dates`}
        />
      );
    } else {
      return <Redirect push to={`/institution/donations/${donationId}`} />;
    }
  }

  if (isErrorDonation || isErrorMuseumAccount || isErrorObj) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mdm-header mx-auto d-flex">
          <Link to="/institution/proposals/active">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="mdm-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="mdm-header-back-container-content">
            <p className="mdm-header-title">Pre-Shipping Requirements</p>
          </div>
        </div>
        <p className="text-center mdm-error-text">
          <em>Something went wrong. Please try again.</em>
        </p>
      </div>
    );
  }

  if (obj) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mdm-header mx-auto d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="ddm-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div class-name="mdm-header-back-container-content">
            <p className="mdm-header-title">Pre-Shipping Requirements</p>
            <div className="mdm-header-text-container">
              <p className="mdm-header-text">
                Please indicate if an inspection or a loan agreement will be
                required before the object can be shipped to the institution for
                acquisition consideration.
                <br />
                <br />
                <b>Note: </b>
                The institution will be responsible for any costs related to the
                selected inspection type.
                <br />
                <br />
                <b>Note: </b>
                Unless otherwise indicated by the donor, the institution is
                responsible to insure the object wall-to-wall under its fine art
                policy against all risks of physical loss or damage while in
                transit and on location; either through a loan agreement, deed
                of gift, or fine art policy.
              </p>
            </div>
          </div>
        </div>
        <form
          className="mdm-match-two-content mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mdm-match-two-content-inner mx-auto">
            <div className="mdm-match-two-content-inner-section mx-auto">
              <div className="tw-grid tw-gap-3">
                <p
                  className={`mdm-match-two-title-text ${
                    errors.inspectionType ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Inspection
                </p>
                <input
                  type="hidden"
                  {...register('inspectionType', { required: true })}
                />
                <SelectButton
                  text="Not Applicable"
                  onSelect={() => {
                    setValue('inspectionType', INSPECTION_TYPES.notApplicable);
                  }}
                  isActive={inspectionType === INSPECTION_TYPES.notApplicable}
                />
                <SelectButton
                  text="Condition Report"
                  onSelect={() => {
                    setValue(
                      'inspectionType',
                      INSPECTION_TYPES.conditionReport,
                    );
                  }}
                  isActive={inspectionType === INSPECTION_TYPES.conditionReport}
                />
                <SelectButton
                  text={
                    <span>
                      Conservator Inspection{' '}
                      <span className="tw-ml-2 tw-italic">
                        (with a condition report)
                      </span>
                    </span>
                  }
                  onSelect={() => {
                    setValue(
                      'inspectionType',
                      INSPECTION_TYPES.conservatorInspection,
                    );
                  }}
                  isActive={
                    inspectionType === INSPECTION_TYPES.conservatorInspection
                  }
                />
                <SelectButton
                  text="Shipper Site Visit"
                  onSelect={() => {
                    setValue(
                      'inspectionType',
                      INSPECTION_TYPES.shipperSiteVisit,
                    );
                  }}
                  isActive={
                    inspectionType === INSPECTION_TYPES.shipperSiteVisit
                  }
                />
              </div>
            </div>
            <div className="mdm-match-two-content-inner-section mx-auto">
              <div className="tw-grid tw-gap-3">
                <p
                  className={`mdm-match-two-title-text ${
                    errors.requireLoanAgreement ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Loan Agreement
                </p>
                <input type="hidden" {...register('requireLoanAgreement')} />
                <SelectButton
                  text={
                    <span>
                      Required
                      <span className="text-small tw-ml-2 tw-italic">
                        (deed of gift executed AFTER shipping)
                      </span>
                    </span>
                  }
                  onSelect={() => {
                    setValue('requireLoanAgreement', true);
                  }}
                  isActive={requireLoanAgreement === true}
                />
                <SelectButton
                  text={
                    <span>
                      Not Required{' '}
                      <span className="text-small tw-ml-2 tw-italic">
                        (deed of gift executed BEFORE shipping)
                      </span>
                    </span>
                  }
                  onSelect={_ => {
                    setValue('requireLoanAgreement', false);
                  }}
                  isActive={requireLoanAgreement === false}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <input
                  type="submit"
                  disabled={isSubmitting}
                  className={`mdm-btn-next-inactive gen-cursor-hover-link tw-uppercase disabled:tw-opacity-50 ${
                    isEmpty(errors) ? 'mdm-btn-next-active' : ''
                  }`}
                  value="Submit"
                />
                {!isEmpty(errors) ? (
                  <div className="mdm-error-message-container">
                    <p className="mdm-error-message">
                      Please complete all required fields
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return <div className="gen-background-nav-museum"></div>;
}
