import { ONGOING_CYCLE_NAME } from 'constants/cycles';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';

export function selectCurrentCycle(state) {
  const isMuseum = selectIsMuseum(state);

  const findFn = isMuseum
    ? cycle => cycle.name !== ONGOING_CYCLE_NAME
    : cycle => cycle.name === ONGOING_CYCLE_NAME;

  return state.cyclesState.cyclesSorted?.current?.find(findFn);
}
