import React from 'react';
import PropTypes from 'prop-types';

export default function ContactCard({
  contact,
  purpose,
  onClick,
  onRemove,
  isSelected = false,
}) {
  if (!contact) {
    return null;
  }

  const content = (
    <div className="d-flex">
      <div className="ppm-contact-card-initials-container">
        <p className="txt-15 text-center">
          {contact.nameFirst[0].toUpperCase()}
          {contact.nameLast[0].toUpperCase()}
        </p>
      </div>
      <div>
        <div className="ppm-contact-card-name-container tw-text-left">
          <p className="ppm-contact-card-name-txt">{`${contact.nameFirst} ${contact.nameLast}`}</p>
        </div>
        <p className="ppm-contact-card-sub-txt">{contact.email}</p>
      </div>
      {onRemove && <RemoveButton onClick={onRemove} />}
    </div>
  );

  if (purpose === 'list') {
    return (
      <button
        //   TODO: ensure styles still work
        onClick={onClick}
      >
        <div
          key={`contact_card_${contact.id}`}
          className={`ppm-contact-card ppm-contact-card-list gen-cursor-hover-link ${
            isSelected ? 'ppm-contact-card-selected' : ''
          }`}
        >
          {content}
        </div>
      </button>
    );
  }

  if (purpose === 'modalDisplay') {
    return <div className="mod-ct-contact-card">{content}</div>;
  }

  return <div className="pro-edit-contact-card">{content}</div>;
}

ContactCard.propTypes = {
  contact: PropTypes.object.isRequired,
  purpose: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  isSelected: PropTypes.bool,
};

const RemoveButton = ({ onClick }) => {
  return (
    <button
      className="align-self-center ml-auto mr-3 ppm-close-btn"
      onClick={onClick}
    >
      {xIcon}
    </button>
  );
};

const xIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M13.721 4.279a.748.748 0 0 0-1.057 0L9 7.942 5.336 4.28A.748.748 0 0 0 4.28 5.336L7.942 9 4.28 12.664a.748.748 0 0 0 1.057 1.057L9 10.057l3.664 3.664a.748.748 0 1 0 1.057-1.057L10.057 9l3.664-3.664a.748.748 0 0 0 0-1.057z"
        id="nfzqcq8cma"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle id="ppm-close-circle" cx="12" cy="12" r="12" />
      <g transform="translate(3 3)">
        <mask id="j2humrdrqb" fill="#fff">
          <use xlinkHref="#nfzqcq8cma" />
        </mask>
        <use fill="#C2D1D9" xlinkHref="#nfzqcq8cma" />
        <g mask="url(#j2humrdrqb)" fill="#FFF">
          <path d="M0 0h18v18H0z" />
        </g>
      </g>
    </g>
  </svg>
);
