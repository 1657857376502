import { useGetDonationsMuseumAccountQuery } from 'requests/api';

export function useMuseumGetDonation({ donationId, accountId }) {
  // Note: we should eventually add an actual "getDonation" endpoint for museums
  const {
    data: donations,
    isLoading,
    isError,
  } = useGetDonationsMuseumAccountQuery(accountId, { skip: !accountId });

  const donation = donations?.find(donation => donation.id === donationId);

  return { data: donation || null, isLoading, isError };
}
