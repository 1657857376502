import {
  DONOR_APPLICATION_RESET,
  DONOR_APPLICATION_UPDATE_CITY,
  DONOR_APPLICATION_UPDATE_CONSENT,
  DONOR_APPLICATION_UPDATE_ERRORS,
  DONOR_APPLICATION_UPDATE_PROFESSION,
  DONOR_APPLICATION_UPDATE_STATE,
  DONOR_APPLICATION_UPDATE_TYPE,
} from '../actionTypes';

export const donorApplicationReset = _ => {
  return {
    type: DONOR_APPLICATION_RESET,
  };
};

export const donorApplicationUpdateCity = payload => {
  return {
    type: DONOR_APPLICATION_UPDATE_CITY,
    payload,
  };
};

export const donorApplicationUpdateConsent = payload => {
  return {
    type: DONOR_APPLICATION_UPDATE_CONSENT,
    payload,
  };
};

export const donorApplicationUpdateErrors = payload => {
  return {
    type: DONOR_APPLICATION_UPDATE_ERRORS,
    payload,
  };
};

export const donorApplicationUpdateProfession = payload => {
  return {
    type: DONOR_APPLICATION_UPDATE_PROFESSION,
    payload,
  };
};

export const donorApplicationUpdateState = payload => {
  return {
    type: DONOR_APPLICATION_UPDATE_STATE,
    payload,
  };
};

export const donorApplicationUpdateType = payload => {
  return {
    type: DONOR_APPLICATION_UPDATE_TYPE,
    payload,
  };
};
