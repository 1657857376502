import {
  TEL_RESET_ADD,
  TEL_RESET_CONFIRM,
  TEL_UPDATE_ADD_ERRORS,
  TEL_UPDATE_CODE,
  TEL_UPDATE_CONFIRM_ERRORS,
  TEL_UPDATE_CONSENT,
  TEL_UPDATE_TEL,
} from '../../actions/actionTypes';

const initialTelState = {
  addFormData: {
    tel: '',
    consentTel: false,
  },
  addFormErrors: {
    formError: false,
    telError: false,
    consentTelError: false,
  },
  confirmFormData: {
    code: '',
  },
  confirmFormErrors: {
    formError: false,
    codeError: false,
  },
};

export const telReducer = (state = initialTelState, action) => {
  switch (action.type) {
    case TEL_RESET_ADD:
      return {
        ...state,
        addFormData: {
          tel: '',
          consentTel: false,
        },
        addFormErrors: {
          formError: false,
          telError: false,
        },
      };

    case TEL_RESET_CONFIRM:
      return {
        ...state,
        confirmFormData: {
          tel: '',
        },
        confirmFormErrors: {
          formError: false,
          codeError: false,
        },
      };

    case TEL_UPDATE_ADD_ERRORS:
      return {
        ...state,
        addFormErrors: action.payload.addFormErrors,
      };

    case TEL_UPDATE_CONFIRM_ERRORS:
      return {
        ...state,
        confirmFormErrors: action.payload.confirmFormErrors,
      };

    case TEL_UPDATE_CODE:
      return {
        ...state,
        confirmFormData: {
          ...state.confirmFormData,
          code: action.payload.code,
        },
      };

    case TEL_UPDATE_TEL:
      return {
        ...state,
        addFormData: {
          ...state.addFormData,
          tel: action.payload.tel,
        },
      };

    case TEL_UPDATE_CONSENT:
      return {
        ...state,
        addFormData: {
          ...state.addFormData,
          consentTel: action.payload.consentTel,
        },
      };

    default:
      return state;
  }
};
