export const validateMuseumHasAdmin = museum => {
  let hasAdmin = false;
  if (typeof museum === 'object' && Array.isArray(museum.users)) {
    museum.users.forEach(u => {
      if (u.role === 'owner' || u.role === 'admin') {
        hasAdmin = true;
      }
    });
  }
  return hasAdmin;
};
