import {
  CONFIRM_EMAIL_RESET,
  CONFIRM_EMAIL_UPDATE_EMAIL,
  CONFIRM_EMAIL_UPDATE_EMAIL_ID,
  CONFIRM_EMAIL_UPDATE_FORM_ERRORS,
  CONFIRM_EMAIL_UPDATE_LOADING,
  CONFIRM_EMAIL_UPDATE_PASSWORD,
  CONFIRM_EMAIL_PUT_CONFIRM_CLEAR,
  CONFIRM_EMAIL_PUT_CONFIRM_FAILURE,
  CONFIRM_EMAIL_PUT_CONFIRM_REQUEST,
  CONFIRM_EMAIL_PUT_CONFIRM_SUCCESS,
} from '../../actions/actionTypes';

export const confirmEmailReset = _ => {
  return {
    type: CONFIRM_EMAIL_RESET,
  };
};

export const confirmEmailUpdateEmail = payload => {
  return {
    type: CONFIRM_EMAIL_UPDATE_EMAIL,
    payload,
  };
};

export const confirmEmailUpdateEmailId = payload => {
  return {
    type: CONFIRM_EMAIL_UPDATE_EMAIL_ID,
    payload,
  };
};

export const confirmEmailUpdateLoading = payload => {
  return {
    type: CONFIRM_EMAIL_UPDATE_LOADING,
    payload,
  };
};

export const confirmEmailUpdatePassword = payload => {
  return {
    type: CONFIRM_EMAIL_UPDATE_PASSWORD,
    payload,
  };
};

export const confirmEmailUpdateFormErrors = payload => {
  return {
    type: CONFIRM_EMAIL_UPDATE_FORM_ERRORS,
    payload,
  };
};

export const confirmEmailPutConfirmClear = _ => {
  return {
    type: CONFIRM_EMAIL_PUT_CONFIRM_CLEAR,
  };
};

export const confirmEmailPutConfirmFailure = payload => {
  return {
    type: CONFIRM_EMAIL_PUT_CONFIRM_FAILURE,
    payload,
  };
};

export const confirmEmailPutConfirmRequest = _ => {
  return {
    type: CONFIRM_EMAIL_PUT_CONFIRM_REQUEST,
  };
};

export const confirmEmailPutConfirmSuccess = payload => {
  return {
    type: CONFIRM_EMAIL_PUT_CONFIRM_SUCCESS,
    payload,
  };
};
