import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT, IMAGE_UPLOAD_TIMEOUT } from '../../config';
import {
  propDeleteImageFailure,
  propDeleteImageRequest,
  propDeleteImageSuccess,
  propPostDraftFailure,
  propPostDraftRequest,
  propPostDraftSuccess,
  propPutApproveFailure,
  propPutApproveRequest,
  propPutApproveSuccess,
  propPutDraftFailure,
  propPutDraftRequest,
  propPutDraftSuccess,
  propPutImageFailure,
  propPutImageRequest,
  propPutImageSuccess,
  propPutRejectProposalsFailure,
  propPutRejectProposalsRequest,
  propPutRejectProposalsSuccess,
  propPutSubmitFailure,
  propPutSubmitRequest,
  propPutSubmitSuccess,
  propSetForm,
} from '../../actions';
import { parseProposalIntoFormData } from '../../glue';
import { api, API_TAGS } from 'requests/api';

export const deleteProposalImage = (token, proposalId, imgId) => {
  return dispatch => {
    dispatch(propDeleteImageRequest());
    axios({
      method: 'DELETE',
      url: `${BASE_URL}/proposals/${proposalId}/images/${imgId}`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propDeleteImageSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
      })
      .catch(error => {
        dispatch(propDeleteImageFailure(error.message));
      });
  };
};

export const postProposalDraft = (token, data) => {
  return dispatch => {
    dispatch(propPostDraftRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/proposals/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPostDraftSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
      })
      .catch(error => {
        dispatch(propPostDraftFailure(error.message));
      });
  };
};

// export const postProposalSubmit = (token, data) => {
//   return dispatch => {
//     dispatch(propPostSubmitRequest());
//     axios({
//       method: 'POST',
//       url: `${BASE_URL}/proposals/drafts/submit`,
//       timeout: DEFAULT_TIMEOUT,
//       data: JSON.stringify(data),
//       headers: {
//         'content-type': 'application/json',
//         'x-api-token': token.id,
//       },
//     })
//       .then(response => {
//         dispatch(propPostSubmitSuccess(response.data));
//         dispatch(
//           propSetForm({ formData: parseProposalIntoFormData(response.data) }),
//         );
//       })
//       .catch(error => {
//         dispatch(propPostSubmitFailure(error.message));
//       });
//   };
// };

export const putProposalApprove = (token, proposalId, isMuseum) => {
  return dispatch => {
    dispatch(propPutApproveRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/proposals/${proposalId}/approve`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutApproveSuccess(response.data));

        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );

        if (!isMuseum) {
          // Note: if account type is "other", we need to refetch objects to get the new donor review dates after a proposal has been submitted.
          dispatch(api.util.invalidateTags([API_TAGS.MuseumObjects]));
        }
      })
      .catch(error => {
        dispatch(propPutApproveFailure(error.message));
      });
  };
};

export const putProposalImage = (token, proposalId, formData) => {
  return dispatch => {
    dispatch(propPutImageRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/proposals/${proposalId}/images`,
      timeout: IMAGE_UPLOAD_TIMEOUT,
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutImageSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
      })
      .catch(error => {
        dispatch(propPutImageFailure(error));
      });
  };
};

export const putProposalDraft = (token, proposalId, data) => {
  return dispatch => {
    dispatch(propPutDraftRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/proposals/${proposalId}/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutDraftSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
      })
      .catch(error => {
        dispatch(propPutDraftFailure(error.message));
      });
  };
};

export const putProposalDraftAndDeleteProposalImage = (
  token,
  proposalId,
  data,
  imageId,
) => {
  return dispatch => {
    dispatch(propPutDraftRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/proposals/${proposalId}/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutDraftSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
        dispatch(deleteProposalImage(token, proposalId, imageId));
      })
      .catch(error => {
        dispatch(propPutDraftFailure(error.message));
      });
  };
};

export const putProposalDraftAndPutProposalImage = (
  token,
  proposalId,
  data,
  formData,
) => {
  return dispatch => {
    dispatch(propPutDraftRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/proposals/${proposalId}/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutDraftSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
        dispatch(putProposalImage(token, proposalId, formData));
      })
      .catch(error => {
        dispatch(propPutDraftFailure(error.message));
      });
  };
};

export const putProposalSubmit = (token, proposalId, data) => {
  return dispatch => {
    dispatch(propPutSubmitRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/proposals/${proposalId}/drafts/submit`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutSubmitSuccess(response.data));
        dispatch(
          propSetForm({ formData: parseProposalIntoFormData(response.data) }),
        );
      })
      .catch(error => {
        dispatch(propPutSubmitFailure(error.message));
      });
  };
};

export const putRejectProposals = (token, { objectId, proposals }) => {
  return dispatch => {
    dispatch(propPutRejectProposalsRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/objects/${objectId}/proposals`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ reject: proposals }),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(propPutRejectProposalsSuccess(true));
      })
      .catch(error => {
        dispatch(propPutRejectProposalsFailure(error.message));
      });
  };
};
