import {
  DPROP_CLEAR_PROPOSALS,
  DPROP_GET_PROPOSALS_ACCOUNT_CLEAR,
  DPROP_GET_PROPOSALS_ACCOUNT_FAILURE,
  DPROP_GET_PROPOSALS_ACCOUNT_REQUEST,
  DPROP_GET_PROPOSALS_ACCOUNT_SUCCESS,
  DPROP_SET_ACCEPT_MODAL,
  DPROP_SET_SELECTED_IMAGE,
  DPROP_SET_SELECTED_PROP_INDEX,
  DPROP_SET_SELECTED_PROPS,
} from '../../actions/actionTypes';

const initialDonorProposalsState = {
  acceptModalOpen: false,
  proposals: null,
  selectedImage: null,
  selectedPropIndex: null,
  selectedProps: null,
  getProposalsAccountRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const donorProposalsReducer = (
  state = initialDonorProposalsState,
  action,
) => {
  switch (action.type) {
    case DPROP_CLEAR_PROPOSALS:
      return {
        ...state,
        proposals: null,
      };

    case DPROP_GET_PROPOSALS_ACCOUNT_CLEAR:
      return {
        ...state,
        getProposalsAccountRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case DPROP_GET_PROPOSALS_ACCOUNT_FAILURE:
      return {
        ...state,
        getProposalsAccountRequest: {
          ...state.getProposalsAccountRequest,
          loading: false,
          error: action.payload,
        },
      };

    case DPROP_GET_PROPOSALS_ACCOUNT_REQUEST:
      return {
        ...state,
        getProposalsAccountRequest: {
          ...state.getProposalsAccountRequest,
          loading: true,
          error: '',
        },
      };

    case DPROP_GET_PROPOSALS_ACCOUNT_SUCCESS:
      return {
        ...state,
        proposals: action.payload,
        getProposalsAccountRequest: {
          ...state.getProposalsAccountRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case DPROP_SET_ACCEPT_MODAL:
      return {
        ...state,
        acceptModalOpen: action.payload,
      };

    case DPROP_SET_SELECTED_IMAGE:
      return {
        ...state,
        selectedImage: action.payload,
      };

    case DPROP_SET_SELECTED_PROP_INDEX:
      return {
        ...state,
        selectedPropIndex: action.payload,
      };

    case DPROP_SET_SELECTED_PROPS:
      return {
        ...state,
        selectedProps: action.payload,
      };

    default:
      return state;
  }
};
