import BackIcon from '../../images/arrow-back.png';
import Lightbox from 'react-image-lightbox';
import { React, useState } from 'react';
import {
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
} from 'requests/api';
import { MEETING_TYPES, DONATION_YEAR_TYPES } from 'constants/donations';
import { dimensionsMapping } from '../../glue';
import { getDonationDonorNameText } from 'lib/getDonorNameText';
import { useDonorGetDonation } from './useDonorGetDonation';
import { useDonorGetObject } from './useDonorGetObject';
import { useHistory } from 'react-router-dom';
import { useMuseumGetDonation } from 'components/_shared/useMuseumGetDonation';
import { useParams } from 'react-router-dom';

export function DonorDonationDetails() {
  const { donationId, accountId } = useParams();

  const { data: donation } = useDonorGetDonation({ donationId, accountId });

  const { data: object } = useDonorGetObject({
    objectId: donation?.objectId,
    accountId,
  });

  return <DonationDetails donation={donation} object={object} />;
}

export function MuseumDonationDetails() {
  const { donationId } = useParams();

  const { data: museumAccount } = useGetMuseumAccountUserQuery();

  const { data: donation } = useMuseumGetDonation({
    donationId,
    accountId: museumAccount?.id,
  });

  const { data: object } = useGetObjMuseumQuery(
    { objectId: donation?.objectId },
    { skip: !donation },
  );

  return <DonationDetails donation={donation} object={object} />;
}

function DonationDetails({ donation, object }) {
  const { goBack } = useHistory();
  const [lightboxOpen, setLightboxOpen] = useState(false);

  if (object && donation) {
    return (
      <>
        <div className="gen-background-nav-museum">
          <div className="d-header mx-auto">
            <div className="d-flex">
              <img
                src={BackIcon}
                alt="back-arrow"
                className="d-header-icon-back gen-cursor-hover-link"
                onClick={e => goBack()}
              />
              <div class-name="d-header-back-container-content">
                <p className="d-header-title">Donation Details</p>
              </div>
            </div>
          </div>
          <div className="tw-mx-auto tw-w-[892px]">
            <div className="tw-grid tw-gap-10 tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#e7ebec] tw-p-7">
              <DetailCard title="Approval Dates">
                <DetailRow
                  title="Preliminary Approval"
                  content={getPreliminaryApprovalText({ donation })}
                />
                <DetailRow
                  title="Ratification"
                  content={getRatificationText({ donation })}
                />
              </DetailCard>
              <DetailCard title="Donor Information">
                <DetailRow
                  title="Donor Name"
                  content={getDonationDonorNameText({ donation })}
                />
                <DetailRow
                  title="Donor Primary Address"
                  content={<DonationDonorAddress donation={donation} />}
                />
                <DetailRow
                  title="Credit Line"
                  content={donation.donorInfo.creditLine}
                />
                <DetailRow
                  title="Donation Year"
                  content={getDonationDonorDonationYearText({ donation })}
                />
              </DetailCard>
              <DetailCard title="Pick Up Information">
                <DetailRow
                  title="Pick Up Address"
                  content={<DonationDonorPickUpAddress donation={donation} />}
                />
                <DetailRow
                  title="Pick Up Contact"
                  content={<DonationDonorPickUpContact donation={donation} />}
                />
                <DetailRow
                  title="General Availability"
                  content={donation.donorInfo?.shippingDescription}
                />
                <DetailRow
                  title="Cost of Shipping"
                  content={
                    donation.museumRequiresDonorPayShipping
                      ? 'As a condition of the proposal, the institution asks that the cost of shipping be covered by the donor. Generally, this can be made as a tax-deductible charitable contribution to the institution.'
                      : 'The cost of shipping will be covered by the institution'
                  }
                  isHidden={donation.museumRequiresDonorPayShipping == null}
                />
              </DetailCard>
              <DetailCard title="Object Information">
                <DetailRow
                  title="Image"
                  content={
                    <button
                      onClick={e => {
                        e.preventDefault();
                        setLightboxOpen(true);
                      }}
                    >
                      <img
                        src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${object?.primaryImage?.bucket}.s3.amazonaws.com/${object?.primaryImage?.imageId}`}
                        alt={`${object?.id}`}
                        className="mcv-obj-lst-card-img"
                      />
                    </button>
                  }
                />
                <DetailRow title="Artist" content={object?.artistName} />
                <DetailRow title="Title" content={object?.title} />
                <DetailRow title="Year" content={object?.year} />
                <DetailRow title="Medium" content={object?.medium} />
                <DetailRow
                  title="Dimensions"
                  content={<ObjectDimensions object={object} />}
                />
                {object?.edition && (
                  <DetailRow title="Edition" content={object.edition} />
                )}
                <DetailRow title="Condition" content={object?.condition} />
                <DetailRow title="Provenance" content={object?.provenance} />
                {object?.restrictions && (
                  <DetailRow
                    title="Donor Conditions"
                    content={object.restrictions}
                  />
                )}
              </DetailCard>
              <DetailCard title="Packing Information">
                <DetailRow
                  title="Framed Dimensions"
                  content={
                    <DonationFramedObjectDimensions
                      donation={donation}
                      object={object}
                    />
                  }
                />
                <DetailRow
                  title="Object Weight"
                  content={`${donation.objectInfo.weight?.weightImperial} lb (${donation.objectInfo.weight?.weightMetric} kg)`}
                  isHidden={!donation.objectInfo.weight}
                />
                <DetailRow
                  title="Packing"
                  content={<DonationPacking donation={donation} />}
                />
                <DetailRow
                  title="Packed Dimensions"
                  content={
                    <DonationObjectPackedDimensions donation={donation} />
                  }
                />
                <DetailRow
                  title="Packing On Site"
                  content={
                    donation.objectInfo?.canPackOnSite === true
                      ? 'Shipper can pack the object on site'
                      : 'Shipper cannot pack the object on site'
                  }
                  isHidden={donation.objectInfo?.canPackOnSite == null}
                />
                {donation?.donorInfo?.shippingInstructions ? (
                  <DetailRow
                    title="Packing Notes"
                    content={donation.donorInfo.shippingInstructions}
                  />
                ) : null}
                <DetailRow
                  title="Condition"
                  content={donation.objectInfo.condition}
                  isHidden={!donation.objectInfo.condition}
                />
                <DetailRow
                  title="Insurance Value"
                  content={getDonationObjectInsuranceValueText({ donation })}
                />
              </DetailCard>
            </div>
          </div>
        </div>
        {lightboxOpen && (
          <Lightbox
            mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${object?.primaryImage?.bucket}.s3.amazonaws.com/${object?.primaryImage?.imageId}`}
            onCloseRequest={_ => setLightboxOpen(false)}
            imagePadding={65}
            clickOutsideToClose={false}
          />
        )}
      </>
    );
  }

  return (
    <div className="gen-background-nav-museum">
      <div className="d-header mx-auto d-flex">
        <img
          src={BackIcon}
          alt="back-arrow"
          className="d-header-icon-back gen-cursor-hover-link"
          onClick={e => goBack()}
        />
        <div class-name="d-header-back-container-content">
          <p className="d-header-title">Donation Details</p>
        </div>
      </div>
    </div>
  );
}

function DetailCard({ title, children }) {
  return (
    <div className="tw-rounded-sm tw-shadow-lg">
      <div className="tw-bg-[#e8eeff] tw-py-4 tw-px-24">
        <h2 className="tw-mb-0 tw-text-lg tw-uppercase">{title}</h2>
      </div>
      <div className="tw-grid tw-gap-6 tw-bg-white tw-py-6 tw-px-24">
        {children}
      </div>
    </div>
  );
}

function DetailRow({ title, content, isHidden }) {
  if (isHidden) return null;
  return (
    <div className="tw-grid tw-grid-cols-3">
      <div className="tw-w-[120px] tw-text-right tw-text-sm tw-uppercase">
        {title}
      </div>
      <div className="tw-col-span-2 tw-text-sm tw-text-[#69707f]">
        {content}
      </div>
    </div>
  );
}

function getDonationDonorDonationYearText({ donation }) {
  if (!donation.donorInfo.donationYear) return 'No Preference';
  if (!donation.donorInfo.donationYear.yearDisplayText) return 'No Preference';
  if (
    [
      DONATION_YEAR_TYPES.currentYear,
      DONATION_YEAR_TYPES.nextYear,
      DONATION_YEAR_TYPES.noPreference,
    ].includes(donation.donorInfo.donationYear.type)
  ) {
    return donation.donorInfo.donationYear.yearDisplayText;
  }

  return 'No Preference';
}

function getDonationObjectInsuranceValueText({ donation }) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(donation.objectInfo.insuranceValue / 100);
}

function ObjectDimensions({ object }) {
  if (!object) return null;

  const elements = [];
  dimensionsMapping.forEach((v, i) => {
    if (object.dimensions[v.key]) {
      if (v.key === 'dimensionsVariable' || v.key === 'duration') {
        elements.push(null);
      } else {
        const dimensions = object.dimensions[v.key];

        elements.push(
          <div key={`dimensions_elem_${i}`} className="tw-flex tw-gap-4">
            <p className="tw-text-gray-700">{v.display}</p>
            <div className="tw-flex tw-gap-2">
              <p className="tw-text-gray-500">
                {`${dimensions.heightImperial} x ${dimensions.widthImperial}${
                  dimensions.depthImperial
                    ? ` x ${dimensions.depthImperial}`
                    : ``
                } in`}
              </p>
              <p className="tw-text-gray-400">
                {`(${dimensions.heightMetric} x ${dimensions.widthMetric}${
                  dimensions.depthMetric ? ` x ${dimensions.depthMetric}` : ``
                } cm)`}
              </p>
            </div>
          </div>,
        );
      }
    }
  });

  if (elements.length) {
    return <div className="tw-grid tw-gap-3">{elements}</div>;
  } else {
    return null;
  }
}

function DonationDonorAddress({ donation }) {
  return (
    <>
      {donation.donorInfo.donorAddress?.lineOne}
      <br />
      {donation.donorInfo.donorAddress?.lineTwo ? (
        <>
          {donation.donorInfo.donorAddress?.lineTwo}
          <br />
        </>
      ) : null}
      {donation.donorInfo.donorAddress?.city},{' '}
      {donation.donorInfo.donorAddress?.state}{' '}
      {donation.donorInfo.donorAddress?.zip}
      <br />
      {donation.donorInfo.donorAddress?.country}
    </>
  );
}
function DonationDonorPickUpAddress({ donation }) {
  return (
    <>
      {donation.donorInfo.shippingAddress?.company ? (
        <>
          {donation.donorInfo.shippingAddress?.company}
          <br />
        </>
      ) : null}
      {donation.donorInfo.shippingAddress?.lineOne}
      <br />
      {donation.donorInfo.shippingAddress?.lineTwo ? (
        <>
          {donation.donorInfo.shippingAddress?.lineTwo}
          <br />
        </>
      ) : null}
      {donation.donorInfo.shippingAddress?.city},{' '}
      {donation.donorInfo.shippingAddress?.state}{' '}
      {donation.donorInfo.shippingAddress?.zip}
      <br />
      {donation.donorInfo.shippingAddress?.country}
    </>
  );
}

function DonationDonorPickUpContact({ donation }) {
  return (
    <div>
      {donation.donorInfo?.shippingContact?.nameFirst}{' '}
      {donation.donorInfo?.shippingContact?.nameLast}
      <br />
      {donation.donorInfo?.shippingContact?.tel ? (
        <>
          {donation.donorInfo?.shippingContact?.tel}
          <br />
        </>
      ) : null}
      {donation.donorInfo?.shippingContact?.email}
    </div>
  );
}

function DonationFramedObjectDimensions({ donation, object }) {
  if (!object) return null;
  if (!donation) return null;
  if (!donation.objectInfo.frame) {
    return (
      <div className="tw-grid tw-gap-3">
        <div key={`dimensions_framed`} className="tw-flex tw-gap-4">
          <p className="tw-text-gray-700">N/A</p>
        </div>
      </div>
    );
  }

  const dimensions = donation.objectInfo.frame;
  return (
    <div className="tw-grid tw-gap-3">
      <div key={`dimensions_framed`} className="tw-flex tw-gap-4">
        <div className="tw-flex tw-gap-2">
          <p className="tw-text-gray-500">
            {`${dimensions.heightImperial} x ${dimensions.widthImperial}${
              dimensions.depthImperial ? ` x ${dimensions.depthImperial}` : ``
            } in`}
          </p>
          <p className="tw-text-gray-400">
            {`(${dimensions.heightMetric} x ${dimensions.widthMetric}${
              dimensions.depthMetric ? ` x ${dimensions.depthMetric}` : ``
            } cm)`}
          </p>
        </div>
      </div>
    </div>
  );
}

function DonationObjectPackedDimensions({ donation }) {
  return (
    <>
      {donation.objectInfo.crate ? (
        <div className="d-flex tw-gap-2">
          <p className="d-shipping-value-txt">
            {`${donation.objectInfo.crate.heightImperial} x ${
              donation.objectInfo.crate.widthImperial
            }${
              donation.objectInfo.crate.depthImperial
                ? ` x ${donation.objectInfo.crate.depthImperial}`
                : ``
            } in`}
          </p>
          <p className="d-shipping-value-txt-lt">
            {`(${donation.objectInfo.crate.heightMetric} x ${
              donation.objectInfo.crate.widthMetric
            }${
              donation.objectInfo.crate.depthMetric
                ? ` x ${donation.objectInfo.crate.depthMetric}`
                : ``
            } cm)`}
          </p>
        </div>
      ) : (
        <div className="tw-grid tw-gap-3">
          <div key={`dimensions_packed`} className="tw-flex tw-gap-4">
            <p className="tw-text-gray-700">N/A</p>
          </div>
        </div>
      )}
    </>
  );
}

function DonationPacking({ donation }) {
  if (donation.objectInfo?.crate) return 'Object is packed';

  return 'Not packed';
}

function getPreliminaryApprovalText({ donation }) {
  switch (donation.museumInfo.committeeMeetingType) {
    case MEETING_TYPES.notApplicable: {
      return 'Not Applicable';
    }
    case MEETING_TYPES.unspecified: {
      return 'Unspecified';
    }
    case MEETING_TYPES.specified: {
      return donation.museumInfo.committeeMeetingDate;
    }
    default: {
      return 'Not Applicable';
    }
  }
}

function getRatificationText({ donation }) {
  switch (donation.museumInfo.ratificationMeetingType) {
    case MEETING_TYPES.notApplicable: {
      return 'Not Applicable';
    }
    case MEETING_TYPES.unspecified: {
      return 'Unspecified';
    }
    case MEETING_TYPES.specified: {
      return donation.museumInfo.ratificationMeetingDate;
    }
    default: {
      return 'Not Applicable';
    }
  }
}
