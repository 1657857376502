import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  objDraftPostClear,
  objDraftPutClear,
  objDraftUpdateRestrictions,
  objDraftUpdateRestrictionsDescription,
  objDraftUpdateCity,
  objDraftUpdateState,
  objDraftUpdateZip,
  objDraftUpdateDraftErrors,
  objDraftUpdateSubmitErrors,
} from '../../actions';
import { postObjectDraft, putObjectDraft } from '../../requests';
import {
  makeObjectDraftPayload,
  validateFormDraft,
  validateFormSubmit,
} from '../../glue';
import { BackToObjectsLink, NextDestination } from './ObjectDraftOne';

class ObjectDraftThree extends Component {
  static get propTypes() {
    return {
      formData: PropTypes.object,
      formDraftErrors: PropTypes.object,
      formSubmitAttempted: PropTypes.bool,
      formSubmitErrors: PropTypes.object,
      postObjectDraft: PropTypes.func,
      postObjectDraftClear: PropTypes.func,
      postObjectDraftRequest: PropTypes.object,
      putObjectDraft: PropTypes.func,
      putObjectDraftClear: PropTypes.func,
      putObjectDraftRequest: PropTypes.object,
      token: PropTypes.object,
      updateRestrictions: PropTypes.func,
      updateRestrictionsDescription: PropTypes.func,
      updateCity: PropTypes.func,
      updateState: PropTypes.func,
      updateZip: PropTypes.func,
      updateDraftErrors: PropTypes.func,
      updateSubmitErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
  }

  componentWillUnmount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
  }

  handleUpdateRestrictions = restrictions => {
    this.props.updateRestrictions({
      restrictions: Boolean(Number(restrictions)),
    });
    this.props.updateRestrictionsDescription({ restrictionsDescription: '' });
  };

  handleUpdateRestrictionsDescription = restrictionsDescription => {
    this.props.updateRestrictionsDescription({ restrictionsDescription });
  };

  handleUpdateCity = locationCity => {
    this.props.updateCity({ locationCity });
  };

  handleUpdateState = locationState => {
    this.props.updateState({ locationState });
  };

  handleUpdateZip = event => {
    if (event.target.value.length <= 7) {
      this.props.updateZip({ locationZip: event.target.value });
    }
  };

  destination = '';
  handleNextStepClick = _ => {
    if (
      this.props.postObjectDraftRequest.loading ||
      this.props.putObjectDraftRequest.loading
    ) {
      return;
    }

    const formDraftErrors = validateFormDraft(this.props.formData);
    this.props.updateDraftErrors({ formDraftErrors });

    if (this.props.formSubmitAttempted) {
      const formSubmitErrors = validateFormSubmit(this.props.formData);
      this.props.updateSubmitErrors({ formSubmitErrors });
    }

    if (formDraftErrors.formError) {
      return;
    }

    let payload = {};
    try {
      payload = makeObjectDraftPayload(this.props.formData);
    } catch (e) {
      console.error(e);
      return;
    }

    if (this.props.formData.id) {
      this.props.putObjectDraft(
        this.props.token,
        this.props.formData.id,
        payload,
      );
    } else {
      this.props.postObjectDraft(this.props.token, payload, this.props.user.id);
    }
  };

  render() {
    const { accountId } = this.props.match.params;
    if (
      (this.props.postObjectDraftRequest.data &&
        !this.props.postObjectDraftRequest.loading &&
        !this.props.postObjectDraftRequest.error) ||
      (this.props.putObjectDraftRequest.data &&
        !this.props.putObjectDraftRequest.loading &&
        !this.props.putObjectDraftRequest.error)
    ) {
      return (
        <NextDestination destination={this.destination} nextPageNumber={4} />
      );
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <div className="back-to-folders-link-container">
          <BackToObjectsLink />
        </div>
        <p className="header text-center mex-mt-47">Submit a New Object</p>
        <div className="sub-header sub-header-wide mx-auto mex-mb-30">
          <p className="text text-center">
            Please complete this form to list your object for donation
            consideration on Museum Exchange
          </p>
        </div>
        <div className="new-object-steps-container mx-auto d-flex justify-content-between mex-mb-26">
          <Link
            className="link-no-decoration"
            to={`/donor/folders/${accountId}/object/draft/1`}
          >
            <div
              className={`new-object-step new-object-step-complete d-flex justify-content-center align-items-center ${
                this.props.formDraftErrors.errorPages.one ||
                this.props.formSubmitErrors.errorPages.one
                  ? 'new-object-step-error'
                  : ''
              }`}
            >
              <p
                className={`new-object-step-text ${
                  this.props.formDraftErrors.errorPages.one ||
                  this.props.formSubmitErrors.errorPages.one
                    ? 'new-object-step-current-text'
                    : ''
                }`}
              >
                OBJECT DETAILS
              </p>
            </div>
          </Link>
          <Link
            className="link-no-decoration"
            to={`/donor/folders/${accountId}/object/draft/2`}
          >
            <div
              className={`new-object-step new-object-step-complete d-flex justify-content-center align-items-center ${
                this.props.formSubmitErrors.errorPages.two
                  ? 'new-object-step-error'
                  : ''
              }`}
            >
              <p
                className={`new-object-step-text ${
                  this.props.formSubmitErrors.errorPages.two
                    ? 'new-object-step-current-text'
                    : ''
                }`}
              >
                CATALOGUING
              </p>
            </div>
          </Link>
          <div
            className={`new-object-step new-object-step-current d-flex justify-content-center align-items-center ${
              this.props.formDraftErrors.errorPages.three ||
              this.props.formSubmitErrors.errorPages.three
                ? 'new-object-step-error'
                : ''
            }`}
          >
            <p className="new-object-step-text new-object-step-current-text">
              DONATION INFORMATION
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p
              className={`new-object-step-text ${
                this.props.formSubmitErrors.errorPages.four
                  ? 'new-object-step-error-text'
                  : ''
              }`}
            >
              UPLOAD IMAGES
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p className="new-object-step-text">REVIEW & SUBMIT</p>
          </div>
        </div>
        <div className="new-object-content-container d-flex justify-content-center mx-auto">
          <div className="new-object-content">
            <div className="new-object-content-inner mx-auto">
              <div className="new-object-content-inner mx-auto">
                <div className="mex-mb-30">
                  <p
                    className={`label-bold mex-mb-6 ${
                      this.props.formDraftErrors.restrictionsError ||
                      this.props.formSubmitErrors.restrictionsError
                        ? 'label-error'
                        : ''
                    }`}
                  >
                    Donor Conditions
                  </p>
                  <p
                    className="title-small mex-mb-12"
                    style={{ width: '371px' }}
                  >
                    Describe any conditions institutions must meet to receive
                    the donation.
                  </p>
                  <a href="# " className="link-no-decoration">
                    <div
                      className={`${
                        !this.props.formData.restrictions
                          ? 'new-object-restriction-selector-selected'
                          : 'new-object-restriction-selector'
                      } mex-mb-12`}
                      onClick={_ => this.handleUpdateRestrictions('0')}
                    >
                      <div className="mx-auto d-flex">
                        <div
                          className={`rounded-circle radio-spaced ${
                            !this.props.formData.restrictions
                              ? 'radio-selected'
                              : 'radio-deselected'
                          }`}
                        ></div>
                        <p className="text">No Conditions</p>
                      </div>
                    </div>
                  </a>
                  <a href="# " className="link-no-decoration">
                    <div
                      className={`${
                        this.props.formData.restrictions
                          ? 'new-object-restriction-selector-selected'
                          : 'new-object-restriction-selector'
                      } mex-mb-12`}
                      onClick={_ => this.handleUpdateRestrictions('1')}
                    >
                      <div className="mx-auto d-flex">
                        <div
                          className={`rounded-circle radio-spaced ${
                            this.props.formData.restrictions
                              ? 'radio-selected'
                              : 'radio-deselected'
                          }`}
                        ></div>
                        <p className="text">Apply Conditions</p>
                      </div>
                    </div>
                  </a>
                  {this.props.formData.restrictions && (
                    <div>
                      <textarea
                        className={`input-textarea ${
                          this.props.formDraftErrors.restrictionsError ||
                          this.props.formSubmitErrors.restrictionsError
                            ? 'input-error'
                            : ''
                        }`}
                        onChange={event =>
                          this.handleUpdateRestrictionsDescription(
                            event.target.value,
                          )
                        }
                        value={this.props.formData.restrictionsDescription}
                        maxLength="1000"
                        style={{ marginLeft: '25px', width: '425px' }}
                        placeholder="Enter description of conditions"
                      ></textarea>
                    </div>
                  )}
                </div>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-6 ${
                    this.props.formSubmitErrors.locationCityError ||
                    this.props.formSubmitErrors.locationStateError ||
                    this.props.formSubmitErrors.locationZipError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Object Location
                </p>
                <div className="d-flex justify-content-between">
                  <div className="new-object-field-medium">
                    <p
                      className={`title-small mex-mb-6 ${
                        this.props.formSubmitErrors.locationCityError
                          ? 'label-error'
                          : ''
                      }`}
                    >
                      City
                    </p>
                    <input
                      className={`input ${
                        this.props.formSubmitErrors.locationCityError
                          ? 'input-error'
                          : ''
                      }`}
                      type="text"
                      onChange={event =>
                        this.handleUpdateCity(event.target.value)
                      }
                      value={this.props.formData.locationCity}
                      maxLength="100"
                      placeholder="City"
                    ></input>
                  </div>
                  <div className="new-object-field-medium">
                    <p
                      className={`title-small mex-mb-6 ${
                        this.props.formSubmitErrors.locationStateError
                          ? 'label-error'
                          : ''
                      }`}
                    >
                      State / Province
                    </p>
                    <input
                      className={`input ${
                        this.props.formSubmitErrors.locationStateError
                          ? 'input-error'
                          : ''
                      }`}
                      type="text"
                      onChange={event =>
                        this.handleUpdateState(event.target.value.toUpperCase())
                      }
                      value={this.props.formData.locationState}
                      maxLength="2"
                      placeholder="State / Province"
                    ></input>
                  </div>
                  <div className="new-object-field-medium">
                    <p
                      className={`title-small mex-mb-6 ${
                        this.props.formSubmitErrors.locationZipError
                          ? 'label-error'
                          : ''
                      }`}
                    >
                      Zip / Postal Code
                    </p>
                    <input
                      className={`input ${
                        this.props.formSubmitErrors.locationZipError
                          ? 'input-error'
                          : ''
                      }`}
                      type="text"
                      onChange={event => this.handleUpdateZip(event)}
                      value={this.props.formData.locationZip}
                      placeholder="Zip / Post Code"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar fixed-bottom d-flex justify-content-center new-object-nav-bottom align-items-end">
          <div className="new-object-nav-bottom-container d-flex justify-content-end">
            <div className="new-object-nav-bottom-alert-container">
              {(this.props.putObjectDraftRequest.error ||
                this.props.postObjectDraftRequest.error ||
                this.props.formDraftErrors.formError ||
                this.props.formSubmitErrors.formError) && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                  {this.props.putObjectDraftRequest.error ||
                  this.props.postObjectDraftRequest.error
                    ? 'Please try again'
                    : 'Please complete missing field(s)'}
                </div>
              )}
            </div>
            <button
              className="btn btn-primary new-object-nav-bottom-save-button"
              onClick={_ => {
                this.destination = 'folders';
                this.handleNextStepClick();
              }}
            >
              SAVE DRAFT & EXIT
            </button>
          </div>
          <div className="new-object-nav-bottom-container d-flex justify-content-start">
            <div>
              <p className="title-small text-center mex-mb-10">NEXT STEP</p>
              <button
                className="btn btn-primary new-object-nav-bottom-next-button"
                onClick={_ => {
                  this.destination = 'next';
                  this.handleNextStepClick();
                }}
              >
                UPLOAD IMAGES
              </button>
            </div>
            <div className="new-object-nav-bottom-note-container"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    formData: state.objectDraftState.formData,
    formDraftErrors: state.objectDraftState.formDraftErrors,
    formSubmitAttempted: state.objectDraftState.formSubmitAttempted,
    formSubmitErrors: state.objectDraftState.formSubmitErrors,
    postObjectDraftRequest: state.objectDraftState.objDraftPostRequest,
    putObjectDraftRequest: state.objectDraftState.objDraftPutRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postObjectDraft: (token, payload, userId) =>
      dispatch(postObjectDraft(token, payload, userId)),
    postObjectDraftClear: _ => dispatch(objDraftPostClear()),
    putObjectDraft: (token, id, payload) =>
      dispatch(putObjectDraft(token, id, payload)),
    putObjectDraftClear: _ => dispatch(objDraftPutClear()),
    updateRestrictions: payload =>
      dispatch(objDraftUpdateRestrictions(payload)),
    updateRestrictionsDescription: payload =>
      dispatch(objDraftUpdateRestrictionsDescription(payload)),
    updateCity: payload => dispatch(objDraftUpdateCity(payload)),
    updateState: payload => dispatch(objDraftUpdateState(payload)),
    updateZip: payload => dispatch(objDraftUpdateZip(payload)),
    updateDraftErrors: payload => dispatch(objDraftUpdateDraftErrors(payload)),
    updateSubmitErrors: payload =>
      dispatch(objDraftUpdateSubmitErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDraftThree);
