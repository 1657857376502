import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AddTel from '../components/tel/AddTel';
import ConfirmEmail from '../components/confirmEmail/ConfirmEmail';
import ConfirmTel from '../components/tel/ConfirmTel';
import DonorAccount from '../components/donorAccounts/DonorAccount/DonorAccount';
import DonorAccountsLanding from '../components/donorAccounts/DonorAccountsLanding';
import DonorAccountsShared from '../components/donorAccounts/DonorAccountsShared';
import DonorAccountsNew from '../components/donorAccounts/DonorAccountsNew';
import DonorApplication from '../components/donorApplication/DonorApplication';
import DonorDonation from '../components/donorDonations/DonorDonation';
import DonorDonationShipping from '../components/donorDonations/DonorDonationShipping';
import DonorDonationAppraisal from '../components/donorDonations/DonorDonationAppraisal';
import DonorCataloguePreview from '../components/donorAccounts/DonorCataloguePreview';
import DonorDonationMatch from '../components/donorDonations/DonorDonationMatch';
import DonorDonationPreliminaryShipping from '../components/donorDonations/DonorDonationPreliminaryShipping';
import DonorDonationPreliminaryObject from '../components/donorDonations/DonorDonationPreliminaryObject';
import DonorObjectDetails from '../components/donorAccounts/DonorObjectDetails';
import DonorObjectDetailsImages from '../components/donorAccounts/DonorObjectDetailsImages';
import DonorPending from '../components/donorApplication/DonorPending';
import DonorProposalsView from '../components/donorProposals/DonorProposalsView';
import DonorUser from '../components/donorSettings/DonorUser';
import DonorUserName from '../components/donorSettings/DonorUserName';
import DonorUserEmail from '../components/donorSettings/DonorUserEmail';
import DonorUserCollection from '../components/donorSettings/DonorUserCollection';
import DonorUserLocation from '../components/donorSettings/DonorUserLocation';
import DonorUserTelAdd from '../components/donorSettings/DonorUserTelAdd';
import DonorUserTelVerify from '../components/donorSettings/DonorUserTelVerify';
import DonorUserPasswordAuthorize from '../components/donorSettings/DonorUserPasswordAuthorize';
import DonorUserPasswordConfirm from '../components/donorSettings/DonorUserPasswordConfirm';
import NotFound from '../components/notFound/NotFound';
import Login from '../components/login/Login';
import LoginVerify from '../components/login/LoginVerify';
import Logout from '../components/logout/Logout';
import MuseumApplication from '../components/museumApplication/MuseumApplication';
import MuseumCatalogue from '../components/museumComponents/MuseumCatalogue';
import MuseumCatalogueDetails from '../components/museumComponents/MuseumCatalogueDetails';
import MuseumDonation from '../components/museumDonations/MuseumDonation';
import MuseumDonationShipping from '../components/museumDonations/MuseumDonationShipping';
import MuseumDonationsActive from '../components/museumDonations/MuseumDonationsActive';
import MuseumDonationsHistory from '../components/museumDonations/MuseumDonationsHistory';
import MuseumDonationsMatchOne from '../components/museumDonations/MuseumDonationsMatch';
import MuseumDonationsPreliminaryApprovalDates from '../components/museumDonations/MuseumDonationsPreliminaryApprovalDates';
import MuseumLanding from '../components/museumComponents/MuseumLanding';
import MuseumListCuratorial from '../components/museumComponents/MuseumListCuratorial';
import MuseumListInterest from '../components/museumComponents/MuseumListInterest';
import MuseumObjectDetails from '../components/museumComponents/MuseumObjectDetails';
import MuseumObjectDetailsImages from '../components/museumComponents/MuseumObjectDetailsImages';
import MuseumPending from '../components/museumApplication/MuseumPending';
import MuseumProposalReview from '../components/museumComponents/MuseumProposalReview';
import MuseumInfo from '../components/museumSettings/MuseumInfo';
import MuseumInfoName from '../components/museumSettings/MuseumInfoName';
import MuseumInfoLocation from '../components/museumSettings/MuseumInfoLocation';
import MuseumInfoWebsite from '../components/museumSettings/MuseumInfoWebsite';
import MuseumStaff from '../components/museumSettings/MuseumStaff';
import MuseumUser from '../components/museumSettings/MuseumUser';
import MuseumUserName from '../components/museumSettings/MuseumUserName';
import MuseumUserEmail from '../components/museumSettings/MuseumUserEmail';
import MuseumUserTelAdd from '../components/museumSettings/MuseumUserTelAdd';
import MuseumUserTelVerify from '../components/museumSettings/MuseumUserTelVerify';
import MuseumUserRole from '../components/museumSettings/MuseumUserRole';
import MuseumUserPasswordAuthorize from '../components/museumSettings/MuseumUserPasswordAuthorize';
import MuseumUserPasswordConfirm from '../components/museumSettings/MuseumUserPasswordConfirm';
import ObjectDraftOne from '../components/objectDraft/ObjectDraftOne';
import ObjectDraftTwo from '../components/objectDraft/ObjectDraftTwo';
import ObjectDraftThree from '../components/objectDraft/ObjectDraftThree';
import ObjectDraftFour from '../components/objectDraft/ObjectDraftFour';
import ObjectDraftFive from '../components/objectDraft/ObjectDraftFive';
import Page from '../components/page/Page';
import ProposalsActive from '../components/proposals/ProposalsActive';
import ProposalsHistory from '../components/proposals/ProposalsHistory';
import ProposalEdit from '../components/proposals/ProposalEdit';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import Signup from '../components/signup/Signup';
import SubmitReset from '../components/reset/SubmitReset';
import ConfirmReset from '../components/reset/ConfirmReset';
import ObjectDraftViewOnly from 'components/objectDraft/ObjectDraftViewOnly';
import MuseumDonationsPreliminaryPreshippingRequirements from 'components/museumDonations/MuseumDonationsPreliminaryPreshippingRequirements';
import {
  DonorDonationDetails,
  MuseumDonationDetails,
} from 'components/_shared/DonationDetails';
import MuseumDonationsScheduleShipping from 'components/museumDonations/MuseumDonationsScheduleShipping';
import DonorDonationsScheduleShippingApprove from 'components/donorDonations/DonorDonationsScheduleShippingApprove';
import DonorDonationsAppraiserReview from 'components/donorDonations/DonorDonationsAppraiserReview';

const Router = _ => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route
          path={'/museum*'}
          render={() => {
            // Redirect to absolute url - see this answer: https://stackoverflow.com/a/54280836
            window.location.href =
              window.location.origin +
              window.location.pathname.replace('museum', 'institution');
            return null;
          }}
        />
        <Route
          path="/confirm/email/:emailId"
          exact
          render={props => (
            <Page
              {...props}
              component={ConfirmEmail}
              header={false}
              title="Museum Exchange | Confirm Email"
              emailConfirm={true}
            />
          )}
        />
        <Route
          path="/donor"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorAccountsLanding}
              header={'basic'}
              title="Museum Exchange | Home"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorAccount}
              header={'basic'}
              title="Museum Exchange | Folder"
            />
          )}
        />
        <Route
          path="/donor/shared"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorAccountsShared}
              header={'basic'}
              title="Museum Exchange | Shared with Me"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonation}
              header={'basic'}
              title="Museum Exchange | Donation Overview"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId/shipping"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationShipping}
              header={'basic'}
              title="Museum Exchange | Pick Up Information"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId/schedule-shipping-approve"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationsScheduleShippingApprove}
              header={'basic'}
              title="Museum Exchange | Approve Pick Up"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId/appraiser-review"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationsAppraiserReview}
              header={'basic'}
              title="Museum Exchange | Approve Appraiser"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId/preliminary-object-info"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationPreliminaryObject}
              header={'basic'}
              title="Museum Exchange | Preliminary Packing Information"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId/preliminary-shipping-info"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationPreliminaryShipping}
              header={'basic'}
              title="Museum Exchange | Preliminary Pick Up Information"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donation/:donationId/appraisal"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationAppraisal}
              header={'basic'}
              title="Museum Exchange | Approve Appraisal"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donations/:donationId/match"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationMatch}
              header={'basic'}
              title="Museum Exchange | Donation Form"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donations/:donationId/match/2"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationPreliminaryShipping}
              header={'basic'}
              title="Museum Exchange | Donation Form"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donations/:donationId/match/3"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationPreliminaryObject}
              header={'basic'}
              title="Museum Exchange | Donation Form"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/donations/:donationId/details"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorDonationDetails}
              header={'basic'}
              title="Museum Exchange | Donation Details"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/:objectId/details"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorObjectDetails}
              header={'basic'}
              title="Museum Exchange | Object Details"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/:objectId/proposals"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorProposalsView}
              header={'basic'}
              title="Museum Exchange | View Proposals"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/:objectId/details/images"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorObjectDetailsImages}
              header={'basic'}
              title="Museum Exchange | Object Images"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/:objectId/preview"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorCataloguePreview}
              header={'basic'}
              title="Museum Exchange | Object Preview"
            />
          )}
        />
        <Route
          path="/donor/folder/new"
          render={props => (
            <Page
              {...props}
              component={DonorAccountsNew}
              header={'basic'}
              title="Museum Exchange | New Folder"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/draft/1"
          render={props => (
            <Page
              {...props}
              component={ObjectDraftOne}
              header={'basic'}
              title="Museum Exchange | New Object - Lot Details"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/draft/2"
          render={props => (
            <Page
              {...props}
              component={ObjectDraftTwo}
              header={'basic'}
              title="Museum Exchange | New Object - Cataloguing"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/draft/3"
          render={props => (
            <Page
              {...props}
              component={ObjectDraftThree}
              header={'basic'}
              title="Museum Exchange | New Object - Donation Info"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/draft/4"
          render={props => (
            <Page
              {...props}
              component={ObjectDraftFour}
              header={'basic'}
              title="Museum Exchange | New Object - Upload Images"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/draft/5"
          render={props => (
            <Page
              {...props}
              component={ObjectDraftFive}
              header={'basic'}
              title="Museum Exchange | New Object - Review & Submit"
            />
          )}
        />
        <Route
          path="/donor/folders/:accountId/object/draft/view"
          render={props => (
            <Page
              {...props}
              component={ObjectDraftViewOnly}
              header={'basic'}
              title="Museum Exchange | New Object - View"
            />
          )}
        />
        <Route
          path="/donor/settings/user"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUser}
              header={'basic'}
              title="Museum Exchange | Manage your Account"
            />
          )}
        />
        <Route
          path="/donor/settings/user/name"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserName}
              header={'basic'}
              title="Museum Exchange | Change Name"
            />
          )}
        />
        <Route
          path="/donor/settings/user/email"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserEmail}
              header={'basic'}
              title="Museum Exchange | Change Email"
            />
          )}
        />
        <Route
          path="/donor/settings/user/collection"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserCollection}
              header={'basic'}
              title="Museum Exchange | Change Collection Type"
            />
          )}
        />
        <Route
          path="/donor/settings/user/location"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserLocation}
              header={'basic'}
              title="Museum Exchange | Change Location"
            />
          )}
        />
        <Route
          path="/donor/settings/user/tel/add"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserTelAdd}
              header={'basic'}
              title="Museum Exchange | Change Mobile Number"
            />
          )}
        />
        <Route
          path="/donor/settings/user/tel/verify"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserTelVerify}
              header={'basic'}
              title="Museum Exchange | Change Mobile Number"
            />
          )}
        />
        <Route
          path="/donor/settings/user/password/authorize"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserPasswordAuthorize}
              header={'basic'}
              title="Museum Exchange | Change Password"
            />
          )}
        />
        <Route
          path="/donor/settings/user/password/confirm"
          exact
          render={props => (
            <Page
              {...props}
              component={DonorUserPasswordConfirm}
              header={'basic'}
              title="Museum Exchange | Change Password"
            />
          )}
        />
        <Route
          path="/login"
          exact
          render={props => (
            <Page
              {...props}
              component={Login}
              header={false}
              title="Museum Exchange | Log In"
              login={true}
            />
          )}
        />
        <Route
          path="/login/verify"
          render={props => (
            <Page
              {...props}
              component={LoginVerify}
              header={'basic'}
              title="Museum Exchange | Log In"
            />
          )}
        />
        <Route
          path="/logout"
          render={props => (
            <Page
              {...props}
              component={Logout}
              header={'basic'}
              title="Museum Exchange | Logout"
            />
          )}
        />
        <Route
          path="/institution/catalogue/:page"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumCatalogue}
              header={'museum'}
              title="Museum Exchange | Catalogue"
            />
          )}
        />
        <Route
          path="/institution/catalogue/object/:objectId"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumCatalogueDetails}
              header={'museum'}
              title="Museum Exchange | Object Details"
            />
          )}
        />
        <Route
          path="/institution/object/:objectId"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumObjectDetails}
              header={'museum'}
              title="Museum Exchange | Object Details"
            />
          )}
        />
        <Route
          path="/institution/object/:objectId/images"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumObjectDetailsImages}
              header={'museum'}
              title="Museum Exchange | Object Images"
            />
          )}
        />
        <Route
          path="/institution"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumLanding}
              header={false}
              title="Museum Exchange | Home"
            />
          )}
        />
        <Route
          path="/institution/settings/general"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumInfo}
              header={'museum'}
              title="Museum Exchange | Institution Information"
            />
          )}
        />
        <Route
          path="/institution/settings/general/name"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumInfoName}
              header={'museum'}
              title="Museum Exchange | Change Name"
            />
          )}
        />
        <Route
          path="/institution/settings/general/location"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumInfoLocation}
              header={'museum'}
              title="Museum Exchange | Change Location"
            />
          )}
        />
        <Route
          path="/institution/settings/general/website"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumInfoWebsite}
              header={'museum'}
              title="Museum Exchange | Change Website"
            />
          )}
        />
        <Route
          path="/institution/settings/staff"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumStaff}
              header={'museum'}
              title="Museum Exchange | Staff & Permissions"
            />
          )}
        />
        <Route
          path="/institution/settings/user"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUser}
              header={'museum'}
              title="Museum Exchange | Manage your Account"
            />
          )}
        />
        <Route
          path="/institution/settings/user/name"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserName}
              header={'museum'}
              title="Museum Exchange | Change Name"
            />
          )}
        />
        <Route
          path="/institution/settings/user/email"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserEmail}
              header={'museum'}
              title="Museum Exchange | Change Email"
            />
          )}
        />
        <Route
          path="/institution/settings/user/tel/add"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserTelAdd}
              header={'museum'}
              title="Museum Exchange | Change Mobile Number"
            />
          )}
        />
        <Route
          path="/institution/settings/user/tel/verify"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserTelVerify}
              header={'museum'}
              title="Museum Exchange | Change Mobile Number"
            />
          )}
        />
        <Route
          path="/institution/settings/user/password/authorize"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserPasswordAuthorize}
              header={'museum'}
              title="Museum Exchange | Change Password"
            />
          )}
        />
        <Route
          path="/institution/settings/user/password/confirm"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserPasswordConfirm}
              header={'museum'}
              title="Museum Exchange | Change Password"
            />
          )}
        />
        <Route
          path="/institution/settings/user/role"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumUserRole}
              header={'museum'}
              title="Museum Exchange | Change Role"
            />
          )}
        />
        <Route
          path="/institution/lists/curatorial"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumListCuratorial}
              header={'museum'}
              title="Museum Exchange | Curatorial Review"
            />
          )}
        />
        <Route
          path="/institution/lists/interest"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumListInterest}
              header={'museum'}
              title="Museum Exchange | Objects of Interest"
            />
          )}
        />
        <Route
          path="/institution/donations/active"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationsActive}
              header={'museum'}
              title="Museum Exchange | Active Donations"
            />
          )}
        />
        <Route
          path="/institution/donations/history"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationsHistory}
              header={'museum'}
              title="Museum Exchange | Donation History"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId/match/confirm"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationsMatchOne}
              header={'museum'}
              title="Museum Exchange | Confirm Donation"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonation}
              header={'museum'}
              title="Museum Exchange | Donation Overview"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId/shipping"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationShipping}
              header={'museum'}
              title="Museum Exchange | Pick Up Information"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId/details"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationDetails}
              header={'museum'}
              title="Museum Exchange | Donation Details"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId/preliminary/approval-dates"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationsPreliminaryApprovalDates}
              header={'museum'}
              title="Museum Exchange | Approval Dates"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId/preliminary/preshipping"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationsPreliminaryPreshippingRequirements}
              header={'museum'}
              title="Museum Exchange | Pre-shipping Requirements"
            />
          )}
        />
        <Route
          path="/institution/donations/:donationId/schedule-shipping"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumDonationsScheduleShipping}
              header={'museum'}
              title="Museum Exchange | Schedule Pick Up"
            />
          )}
        />
        <Route
          path="/institution/proposals/active"
          exact
          render={props => (
            <Page
              {...props}
              component={ProposalsActive}
              header={'museum'}
              title="Museum Exchange | Active Proposals"
            />
          )}
        />
        <Route
          path="/institution/proposals/history"
          exact
          render={props => (
            <Page
              {...props}
              component={ProposalsHistory}
              header={'museum'}
              title="Museum Exchange | Proposal History"
            />
          )}
        />
        <Route
          path="/institution/proposals/edit"
          exact
          render={props => (
            <Page
              {...props}
              component={ProposalEdit}
              header={'museum'}
              title="Museum Exchange | Edit Proposal"
            />
          )}
        />
        <Route
          path="/institution/proposals/review"
          exact
          render={props => (
            <Page
              {...props}
              component={MuseumProposalReview}
              header={'museum'}
              title="Museum Exchange | Review Proposal"
            />
          )}
        />
        <Route
          path="/reset"
          exact
          render={props => (
            <Page
              {...props}
              component={SubmitReset}
              header={false}
              title="Museum Exchange | Reset Password"
            />
          )}
        />
        <Route
          path="/reset/:resetId"
          exact
          render={props => (
            <Page
              {...props}
              component={ConfirmReset}
              header={false}
              title="Museum Exchange | Reset Password"
              bypass={true}
            />
          )}
        />
        <Route
          path="/signup"
          render={props => (
            <Page
              {...props}
              component={Signup}
              header={false}
              title="Museum Exchange | Create Account"
            />
          )}
        />
        <Route
          path="/user/donor/apply"
          render={props => (
            <Page
              {...props}
              component={DonorApplication}
              header={'basic'}
              title="Museum Exchange | Donor Application"
            />
          )}
        />
        <Route
          path="/user/donor/pending"
          render={props => (
            <Page
              {...props}
              component={DonorPending}
              header={'basic'}
              title="Museum Exchange | Application Pending"
            />
          )}
        />
        <Route
          path="/user/institution/apply"
          render={props => (
            <Page
              {...props}
              component={MuseumApplication}
              header={'basic'}
              title="Museum Exchange | Museum Application"
            />
          )}
        />
        <Route
          path="/user/institution/pending"
          render={props => (
            <Page
              {...props}
              component={MuseumPending}
              header={'basic'}
              title="Museum Exchange | Application Pending"
            />
          )}
        />
        <Route
          path="/user/tel/add"
          render={props => (
            <Page
              {...props}
              component={AddTel}
              header={'basic'}
              title="Museum Exchange | Enter Phone Number"
            />
          )}
        />
        <Route
          path="/user/tel/confirm"
          render={props => (
            <Page
              {...props}
              component={ConfirmTel}
              header={'basic'}
              title="Museum Exchange | Confirm Phone Number"
            />
          )}
        />
        <Route
          path="*"
          render={props => (
            <Page
              {...props}
              component={NotFound}
              header={false}
              title="Museum Exchange | Not Found"
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
