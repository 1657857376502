import React from 'react';
import ObjectDetailsCatalogue from '../objectDetails/ObjectDetailsCatalogue';
import BackArrow from '../../images/arrow-back.png';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';

export default function DonorCataloguePreview() {
  const { accountId, objectId } = useParams();
  const { goBack } = useHistory();
  const { data: obj } = useDonorGetObject({ accountId, objectId });

  if (obj) {
    return (
      <div className="gen-background-nav-donor-white">
        <div className="da-prev-header">
          <div className="da-prev-header-inner mx-auto d-flex">
            <a
              href="# "
              onClick={_ => goBack()}
              className="gen-link-no-decoration"
            >
              <img
                src={BackArrow}
                alt="back-arrow"
                className="da-prev-back-arrow"
              />
            </a>
            <p className="da-prev-header-text">Catalogue Preview</p>
          </div>
        </div>
        <ObjectDetailsCatalogue object={obj} />
      </div>
    );
  } else {
    return <div className="gen-background-nav-donor-white"></div>;
  }
}
