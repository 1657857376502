import React from 'react';
import ObjectDetails from '../objectDetails/ObjectDetails';
import BackArrow from '../../images/arrow-back.png';
import '../../styles/general.css';
import '../../styles/museum-object-details.css';
import '../../styles/text.css';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';

export default function DonorObjectDetails() {
  const { accountId, objectId } = useParams();
  const { data: obj } = useDonorGetObject({ accountId, objectId });
  const { goBack } = useHistory();

  if (obj) {
    return (
      <div className="gen-background-nav-donor-white">
        <div className="mod-content d-flex mx-auto">
          <div className="mod-side-container">
            <div className="mod-back-container d-flex" onClick={_ => goBack()}>
              <img
                src={BackArrow}
                alt="back-arrow"
                className="mod-back-arrow"
              />
              <p className="txt-header-medium-20">Back</p>
            </div>
          </div>
          <ObjectDetails accountId={accountId} obj={obj} />
          <div className="mod-side-container"></div>
        </div>
      </div>
    );
  } else {
    return <div className="gen-background-nav-donor-white"></div>;
  }
}
