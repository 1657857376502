import {
  SIGNUP_RESET,
  SIGNUP_SELECT_ACCOUNT_TYPE,
  SIGNUP_UPDATE_EMAIL,
  SIGNUP_UPDATE_FORM_ERRORS,
  SIGNUP_UPDATE_NAME_FIRST,
  SIGNUP_UPDATE_NAME_LAST,
  SIGNUP_UPDATE_PASSWORD,
} from '../../actions/actionTypes';

const initialSignupState = {
  formData: {
    accountType: '',
    nameFirst: '',
    nameLast: '',
    email: '',
    password: '',
  },
  formErrors: {
    formError: false,
    accountTypeError: false,
    nameFirstError: false,
    nameLastError: false,
    emailError: false,
    passwordError: false,
  },
};

export const signupReducer = (state = initialSignupState, action) => {
  switch (action.type) {
    case SIGNUP_RESET:
      return {
        ...state,
        formData: {
          accountType: '',
          nameFirst: '',
          nameLast: '',
          email: '',
          password: '',
        },
        formErrors: {
          formError: false,
          accountTypeError: false,
          nameFirstError: false,
          nameLastError: false,
          emailError: false,
          passwordError: false,
        },
      };

    case SIGNUP_SELECT_ACCOUNT_TYPE:
      return {
        ...state,
        formData: {
          ...state.formData,
          accountType: action.payload.accountType,
        },
      };

    case SIGNUP_UPDATE_EMAIL:
      return {
        ...state,
        formData: { ...state.formData, email: action.payload.email },
      };

    case SIGNUP_UPDATE_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };

    case SIGNUP_UPDATE_NAME_FIRST:
      return {
        ...state,
        formData: { ...state.formData, nameFirst: action.payload.nameFirst },
      };

    case SIGNUP_UPDATE_NAME_LAST:
      return {
        ...state,
        formData: { ...state.formData, nameLast: action.payload.nameLast },
      };

    case SIGNUP_UPDATE_PASSWORD:
      return {
        ...state,
        formData: { ...state.formData, password: action.payload.password },
      };

    default:
      return state;
  }
};
