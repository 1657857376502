import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  useGetDonorAccountGeneralQuery,
  useGetDonorAccountQuery,
} from 'requests/api';

export function useCurrentDonorAccount() {
  const { accountId } = useParams();

  const { data: account } = useGetDonorAccountQuery(accountId, {
    skip: !accountId,
  });

  // Need to handle the case that we're on the donor general landing page
  const { data: generalAccount } = useGetDonorAccountGeneralQuery(undefined, {
    skip:
      removeTrailingSlash(window.location.pathname) !== '/donor' || accountId,
  });

  const currentAccount = account || generalAccount;
  return currentAccount;
}

function removeTrailingSlash(str) {
  return str.replace(/\/$/, '');
}
