import React from 'react';

// TODO: It would be better to rely on a tested tooltip from a UI library, but ideally we can pull one in without much bloat.
export function Tooltip({ content, children }) {
  return (
    <div data-mex-tooltip>
      <div className="tw-group tw-relative">
        {children}
        <span className="invisible tw-absolute tw-top-full tw-left-1/2 tw-z-10 tw-flex tw-min-w-[280px] tw--translate-x-1/2 tw-items-center tw-justify-center tw-pt-2 group-hover:tw-visible">
          <div className="tw-grow-0 tw-rounded tw-bg-gray-600 tw-py-2 tw-px-5 tw-text-xs tw-text-white">
            <div className="">{content}</div>
          </div>
        </span>
      </div>
    </div>
  );
}
