import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserClear } from '../../actions';
import { getUser } from '../../requests';
import { formatPhoneNumber } from '../../lib';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/settings.css';
import '../../styles/text.css';

class MuseumUser extends Component {
  static get propTypes() {
    return {
      getUser: PropTypes.func,
      getUserClear: PropTypes.func,
      getUserRequest: PropTypes.object,
      token: PropTypes.object,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.getUser(this.props.token);
  }

  componentWillUnmount() {
    this.props.getUserClear();
  }

  makeContent = _ => {
    if (this.props.getUserRequest.data) {
      return (
        <div className="set-content-outer">
          <div className="set-info-container mx-auto">
            <div className="set-info-row set-info-row-border">
              <p className="set-label-txt">NAME</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">{`${this.props.user.nameFirst} ${this.props.user.nameLast}`}</p>
                <Link
                  to="/institution/settings/user/name"
                  className="gen-link-no-decoration"
                >
                  <p className="set-info-row-link">EDIT</p>
                </Link>
              </div>
            </div>
            <div className="set-info-row set-info-row-border">
              <p className="set-label-txt">EMAIL ADDRESS</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">{this.props.user.email}</p>
                <Link
                  to="/institution/settings/user/email"
                  className="gen-link-no-decoration"
                >
                  <p className="set-info-row-link">EDIT</p>
                </Link>
              </div>
            </div>
            <div className="set-info-row set-info-row-border">
              <p className="set-label-txt">MOBILE NUMBER</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">
                  {formatPhoneNumber(this.props.user.tel)}
                </p>
                <Link
                  to="/institution/settings/user/tel/add"
                  className="gen-link-no-decoration"
                >
                  <p className="set-info-row-link">EDIT</p>
                </Link>
              </div>
            </div>
            <div className="set-info-row set-info-row-border">
              <p className="set-label-txt">PASSWORD</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">••••••••••</p>
                <Link
                  to="/institution/settings/user/password/authorize"
                  className="gen-link-no-decoration"
                >
                  <p className="set-info-row-link">EDIT</p>
                </Link>
              </div>
            </div>
            <div className="set-info-row">
              <p className="set-label-txt">ROLE</p>
              <div className="set-info-row-val-container d-flex">
                <p className="set-info-txt">
                  {this.props.user.museum.position}
                </p>
                <Link
                  to="/institution/settings/user/role"
                  className="gen-link-no-decoration"
                >
                  <p className="set-info-row-link">EDIT</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="gen-background-nav-museum-settings">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <div className="set-header-initials-container">
              <p className="set-header-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
            <p className="txt-header">Manage your Account</p>
          </div>
          {this.makeContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    getUserRequest: state.userState.getUserRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUser: token => dispatch(getUser(token)),
    getUserClear: _ => dispatch(getUserClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MuseumUser);
