export const PROP_CLEAR_PROP = 'PROP_CLEAR_PROP';
export const PROP_DELETE_IMAGE_CLEAR = 'PROP_DELETE_IMAGE_CLEAR';
export const PROP_DELETE_IMAGE_FAILURE = 'PROP_DELETE_IMAGE_FAILURE';
export const PROP_DELETE_IMAGE_REQUEST = 'PROP_DELETE_IMAGE_REQUEST';
export const PROP_DELETE_IMAGE_SUCCESS = 'PROP_DELETE_IMAGE_SUCCESS';
export const PROP_POST_DRAFT_CLEAR = 'PROP_POST_DRAFT_CLEAR';
export const PROP_POST_DRAFT_FAILURE = 'PROP_POST_DRAFT_FAILURE';
export const PROP_POST_DRAFT_REQUEST = 'PROP_POST_DRAFT_REQUEST';
export const PROP_POST_DRAFT_SUCCESS = 'PROP_POST_DRAFT_SUCCESS';
export const PROP_PUT_APPROVE_CLEAR = 'PROP_PUT_APPROVE_CLEAR';
export const PROP_PUT_APPROVE_FAILURE = 'PROP_PUT_APPROVE_FAILURE';
export const PROP_PUT_APPROVE_REQUEST = 'PROP_PUT_APPROVE_REQUEST';
export const PROP_PUT_APPROVE_SUCCESS = 'PROP_PUT_APPROVE_SUCCESS';
export const PROP_PUT_DRAFT_CLEAR = 'PROP_PUT_DRAFT_CLEAR';
export const PROP_PUT_DRAFT_FAILURE = 'PROP_PUT_DRAFT_FAILURE';
export const PROP_PUT_DRAFT_REQUEST = 'PROP_PUT_DRAFT_REQUEST';
export const PROP_PUT_DRAFT_SUCCESS = 'PROP_PUT_DRAFT_SUCCESS';
export const PROP_PUT_IMAGE_CLEAR = 'PROP_PUT_IMAGE_CLEAR';
export const PROP_PUT_IMAGE_FAILURE = 'PROP_PUT_IMAGE_FAILURE';
export const PROP_PUT_IMAGE_REQUEST = 'PROP_PUT_IMAGE_REQUEST';
export const PROP_PUT_IMAGE_SUCCESS = 'PROP_PUT_IMAGE_SUCCESS';
export const PROP_PUT_SUBMIT_CLEAR = 'PROP_PUT_SUBMIT_CLEAR';
export const PROP_PUT_SUBMIT_FAILURE = 'PROP_PUT_SUBMIT_FAILURE';
export const PROP_PUT_SUBMIT_REQUEST = 'PROP_PUT_SUBMIT_REQUEST';
export const PROP_PUT_SUBMIT_SUCCESS = 'PROP_PUT_SUBMIT_SUCCESS';
export const PROP_RESET_ERRORS = 'PROP_RESET_ERRORS';
export const PROP_RESET_FORM = 'PROP_RESET_FORM';
export const PROP_SET_FORM = 'PROP_SET_FORM';
export const PROP_SET_CONTACT_MODAL = 'PROP_SET_CONTACT_MODAL';
export const PROP_SET_APPROVE_MODAL = 'PROP_SET_APPROVE_MODAL';
export const PROP_SET_DISCARD_MODAL = 'PROP_SET_DISCARD_MODAL';
export const PROP_SET_SUBMIT_MODAL = 'PROP_SET_SUBMIT_MODAL';
export const PROP_SET_TMP_CONTACT_ID = 'PROP_SET_TMP_CONTACT_ID';
export const PROP_UPDATE_CONDITIONS_CLARIFICATION =
  'PROP_UPDATE_CONDITIONS_CLARIFICATION';
export const PROP_UPDATE_CYCLE_ID = 'PROP_UPDATE_CYCLE_ID';
export const PROP_UPDATE_LETTER_OF_INTEREST = 'PROP_UPDATE_LETTER_OF_INTEREST';
export const PROP_UPDATE_MUSEUM_ID = 'PROP_UPDATE_MUSEUM_ID';
export const PROP_UPDATE_OBJECT_ID = 'PROP_UPDATE_OBJECT_ID';
export const PROP_UPDATE_POC_ID = 'PROP_UPDATE_POC_ID';
export const PROP_UPDATE_POC_TEL = 'PROP_UPDATE_POC_TEL';
export const PROP_UPDATE_ERRORS_DRAFT = 'PROP_UPDATE_ERRORS_DRAFT';
export const PROP_UPDATE_ERRORS_SUBMIT = 'PROP_UPDATE_ERRORS_SUBMIT';
export const PROP_SET_SELECTED_OBJECT = 'PROP_SET_SELECTED_OBJECT';
export const PROP_SET_SELECTED_PROPOSAL_ID = 'PROP_SET_SELECTED_PROPOSAL_ID';
export const PROP_SET_SELECTED_IMG = 'PROP_SET_SELECTED_IMG';
export const PROP_UPDATE_SHIPPING_COST = 'PROP_UPDATE_SHIPPING_COST';
export const PROP_PUT_REJECT_PROPOSALS_CLEAR =
  'PROP_PUT_REJECT_PROPOSALS_CLEAR';
export const PROP_PUT_REJECT_PROPOSALS_FAILURE =
  'PROP_PUT_REJECT_PROPOSALS_FAILURE';
export const PROP_PUT_REJECT_PROPOSALS_REQUEST =
  'PROP_PUT_REJECT_PROPOSALS_REQUEST';
export const PROP_PUT_REJECT_PROPOSALS_SUCCESS =
  'PROP_PUT_REJECT_PROPOSALS_SUCCESS';
