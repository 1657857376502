export const OBJ_DRAFT_DELETE_PRIMARY_IMAGE_CLEAR =
  'OBJ_DRAFT_DELETE_PRIMARY_IMAGE_CLEAR';
export const OBJ_DRAFT_DELETE_PRIMARY_IMAGE_FAILURE =
  'OBJ_DRAFT_DELETE_PRIMARY_IMAGE_FAILURE';
export const OBJ_DRAFT_DELETE_PRIMARY_IMAGE_REQUEST =
  'OBJ_DRAFT_DELETE_PRIMARY_IMAGE_REQUEST';
export const OBJ_DRAFT_DELETE_PRIMARY_IMAGE_SUCCESS =
  'OBJ_DRAFT_DELETE_PRIMARY_IMAGE_SUCCESS';
export const OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_CLEAR =
  'OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_CLEAR';
export const OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_FAILURE =
  'OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_FAILURE';
export const OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_REQUEST =
  'OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_REQUEST';
export const OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_SUCCESS =
  'OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_SUCCESS';
export const OBJ_DRAFT_POST_CLEAR = 'OBJ_DRAFT_POST_CLEAR';
export const OBJ_DRAFT_POST_FAILURE = 'OBJ_DRAFT_POST_FAILURE';
export const OBJ_DRAFT_POST_REQUEST = 'OBJ_DRAFT_POST_REQUEST';
export const OBJ_DRAFT_POST_SUCCESS = 'OBJ_DRAFT_POST_SUCCESS';
export const OBJ_DRAFT_PUT_CLEAR = 'OBJ_DRAFT_PUT_CLEAR';
export const OBJ_DRAFT_PUT_FAILURE = 'OBJ_DRAFT_PUT_FAILURE';
export const OBJ_DRAFT_PUT_REQUEST = 'OBJ_DRAFT_PUT_REQUEST';
export const OBJ_DRAFT_PUT_SUCCESS = 'OBJ_DRAFT_PUT_SUCCESS';
export const OBJ_DRAFT_PUT_PRIMARY_IMAGE_CLEAR =
  'OBJ_DRAFT_PUT_PRIMARY_IMAGE_CLEAR';
export const OBJ_DRAFT_PUT_PRIMARY_IMAGE_FAILURE =
  'OBJ_DRAFT_PUT_PRIMARY_IMAGE_FAILURE';
export const OBJ_DRAFT_PUT_PRIMARY_IMAGE_REQUEST =
  'OBJ_DRAFT_PUT_PRIMARY_IMAGE_REQUEST';
export const OBJ_DRAFT_PUT_PRIMARY_IMAGE_SUCCESS =
  'OBJ_DRAFT_PUT_PRIMARY_IMAGE_SUCCESS';
export const OBJ_DRAFT_PUT_SUPPORTING_IMAGE_CLEAR =
  'OBJ_DRAFT_PUT_SUPPORTING_IMAGE_CLEAR';
export const OBJ_DRAFT_PUT_SUPPORTING_IMAGE_FAILURE =
  'OBJ_DRAFT_PUT_SUPPORTING_IMAGE_FAILURE';
export const OBJ_DRAFT_PUT_SUPPORTING_IMAGE_REQUEST =
  'OBJ_DRAFT_PUT_SUPPORTING_IMAGE_REQUEST';
export const OBJ_DRAFT_PUT_SUPPORTING_IMAGE_SUCCESS =
  'OBJ_DRAFT_PUT_SUPPORTING_IMAGE_SUCCESS';
export const OBJ_DRAFT_PUT_SUBMIT_CLEAR = 'OBJ_DRAFT_PUT_SUBMIT_CLEAR';
export const OBJ_DRAFT_PUT_SUBMIT_FAILURE = 'OBJ_DRAFT_PUT_SUBMIT_FAILURE';
export const OBJ_DRAFT_PUT_SUBMIT_REQUEST = 'OBJ_DRAFT_PUT_SUBMIT_REQUEST';
export const OBJ_DRAFT_PUT_SUBMIT_SUCCESS = 'OBJ_DRAFT_PUT_SUBMIT_SUCCESS';
export const OBJ_DRAFT_RESET = 'OBJ_DRAFT_RESET';
export const OBJ_DRAFT_SET_FORM = 'OBJ_DRAFT_SET_FORM';
export const OBJ_DRAFT_UPDATE_ACCOUNT = 'OBJ_DRAFT_UPDATE_ACCOUNT';
export const OBJ_DRAFT_UPDATE_COLLECTING_AREA =
  'OBJ_DRAFT_UPDATE_COLLECTING_AREA';
export const OBJ_DRAFT_UPDATE_ARTIST_NAME = 'OBJ_DRAFT_UPDATE_ARTIST_NAME';
export const OBJ_DRAFT_UPDATE_TITLE = 'OBJ_DRAFT_UPDATE_TITLE';
export const OBJ_DRAFT_UPDATE_YEAR = 'OBJ_DRAFT_UPDATE_YEAR';
export const OBJ_DRAFT_UPDATE_CLASSIFICATION =
  'OBJ_DRAFT_UPDATE_CLASSIFICATION';
export const OBJ_DRAFT_UPDATE_MEDIUM = 'OBJ_DRAFT_UPDATE_MEDIUM';
export const OBJ_DRAFT_UPDATE_DIMENSIONS = 'OBJ_DRAFT_UPDATE_DIMENSIONS';
export const OBJ_DRAFT_UPDATE_WEIGHT = 'OBJ_DRAFT_UPDATE_WEIGHT';
export const OBJ_DRAFT_UPDATE_WEIGHT_SYSTEM = 'OBJ_DRAFT_UPDATE_WEIGHT_SYSTEM';
export const OBJ_DRAFT_UPDATE_EDITION = 'OBJ_DRAFT_UPDATE_EDITION';
export const OBJ_DRAFT_UPDATE_EDITION_DESCRIPTION =
  'OBJ_DRAFT_UPDATE_EDITION_DESCRIPTION';
export const OBJ_DRAFT_UPDATE_INSCRIPTIONS = 'OBJ_DRAFT_UPDATE_INSCRIPTIONS';
export const OBJ_DRAFT_UPDATE_CONDITION = 'OBJ_DRAFT_UPDATE_CONDITION';
export const OBJ_DRAFT_UPDATE_PROVENANCE = 'OBJ_DRAFT_UPDATE_PROVENANCE';
export const OBJ_DRAFT_UPDATE_EXHIBITION_HISTORY =
  'OBJ_DRAFT_UPDATE_EXHIBITION_HISTORY';
export const OBJ_DRAFT_UPDATE_LITERATURE = 'OBJ_DRAFT_UPDATE_LITERATURE';
export const OBJ_DRAFT_UPDATE_CONSERVATION_HISTORY =
  'OBJ_DRAFT_UPDATE_CONSERVATION_HISTORY';
export const OBJ_DRAFT_UPDATE_NOTES = 'OBJ_DRAFT_UPDATE_NOTES';
export const OBJ_DRAFT_UPDATE_RESTRICTIONS = 'OBJ_DRAFT_UPDATE_RESTRICTIONS';
export const OBJ_DRAFT_UPDATE_RESTRICTIONS_DESCRIPTION =
  'OBJ_DRAFT_UPDATE_RESTRICTIONS_DESCRIPTION';
export const OBJ_DRAFT_UPDATE_CITY = 'OBJ_DRAFT_UPDATE_CITY';
export const OBJ_DRAFT_UPDATE_STATE = 'OBJ_DRAFT_UPDATE_STATE';
export const OBJ_DRAFT_UPDATE_ZIP = 'OBJ_DRAFT_UPDATE_ZIP';
export const OBJ_DRAFT_UPDATE_PRIMARY_IMAGE = 'OBJ_DRAFT_UPDATE_PRIMARY_IMAGE';
export const OBJ_DRAFT_UPDATE_SUPPORTING_IMAGES =
  'OBJ_DRAFT_UPDATE_SUPPORTING_IMAGES';
export const OBJ_DRAFT_UPDATE_SUBMISSION_HASH =
  'OBJ_DRAFT_UPDATE_SUBMISSION_HASH';
export const OBJ_DRAFT_UPDATE_PRIVACY_TOS_HASH =
  'OBJ_DRAFT_UPDATE_PRIVACY_TOS_HASH';
export const OBJ_DRAFT_UPDATE_DRAFT_ERRORS = 'OBJ_DRAFT_UPDATE_DRAFT_ERRORS';
export const OBJ_DRAFT_UPDATE_SUBMIT_ATTEMPTED =
  'OBJ_DRAFT_UPDATE_SUBMIT_ATTEMPTED';
export const OBJ_DRAFT_UPDATE_SUBMIT_ERRORS = 'OBJ_DRAFT_UPDATE_SUBMIT_ERRORS';
export const OBJ_DRAFT_SET_SELECTED_IMG = 'OBJ_DRAFT_SET_SELECTED_IMG';
