import { INSTITUTION_KINDS } from 'constants/institutions';
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'museumAccounts',
  initialState: {
    account: null,
  },
  reducers: {
    macctClearAccount: state => {
      state.account = null;
    },
    macctSetAccount: (state, { payload }) => {
      state.account = payload;
    },
  },
});

export const { reducer: macctReducer, actions: macctActions } = slice;

export const selectMuseumAccount = state => state[slice.name].account;

export function selectIsMuseum(state) {
  return selectMuseumAccount(state)?.kind !== INSTITUTION_KINDS.other;
}
