export const validateFormDraft = payload => {
  const errors = {
    formError: false,
    errorPages: {},
    accountError: false,
    titleError: false,
    heightError: false,
    lengthError: false,
    dimensionsError: false,
    dimensionsErrors: {},
    weightSystemError: false,
    editionError: false,
    restrictionsError: false,
  };

  if (!payload.account) {
    errors.formError = true;
    errors.accountError = true;
    errors.errorPages.one = true;
  }

  if (!payload.title) {
    errors.formError = true;
    errors.titleError = true;
    errors.errorPages.one = true;
  }

  payload.dimensions.forEach(v => {
    const dErrors = {};
    if (v.key !== 'dimensionsVariable' && v.key !== 'duration') {
      dErrors[v.key] = {};
      if (v.key || v.height || v.width || (v.depth && v.depth !== '0')) {
        if (v.key === '') {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].typeError = true;
          errors.errorPages.one = true;
        }

        if (!v.height || v.height === '0') {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].heightError = true;
          errors.errorPages.one = true;
        }

        if (!v.width || v.width === '0') {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].widthError = true;
          errors.errorPages.one = true;
        }

        if (!v.system) {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].systemError = true;
          errors.errorPages.one = true;
        }
      }
      errors.dimensionsErrors = dErrors;
    }
  });

  if (payload.weight && payload.weight !== '0') {
    if (!payload.weightSystem) {
      errors.formError = true;
      errors.weightSystemError = true;
      errors.errorPages.one = true;
    }
  }

  if (payload.edition && !payload.editionDescription) {
    errors.formError = true;
    errors.editionError = true;
    errors.errorPages.one = true;
  }

  if (payload.restrictions && !payload.restrictionsDescription) {
    errors.formError = true;
    errors.restrictionsError = true;
    errors.errorPages.three = true;
  }

  return errors;
};
