import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import configureAppStore from './store';
import Router from './router/Router';

function App() {
  return (
    <Provider store={configureAppStore()}>
      <Router className="App" />
    </Provider>
  );
}

export default App;
