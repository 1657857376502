export function getDonationDonorNameText({ donation }) {
  if (!donation.donorInfo?.donorType) return '';

  if (donation.donorInfo?.donorType?.individual) {
    return `${donation.donorInfo?.donorType?.individual.nameFirst} ${donation.donorInfo?.donorType?.individual.nameLast}`;
  }
  if (donation.donorInfo?.donorType?.couple) {
    return donation.donorInfo.donorType.couple;
  }
  if (donation.donorInfo?.donorType?.institution) {
    return donation.donorInfo.donorType.institution;
  }
}
