import React from 'react';
import { Redirect } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/general.css';
import '../../styles/museum-donation-match.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { usePutDonationAppraisalAppraiserApproveMutation } from 'requests/api';

export default function DonorDonationsAppraiserReview() {
  const { donationId, accountId } = useParams();
  const { goBack } = useHistory();
  const { data: donation } = useDonorGetDonation({ donationId, accountId });

  const [
    putDonationAppraisalAppraiserApprove,
    {
      isLoading: isLoadingPutDonationAppraisalAppraiserApprove,
      isSuccess: isSuccessPutDonationAppraisalAppraiserApprove,
    },
  ] = usePutDonationAppraisalAppraiserApproveMutation();

  async function onSubmit() {
    if (isLoadingPutDonationAppraisalAppraiserApprove) return;

    try {
      await putDonationAppraisalAppraiserApprove({
        donationId: donation.id,
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  if (isSuccessPutDonationAppraisalAppraiserApprove) {
    return (
      <Redirect
        push
        to={`/donor/folders/${accountId}/donation/${donation.id}`}
      />
    );
  }

  if (!donation) return null;

  return (
    <div className="gen-background-nav-museum">
      <div className="d-header mx-auto">
        <div className="d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="d-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div className="tw-grid tw-gap-3">
            <p className="tw-text-3xl tw-font-light tw-text-[#2e384d]">
              Approve Appraiser
            </p>
            <div className="tw-grid tw-max-w-[540px] tw-gap-4 tw-text-sm tw-text-[#8798ad]">
              <div>
                The appraiser estimates that the completion of the appraisal
                will require the stated number of hours at their hourly rate,
                and will not deviate by more than one hour without your prior
                approval. Please contact a{' '}
                <a
                  href={`mailto:donor_liaison@museumexchange.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tw-text-blue-500 hover:tw-underline"
                >
                  Donor Liaison
                </a>{' '}
                with any questions.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-mx-auto tw-w-[892px]">
        <div className="tw-mb-20 tw-grid tw-gap-10 tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-[#e7ebec] tw-p-7">
          <div className="tw-rounded-sm tw-bg-white tw-shadow-lg">
            <div className="tw-grid tw-gap-6 tw-p-4">
              <div className="tw-grid tw-gap-10">
                <p className="tw-text-xl tw-font-light tw-text-[#2e384d]">
                  Appraiser Estimate
                </p>
                <div className="tw-flex tw-w-full">
                  <div className="tw-w-1/3">
                    <div className="tw-font-medium">Appraiser</div>
                    {donation.appraisal.appraiserWebsite ? (
                      <div>
                        <a
                          href={donation.appraisal.appraiserWebsite}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="tw-text-blue-500 hover:tw-underline"
                        >
                          {donation.appraisal.appraiser}
                        </a>
                      </div>
                    ) : (
                      <div>{donation.appraisal.appraiser}</div>
                    )}
                  </div>
                  <div className="tw-grid tw-gap-3">
                    <div>
                      <div className="tw-mb-0 tw-font-medium">
                        Estimated Hours
                      </div>
                      <div>{donation.appraisal.estimatedHours || '--'}</div>
                    </div>
                    <div>
                      <div className="tw-mb-0 tw-font-medium">
                        Estimated Total Cost
                      </div>
                      <div>
                        {donation.appraisal.estimatedCost
                          ? new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 0,
                            }).format(donation.appraisal.estimatedCost)
                          : '--'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-grid tw-justify-center tw-gap-10">
            <button
              className="tw-min-w-[260px] tw-rounded tw-bg-blue-500 tw-px-6 tw-py-4 tw-uppercase tw-text-white disabled:tw-bg-[#bfc5d2]"
              disabled={isLoadingPutDonationAppraisalAppraiserApprove}
              onClick={onSubmit}
            >
              Approve Appraiser
            </button>
            <div>
              <a
                href={`mailto:donor_liaison@museumexchange.com`}
                target="_blank"
                rel="noopener noreferrer"
                className="tw-block tw-text-center tw-uppercase tw-text-blue-500 hover:tw-underline"
              >
                Contact a Donor Liaison
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
