import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  listsClearCuratorial,
  listsClearInterest,
  listsGetCuratorialClear,
  listsGetInterestClear,
  listsSetCuratorialObjects,
  listsClearSelectedObject,
  listsSetSelectedObject,
  listsClearCuratorialAction,
  listsSetCuratorialAction,
  listsPutCuratorialApproveClear,
  listsPutCuratorialRemoveClear,
} from '../../actions';
import {
  getCurrentCycleLists,
  putListCuratorialApproveObject,
} from '../../requests';
import {
  addDays,
  getDateString,
  getInitials,
  validateMuseumHasAdmin,
  validateUserMuseumAdmin,
} from '../../lib';
import ApprovedCheck from '../../images/approved-check.png';
import CheckmarkGrey from '../../images/checkmark-grey.png';
import CuratorialReviewGrey from '../../images/curatorial-review-grey.png';
import MoreIcon from '../../images/more-icon.png';
import RedAlert from '../../images/alert-red.png';
import '../../styles/general.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { selectCurrentCycle } from 'selectors/cyclesSelectors';
import { useGetMuseumAccountUserQuery } from 'requests/api';

export default function MuseumListCuratorial() {
  const curatorialAction = useSelector(
    state => state.listsState.curatorialAction,
  );
  const currentCycle = useSelector(state => selectCurrentCycle(state));
  const cyclesSorted = useSelector(state => state.cyclesState.cyclesSorted);

  const listCuratorial = useSelector(state => state.listsState.listCuratorial);
  const listInterest = useSelector(state => state.listsState.listInterest);
  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();
  const putListApproveObjectRequest = useSelector(
    state => state.listsState.putListCuratorialApproveRequest,
  );
  const putListRemoveObjectRequest = useSelector(
    state => state.listsState.putListCuratorialRemoveRequest,
  );
  const selectedObject = useSelector(state => state.listsState.selectedObject);
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);
  const isMuseum = useSelector(state => selectIsMuseum(state));

  const dispatch = useDispatch();

  const putListRemoveObject = (token, listType, listId, objectId) =>
    dispatch(putListRemoveObject(token, listType, listId, objectId));

  useEffect(() => {
    if (token && user && museumAccount) {
      dispatch(
        getCurrentCycleLists(token, user.museum.museumId, museumAccount.kind),
      );
    }
  }, [museumAccount]);

  useEffect(() => {
    return () => {
      dispatch(listsClearCuratorial());
      dispatch(listsClearInterest());
      dispatch(listsGetCuratorialClear());
      dispatch(listsGetInterestClear());

      dispatch(listsClearSelectedObject());
      dispatch(listsClearCuratorialAction());
      dispatch(listsPutCuratorialApproveClear());
      dispatch(listsPutCuratorialRemoveClear());
    };
  }, []);

  const handleClickOutside = _ => {
    if (
      listCuratorial &&
      typeof listCuratorial === 'object' &&
      listCuratorial.objects
    ) {
      const objects = JSON.parse(JSON.stringify(listCuratorial.objects));
      const keys = Object.keys(objects);
      keys.forEach(k => {
        objects[k].showMore = false;
      });
      dispatch(listsSetCuratorialObjects(objects));
    }
  };

  const handleMoreMenuClick = key => {
    const objects = JSON.parse(JSON.stringify(listCuratorial.objects));
    const keys = Object.keys(objects);
    keys.forEach(k => {
      if (k === key) {
        objects[k].showMore = true;
      } else {
        objects[k].showMore = false;
      }
    });
    dispatch(listsSetCuratorialObjects(objects));
  };

  const makeCards = _ => {
    const makeEmptyState = _ => {
      return (
        <div className="lst-empty-state-container">
          <p className="mx-auto text-center lst-empty-state-header">
            No Objects
          </p>
          <p className="mx-auto text-center lst-empty-state-text">
            Submit objects from your <br />
            <Link to="/institution/lists/interest" className="gen-link">
              objects of interest
            </Link>
          </p>
        </div>
      );
    };

    if (
      listCuratorial &&
      listCuratorial.objects &&
      typeof listCuratorial.objects == 'object'
    ) {
      const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

      const keys = Object.keys(listCuratorial.objects);
      if (!keys.length) {
        return makeEmptyState();
      }
      return keys.map(k => {
        return (
          <div key={`lst_object_card_${k}`}>
            <div className="lst-card">
              {listCuratorial.objects[k].approved && (
                <div className="lst-card-banner-approved d-flex">
                  <img
                    src={CheckmarkGrey}
                    alt="checkmark-grey"
                    className="lst-card-banner-approved-icon"
                  />
                  <div>
                    <p className="lst-card-banner-approved-txt">
                      APPROVED FOR PROPOSAL CREATION
                    </p>
                    <Link
                      to={`/institution/proposals/active`}
                      className="gen-link-no-decoration"
                    >
                      <p className="lst-card-banner-approved-link">
                        GO TO PROPOSALS
                      </p>
                    </Link>
                  </div>
                </div>
              )}
              <div className="lst-card-banner-low d-flex">
                <div className="lst-initials-bg">
                  <p className="lst-initials-fg text-center">
                    {getInitials(listCuratorial.objects[k].userName)}
                  </p>
                </div>
                <p className="lst-card-banner-low-txt">
                  {listCuratorial.objects[k].userName}
                </p>
              </div>
              <img
                src={`https://res.cloudinary.com/ex-tech/image/fetch/h_542,w_576,c_fill,q_auto/https://${listCuratorial.objects[k].imageBucket}.s3.amazonaws.com/${listCuratorial.objects[k].imageId}`}
                alt={`object-${listCuratorial.objects[k]}`}
                className="lst-obj-img spc-mb-20"
              />
              <div className="d-flex">
                <div className="lst-card-info-container">
                  <p className="txt-rubik-dark-16 lst-card-info-artist gen-crop-txt-1">
                    {listCuratorial.objects[k].artistName}
                  </p>
                  <p>
                    <span className="txt-thin-italic-15 gen-crop-txt-1">
                      {listCuratorial.objects[k].title},
                    </span>{' '}
                    <span className="txt-light-15">
                      {listCuratorial.objects[k].year}
                    </span>
                  </p>
                </div>
                {!listCuratorial.objects[k].approved && (
                  <a
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleMoreMenuClick(k);
                      dispatch(listsClearSelectedObject());
                      dispatch(listsClearCuratorialAction());
                      dispatch(listsPutCuratorialApproveClear());
                      dispatch(listsPutCuratorialRemoveClear());
                    }}
                    href="# "
                    className="gen-link-no-decoration"
                  >
                    <img
                      src={MoreIcon}
                      alt="more-menu-button"
                      className="lst-more-btn"
                    />
                  </a>
                )}
              </div>
            </div>
            {listCuratorial.objects[k].showMore && (
              <div
                className="lst-card-overlay"
                onClick={e => e.stopPropagation()}
              >
                {userIsAdmin ? (
                  <div className="mx-auto lst-card-overlay-content">
                    <div className="mx-auto lst-card-overlay-content-inner">
                      <a
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(listsSetCuratorialAction('approve'));
                          dispatch(
                            listsSetSelectedObject(listCuratorial.objects[k]),
                          );
                        }}
                        href="# "
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          Approve Object for
                          <br />
                          Proposal Creation
                        </p>
                      </a>
                      <div className="gen-break-light lst-card-overlay-content-inner-break"></div>
                      <a
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(listsSetCuratorialAction('reject'));
                          dispatch(
                            listsSetSelectedObject(listCuratorial.objects[k]),
                          );
                        }}
                        href="# "
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          Decline Object for
                          <br />
                          Donation Pursuit
                        </p>
                      </a>
                      <div className="gen-break-light"></div>
                      <Link
                        to={`/institution/catalogue/object/${k}`}
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          View Object Details
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto lst-card-overlay-content">
                    <div className="mx-auto lst-card-overlay-content-inner">
                      <Link
                        to={`/institution/catalogue/object/${k}`}
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          View Object Details
                        </p>
                      </Link>
                      <div className="gen-break-light lst-card-overlay-content-inner-break"></div>
                      <div className="lst-card-overlay-content-inner-note-container">
                        <p className="lst-card-overlay-content-inner-note">
                          Only admin members of your{' '}
                          {isMuseum ? 'museum' : 'institution'} can approve an
                          object for proposal creation
                          <br />
                          <br />{' '}
                          <a
                            href="mailto:museum_liaison@museumexchange.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="gen-link"
                          >
                            Contact us
                          </a>{' '}
                          with questions or if you require assistance
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      });
    }

    return makeEmptyState();
  };

  const makeModalContent = _ => {
    if (curatorialAction === 'approve') {
      if (putListApproveObjectRequest.data) {
        return (
          <div className="list-int-modal-content ">
            <div className="d-flex justify-content-center mx-auto">
              <img
                src={ApprovedCheck}
                alt="approved-check"
                className="mod-approved-check"
              />
              <p className="mod-txt-header">Approved</p>
            </div>
            <div className="mod-content-object-info-container mx-auto">
              <p className="mod-txt-18">
                {selectedObject.artistName}, <i>{selectedObject.title}</i>
              </p>
            </div>
            <div className="spc-mb-30">
              <p className="mod-txt-header">for proposal creation</p>
            </div>
            <p className="mod-txt-18">
              Staff members will now be able to draft a<br />
              proposal for this object. <b>{selectedObject.userName}</b> will
              <br />
              be notified.
            </p>
            <div className="mod-btn-container mx-auto d-flex justify-content-center">
              <button
                className="btn mod-btn-dark"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(listsClearSelectedObject());
                  dispatch(listsClearCuratorialAction());
                  dispatch(listsPutCuratorialApproveClear());
                }}
              >
                OKAY
              </button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="list-int-modal-content spc-mt-30">
            <p className="mod-txt-header">
              Are you sure you
              <br />
              would like to approve
            </p>
            <div className="mod-content-object-info-container mx-auto">
              <p className="mod-txt-18">
                {selectedObject.artistName}, <i>{selectedObject.title}</i>
              </p>
            </div>
            <p className="mod-txt-header">for proposal creation?</p>
            <div className="mod-btn-container mx-auto d-flex justify-content-between">
              <button
                className="btn mod-btn-light"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(listsClearSelectedObject());
                  dispatch(listsClearCuratorialAction());
                }}
              >
                CANCEL
              </button>
              <button
                className="btn mod-btn-dark"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(
                    putListCuratorialApproveObject(
                      token,
                      listCuratorial.id,
                      selectedObject.id,
                    ),
                  );
                }}
              >
                CONFIRM & APPROVE
              </button>
            </div>
          </div>
        );
      }
    } else if (curatorialAction === 'reject') {
      return (
        <div className="list-int-modal-content spc-mt-30">
          <p className="mod-txt-header">
            Are you sure you
            <br />
            would like to decline
          </p>
          <div className="mod-content-object-info-container mx-auto">
            <p className="mod-txt-18">
              {selectedObject.artistName}, <i>{selectedObject.title}</i>
            </p>
          </div>
          <p className="mod-txt-header">for donation pursuit?</p>
          <div className="mod-btn-container mx-auto d-flex justify-content-between">
            <button
              className="btn mod-btn-light"
              onClick={e => {
                e.stopPropagation();
                dispatch(listsClearSelectedObject());
                dispatch(listsClearCuratorialAction());
              }}
            >
              CANCEL
            </button>
            <button
              className="btn mod-btn-dark"
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  putListRemoveObject(
                    token,
                    'curatorial',
                    listCuratorial.id,
                    selectedObject.id,
                  ),
                );
              }}
            >
              CONFIRM & DECLINE
            </button>
          </div>
        </div>
      );
    }
  };

  if (cyclesSorted && listCuratorial && user && museumAccount) {
    const museumHasAdmin = validateMuseumHasAdmin(museumAccount);

    let listEmpty = true;
    if (listCuratorial.objects && typeof listCuratorial.objects == 'object') {
      const keys = Object.keys(listCuratorial.objects);
      if (keys.length) {
        listEmpty = false;
      }
    }

    return (
      <div className="gen-background-nav-museum" onClick={handleClickOutside}>
        <div className="lst-content-container mx-auto d-flex">
          <div className="lst-content-left">
            <div className="d-flex">
              <img
                src={CuratorialReviewGrey}
                alt="grey-curatorial-review"
                className="lst-grey-cr"
              />
              <p className="txt-header">Curatorial Review</p>
            </div>
            {currentCycle ? (
              <div className="lst-content-left-info">
                {isMuseum ? (
                  <>
                    <p className="txt-bold-15 lst-left-note-container-cycle">
                      {currentCycle.name.toUpperCase()}
                    </p>
                    <p className="txt-15 spc-mb-15">
                      {
                        <>
                          {getDateString(addDays(currentCycle.cutoff, 2))}{' '}
                          &ndash; {getDateString(addDays(currentCycle.end, 1))}
                        </>
                      }
                    </p>
                  </>
                ) : null}

                <div className="lst-left-note-container">
                  {!listEmpty && !museumHasAdmin ? (
                    <React.Fragment>
                      <p className="txt-14 lst-left-note-container-txt">
                        Shared folder of objects selected by{' '}
                        {isMuseum ? 'curatorial staff' : 'staff members'} to
                        pursue for acquisition.
                        <br />
                        <br />
                      </p>
                      <p className="txt-14 lst-left-note-container-txt lst-left-note-container-txt-alert">
                        <img
                          src={RedAlert}
                          alt="red-alert"
                          className="lst-left-icon-red-alert"
                        />
                        YOUR INSTITUTION DOES NOT HAVE ANY ADMIN ACCOUNTS YET.
                        <br />
                        <br />
                      </p>
                      <p className="txt-14 lst-left-note-container-txt">
                        Admin members of your institution can approve an object
                        for proposal creation.
                        <br />
                        <br />
                      </p>
                      <p className="txt-14 lst-left-note-container-txt">
                        <a
                          href="mailto:museum_liaison@museumexchange.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="gen-link"
                        >
                          Contact us
                        </a>{' '}
                        to assign an admin for your institution.
                      </p>
                    </React.Fragment>
                  ) : (
                    <p className="txt-14 lst-left-note-container-txt">
                      Shared folder of objects selected by{' '}
                      {isMuseum ? 'curatorial staff' : 'staff members'} to
                      pursue for acquisition.
                      <br />
                      <br />
                      Admin members of your institution can approve an object
                      for proposal creation.
                      <br />
                      <br />
                      <a
                        href="mailto:museum_liaison@museumexchange.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gen-link"
                      >
                        Contact us
                      </a>{' '}
                      with questions or if you require assistance.
                    </p>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="lst-cards-container d-flex justify-content-between flex-wrap">
            {makeCards()}
          </div>
        </div>
        {selectedObject &&
          curatorialAction &&
          !putListRemoveObjectRequest.data && (
            <div className="mod">
              <div className="mod-content-container mx-auto">
                {makeModalContent()}
              </div>
            </div>
          )}
      </div>
    );
  } else {
    return <div className="gen-background-nav-museum"></div>;
  }
}
