import {
  MSET_PUT_MUSEUM_CLEAR,
  MSET_PUT_MUSEUM_FAILURE,
  MSET_PUT_MUSEUM_REQUEST,
  MSET_PUT_MUSEUM_SUCCESS,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_CLEAR,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_FAILURE,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_REQUEST,
  MSET_PUT_MUSEUM_USER_PERMISSIONS_SUCCESS,
  MSET_PUT_MUSEUM_USER_POSITION_CLEAR,
  MSET_PUT_MUSEUM_USER_POSITION_FAILURE,
  MSET_PUT_MUSEUM_USER_POSITION_REQUEST,
  MSET_PUT_MUSEUM_USER_POSITION_SUCCESS,
  MSET_SET_PERMISSIONS_CARD_HOVER,
  MSET_SET_SELECTED_USER,
  MSET_SET_SELECTED_USER_ADMIN_TOGGLE,
  MSET_UPDATE_NEW_POSITION,
  MSET_UPDATE_NEW_POSITION_ERROR,
  MSET_RESET_FORM,
  MSET_UPDATE_FORM_ERRORS,
  MSET_UPDATE_NAME,
  MSET_UPDATE_LOCATION_CITY,
  MSET_UPDATE_LOCATION_STATE,
  MSET_UPDATE_URL,
} from '../actionTypes';

export const msetPutMuseumClear = _ => {
  return {
    type: MSET_PUT_MUSEUM_CLEAR,
  };
};

export const msetPutMuseumFailure = payload => {
  return {
    type: MSET_PUT_MUSEUM_FAILURE,
    payload,
  };
};

export const msetPutMuseumRequest = payload => {
  return {
    type: MSET_PUT_MUSEUM_REQUEST,
    payload,
  };
};

export const msetPutMuseumSuccess = payload => {
  return {
    type: MSET_PUT_MUSEUM_SUCCESS,
    payload,
  };
};

export const msetPutMuseumUserPermissionsClear = _ => {
  return {
    type: MSET_PUT_MUSEUM_USER_PERMISSIONS_CLEAR,
  };
};

export const msetPutMuseumUserPermissionsFailure = payload => {
  return {
    type: MSET_PUT_MUSEUM_USER_PERMISSIONS_FAILURE,
    payload,
  };
};

export const msetPutMuseumUserPermissionsRequest = payload => {
  return {
    type: MSET_PUT_MUSEUM_USER_PERMISSIONS_REQUEST,
    payload,
  };
};

export const msetPutMuseumUserPermissionsSuccess = payload => {
  return {
    type: MSET_PUT_MUSEUM_USER_PERMISSIONS_SUCCESS,
    payload,
  };
};

export const msetPutMuseumUserPositionClear = _ => {
  return {
    type: MSET_PUT_MUSEUM_USER_POSITION_CLEAR,
  };
};

export const msetPutMuseumUserPositionFailure = payload => {
  return {
    type: MSET_PUT_MUSEUM_USER_POSITION_FAILURE,
    payload,
  };
};

export const msetPutMuseumUserPositionRequest = payload => {
  return {
    type: MSET_PUT_MUSEUM_USER_POSITION_REQUEST,
    payload,
  };
};

export const msetPutMuseumUserPositionSuccess = payload => {
  return {
    type: MSET_PUT_MUSEUM_USER_POSITION_SUCCESS,
    payload,
  };
};

export const msetSetPermissionsCardHover = payload => {
  return {
    type: MSET_SET_PERMISSIONS_CARD_HOVER,
    payload,
  };
};

export const msetSetSelectedUser = payload => {
  return {
    type: MSET_SET_SELECTED_USER,
    payload,
  };
};

export const msetSetSelectedUserAdminToggle = payload => {
  return {
    type: MSET_SET_SELECTED_USER_ADMIN_TOGGLE,
    payload,
  };
};

export const msetUpdateNewPosition = payload => {
  return {
    type: MSET_UPDATE_NEW_POSITION,
    payload,
  };
};

export const msetUpdateNewPositionError = payload => {
  return {
    type: MSET_UPDATE_NEW_POSITION_ERROR,
    payload,
  };
};

export const msetResetForm = _ => {
  return {
    type: MSET_RESET_FORM,
  };
};

export const msetUpdateFormErrors = payload => {
  return {
    type: MSET_UPDATE_FORM_ERRORS,
    payload,
  };
};

export const msetUpdateName = payload => {
  return {
    type: MSET_UPDATE_NAME,
    payload,
  };
};

export const msetUpdateLocationCity = payload => {
  return {
    type: MSET_UPDATE_LOCATION_CITY,
    payload,
  };
};

export const msetUpdateLocationState = payload => {
  return {
    type: MSET_UPDATE_LOCATION_STATE,
    payload,
  };
};

export const msetUpdateURL = payload => {
  return {
    type: MSET_UPDATE_URL,
    payload,
  };
};
