import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  ART_PROFESSIONS,
  CURRENT_PRIVACY_POLICY_HASH,
  CURRENT_TOS_HASH,
} from '../../config';
import {
  donorApplicationReset,
  donorApplicationUpdateCity,
  donorApplicationUpdateConsent,
  donorApplicationUpdateErrors,
  donorApplicationUpdateProfession,
  donorApplicationUpdateState,
  donorApplicationUpdateType,
  postDonorApplicationClear,
} from '../../actions';
import { postDonorApplication } from '../../requests';
import '../../styles/general.css';

class DonorApplication extends Component {
  static get propTypes() {
    return {
      consent: PropTypes.bool,
      donorType: PropTypes.string,
      formErrors: PropTypes.object,
      header: PropTypes.bool,
      locationCity: PropTypes.string,
      locationState: PropTypes.string,
      postApplication: PropTypes.func,
      postApplicationClear: PropTypes.func,
      postApplicationRequest: PropTypes.object,
      profession: PropTypes.string,
      reset: PropTypes.func,
      token: PropTypes.object,
      updateCity: PropTypes.func,
      updateConsent: PropTypes.func,
      updateErrors: PropTypes.func,
      updateProfession: PropTypes.func,
      updateState: PropTypes.func,
      updateType: PropTypes.func,
    };
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.postApplicationClear();
  }

  handleUpdateCity = locationCity => {
    this.props.updateCity({ locationCity });
  };

  handleUpdateState = locationState => {
    this.props.updateState({ locationState });
  };

  handleUpdateProfession = profession => {
    this.props.updateProfession({ profession });
  };

  handleUpdateType = donorType => {
    if (donorType === 'collector') {
      this.props.updateProfession({ profession: '' });
    }
    this.props.updateType({ donorType });
  };

  handleUpdateConsent = _ => {
    this.props.updateConsent({ consent: !this.props.consent });
  };

  validateFormAndSubmit = _ => {
    if (this.props.postApplicationRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      consentError: false,
      donorTypeError: false,
      locationCityError: false,
      locationStateError: false,
      professionError: false,
    };

    if (this.props.donorType === '') {
      errors.formError = true;
      errors.donorTypeError = true;
    }

    if (
      this.props.donorType === 'representative' &&
      this.props.profession === ''
    ) {
      errors.formError = true;
      errors.professionError = true;
    }

    if (this.props.locationCity === '') {
      errors.formError = true;
      errors.locationCityError = true;
    }

    if (this.props.locationState.length !== 2) {
      errors.formError = true;
      errors.locationStateError = true;
    }

    if (!this.props.consent) {
      errors.formError = true;
      errors.consentError = true;
    }

    this.props.updateErrors({ formErrors: errors });

    if (errors.formError) {
      return;
    }

    this.props.postApplication(this.props.token, {
      privacyPolicyHash: CURRENT_PRIVACY_POLICY_HASH,
      tosHash: CURRENT_TOS_HASH,
      donorProfile: {
        donorType: this.props.donorType,
        profession: this.props.profession || undefined,
        locationCity: this.props.locationCity,
        locationState: this.props.locationState,
      },
    });
  };

  render() {
    if (this.props.postApplicationRequest.data) {
      return <Redirect push to="/user/donor/pending" />;
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <p className="header text-center mex-mt-47">Donor Application</p>
        <div className="sub-header mx-auto mex-mb-30">
          <p className="text text-center">
            Please complete this form to submit your application for Museum
            Exchange
          </p>
        </div>
        <div className="donor-application-content mx-auto">
          <div className="donor-application-content-inner d-flex justify-content-between">
            <div className="donor-application-content-inner-left">
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formErrors.donorTypeError ? 'label-error' : ''
                  }`}
                >
                  Whose collection will you be listing on the platform?
                </p>
                <a href="# " className="link-no-decoration">
                  <div
                    className={`${
                      this.props.donorType === 'collector'
                        ? 'donor-account-type-selector-selected'
                        : 'donor-account-type-selector'
                    } mex-mb-12`}
                    onClick={_ => this.handleUpdateType('collector')}
                  >
                    <div className="mx-auto d-flex">
                      <div
                        className={`rounded-circle radio-spaced ${
                          this.props.donorType === 'collector'
                            ? 'radio-selected'
                            : 'radio-deselected'
                        }`}
                      ></div>
                      <p className="text">My Collection</p>
                    </div>
                  </div>
                </a>
                <a href="# " className="link-no-decoration">
                  <div
                    className={`${
                      this.props.donorType === 'representative'
                        ? 'donor-account-type-selector-selected'
                        : 'donor-account-type-selector'
                    }`}
                    onClick={_ => this.handleUpdateType('representative')}
                  >
                    <div className="mx-auto d-flex">
                      <div
                        className={`rounded-circle radio-spaced donor-application-radio-spaced ${
                          this.props.donorType === 'representative'
                            ? 'radio-selected'
                            : 'radio-deselected'
                        }`}
                      ></div>
                      <div>
                        <p className="text">Other Collections</p>
                        <p className="text-small">REPRESENTATIVE/AGENT</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {this.props.donorType === 'representative' && (
                <div className="mex-mb-30">
                  <p
                    className={`label-bold mex-mb-12 ${
                      this.props.formErrors.professionError ? 'label-error' : ''
                    }`}
                  >
                    Profession
                  </p>
                  <select
                    className={`btn dropdown-toggle mex-dropdown ${
                      this.props.profession
                        ? 'mex-dropdown-selection'
                        : 'mex-dropdown-no-selection'
                    } ${
                      this.props.formErrors.professionError
                        ? 'mex-dropdown-error'
                        : ''
                    }`}
                    style={{ width: '100%' }}
                    onChange={event =>
                      this.handleUpdateProfession(event.target.value)
                    }
                    value={this.props.profession}
                  >
                    <option disabled value="">
                      Select your profession
                    </option>
                    {ART_PROFESSIONS.map((v, i) => (
                      <option key={`artProfession_${i}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="mex-mb-30">
                <p className="label-bold mex-mb-12">Primary Location</p>
                <p
                  className={`label ${
                    this.props.formErrors.locationCityError ? 'label-error' : ''
                  }`}
                >
                  CITY
                </p>
                <input
                  type="text"
                  className={`input mex-mb-12 ${
                    this.props.formErrors.locationCityError ? 'input-error' : ''
                  }`}
                  onChange={event => this.handleUpdateCity(event.target.value)}
                  value={this.props.locationCity}
                  placeholder="City"
                ></input>
                <p
                  className={`label ${
                    this.props.formErrors.locationStateError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  STATE / PROVINCE
                </p>
                <input
                  type="text"
                  className={`input ${
                    this.props.formErrors.locationStateError
                      ? 'input-error'
                      : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateState(event.target.value.toUpperCase())
                  }
                  value={this.props.locationState}
                  maxLength="2"
                  placeholder="State / Province"
                ></input>
              </div>
              <div className="mex-mb-30 d-flex">
                <input
                  type="checkbox"
                  checked={this.props.consent}
                  onChange={this.handleUpdateConsent}
                  className="donor-museum-application-checkbox"
                />
                <a
                  href="# "
                  className="link-no-decoration"
                  onClick={e => e.preventDefault()}
                >
                  <p
                    className={`text ${
                      this.props.formErrors.consentError ? 'label-error' : ''
                    }`}
                    onClick={this.handleUpdateConsent}
                  >
                    I agree to Museum Exchange's{' '}
                    <a
                      href="https://www.museumexchange.com/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()}
                      className="gen-link"
                    >
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://www.museumexchange.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()}
                      className="gen-link"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </a>
              </div>
              <button
                className="btn btn-primary mex-btn-primary mex-btn-primary-fill"
                style={{ width: '100%' }}
                onClick={this.validateFormAndSubmit}
              >
                Submit Application
              </button>
              {this.props.postApplicationRequest.error && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9">
                  Please try again.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    consent: state.donorApplicationState.formData.consent,
    donorType: state.donorApplicationState.formData.donorType,
    formErrors: state.donorApplicationState.formErrors,
    locationCity: state.donorApplicationState.formData.locationCity,
    locationState: state.donorApplicationState.formData.locationState,
    postApplicationRequest: state.userState.postDonorApplicationRequest,
    profession: state.donorApplicationState.formData.profession,
    token: state.authState.token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    reset: _ => dispatch(donorApplicationReset()),
    updateCity: payload => dispatch(donorApplicationUpdateCity(payload)),
    updateConsent: payload => dispatch(donorApplicationUpdateConsent(payload)),
    updateErrors: payload => dispatch(donorApplicationUpdateErrors(payload)),
    updateProfession: payload =>
      dispatch(donorApplicationUpdateProfession(payload)),
    updateState: payload => dispatch(donorApplicationUpdateState(payload)),
    updateType: payload => dispatch(donorApplicationUpdateType(payload)),
    postApplication: (token, payload) =>
      dispatch(postDonorApplication(token, payload)),
    postApplicationClear: _ => dispatch(postDonorApplicationClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorApplication);
