import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export function ButtonRound({ children, icon, onClick, className = '' }) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'tw-relative tw-flex tw-w-full tw-min-w-[178px] tw-items-center tw-rounded-2xl tw-border tw-border-gray-800 tw-bg-transparent tw-py-2 tw-px-7 tw-text-center tw-text-xs tw-text-gray-600',
        icon ? 'tw-justify-between' : 'tw-justify-center',
        className,
      )}
    >
      <span className="">{children}</span>
      {icon && <img src={icon} alt="" className="tw-h-4 tw-w-4" />}
    </button>
  );
}

ButtonRound.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};
