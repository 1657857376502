import { useCurrentDonorAccount } from 'components/_shared/useCurrentDonorAccount';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePutMoveObjectMutation } from 'requests/api';

export default function MoveObjectModal() {
  const dispatch = useDispatch();

  const selectedObject = useSelector(
    state => state.donorAccountsState.selectedObject,
  );

  const selectedAccount = useCurrentDonorAccount();

  const [putMoveObject, { isLoading }] = usePutMoveObjectMutation();

  const destFolder = useSelector(state => state.donorAccountsState.destFolder);

  return (
    <div
      className="mod"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="da-move-modal-content mx-auto">
        <p className="da-move-modal-text-top">
          Are you sure you would like to move this object?
        </p>
        <p className="da-move-modal-text-bottom">
          By moving the object, you will be transferring ownership of the object
          to the owner of the destination folder.
        </p>
        <div className="d-flex justify-content-center">
          <button
            className="da-move-modal-btn-cancel gen-cursor-hover-link tw-uppercase"
            onClick={e => {
              dispatch(dacctActions.dacctSetMoveModalOpen(false));
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            Cancel
          </button>
          <button
            className="da-move-modal-btn-ok gen-cursor-hover-link tw-uppercase"
            disabled={isLoading}
            onClick={e => {
              if (isLoading) return;

              putMoveObject({
                accountId: selectedAccount.id,
                objectId: selectedObject.id,
                destId: destFolder.id,
              });
            }}
          >
            Move
          </button>
        </div>
      </div>
    </div>
  );
}
