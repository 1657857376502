import DeleteIcon from '@images/delete-icon.png';
import HelpIcon from '@images/help-icon.png';
import InfoIcon from '@images/info-icon.png';
import MoveIcon from '@images/menu-move-icon.png';
import TransferIcon from '@images/transfer-icon.png';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { useCurrentDonorAccountRole } from 'components/_shared/useDonorAccountRole';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import { useGetDonorAccountsQuery, useGetNetworkQuery } from 'requests/api';

// TODO: these should be made accessible by button / keyboard.
function TransferObjectOption() {
  const dispatch = useDispatch();

  return (
    <button
      className="da-obj-lst-card-more-menu-item d-flex"
      onClick={_ => {
        dispatch(dacctActions.dacctSetTransferModalOpen(true));
      }}
    >
      <img
        src={TransferIcon}
        alt="transfer"
        className="da-obj-lst-card-more-menu-item-icon-move"
      />
      <p className="da-obj-lst-card-txt">Transfer Ownership</p>
    </button>
  );
}

function MoveObjectOption() {
  const dispatch = useDispatch();
  return (
    <button
      className="da-obj-lst-card-more-menu-item d-flex"
      onClick={_ => {
        dispatch(dacctActions.dacctSetDestFolder(null));
        dispatch(dacctActions.dacctSetMoveMenuOpen(true));
      }}
    >
      <img
        src={MoveIcon}
        alt="move"
        className="da-obj-lst-card-more-menu-item-icon-move"
      />
      <p className="da-obj-lst-card-txt">Move Object</p>
    </button>
  );
}

function DeleteObjectOption() {
  const dispatch = useDispatch();

  return (
    <a
      href="# "
      className="link link-no-decoration"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(dacctActions.dacctDeleteDraftClear());
        dispatch(dacctActions.dacctSetDraftDeleteModalOpen(true));
      }}
    >
      <div className="da-obj-lst-card-more-menu-item d-flex">
        <img
          src={DeleteIcon}
          alt="delete"
          className="da-obj-lst-card-more-menu-item-icon-delete"
        />
        <p className="da-obj-lst-card-txt">Delete object draft</p>
      </div>
    </a>
  );
}

function GetHelpWithObjectOption() {
  return (
    <a
      href="mailto:help@museumexchange.com"
      target="_blank"
      rel="noopener noreferrer"
      className="link link-no-decoration"
    >
      <div className="da-obj-lst-card-more-menu-item d-flex">
        <img
          src={HelpIcon}
          alt="help"
          className="da-obj-lst-card-more-menu-item-icon"
        />
        <p className="da-obj-lst-card-txt">Get Help with an object</p>
      </div>
    </a>
  );
}

function ViewObjectDetailsOption({ objectId, accountId }) {
  return (
    <Link
      to={`/donor/folders/${accountId}/object/${objectId}/preview`}
      className="gen-link-no-decoration"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div className="da-obj-lst-card-more-menu-item d-flex">
        <img
          src={InfoIcon}
          alt="details"
          className="da-obj-lst-card-more-menu-item-icon"
        />
        <p className="da-obj-lst-card-txt">View object details</p>
      </div>
    </Link>
  );
}

export function DonorAccountActiveObjectActionsPopover({
  object,
  accountId,
  accounts,
  network,
}) {
  const currentDonorAccountRole = useCurrentDonorAccountRole();

  return (
    <div
      className="da-obj-lst-card-more-menu"
      onClick={e => e.stopPropagation()}
    >
      {accounts && currentDonorAccountRole === DONOR_ACCESS_ROLES.owner ? (
        <MoveObjectOption />
      ) : null}
      {network && currentDonorAccountRole === DONOR_ACCESS_ROLES.owner ? (
        <TransferObjectOption />
      ) : null}
      {object.status === 'draft' ? (
        <React.Fragment>
          {[DONOR_ACCESS_ROLES.owner, DONOR_ACCESS_ROLES.editor].includes(
            currentDonorAccountRole,
          ) ? (
            <DeleteObjectOption />
          ) : (
            <ViewObjectDetailsOption
              accountId={accountId}
              objectId={object.id}
            />
          )}
          <GetHelpWithObjectOption />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ViewObjectDetailsOption objectId={object.id} accountId={accountId} />
          <GetHelpWithObjectOption />
        </React.Fragment>
      )}
    </div>
  );
}

export function DonorAccountInactiveObjectActionsPopover({ object }) {
  const userId = useSelector(state => state.authState.token.userId);

  const { data: network } = useGetNetworkQuery(userId);
  const { data: accounts } = useGetDonorAccountsQuery(userId);

  const currentDonorAccountRole = useCurrentDonorAccountRole();

  return (
    <div
      className="da-obj-lst-card-more-menu"
      onClick={e => e.stopPropagation()}
    >
      <React.Fragment>
        {accounts && currentDonorAccountRole === DONOR_ACCESS_ROLES.owner ? (
          <MoveObjectOption />
        ) : null}
        {network && currentDonorAccountRole === DONOR_ACCESS_ROLES.owner ? (
          <TransferObjectOption />
        ) : null}
        <ViewObjectDetailsOption objectId={object.id} />
        <GetHelpWithObjectOption />
      </React.Fragment>
    </div>
  );
}
