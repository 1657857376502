import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { dimensionsMapping } from '../../glue';
import {
  listsClearCuratorial,
  listsClearInterest,
  listsGetCuratorialClear,
  listsGetInterestClear,
  listsPutInterestAddClear,
  setCurImgIdx,
  setHvrL,
  setHvrR,
  setSelectedImg,
} from '../../actions';
import { getCurrentCycleLists, putListAddObject } from '../../requests';
import ArrowLeftBlack from '../../images/arrow-left-black.png';
import ArrowLeftWhite from '../../images/arrow-left-white.png';
import ArrowRightWhite from '../../images/arrow-right-white.png';
import ArrowRightBlack from '../../images/arrow-right-black.png';
import BannerBookmarkInactive from '../../images/banner-bookmark-inactive.png';
import BannerCuratorial from '../../images/banner-curatorial.png';
import '../../styles/general.css';
import '../../styles/object-detail-catalogue.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { getLastDateInCatalog } from 'components/museumComponents/ObjectTimeBadge';
import { getDaysLeftInCatalog } from '../../lib';
import { isDate } from 'date-fns';
import { convertToMMDDYY } from '@lib';
import { useGetMuseumAccountUserQuery } from 'requests/api';
import { useMuseumGetObjectSummariesInCurrentCycle } from 'components/_shared/useMuseumGetObjectSummariesInCurrentCycle';

export default function ObjectDetailsCatalogue({ object }) {
  const curImgIdx = useSelector(state => state.imageCarouselState.curImgIdx);
  const hvrL = useSelector(state => state.imageCarouselState.hvrL);
  const hvrR = useSelector(state => state.imageCarouselState.hvrR);
  const listCuratorial = useSelector(state => state.listsState.listCuratorial);
  const listInterest = useSelector(state => state.listsState.listInterest);
  const { activeObjectSummaries = [] } =
    useMuseumGetObjectSummariesInCurrentCycle();

  const putListAddObjectRequest = useSelector(
    state => state.listsState.putListInterestAddRequest,
  );
  const selectedImg = useSelector(
    state => state.imageCarouselState.selectedImg,
  );
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);
  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();
  const isMuseum = useSelector(selectIsMuseum);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token && user && user.museum) {
      dispatch(
        getCurrentCycleLists(token, user.museum.museumId, museumAccount?.kind),
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setCurImgIdx(0));
      dispatch(setHvrL(false));
      dispatch(setHvrR(false));
      dispatch(setSelectedImg(null));
      if (token.role === 'museum') {
        dispatch(listsClearCuratorial());
        dispatch(listsClearInterest());
        dispatch(listsGetCuratorialClear());
        dispatch(listsGetInterestClear());
        dispatch(listsPutInterestAddClear());
      }
    };
  }, []);

  const handleHvrL = _ => {
    dispatch(setHvrL(!hvrL));
  };

  const handleHvrR = _ => {
    dispatch(setHvrR(!hvrR));
  };

  const getImages = _ => {
    const makeImage = v => {
      if (v) {
        return (
          <a
            href="# "
            className="gen-link-no-decoration odc-img-zoom"
            onClick={e => {
              e.preventDefault();
              dispatch(setSelectedImg(v));
            }}
          >
            <img
              key={v.id}
              src={`https://res.cloudinary.com/ex-tech/image/fetch/h_840,w_890,c_pad,b_white,q_auto/https://${v.bucket}.s3.amazonaws.com/${v.imageId}`}
              alt={`object-${v.title}`}
              className="odc-img-prev"
            />
          </a>
        );
      }
      return <div className="odc-img-prev"></div>;
    };

    const makeDots = images => {
      return images.map((v, i) => {
        if (i === curImgIdx) {
          return (
            <a
              key={`dot_${i}`}
              href="# "
              className="gen-link-no-decoration"
              onClick={e => e.preventDefault()}
            >
              <div className="odc-img-dot-active"></div>
            </a>
          );
        } else {
          return (
            <a
              key={`dot_${i}`}
              href="# "
              className="gen-link-no-decoration"
              onClick={e => {
                e.preventDefault();
                dispatch(setCurImgIdx(i));
                if (i === 0) {
                  dispatch(setHvrL(false));
                }
                if (i === images.length - 1) {
                  dispatch(setHvrR(false));
                }
              }}
            >
              <div className="odc-img-dot-inactive"></div>
            </a>
          );
        }
      });
    };

    let images = [];
    images.push(object.primaryImage);
    if (Array.isArray(object.supportingImages)) {
      images = images.concat(object.supportingImages);
    }

    if (images.length > 1) {
      return (
        <div>
          <div className="d-flex">
            <div className="odc-img-side-container d-flex justify-content-start">
              {curImgIdx ? (
                <a
                  href="# "
                  className="gen-link-no-decoration"
                  onMouseEnter={_ => {
                    if (!hvrL) {
                      handleHvrL();
                    }
                  }}
                  onMouseLeave={_ => {
                    if (hvrL) {
                      handleHvrL();
                    }
                  }}
                  onClick={e => {
                    e.preventDefault();
                    const newIndex = curImgIdx - 1;
                    dispatch(setCurImgIdx(newIndex));
                    if (newIndex === 0) {
                      dispatch(setHvrL(false));
                    }
                  }}
                >
                  {hvrL ? (
                    <img
                      src={ArrowLeftBlack}
                      alt="arrow-left"
                      className="odc-img-arrow"
                    />
                  ) : (
                    <img
                      src={ArrowLeftWhite}
                      alt="arrow-left"
                      className="odc-img-arrow"
                    />
                  )}
                </a>
              ) : null}
            </div>
            <div>{makeImage(images[curImgIdx])}</div>
            <div className="odc-img-side-container d-flex justify-content-end">
              {curImgIdx < images.length - 1 ? (
                <a
                  href="# "
                  className="gen-link-no-decoration"
                  onMouseEnter={_ => {
                    if (!hvrR) {
                      handleHvrR();
                    }
                  }}
                  onMouseLeave={_ => {
                    if (hvrR) {
                      handleHvrR();
                    }
                  }}
                  onClick={e => {
                    e.preventDefault();
                    const newIndex = curImgIdx + 1;
                    dispatch(setCurImgIdx(newIndex));
                    if (newIndex === images.length - 1) {
                      dispatch(setHvrR(false));
                    }
                  }}
                >
                  {hvrR ? (
                    <img
                      src={ArrowRightBlack}
                      alt="arrow-right"
                      className="odc-img-arrow"
                    />
                  ) : (
                    <img
                      src={ArrowRightWhite}
                      alt="arrow-right"
                      className="odc-img-arrow"
                    />
                  )}
                </a>
              ) : null}
            </div>
          </div>
          <div className="odc-img-dots-container d-flex justify-content-center">
            {makeDots(images)}
          </div>
        </div>
      );
    } else if (images.length === 1) {
      return (
        <div className="d-flex">
          <div className="odc-img-side-container"></div>
          <div>{makeImage(images[0])}</div>
          <div className="odc-img-side-container"></div>
        </div>
      );
    }
  };

  const getDimensions = _ => {
    const elements = [];
    if (typeof object === 'object') {
      dimensionsMapping.forEach((v, i) => {
        if (
          (v.key === 'dimensionsVariable' || v.key === 'duration') &&
          object.dimensions[v.key]
        ) {
          elements.push(
            <div key={`dimensions_elem_${i}`} className="spc-mb-20">
              <p className="odc-small-caps">{v.display.toUpperCase()}</p>
              <div className="odc-dimensions-row d-flex">
                <p className="odc-txt">{object.dimensions[v.key]}</p>
              </div>
            </div>,
          );
        } else if (object.dimensions[v.key]) {
          elements.push(
            <div key={`dimensions_elem_${i}`} className="spc-mb-20">
              <p className="odc-small-caps">{v.display.toUpperCase()}</p>
              <div className="odc-dimensions-row d-flex">
                <span className="odc-txt">
                  {`${object.dimensions[v.key].heightImperial} x ${
                    object.dimensions[v.key].widthImperial
                  }${
                    object.dimensions[v.key].depthImperial
                      ? ` x ${object.dimensions[v.key].depthImperial}`
                      : ``
                  } in`}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </span>
                <span className="odc-txt-light">
                  {`(${object.dimensions[v.key].heightMetric} x ${
                    object.dimensions[v.key].widthMetric
                  }${
                    object.dimensions[v.key].depthMetric
                      ? ` x ${object.dimensions[v.key].depthMetric}`
                      : ``
                  } cm)`}
                </span>
              </div>
            </div>,
          );
        }
      });
    }
    return elements;
  };

  const getWeight = _ => {
    if (
      typeof object === 'object' &&
      object.weightImperial &&
      object.weightMetric
    ) {
      return (
        <div key={`weight_elem_${object.id}`} className="spc-mb-20">
          <p className="odc-small-caps">WEIGHT</p>
          <div className="odc-dimensions-row d-flex">
            <span className="odc-txt">
              {`${object.weightImperial} lb`}
              &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
            <span className="odc-txt-light">
              {`(${object.weightMetric} kg)`}
            </span>
          </div>
        </div>
      );
    }
  };

  const makeFinalRightSection = _ => {
    let inCatalogueUntil = null;

    if (!isMuseum) {
      // Note: We only show inCatalogueUntil for non museums in the ongoing catalog, since all objects may end at different times
      const objectSummary = activeObjectSummaries?.find(
        ({ id }) => id === object.id,
      );

      const daysLeft = getDaysLeftInCatalog({
        dateAddedToCatalog: objectSummary.events?.approved?.timestamp,
        firstProposalDate: objectSummary?.firstProposalDate,
      });

      const lastDateInCatalog = getLastDateInCatalog(daysLeft);

      if (isDate(lastDateInCatalog)) {
        inCatalogueUntil = (
          <div className="tw-mb-3 tw-text-center tw-text-sm tw-text-gray-500">
            In Catalogue until{' '}
            <span className="tw-font-medium">
              {convertToMMDDYY(lastDateInCatalog)}
            </span>
          </div>
        );
      }
    }

    let onListCuratorial = false;
    if (
      listCuratorial &&
      typeof listCuratorial === 'object' &&
      listCuratorial.objects &&
      typeof listCuratorial.objects === 'object'
    ) {
      if (listCuratorial.objects[object.id]) {
        onListCuratorial = true;
      }
    }

    let onListInterest = false;
    if (
      listInterest &&
      typeof listInterest === 'object' &&
      listInterest.objects &&
      typeof listInterest.objects === 'object'
    ) {
      if (listInterest.objects[object.id]) {
        onListInterest = true;
      }
    }

    let objectsOfInterest = null;

    if (onListCuratorial) {
      objectsOfInterest = (
        <div className="spc-mb-50">
          <div className="odc-banner-enabled d-flex justify-content-center spc-mb-20">
            <img
              src={BannerCuratorial}
              alt="bookmark"
              className="odc-banner-curatorial"
            />
            <p className="odc-banner-enabled-text">
              SUBMITTED FOR CURATORIAL REVIEW
            </p>
          </div>
          <p className="txt-rubik-15 text-center">
            <Link to="/institution/lists/curatorial" className="gen-link">
              GO TO CURATORIAL REVIEW
            </Link>
          </p>
        </div>
      );
    } else if (onListInterest) {
      objectsOfInterest = (
        <div className="spc-mb-50">
          <div className="odc-banner-enabled d-flex justify-content-center spc-mb-20">
            <img
              src={BannerBookmarkInactive}
              alt="bookmark"
              className="odc-banner-bookmark-disabled"
            />
            <p className="odc-banner-enabled-text">IN OBJECTS OF INTEREST</p>
          </div>
          <p className="txt-rubik-15 text-center">
            <Link to="/institution/lists/interest" className="gen-link">
              GO TO OBJECTS OF INTEREST
            </Link>
          </p>
        </div>
      );
    } else {
      objectsOfInterest = (
        <div className="spc-mb-50">
          <a
            href="# "
            className="gen-link-no-decoration"
            onClick={e => {
              e.preventDefault();
              dispatch(
                putListAddObject(token, 'interest', listInterest.id, object.id),
              );
            }}
          >
            <div className="odc-banner-disabled d-flex justify-content-center spc-mb-20">
              <img
                src={BannerBookmarkInactive}
                alt="bookmark"
                className="odc-banner-bookmark-disabled"
              />
              <p className="odc-banner-disabled-text">
                ADD TO OBJECTS OF INTEREST
              </p>
            </div>
          </a>
        </div>
      );
    }

    // Note: commenting this out for now - we're not launching tier 2 accounts with ability to submit proposal without curatorial review. May add in future though.
    // let proposal = null;

    // if (!isMuseum) {
    //   const hasProposalInProgress = false;
    //   if (hasProposalInProgress) {
    //     proposal = (
    //       <div className="spc-mb-50">
    //         <div className="tw-gap-4 odc-banner-enabled d-flex justify-content-center spc-mb-20">
    //           <ProposalIcon />
    //           <p className="odc-banner-enabled-text">DRAFT PROPOSAL</p>
    //         </div>
    //         <p className="txt-rubik-15 text-center">
    //           <Link to="/institution/proposals/active" className="gen-link">
    //             GO TO PROPOSALS
    //           </Link>
    //         </p>
    //       </div>
    //     );
    //   } else {
    //     proposal = (
    //       <div className="spc-mb-50">
    //         <button
    //           className="tw-w-full gen-link-no-decoration"
    //           onClick={e => {
    //             e.preventDefault();

    //             // TODO: This part should actually launch a modal.
    //             putListInterestApproveObject(
    //               token,
    //               listInterest.id,
    //               object.id,
    //             );
    //           }}
    //         >
    //           <div className="tw-gap-4 odc-banner-disabled d-flex justify-content-center spc-mb-20">
    //             <ProposalIcon />
    //             <p className="odc-banner-disabled-text">DRAFT PROPOSAL</p>
    //           </div>
    //         </button>
    //       </div>
    //     );
    //   }
    // }

    return (
      <div>
        {inCatalogueUntil}
        {objectsOfInterest}
        <div>
          <p className="txt-header-small text-center">Have Questions?</p>
          <p className="text-center odc-link-txt">
            Speak to an{' '}
            <a
              href="mailto:museum_liaison@museumexchange.com"
              target="_blank"
              rel="noopener noreferrer"
              className="gen-link"
            >
              Institution Liaison
            </a>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto d-flex justify-content-center odc-container">
      <div className="odc-content-left">
        <div className="odc-content-left-img-container">{getImages()}</div>
        <div className="odc-content-left-sub">
          <div className="odc-content-left-sub-header">
            <p className="txt-header-small">Object Details</p>
          </div>
          {object.inscriptions && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">
                  Signature / Inscriptions
                </p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.inscriptions}
                </p>
              </div>
            </div>
          )}
          {object.edition && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">Edition</p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">{object.edition}</p>
              </div>
            </div>
          )}
          {object.condition && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">Condition</p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.condition}
                </p>
              </div>
            </div>
          )}
          {object.restrictions && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">Donor Conditions</p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.restrictions}
                </p>
              </div>
            </div>
          )}
          {object.locationCity && object.locationState && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">Object Location</p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.locationCity}, {object.locationState}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="odc-content-left-sub">
          <div className="odc-content-left-sub-header">
            <p className="txt-header-small">Cataloguing Information</p>
          </div>
          <div className="odc-content-left-sub-row d-flex">
            <div className="odc-content-left-sub-row-key-container">
              <p className="odc-content-left-sub-row-key">Provenance</p>
            </div>
            <div className="odc-content-left-sub-row-val-container">
              <p className="odc-content-left-sub-row-val">
                {object.provenance}
              </p>
            </div>
          </div>
          {object.exhibitionHistory && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">
                  Exhibition History
                </p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.exhibitionHistory}
                </p>
              </div>
            </div>
          )}
          {object.literature && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">Literature</p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.literature}
                </p>
              </div>
            </div>
          )}
          {object.conservationHistory && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">
                  Conservation History
                </p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">
                  {object.conservationHistory}
                </p>
              </div>
            </div>
          )}
          {object.notes && (
            <div className="odc-content-left-sub-row d-flex">
              <div className="odc-content-left-sub-row-key-container">
                <p className="odc-content-left-sub-row-key">Catalogue Notes</p>
              </div>
              <div className="odc-content-left-sub-row-val-container">
                <p className="odc-content-left-sub-row-val">{object.notes}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="odc-content-right">
        <div className="odc-content-right-title-container">
          <p className="txt-header-medium">{object.artistName}</p>
        </div>
        <div className="gen-break-light odc-break"></div>
        <div className="spc-mb-40">
          <div className="spc-mb-20">
            <p className="odc-txt">
              <i>{object.title}</i>, {object.year}
            </p>
          </div>
          <div className="spc-mb-20">
            <p className="odc-txt">{object.medium}</p>
          </div>
          {getDimensions()}
          {getWeight()}
        </div>
        <div className="gen-break-light odc-break"></div>
        {user.accountType === 'museum' && listCuratorial && listInterest && (
          <div>{makeFinalRightSection()}</div>
        )}
      </div>
      {selectedImg && (
        <Lightbox
          mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${selectedImg.bucket}.s3.amazonaws.com/${selectedImg.imageId}`}
          onCloseRequest={_ => dispatch(setSelectedImg(null))}
          imagePadding={65}
          clickOutsideToClose={false}
        />
      )}
    </div>
  );
}

function ProposalIcon({ color = '#C2D1D9' }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="nonzero" stroke={color} fill="none">
        <path
          d="M14.556 1.175v9.907l-4.408 4.876H1V1.175h13.556z"
          strokeWidth="2"
        />
        <path
          strokeWidth=".5"
          fill={color}
          d="M3.444 4.572v.799h7.945v-.8zM3.444 6.969v.798h7.945V6.97zM7.778 10.963v-.799H4.167v.8zM9.222 13.36l2.89-3.196h-2.89z"
        />
      </g>
    </svg>
  );
}
