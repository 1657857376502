import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ddonmResetAll, objDraftReset } from '@actions';
import { getInitials } from '@lib';

import AddObjBtnIcon from '@images/add-obj-btn-icon.png';
import ShareFolderIcon from '@images/share-folder-icon.png';
import AddObjectsToCollectionImg from '@images/add-objects-to-collection.png';
import '@styles/donor-account.css';
import '@styles/general.css';
import '@styles/modal.css';
import '@styles/people-picker-modal.css';
import '@styles/spacing.css';
import '@styles/text.css';
import DonorAccountShareModal from './DonorAccountShareModal';
import DonorAccountEditAccessModal from './DonorAccountEditAccessModal';
import { partition } from 'lodash';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { getRoleDetails } from 'lib/getRoleDetails/getRoleDetails';
import { Tooltip } from 'components/_shared/Tooltip';
import { ButtonRound } from 'components/_shared/Button';
import { ChevronRightIcon } from '@heroicons/react/solid';
import DonorAccountTransferObjectModal from './DonorAccountTransferObject/DonorAccountTransferObjectModal';
import DonorAccountTransferEmailModal from './DonorAccountTransferObject/DonorAccountTransferEmailModal';
import MoveObjectModal from './MoveObjectModal';
import { useCurrentDonorAccountRole } from 'components/_shared/useDonorAccountRole';
import { DeleteObjectDraftModal } from './DeleteObjectDraftModal';
import { NestedSection } from 'components/_shared/NestedSection';
import { DonorObjectsList } from './DonorObjectsList';
import DonorAccountActionsDropdown from './DonorAccountActionsDropdown';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import {
  useGetCyclesSortedQuery,
  useGetDonorDonationsQuery,
  useGetDonorAccountActiveObjectsQuery,
  useGetDonorAccountInactiveObjectsQuery,
  useGetDonorAccountQuery,
  useGetDonorAccountUsersQuery,
  useGetMuseumAccountsSimpleQuery,
  useGetProposalsDonorAccountQuery,
  usePutDonorAccountMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function DonorAccount() {
  const [isEditAccessModalOpen, setIsEditAccessModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const dispatch = useDispatch();

  function openShareModal() {
    setIsShareModalOpen(true);
  }
  function closeShareModal() {
    setIsShareModalOpen(false);
  }
  function openEditAccessModal() {
    setIsEditAccessModalOpen(true);
  }
  function closeEditAccessModal() {
    setIsEditAccessModalOpen(false);
  }

  const { accountId } = useParams();

  const {
    data: account,
    isLoading: isAccountLoading,
    isError: isAccountLoadingError,
  } = useGetDonorAccountQuery(accountId);

  const currentDonorAccountRole = useCurrentDonorAccountRole();

  const { data: activeObjects, isLoading: isLoadingActiveObjects } =
    useGetDonorAccountActiveObjectsQuery(accountId);
  const { data: inactiveObjects, isLoading: isLoadingInactiveObjects } =
    useGetDonorAccountInactiveObjectsQuery(accountId);

  const accountHasItems =
    activeObjects?.length > 0 || inactiveObjects?.length > 0;
  const accountIsEmpty = !accountHasItems;

  const { data: cyclesSorted } = useGetCyclesSortedQuery();

  const moveModalOpen = useSelector(
    state => state.donorAccountsState.moveModalOpen,
  );

  const [putDonorAccount] = usePutDonorAccountMutation();

  const selectedAccount = account;

  const { data: proposals, isLoading: isLoadingProposals } =
    useGetProposalsDonorAccountQuery(selectedAccount?.id, {
      skip: !selectedAccount,
    });

  const { data: selectedAccountUsers } =
    useGetDonorAccountUsersQuery(accountId);

  const { data: museums } = useGetMuseumAccountsSimpleQuery();

  const { data: donations, isLoading: isDonationsLoading } =
    useGetDonorDonationsQuery(selectedAccount?.id, {
      skip: !selectedAccount,
    });

  const transferModalOpen = useSelector(
    state => state.donorAccountsState.transferModalOpen,
  );
  const transferEmailModalOpen = useSelector(
    state => state.donorAccountsState.transferEmailModalOpen,
  );

  const history = useHistory();
  useEffect(() => {
    if (!accountId) {
      history.push('/donor');
    }
  }, [accountId]);

  useEffect(
    function resetState() {
      dispatch(ddonmResetAll());
      dispatch(objDraftReset());
      dispatch(dacctActions.dacctSetSelectedObject(null));
    },
    [dispatch],
  );

  const isLoading =
    isAccountLoading ||
    isLoadingActiveObjects ||
    isLoadingInactiveObjects ||
    isLoadingProposals ||
    isDonationsLoading;

  if (isLoading) return null;

  if (isAccountLoadingError) {
    return (
      <div>
        <p className="text-center da-error-txt">
          Error getting donor information. Please try again.
        </p>
      </div>
    );
  }

  if (!account) return null;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="gen-background-nav-donor"
        onKeyDown={e => {
          if (e.key === 'Escape') {
            dispatch(dacctActions.dacctSetSelectedObject(null));
            dispatch(dacctActions.dacctSetMoveMenuOpen(false));
          }
        }}
        onClick={_ => {
          dispatch(dacctActions.dacctSetSelectedObject(null));
          dispatch(dacctActions.dacctSetMoveMenuOpen(false));
        }}
      >
        <div className="mx-auto da-landing-content mex-mb-30">
          <div>
            <div className="d-flex da-header justify-content-between mex-mb-20 tw-border-b tw-pb-7">
              <div className="tw-grid tw-gap-5">
                <div className="da-sec-txt-header tw-flex tw-items-center tw-gap-1 tw-text-3xl">
                  {currentDonorAccountRole === DONOR_ACCESS_ROLES.owner && (
                    <Link to={'/donor'} className="tw-text-gray-400">
                      My Objects
                    </Link>
                  )}
                  {currentDonorAccountRole !== DONOR_ACCESS_ROLES.owner && (
                    <Link to={'/donor/shared'} className="tw-text-gray-400">
                      Shared with Me
                    </Link>
                  )}
                  <span>
                    <ChevronRightIcon className="tw-h-8 tw-w-8 tw-text-gray-400" />
                  </span>
                  <DonorAccountActionsDropdown />
                </div>
                <UsersList onClickEdit={openEditAccessModal} />
              </div>
              <div className="tw-grid tw-items-end tw-gap-4">
                {[DONOR_ACCESS_ROLES.owner, DONOR_ACCESS_ROLES.editor].includes(
                  currentDonorAccountRole,
                ) && (
                  <>
                    <div>
                      <ButtonRound
                        className="tw-uppercase"
                        icon={ShareFolderIcon}
                        onClick={() => {
                          openShareModal();
                        }}
                      >
                        Share Folder
                      </ButtonRound>
                    </div>
                    <div>
                      <Link
                        to={`/donor/folders/${selectedAccount.id}/object/draft/1`}
                        className="gen-link-no-decoration"
                      >
                        <ButtonRound
                          className="tw-uppercase"
                          icon={AddObjBtnIcon}
                        >
                          New Object
                        </ButtonRound>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            {accountIsEmpty ? (
              <DonorAccountEmptyState selectedAccount={selectedAccount} />
            ) : (
              <NestedSection>
                <div className="tw-p-5">
                  <DonorObjectsList
                    selectedAccount={selectedAccount}
                    inactiveObjects={inactiveObjects}
                    activeObjects={activeObjects}
                    cycles={cyclesSorted}
                    donations={donations}
                    museums={museums}
                    proposals={proposals}
                  />
                </div>
              </NestedSection>
            )}
          </div>
        </div>
        <DeleteObjectDraftModal selectedAccount={selectedAccount} />
        <DonorAccountEditAccessModal
          isOpen={isEditAccessModalOpen}
          closeModal={closeEditAccessModal}
          accountName={selectedAccount.name}
          selectedAccountUsers={selectedAccountUsers ?? undefined}
          onChangeRole={data => {
            putDonorAccount({ accountId: selectedAccount.id, ...data });
          }}
          onRemoveUser={data => {
            putDonorAccount({ accountId: selectedAccount.id, ...data });
          }}
        />
        <DonorAccountShareModal
          isOpen={isShareModalOpen}
          closeModal={closeShareModal}
          onSubmit={data => {
            putDonorAccount({ accountId: selectedAccount.id, ...data });
            closeShareModal();
          }}
        />
        {moveModalOpen && <MoveObjectModal />}
        {transferModalOpen && <DonorAccountTransferObjectModal />}
        {transferEmailModalOpen && <DonorAccountTransferEmailModal />}
      </div>
    </>
  );
}

function DonorAccountEmptyState({ selectedAccount }) {
  return (
    <div className="da-empty-state-container">
      <img
        className="da-empty-state-image"
        src={AddObjectsToCollectionImg}
        alt="art hanging on wall"
        // className="da-obj-list-card-img-placeholder"
      />
      <p className="da-empty-state-text">Add objects to this folder</p>
      <div className="da-empty-state-link-container">
        <Link
          to={`/donor/folders/${selectedAccount.id}/object/draft/1`}
          className="gen-link-no-decoration da-empty-state-link"
        >
          New object
        </Link>
      </div>
    </div>
  );
}

function UsersList({ onClickEdit }) {
  const NON_OWNERS_DISPLAY_LIMIT = 4;

  const { accountId } = useParams();
  const { data: selectedAccountUsers } =
    useGetDonorAccountUsersQuery(accountId);

  const [[owner], nonOwners] = partition(
    selectedAccountUsers,
    user => user.role === 'owner',
  );

  const currentDonorAccountRole = useCurrentDonorAccountRole();

  // We don't want to show the list if no one is in it except owner.
  if (nonOwners.length === 0) return null;

  const numberOfHiddenUsers = nonOwners.length - NON_OWNERS_DISPLAY_LIMIT;

  return (
    <div className="tw-flex tw-items-center tw-gap-3">
      <div className="tw-border-r tw-pr-3">
        <UserAvatar
          nameFirst={owner.nameFirst}
          nameLast={owner.nameLast}
          role={owner.role}
        />
      </div>
      <div className="tw-flex tw-items-center tw-gap-3">
        {nonOwners.slice(0, NON_OWNERS_DISPLAY_LIMIT).map((person, i) => {
          return (
            <UserAvatar
              key={`user-list-person-${i}`}
              nameFirst={person.nameFirst}
              nameLast={person.nameLast}
              role={person.role}
            />
          );
        })}
        {nonOwners.length > NON_OWNERS_DISPLAY_LIMIT && (
          <Tooltip
            content={`${numberOfHiddenUsers} more member${
              numberOfHiddenUsers === 1 ? '' : 's'
            }`}
          >
            <div className="da-network--name-bubble">
              +{numberOfHiddenUsers}
            </div>
          </Tooltip>
        )}
        <button
          onClick={onClickEdit}
          className="tw-flex tw-content-center tw-items-center tw-text-sm tw-uppercase tw-text-blue-500"
        >
          {[DONOR_ACCESS_ROLES.owner, DONOR_ACCESS_ROLES.editor].includes(
            currentDonorAccountRole,
          ) ? (
            'Edit access'
          ) : (
            <div className="tw-text-left tw-text-xs">
              View users
              <br />
              with access
            </div>
          )}
        </button>
      </div>
    </div>
  );
}

function UserAvatar({ nameFirst, nameLast, role }) {
  const nameFull = `${nameFirst} ${nameLast}`;
  return (
    <Tooltip
      content={
        <div className="tw-text-center">
          <div>{nameFull}</div>
          <div className="tw-font-light">
            {getRoleDetails({ role }).displayName}
          </div>
        </div>
      }
    >
      <div className="da-network--name-bubble">
        <span>{getInitials(nameFull)}</span>
      </div>
    </Tooltip>
  );
}

UserAvatar.propTypes = {
  nameFirst: PropTypes.string,
  nameLast: PropTypes.string,
  role: PropTypes.string,
};
