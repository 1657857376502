import {
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_CLEAR,
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_FAILURE,
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_REQUEST,
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_SUCCESS,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_CLEAR,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_FAILURE,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_REQUEST,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_SUCCESS,
  OBJ_DRAFT_POST_CLEAR,
  OBJ_DRAFT_POST_FAILURE,
  OBJ_DRAFT_POST_REQUEST,
  OBJ_DRAFT_POST_SUCCESS,
  OBJ_DRAFT_PUT_CLEAR,
  OBJ_DRAFT_PUT_FAILURE,
  OBJ_DRAFT_PUT_REQUEST,
  OBJ_DRAFT_PUT_SUCCESS,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_CLEAR,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_FAILURE,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_REQUEST,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_SUCCESS,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_CLEAR,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_FAILURE,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_REQUEST,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_SUCCESS,
  OBJ_DRAFT_PUT_SUBMIT_CLEAR,
  OBJ_DRAFT_PUT_SUBMIT_FAILURE,
  OBJ_DRAFT_PUT_SUBMIT_REQUEST,
  OBJ_DRAFT_PUT_SUBMIT_SUCCESS,
  OBJ_DRAFT_RESET,
  OBJ_DRAFT_SET_FORM,
  OBJ_DRAFT_UPDATE_ACCOUNT,
  OBJ_DRAFT_UPDATE_COLLECTING_AREA,
  OBJ_DRAFT_UPDATE_ARTIST_NAME,
  OBJ_DRAFT_UPDATE_TITLE,
  OBJ_DRAFT_UPDATE_YEAR,
  OBJ_DRAFT_UPDATE_CLASSIFICATION,
  OBJ_DRAFT_UPDATE_MEDIUM,
  OBJ_DRAFT_UPDATE_DIMENSIONS,
  OBJ_DRAFT_UPDATE_WEIGHT,
  OBJ_DRAFT_UPDATE_WEIGHT_SYSTEM,
  OBJ_DRAFT_UPDATE_EDITION,
  OBJ_DRAFT_UPDATE_EDITION_DESCRIPTION,
  OBJ_DRAFT_UPDATE_INSCRIPTIONS,
  OBJ_DRAFT_UPDATE_CONDITION,
  OBJ_DRAFT_UPDATE_PROVENANCE,
  OBJ_DRAFT_UPDATE_EXHIBITION_HISTORY,
  OBJ_DRAFT_UPDATE_LITERATURE,
  OBJ_DRAFT_UPDATE_CONSERVATION_HISTORY,
  OBJ_DRAFT_UPDATE_NOTES,
  OBJ_DRAFT_UPDATE_RESTRICTIONS,
  OBJ_DRAFT_UPDATE_RESTRICTIONS_DESCRIPTION,
  OBJ_DRAFT_UPDATE_CITY,
  OBJ_DRAFT_UPDATE_STATE,
  OBJ_DRAFT_UPDATE_ZIP,
  OBJ_DRAFT_UPDATE_PRIMARY_IMAGE,
  OBJ_DRAFT_UPDATE_SUPPORTING_IMAGES,
  OBJ_DRAFT_UPDATE_SUBMISSION_HASH,
  OBJ_DRAFT_UPDATE_PRIVACY_TOS_HASH,
  OBJ_DRAFT_UPDATE_DRAFT_ERRORS,
  OBJ_DRAFT_UPDATE_SUBMIT_ATTEMPTED,
  OBJ_DRAFT_UPDATE_SUBMIT_ERRORS,
  OBJ_DRAFT_SET_SELECTED_IMG,
} from '../actionTypes';

export const objDraftDeletePrimaryImageClear = _ => {
  return {
    type: OBJ_DRAFT_DELETE_PRIMARY_IMAGE_CLEAR,
  };
};

export const objDraftDeletePrimaryImageFailure = payload => {
  return {
    type: OBJ_DRAFT_DELETE_PRIMARY_IMAGE_FAILURE,
    payload,
  };
};

export const objDraftDeletePrimaryImageRequest = payload => {
  return {
    type: OBJ_DRAFT_DELETE_PRIMARY_IMAGE_REQUEST,
    payload,
  };
};

export const objDraftDeletePrimaryImageSuccess = payload => {
  return {
    type: OBJ_DRAFT_DELETE_PRIMARY_IMAGE_SUCCESS,
    payload,
  };
};

export const objDraftDeleteSupportingImageClear = _ => {
  return {
    type: OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_CLEAR,
  };
};

export const objDraftDeleteSupportingImageFailure = payload => {
  return {
    type: OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_FAILURE,
    payload,
  };
};

export const objDraftDeleteSupportingImageRequest = payload => {
  return {
    type: OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_REQUEST,
    payload,
  };
};

export const objDraftDeleteSupportingImageSuccess = payload => {
  return {
    type: OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_SUCCESS,
    payload,
  };
};

export const objDraftPostClear = _ => {
  return {
    type: OBJ_DRAFT_POST_CLEAR,
  };
};

export const objDraftPostFailure = payload => {
  return {
    type: OBJ_DRAFT_POST_FAILURE,
    payload,
  };
};

export const objDraftPostRequest = payload => {
  return {
    type: OBJ_DRAFT_POST_REQUEST,
    payload,
  };
};

export const objDraftPostSuccess = payload => {
  return {
    type: OBJ_DRAFT_POST_SUCCESS,
    payload,
  };
};

export const objDraftPutClear = _ => {
  return {
    type: OBJ_DRAFT_PUT_CLEAR,
  };
};

export const objDraftPutFailure = payload => {
  return {
    type: OBJ_DRAFT_PUT_FAILURE,
    payload,
  };
};

export const objDraftPutRequest = payload => {
  return {
    type: OBJ_DRAFT_PUT_REQUEST,
    payload,
  };
};

export const objDraftPutSuccess = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUCCESS,
    payload,
  };
};

export const objDraftPutPrimaryImageClear = _ => {
  return {
    type: OBJ_DRAFT_PUT_PRIMARY_IMAGE_CLEAR,
  };
};

export const objDraftPutPrimaryImageFailure = payload => {
  return {
    type: OBJ_DRAFT_PUT_PRIMARY_IMAGE_FAILURE,
    payload,
  };
};

export const objDraftPutPrimaryImageRequest = payload => {
  return {
    type: OBJ_DRAFT_PUT_PRIMARY_IMAGE_REQUEST,
    payload,
  };
};

export const objDraftPutPrimaryImageSuccess = payload => {
  return {
    type: OBJ_DRAFT_PUT_PRIMARY_IMAGE_SUCCESS,
    payload,
  };
};

export const objDraftPutSupportingImageClear = _ => {
  return {
    type: OBJ_DRAFT_PUT_SUPPORTING_IMAGE_CLEAR,
  };
};

export const objDraftPutSupportingImageFailure = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUPPORTING_IMAGE_FAILURE,
    payload,
  };
};

export const objDraftPutSupportingImageRequest = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUPPORTING_IMAGE_REQUEST,
    payload,
  };
};

export const objDraftPutSupportingImageSuccess = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUPPORTING_IMAGE_SUCCESS,
    payload,
  };
};

export const objDraftPutSubmitClear = _ => {
  return {
    type: OBJ_DRAFT_PUT_SUBMIT_CLEAR,
  };
};

export const objDraftPutSubmitFailure = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUBMIT_FAILURE,
    payload,
  };
};

export const objDraftPutSubmitRequest = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUBMIT_REQUEST,
    payload,
  };
};

export const objDraftPutSubmitSuccess = payload => {
  return {
    type: OBJ_DRAFT_PUT_SUBMIT_SUCCESS,
    payload,
  };
};

export const objDraftReset = _ => {
  return {
    type: OBJ_DRAFT_RESET,
  };
};

export const objDraftSetForm = payload => {
  return {
    type: OBJ_DRAFT_SET_FORM,
    payload,
  };
};

export const objDraftUpdateAccount = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_ACCOUNT,
    payload,
  };
};

export const objDraftUpdateCollectingArea = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_COLLECTING_AREA,
    payload,
  };
};

export const objDraftUpdateArtistName = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_ARTIST_NAME,
    payload,
  };
};

export const objDraftUpdateTitle = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_TITLE,
    payload,
  };
};

export const objDraftUpdateYear = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_YEAR,
    payload,
  };
};

export const objDraftUpdateClassification = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_CLASSIFICATION,
    payload,
  };
};

export const objDraftUpdateMedium = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_MEDIUM,
    payload,
  };
};

export const objDraftUpdateDimensions = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_DIMENSIONS,
    payload,
  };
};

export const objDraftUpdateWeight = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_WEIGHT,
    payload,
  };
};

export const objDraftUpdateWeightSystem = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_WEIGHT_SYSTEM,
    payload,
  };
};

export const objDraftUpdateEdition = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_EDITION,
    payload,
  };
};

export const objDraftUpdateEditionDescription = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_EDITION_DESCRIPTION,
    payload,
  };
};

export const objDraftUpdateInscriptions = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_INSCRIPTIONS,
    payload,
  };
};

export const objDraftUpdateCondition = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_CONDITION,
    payload,
  };
};

export const objDraftUpdateProvenance = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_PROVENANCE,
    payload,
  };
};

export const objDraftUpdateExhibitionHistory = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_EXHIBITION_HISTORY,
    payload,
  };
};

export const objDraftUpdateLiterature = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_LITERATURE,
    payload,
  };
};

export const objDraftUpdateConservationHistory = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_CONSERVATION_HISTORY,
    payload,
  };
};

export const objDraftUpdateNotes = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_NOTES,
    payload,
  };
};

export const objDraftUpdateRestrictions = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_RESTRICTIONS,
    payload,
  };
};

export const objDraftUpdateRestrictionsDescription = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_RESTRICTIONS_DESCRIPTION,
    payload,
  };
};

export const objDraftUpdateCity = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_CITY,
    payload,
  };
};

export const objDraftUpdateState = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_STATE,
    payload,
  };
};

export const objDraftUpdateZip = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_ZIP,
    payload,
  };
};

export const objDraftUpdatePrimaryImage = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_PRIMARY_IMAGE,
    payload,
  };
};

export const objDraftUpdateSupportingImages = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_SUPPORTING_IMAGES,
    payload,
  };
};

export const objDraftUpdateSubmissionHash = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_SUBMISSION_HASH,
    payload,
  };
};

export const objDraftUpdatePrivacyTOSHash = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_PRIVACY_TOS_HASH,
    payload,
  };
};

export const objDraftUpdateDraftErrors = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_DRAFT_ERRORS,
    payload,
  };
};

export const objDraftUpdateSubmitAttempted = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_SUBMIT_ATTEMPTED,
    payload,
  };
};

export const objDraftUpdateSubmitErrors = payload => {
  return {
    type: OBJ_DRAFT_UPDATE_SUBMIT_ERRORS,
    payload,
  };
};

export const objDraftSetSelectedImg = payload => {
  return {
    type: OBJ_DRAFT_SET_SELECTED_IMG,
    payload,
  };
};
