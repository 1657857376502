import {
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_CLEAR,
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_FAILURE,
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_REQUEST,
  OBJ_DRAFT_DELETE_PRIMARY_IMAGE_SUCCESS,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_CLEAR,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_FAILURE,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_REQUEST,
  OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_SUCCESS,
  OBJ_DRAFT_POST_CLEAR,
  OBJ_DRAFT_POST_FAILURE,
  OBJ_DRAFT_POST_REQUEST,
  OBJ_DRAFT_POST_SUCCESS,
  OBJ_DRAFT_PUT_CLEAR,
  OBJ_DRAFT_PUT_FAILURE,
  OBJ_DRAFT_PUT_REQUEST,
  OBJ_DRAFT_PUT_SUCCESS,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_CLEAR,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_FAILURE,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_REQUEST,
  OBJ_DRAFT_PUT_PRIMARY_IMAGE_SUCCESS,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_CLEAR,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_FAILURE,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_REQUEST,
  OBJ_DRAFT_PUT_SUPPORTING_IMAGE_SUCCESS,
  OBJ_DRAFT_PUT_SUBMIT_CLEAR,
  OBJ_DRAFT_PUT_SUBMIT_FAILURE,
  OBJ_DRAFT_PUT_SUBMIT_REQUEST,
  OBJ_DRAFT_PUT_SUBMIT_SUCCESS,
  OBJ_DRAFT_RESET,
  OBJ_DRAFT_SET_FORM,
  OBJ_DRAFT_UPDATE_ACCOUNT,
  OBJ_DRAFT_UPDATE_COLLECTING_AREA,
  OBJ_DRAFT_UPDATE_ARTIST_NAME,
  OBJ_DRAFT_UPDATE_TITLE,
  OBJ_DRAFT_UPDATE_YEAR,
  OBJ_DRAFT_UPDATE_CLASSIFICATION,
  OBJ_DRAFT_UPDATE_MEDIUM,
  OBJ_DRAFT_UPDATE_DIMENSIONS,
  OBJ_DRAFT_UPDATE_WEIGHT,
  OBJ_DRAFT_UPDATE_WEIGHT_SYSTEM,
  OBJ_DRAFT_UPDATE_EDITION,
  OBJ_DRAFT_UPDATE_EDITION_DESCRIPTION,
  OBJ_DRAFT_UPDATE_INSCRIPTIONS,
  OBJ_DRAFT_UPDATE_CONDITION,
  OBJ_DRAFT_UPDATE_PROVENANCE,
  OBJ_DRAFT_UPDATE_EXHIBITION_HISTORY,
  OBJ_DRAFT_UPDATE_LITERATURE,
  OBJ_DRAFT_UPDATE_CONSERVATION_HISTORY,
  OBJ_DRAFT_UPDATE_NOTES,
  OBJ_DRAFT_UPDATE_RESTRICTIONS,
  OBJ_DRAFT_UPDATE_RESTRICTIONS_DESCRIPTION,
  OBJ_DRAFT_UPDATE_CITY,
  OBJ_DRAFT_UPDATE_STATE,
  OBJ_DRAFT_UPDATE_ZIP,
  OBJ_DRAFT_UPDATE_PRIMARY_IMAGE,
  OBJ_DRAFT_UPDATE_SUPPORTING_IMAGES,
  OBJ_DRAFT_UPDATE_SUBMISSION_HASH,
  OBJ_DRAFT_UPDATE_PRIVACY_TOS_HASH,
  OBJ_DRAFT_UPDATE_DRAFT_ERRORS,
  OBJ_DRAFT_UPDATE_SUBMIT_ATTEMPTED,
  OBJ_DRAFT_UPDATE_SUBMIT_ERRORS,
  OBJ_DRAFT_SET_SELECTED_IMG,
} from '../../actions/actionTypes';

const initialObjectDraftState = {
  formData: {
    id: '',
    account: '',
    collectingArea: '',
    artistName: '',
    title: '',
    year: '',
    classification: '',
    medium: '',
    height: '',
    length: '',
    depth: '',
    dimensions: [
      {
        key: '',
        system: '',
        height: '',
        width: '',
        depth: '',
      },
    ],
    weight: '',
    weightSystem: '',
    edition: false,
    editionDescription: '',
    inscriptions: '',
    condition: '',
    provenance: '',
    exhibitionHistory: '',
    literature: '',
    conservationHistory: '',
    restrictions: false,
    restrictionsDescription: '',
    notes: '',
    locationCity: '',
    locationState: '',
    locationZip: '',
    primaryImage: null,
    supportingImages: null,
    rawDimensions: {},
    rawWeight: {},
    submissionHash: null,
    privacyPolicyHash: null,
    tosHash: null,
  },
  formDraftErrors: {
    formError: false,
    errorPages: {},
    accountError: false,
    titleError: false,
    dimensionsError: false,
    dimensionsErrors: {},
    weightSystemError: false,
    editionError: false,
    restrictionsError: false,
  },
  formSubmitAttempted: false,
  formSubmitErrors: {
    formError: false,
    errorPages: {},
    accountError: false,
    collectingAreaError: false,
    artistNameError: false,
    titleError: false,
    yearError: false,
    classificationError: false,
    mediumError: false,
    dimensionsError: false,
    dimensionsErrors: {},
    weightSystemError: false,
    editionError: false,
    conditionError: false,
    provenanceError: false,
    restrictionsError: false,
    locationCityError: false,
    locationStateError: false,
    locationZipError: false,
    primaryImageError: false,
    submissionHashError: false,
    privacyTOSHashError: false,
  },
  selectedImg: null,
  objDraftPostRequest: {
    loading: false,
    data: null,
    error: '',
  },
  objDraftPutRequest: {
    loading: false,
    data: null,
    error: '',
  },
  objDraftDeletePrimaryImageRequest: {
    loading: false,
    data: null,
    error: '',
  },
  objDraftDeleteSupportingImageRequest: {
    loading: false,
    data: null,
    error: '',
  },
  objDraftPutPrimaryImageRequest: {
    loading: false,
    data: null,
    error: '',
  },
  objDraftPutSupportingImageRequest: {
    loading: false,
    data: null,
    error: '',
  },
  objDraftPutSubmitRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const objectDraftReducer = (state = initialObjectDraftState, action) => {
  switch (action.type) {
    case OBJ_DRAFT_DELETE_PRIMARY_IMAGE_CLEAR:
      return {
        ...state,
        objDraftDeletePrimaryImageRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_DELETE_PRIMARY_IMAGE_FAILURE:
      return {
        ...state,
        objDraftDeletePrimaryImageRequest: {
          ...state.objDraftDeletePrimaryImageRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_DELETE_PRIMARY_IMAGE_REQUEST:
      return {
        ...state,
        objDraftDeletePrimaryImageRequest: {
          ...state.objDraftDeletePrimaryImageRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_DELETE_PRIMARY_IMAGE_SUCCESS:
      return {
        ...state,
        objDraftDeletePrimaryImageRequest: {
          ...state.objDraftDeletePrimaryImageRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_CLEAR:
      return {
        ...state,
        objDraftDeleteSupportingImageRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_FAILURE:
      return {
        ...state,
        objDraftDeleteSupportingImageRequest: {
          ...state.objDraftDeleteSupportingImageRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_REQUEST:
      return {
        ...state,
        objDraftDeleteSupportingImageRequest: {
          ...state.objDraftDeleteSupportingImageRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_DELETE_SUPPORTING_IMAGE_SUCCESS:
      return {
        ...state,
        objDraftDeleteSupportingImageRequest: {
          ...state.objDraftDeleteSupportingImageRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_POST_CLEAR:
      return {
        ...state,
        objDraftPostRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_POST_FAILURE:
      return {
        ...state,
        objDraftPostRequest: {
          ...state.objDraftPostRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_POST_REQUEST:
      return {
        ...state,
        objDraftPostRequest: {
          ...state.objDraftPostRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_POST_SUCCESS:
      return {
        ...state,
        objDraftPostRequest: {
          ...state.objDraftPostRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_CLEAR:
      return {
        ...state,
        objDraftPutRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_FAILURE:
      return {
        ...state,
        objDraftPutRequest: {
          ...state.objDraftPutRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_PUT_REQUEST:
      return {
        ...state,
        objDraftPutRequest: {
          ...state.objDraftPutRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUCCESS:
      return {
        ...state,
        objDraftPutRequest: {
          ...state.objDraftPutRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_PRIMARY_IMAGE_CLEAR:
      return {
        ...state,
        objDraftPutPrimaryImageRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_PRIMARY_IMAGE_FAILURE:
      return {
        ...state,
        objDraftPutPrimaryImageRequest: {
          ...state.objDraftPutPrimaryImageRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_PUT_PRIMARY_IMAGE_REQUEST:
      return {
        ...state,
        objDraftPutPrimaryImageRequest: {
          ...state.objDraftPutPrimaryImageRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_PRIMARY_IMAGE_SUCCESS:
      return {
        ...state,
        objDraftPutPrimaryImageRequest: {
          ...state.objDraftPutPrimaryImageRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUPPORTING_IMAGE_CLEAR:
      return {
        ...state,
        objDraftPutSupportingImageRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUPPORTING_IMAGE_FAILURE:
      return {
        ...state,
        objDraftPutSupportingImageRequest: {
          ...state.objDraftPutSupportingImageRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_PUT_SUPPORTING_IMAGE_REQUEST:
      return {
        ...state,
        objDraftPutSupportingImageRequest: {
          ...state.objDraftPutSupportingImageRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUPPORTING_IMAGE_SUCCESS:
      return {
        ...state,
        objDraftPutSupportingImageRequest: {
          ...state.objDraftPutSupportingImageRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUBMIT_CLEAR:
      return {
        ...state,
        objDraftPutSubmitRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUBMIT_FAILURE:
      return {
        ...state,
        objDraftPutSubmitRequest: {
          ...state.objDraftPutSubmitRequest,
          loading: false,
          error: action.payload,
        },
      };

    case OBJ_DRAFT_PUT_SUBMIT_REQUEST:
      return {
        ...state,
        objDraftPutSubmitRequest: {
          ...state.objDraftPutSubmitRequest,
          loading: true,
          error: '',
        },
      };

    case OBJ_DRAFT_PUT_SUBMIT_SUCCESS:
      return {
        ...state,
        objDraftPutSubmitRequest: {
          ...state.objDraftPutSubmitRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case OBJ_DRAFT_RESET:
      return {
        ...state,
        formData: {
          id: '',
          account: '',
          collectingArea: '',
          artistName: '',
          title: '',
          year: '',
          classification: '',
          medium: '',
          dimensions: [
            {
              key: '',
              system: '',
              height: '',
              width: '',
              depth: '',
            },
          ],
          weight: '',
          weightSystem: '',
          edition: false,
          editionDescription: '',
          inscriptions: '',
          condition: '',
          provenance: '',
          exhibitionHistory: '',
          literature: '',
          conservationHistory: '',
          notes: '',
          restrictions: false,
          restrictionsDescription: '',
          locationCity: '',
          locationState: '',
          locationZip: '',
          primaryImage: null,
          supportingImages: null,
          rawDimensions: {},
          rawWeight: {},
          submissionHash: null,
          privacyPolicyHash: null,
          tosHash: null,
        },
        formDraftErrors: {
          formError: false,
          errorPages: {},
          accountError: false,
          titleError: false,
          dimensionsError: false,
          dimensionsErrors: {},
          weightSystemError: false,
          editionError: false,
          restrictionsError: false,
        },
        formSubmitAttempted: false,
        formSubmitErrors: {
          formError: false,
          errorPages: {},
          accountError: false,
          collectingAreaError: false,
          artistNameError: false,
          titleError: false,
          yearError: false,
          classificationError: false,
          mediumError: false,
          dimensionsError: false,
          dimensionsErrors: {},
          weightSystemError: false,
          edition: false,
          conditionError: false,
          provenanceError: false,
          restrictionsError: false,
          locationCityError: false,
          locationStateError: false,
          locationZipError: false,
          primaryImageError: false,
          submissionHashError: false,
          privacyTOSHashError: false,
        },
      };

    case OBJ_DRAFT_SET_FORM:
      return {
        ...state,
        formData: action.payload.formData,
      };

    case OBJ_DRAFT_UPDATE_ACCOUNT:
      return {
        ...state,
        formData: {
          ...state.formData,
          account: action.payload.account,
        },
      };

    case OBJ_DRAFT_UPDATE_COLLECTING_AREA:
      return {
        ...state,
        formData: {
          ...state.formData,
          collectingArea: action.payload.collectingArea,
        },
      };

    case OBJ_DRAFT_UPDATE_ARTIST_NAME:
      return {
        ...state,
        formData: {
          ...state.formData,
          artistName: action.payload.artistName,
        },
      };

    case OBJ_DRAFT_UPDATE_TITLE:
      return {
        ...state,
        formData: {
          ...state.formData,
          title: action.payload.title,
        },
      };

    case OBJ_DRAFT_UPDATE_YEAR:
      return {
        ...state,
        formData: {
          ...state.formData,
          year: action.payload.year,
        },
      };

    case OBJ_DRAFT_UPDATE_CLASSIFICATION:
      return {
        ...state,
        formData: {
          ...state.formData,
          classification: action.payload.classification,
        },
      };

    case OBJ_DRAFT_UPDATE_MEDIUM:
      return {
        ...state,
        formData: {
          ...state.formData,
          medium: action.payload.medium,
        },
      };

    case OBJ_DRAFT_UPDATE_DIMENSIONS:
      return {
        ...state,
        formData: {
          ...state.formData,
          dimensions: action.payload.dimensions,
        },
      };

    case OBJ_DRAFT_UPDATE_WEIGHT:
      return {
        ...state,
        formData: {
          ...state.formData,
          weight: action.payload.weight,
        },
      };

    case OBJ_DRAFT_UPDATE_WEIGHT_SYSTEM:
      return {
        ...state,
        formData: {
          ...state.formData,
          weightSystem: action.payload.weightSystem,
        },
      };

    case OBJ_DRAFT_UPDATE_EDITION:
      return {
        ...state,
        formData: {
          ...state.formData,
          edition: action.payload.edition,
        },
      };

    case OBJ_DRAFT_UPDATE_EDITION_DESCRIPTION:
      return {
        ...state,
        formData: {
          ...state.formData,
          editionDescription: action.payload.editionDescription,
        },
      };

    case OBJ_DRAFT_UPDATE_INSCRIPTIONS:
      return {
        ...state,
        formData: {
          ...state.formData,
          inscriptions: action.payload.inscriptions,
        },
      };

    case OBJ_DRAFT_UPDATE_CONDITION:
      return {
        ...state,
        formData: {
          ...state.formData,
          condition: action.payload.condition,
        },
      };

    case OBJ_DRAFT_UPDATE_PROVENANCE:
      return {
        ...state,
        formData: {
          ...state.formData,
          provenance: action.payload.provenance,
        },
      };

    case OBJ_DRAFT_UPDATE_EXHIBITION_HISTORY:
      return {
        ...state,
        formData: {
          ...state.formData,
          exhibitionHistory: action.payload.exhibitionHistory,
        },
      };

    case OBJ_DRAFT_UPDATE_LITERATURE:
      return {
        ...state,
        formData: {
          ...state.formData,
          literature: action.payload.literature,
        },
      };

    case OBJ_DRAFT_UPDATE_CONSERVATION_HISTORY:
      return {
        ...state,
        formData: {
          ...state.formData,
          conservationHistory: action.payload.conservationHistory,
        },
      };

    case OBJ_DRAFT_UPDATE_NOTES:
      return {
        ...state,
        formData: {
          ...state.formData,
          notes: action.payload.notes,
        },
      };

    case OBJ_DRAFT_UPDATE_RESTRICTIONS:
      return {
        ...state,
        formData: {
          ...state.formData,
          restrictions: action.payload.restrictions,
        },
      };

    case OBJ_DRAFT_UPDATE_RESTRICTIONS_DESCRIPTION:
      return {
        ...state,
        formData: {
          ...state.formData,
          restrictionsDescription: action.payload.restrictionsDescription,
        },
      };

    case OBJ_DRAFT_UPDATE_CITY:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationCity: action.payload.locationCity,
        },
      };

    case OBJ_DRAFT_UPDATE_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationState: action.payload.locationState,
        },
      };

    case OBJ_DRAFT_UPDATE_ZIP:
      return {
        ...state,
        formData: {
          ...state.formData,
          locationZip: action.payload.locationZip,
        },
      };

    case OBJ_DRAFT_UPDATE_PRIMARY_IMAGE:
      return {
        ...state,
        formData: {
          ...state.formData,
          primaryImage: action.payload.primaryImage,
        },
      };

    case OBJ_DRAFT_UPDATE_SUPPORTING_IMAGES:
      return {
        ...state,
        formData: {
          ...state.formData,
          supportingImages: action.payload.supportingImages,
        },
      };

    case OBJ_DRAFT_UPDATE_SUBMISSION_HASH:
      return {
        ...state,
        formData: {
          ...state.formData,
          submissionHash: action.payload.submissionHash,
        },
      };

    case OBJ_DRAFT_UPDATE_PRIVACY_TOS_HASH:
      return {
        ...state,
        formData: {
          ...state.formData,
          privacyPolicyHash: action.payload.privacyPolicyHash,
          tosHash: action.payload.tosHash,
        },
      };

    case OBJ_DRAFT_UPDATE_DRAFT_ERRORS:
      return {
        ...state,
        formDraftErrors: action.payload.formDraftErrors,
      };

    case OBJ_DRAFT_UPDATE_SUBMIT_ATTEMPTED:
      return {
        ...state,
        formSubmitAttempted: action.payload.formSubmitAttempted,
      };

    case OBJ_DRAFT_UPDATE_SUBMIT_ERRORS:
      return {
        ...state,
        formSubmitErrors: action.payload.formSubmitErrors,
      };

    case OBJ_DRAFT_SET_SELECTED_IMG:
      return {
        ...state,
        selectedImg: action.payload,
      };

    default:
      return state;
  }
};
