import {
  PROP_CLEAR_PROP,
  PROP_DELETE_IMAGE_CLEAR,
  PROP_DELETE_IMAGE_FAILURE,
  PROP_DELETE_IMAGE_REQUEST,
  PROP_DELETE_IMAGE_SUCCESS,
  PROP_POST_DRAFT_CLEAR,
  PROP_POST_DRAFT_FAILURE,
  PROP_POST_DRAFT_REQUEST,
  PROP_POST_DRAFT_SUCCESS,
  PROP_PUT_APPROVE_CLEAR,
  PROP_PUT_APPROVE_FAILURE,
  PROP_PUT_APPROVE_REQUEST,
  PROP_PUT_APPROVE_SUCCESS,
  PROP_PUT_DRAFT_CLEAR,
  PROP_PUT_DRAFT_FAILURE,
  PROP_PUT_DRAFT_REQUEST,
  PROP_PUT_DRAFT_SUCCESS,
  PROP_PUT_IMAGE_CLEAR,
  PROP_PUT_IMAGE_FAILURE,
  PROP_PUT_IMAGE_REQUEST,
  PROP_PUT_IMAGE_SUCCESS,
  PROP_PUT_REJECT_PROPOSALS_CLEAR,
  PROP_PUT_REJECT_PROPOSALS_FAILURE,
  PROP_PUT_REJECT_PROPOSALS_REQUEST,
  PROP_PUT_REJECT_PROPOSALS_SUCCESS,
  PROP_PUT_SUBMIT_CLEAR,
  PROP_PUT_SUBMIT_FAILURE,
  PROP_PUT_SUBMIT_REQUEST,
  PROP_PUT_SUBMIT_SUCCESS,
  PROP_RESET_ERRORS,
  PROP_RESET_FORM,
  PROP_SET_FORM,
  PROP_SET_CONTACT_MODAL,
  PROP_SET_APPROVE_MODAL,
  PROP_SET_DISCARD_MODAL,
  PROP_SET_SUBMIT_MODAL,
  PROP_SET_TMP_CONTACT_ID,
  PROP_UPDATE_CONDITIONS_CLARIFICATION,
  PROP_UPDATE_CYCLE_ID,
  PROP_UPDATE_LETTER_OF_INTEREST,
  PROP_UPDATE_MUSEUM_ID,
  PROP_UPDATE_OBJECT_ID,
  PROP_UPDATE_POC_ID,
  PROP_UPDATE_POC_TEL,
  PROP_UPDATE_ERRORS_DRAFT,
  PROP_UPDATE_ERRORS_SUBMIT,
  PROP_SET_SELECTED_OBJECT,
  PROP_SET_SELECTED_PROPOSAL_ID,
  PROP_SET_SELECTED_IMG,
  PROP_UPDATE_SHIPPING_COST,
} from '../actionTypes';

export const propClearProposal = _ => {
  return {
    type: PROP_CLEAR_PROP,
  };
};

export const propDeleteImageClear = _ => {
  return {
    type: PROP_DELETE_IMAGE_CLEAR,
  };
};

export const propDeleteImageFailure = payload => {
  return {
    type: PROP_DELETE_IMAGE_FAILURE,
    payload,
  };
};

export const propDeleteImageRequest = _ => {
  return {
    type: PROP_DELETE_IMAGE_REQUEST,
  };
};

export const propDeleteImageSuccess = payload => {
  return {
    type: PROP_DELETE_IMAGE_SUCCESS,
    payload,
  };
};

export const propPostDraftClear = _ => {
  return {
    type: PROP_POST_DRAFT_CLEAR,
  };
};

export const propPostDraftFailure = payload => {
  return {
    type: PROP_POST_DRAFT_FAILURE,
    payload,
  };
};

export const propPostDraftRequest = _ => {
  return {
    type: PROP_POST_DRAFT_REQUEST,
  };
};

export const propPostDraftSuccess = payload => {
  return {
    type: PROP_POST_DRAFT_SUCCESS,
    payload,
  };
};

export const propPutApproveClear = _ => {
  return {
    type: PROP_PUT_APPROVE_CLEAR,
  };
};

export const propPutApproveFailure = payload => {
  return {
    type: PROP_PUT_APPROVE_FAILURE,
    payload,
  };
};

export const propPutApproveRequest = _ => {
  return {
    type: PROP_PUT_APPROVE_REQUEST,
  };
};

export const propPutApproveSuccess = payload => {
  return {
    type: PROP_PUT_APPROVE_SUCCESS,
    payload,
  };
};

export const propPutDraftClear = _ => {
  return {
    type: PROP_PUT_DRAFT_CLEAR,
  };
};

export const propPutDraftFailure = payload => {
  return {
    type: PROP_PUT_DRAFT_FAILURE,
    payload,
  };
};

export const propPutDraftRequest = _ => {
  return {
    type: PROP_PUT_DRAFT_REQUEST,
  };
};

export const propPutDraftSuccess = payload => {
  return {
    type: PROP_PUT_DRAFT_SUCCESS,
    payload,
  };
};

export const propPutImageClear = _ => {
  return {
    type: PROP_PUT_IMAGE_CLEAR,
  };
};

export const propPutImageFailure = payload => {
  return {
    type: PROP_PUT_IMAGE_FAILURE,
    payload,
  };
};

export const propPutImageRequest = _ => {
  return {
    type: PROP_PUT_IMAGE_REQUEST,
  };
};

export const propPutImageSuccess = payload => {
  return {
    type: PROP_PUT_IMAGE_SUCCESS,
    payload,
  };
};

export const propPutRejectProposalsSuccess = payload => {
  return {
    type: PROP_PUT_REJECT_PROPOSALS_SUCCESS,
    payload,
  };
};

export const propPutRejectProposalsClear = _ => {
  return {
    type: PROP_PUT_REJECT_PROPOSALS_CLEAR,
  };
};

export const propPutRejectProposalsFailure = payload => {
  return {
    type: PROP_PUT_REJECT_PROPOSALS_FAILURE,
    payload,
  };
};

export const propPutRejectProposalsRequest = _ => {
  return {
    type: PROP_PUT_REJECT_PROPOSALS_REQUEST,
  };
};

export const propPutSubmitClear = _ => {
  return {
    type: PROP_PUT_SUBMIT_CLEAR,
  };
};

export const propPutSubmitFailure = payload => {
  return {
    type: PROP_PUT_SUBMIT_FAILURE,
    payload,
  };
};

export const propPutSubmitRequest = _ => {
  return {
    type: PROP_PUT_SUBMIT_REQUEST,
  };
};

export const propPutSubmitSuccess = payload => {
  return {
    type: PROP_PUT_SUBMIT_SUCCESS,
    payload,
  };
};

export const propResetErrors = _ => {
  return {
    type: PROP_RESET_ERRORS,
  };
};

export const propResetForm = _ => {
  return {
    type: PROP_RESET_FORM,
  };
};

export const propSetForm = payload => {
  return {
    type: PROP_SET_FORM,
    payload,
  };
};

export const propSetContactModal = payload => {
  return {
    type: PROP_SET_CONTACT_MODAL,
    payload,
  };
};

export const propSetApproveModal = payload => {
  return {
    type: PROP_SET_APPROVE_MODAL,
    payload,
  };
};

export const propSetDiscardModal = payload => {
  return {
    type: PROP_SET_DISCARD_MODAL,
    payload,
  };
};

export const propSetSubmitModal = payload => {
  return {
    type: PROP_SET_SUBMIT_MODAL,
    payload,
  };
};

export const propSetTmpContactId = payload => {
  return {
    type: PROP_SET_TMP_CONTACT_ID,
    payload,
  };
};

export const propUpdateConditionsClarification = payload => {
  return {
    type: PROP_UPDATE_CONDITIONS_CLARIFICATION,
    payload,
  };
};

export const propUpdateCycleId = payload => {
  return {
    type: PROP_UPDATE_CYCLE_ID,
    payload,
  };
};

export const propUpdateLetterOfInterest = payload => {
  return {
    type: PROP_UPDATE_LETTER_OF_INTEREST,
    payload,
  };
};

export const propUpdateMuseumId = payload => {
  return {
    type: PROP_UPDATE_MUSEUM_ID,
    payload,
  };
};

export const propUpdateObjectId = payload => {
  return {
    type: PROP_UPDATE_OBJECT_ID,
    payload,
  };
};

export const propUpdatePOCId = payload => {
  return {
    type: PROP_UPDATE_POC_ID,
    payload,
  };
};

export const propUpdatePOCTel = payload => {
  return {
    type: PROP_UPDATE_POC_TEL,
    payload,
  };
};

export const propUpdateErrorsDraft = payload => {
  return {
    type: PROP_UPDATE_ERRORS_DRAFT,
    payload,
  };
};

export const propUpdateErrorsSubmit = payload => {
  return {
    type: PROP_UPDATE_ERRORS_SUBMIT,
    payload,
  };
};

export const propSetSelectedObject = payload => {
  return {
    type: PROP_SET_SELECTED_OBJECT,
    payload,
  };
};

export const propSetSelectedProposalId = payload => {
  return {
    type: PROP_SET_SELECTED_PROPOSAL_ID,
    payload,
  };
};

export const propSetSelectedImage = payload => {
  return {
    type: PROP_SET_SELECTED_IMG,
    payload,
  };
};

export const propUpdateShippingCost = payload => {
  return {
    type: PROP_UPDATE_SHIPPING_COST,
    payload,
  };
};
