import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT, IMAGE_UPLOAD_TIMEOUT } from '../../config';
import {
  objDraftDeletePrimaryImageFailure,
  objDraftDeletePrimaryImageRequest,
  objDraftDeletePrimaryImageSuccess,
  objDraftDeleteSupportingImageFailure,
  objDraftDeleteSupportingImageRequest,
  objDraftDeleteSupportingImageSuccess,
  objDraftPostFailure,
  objDraftPostRequest,
  objDraftPostSuccess,
  objDraftPutFailure,
  objDraftPutRequest,
  objDraftPutSuccess,
  objDraftPutPrimaryImageFailure,
  objDraftPutPrimaryImageRequest,
  objDraftPutPrimaryImageSuccess,
  objDraftPutSupportingImageFailure,
  objDraftPutSupportingImageRequest,
  objDraftPutSupportingImageSuccess,
  objDraftPutSubmitFailure,
  objDraftPutSubmitRequest,
  objDraftPutSubmitSuccess,
  objDraftSetForm,
} from '../../actions';
import { parseDraftIntoFormData } from '../../glue';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import { api, API_TAGS } from 'requests/api';

export const deleteObjectDraftAndGetSelectedAccount = (
  token,
  objId,
  selectedAccountId,
) => {
  return dispatch => {
    dispatch(dacctActions.dacctDeleteDraftRequest());
    axios({
      method: 'DELETE',
      url: `${BASE_URL}/objects/${objId}/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(dacctActions.dacctDeleteDraftSuccess(response.data));
        dispatch(dacctActions.dacctSetDraftDeleteModalOpen(false));
        dispatch(dacctActions.dacctSetMoveMenuOpen(false));

        // TODO: eventually this should be handled by rtk cache
        dispatch(
          api.util.invalidateTags([
            { type: API_TAGS.DonorAccount, id: 'LIST' },
            { type: API_TAGS.DonorAccountActiveObjects, id: selectedAccountId },
          ]),
        );

        dispatch(dacctActions.dacctSetSuccessMessage('Object draft deleted'));

        setTimeout(_ => {
          dispatch(dacctActions.dacctSetSuccessMessage(null));
        }, 5000);
      })
      .catch(error => {
        dispatch(dacctActions.dacctDeleteDraftFailure(error.message));
      });
  };
};

export const deleteObjectImage = (token, objId, imgId, primary, accountId) => {
  return dispatch => {
    if (primary) {
      dispatch(objDraftDeletePrimaryImageRequest());
    } else {
      dispatch(objDraftDeleteSupportingImageRequest());
    }
    axios({
      method: 'DELETE',
      url: `${BASE_URL}/objects/${objId}/images/${imgId}`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(
          objDraftSetForm({ formData: parseDraftIntoFormData(response.data) }),
        );
        if (primary) {
          dispatch(objDraftDeletePrimaryImageSuccess(response.data));

          dispatch(
            api.util.invalidateTags([
              { type: API_TAGS.DonorAccount, id: 'LIST' },
              { type: API_TAGS.DonorAccountActiveObjects, id: accountId },
            ]),
          );
        } else {
          dispatch(objDraftDeleteSupportingImageSuccess(response.data));
        }
      })
      .catch(error => {
        if (primary) {
          dispatch(objDraftDeletePrimaryImageFailure(error.message));
        } else {
          dispatch(objDraftDeleteSupportingImageFailure(error.message));
        }
      });
  };
};

export const postObjectDraft = (token, data, userId) => {
  return dispatch => {
    data.draftBy = userId;
    dispatch(objDraftPostRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/objects/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(
          objDraftSetForm({ formData: parseDraftIntoFormData(response.data) }),
        );
        dispatch(objDraftPostSuccess(response.data));

        dispatch(
          api.util.invalidateTags([
            { type: API_TAGS.DonorAccount, id: 'LIST' },
            { type: API_TAGS.DonorAccountActiveObjects, id: data.accountId },
          ]),
        );
      })
      .catch(error => {
        dispatch(objDraftPostFailure(error.message));
      });
  };
};

export const putObjectDraft = (token, id, data) => {
  return dispatch => {
    dispatch(objDraftPutRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/objects/${id}/drafts`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(
          objDraftSetForm({ formData: parseDraftIntoFormData(response.data) }),
        );
        dispatch(objDraftPutSuccess(response.data));
        dispatch(
          api.util.invalidateTags([
            { type: API_TAGS.DonorAccount, id: 'LIST' },
            { type: API_TAGS.DonorAccountActiveObjects, id: data.accountId },
          ]),
        );
      })
      .catch(error => {
        dispatch(objDraftPutFailure(error.message));
      });
  };
};

export const putObjectImage = (token, objId, formData, primary, accountId) => {
  return dispatch => {
    if (primary === 'true') {
      dispatch(objDraftPutPrimaryImageRequest());
    } else if (primary === 'false') {
      dispatch(objDraftPutSupportingImageRequest());
    }
    axios({
      method: 'PUT',
      url: `${BASE_URL}/objects/${objId}/images`,
      timeout: IMAGE_UPLOAD_TIMEOUT,
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(
          objDraftSetForm({ formData: parseDraftIntoFormData(response.data) }),
        );
        if (primary === 'true') {
          dispatch(objDraftPutPrimaryImageSuccess(response.data));
          dispatch(
            api.util.invalidateTags([
              { type: API_TAGS.DonorAccount, id: 'LIST' },
              { type: API_TAGS.DonorAccountActiveObjects, id: accountId },
            ]),
          );
        } else if (primary === 'false') {
          dispatch(objDraftPutSupportingImageSuccess());
        }
      })
      .catch(error => {
        if (primary === 'true') {
          dispatch(objDraftPutPrimaryImageFailure(error.message));
        } else if (primary === 'false') {
          dispatch(objDraftPutSupportingImageFailure());
        }
      });
  };
};

export const putObjectSubmit = (token, id, data, userId) => {
  return dispatch => {
    data.submittedBy = userId;
    dispatch(objDraftPutSubmitRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/objects/${id}/submit`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(objDraftPutSubmitSuccess(response.data));

        dispatch(
          api.util.invalidateTags([
            { type: API_TAGS.DonorAccount, id: 'LIST' },
            { type: API_TAGS.DonorAccountActiveObjects, id: data.accountId },
          ]),
        );
      })
      .catch(error => {
        dispatch(objDraftPutSubmitFailure(error.message));
      });
  };
};
