import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  listsClearCuratorial,
  listsClearInterest,
  listsGetCuratorialClear,
  listsGetInterestClear,
  listsPutCuratorialAddClear,
  listsPutInterestRemoveClear,
  listsSetInterestObjects,
  listsClearSelectedObject,
  listsSetSelectedObject,
} from '../../actions';
import {
  getCurrentCycleLists,
  putListAddObject,
  putListRemoveObject,
} from '../../requests';
import { addDays, getDateString } from '../../lib';
import ApprovedCheck from '../../images/approved-check.png';
import CheckmarkGrey from '../../images/checkmark-grey.png';
import CuratorialReviewWhite from '../../images/curatorial-review-white.png';
import GreyBookmark from '../../images/grey-bookmark.png';
import MoreIcon from '../../images/more-icon.png';
import '../../styles/general.css';
import '../../styles/lists.css';
import '../../styles/modal.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { ObjectTimeBadge } from './ObjectTimeBadge';
import { selectCurrentCycle } from 'selectors/cyclesSelectors';
import { useGetMuseumAccountUserQuery } from 'requests/api';
import { useMuseumGetObjectSummariesInCurrentCycle } from 'components/_shared/useMuseumGetObjectSummariesInCurrentCycle';

export default function MuseumListInterest() {
  const isMuseum = useSelector(state => selectIsMuseum(state));
  const currentCycle = useSelector(state => selectCurrentCycle(state));
  const listCuratorial = useSelector(state => state.listsState.listCuratorial);
  const listInterest = useSelector(state => state.listsState.listInterest);

  const { activeObjectSummaries = [] } =
    useMuseumGetObjectSummariesInCurrentCycle();

  const putListAddObjectRequest = useSelector(
    state => state.listsState.putListCuratorialAddRequest,
  );
  const putListRemoveObjectRequest = useSelector(
    state => state.listsState.putListInterestRemoveRequest,
  );
  const selectedObject = useSelector(state => state.listsState.selectedObject);
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (token && user && museumAccount) {
      dispatch(
        getCurrentCycleLists(token, user.museum.museumId, museumAccount.kind),
      );
    }
  }, [museumAccount]);

  useEffect(() => {
    return () => {
      dispatch(listsClearCuratorial());
      dispatch(listsClearInterest());
      dispatch(listsGetCuratorialClear());
      dispatch(listsGetInterestClear());

      dispatch(listsPutCuratorialAddClear());
      dispatch(listsPutInterestRemoveClear());
      dispatch(listsClearSelectedObject());
    };
  }, []);

  const handleClickOutside = _ => {
    const objects = JSON.parse(JSON.stringify(listInterest.objects));
    const keys = Object.keys(objects);
    keys.forEach(k => {
      objects[k].showMore = false;
    });
    dispatch(listsSetInterestObjects(objects));
  };

  const handleMoreMenuClick = key => {
    const objects = JSON.parse(JSON.stringify(listInterest.objects));
    const keys = Object.keys(objects);
    keys.forEach(k => {
      if (k === key) {
        objects[k].showMore = true;
      } else {
        objects[k].showMore = false;
      }
    });
    dispatch(listsSetInterestObjects(objects));
  };

  const makeCards = _ => {
    const makeEmptyState = _ => {
      return (
        <div className="lst-empty-state-container">
          <p className="mx-auto text-center lst-empty-state-header">
            No Objects
          </p>
          <p className="mx-auto text-center lst-empty-state-text">
            Select objects from the <br />
            <Link to="/institution/catalogue/1" className="gen-link">
              current catalogue
            </Link>
          </p>
        </div>
      );
    };

    if (
      listInterest &&
      listInterest.objects &&
      typeof listInterest.objects == 'object'
    ) {
      const keys = Object.keys(listInterest.objects);
      if (!keys.length) {
        return makeEmptyState();
      }
      return keys.map(k => {
        let onListCuratorial = false;
        let approved = false;
        if (
          listCuratorial &&
          typeof listCuratorial === 'object' &&
          listCuratorial.objects &&
          typeof listCuratorial.objects === 'object'
        ) {
          if (listCuratorial.objects[k]) {
            onListCuratorial = true;
            approved = listCuratorial.objects[k].approved;
          }
        }

        const objectSummary = activeObjectSummaries?.find(obj => obj.id === k);

        return (
          <div key={`lst_object_card_${k}`}>
            <div className="lst-card">
              <div className="tw-relative">
                {approved && (
                  <div className="lst-card-banner-approved lst-card-banner-approved-int d-flex">
                    <img
                      src={CheckmarkGrey}
                      alt="checkmark-grey"
                      className="lst-card-banner-approved-icon"
                    />
                    <div>
                      <p className="lst-card-banner-approved-txt">
                        APPROVED FOR PROPOSAL CREATION
                      </p>
                      <Link
                        to={`/institution/proposals/active`}
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-banner-approved-link">
                          GO TO PROPOSALS
                        </p>
                      </Link>
                    </div>
                  </div>
                )}
                {!approved && onListCuratorial && (
                  <div className="lst-card-banner-low d-flex">
                    <img
                      src={CuratorialReviewWhite}
                      alt="curatorial-review"
                      className="lst-card-banner-low-cr-icon"
                    />
                    <p className="lst-card-banner-low-txt">
                      IN CURATORIAL REVIEW
                    </p>
                  </div>
                )}
                <img
                  src={`https://res.cloudinary.com/ex-tech/image/fetch/h_542,w_576,c_fill,q_auto/https://${listInterest.objects[k].imageBucket}.s3.amazonaws.com/${listInterest.objects[k].imageId}`}
                  alt={`object-${listInterest.objects[k]}`}
                  className="lst-obj-img spc-mb-20"
                />
                {!isMuseum && objectSummary?.events ? (
                  <ObjectTimeBadge
                    dateAddedToCatalog={objectSummary.events.approved.timestamp}
                    firstProposalDate={objectSummary.firstProposalDate}
                    isObjectOfInterestPage
                  />
                ) : null}
              </div>
              <div className="d-flex">
                <div className="lst-card-info-container">
                  <p className="txt-rubik-dark-16 lst-card-info-artist gen-crop-txt-1">
                    {listInterest.objects[k].artistName}
                  </p>
                  <p>
                    <span className="txt-thin-italic-15 gen-crop-txt-1">
                      {listInterest.objects[k].title},
                    </span>{' '}
                    <span className="txt-light-15">
                      {listInterest.objects[k].year}
                    </span>
                  </p>
                </div>
                <a
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleMoreMenuClick(k);
                  }}
                  href="# "
                  className="gen-link-no-decoration"
                >
                  <img
                    src={MoreIcon}
                    alt="more-menu-button"
                    className="lst-more-btn"
                  />
                </a>
              </div>
            </div>
            {listInterest.objects[k].showMore && (
              <div
                className="lst-card-overlay"
                onClick={e => e.stopPropagation()}
              >
                {!onListCuratorial ? (
                  <div className="mx-auto lst-card-overlay-content">
                    <div className="mx-auto lst-card-overlay-content-inner">
                      <a
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(
                            listsSetSelectedObject(listInterest.objects[k]),
                          );
                        }}
                        href="# "
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          Submit for
                          <br />
                          Curatorial Review
                        </p>
                      </a>
                      <div className="gen-break-light lst-card-overlay-content-inner-break"></div>
                      <a
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(
                            putListRemoveObject(
                              token,
                              'interest',
                              listInterest.id,
                              k,
                            ),
                          );
                        }}
                        href="# "
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          Remove from Objects of Interest
                        </p>
                      </a>
                      <div className="gen-break-light"></div>
                      <Link
                        to={`/institution/catalogue/object/${k}`}
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          View Object Details
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto lst-card-overlay-content lst-card-overlay-content-low">
                    <div className="mx-auto lst-card-overlay-content-inner">
                      <Link
                        to={`/institution/catalogue/object/${k}`}
                        className="gen-link-no-decoration"
                      >
                        <p className="lst-card-overlay-content-inner-option txt-medium-14">
                          View Object Details
                        </p>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      });
    }
    return makeEmptyState();
  };

  if (currentCycle && listInterest) {
    return (
      <div className="gen-background-nav-museum" onClick={handleClickOutside}>
        <div className="lst-content-container mx-auto d-flex">
          <div className="lst-content-left">
            <div className="d-flex">
              <img
                src={GreyBookmark}
                alt="grey-bookmark"
                className="lst-grey-bkmk"
              />
              <p className="txt-header">Objects of Interest</p>
            </div>
            {currentCycle ? (
              <div className="lst-content-left-info">
                {isMuseum ? (
                  <>
                    <p className="txt-bold-15 lst-left-note-container-cycle">
                      {currentCycle.name.toUpperCase()}
                    </p>
                    <p className="txt-15 spc-mb-15">
                      {
                        <>
                          {getDateString(addDays(currentCycle.cutoff, 2))}{' '}
                          &ndash; {getDateString(addDays(currentCycle.end, 1))}
                        </>
                      }
                    </p>
                    <div className="lst-left-note-container">
                      <p className="txt-14 lst-left-note-container-txt">
                        Your private folder of objects of interest selected from
                        the current catalogue.
                        <br />
                        <br />
                        Submit objects for curatorial review to pursue for
                        acquistion.
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="lst-left-note-container">
                    <p className="txt-14 lst-left-note-container-txt">
                      Your private folder of objects of interest selected from
                      the catalogue.
                      <br />
                      <br />
                      Submit objects for curatorial review to pursue for
                      acquistion.
                    </p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="lst-cards-container d-flex justify-content-between flex-wrap">
            {makeCards()}
          </div>
        </div>
        {selectedObject && (
          <div className="mod">
            <div className="mod-content-container mx-auto">
              {putListAddObjectRequest.data ? (
                <div className="list-int-modal-content ">
                  <div className="d-flex justify-content-center mx-auto">
                    <img
                      src={ApprovedCheck}
                      alt="approved-check"
                      className="mod-approved-check"
                    />
                    <p className="mod-txt-header">Submitted</p>
                  </div>
                  <div className="mod-content-object-info-container mx-auto">
                    <p className="mod-txt-18">
                      {selectedObject.artistName}, <i>{selectedObject.title}</i>
                    </p>
                  </div>
                  <div className="spc-mb-30">
                    <p className="mod-txt-header">for curatorial review</p>
                  </div>
                  <p className="mod-txt-18">
                    You will be notified if the object is approved
                    <br /> by an administrator for proposal creation
                  </p>
                  <div className="mod-btn-container mx-auto d-flex justify-content-center">
                    <button
                      className="btn mod-btn-dark"
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(listsClearSelectedObject());
                        dispatch(listsPutCuratorialAddClear());
                      }}
                    >
                      OKAY
                    </button>
                  </div>
                </div>
              ) : (
                <div className="list-int-modal-content spc-mt-30">
                  <p className="mod-txt-header">
                    Are you sure you
                    <br />
                    would like to submit
                  </p>
                  <div className="mod-content-object-info-container mx-auto">
                    <p className="mod-txt-18">
                      {selectedObject.artistName}, <i>{selectedObject.title}</i>
                    </p>
                  </div>
                  <p className="mod-txt-header">for curatorial review?</p>
                  <div className="mod-btn-container mx-auto d-flex justify-content-between">
                    <button
                      className="btn mod-btn-light"
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(listsClearSelectedObject());
                      }}
                    >
                      CANCEL
                    </button>
                    <button
                      className="btn mod-btn-dark"
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(
                          putListAddObject(
                            token,
                            'curatorial',
                            listCuratorial.id,
                            selectedObject.id,
                          ),
                        );
                      }}
                    >
                      CONFIRM & SUBMIT
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <div className="gen-background-nav-museum"></div>;
  }
}
