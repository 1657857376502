import React from 'react';
import { getNaturallySortedAccounts, validateAccountOwner } from '@lib';
import { useDispatch, useSelector } from 'react-redux';
import { partition } from 'lodash';
import MoveMenuFolderIcon from '@images/move-menu-folder-icon.png';
import MoveMenuFolderLooseObjectsIcon from '@images/move-menu-folder-icon-loose-objects.png';
import CloseIconSmall from '@images/menu-small-close-grey.png';
import NewFolderMoveMenuIcon from '@images/new-folder-move-menu.png';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import {
  useGetDonorAccountGeneralQuery,
  useGetDonorAccountsQuery,
  usePutMoveObjectMutation,
} from 'requests/api';

function useDonorAccountMoveObjectPopover() {
  const token = useSelector(state => state.authState.token);
  const { data: accounts } = useGetDonorAccountsQuery(token.userId);

  const [myAccounts, sharedAccounts] = partition(accounts, a =>
    validateAccountOwner(a.account.users, token.userId),
  );

  const [putMoveObject, { isLoading }] = usePutMoveObjectMutation();

  const selectedObject = useSelector(
    state => state.donorAccountsState.selectedObject,
  );
  const { data: accountGeneral } = useGetDonorAccountGeneralQuery();

  return {
    myAccounts,
    sharedAccounts,
    token,
    accounts,
    isLoading,
    accountGeneral,
    putMoveObject,
    selectedObject,
  };
}

function Elem({
  account,
  isGeneralAccount,
  isDisabled,
  isOwner,
  selectedAccount,
}) {
  const { isLoading, putMoveObject, selectedObject } =
    useDonorAccountMoveObjectPopover();
  const dispatch = useDispatch();

  return (
    <button
      key={`move_menu_account_row_${account.id}`}
      className="tw-flex tw-justify-start tw-text-left hover:tw-bg-gray-200 disabled:tw-cursor-not-allowed disabled:tw-text-gray-300 disabled:hover:tw-bg-white"
      disabled={isDisabled}
      onClick={e => {
        if (!isLoading) {
          if (isOwner) {
            putMoveObject({
              accountId: selectedAccount.id,
              objectId: selectedObject.id,
              destId: account.id,
            });
          } else {
            dispatch(dacctActions.dacctSetMoveModalOpen(true));
          }
          e.stopPropagation();
          e.preventDefault();
          dispatch(dacctActions.dacctSetDestFolder(account));
        }
      }}
    >
      <img
        src={
          isGeneralAccount ? MoveMenuFolderLooseObjectsIcon : MoveMenuFolderIcon
        }
        alt="folder-icon"
        className="da-move-menu-new-folder-icon"
      />
      <p
        className={`da-move-menu-folder-name text-truncate ${
          isDisabled ? 'tw-text-gray-300' : 'tw-text-gray-600'
        }`}
      >
        {account.name}
      </p>
    </button>
  );
}

export function DonorAccountMoveObjectPopover({ selectedAccount }) {
  const myAccounts = [];
  const sharedAccounts = [];

  const dispatch = useDispatch();

  const { accounts, selectedObject, accountGeneral, token } =
    useDonorAccountMoveObjectPopover();

  if (Array.isArray(accounts) && accounts.length && accountGeneral) {
    myAccounts.push(
      <Elem
        key={accountGeneral?.id}
        selectedAccount={selectedAccount}
        account={accountGeneral}
        isGeneralAccount={true}
        isDisabled={accountGeneral?.id === selectedObject.accountId}
        isOwner
      />,
    );

    getNaturallySortedAccounts(accounts).forEach(account => {
      const currentUsersRoleOnAccount = account.account.users.find(
        user => user.userId === token.userId,
      )?.role;

      const isOwner = currentUsersRoleOnAccount === DONOR_ACCESS_ROLES.owner;

      const hasMoveAccess = [
        DONOR_ACCESS_ROLES.editor,
        DONOR_ACCESS_ROLES.owner,
      ].includes(currentUsersRoleOnAccount);

      const isCurrentAccount = account.account.id === selectedObject.accountId;

      const elem = (
        <Elem
          key={account.account.id}
          selectedAccount={selectedAccount}
          account={account.account}
          isDisabled={isCurrentAccount}
          isGeneralAccount={account.general}
          isOwner={isOwner}
        />
      );

      if (isOwner) {
        myAccounts.push(elem);
      } else {
        if (!hasMoveAccess) return;

        sharedAccounts.push(elem);
      }
    });
  }

  return (
    <div className="da-obj-lst-card-more-menu-move">
      <div className="da-move-menu-header-section d-flex">
        <div className="da-move-menu-title-container">
          <p className="da-move-menu-title">
            {myAccounts.length
              ? 'My Objects'
              : sharedAccounts.length
              ? 'Shared with Me'
              : ''}
          </p>
        </div>
        <div
          className="da-move-menu-close-circle gen-cursor-hover-link"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            dispatch(dacctActions.dacctSetMoveMenuOpen(false));
          }}
        >
          <img
            src={CloseIconSmall}
            alt="close-move-menu"
            className="da-move-menu-close-sm"
          />
        </div>
      </div>
      {myAccounts.length ? (
        <div className="da-move-menu-folders-section">{myAccounts}</div>
      ) : null}
      {myAccounts.length ? (
        <React.Fragment>
          <div className="da-move-menu-header-section">
            <div className="da-move-menu-title-container">
              <p className="da-move-menu-title">
                {myAccounts.length ? 'Shared with Me' : ''}
              </p>
            </div>
          </div>
          <div className="da-move-menu-folders-section">{sharedAccounts}</div>
        </React.Fragment>
      ) : null}
      <img
        src={NewFolderMoveMenuIcon}
        alt="move-menu-new-folder"
        className="da-move-menu-new-folder-icon gen-cursor-hover-link"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(dacctActions.dacctSetNewAccountModalOpen(true));
        }}
      />
    </div>
  );
}
