import React, { Fragment, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const roles = [
  {
    name: 'Editor',
    description:
      'Editors can view all objects, create new objects, delete drafts, and complete all actions for objects in this shared folder. They cannot move objects out of this shared folder.',
    value: 'editor',
  },
  {
    name: 'View Only',
    description:
      'View only users can view all object information and donation activity for objects in this folder.',
    value: 'viewer',
  },
];

export default function SharePermissionsDropdown({
  value,
  onChange,
  onRemove,
  canRemoveUser = false,
}) {
  const selected = useMemo(
    () => roles.find(item => item.value === value),
    [value],
  );

  function handleChange(item) {
    if (item === 'remove') {
      return onRemove(item);
    }

    return onChange(item.value);
  }

  if (!selected) return null;

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="tw-relative">
        <Listbox.Button className="tw-sm:text-sm tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-relative tw-w-full tw-min-w-[160px] tw-cursor-default tw-rounded-md tw-border tw-bg-white tw-py-2 tw-px-3 tw-pr-10 tw-pl-3 tw-text-left">
          <span className="tw-block tw-truncate">{selected.name}</span>
          <span className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
            <ChevronDownIcon
              className="tw-h-5 tw-w-5 tw-text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="tw-transition tw-ease-out tw-duration-100"
          enterFrom="tw-transform tw-opacity-0 tw-scale-95"
          enterTo="tw-transform tw-opacity-100 tw-scale-100"
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options
            className={`tw-focus:outline-none tw-sm:text-sm tw-absolute tw-right-0 tw-z-10 tw-mt-1 tw-max-h-96 tw-w-[360px] tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5`}
          >
            {roles.map((role, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `${active ? 'tw-bg-blue-50' : 'tw-text-gray-900'}
                          tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4`
                }
                value={role}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? 'tw-font-medium' : 'tw-font-normal'
                      } tw-block tw-truncate
                      ${active ? 'tw-text-blue-600' : ''}
                              `}
                    >
                      {role.name}
                    </span>
                    {selected ? (
                      <span
                        className={`${
                          active ? 'tw-text-blue-600' : 'tw-text-blue-600'
                        }
                                tw-absolute tw-inset-y-0 tw-left-0 tw-mt-2 tw-flex tw-items-start tw-pl-3`}
                      >
                        <CheckIcon
                          className="tw-h-5 tw-w-5"
                          aria-hidden="true"
                        />
                      </span>
                    ) : null}
                    <div>{role.description}</div>
                  </>
                )}
              </Listbox.Option>
            ))}
            {canRemoveUser && (
              <Listbox.Option
                className={({ active }) =>
                  `${active ? 'tw-bg-blue-50' : 'tw-text-gray-900'}
                        tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-10 tw-pr-4`
                }
                value="remove"
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`${
                        selected ? 'tw-font-medium' : 'tw-font-normal'
                      } tw-block tw-truncate
                    ${active ? 'tw-text-blue-600' : 'tw-text-red-500'}
                            `}
                    >
                      Remove
                    </span>
                  </>
                )}
              </Listbox.Option>
            )}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}

SharePermissionsDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  canRemoveUser: PropTypes.bool.isRequired,
};
