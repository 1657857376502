import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';

import {
  authPostLoginFailure,
  authPostLoginRequest,
  authPostLoginSuccess,
  authPostResetFailure,
  authPostResetRequest,
  authPostResetSuccess,
  authPostVerifyFailure,
  authPostVerifyRequest,
  authPostVerifySuccess,
  authPostVerifyResendFailure,
  authPostVerifyResendRequest,
  authPostVerifyResendSuccess,
  authPutLogoutFailure,
  authPutLogoutRequest,
  authPutLogoutSuccess,
  authPutResetFailure,
  authPutResetRequest,
  authPutResetSuccess,
  authPutVerifyFailure,
  authPutVerifyRequest,
  authPutVerifySuccess,
  authUpdateToken,
  authCAPutChangeFailure,
  authCAPutChangeSuccess,
  authCAPutChangeRequest,
  authCCPutChangeFailure,
  authCCPutChangeSuccess,
  authCCPutChangeRequest,
  clearUser,
} from '../../actions';
import { getUser } from '../';

export const authPostLogin = data => {
  return dispatch => {
    dispatch(authPostLoginRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/auth/login`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: { 'content-type': 'application/json' },
    })
      .then(response => {
        localStorage.setItem('mex_token', JSON.stringify(response.data));
        dispatch(authPostLoginSuccess(response.data));
        dispatch(getUser(response.data));
      })
      .catch(error => {
        dispatch(authPostLoginFailure(error.message));
      });
  };
};

export const authPostReset = email => {
  return dispatch => {
    dispatch(authPostResetRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/auth/reset`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ email }),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(response => {
        dispatch(authPostResetSuccess(response.data));
      })
      .catch(error => {
        dispatch(authPostResetFailure(error.message));
      });
  };
};

export const authPostVerify = token => {
  return dispatch => {
    dispatch(authPostVerifyRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/auth/verify/${token.userId}`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(authPostVerifySuccess(response.data));
      })
      .catch(error => {
        dispatch(authPostVerifyFailure(error.message));
      });
  };
};

export const authPostVerifyResend = (token, telObj) => {
  return dispatch => {
    dispatch(authPostVerifyResendRequest());
    axios({
      method: 'POST',
      url: `${BASE_URL}/auth/verify/${token.userId}/tel/${telObj.id}/resend`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(authPostVerifyResendSuccess(response.data));
      })
      .catch(error => {
        dispatch(authPostVerifyResendFailure(error.message));
      });
  };
};

export const authPutLogout = token => {
  return dispatch => {
    dispatch(authPutLogoutRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/auth/logout`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.clear();
        dispatch(authPutLogoutSuccess(response.data));
        dispatch(clearUser());
      })
      .catch(error => {
        dispatch(authPutLogoutFailure(error.message));
      });
  };
};

export const authPutReset = data => {
  return dispatch => {
    dispatch(authPutResetRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/auth/reset`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(response => {
        dispatch(authPutResetSuccess(response.data));
      })
      .catch(error => {
        dispatch(authPutResetFailure(error.message));
      });
  };
};

export const authPutVerify = (token, data) => {
  return dispatch => {
    dispatch(authPutVerifyRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/auth/verify/${token.userId}`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.setItem('mex_token', JSON.stringify(response.data));
        dispatch(authPutVerifySuccess(response.data));
        dispatch(authUpdateToken({ token: response.data }));
      })
      .catch(error => {
        dispatch(authPutVerifyFailure(error.message));
      });
  };
};

export const authCAPutChange = (token, passwordHash) => {
  return dispatch => {
    dispatch(authCAPutChangeRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/auth/change/${token.userId}/authorize`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ passwordHash }),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        localStorage.setItem('mex_token', JSON.stringify(response.data));
        dispatch(authCAPutChangeSuccess(response.data));
        dispatch(authUpdateToken({ token: response.data }));
      })
      .catch(error => {
        dispatch(authCAPutChangeFailure(error.message));
      });
  };
};

export const authCCPutChange = (token, passwordHash) => {
  return dispatch => {
    dispatch(authCCPutChangeRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/auth/change/${token.userId}/confirm`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ passwordHash }),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(authCCPutChangeSuccess(response.data));
      })
      .catch(error => {
        dispatch(authCCPutChangeFailure(error.message));
      });
  };
};
