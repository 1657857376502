import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ctgSetClassifications,
  ctgSetClassificationsOpen,
  listsClearCuratorial,
  listsClearInterest,
  listsGetCuratorialClear,
  listsGetInterestClear,
  listsPutInterestAddClear,
  listsPutInterestRemoveClear,
} from '../../actions';
import {
  getCurrentCycleLists,
  putListAddObject,
  putListRemoveObject,
} from '../../requests';
import { CLASSIFICATIONS } from '../../config';
import { addDays, getDateString } from '../../lib';
import BookmarkIcon from '../../images/bookmark-icon.png';
import CatalogueIcon from '../../images/catalog-icon.png';
import CuratorialReviewIcon from '../../images/curatorial-review-icon.png';
import WhiteCloseIcon from '../../images/white-close-icon.png';
import FirstDisabled from '../../images/pg-first-disabled.png';
import FirstEnabled from '../../images/pg-first-enabled.png';
import PreviousDisabled from '../../images/pg-previous-disabled.png';
import PreviousEnabled from '../../images/pg-previous-enabled.png';
import LastDisabled from '../../images/pg-last-disabled.png';
import LastEnabled from '../../images/pg-last-enabled.png';
import NextDisabled from '../../images/pg-next-disabled.png';
import NextEnabled from '../../images/pg-next-enabled.png';
import '../../styles/catalogue.css';
import '../../styles/general.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { ObjectTimeBadge } from './ObjectTimeBadge';
import { useParams } from 'react-router-dom';
import {
  useGetMuseumAccountUserQuery,
  useGetProposalsSimpleQuery,
} from 'requests/api';
import { useMuseumGetObjectSummariesInCurrentCycle } from 'components/_shared/useMuseumGetObjectSummariesInCurrentCycle';

export default function MuseumCatalogue() {
  const { page } = useParams();

  const { data: museumAccount } = useGetMuseumAccountUserQuery();

  const { activeObjectSummaries: objectSummaries, currentCycle } =
    useMuseumGetObjectSummariesInCurrentCycle();

  const classifications = useSelector(
    state => state.catalogueState.classificationsFilters,
  );
  const classificationsOpen = useSelector(
    state => state.catalogueState.classificationsOpen,
  );
  const listCuratorial = useSelector(state => state.listsState.listCuratorial);
  const listInterest = useSelector(state => state.listsState.listInterest);

  const putListAddObjectRequest = useSelector(
    state => state.listsState.putListInterestAddRequest,
  );

  const putListRemoveObjectRequest = useSelector(
    state => state.listsState.putListInterestRemoveRequest,
  );
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const isMuseum = useSelector(state => selectIsMuseum(state));

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      // TODO: update current cycle lists.
      if (user) {
        dispatch(
          getCurrentCycleLists(token, user.museum.museumId, museumAccount.kind),
        );
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(listsClearCuratorial());
      dispatch(listsClearInterest());
      dispatch(listsGetCuratorialClear());
      dispatch(listsGetInterestClear());
      dispatch(listsPutInterestAddClear());
      dispatch(listsPutInterestRemoveClear());
    };
  }, []);

  const [hoverIndex, setHoverIndex] = useState(null);

  const handleMouseEnter = v => {
    const index = objectSummaries.findIndex(object => {
      return object.id === v.id;
    });
    setHoverIndex(index);
  };

  const handleMouseLeave = v => {
    setHoverIndex(null);
    dispatch(listsPutInterestAddClear());
  };

  const makeObjectCards = objs => {
    const makeImageIcon = (v, onListInterest, onListCuratorial, i) => {
      if (onListCuratorial) {
        return (
          <div className="ctg-img-icon ctg-img-icon-bg-solid">
            <img
              src={CuratorialReviewIcon}
              alt={`in curatorial review ${v.title}`}
              className="ctg-img-icon-cr"
            />
          </div>
        );
      }

      if (hoverIndex === i && !putListAddObjectRequest.data) {
        if (onListInterest) {
          return (
            <div
              className="ctg-img-icon ctg-img-icon-bg-solid"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (listInterest && typeof listInterest === 'object') {
                  dispatch(
                    putListRemoveObject(
                      token,
                      'interest',
                      listInterest.id,
                      v.id,
                    ),
                  );
                }
              }}
              onMouseEnter={_ => handleMouseEnter(v)}
              onMouseLeave={_ => handleMouseLeave(v)}
            >
              <img
                src={WhiteCloseIcon}
                alt={`unbookmark ${v.title}`}
                className="ctg-img-icon-x"
              />
            </div>
          );
        } else {
          return (
            <div
              className="ctg-img-icon ctg-img-icon-bg-trans"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (listInterest && typeof listInterest === 'object') {
                  dispatch(
                    putListAddObject(token, 'interest', listInterest.id, v.id),
                  );
                }
              }}
            >
              <img
                src={BookmarkIcon}
                alt={`bookmark ${v.title}`}
                className="ctg-img-icon-bkmk"
              />
            </div>
          );
        }
      } else if (onListInterest) {
        return (
          <div
            className="ctg-img-icon ctg-img-icon-bg-solid"
            onMouseEnter={_ => handleMouseEnter(v)}
            onMouseLeave={_ => handleMouseLeave(v)}
          >
            <img
              src={BookmarkIcon}
              alt={`bookmarked ${v.title}`}
              className="ctg-img-icon-bkmk"
            />
          </div>
        );
      }
    };

    if (Array.isArray(objs)) {
      if (objs.length === 0 && Array.isArray(classifications)) {
        return (
          <div className="ctg-filters-empty-container mx-auto">
            <p className="ctg-filters-empty-text">
              <em>
                No objects match your filters.
                <br />
                <br />
                Please{' '}
                <span
                  className="ctg-filters-empty-text-blue gen-cursor-hover-link"
                  onClick={_ => {
                    dispatch(ctgSetClassifications(null));
                    history.push('/institution/catalogue/1');
                  }}
                >
                  clear filters
                </span>{' '}
                to see other catalogue objects.
                <br />
                <br />
                Please{' '}
                <a
                  href="mailto:museum_liaison@museumexchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ctg-filters-empty-text-blue gen-link"
                >
                  contact us
                </a>{' '}
                if you are looking for specific objects for your collection.
              </em>
            </p>
          </div>
        );
      }

      if (page && !isNaN(Number(page))) {
        objs = objs.slice(10 * (Number(page) - 1), 10 * Number(page));
      }

      return objs.map((v, i) => {
        let onListInterest = false;
        if (
          listInterest &&
          typeof listInterest === 'object' &&
          listInterest.objects &&
          typeof listInterest.objects === 'object'
        ) {
          if (listInterest.objects[v.id]) {
            onListInterest = true;
          }
        }

        let onListCuratorial = false;
        if (
          listCuratorial &&
          typeof listCuratorial === 'object' &&
          listCuratorial.objects &&
          typeof listCuratorial.objects === 'object'
        ) {
          if (listCuratorial.objects[v.id]) {
            onListCuratorial = true;
          }
        }

        return (
          <div key={`object_summary_card_${v.id}`} className="ctg-object-card">
            <Link
              to={`/institution/catalogue/object/${v.id}`}
              className="gen-link-no-decoration"
              onMouseEnter={_ => {
                if (!onListInterest) {
                  handleMouseEnter(v);
                }
              }}
              onMouseLeave={_ => {
                if (!onListInterest) {
                  handleMouseLeave(v);
                }
              }}
            >
              <div className="tw-relative">
                <img
                  src={`https://res.cloudinary.com/ex-tech/image/fetch/h_554,w_576,c_fill,q_auto/https://${v.primaryImage.bucket}.s3.amazonaws.com/${v.primaryImage.imageId}`}
                  alt={`object-${v.title}`}
                  className="ctg-object-img"
                />
                {makeImageIcon(v, onListInterest, onListCuratorial, i)}
                {!isMuseum ? (
                  <ObjectTimeBadge
                    dateAddedToCatalog={v.events.approved.timestamp}
                    firstProposalDate={v.firstProposalDate}
                  />
                ) : null}
              </div>
              <div className="ctg-object-card-info">
                <p className="txt-rubik-dark-16 ctg-object-card-artist gen-crop-txt-1">
                  {v.artistName}
                </p>
                <p>
                  <span className="txt-thin-italic-15">
                    <span className="gen-crop-txt-1">{v.title},</span>
                  </span>{' '}
                  <span className="txt-light-15">{v.year}</span>
                </p>
              </div>
            </Link>
          </div>
        );
      });
    }
  };

  const makePagination = objs => {
    if (Array.isArray(objs) && objs.length && page && !isNaN(Number(page))) {
      const pageNumber = Number(page);
      const lastPage = Math.ceil(objs.length / 10);

      if (lastPage > 1) {
        const numbers = Array.from(
          { length: Math.ceil(objs.length / 10) },
          (x, i) => {
            return (
              <Link
                key={`pg_num_${i + 1}`}
                to={`/institution/catalogue/${i + 1}`}
                className="gen-link-no-decoration"
                onClick={_ =>
                  document.getElementById('catalogue-view').scrollTo(0, 0)
                }
              >
                <div
                  className={`ctg-pg-elem-container ctg-pg-elem-container-hover ${
                    pageNumber === i + 1 ? 'ctg-pg-elem-container-active' : ''
                  }`}
                >
                  <p className="ctg-pg-number">{i + 1}</p>
                </div>
              </Link>
            );
          },
        );

        let firstBtn = (
          <div className="ctg-pg-elem-container ctg-pg-elem-container-btn d-flex justify-content-center">
            <img
              src={FirstDisabled}
              alt="first-disabled"
              className="ctg-pg-btn-wide"
            />
          </div>
        );
        if (pageNumber !== 1) {
          firstBtn = (
            <Link
              to={`/institution/catalogue/1`}
              className="gen-link-no-decoration"
              onClick={_ =>
                document.getElementById('catalogue-view').scrollTo(0, 0)
              }
            >
              <div className="ctg-pg-elem-container ctg-pg-elem-container-btn ctg-pg-elem-container-hover d-flex justify-content-center">
                <img
                  src={FirstEnabled}
                  alt="first-enabled"
                  className="ctg-pg-btn-wide"
                />
              </div>
            </Link>
          );
        }

        let previousBtn = (
          <div className="ctg-pg-elem-container ctg-pg-elem-container-btn d-flex justify-content-center">
            <img
              src={PreviousDisabled}
              alt="previous-disabled"
              className="ctg-pg-btn-narrow"
            />
          </div>
        );
        if (pageNumber !== 1) {
          previousBtn = (
            <Link
              to={`/institution/catalogue/${pageNumber - 1}`}
              className="gen-link-no-decoration"
              onClick={_ =>
                document.getElementById('catalogue-view').scrollTo(0, 0)
              }
            >
              <div className="ctg-pg-elem-container ctg-pg-elem-container-btn ctg-pg-elem-container-hover d-flex justify-content-center">
                <img
                  src={PreviousEnabled}
                  alt="previous-enabled"
                  className="ctg-pg-btn-narrow"
                />
              </div>
            </Link>
          );
        }

        let nextBtn = (
          <div className="ctg-pg-elem-container ctg-pg-elem-container-btn d-flex justify-content-center">
            <img
              src={NextDisabled}
              alt="next-disabled"
              className="ctg-pg-btn-narrow"
            />
          </div>
        );
        if (pageNumber !== lastPage) {
          nextBtn = (
            <Link
              to={`/institution/catalogue/${pageNumber + 1}`}
              className="gen-link-no-decoration"
              onClick={_ =>
                document.getElementById('catalogue-view').scrollTo(0, 0)
              }
            >
              <div className="ctg-pg-elem-container ctg-pg-elem-container-btn ctg-pg-elem-container-hover d-flex justify-content-center">
                <img
                  src={NextEnabled}
                  alt="next-enabled"
                  className="ctg-pg-btn-narrow"
                />
              </div>
            </Link>
          );
        }

        let lastBtn = (
          <div className="ctg-pg-elem-container ctg-pg-elem-container-btn d-flex justify-content-center">
            <img
              src={LastDisabled}
              alt="last-disabled"
              className="ctg-pg-btn-wide"
            />
          </div>
        );
        if (pageNumber !== lastPage) {
          lastBtn = (
            <Link
              to={`/institution/catalogue/${lastPage}`}
              className="gen-link-no-decoration"
              onClick={_ =>
                document.getElementById('catalogue-view').scrollTo(0, 0)
              }
            >
              <div className="ctg-pg-elem-container ctg-pg-elem-container-btn ctg-pg-elem-container-hover d-flex justify-content-center">
                <img
                  src={LastEnabled}
                  alt="last-enabled"
                  className="ctg-pg-btn-wide"
                />
              </div>
            </Link>
          );
        }

        return (
          <div className="ctg-pg-container d-flex justify-content-center">
            <div className="ctg-pg-container-inner d-flex">
              {firstBtn}
              {previousBtn}
              {numbers}
              {nextBtn}
              {lastBtn}
            </div>
          </div>
        );
      }
    }
  };

  function makeClassificationOptions() {
    let options = null;

    const updateFilters = classification => {
      if (Array.isArray(classifications)) {
        const tmp = [...classifications];
        const index = tmp.indexOf(classification);
        if (index >= 0) {
          tmp.splice(index, 1);
          if (tmp.length) {
            dispatch(ctgSetClassifications(tmp));
          } else {
            dispatch(ctgSetClassifications(null));
          }
        } else {
          tmp.push(classification);
          dispatch(ctgSetClassifications(tmp));
        }
      } else {
        dispatch(ctgSetClassifications([classification]));
      }
      history.push('/institution/catalogue/1');
    };

    if (classificationsOpen) {
      options = CLASSIFICATIONS.map((v, i) => {
        return (
          <div key={`classification_${i}`} className="d-flex spc-mb-10">
            <input
              type="checkbox"
              className="ctg-filters-checkbox gen-cursor-hover-link"
              checked={
                Array.isArray(classifications) && classifications.includes(v)
              }
              onChange={_ => updateFilters(v)}
            />
            <p
              className={`ctg-filters-option gen-cursor-hover-link ${
                Array.isArray(classifications) && classifications.includes(v)
                  ? 'ctg-filters-option-selected'
                  : ''
              }`}
              onClick={_ => updateFilters(v)}
            >
              {v}
            </p>
          </div>
        );
      });
    }

    return <div>{options}</div>;
  }

  function filterObjects() {
    if (Array.isArray(objectSummaries)) {
      if (Array.isArray(classifications)) {
        const objs = [];
        objectSummaries.forEach(v => {
          if (classifications.includes(v.classification)) {
            objs.push(v);
          }
        });
        return objs;
      } else {
        return objectSummaries;
      }
    }

    return null;
  }

  if (objectSummaries && currentCycle) {
    const objs = filterObjects();
    const quantities = objectSummaries.reduce((acc, v) => {
      if (v.quantity && v.quantity > 0) {
        return acc + v.quantity;
      }
      return acc + 1;
    }, 0);

    return (
      <div id="catalogue-view" className="gen-background-nav-museum-white">
        <div className="ctg-content-container mx-auto d-flex">
          <div className="ctg-content-container-left">
            <p className="ctg-filters-title">FILTERS</p>
            <div className="ctg-filters-section">
              <div
                className="gen-cursor-hover-link d-flex spc-mb-20"
                onClick={_ =>
                  dispatch(ctgSetClassificationsOpen(!classificationsOpen))
                }
              >
                <p className="ctg-filters-section-title">Classification</p>
                {classificationsOpen ? (
                  <p className="ctg-filters-section-toggle gen-cursor-hover-link">
                    ▾
                  </p>
                ) : (
                  <p className="ctg-filters-section-toggle">▸</p>
                )}
              </div>
              {makeClassificationOptions()}
            </div>
            <div
              className={`ctg-filters-btn-disabled ${
                Array.isArray(classifications)
                  ? 'ctg-filters-btn-enabled gen-cursor-hover-link'
                  : ''
              }`}
              onClick={_ => {
                if (Array.isArray(classifications)) {
                  dispatch(ctgSetClassifications(null));
                  history.push('/institution/catalogue/1');
                }
              }}
            >
              CLEAR FILTERS
            </div>
          </div>
          <div className="ctg-content-container-right mx-auto spc-mb-100">
            <div>
              <div className="d-flex spc-mb-10">
                <img
                  src={CatalogueIcon}
                  alt="catalogue-icon"
                  className="ctg-catalogue-icon"
                />
                <p className="txt-header text-center">
                  {isMuseum ? `${currentCycle.name} Catalogue` : 'Catalogue'}
                </p>
              </div>
              <div className="ctg-header-sub-text">
                <p className="txt-15">
                  {isMuseum ? (
                    <>
                      {getDateString(addDays(currentCycle.cutoff, 2))} &ndash;{' '}
                      {getDateString(addDays(currentCycle.end, 1))}
                    </>
                  ) : (
                    'Create proposals to have donors consider your institution for their donation.'
                  )}
                </p>
                <p className="txt-small-caps-spaced ctg-objects-count">
                  {`${objectSummaries.length} LISTING${
                    objectSummaries.length > 1 ? 'S' : ''
                  }`}
                  <b> • </b>
                  {`${quantities} OBJECT${
                    objectSummaries.length > 1 ? 'S' : ''
                  }`}
                </p>
              </div>
              <div className="gen-break ctg-break"></div>
            </div>
            <div className="d-flex flex-wrap">{makeObjectCards(objs)}</div>
            {makePagination(objs)}
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="gen-background-nav-museum-white"></div>;
  }
}
