import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';

import {
  msetPutMuseumFailure,
  msetPutMuseumRequest,
  msetPutMuseumSuccess,
  msetPutMuseumUserPermissionsFailure,
  msetPutMuseumUserPermissionsRequest,
  msetPutMuseumUserPermissionsSuccess,
  msetPutMuseumUserPositionFailure,
  msetPutMuseumUserPositionRequest,
  msetPutMuseumUserPositionSuccess,
  userUpdateUser,
} from '../../actions';
import { macctActions } from 'features/museumAccounts/museumAccountsSlice';

export const putMuseum = (token, museumId, data) => {
  return dispatch => {
    dispatch(msetPutMuseumRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/museums/${museumId}`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(msetPutMuseumSuccess(response.data));
        dispatch(macctActions.macctSetAccount(response.data));
      })
      .catch(error => {
        dispatch(msetPutMuseumFailure(error.message));
      });
  };
};

export const putMuseumUserPermissions = (token, museumId, userId, role) => {
  return dispatch => {
    dispatch(msetPutMuseumUserPermissionsRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/museums/${museumId}/users/${userId}/permissions`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ role }),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(msetPutMuseumUserPermissionsSuccess(response.data));
        dispatch(macctActions.macctSetAccount(response.data));
      })
      .catch(error => {
        dispatch(msetPutMuseumUserPermissionsFailure(error.message));
      });
  };
};

export const putMuseumUserPosition = (token, userId, position) => {
  return dispatch => {
    dispatch(msetPutMuseumUserPositionRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/users/${userId}/museum/position`,
      timeout: DEFAULT_TIMEOUT,
      data: JSON.stringify({ position }),
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(msetPutMuseumUserPositionSuccess(response.data));
        dispatch(userUpdateUser({ user: response.data }));
      })
      .catch(error => {
        dispatch(msetPutMuseumUserPositionFailure(error.message));
      });
  };
};
