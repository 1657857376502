export const AUTH_CLEAR_TEL = 'AUTH_CLEAR_TEL';
export const AUTH_CLEAR_TOKEN = 'AUTH_CLEAR_TOKEN';
export const AUTH_POST_LOGIN_CLEAR = 'AUTH_POST_LOGIN_CLEAR';
export const AUTH_POST_LOGIN_FAILURE = 'AUTH_POST_LOGIN_FAILURE';
export const AUTH_POST_LOGIN_REQUEST = 'AUTH_POST_LOGIN_REQUEST';
export const AUTH_POST_LOGIN_SUCCESS = 'AUTH_POST_LOGIN_SUCCESS';
export const AUTH_POST_RESET_CLEAR = 'AUTH_POST_RESET_CLEAR';
export const AUTH_POST_RESET_FAILURE = 'AUTH_POST_RESET_FAILURE';
export const AUTH_POST_RESET_REQUEST = 'AUTH_POST_RESET_REQUEST';
export const AUTH_POST_RESET_SUCCESS = 'AUTH_POST_RESET_SUCCESS';
export const AUTH_POST_VERIFY_CLEAR = 'AUTH_POST_VERIFY_CLEAR';
export const AUTH_POST_VERIFY_FAILURE = 'AUTH_POST_VERIFY_FAILURE';
export const AUTH_POST_VERIFY_REQUEST = 'AUTH_POST_VERIFY_REQUEST';
export const AUTH_POST_VERIFY_SUCCESS = 'AUTH_POST_VERIFY_SUCCESS';
export const AUTH_POST_VERIFY_RESEND_CLEAR = 'AUTH_POST_VERIFY_RESEND_CLEAR';
export const AUTH_POST_VERIFY_RESEND_FAILURE =
  'AUTH_POST_VERIFY_RESEND_FAILURE';
export const AUTH_POST_VERIFY_RESEND_REQUEST =
  'AUTH_POST_VERIFY_RESEND_REQUEST';
export const AUTH_POST_VERIFY_RESEND_SUCCESS =
  'AUTH_POST_VERIFY_RESEND_SUCCESS';
export const AUTH_PUT_LOGOUT_CLEAR = 'AUTH_PUT_LOGOUT_CLEAR';
export const AUTH_PUT_LOGOUT_FAILURE = 'AUTH_PUT_LOGOUT_FAILURE';
export const AUTH_PUT_LOGOUT_REQUEST = 'AUTH_PUT_LOGOUT_REQUEST';
export const AUTH_PUT_LOGOUT_SUCCESS = 'AUTH_PUT_LOGOUT_SUCCESS';
export const AUTH_PUT_RESET_CLEAR = 'AUTH_PUT_RESET_CLEAR';
export const AUTH_PUT_RESET_FAILURE = 'AUTH_PUT_RESET_FAILURE';
export const AUTH_PUT_RESET_REQUEST = 'AUTH_PUT_RESET_REQUEST';
export const AUTH_PUT_RESET_SUCCESS = 'AUTH_PUT_RESET_SUCCESS';
export const AUTH_PUT_VERIFY_CLEAR = 'AUTH_PUT_VERIFY_CLEAR';
export const AUTH_PUT_VERIFY_FAILURE = 'AUTH_PUT_VERIFY_FAILURE';
export const AUTH_PUT_VERIFY_REQUEST = 'AUTH_PUT_VERIFY_REQUEST';
export const AUTH_PUT_VERIFY_SUCCESS = 'AUTH_PUT_VERIFY_SUCCESS';
export const AUTH_UPDATE_TOKEN = 'AUTH_UPDATE_TOKEN';
export const AUTH_CA_RESET_FORM = 'AUTH_CA_RESET_FORM';
export const AUTH_CA_UPDATE_ERROR = 'AUTH_CA_UPDATE_ERROR';
export const AUTH_CA_UPDATE_PASSWORD = 'AUTH_CA_UPDATE_PASSWORD';
export const AUTH_CA_PUT_CHANGE_CLEAR = 'AUTH_CA_PUT_CHANGE_CLEAR';
export const AUTH_CA_PUT_CHANGE_FAILURE = 'AUTH_CA_PUT_CHANGE_FAILURE';
export const AUTH_CA_PUT_CHANGE_REQUEST = 'AUTH_CA_PUT_CHANGE_REQUEST';
export const AUTH_CA_PUT_CHANGE_SUCCESS = 'AUTH_CA_PUT_CHANGE_SUCCESS';
export const AUTH_CC_RESET_FORM = 'AUTH_CC_RESET_FORM';
export const AUTH_CC_UPDATE_ERRORS = 'AUTH_CC_UPDATE_ERRORS';
export const AUTH_CC_UPDATE_PW_ONE = 'AUTH_CC_UPDATE_PW_ONE';
export const AUTH_CC_UPDATE_PW_TWO = 'AUTH_CC_UPDATE_PW_TWO';
export const AUTH_CC_PUT_CHANGE_CLEAR = 'AUTH_CC_PUT_CHANGE_CLEAR';
export const AUTH_CC_PUT_CHANGE_FAILURE = 'AUTH_CC_PUT_CHANGE_FAILURE';
export const AUTH_CC_PUT_CHANGE_REQUEST = 'AUTH_CC_PUT_CHANGE_REQUEST';
export const AUTH_CC_PUT_CHANGE_SUCCESS = 'AUTH_CC_PUT_CHANGE_SUCCESS';
