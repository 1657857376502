import '../../styles/general.css';
import '../../styles/modal.css';
import '../../styles/museum-donation-match.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import AssignRegistrarModal from './AssignRegistrarModal';
import BackIcon from '../../images/arrow-back.png';
import InfoIcon from '@images/info-icon.png';
import React, { useEffect, useState } from 'react';
import {
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
  usePutDonationMuseumMatchCompleteMutation,
  usePutMuseumRegistrarMutation,
} from 'requests/api';
import { Link, Redirect } from 'react-router-dom';
import { getDonationDonorNameText } from 'lib/getDonorNameText';
import { useMuseumGetDonation } from 'components/_shared/useMuseumGetDonation';
import { useParams } from 'react-router-dom';

export default function MuseumDonationsMatchOne() {
  const [mounted, setMounted] = useState();

  const { donationId } = useParams();
  const { data: museumAccount } = useGetMuseumAccountUserQuery();

  const { data: donation } = useMuseumGetDonation({
    donationId,
    accountId: museumAccount?.id,
  });

  const { data: obj } = useGetObjMuseumQuery(
    { objectId: donation?.objectId },
    { skip: !donation },
  );

  const [
    putDonationMuseumMatchComplete,
    {
      isLoading: isLoadingPutMatchComplete,
      isSuccess: isSuccessPutMatchComplete,
    },
  ] = usePutDonationMuseumMatchCompleteMutation();

  const [putMuseumRegistrar, { isLoading: isLoadingPutMuseumRegistrar }] =
    usePutMuseumRegistrarMutation();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAssignRegistrarModalOpen, setIsAssignRegistrarModalOpen] =
    useState();

  const [museumRegistrar, setMuseumRegistrar] = useState(null);

  useEffect(() => {
    setTimeout(_ => setMounted(true), 1000);
  }, []);

  const makeConfirmModal = _ => {
    return (
      <div className="mod">
        <div className="mod-content-container mx-auto">
          <div className="prop-edit-modal-content-short mx-auto">
            <p className="mod-txt-header">
              Are you sure you would like to proceed with the donation?
            </p>
            <div className="mod-btn-container mx-auto d-flex justify-content-between">
              <button
                className="btn mod-btn-light"
                onClick={e => {
                  e.preventDefault();
                  setIsConfirmModalOpen(false);
                }}
              >
                CANCEL
              </button>
              <button
                className="btn mod-btn-dark disabled:tw-opacity-50"
                disabled={isLoadingPutMuseumRegistrar}
                onClick={async e => {
                  e.preventDefault();
                  if (isLoadingPutMuseumRegistrar) {
                    return;
                  }

                  try {
                    if (museumRegistrar) {
                      await putMuseumRegistrar({
                        donationId,
                        data: {
                          userId: museumRegistrar.id,
                        },
                      }).unwrap();
                    }

                    // Note: this used to be a separate step, and we are combining "confirm" and "confirmComplete". Keeping in case we need to revert back?
                    // await putDonationMuseumMatchConfirm({
                    //   donationId,
                    // }).unwrap();

                    await putDonationMuseumMatchComplete({
                      donationId,
                    }).unwrap();

                    setIsConfirmModalOpen(false);
                  } catch (e) {
                    console.log('something went wrong', e);
                  }
                }}
              >
                CONFIRM & BEGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isSuccessPutMatchComplete) {
    return <Redirect push to={`/institution/donations/active`} />;
  }

  if (obj) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mdm-header mx-auto d-flex">
          <Link to="/institution/proposals/active">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="mdm-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="mdm-header-back-container-content">
            <p className="mdm-header-title">Confirm Donation</p>
            <div className="mdm-header-text-container">
              <p className="mdm-header-text">
                Please review the donor information and confirm that you wish to
                proceed with the donation.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto tw-mx-[275px] tw-my-[34px] tw-w-[890px] tw-rounded-sm tw-border-[rgba(46,91,255,0.08)] tw-bg-[#edeeef] tw-py-5 tw-px-10 tw-shadow-md">
          <div className="mx-auto tw-mb-10 tw-w-[700px] tw-rounded-sm tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-white tw-px-20  tw-py-6 tw-shadow">
            <div className="mx-auto tw-w-[550px]">
              <p className="tw-mb-6 tw-mt-4 tw-text-lg tw-leading-3 tw-text-[#2e384d]">
                Donor Information
              </p>
              <div className="mdm-content-section">
                <p className="mdm-text-small-label">OBJECT SUMMARY</p>
                <div className="d-flex spc-mt-20">
                  <img
                    src={`https://res.cloudinary.com/ex-tech/image/fetch/h_153,w_114,c_fill,q_auto/https://${obj.primaryImage.bucket}.s3.amazonaws.com/${obj.primaryImage.imageId}`}
                    alt="primary"
                    className="mdm-object-img"
                  />
                  <div className="mdm-object-info-container">
                    <div className="spc-mb-5">
                      <p className="mdm-text-small gen-crop-txt-1">
                        {obj.artistName}
                      </p>
                    </div>
                    <p className="mdm-text-small-light gen-crop-txt-2">
                      {obj.title}
                      {', '}
                      <em>{obj.year}</em>
                    </p>
                    <Link
                      to={`/institution/object/${obj.id}`}
                      className="gen-link mdm-link"
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      View Object Details
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mdm-content-section">
                <div className="spc-mb-20">
                  <p className="mdm-text-small-label">DONOR</p>
                </div>
                <p className="mdm-text">
                  {getDonationDonorNameText({ donation })}
                </p>
              </div>
              <div className="mdm-content-section">
                <div className="spc-mb-20">
                  <p className="mdm-text-small-label">PRIMARY LOCATION</p>
                </div>
                <p className="mdm-text">{`${donation.donorInfo.donorAddress.city}, ${donation.donorInfo.donorAddress.state}`}</p>
              </div>
              {donation.donorInfo.representative ? (
                <div className="mdm-content-section">
                  <div className="spc-mb-20">
                    <p className="mdm-text-small-label">REPRESENTATIVE NAME</p>
                  </div>
                  <p className="mdm-text">{`${donation.donorInfo.representative.nameFirst} ${donation.donorInfo.representative.nameLast}`}</p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mx-auto tw-mb-10 tw-w-[700px] tw-rounded-sm tw-border tw-border-[rgba(46,91,255,0.08)] tw-bg-white tw-px-20  tw-py-6 tw-shadow">
            <div className="mx-auto tw-w-[550px]">
              <p className="tw-mb-6 tw-mt-4 tw-text-lg tw-leading-3 tw-text-[#2e384d]">
                Registrar
              </p>
              <div className="tw-mb-5 tw-w-full tw-pb-5">
                <div className="spc-mb-20">
                  <div className="d-flex">
                    <p className="tw-text-sm tw-text-gray-700">
                      Assign a registrar to this donation
                    </p>
                    <img
                      src={InfoIcon}
                      alt="details"
                      className="mdm-info-icon gen-cursor-hover-link mdm-info-icon-registrar"
                    />
                    <div className="ddm-hover-element mdm-hover-element-registrar">
                      <p className="mdm-hover-element-text">
                        You may assign a registrar or other colleague to this
                        donation. By doing so, all the steps in the donation
                        process will be assigned to them by default. You may
                        assign or reassign a registrar at any point.
                        <br />
                        <br />
                        All institution accounts are linked so you will be able
                        to follow the donation in progress through its formal
                        acceptance into the institution collection.
                      </p>
                    </div>
                  </div>
                  <p className="mdm-text-small-label tw-uppercase">Optional</p>
                </div>
                {museumRegistrar ? (
                  <div className="d-flex tw-justify-between tw-rounded tw-p-5 tw-shadow-md">
                    <div className="tw-flex">
                      <div className="ppm-contact-card-initials-container">
                        <p className="txt-15 text-center">
                          {museumRegistrar.nameFirst[0].toUpperCase()}
                          {museumRegistrar.nameLast[0].toUpperCase()}
                        </p>
                      </div>
                      <div>
                        <div className="ppm-contact-card-name-container tw-text-left">
                          <p className="ppm-contact-card-name-txt">{`${museumRegistrar.nameFirst} ${museumRegistrar.nameLast}`}</p>
                        </div>
                        <p className="ppm-contact-card-sub-txt">
                          {museumRegistrar.email}
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="tw-min-w-[125px] tw-rounded-full tw-bg-[#8798ad] tw-py-2 tw-px-10 tw-text-sm tw-uppercase tw-text-white"
                      onClick={() => setIsAssignRegistrarModalOpen(true)}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-border tw-border-[#f8f9fa] tw-bg-[#f8f9fa] tw-p-5 tw-shadow-inner">
                    <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
                      <div>
                        <svg
                          width="42"
                          height="42"
                          viewBox="0 0 42 42"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fillRule="nonzero"
                            stroke="#BEC7D3"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeOpacity=".751"
                          >
                            <path
                              d="M21 1C9.96 1 1 9.96 1 21s8.96 20 20 20 20-8.96 20-20S32.04 1 21 1z"
                              strokeDasharray="4.25,4.75"
                            />
                            <path d="M21 7c3.32 0 6 2.68 6 6s-2.68 6-6 6-6-2.68-6-6 2.68-6 6-6zM9 28.96c.06-3.98 8-6.16 12-6.16 3.98 0 11.94 2.18 12 6.16" />
                          </g>
                        </svg>
                      </div>
                      <div>
                        <svg
                          width="125"
                          height="39"
                          viewBox="0 0 125 39"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd" opacity=".209">
                            <rect
                              fill="#69707F"
                              width="109"
                              height="6"
                              rx="3"
                            />
                            <rect
                              fill="#8798AD"
                              y="19"
                              width="50"
                              height="6"
                              rx="3"
                            />
                            <rect
                              fill="#8798AD"
                              y="33"
                              width="125"
                              height="6"
                              rx="3"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="tw-min-w-[125px] tw-rounded-full tw-bg-[#8798ad] tw-py-2 tw-px-10 tw-text-sm tw-uppercase tw-text-white"
                      onClick={() => setIsAssignRegistrarModalOpen(true)}
                    >
                      Assign
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex tw-mb-10 tw-justify-center tw-gap-8">
            <div className="mdm-contact-container">
              <p className="mdm-text-small-dark">
                Speak to an{' '}
                <a
                  href="mailto:museum_liaison@museumexchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link"
                >
                  Institution Liaison
                </a>{' '}
                to address any questions or concerns
              </p>
            </div>
            <div
              className="mdm-btn-confirm gen-cursor-hover-link"
              onClick={e => {
                e.preventDefault();
                setIsConfirmModalOpen(true);
              }}
            >
              CONFIRM & BEGIN DONATION
            </div>
          </div>
        </div>
        {isConfirmModalOpen ? makeConfirmModal() : null}
        {isAssignRegistrarModalOpen ? (
          <AssignRegistrarModal
            museum={museumAccount}
            setIsAssignRegistrarModalOpen={setIsAssignRegistrarModalOpen}
            defaultRegistrar={museumRegistrar}
            onSelectRegistrar={registrar => {
              setMuseumRegistrar(registrar);
              setIsAssignRegistrarModalOpen(false);
            }}
          />
        ) : null}
      </div>
    );
  }

  if (mounted) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mdm-header mx-auto d-flex">
          <Link to="/institution/proposals/active">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="mdm-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="mdm-header-back-container-content">
            <p className="mdm-header-title">Confirm Acceptance</p>
          </div>
        </div>
        <p className="text-center mdm-error-text">
          <em>Please select a donation from the previous page.</em>
        </p>
      </div>
    );
  }

  return <div className="gen-background-nav-museum"></div>;
}
