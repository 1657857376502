export const DPROP_CLEAR_PROPOSALS = 'DPROP_CLEAR_PROPOSALS';
export const DPROP_GET_PROPOSALS_ACCOUNT_CLEAR =
  'DPROP_GET_PROPOSALS_ACCOUNT_CLEAR';
export const DPROP_GET_PROPOSALS_ACCOUNT_FAILURE =
  'DPROP_GET_PROPOSALS_ACCOUNT_FAILURE';
export const DPROP_GET_PROPOSALS_ACCOUNT_REQUEST =
  'DPROP_GET_PROPOSALS_ACCOUNT_REQUEST';
export const DPROP_GET_PROPOSALS_ACCOUNT_SUCCESS =
  'DPROP_GET_PROPOSALS_ACCOUNT_SUCCESS';
export const DPROP_SET_ACCEPT_MODAL = 'DPROP_SET_ACCEPT_MODAL';
export const DPROP_SET_SELECTED_IMAGE = 'DPROP_SET_SELECTED_IMAGE';
export const DPROP_SET_SELECTED_PROP_INDEX = 'DPROP_SET_SELECTED_PROP_INDEX';
export const DPROP_SET_SELECTED_PROPS = 'DPROP_SET_SELECTED_PROPS';
