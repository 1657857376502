import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useCallback } from 'react';
import SharePermissionsDropdown from './DonorAccountPermissionsDropdown';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

const defaultValues = {
  role: 'viewer',
  userEmail: '',
  action: 'invite',
};

export default function DonorAccountShareModal({
  isOpen,
  closeModal,
  onSubmit,
}) {
  const { register, handleSubmit, control, reset, formState } = useForm({
    defaultValues,
  });

  const { errors } = formState;

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [formState, reset]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    reset(defaultValues);
  }, [closeModal, reset]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="tw-fixed tw-inset-0 tw-z-10 tw-overflow-y-auto"
        onClose={handleCloseModal}
      >
        <div className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-px-4 tw-text-center">
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Dialog.Overlay className="tw-fixed tw-inset-0 tw-bg-black/25" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-scale-95"
            enterTo="tw-opacity-100 tw-scale-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100 tw-scale-100"
            leaveTo="tw-opacity-0 tw-scale-95"
          >
            <div className="tw-inline-block tw-w-[530px] tw-transform tw-rounded-lg tw-bg-white tw-p-6 tw-text-left tw-align-middle tw-transition-all">
              <div className="tw-grid tw-gap-8">
                <div className="tw-grid tw-gap-1">
                  <Dialog.Title as="h2" className="tw-mb-0 tw-text-xl">
                    Share Folder
                  </Dialog.Title>
                  <Dialog.Description className="tw-text-gray-500">
                    Give others access to objects in this folder. Select if you
                    would like this user to have editor or view only access.
                  </Dialog.Description>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="tw-grid tw-gap-8"
                >
                  <input
                    type="hidden"
                    {...register('action')}
                    value="invite"
                  ></input>
                  <div className="tw-grid tw-gap-2">
                    <div className="tw-flex tw-gap-1">
                      <div className="tw-grow">
                        <input
                          className="tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm tw-block tw-w-full tw-rounded tw-border tw-border-gray-300 tw-py-2 tw-px-3"
                          type="email"
                          placeholder="Add email address"
                          {...register('userEmail', { required: true })}
                        />
                      </div>
                      <Controller
                        control={control}
                        name="role"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <div className="tw-w-130 tw-min-w-[130px]">
                              <SharePermissionsDropdown
                                value={value}
                                onChange={onChange}
                                canRemoveUser={false}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                    {errors && (
                      <>
                        {errors.userEmail && (
                          <div className="tw-text-red-600">
                            Please enter a valid email
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className="tw-flex tw-justify-end tw-gap-5">
                    <button
                      type="button"
                      className="tw-rounded tw-border tw-border-blue-800 tw-py-2 tw-px-7 tw-uppercase"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="tw-rounded tw-border tw-border-blue-800 tw-bg-blue-800 tw-py-2 tw-px-7 tw-uppercase tw-text-white"
                      type="submit"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

DonorAccountShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
