import {
  PAGE_APP_RESET,
  PAGE_RESET,
  PAGE_ALLOW_REDIRECT,
  PAGE_DISALLOW_REDIRECT,
  PAGE_UPDATE_WINDOW_WIDTH,
  PAGE_SET_MENU_OPEN,
} from '../actionTypes';

export const pageAppReset = _ => {
  return {
    type: PAGE_APP_RESET,
  };
};

export const pageReset = _ => {
  return {
    type: PAGE_RESET,
  };
};

export const pageAllowRedirect = _ => {
  return {
    type: PAGE_ALLOW_REDIRECT,
  };
};

export const pageDisallowRedirect = _ => {
  return {
    type: PAGE_DISALLOW_REDIRECT,
  };
};

export const pageUpdateWindowWidth = payload => {
  return {
    type: PAGE_UPDATE_WINDOW_WIDTH,
    payload,
  };
};

export const pageSetMenuOpen = payload => {
  return {
    type: PAGE_SET_MENU_OPEN,
    payload,
  };
};
