import {
  CTG_SET_CLASSIFICATIONS,
  CTG_SET_CLASSIFICATIONS_OPEN,
} from '../../actions/actionTypes';

const initialCatalogueState = {
  classificationsOpen: true,
  classificationsFilters: null,
};

export const catalogueReducer = (state = initialCatalogueState, action) => {
  switch (action.type) {
    case CTG_SET_CLASSIFICATIONS:
      return {
        ...state,
        classificationsFilters: action.payload,
      };

    case CTG_SET_CLASSIFICATIONS_OPEN:
      return {
        ...state,
        classificationsOpen: action.payload,
      };

    default:
      return state;
  }
};
