import {
  LISTS_CLEAR_CUR,
  LISTS_CLEAR_CUR_PAST,
  LISTS_CLEAR_INT,
  LISTS_CLEAR_INT_PAST,
  LISTS_GET_CUR_CLEAR,
  LISTS_GET_CUR_FAILURE,
  LISTS_GET_CUR_REQUEST,
  LISTS_GET_CUR_SUCCESS,
  LISTS_GET_CUR_PAST_CLEAR,
  LISTS_GET_CUR_PAST_FAILURE,
  LISTS_GET_CUR_PAST_REQUEST,
  LISTS_GET_CUR_PAST_SUCCESS,
  LISTS_GET_INT_CLEAR,
  LISTS_GET_INT_FAILURE,
  LISTS_GET_INT_REQUEST,
  LISTS_GET_INT_SUCCESS,
  LISTS_GET_INT_PAST_CLEAR,
  LISTS_GET_INT_PAST_FAILURE,
  LISTS_GET_INT_PAST_REQUEST,
  LISTS_GET_INT_PAST_SUCCESS,
  LISTS_PUT_CUR_ADD_CLEAR,
  LISTS_PUT_CUR_ADD_FAILURE,
  LISTS_PUT_CUR_ADD_REQUEST,
  LISTS_PUT_CUR_ADD_SUCCESS,
  LISTS_PUT_CUR_APPROVE_CLEAR,
  LISTS_PUT_CUR_APPROVE_FAILURE,
  LISTS_PUT_CUR_APPROVE_REQUEST,
  LISTS_PUT_CUR_APPROVE_SUCCESS,
  LISTS_PUT_INT_ADD_CLEAR,
  LISTS_PUT_INT_ADD_FAILURE,
  LISTS_PUT_INT_ADD_REQUEST,
  LISTS_PUT_INT_ADD_SUCCESS,
  LISTS_PUT_CUR_REMOVE_CLEAR,
  LISTS_PUT_CUR_REMOVE_FAILURE,
  LISTS_PUT_CUR_REMOVE_REQUEST,
  LISTS_PUT_CUR_REMOVE_SUCCESS,
  LISTS_PUT_INT_REMOVE_CLEAR,
  LISTS_PUT_INT_REMOVE_FAILURE,
  LISTS_PUT_INT_REMOVE_REQUEST,
  LISTS_PUT_INT_REMOVE_SUCCESS,
  LISTS_SET_CUR_OBJECTS,
  LISTS_SET_INT_OBJECTS,
  LISTS_CLEAR_SELECTED_OBJECT,
  LISTS_SET_SELECTED_OBJECT,
  LISTS_CLEAR_CUR_ACTION,
  LISTS_SET_CUR_ACTION,
} from '../../actions/actionTypes';

const initialListsState = {
  curatorialAction: null,
  listCuratorial: null,
  listsCuratorialPast: null,
  listInterest: null,
  listsInterestPast: null,
  selectedObject: null,
  getListCuratorialRequest: {
    loading: false,
    data: null,
    error: '',
  },
  getListsCuratorialPastRequest: {
    loading: false,
    data: null,
    error: '',
  },
  getListInterestRequest: {
    loading: false,
    data: null,
    error: '',
  },
  getListsInterestPastRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putListCuratorialAddRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putListCuratorialApproveRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putListInterestAddRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putListCuratorialRemoveRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putListInterestRemoveRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const listsReducer = (state = initialListsState, action) => {
  switch (action.type) {
    case LISTS_CLEAR_CUR:
      return {
        ...state,
        listCuratorial: null,
      };

    case LISTS_CLEAR_CUR_PAST:
      return {
        ...state,
        listsCuratorialPast: null,
      };

    case LISTS_CLEAR_INT:
      return {
        ...state,
        listInterest: null,
      };

    case LISTS_CLEAR_INT_PAST:
      return {
        ...state,
        listsInterestPast: null,
      };

    case LISTS_GET_CUR_CLEAR:
      return {
        ...state,
        getListCuratorialRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_GET_CUR_FAILURE:
      return {
        ...state,
        getListCuratorialRequest: {
          ...state.getListCuratorialRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_GET_CUR_REQUEST:
      return {
        ...state,
        getListCuratorialRequest: {
          ...state.getListCuratorialRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_GET_CUR_SUCCESS:
      return {
        ...state,
        listCuratorial: action.payload,
        getListCuratorialRequest: {
          ...state.getListCuratorialRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_GET_CUR_PAST_CLEAR:
      return {
        ...state,
        getListsCuratorialPastRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_GET_CUR_PAST_FAILURE:
      return {
        ...state,
        getListsCuratorialPastRequest: {
          ...state.getListsCuratorialPastRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_GET_CUR_PAST_REQUEST:
      return {
        ...state,
        getListsCuratorialPastRequest: {
          ...state.getListsCuratorialPastRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_GET_CUR_PAST_SUCCESS:
      return {
        ...state,
        listsCuratorialPast: action.payload,
        getListsCuratorialPastRequest: {
          ...state.getListsCuratorialPastRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_GET_INT_CLEAR:
      return {
        ...state,
        getListInterestRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_GET_INT_FAILURE:
      return {
        ...state,
        getListInterestRequest: {
          ...state.getListInterestRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_GET_INT_REQUEST:
      return {
        ...state,
        getListInterestRequest: {
          ...state.getListInterestRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_GET_INT_SUCCESS:
      return {
        ...state,
        listInterest: action.payload,
        getListInterestRequest: {
          ...state.getListInterestRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_GET_INT_PAST_CLEAR:
      return {
        ...state,
        getListsInterestPastRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_GET_INT_PAST_FAILURE:
      return {
        ...state,
        getListsInterestPastRequest: {
          ...state.getListsInterestPastRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_GET_INT_PAST_REQUEST:
      return {
        ...state,
        getListsInterestPastRequest: {
          ...state.getListsInterestPastRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_GET_INT_PAST_SUCCESS:
      return {
        ...state,
        listsInterestPast: action.payload,
        getListsInterestPastRequest: {
          ...state.getListsInterestPastRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_PUT_CUR_ADD_CLEAR:
      return {
        ...state,
        putListCuratorialAddRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_PUT_CUR_ADD_FAILURE:
      return {
        ...state,
        putListCuratorialAddRequest: {
          ...state.putListCuratorialAddRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_PUT_CUR_ADD_REQUEST:
      return {
        ...state,
        putListCuratorialAddRequest: {
          ...state.putListCuratorialAddRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_PUT_CUR_ADD_SUCCESS:
      return {
        ...state,
        listCuratorial: action.payload,
        putListCuratorialAddRequest: {
          ...state.putListCuratorialAddRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_PUT_CUR_APPROVE_CLEAR:
      return {
        ...state,
        putListCuratorialApproveRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_PUT_CUR_APPROVE_FAILURE:
      return {
        ...state,
        putListCuratorialApproveRequest: {
          ...state.putListCuratorialApproveRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_PUT_CUR_APPROVE_REQUEST:
      return {
        ...state,
        putListCuratorialApproveRequest: {
          ...state.putListCuratorialApproveRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_PUT_CUR_APPROVE_SUCCESS:
      return {
        ...state,
        listCuratorial: action.payload,
        putListCuratorialApproveRequest: {
          ...state.putListCuratorialApproveRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_PUT_INT_ADD_CLEAR:
      return {
        ...state,
        putListInterestAddRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_PUT_INT_ADD_FAILURE:
      return {
        ...state,
        putListInterestAddRequest: {
          ...state.putListInterestAddRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_PUT_INT_ADD_REQUEST:
      return {
        ...state,
        putListInterestAddRequest: {
          ...state.putListInterestAddRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_PUT_INT_ADD_SUCCESS:
      return {
        ...state,
        listInterest: action.payload,
        putListInterestAddRequest: {
          ...state.putListInterestAddRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_PUT_CUR_REMOVE_CLEAR:
      return {
        ...state,
        putListCuratorialRemoveRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_PUT_CUR_REMOVE_FAILURE:
      return {
        ...state,
        putListCuratorialRemoveRequest: {
          ...state.putListCuratorialRemoveRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_PUT_CUR_REMOVE_REQUEST:
      return {
        ...state,
        putListCuratorialRemoveRequest: {
          ...state.putListCuratorialRemoveRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_PUT_CUR_REMOVE_SUCCESS:
      return {
        ...state,
        listCuratorial: action.payload,
        putListCuratorialRemoveRequest: {
          ...state.putListCuratorialRemoveRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_PUT_INT_REMOVE_CLEAR:
      return {
        ...state,
        putListInterestRemoveRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case LISTS_PUT_INT_REMOVE_FAILURE:
      return {
        ...state,
        putListInterestRemoveRequest: {
          ...state.putListInterestRemoveRequest,
          loading: false,
          error: action.payload,
        },
      };

    case LISTS_PUT_INT_REMOVE_REQUEST:
      return {
        ...state,
        putListInterestRemoveRequest: {
          ...state.putListInterestRemoveRequest,
          loading: true,
          error: '',
        },
      };

    case LISTS_PUT_INT_REMOVE_SUCCESS:
      return {
        ...state,
        listInterest: action.payload,
        putListInterestRemoveRequest: {
          ...state.putListInterestRemoveRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case LISTS_SET_CUR_OBJECTS:
      if (state.listCuratorial && state.listCuratorial.objects) {
        return {
          ...state,
          listCuratorial: {
            ...state.listCuratorial,
            objects: action.payload,
          },
        };
      } else {
        return state;
      }

    case LISTS_SET_INT_OBJECTS:
      if (state.listInterest && state.listInterest.objects) {
        return {
          ...state,
          listInterest: {
            ...state.listInterest,
            objects: action.payload,
          },
        };
      } else {
        return state;
      }

    case LISTS_CLEAR_SELECTED_OBJECT:
      return {
        ...state,
        selectedObject: null,
      };

    case LISTS_SET_SELECTED_OBJECT:
      return {
        ...state,
        selectedObject: action.payload,
      };

    case LISTS_CLEAR_CUR_ACTION:
      return {
        ...state,
        curatorialAction: null,
      };

    case LISTS_SET_CUR_ACTION:
      return {
        ...state,
        curatorialAction: action.payload,
      };

    default:
      return state;
  }
};
