import {
  AUTH_CLEAR_TEL,
  AUTH_CLEAR_TOKEN,
  AUTH_POST_LOGIN_CLEAR,
  AUTH_POST_LOGIN_FAILURE,
  AUTH_POST_LOGIN_REQUEST,
  AUTH_POST_LOGIN_SUCCESS,
  AUTH_POST_RESET_CLEAR,
  AUTH_POST_RESET_FAILURE,
  AUTH_POST_RESET_REQUEST,
  AUTH_POST_RESET_SUCCESS,
  AUTH_POST_VERIFY_CLEAR,
  AUTH_POST_VERIFY_FAILURE,
  AUTH_POST_VERIFY_REQUEST,
  AUTH_POST_VERIFY_SUCCESS,
  AUTH_POST_VERIFY_RESEND_CLEAR,
  AUTH_POST_VERIFY_RESEND_FAILURE,
  AUTH_POST_VERIFY_RESEND_REQUEST,
  AUTH_POST_VERIFY_RESEND_SUCCESS,
  AUTH_PUT_LOGOUT_CLEAR,
  AUTH_PUT_LOGOUT_FAILURE,
  AUTH_PUT_LOGOUT_REQUEST,
  AUTH_PUT_LOGOUT_SUCCESS,
  AUTH_PUT_RESET_CLEAR,
  AUTH_PUT_RESET_FAILURE,
  AUTH_PUT_RESET_REQUEST,
  AUTH_PUT_RESET_SUCCESS,
  AUTH_PUT_VERIFY_CLEAR,
  AUTH_PUT_VERIFY_FAILURE,
  AUTH_PUT_VERIFY_REQUEST,
  AUTH_PUT_VERIFY_SUCCESS,
  AUTH_UPDATE_TOKEN,
  AUTH_CA_RESET_FORM,
  AUTH_CA_UPDATE_ERROR,
  AUTH_CA_UPDATE_PASSWORD,
  AUTH_CA_PUT_CHANGE_CLEAR,
  AUTH_CA_PUT_CHANGE_FAILURE,
  AUTH_CA_PUT_CHANGE_REQUEST,
  AUTH_CA_PUT_CHANGE_SUCCESS,
  AUTH_CC_RESET_FORM,
  AUTH_CC_UPDATE_ERRORS,
  AUTH_CC_UPDATE_PW_ONE,
  AUTH_CC_UPDATE_PW_TWO,
  AUTH_CC_PUT_CHANGE_CLEAR,
  AUTH_CC_PUT_CHANGE_FAILURE,
  AUTH_CC_PUT_CHANGE_REQUEST,
  AUTH_CC_PUT_CHANGE_SUCCESS,
} from '../../actions/actionTypes';

const initialAuthState = {
  caPassword: '',
  caPasswordError: false,
  ccFormData: {
    passwordOne: '',
    passwordTwo: '',
  },
  ccFormErrors: {
    formError: false,
    passwordOneError: false,
    passwordTwoError: false,
  },
  token: null,
  telObj: null,
  postLoginRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postResetRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postVerifyRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postVerifyResendRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putLogoutRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putResetRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putVerifyRequest: {
    loading: false,
    data: null,
    error: '',
  },
  caPutChangeRequest: {
    loading: false,
    data: null,
    error: '',
  },
  ccPutChangeRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case AUTH_CLEAR_TEL:
      return {
        ...state,
        telObj: null,
      };

    case AUTH_CLEAR_TOKEN:
      return {
        ...state,
        token: null,
      };

    case AUTH_POST_LOGIN_CLEAR:
      return {
        ...state,
        postLoginRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_POST_LOGIN_FAILURE:
      return {
        ...state,
        postLoginRequest: {
          ...state.postLoginRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_POST_LOGIN_REQUEST:
      return {
        ...state,
        postLoginRequest: {
          ...state.postLoginRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_POST_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        postLoginRequest: {
          ...state.postLoginRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_POST_RESET_CLEAR:
      return {
        ...state,
        postResetRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_POST_RESET_FAILURE:
      return {
        ...state,
        postResetRequest: {
          ...state.postResetRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_POST_RESET_REQUEST:
      return {
        ...state,
        postResetRequest: {
          ...state.postResetRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_POST_VERIFY_CLEAR:
      return {
        ...state,
        postVerifyRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_POST_VERIFY_FAILURE:
      return {
        ...state,
        postVerifyRequest: {
          ...state.postVerifyRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_POST_VERIFY_REQUEST:
      return {
        ...state,
        postVerifyRequest: {
          ...state.postVerifyRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_POST_VERIFY_SUCCESS:
      return {
        ...state,
        telObj: action.payload,
        postVerifyRequest: {
          ...state.postVerifyRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_POST_VERIFY_RESEND_CLEAR:
      return {
        ...state,
        postVerifyResendRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_POST_VERIFY_RESEND_FAILURE:
      return {
        ...state,
        postVerifyResendRequest: {
          ...state.postVerifyResendRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_POST_VERIFY_RESEND_REQUEST:
      return {
        ...state,
        postVerifyResendRequest: {
          ...state.postVerifyResendRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_POST_VERIFY_RESEND_SUCCESS:
      return {
        ...state,
        telObj: action.payload,
        postVerifyResendRequest: {
          ...state.postVerifyResendRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_POST_RESET_SUCCESS:
      return {
        ...state,
        postResetRequest: {
          ...state.postResetRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_PUT_LOGOUT_CLEAR:
      return {
        ...state,
        putLogoutRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_PUT_LOGOUT_FAILURE:
      return {
        ...state,
        putLogoutRequest: {
          ...state.putLogoutRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_PUT_LOGOUT_REQUEST:
      return {
        ...state,
        putLogoutRequest: {
          ...state.putLogoutRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_PUT_LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        putLogoutRequest: {
          ...state.putLogoutRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_PUT_RESET_CLEAR:
      return {
        ...state,
        putResetRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_PUT_RESET_FAILURE:
      return {
        ...state,
        putResetRequest: {
          ...state.putResetRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_PUT_RESET_REQUEST:
      return {
        ...state,
        putResetRequest: {
          ...state.putResetRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_PUT_RESET_SUCCESS:
      return {
        ...state,
        putResetRequest: {
          ...state.putResetRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_PUT_VERIFY_CLEAR:
      return {
        ...state,
        putVerifyRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_PUT_VERIFY_FAILURE:
      return {
        ...state,
        putVerifyRequest: {
          ...state.putVerifyRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_PUT_VERIFY_REQUEST:
      return {
        ...state,
        putVerifyRequest: {
          ...state.putVerifyRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_PUT_VERIFY_SUCCESS:
      return {
        ...state,
        putVerifyRequest: {
          ...state.putVerifyRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_UPDATE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };

    case AUTH_CA_RESET_FORM:
      return {
        ...state,
        caPassword: '',
        caPasswordError: false,
      };

    case AUTH_CA_UPDATE_ERROR:
      return {
        ...state,
        caPasswordError: action.payload.caPasswordError,
      };

    case AUTH_CA_UPDATE_PASSWORD:
      return {
        ...state,
        caPassword: action.payload.caPassword,
      };

    case AUTH_CA_PUT_CHANGE_CLEAR:
      return {
        ...state,
        caPutChangeRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_CA_PUT_CHANGE_FAILURE:
      return {
        ...state,
        caPutChangeRequest: {
          ...state.caPutChangeRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_CA_PUT_CHANGE_REQUEST:
      return {
        ...state,
        caPutChangeRequest: {
          ...state.caPutChangeRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_CA_PUT_CHANGE_SUCCESS:
      return {
        ...state,
        caPutChangeRequest: {
          ...state.caPutChangeRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case AUTH_CC_RESET_FORM:
      return {
        ...state,
        ccFormData: {
          passwordOne: '',
          passwordTwo: '',
        },
        ccFormErrors: {
          formError: false,
          passwordOneError: false,
          passwordTwoError: false,
        },
      };

    case AUTH_CC_UPDATE_ERRORS:
      return {
        ...state,
        ccFormErrors: action.payload.ccFormErrors,
      };

    case AUTH_CC_UPDATE_PW_ONE:
      return {
        ...state,
        ccFormData: {
          ...state.ccFormData,
          passwordOne: action.payload.passwordOne,
        },
      };

    case AUTH_CC_UPDATE_PW_TWO:
      return {
        ...state,
        ccFormData: {
          ...state.ccFormData,
          passwordTwo: action.payload.passwordTwo,
        },
      };

    case AUTH_CC_PUT_CHANGE_CLEAR:
      return {
        ...state,
        ccPutChangeRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case AUTH_CC_PUT_CHANGE_FAILURE:
      return {
        ...state,
        ccPutChangeRequest: {
          ...state.ccPutChangeRequest,
          loading: false,
          error: action.payload,
        },
      };

    case AUTH_CC_PUT_CHANGE_REQUEST:
      return {
        ...state,
        ccPutChangeRequest: {
          ...state.ccPutChangeRequest,
          loading: true,
          error: '',
        },
      };

    case AUTH_CC_PUT_CHANGE_SUCCESS:
      return {
        ...state,
        ccPutChangeRequest: {
          ...state.ccPutChangeRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    default:
      return state;
  }
};
