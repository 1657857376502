import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/general.css';
import '../../styles/museum-donation-match.css';
import '../../styles/spacing.css';
import '../../styles/text.css';
import {
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
  usePutDonationMuseumApprovalDatesMutation,
} from 'requests/api';
import { useParams } from 'react-router-dom';
import { useMuseumGetDonation } from 'components/_shared/useMuseumGetDonation';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SelectButton } from 'components/_shared/forms/SelectButton';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { MEETING_TYPES } from 'constants/donations';

const defaultValues = {
  committeeMeetingType: MEETING_TYPES.unspecified,
  committeeMeetingDate: null,

  ratificationMeetingType: MEETING_TYPES.unspecified,
  ratificationMeetingDate: null,
};

export default function MuseumDonationsPreliminaryApprovalDates() {
  const { donationId } = useParams();
  const { goBack } = useHistory();

  const { data: museumAccount, isError: isErrorMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const { data: donation, isError: isErrorDonation } = useMuseumGetDonation({
    donationId,
    accountId: museumAccount?.id,
  });

  const { data: obj, isError: isErrorObj } = useGetObjMuseumQuery(
    { objectId: donation?.objectId },
    { skip: !donation },
  );

  const [
    putDonationMuseumApprovalDates,
    {
      isLoading: isLoadingPutMuseumApprovalDates,
      isSuccess: isSuccessPutMuseumApprovalDates,
    },
  ] = usePutDonationMuseumApprovalDatesMutation();

  const { register, handleSubmit, formState, setValue, watch } = useForm({
    defaultValues,
  });

  async function onSubmit(values) {
    if (isSubmitting) return;
    if (isLoadingPutMuseumApprovalDates) return;

    try {
      await putDonationMuseumApprovalDates({
        donationId: donation.id,
        data: values,
      }).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  const { errors, isSubmitting } = formState;

  const committeeMeetingType = watch('committeeMeetingType');
  const ratificationMeetingType = watch('ratificationMeetingType');

  if (isSuccessPutMuseumApprovalDates) {
    if (!donation?.museumInfo?.preshippingRequirementsSubmitted) {
      return (
        <Redirect
          push
          to={`/institution/donations/${donationId}/preliminary/preshipping`}
        />
      );
    } else {
      return <Redirect push to={`/institution/donations/${donationId}`} />;
    }
  }

  if (isErrorDonation || isErrorMuseumAccount || isErrorObj) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mdm-header mx-auto d-flex">
          <Link to="/institution/proposals/active">
            <img
              src={BackIcon}
              alt="back-arrow"
              className="mdm-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="mdm-header-back-container-content">
            <p className="mdm-header-title">Approval Dates</p>
          </div>
        </div>
        <p className="text-center mdm-error-text">
          <em>Something went wrong. Please try again.</em>
        </p>
      </div>
    );
  }

  if (obj) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mdm-header mx-auto d-flex">
          <img
            src={BackIcon}
            alt="back-arrow"
            className="ddm-header-icon-back gen-cursor-hover-link"
            onClick={e => goBack()}
          />
          <div class-name="mdm-header-back-container-content">
            <p className="mdm-header-title">Approval Dates</p>
            <div className="mdm-header-text-container">
              <p className="mdm-header-text">
                Please provide the approval dates when the object will be
                presented for donation consideration. You will be prompted to
                provide the outcome of these meetings/inspections.
              </p>
            </div>
          </div>
        </div>
        <form
          className="mdm-match-two-content mx-auto"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="mdm-match-two-content-inner mx-auto">
            <div className="mdm-match-two-content-inner-section mx-auto">
              <div className="tw-grid tw-gap-3">
                <p
                  className={`mdm-match-two-title-text ${
                    errors.committeeMeetingType ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Preliminary Approval
                </p>
                <input
                  type="hidden"
                  {...register('committeeMeetingType', { required: true })}
                />
                <SelectButton
                  text={
                    <span>
                      Unspecified Date{' '}
                      <span className="tw-ml-2 tw-italic">
                        (upon viewing object)
                      </span>
                    </span>
                  }
                  onSelect={() => {
                    setValue('committeeMeetingType', MEETING_TYPES.unspecified);
                    setValue('committeeMeetingDate', null);
                  }}
                  isActive={committeeMeetingType === MEETING_TYPES.unspecified}
                />
                <SelectButton
                  text={
                    <span>
                      Specified Date{' '}
                      <span className="tw-ml-2 tw-italic">
                        (scheduled meeting)
                      </span>
                    </span>
                  }
                  onSelect={_ => {
                    setValue('committeeMeetingType', MEETING_TYPES.specified);
                    setValue('committeeMeetingDate', '');
                  }}
                  isActive={committeeMeetingType === MEETING_TYPES.specified}
                />
                {committeeMeetingType === MEETING_TYPES.specified ? (
                  <input
                    type="text"
                    className={`fd-input mdm-field-narrow ${
                      errors.committeeMeetingDate ? 'fd-input-error-field' : ''
                    }`}
                    {...register('committeeMeetingDate', {
                      required: true,
                      minLength: 6,
                      maxLength: 8,
                    })}
                    minLength="6"
                    maxLength="8"
                    placeholder="MM/DD/YY"
                  ></input>
                ) : null}
                <SelectButton
                  text="Not Applicable"
                  onSelect={() => {
                    setValue(
                      'committeeMeetingType',
                      MEETING_TYPES.notApplicable,
                    );
                    setValue('committeeMeetingDate', null);
                  }}
                  isActive={
                    committeeMeetingType === MEETING_TYPES.notApplicable
                  }
                />
              </div>
            </div>
            <div className="mdm-match-two-content-inner-section mx-auto">
              <div className="tw-grid tw-gap-3">
                <p
                  className={`mdm-match-two-title-text ${
                    errors.ratificationMeetingType ? 'fd-input-error-txt' : ''
                  }`}
                >
                  Ratification
                </p>
                <input
                  type="hidden"
                  {...register('ratificationMeetingType', { required: true })}
                />
                <SelectButton
                  text={
                    <span>
                      Unspecified Date{' '}
                      <span className="tw-ml-2 tw-italic">
                        (upon viewing object)
                      </span>
                    </span>
                  }
                  onSelect={() => {
                    setValue(
                      'ratificationMeetingType',
                      MEETING_TYPES.unspecified,
                    );
                    setValue('ratificationMeetingDate', null);
                  }}
                  isActive={
                    ratificationMeetingType === MEETING_TYPES.unspecified
                  }
                />
                <SelectButton
                  text={
                    <span>
                      Specified Date{' '}
                      <span className="tw-ml-2 tw-italic">
                        (scheduled meeting)
                      </span>
                    </span>
                  }
                  onSelect={_ => {
                    setValue(
                      'ratificationMeetingType',
                      MEETING_TYPES.specified,
                    );
                    setValue('ratificationMeetingDate', '');
                  }}
                  isActive={ratificationMeetingType === MEETING_TYPES.specified}
                />
                {ratificationMeetingType === MEETING_TYPES.specified ? (
                  <input
                    type="text"
                    className={`fd-input mdm-field-narrow ${
                      errors.ratificationMeetingDate
                        ? 'fd-input-error-field'
                        : ''
                    }`}
                    {...register('ratificationMeetingDate', {
                      required: true,
                      minLength: 6,
                      maxLength: 8,
                    })}
                    minLength="6"
                    maxLength="8"
                    placeholder="MM/DD/YY"
                  ></input>
                ) : null}
                <SelectButton
                  text={<span>Not Applicable</span>}
                  onSelect={() => {
                    setValue(
                      'ratificationMeetingType',
                      MEETING_TYPES.notApplicable,
                    );
                    setValue('ratificationMeetingDate', null);
                  }}
                  isActive={
                    ratificationMeetingType === MEETING_TYPES.notApplicable
                  }
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex align-items-end flex-column">
                <input
                  type="submit"
                  disabled={isSubmitting}
                  className={`mdm-btn-next-inactive gen-cursor-hover-link tw-uppercase disabled:tw-opacity-50 ${
                    isEmpty(errors) ? 'mdm-btn-next-active' : ''
                  }`}
                  value="Submit"
                />
                {!isEmpty(errors) ? (
                  <div className="mdm-error-message-container">
                    <p className="mdm-error-message">
                      Please provide the approval dates when the object will be
                      presented for acquisition consideration. You will be
                      prompted to provide the outcome of these
                      meetings/inspections.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return <div className="gen-background-nav-museum"></div>;
}
