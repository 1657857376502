export const validateUserMuseumAdmin = (userId, museum) => {
  let admin = false;
  if (typeof museum === 'object' && Array.isArray(museum.users)) {
    museum.users.forEach(u => {
      if (u.userId === userId && (u.role === 'owner' || u.role === 'admin')) {
        admin = true;
      }
    });
  }
  return admin;
};
