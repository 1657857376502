import { ONGOING_CYCLE_NAME } from 'constants/cycles';
import { INSTITUTION_KINDS } from 'constants/institutions';
import {
  useGetCyclesSortedQuery,
  useGetMuseumAccountUserQuery,
  useGetObjSummariesQuery,
} from 'requests/api';

export function useMuseumGetObjectSummariesInCurrentCycle() {
  const { data: cyclesSorted } = useGetCyclesSortedQuery();
  const { data: museumAccount } = useGetMuseumAccountUserQuery();

  const currentCycle = findCurrentCycleByInstitutionKind({
    institutionAccount: museumAccount,
    cyclesSorted,
  });

  const { data, isLoading, isError } = useGetObjSummariesQuery(
    { cycleId: currentCycle?.id },
    { skip: !currentCycle },
  );

  return {
    // data,
    activeObjectSummaries: data?.activeObjects,
    inactiveObjectSummaries: data?.inactiveObjects,
    currentCycle,
    isLoading,
    isError,
  };
}

function findCurrentCycleByInstitutionKind({
  institutionAccount,
  cyclesSorted,
}) {
  if (!cyclesSorted) return;
  if (!institutionAccount) return;

  const findFn =
    institutionAccount.kind === INSTITUTION_KINDS.other
      ? cycle => cycle.name === ONGOING_CYCLE_NAME
      : cycle => cycle.name !== ONGOING_CYCLE_NAME;

  const currentCycle = cyclesSorted.current?.find?.(findFn);

  return currentCycle;
}
