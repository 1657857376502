import React, { useState } from 'react';
import CloseIcon from '@images/grey-close-icon.png';
import { useSelector } from 'react-redux';
import { useGetUsersMuseumQuery } from 'requests/api';
import ContactCard from 'components/donorAccounts/DonorAccount/DonorAccountTransferObject/DonorAccountContactCard';
import MuseumInviteUserByEmailModal from 'components/museumComponents/MuseumInviteUserByEmailModal';

export default function AssignRegistrarModal({
  defaultRegistrar,
  museum,
  onSelectRegistrar: _onSelectRegistrar,
  setIsAssignRegistrarModalOpen,
}) {
  const user = useSelector(state => state.userState.user);
  const { data: museumUsers, isLoading: isLoadingMuseumUsers } =
    useGetUsersMuseumQuery(user.museum.museumId);

  const [registrarTarget, setRegistrarTarget] = useState(defaultRegistrar);

  function onClose(e) {
    setIsAssignRegistrarModalOpen(false);
    setRegistrarTarget(null);
  }

  function onSelectRegistrar(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!registrarTarget) return;

    _onSelectRegistrar(registrarTarget);
  }

  const [isRegistrarEmailModalOpen, setIsRegistrarEmailModalOpen] =
    useState(false);

  return (
    <div
      className="mod"
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="mx-auto d-flex justify-content-center ppm-container">
        <div className="ppm-left">
          <button onClick={onClose}>
            <img
              src={CloseIcon}
              alt="close"
              className="ppm-btn-close gen-cursor-hover-link"
            />
          </button>
          <div className="ppm-left-inner">
            <div className="d-flex">
              <p className="ppm-title-text">Assign a Registrar</p>
            </div>
            <p className="ppm-sub-title-text">
              You may assign a registar to this donation. By doing so, all
              donation tasks will be assigned to the registrar by default. You
              may assign or reassign a registar at any point.
            </p>
            <div className="ppm-contacts-container">
              {Array.isArray(museumUsers)
                ? museumUsers.map(v => (
                    <ContactCard
                      key={`network-contacts-${v.id}`}
                      contact={v}
                      purpose="list"
                      onClick={() => {
                        setRegistrarTarget(v);
                      }}
                      isSelected={v.id === registrarTarget?.id}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="ppm-right">
          {registrarTarget ? (
            <div className="ppm-right-transfer-target-container">
              <div className="spc-mb-15">
                <p className="ppm-small-header tw-uppercase">
                  Selected Registrar
                </p>
              </div>
              <ContactCard
                contact={registrarTarget}
                purpose="modalDisplay"
                onRemove={() => setRegistrarTarget(null)}
              />
              <button
                className="ppm-right-confirm-btn gen-cursor-hover-link tw-uppercase"
                onClick={onSelectRegistrar}
              >
                Select Registrar
              </button>
            </div>
          ) : (
            <div className="ppm-right-placeholder"></div>
          )}
          {
            <div className="tw-mx-auto tw-max-w-xs">
              <p className="ppm-right-empty-container-top">
                Don't see the user you are looking for?
              </p>
              <p className="ppm-right-empty-container-bottom">
                Invite your registrar to join the platform by{' '}
                <button
                  className="gen-cursor-hover-link tw-text-center tw-text-sm tw-text-blue"
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsRegistrarEmailModalOpen(true);
                  }}
                >
                  entering their email address
                </button>
                .
              </p>
            </div>
          }
        </div>
      </div>
      {isRegistrarEmailModalOpen ? (
        <MuseumInviteUserByEmailModal
          museum={museum}
          closeModal={() => setIsRegistrarEmailModalOpen(false)}
        />
      ) : null}
    </div>
  );
}
