export const CONFIRM_EMAIL_RESET = 'CONFIRM_EMAIL_RESET';
export const CONFIRM_EMAIL_UPDATE_EMAIL = 'CONFIRM_EMAIL_UPDATE_EMAIL';
export const CONFIRM_EMAIL_UPDATE_EMAIL_ID = 'CONFIRM_EMAIL_UPDATE_EMAIL_ID';
export const CONFIRM_EMAIL_UPDATE_FORM_ERRORS =
  'CONFIRM_EMAIL_UPDATE_FORM_ERRORS';
export const CONFIRM_EMAIL_UPDATE_LOADING = 'CONFIRM_EMAIL_UPDATE_LOADING';
export const CONFIRM_EMAIL_UPDATE_PASSWORD = 'CONFIRM_EMAIL_UPDATE_PASSWORD';
export const CONFIRM_EMAIL_PUT_CONFIRM_CLEAR =
  'CONFIRM_EMAIL_PUT_CONFIRM_CLEAR';
export const CONFIRM_EMAIL_PUT_CONFIRM_FAILURE =
  'CONFIRM_EMAIL_PUT_CONFIRM_FAILURE';
export const CONFIRM_EMAIL_PUT_CONFIRM_REQUEST =
  'CONFIRM_EMAIL_PUT_CONFIRM_REQUEST';
export const CONFIRM_EMAIL_PUT_CONFIRM_SUCCESS =
  'CONFIRM_EMAIL_PUT_CONFIRM_SUCCESS';
