import { dacctActions } from 'features/donorAccounts/donorAccountsSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteObjectDraftAndGetSelectedAccount } from 'requests/objectDraftRequests/objectDraftRequests';

export function DeleteObjectDraftModal({ selectedAccount }) {
  const dispatch = useDispatch();

  const draftDeleteModalOpen = useSelector(
    state => state.donorAccountsState.draftDeleteModalOpen,
  );

  const deleteDraftRequestData = useSelector(
    state => state.donorAccountsState.deleteDraftRequest.data,
  );

  const token = useSelector(state => state.authState.token);

  const selectedObject = useSelector(
    state => state.donorAccountsState.selectedObject,
  );

  if (!draftDeleteModalOpen) return null;
  if (deleteDraftRequestData) return null;

  return (
    <div className="mod">
      <div className="mod-content-container mx-auto">
        <p className="mod-txt-header">
          Are you sure you
          <br />
          would like to delete this
          <br /> object draft?
        </p>
        <div className="mod-btn-container mx-auto d-flex justify-content-between">
          <button
            className="btn mod-btn-light"
            onClick={e => {
              e.stopPropagation();
              dispatch(dacctActions.dacctSetDraftDeleteModalOpen(false));
            }}
          >
            CANCEL
          </button>
          <button
            className="btn mod-btn-dark"
            onClick={e => {
              e.stopPropagation();
              dispatch(
                deleteObjectDraftAndGetSelectedAccount(
                  token,
                  selectedObject.id,
                  selectedAccount.id,
                ),
              );
            }}
          >
            YES, DELETE DRAFT
          </button>
        </div>
      </div>
    </div>
  );
}
