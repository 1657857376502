import {
  LISTS_CLEAR_CUR,
  LISTS_CLEAR_CUR_PAST,
  LISTS_CLEAR_INT,
  LISTS_CLEAR_INT_PAST,
  LISTS_GET_CUR_CLEAR,
  LISTS_GET_CUR_FAILURE,
  LISTS_GET_CUR_REQUEST,
  LISTS_GET_CUR_SUCCESS,
  LISTS_GET_CUR_PAST_CLEAR,
  LISTS_GET_CUR_PAST_FAILURE,
  LISTS_GET_CUR_PAST_REQUEST,
  LISTS_GET_CUR_PAST_SUCCESS,
  LISTS_GET_INT_CLEAR,
  LISTS_GET_INT_FAILURE,
  LISTS_GET_INT_REQUEST,
  LISTS_GET_INT_SUCCESS,
  LISTS_GET_INT_PAST_CLEAR,
  LISTS_GET_INT_PAST_FAILURE,
  LISTS_GET_INT_PAST_REQUEST,
  LISTS_GET_INT_PAST_SUCCESS,
  LISTS_PUT_CUR_ADD_CLEAR,
  LISTS_PUT_CUR_ADD_FAILURE,
  LISTS_PUT_CUR_ADD_REQUEST,
  LISTS_PUT_CUR_ADD_SUCCESS,
  LISTS_PUT_CUR_APPROVE_CLEAR,
  LISTS_PUT_CUR_APPROVE_FAILURE,
  LISTS_PUT_CUR_APPROVE_REQUEST,
  LISTS_PUT_CUR_APPROVE_SUCCESS,
  LISTS_PUT_INT_ADD_CLEAR,
  LISTS_PUT_INT_ADD_FAILURE,
  LISTS_PUT_INT_ADD_REQUEST,
  LISTS_PUT_INT_ADD_SUCCESS,
  LISTS_PUT_CUR_REMOVE_CLEAR,
  LISTS_PUT_CUR_REMOVE_FAILURE,
  LISTS_PUT_CUR_REMOVE_REQUEST,
  LISTS_PUT_CUR_REMOVE_SUCCESS,
  LISTS_PUT_INT_REMOVE_CLEAR,
  LISTS_PUT_INT_REMOVE_FAILURE,
  LISTS_PUT_INT_REMOVE_REQUEST,
  LISTS_PUT_INT_REMOVE_SUCCESS,
  LISTS_SET_CUR_OBJECTS,
  LISTS_SET_INT_OBJECTS,
  LISTS_CLEAR_SELECTED_OBJECT,
  LISTS_SET_SELECTED_OBJECT,
  LISTS_CLEAR_CUR_ACTION,
  LISTS_SET_CUR_ACTION,
} from '../actionTypes';

export const listsClearCuratorial = _ => {
  return {
    type: LISTS_CLEAR_CUR,
  };
};

export const listsClearCuratorialPast = _ => {
  return {
    type: LISTS_CLEAR_CUR_PAST,
  };
};

export const listsClearInterest = _ => {
  return {
    type: LISTS_CLEAR_INT,
  };
};

export const listsClearInterestPast = _ => {
  return {
    type: LISTS_CLEAR_INT_PAST,
  };
};

export const listsGetCuratorialClear = _ => {
  return {
    type: LISTS_GET_CUR_CLEAR,
  };
};

export const listsGetCuratorialFailure = payload => {
  return {
    type: LISTS_GET_CUR_FAILURE,
    payload,
  };
};

export const listsGetCuratorialRequest = _ => {
  return {
    type: LISTS_GET_CUR_REQUEST,
  };
};

export const listsGetCuratorialSuccess = payload => {
  return {
    type: LISTS_GET_CUR_SUCCESS,
    payload,
  };
};

export const listsGetCuratorialPastClear = _ => {
  return {
    type: LISTS_GET_CUR_PAST_CLEAR,
  };
};

export const listsGetCuratorialPastFailure = payload => {
  return {
    type: LISTS_GET_CUR_PAST_FAILURE,
    payload,
  };
};

export const listsGetCuratorialPastRequest = _ => {
  return {
    type: LISTS_GET_CUR_PAST_REQUEST,
  };
};

export const listsGetCuratorialPastSuccess = payload => {
  return {
    type: LISTS_GET_CUR_PAST_SUCCESS,
    payload,
  };
};

export const listsGetInterestClear = _ => {
  return {
    type: LISTS_GET_INT_CLEAR,
  };
};

export const listsGetInterestFailure = payload => {
  return {
    type: LISTS_GET_INT_FAILURE,
    payload,
  };
};

export const listsGetInterestRequest = _ => {
  return {
    type: LISTS_GET_INT_REQUEST,
  };
};

export const listsGetInterestSuccess = payload => {
  return {
    type: LISTS_GET_INT_SUCCESS,
    payload,
  };
};

export const listsGetInterestPastClear = _ => {
  return {
    type: LISTS_GET_INT_PAST_CLEAR,
  };
};

export const listsGetInterestPastFailure = payload => {
  return {
    type: LISTS_GET_INT_PAST_FAILURE,
    payload,
  };
};

export const listsGetInterestPastRequest = _ => {
  return {
    type: LISTS_GET_INT_PAST_REQUEST,
  };
};

export const listsGetInterestPastSuccess = payload => {
  return {
    type: LISTS_GET_INT_PAST_SUCCESS,
    payload,
  };
};

export const listsPutCuratorialAddClear = _ => {
  return {
    type: LISTS_PUT_CUR_ADD_CLEAR,
  };
};

export const listsPutCuratorialAddFailure = payload => {
  return {
    type: LISTS_PUT_CUR_ADD_FAILURE,
    payload,
  };
};

export const listsPutCuratorialAddRequest = _ => {
  return {
    type: LISTS_PUT_CUR_ADD_REQUEST,
  };
};

export const listsPutCuratorialAddSuccess = payload => {
  return {
    type: LISTS_PUT_CUR_ADD_SUCCESS,
    payload,
  };
};

export const listsPutCuratorialApproveClear = _ => {
  return {
    type: LISTS_PUT_CUR_APPROVE_CLEAR,
  };
};

export const listsPutCuratorialApproveFailure = payload => {
  return {
    type: LISTS_PUT_CUR_APPROVE_FAILURE,
    payload,
  };
};

export const listsPutCuratorialApproveRequest = _ => {
  return {
    type: LISTS_PUT_CUR_APPROVE_REQUEST,
  };
};

export const listsPutCuratorialApproveSuccess = payload => {
  return {
    type: LISTS_PUT_CUR_APPROVE_SUCCESS,
    payload,
  };
};

export const listsPutInterestAddClear = _ => {
  return {
    type: LISTS_PUT_INT_ADD_CLEAR,
  };
};

export const listsPutInterestAddFailure = payload => {
  return {
    type: LISTS_PUT_INT_ADD_FAILURE,
    payload,
  };
};

export const listsPutInterestAddRequest = _ => {
  return {
    type: LISTS_PUT_INT_ADD_REQUEST,
  };
};

export const listsPutInterestAddSuccess = payload => {
  return {
    type: LISTS_PUT_INT_ADD_SUCCESS,
    payload,
  };
};

export const listsPutCuratorialRemoveClear = _ => {
  return {
    type: LISTS_PUT_CUR_REMOVE_CLEAR,
  };
};

export const listsPutCuratorialRemoveFailure = payload => {
  return {
    type: LISTS_PUT_CUR_REMOVE_FAILURE,
    payload,
  };
};

export const listsPutCuratorialRemoveRequest = _ => {
  return {
    type: LISTS_PUT_CUR_REMOVE_REQUEST,
  };
};

export const listsPutCuratorialRemoveSuccess = payload => {
  return {
    type: LISTS_PUT_CUR_REMOVE_SUCCESS,
    payload,
  };
};

export const listsPutInterestRemoveClear = _ => {
  return {
    type: LISTS_PUT_INT_REMOVE_CLEAR,
  };
};

export const listsPutInterestRemoveFailure = payload => {
  return {
    type: LISTS_PUT_INT_REMOVE_FAILURE,
    payload,
  };
};

export const listsPutInterestRemoveRequest = _ => {
  return {
    type: LISTS_PUT_INT_REMOVE_REQUEST,
  };
};

export const listsPutInterestRemoveSuccess = payload => {
  return {
    type: LISTS_PUT_INT_REMOVE_SUCCESS,
    payload,
  };
};

export const listsSetCuratorialObjects = payload => {
  return {
    type: LISTS_SET_CUR_OBJECTS,
    payload,
  };
};

export const listsSetInterestObjects = payload => {
  return {
    type: LISTS_SET_INT_OBJECTS,
    payload,
  };
};

export const listsClearSelectedObject = _ => {
  return {
    type: LISTS_CLEAR_SELECTED_OBJECT,
  };
};

export const listsSetSelectedObject = payload => {
  return {
    type: LISTS_SET_SELECTED_OBJECT,
    payload,
  };
};

export const listsClearCuratorialAction = _ => {
  return {
    type: LISTS_CLEAR_CUR_ACTION,
  };
};

export const listsSetCuratorialAction = payload => {
  return {
    type: LISTS_SET_CUR_ACTION,
    payload,
  };
};
