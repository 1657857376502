import { sanitizeTel } from '../../lib';

export const validateProposalDraft = payload => {
  const errors = {
    formError: false,
    pointOfContactTelError: false,
    letterOfInterestError: false,
    conditionsClarificationError: false,
  };

  if (payload.pointOfContactTel) {
    if (sanitizeTel(payload.pointOfContactTel).length !== 10) {
      errors.formError = true;
      errors.pointOfContactTelError = true;
    }
  }

  // if (!payload.letterOfInterest) {
  //   errors.formError = true;
  //   errors.letterOfInterestError = true;
  // }

  if (
    typeof payload.conditionsClarification === 'string' &&
    !payload.conditionsClarification.length
  ) {
    errors.formError = true;
    errors.conditionsClarificationError = true;
  }

  return errors;
};
