import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MobileBlock from './MobileBlock';
import {
  authClearToken,
  authUpdateToken,
  clearUser,
  ctgSetClassifications,
  ctgSetClassificationsOpen,
  pageReset,
  pageAllowRedirect,
  pageDisallowRedirect,
  pageUpdateWindowWidth,
  pageSetMenuOpen,
  userUpdateUser,
} from '../../actions';
import LogoStacked from '../../images/logo-stacked.png';
import MenuStaffIcon from '../../images/nav-menu-staff-icon.png';
import MenuStaffIconActive from '../../images/nav-menu-staff-icon-active.png';
import MenuMuseumIcon from '../../images/nav-menu-museum.png';
import MenuMuseumIconActive from '../../images/nav-menu-museum-active.png';
import NavActiveActive from '../../images/nav-active-active.png';
import NavActiveInactive from '../../images/nav-active-inactive.png';
import NavBrowseActive from '../../images/nav-browse-active.png';
import NavBrowseInactive from '../../images/nav-browse-inactive.png';
import NavCatalogueActive from '../../images/nav-catalogue-active.png';
import NavCatalogueInactive from '../../images/nav-catalogue-inactive.png';
import NavCuratorialActive from '../../images/nav-curatorial-active.png';
import NavCuratorialInactive from '../../images/nav-curatorial-inactive.png';
import NavDonationsActive from '../../images/nav-donations-active.png';
import NavDonationsInactive from '../../images/nav-donations-inactive.png';
import NavHistoryActive from '../../images/nav-history-active.png';
import NavHistoryInactive from '../../images/nav-history-inactive.png';
import NavInterestActive from '../../images/nav-interest-active.png';
import NavInterestInactive from '../../images/nav-interest-inactive.png';
import NavProposalsActive from '../../images/nav-proposals-active.png';
import NavProposalsInactive from '../../images/nav-proposals-inactive.png';
import '../../styles/general.css';
import '../../styles/navbars.css';
import '../../styles/text.css';
import '../../styles/spacing.css';
import {
  selectIsMuseum,
  selectMuseumAccount,
} from 'features/museumAccounts/museumAccountsSlice';
import WrappedSuccessMessage from 'components/donorAccounts/DonorAccount/DonorAccountSuccessMessage';

class Page extends Component {
  static get propTypes() {
    return {
      bypass: PropTypes.bool,
      clearToken: PropTypes.func,
      clearUser: PropTypes.func,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      emailConfirm: PropTypes.bool,
      header: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      history: PropTypes.object,
      museumAccount: PropTypes.object,
      pageState: PropTypes.object,
      pageReset: PropTypes.func,
      pageAllowRedirect: PropTypes.func,
      pageDisallowRedirect: PropTypes.func,
      pageUpdateWindowWidth: PropTypes.func,
      pageSetMenuOpen: PropTypes.func,
      title: PropTypes.string,
      updateToken: PropTypes.func,
      updateUser: PropTypes.func,
      setClassifications: PropTypes.func,
      setClassificationsOpen: PropTypes.func,
      token: PropTypes.object,
      user: PropTypes.object,
    };
  }

  mounted = false;

  componentDidMount() {
    this.props.pageDisallowRedirect();
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);

    const tokenStr = localStorage.getItem('mex_token');
    const userStr = localStorage.getItem('mex_user');

    if (!this.props.bypass && tokenStr && userStr) {
      const token = JSON.parse(tokenStr);
      const user = JSON.parse(userStr);
      if (token && user) {
        this.props.updateToken({ token });
        this.props.updateUser({ user });
        if (this.props.emailConfirm) {
          this.props.pageAllowRedirect();
          this.mounted = true;
          return;
        }
        const expiry = new Date(token.expires).getTime();
        const now = new Date().getTime();
        if (expiry > now) {
          if (token.role === 'tmp_h') {
            if (user.tel) {
              this.props.history.push('/login/verify');
            } else if (!user.tel) {
              this.props.history.push('/user/tel/add');
            }
          } else if (token.role === 'tmp_d') {
            if (!user.applied) {
              if (user.accountType === 'donor') {
                this.props.history.push('/user/donor/apply');
              } else if (user.accountType === 'museum') {
                this.props.history.push('/user/institution/apply');
              }
            } else if (user.applied && !user.approved) {
              if (user.accountType === 'donor') {
                this.props.history.push('/user/donor/pending');
              } else if (user.accountType === 'museum') {
                this.props.history.push('/user/institution/pending');
              }
            } else if (user.applied && user.approved && !user.tel) {
              this.props.history.push('/user/tel/add');
            }
          } else if (token.role === 'donor') {
            if (
              this.props.match.params.accountId &&
              window.location.pathname.includes('/object/draft')
            ) {
              this.props.history.push(
                `/donor/folders/${this.props.match.params.accountId}/object/draft/1`,
              );
            }
            if (!window.location.pathname.startsWith('/donor')) {
              this.props.history.push('/donor');
            }
          } else if (token.role === 'museum') {
            this.props.history.push('/institution');
          }
        } else {
          localStorage.clear();
          this.props.clearToken();
          this.props.clearUser();
          this.props.history.push('/login');
        }
      } else {
        localStorage.clear();
        this.props.clearToken();
        this.props.clearUser();
        this.props.history.push('/login');
      }
    } else if (!this.props.bypass && !this.props.emailConfirm) {
      localStorage.clear();
      if (this.props.login) {
        this.props.history.push('/login');
      } else {
        this.props.history.push('/signup');
      }
    }

    this.props.pageAllowRedirect();
    this.mounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
    this.props.pageSetMenuOpen(false);
  }

  updateWindowWidth = _ => {
    const windowWidth = window.innerWidth;
    this.props.pageUpdateWindowWidth({ windowWidth });
  };

  makeMuseumNavBottom = pathname => {
    if (
      pathname.includes('/institution/catalogue') ||
      pathname.includes('institution/lists')
    ) {
      return (
        <React.Fragment>
          {pathname.includes('/institution/catalogue/1') ? (
            <Link
              to="/institution/catalogue/1"
              className="gen-link-no-decoration"
              onClick={_ => {
                this.props.setClassifications(null);
                this.props.setClassificationsOpen(true);
              }}
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavBrowseActive}
                  alt="browse-catalogue"
                  className="nav-museum-bottom-icon-browse-active"
                />
                <p className="nav-museum-bottom-selection-active">
                  Browse Catalogue
                </p>
              </div>
            </Link>
          ) : (
            <Link
              to="/institution/catalogue/1"
              className="gen-link-no-decoration"
              onClick={_ => {
                this.props.setClassifications(null);
                this.props.setClassificationsOpen(true);
              }}
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavBrowseInactive}
                  alt="browse-catalogue"
                  className="nav-museum-bottom-icon-browse-inactive"
                />
                <p className="nav-museum-bottom-selection-inactive">
                  Browse Catalogue
                </p>
              </div>
            </Link>
          )}
          {pathname === '/institution/lists/interest' ? (
            <Link
              to="/institution/lists/interest"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavInterestActive}
                  alt="objects-of-interest"
                  className="nav-museum-bottom-icon-interest-active"
                />
                <p className="nav-museum-bottom-selection-active">
                  Objects of Interest
                </p>
              </div>
            </Link>
          ) : (
            <Link
              to="/institution/lists/interest"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavInterestInactive}
                  alt="objects-of-interest"
                  className="nav-museum-bottom-icon-interest-inactive"
                />
                <p className="nav-museum-bottom-selection-inactive">
                  Objects of Interest
                </p>
              </div>
            </Link>
          )}
          <>
            {pathname === '/institution/lists/curatorial' ? (
              <Link
                to="/institution/lists/curatorial"
                className="gen-link-no-decoration"
              >
                <div className="nav-museum-bottom-selection d-flex">
                  <img
                    src={NavCuratorialActive}
                    alt="objects-of-interest"
                    className="nav-museum-bottom-icon-curatorial-active"
                  />
                  <p className="nav-museum-bottom-selection-active">
                    Curatorial Review
                  </p>
                </div>
              </Link>
            ) : (
              <Link
                to="/institution/lists/curatorial"
                className="gen-link-no-decoration"
              >
                <div className="nav-museum-bottom-selection d-flex">
                  <img
                    src={NavCuratorialInactive}
                    alt="objects-of-interest"
                    className="nav-museum-bottom-icon-curatorial-inactive"
                  />
                  <p className="nav-museum-bottom-selection-inactive">
                    Curatorial Review
                  </p>
                </div>
              </Link>
            )}
          </>
        </React.Fragment>
      );
    }

    if (
      pathname.includes('/institution/proposals') ||
      pathname.includes('/institution/object')
    ) {
      return (
        <React.Fragment>
          {pathname !== '/institution/proposals/history' ? (
            <Link
              to="/institution/proposals/active"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavActiveActive}
                  alt="active-proposals"
                  className="nav-museum-bottom-icon-active-active"
                />
                <p className="nav-museum-bottom-selection-active">Active</p>
              </div>
            </Link>
          ) : (
            <Link
              to="/institution/proposals/active"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavActiveInactive}
                  alt="active-proposals"
                  className="nav-museum-bottom-icon-active-inactive"
                />
                <p className="nav-museum-bottom-selection-inactive">Active</p>
              </div>
            </Link>
          )}
          {pathname === '/institution/proposals/history' ? (
            <Link
              to="/institution/proposals/history"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavHistoryActive}
                  alt="proposals-history"
                  className="nav-museum-bottom-icon-history-active"
                />
                <p className="nav-museum-bottom-selection-active">History</p>
              </div>
            </Link>
          ) : (
            <Link
              to="/institution/proposals/history"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavHistoryInactive}
                  alt="proposals-hsitory"
                  className="nav-museum-bottom-icon-history-inactive"
                />
                <p className="nav-museum-bottom-selection-inactive">History</p>
              </div>
            </Link>
          )}
        </React.Fragment>
      );
    }

    if (pathname.includes('/institution/donations')) {
      return (
        <React.Fragment>
          {pathname !== '/institution/donations/history' ? (
            <Link
              to="/institution/donations/active"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavActiveActive}
                  alt="active-proposals"
                  className="nav-museum-bottom-icon-active-active"
                />
                <p className="nav-museum-bottom-selection-active">Active</p>
              </div>
            </Link>
          ) : (
            <Link
              to="/institution/donations/active"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavActiveInactive}
                  alt="active-proposals"
                  className="nav-museum-bottom-icon-active-inactive"
                />
                <p className="nav-museum-bottom-selection-inactive">Active</p>
              </div>
            </Link>
          )}
          {pathname === '/institution/donations/history' ? (
            <Link
              to="/institution/donations/history"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavHistoryActive}
                  alt="proposals-history"
                  className="nav-museum-bottom-icon-history-active"
                />
                <p className="nav-museum-bottom-selection-active">History</p>
              </div>
            </Link>
          ) : (
            <Link
              to="/institution/donations/history"
              className="gen-link-no-decoration"
            >
              <div className="nav-museum-bottom-selection d-flex">
                <img
                  src={NavHistoryInactive}
                  alt="proposals-hsitory"
                  className="nav-museum-bottom-icon-history-inactive"
                />
                <p className="nav-museum-bottom-selection-inactive">History</p>
              </div>
            </Link>
          )}
        </React.Fragment>
      );
    }
  };

  makeHeader() {
    const { pathname } = this.props.location;

    if (this.props.header === 'basic') {
      return (
        <nav className="navbar nav-donor d-flex justify-content-between">
          {pathname !== '/logout' ? (
            <div className="nav-menu-icon-hidden"></div>
          ) : (
            <p className="mex-nav-element-hidden"></p>
          )}
          {pathname !== '/logout' &&
          this.props.token &&
          this.props.token.role === 'donor' ? (
            <Link to="/donor">
              <img
                src={LogoStacked}
                alt="logo-stacked"
                className="gen-logo-stacked"
              />
            </Link>
          ) : (
            <img
              src={LogoStacked}
              alt="logo-stacked"
              className="gen-logo-stacked tw-max-h-full tw-p-2"
            />
          )}
          {pathname !== '/logout' ? (
            <div
              className={`nav-menu-icon gen-cursor-hover-link ${
                this.props.pageState.menuOpen ? 'nav-menu-icon-active' : ''
              }`}
              onClick={e => {
                e.stopPropagation();
                this.props.pageSetMenuOpen(!this.props.pageState.menuOpen);
              }}
            >
              <p className="nav-menu-icon-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
            </div>
          ) : (
            <div style={{ width: '90px' }}></div>
          )}
          {this.props.pageState.menuOpen ? (
            <div className="nav-menu-donor" onClick={e => e.stopPropagation()}>
              <div className="nav-menu-initials mx-auto">
                <p className="nav-menu-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
              </div>
              <div className="spc-mb-5">
                <p className="txt-dark-16 text-center">{`${this.props.user.nameFirst} ${this.props.user.nameLast}`}</p>
              </div>
              <div className="spc-mb-30">
                <p className="nav-menu-txt text-center">
                  {this.props.user.email}
                </p>
              </div>
              <div className="gen-break-light nav-menu-break-16"></div>
              <div>
                <Link
                  to="/donor/settings/user"
                  className="link-no-decoration"
                  onClick={_ => this.props.pageSetMenuOpen(false)}
                >
                  <div className="d-flex nav-menu-item-row gen-cursor-hover-link">
                    <div className="nav-menu-item-row-initials-container nav-menu-item-row-initials-container-active">
                      <p className="nav-menu-item-row-initials-txt text-center">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
                    </div>
                    <p className="nav-menu-txt nav-menu-item-row-txt-hover">
                      Manage your Account
                    </p>
                  </div>
                </Link>
              </div>
              <div className="gen-break-light nav-menu-break-16"></div>
              <Link
                to="/logout"
                className="gen-link-no-decoration"
                onClick={_ => this.props.pageSetMenuOpen(false)}
              >
                <div className="nav-menu-btn-logout mx-auto">SIGN OUT</div>
              </Link>
              <div className="gen-break-light nav-menu-break-20"></div>
              <p className="nav-menu-txt-sm text-center">
                <a
                  href="https://www.museumexchange.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link-no-decoration nav-menu-txt-sm"
                >
                  Privacy Policy
                </a>
                &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
                <a
                  href="https://www.museumexchange.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="gen-link-no-decoration nav-menu-txt-sm"
                >
                  Terms of Use
                </a>
              </p>
            </div>
          ) : null}
        </nav>
      );
    } else if (this.props.header === 'museum') {
      return (
        <nav className="nav-museum">
          <div
            className={`nav-museum-top tw-mx-auto tw-flex tw-max-w-7xl tw-justify-between ${
              pathname.includes('/settings') ? 'nav-museum-top-settings' : ''
            }`}
          >
            <div className="d-flex">
              {pathname.includes('/institution/catalogue') ||
              pathname.includes('institution/lists') ? (
                <Link
                  to="/institution/catalogue/1"
                  className="gen-link-no-decoration"
                  onClick={_ => {
                    this.props.setClassifications(null);
                    this.props.setClassificationsOpen(true);
                  }}
                >
                  <div className="nav-museum-top-tab-active d-flex justify-content-center">
                    <img
                      src={NavCatalogueActive}
                      alt="nav-catalogue-active"
                      className="nav-museum-icon-catalogue-active"
                    />
                    <p>CATALOGUE</p>
                  </div>
                </Link>
              ) : (
                <Link
                  to="/institution/catalogue/1"
                  className="gen-link-no-decoration"
                  onClick={_ => {
                    this.props.setClassifications(null);
                    this.props.setClassificationsOpen(true);
                  }}
                >
                  <div className="nav-museum-top-tab-inactive d-flex justify-content-center">
                    <img
                      src={NavCatalogueInactive}
                      alt="nav-catalogue-inactive"
                      className="nav-museum-icon-catalogue-inactive"
                    />
                    <p>CATALOGUE</p>
                  </div>
                </Link>
              )}
              {pathname.includes('/institution/proposals') ||
              pathname.includes('/institution/object') ||
              pathname.includes('/match') ? (
                <Link
                  to="/institution/proposals/active"
                  className="gen-link-no-decoration"
                >
                  <div className="nav-museum-top-tab-active d-flex justify-content-center">
                    <img
                      src={NavProposalsActive}
                      alt="nav-proposals-active"
                      className="nav-museum-icon-proposals-active"
                    />
                    <p>PROPOSALS</p>
                  </div>
                </Link>
              ) : (
                <Link
                  to="/institution/proposals/active"
                  className="gen-link-no-decoration"
                >
                  <div className="nav-museum-top-tab-inactive d-flex justify-content-center">
                    <img
                      src={NavProposalsInactive}
                      alt="nav-proposals-incative"
                      className="nav-museum-icon-proposals-inactive"
                    />
                    <p>PROPOSALS</p>
                  </div>
                </Link>
              )}
              {pathname.includes('/institution/donations') &&
              !pathname.includes('/match') ? (
                <Link
                  to="/institution/donations/active"
                  className="gen-link-no-decoration"
                >
                  <div className="nav-museum-top-tab-active d-flex justify-content-center">
                    <img
                      src={NavDonationsActive}
                      alt="nav-donations-incative"
                      className="nav-museum-icon-donations-active"
                    />
                    <p>DONATIONS</p>
                  </div>
                </Link>
              ) : (
                <Link
                  to="/institution/donations/active"
                  className="gen-link-no-decoration"
                >
                  <div className="nav-museum-top-tab-inactive d-flex justify-content-center">
                    <img
                      src={NavDonationsInactive}
                      alt="nav-donations-incative"
                      className="nav-museum-icon-donations-inactive"
                    />
                    <p>DONATIONS</p>
                  </div>
                </Link>
              )}
              <div className="tw-w-32">
                <img
                  src={LogoStacked}
                  alt="logo-stacked"
                  className="tw-max-h-full tw-pt-7 tw-pb-1"
                />
              </div>
            </div>
            <div className="nav-museum-top-right justify-content-end tw-flex">
              <div
                className={`nav-menu-icon gen-cursor-hover-link ${
                  this.props.pageState.menuOpen ? 'nav-menu-icon-active' : ''
                }`}
                onClick={e => {
                  e.stopPropagation();
                  this.props.pageSetMenuOpen(!this.props.pageState.menuOpen);
                }}
              >
                <p className="nav-menu-icon-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
              </div>
              {this.props.pageState.menuOpen ? (
                <div className="nav-menu" onClick={e => e.stopPropagation()}>
                  <div className="nav-menu-top-container">
                    <p className="nav-menu-top-container-txt">{`This account is managed by ${this.props.museumAccount.name}`}</p>
                  </div>
                  <div className="nav-menu-initials mx-auto">
                    <p className="nav-menu-initials-txt">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
                  </div>
                  <div className="spc-mb-5">
                    <p className="txt-dark-16 text-center">{`${this.props.user.nameFirst} ${this.props.user.nameLast}`}</p>
                  </div>
                  <div className="spc-mb-30">
                    <p className="nav-menu-txt text-center">
                      {this.props.user.email}
                    </p>
                  </div>
                  <div className="gen-break-light nav-menu-break-16"></div>
                  <div>
                    <Link
                      to="/institution/settings/general"
                      className="link-no-decoration"
                      onClick={_ => this.props.pageSetMenuOpen(false)}
                    >
                      <div className="d-flex nav-menu-item-row gen-cursor-hover-link">
                        <img
                          src={MenuMuseumIcon}
                          alt="staff"
                          className="nav-menu-item-row-icon"
                        />
                        <img
                          src={MenuMuseumIconActive}
                          alt="staff"
                          className="nav-menu-item-row-icon-active"
                        />
                        <p className="nav-menu-txt nav-menu-item-row-txt-hover">
                          Institution Information
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/institution/settings/staff"
                      className="link-no-decoration"
                      onClick={_ => this.props.pageSetMenuOpen(false)}
                    >
                      <div className="d-flex nav-menu-item-row gen-cursor-hover-link">
                        <img
                          src={MenuStaffIcon}
                          alt="staff"
                          className="nav-menu-item-row-icon"
                        />
                        <img
                          src={MenuStaffIconActive}
                          alt="staff"
                          className="nav-menu-item-row-icon-active"
                        />
                        <p className="nav-menu-txt nav-menu-item-row-txt-hover">
                          Staff & Permissions
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/institution/settings/user"
                      className="link-no-decoration"
                      onClick={_ => this.props.pageSetMenuOpen(false)}
                    >
                      <div className="d-flex nav-menu-item-row gen-cursor-hover-link">
                        <div className="nav-menu-item-row-initials-container nav-menu-item-row-initials-container-active">
                          <p className="nav-menu-item-row-initials-txt text-center">{`${this.props.user.nameFirst[0].toUpperCase()}${this.props.user.nameLast[0].toUpperCase()}`}</p>
                        </div>
                        <p className="nav-menu-txt nav-menu-item-row-txt-hover">
                          Manage your Account
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="gen-break-light nav-menu-break-16"></div>
                  <Link
                    to="/logout"
                    className="gen-link-no-decoration"
                    onClick={_ => this.props.pageSetMenuOpen(false)}
                  >
                    <div className="nav-menu-btn-logout mx-auto">SIGN OUT</div>
                  </Link>
                  <div className="gen-break-light nav-menu-break-20"></div>
                  <p className="nav-menu-txt-sm text-center">
                    <a
                      href="https://www.museumexchange.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="gen-link-no-decoration nav-menu-txt-sm"
                    >
                      Privacy Policy
                    </a>
                    &nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
                    <a
                      href="https://www.museumexchange.com/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="gen-link-no-decoration nav-menu-txt-sm"
                    >
                      Terms of Use
                    </a>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
          <div className="nav-museum-bottom d-flex justify-content-center">
            <div className="nav-museum-bottom-left d-flex">
              {this.makeMuseumNavBottom(pathname)}
            </div>
            <div className="nav-museum-bottom-center"></div>
            <div className="nav-museum-bottom-right"></div>
          </div>
        </nav>
      );
    }
  }

  render() {
    document.title = this.props.title;

    const PageComponent = this.props.component;

    if (
      this.props.pageState.windowWidth &&
      this.props.pageState.windowWidth < 768
    ) {
      return <MobileBlock />;
    } else if (!this.props.pageState.loading && this.mounted) {
      return (
        <div onClick={_ => this.props.pageSetMenuOpen(false)}>
          {this.makeHeader()}
          <div style={{ marginTop: this.props.header ? 80 : 0 }}>
            <PageComponent {...this.props} />
            <WrappedSuccessMessage />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    museumAccount: selectMuseumAccount(state),
    isMuseum: selectIsMuseum(state),
    pageState: state.pageState,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearToken: _ => dispatch(authClearToken()),
    clearUser: _ => dispatch(clearUser()),
    pageReset: _ => dispatch(pageReset()),
    pageAllowRedirect: _ => dispatch(pageAllowRedirect()),
    pageDisallowRedirect: _ => dispatch(pageDisallowRedirect()),
    pageUpdateWindowWidth: payload => dispatch(pageUpdateWindowWidth(payload)),
    pageSetMenuOpen: payload => dispatch(pageSetMenuOpen(payload)),
    setClassifications: payload => dispatch(ctgSetClassifications(payload)),
    setClassificationsOpen: payload =>
      dispatch(ctgSetClassificationsOpen(payload)),
    updateToken: payload => dispatch(authUpdateToken(payload)),
    updateUser: payload => dispatch(userUpdateUser(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
