import { openBase64PDFNewWindow } from '@lib';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLazyGetDonationShippingReceiptQuery } from 'requests/api';
import {
  ActionLink,
  DonationRow,
  DonationSection,
  ShippingIcon,
  SimpleModal,
} from './DonationSections';

export function ShippingArrangementsSection({
  donation,
  setIsShippingUploadOpen = () => {},
}) {
  const userAccountType = useSelector(
    state => state?.userState?.user?.accountType,
  );
  const [getShippingReceipt] = useLazyGetDonationShippingReceiptQuery();

  if (!donation) return null;
  if (donation?.events?.completed && !donation?.shipping?.receipt) {
    return null;
  }
  if (!['donor', 'museum'].includes(userAccountType)) return null;

  const lookupMap = {
    donor: {
      learnMoreLink: `/donor/folders/${donation.donorAccountId}/donations/${donation.id}/details`,
    },
    museum: {
      learnMoreLink: `/institution/donations/${donation.id}/details`,
    },
  };

  const vars = lookupMap[userAccountType];

  return (
    <DonationSection
      title="Shipping Arrangements"
      learnMoreModalContent={
        <div>
          Any object under donation consideration must be shipped to the
          institution to be approved by the staff members. The cost of shipping
          and wall-to-wall insurance are covered by the institution, unless
          agreed otherwise. Please refer to the{' '}
          <Link to={vars.learnMoreLink}>donation details</Link> the donor has
          provided for additional shipping information.
        </div>
      }
      sectionHeader={
        donation.shipping.startDate && !donation.shipping.receipt ? (
          <ShippingSectionHeader donation={donation} />
        ) : null
      }
      completedAction={
        <ActionLink
          href={`#`}
          secondary
          onClick={async _ => {
            const shippingReceiptData = await getShippingReceipt({
              donationId: donation.id,
            }).unwrap();
            openBase64PDFNewWindow(shippingReceiptData);
          }}
          text="View Delivery Receipt"
        />
      }
      icon={<ShippingIcon />}
    >
      <DonationRow
        rowTitle="Schedule Pick Up"
        isCompleted={
          donation.events.completed ||
          donation.shipping.receipt ||
          (!!donation.shipping.startDate &&
            !donation.shipping.reschedulingNotes)
        }
        tenant="Institution"
        action={
          <ActionLink
            href={`/institution/donations/${donation.id}/schedule-shipping`}
            text="Schedule"
          />
        }
      />
      <DonationRow
        rowTitle="Approve Pick Up"
        isUpcoming={
          !donation.shipping.startDate || !!donation.shipping.reschedulingNotes
        }
        isCompleted={
          donation.events.completed ||
          donation.shipping.receipt ||
          donation.shipping.confirmedDonor
        }
        tenant="Donor"
        action={
          <ActionLink
            href={`/donor/folders/${donation.donorAccountId}/donation/${donation.id}/schedule-shipping-approve`}
            text="Review & Approve"
          />
        }
      />
      <DonationRow
        rowTitle="Upload Delivery Receipt"
        isUpcoming={!donation.shipping.confirmedDonor}
        isCompleted={donation.events.completed || !!donation.shipping.receipt}
        tenant="Institution"
        action={
          <ActionLink
            onClick={_ => setIsShippingUploadOpen(true)}
            href={`#`}
            text="Upload"
          />
        }
      />
    </DonationSection>
  );
}

function ShippingSectionHeader({ donation }) {
  const [isSchedulingNotesModalOpen, setIsSchedulingNotesModalOpen] =
    useState(false);

  if (!donation) return null;

  return (
    <div>
      <div className="tw-border tw-border-[#b4c3d2] tw-bg-gradient-to-r tw-from-[#f1f4f6] tw-to-[#dde2e7] tw-px-12 tw-py-7 ">
        <div className="tw-grid tw-gap-5">
          <div>Shipping status: In Progress</div>
          <div className="tw-flex tw-gap-20">
            <div>
              <div className="tw-text-xs tw-uppercase tw-text-gray-400">
                Scheduled Pick Up
              </div>
              {donation.shipping.startDate ? (
                <div>
                  <div className="tw-flex tw-gap-2">
                    <div className="tw-text-xs">
                      {donation.shipping.startDate}
                      {donation.shipping.endDate
                        ? ` - ${donation.shipping.endDate}`
                        : ''}
                    </div>
                    {donation.shipping.confirmedDonor ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="tw-h-4 tw-w-4"
                        viewBox="0 0 20 20"
                        fill="#65999b"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : null}
                  </div>
                  {donation.shipping.confirmedDonor &&
                  donation.shipping.schedulingNotes ? (
                    <button
                      className="tw-text-left tw-text-sm tw-text-blue-500 hover:tw-underline"
                      onClick={() => setIsSchedulingNotesModalOpen(true)}
                    >
                      Donor Comments
                    </button>
                  ) : null}
                </div>
              ) : (
                <div className="tw-text-xs">--</div>
              )}
            </div>
            <div className="tw-grid tw-gap-2">
              <div>
                <div className="tw-text-xs tw-uppercase tw-text-gray-400">
                  Art Shipping Company
                </div>
                <div className="tw-text-xs">
                  {donation.shipping.shippingCompany || '--'}
                </div>
              </div>
              <div>
                <div className="tw-text-xs tw-uppercase tw-text-gray-400">
                  Subcontractor
                </div>
                <div className="tw-text-xs">
                  {donation.shipping.shippingSubcontractor || '--'}
                </div>
              </div>
            </div>
            <div>
              <div className="tw-text-xs tw-uppercase tw-text-gray-400">
                Pick Up Contact
              </div>
              {donation.shipping.confirmedDonor ? (
                <div className="tw-text-xs">
                  <div>
                    {donation.donorInfo?.shippingContact?.nameFirst}{' '}
                    {donation.donorInfo?.shippingContact?.nameLast}
                  </div>
                  <div>{donation.donorInfo?.shippingContact?.tel}</div>
                  <div>{donation.donorInfo?.shippingContact?.email}</div>
                </div>
              ) : (
                <div className="tw-text-xs">--</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SimpleModal
        title="Scheduling Notes"
        description={donation.shipping?.schedulingNotes}
        isOpen={isSchedulingNotesModalOpen}
        closeModal={() => setIsSchedulingNotesModalOpen(false)}
      />
    </div>
  );
}
