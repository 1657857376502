import {
  LOGIN_RESET,
  LOGIN_UPDATE_EMAIL,
  LOGIN_UPDATE_FORM_ERRORS,
  LOGIN_UPDATE_PASSWORD,
  LOGIN_VERIFY_RESET,
  LOGIN_VERIFY_UPDATE_CODE,
  LOGIN_VERIFY_UPDATE_FORM_ERRORS,
} from '../actionTypes';

export const loginReset = _ => {
  return {
    type: LOGIN_RESET,
  };
};

export const loginUpdateEmail = payload => {
  return {
    type: LOGIN_UPDATE_EMAIL,
    payload,
  };
};

export const loginUpdatePassword = payload => {
  return {
    type: LOGIN_UPDATE_PASSWORD,
    payload,
  };
};

export const loginUpdateFormErrors = payload => {
  return {
    type: LOGIN_UPDATE_FORM_ERRORS,
    payload,
  };
};

export const loginVerifyReset = _ => {
  return {
    type: LOGIN_VERIFY_RESET,
  };
};

export const loginVerifyUpdateCode = payload => {
  return {
    type: LOGIN_VERIFY_UPDATE_CODE,
    payload,
  };
};

export const loginVerifyUpdateFormErrors = payload => {
  return {
    type: LOGIN_VERIFY_UPDATE_FORM_ERRORS,
    payload,
  };
};
