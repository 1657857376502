import { getDonationDonorNameText } from 'lib/getDonorNameText';
import React from 'react';

export function DonationTopSection({
  object,
  donation,
  museum,
  isMuseum,
  setIsAssignRegistrarModalOpen,
}) {
  return (
    <div className="d-header-bottom d-flex tw-gap-5">
      <ObjectSummary object={object} />
      <DonorSummary donation={donation} />
      <InstitutionSummary
        donation={donation}
        museum={museum}
        isMuseum={isMuseum}
        setIsAssignRegistrarModalOpen={setIsAssignRegistrarModalOpen}
      />
    </div>
  );
}

function ObjectSummary({ object }) {
  return (
    <TopCard className="tw-from-[#f1f4f6] tw-to-[#dde2e7]">
      <div className="tw-grid tw-gap-3">
        <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#8798ad]">
          Object Summary
        </div>
        <div className="tw-flex tw-gap-3">
          <img
            src={`https://res.cloudinary.com/ex-tech/image/fetch/h_243,w_183,c_fill,q_auto/https://${object.primaryImage.bucket}.s3.amazonaws.com/${object.primaryImage.imageId}`}
            alt="primary"
            className="tw-h-20 tw-w-16 tw-object-cover"
          />

          <div className="tw-max-w-[120px]">
            <p className="tw-text-xs tw-text-[#69707f]">{object.artistName}</p>
            <p className="tw-text-xs tw-text-[#8798ad]">
              {object.title}
              {', '}
              <em>{object.year}</em>
            </p>
          </div>
        </div>
      </div>
    </TopCard>
  );
}

function DonorSummary({ donation }) {
  return (
    <TopCard className="tw-from-[#eef5f5] tw-to-[#dae7e7]">
      <div className="tw-grid tw-gap-3">
        <div className="tw-grid tw-gap-1">
          <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#65999b]">
            Donor
          </div>
          <div className="tw-flex tw-gap-3">
            <div className="tw-max-w-[120px]">
              <p className="tw-text-xs tw-text-[#69707f]">
                {getDonationDonorNameText({ donation })}
                <br />
                {donation?.donorInfo?.donorAddress?.city},{' '}
                {donation?.donorInfo?.donorAddress?.state}
              </p>
            </div>
          </div>
        </div>
        {donation?.donorInfo?.representative ? (
          <div className="tw-grid tw-gap-1">
            <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#65999b]">
              Representative
            </div>
            <div className="tw-flex tw-gap-3">
              <div className="tw-max-w-[120px]">
                <p className="tw-text-xs tw-text-[#69707f]">
                  {donation?.donorInfo?.representative.nameFirst}{' '}
                  {donation?.donorInfo?.representative.nameLast}
                </p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </TopCard>
  );
}

function InstitutionSummary({
  donation,
  museum,
  isMuseum,
  setIsAssignRegistrarModalOpen,
}) {
  return (
    <TopCard className="tw-from-[#f1f1ee] tw-to-[#e3e0d6]">
      <div className="tw-grid tw-gap-3">
        <div className="tw-grid tw-gap-1">
          <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#a0916d]">
            Institution
          </div>
          <div className="tw-max-w-[120px]">
            <p className="tw-text-xs tw-text-[#69707f]">{museum.name}</p>
            <p className="tw-text-xs tw-text-[#8798ad]">
              {museum.locationCity}
              {', '}
              {museum.locationState}
            </p>
          </div>
        </div>
        <div className="tw-grid tw-gap-1">
          <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#a0916d]">
            Curator
          </div>
          <p className="tw-text-xs tw-text-[#69707f]">
            {donation.museumPOC.nameFirst} {donation.museumPOC.nameLast}
          </p>
        </div>
        <div className="tw-grid tw-gap-1">
          <MuseumRegistrar
            donation={donation}
            isMuseum={isMuseum}
            setIsAssignRegistrarModalOpen={setIsAssignRegistrarModalOpen}
          />
        </div>
      </div>
    </TopCard>
  );
}

function MuseumRegistrar({
  donation,
  isMuseum,
  setIsAssignRegistrarModalOpen,
}) {
  if (!isMuseum) {
    return (
      <>
        <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#a0916d]">
          Registrar
        </div>
        <p className="tw-text-xs tw-text-[#69707f]">
          {donation.museumRegistrar
            ? `${donation.museumRegistrar.nameFirst} ${donation.museumRegistrar.nameLast}`
            : '--'}
        </p>
      </>
    );
  }

  const pencil = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="d-assign-registrar-icon"
      width="15"
      height="14"
      viewBox="0 0 15 14"
    >
      <defs>
        <filter id="cvlqt5flxa" colorInterpolationFilters="auto">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.301968 0 0 0 0 0.522602 0 0 0 0 0.795148 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g
                      filter="url(#cvlqt5flxa)"
                      transform="translate(-1029.000000, -402.000000) translate(251.000000, 177.000000) translate(3.995745, 0.000000) translate(651.306383, 60.000000) translate(32.964894, 144.000000) translate(0.000000, 21.000000) translate(89.904255, 0.000000)"
                    >
                      <g fill="#000" fillRule="nonzero">
                        <path d="M5.074 11.496l5.415-5.421L7.917 3.5 2.502 8.92c-.075.076-.128.17-.154.272l-.6 3.058 3.055-.6c.102-.027.196-.08.27-.154zm7.163-7.171c.455-.456.455-1.194 0-1.65l-.924-.925c-.455-.455-1.193-.455-1.648 0l-.924.925 2.572 2.575.924-.925z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );

  const text = (
    <>
      <div className="tw-text-xs tw-font-bold tw-uppercase tw-text-[#a0916d]">
        Registrar
      </div>
      <p className="tw-text-xs tw-text-[#69707f]">
        {donation.museumRegistrar &&
          `${donation.museumRegistrar.nameFirst} ${donation.museumRegistrar.nameLast}`}
        <button
          onClick={e => {
            e.preventDefault();
            setIsAssignRegistrarModalOpen(true);
          }}
        >
          <span className="d-assign-registrar-text">{pencil} Edit</span>
        </button>
      </p>
    </>
  );
  const button = (
    <button
      className="d-assign-registrar-btn my-0"
      onClick={e => {
        e.preventDefault();
        setIsAssignRegistrarModalOpen(true);
      }}
    >
      <svg
        className="d-assign-registrar-icon"
        width="30"
        height="30"
        viewBox="0 0 42 42"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fillRule="nonzero"
          stroke="#cbc4b6"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeOpacity=".751"
        >
          <path
            d="M21 1C9.96 1 1 9.96 1 21s8.96 20 20 20 20-8.96 20-20S32.04 1 21 1z"
            strokeDasharray="4.25,4.75"
          />
          <path d="M21 7c3.32 0 6 2.68 6 6s-2.68 6-6 6-6-2.68-6-6 2.68-6 6-6zM9 28.96c.06-3.98 8-6.16 12-6.16 3.98 0 11.94 2.18 12 6.16" />
        </g>
      </svg>
      <span className="d-assign-registrar-text">Assign Registrar</span>
    </button>
  );

  if (donation.museumRegistrar) return text;

  return button;
}

function TopCard({ className, children }) {
  return (
    <div
      className={`tw-w-full tw-rounded tw-border tw-border-[#b4c3d2] tw-bg-gradient-to-r tw-px-7 tw-py-5 tw-shadow-sm ${className}`}
    >
      {children}
    </div>
  );
}
