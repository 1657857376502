import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authPutLogoutClear } from '../../actions';
import { authPutLogout } from '../../requests';

class Logout extends Component {
  static get propTypes() {
    return {
      history: PropTypes.object,
      putLogout: PropTypes.func,
      putLogoutClear: PropTypes.func,
      putLogoutRequest: PropTypes.object,
      token: PropTypes.object,
    };
  }

  componentWillUnmount() {
    this.props.putLogoutClear();
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    if (!this.props.token) {
      return <Redirect push to="/login" />;
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <p className="header text-center mex-mt-47">Log Out</p>
        <div className="sub-header mx-auto mex-mb-30"></div>
        <div>
          <div className="misc-content mx-auto">
            <div className="misc-content-inner mx-auto">
              <div className="mex-mb-26">
                <p className="text text-center mex-mb-12">
                  Are you sure want to log out?
                </p>
                <div className="d-flex justify-content-between">
                  <div className="misc-content-split-element">
                    <button
                      className="btn btn-primary mex-btn-primary"
                      style={{ width: '100%' }}
                      onClick={_ => this.props.putLogout(this.props.token)}
                    >
                      Yes
                    </button>
                  </div>
                  <div className="misc-content-split-element">
                    <button
                      className="btn btn-primary mex-btn-secondary"
                      style={{ width: '100%' }}
                      onClick={_ => this.props.history.goBack()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {this.props.putLogoutRequest.error && (
                  <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                    Please try again.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    putLogoutRequest: state.authState.putLogoutRequest,
    token: state.authState.token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    putLogout: payload => dispatch(authPutLogout(payload)),
    putLogoutClear: _ => dispatch(authPutLogoutClear()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
