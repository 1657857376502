import {
  AUTH_CLEAR_TEL,
  AUTH_CLEAR_TOKEN,
  AUTH_POST_LOGIN_CLEAR,
  AUTH_POST_LOGIN_FAILURE,
  AUTH_POST_LOGIN_REQUEST,
  AUTH_POST_LOGIN_SUCCESS,
  AUTH_POST_RESET_CLEAR,
  AUTH_POST_RESET_FAILURE,
  AUTH_POST_RESET_REQUEST,
  AUTH_POST_RESET_SUCCESS,
  AUTH_POST_VERIFY_CLEAR,
  AUTH_POST_VERIFY_FAILURE,
  AUTH_POST_VERIFY_REQUEST,
  AUTH_POST_VERIFY_SUCCESS,
  AUTH_POST_VERIFY_RESEND_CLEAR,
  AUTH_POST_VERIFY_RESEND_FAILURE,
  AUTH_POST_VERIFY_RESEND_REQUEST,
  AUTH_POST_VERIFY_RESEND_SUCCESS,
  AUTH_PUT_LOGOUT_CLEAR,
  AUTH_PUT_LOGOUT_FAILURE,
  AUTH_PUT_LOGOUT_REQUEST,
  AUTH_PUT_LOGOUT_SUCCESS,
  AUTH_PUT_RESET_CLEAR,
  AUTH_PUT_RESET_FAILURE,
  AUTH_PUT_RESET_REQUEST,
  AUTH_PUT_RESET_SUCCESS,
  AUTH_PUT_VERIFY_CLEAR,
  AUTH_PUT_VERIFY_FAILURE,
  AUTH_PUT_VERIFY_REQUEST,
  AUTH_PUT_VERIFY_SUCCESS,
  AUTH_UPDATE_TOKEN,
  AUTH_CA_RESET_FORM,
  AUTH_CA_UPDATE_ERROR,
  AUTH_CA_UPDATE_PASSWORD,
  AUTH_CA_PUT_CHANGE_CLEAR,
  AUTH_CA_PUT_CHANGE_FAILURE,
  AUTH_CA_PUT_CHANGE_REQUEST,
  AUTH_CA_PUT_CHANGE_SUCCESS,
  AUTH_CC_RESET_FORM,
  AUTH_CC_UPDATE_ERRORS,
  AUTH_CC_UPDATE_PW_ONE,
  AUTH_CC_UPDATE_PW_TWO,
  AUTH_CC_PUT_CHANGE_CLEAR,
  AUTH_CC_PUT_CHANGE_FAILURE,
  AUTH_CC_PUT_CHANGE_REQUEST,
  AUTH_CC_PUT_CHANGE_SUCCESS,
} from '../actionTypes';

export const authClearTel = _ => {
  return {
    type: AUTH_CLEAR_TEL,
  };
};

export const authClearToken = _ => {
  return {
    type: AUTH_CLEAR_TOKEN,
  };
};

export const authPostLoginClear = _ => {
  return {
    type: AUTH_POST_LOGIN_CLEAR,
  };
};

export const authPostLoginFailure = payload => {
  return {
    type: AUTH_POST_LOGIN_FAILURE,
    payload,
  };
};

export const authPostLoginRequest = _ => {
  return {
    type: AUTH_POST_LOGIN_REQUEST,
  };
};

export const authPostLoginSuccess = payload => {
  return {
    type: AUTH_POST_LOGIN_SUCCESS,
    payload,
  };
};

export const authPostResetClear = _ => {
  return {
    type: AUTH_POST_RESET_CLEAR,
  };
};

export const authPostResetFailure = payload => {
  return {
    type: AUTH_POST_RESET_FAILURE,
    payload,
  };
};

export const authPostResetRequest = _ => {
  return {
    type: AUTH_POST_RESET_REQUEST,
  };
};

export const authPostResetSuccess = payload => {
  return {
    type: AUTH_POST_RESET_SUCCESS,
    payload,
  };
};

export const authPostVerifyClear = _ => {
  return {
    type: AUTH_POST_VERIFY_CLEAR,
  };
};

export const authPostVerifyFailure = payload => {
  return {
    type: AUTH_POST_VERIFY_FAILURE,
    payload,
  };
};

export const authPostVerifyRequest = _ => {
  return {
    type: AUTH_POST_VERIFY_REQUEST,
  };
};

export const authPostVerifySuccess = payload => {
  return {
    type: AUTH_POST_VERIFY_SUCCESS,
    payload,
  };
};

export const authPostVerifyResendClear = _ => {
  return {
    type: AUTH_POST_VERIFY_RESEND_CLEAR,
  };
};

export const authPostVerifyResendFailure = payload => {
  return {
    type: AUTH_POST_VERIFY_RESEND_FAILURE,
    payload,
  };
};

export const authPostVerifyResendRequest = _ => {
  return {
    type: AUTH_POST_VERIFY_RESEND_REQUEST,
  };
};

export const authPostVerifyResendSuccess = payload => {
  return {
    type: AUTH_POST_VERIFY_RESEND_SUCCESS,
    payload,
  };
};

export const authPutLogoutClear = _ => {
  return {
    type: AUTH_PUT_LOGOUT_CLEAR,
  };
};

export const authPutLogoutFailure = payload => {
  return {
    type: AUTH_PUT_LOGOUT_FAILURE,
    payload,
  };
};

export const authPutLogoutRequest = _ => {
  return {
    type: AUTH_PUT_LOGOUT_REQUEST,
  };
};

export const authPutLogoutSuccess = payload => {
  return {
    type: AUTH_PUT_LOGOUT_SUCCESS,
    payload,
  };
};

export const authPutResetClear = _ => {
  return {
    type: AUTH_PUT_RESET_CLEAR,
  };
};

export const authPutResetFailure = payload => {
  return {
    type: AUTH_PUT_RESET_FAILURE,
    payload,
  };
};

export const authPutResetRequest = _ => {
  return {
    type: AUTH_PUT_RESET_REQUEST,
  };
};

export const authPutResetSuccess = payload => {
  return {
    type: AUTH_PUT_RESET_SUCCESS,
    payload,
  };
};

export const authPutVerifyClear = _ => {
  return {
    type: AUTH_PUT_VERIFY_CLEAR,
  };
};

export const authPutVerifyFailure = payload => {
  return {
    type: AUTH_PUT_VERIFY_FAILURE,
    payload,
  };
};

export const authPutVerifyRequest = _ => {
  return {
    type: AUTH_PUT_VERIFY_REQUEST,
  };
};

export const authPutVerifySuccess = payload => {
  return {
    type: AUTH_PUT_VERIFY_SUCCESS,
    payload,
  };
};

export const authUpdateToken = payload => {
  return {
    type: AUTH_UPDATE_TOKEN,
    payload,
  };
};

export const authCAResetForm = _ => {
  return {
    type: AUTH_CA_RESET_FORM,
  };
};

export const authCAUpdateError = payload => {
  return {
    type: AUTH_CA_UPDATE_ERROR,
    payload,
  };
};

export const authCAUpdatePassword = payload => {
  return {
    type: AUTH_CA_UPDATE_PASSWORD,
    payload,
  };
};

export const authCAPutChangeClear = _ => {
  return {
    type: AUTH_CA_PUT_CHANGE_CLEAR,
  };
};

export const authCAPutChangeFailure = payload => {
  return {
    type: AUTH_CA_PUT_CHANGE_FAILURE,
    payload,
  };
};

export const authCAPutChangeRequest = _ => {
  return {
    type: AUTH_CA_PUT_CHANGE_REQUEST,
  };
};

export const authCAPutChangeSuccess = payload => {
  return {
    type: AUTH_CA_PUT_CHANGE_SUCCESS,
    payload,
  };
};

export const authCCResetForm = _ => {
  return {
    type: AUTH_CC_RESET_FORM,
  };
};

export const authCCUpdateErrors = payload => {
  return {
    type: AUTH_CC_UPDATE_ERRORS,
    payload,
  };
};

export const authCCUpdatePasswordOne = payload => {
  return {
    type: AUTH_CC_UPDATE_PW_ONE,
    payload,
  };
};

export const authCCUpdatePasswordTwo = payload => {
  return {
    type: AUTH_CC_UPDATE_PW_TWO,
    payload,
  };
};

export const authCCPutChangeClear = _ => {
  return {
    type: AUTH_CC_PUT_CHANGE_CLEAR,
  };
};

export const authCCPutChangeFailure = payload => {
  return {
    type: AUTH_CC_PUT_CHANGE_FAILURE,
    payload,
  };
};

export const authCCPutChangeRequest = _ => {
  return {
    type: AUTH_CC_PUT_CHANGE_REQUEST,
  };
};

export const authCCPutChangeSuccess = payload => {
  return {
    type: AUTH_CC_PUT_CHANGE_SUCCESS,
    payload,
  };
};
