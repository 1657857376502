import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  MUSEUM_POSITIONS,
  CURRENT_PRIVACY_POLICY_HASH,
  CURRENT_TOS_HASH,
} from '../../config';
import {
  museumApplicationReset,
  museumApplicationUpdateConsent,
  museumApplicationUpdateErrors,
  museumApplicationUpdateMuseum,
  museumApplicationUpdateRole,
  postMuseumApplicationClear,
} from '../../actions';
import { postMuseumApplication } from '../../requests';
import { stripProtocol } from '../../lib';
import '../../styles/general.css';
import { useGetMuseumAccountsSimpleQuery } from 'requests/api';

export default function MuseumApplication({ header }) {
  const {
    data: accounts,
    isError: isErrorMuseumAccounts,
    isLoading: isLoadingMuseumAccounts,
  } = useGetMuseumAccountsSimpleQuery();

  const formData = useSelector(state => state.museumApplicationState.formData);
  const formErrors = useSelector(
    state => state.museumApplicationState.formErrors,
  );
  const postApplicationRequest = useSelector(
    state => state.userState.postMuseumApplicationRequest,
  );
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);

  const dispatch = useDispatch();

  const postApplication = (token, data) =>
    dispatch(postMuseumApplication(token, data));
  const updateConsent = payload =>
    dispatch(museumApplicationUpdateConsent(payload));
  const updateErrors = payload =>
    dispatch(museumApplicationUpdateErrors(payload));
  const updateMuseum = payload =>
    dispatch(museumApplicationUpdateMuseum(payload));
  const updateRole = payload => dispatch(museumApplicationUpdateRole(payload));

  useEffect(() => {
    dispatch(museumApplicationReset());
    dispatch(postMuseumApplicationClear());
  }, []);

  const handleUpdateMuseum = museumId => {
    const museum = accounts.reduce((a, c) => (c.id === museumId ? c : a));
    updateMuseum({ museum });
  };

  const handleUpdateRole = role => {
    updateRole({ role });
  };

  const handleUpdateConsent = _ => {
    updateConsent({ consent: !formData.consent });
  };

  const validateFormAndSubmit = _ => {
    if (postApplicationRequest.loading) {
      return;
    }

    const errors = {
      formError: false,
      consentError: false,
      museumError: false,
      roleError: false,
    };

    if (formData.museum === null) {
      errors.formError = true;
      errors.museumError = true;
    }

    if (formData.role === '') {
      errors.formError = true;
      errors.roleError = true;
    }

    if (!formData.consent) {
      errors.formError = true;
      errors.consentError = true;
    }

    updateErrors({ formErrors: errors });

    if (errors.formError) {
      return;
    }

    postApplication(token, {
      privacyPolicyHash: CURRENT_PRIVACY_POLICY_HASH,
      tosHash: CURRENT_TOS_HASH,
      museumProfile: {
        museumId: formData.museum.id,
        position: formData.role,
      },
    });
  };

  if (isErrorMuseumAccounts) {
    return <p>Error loading museums...</p>;
  }

  if (isLoadingMuseumAccounts || !accounts) {
    return <p>Loading...</p>;
  }

  if (postApplicationRequest.data) {
    return <Redirect push to="/user/institution/pending" />;
  }

  return (
    <div className={`${header ? 'background-nav' : 'background'}`}>
      <p className="header text-center mex-mt-47">Insitution Application</p>
      <div className="sub-header mx-auto mex-mb-30">
        <p className="text text-center">
          Please complete this form to submit your application for Museum
          Exchange
        </p>
      </div>
      <div className="museum-application-content mx-auto d-flex justify-content-center">
        <div className="museum-application-content-inner">
          <div className="mex-mb-30">
            <p
              className={`label-bold mex-mb-12 ${
                formErrors.museumError ? 'label-error' : ''
              }`}
            >
              Which institution will you be representing?
            </p>
            <select
              className={`btn dropdown-toggle mex-dropdown text-truncate ${
                formData.museum
                  ? 'mex-dropdown-selection'
                  : 'mex-dropdown-no-selection'
              } ${formErrors.museumError ? 'mex-dropdown-error' : ''}`}
              style={{ width: '100%' }}
              onChange={event => handleUpdateMuseum(event.target.value)}
              value={formData.museum ? formData.museum.id : ''}
            >
              <option disabled value="">
                Institution
              </option>
              {accounts.map(v => {
                if (
                  !v.hidden ||
                  user.email.endsWith('@museumex.com') ||
                  user.email.endsWith('@museumexchange.com')
                ) {
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  );
                }
                return null;
              })}
            </select>
          </div>
          {formData.museum &&
          ((formData.museum.locationCity && formData.museum.locationState) ||
            formData.museum.websiteURL) ? (
            <div className="mex-mb-26">
              {formData.museum.locationCity && formData.museum.locationState ? (
                <div className="mex-mb-12">
                  <p className="museum-application-label-special">LOCATION</p>
                  <p className="museum-application-value-special">{`${formData.museum.locationCity}, ${formData.museum.locationState}`}</p>
                </div>
              ) : null}
              {formData.museum.websiteURL ? (
                <div className="mex-mb-12">
                  <p className="museum-application-label-special">WEBSITE</p>
                  <p className="museum-application-value-special">
                    {stripProtocol(formData.museum.websiteURL)}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="mex-mb-30">
            <p
              className={`label-bold mex-mb-12 ${
                formErrors.roleError ? 'label-error' : ''
              }`}
            >
              Role
            </p>
            <select
              className={`btn dropdown-toggle mex-dropdown ${
                formData.role
                  ? 'mex-dropdown-selection'
                  : 'mex-dropdown-no-selection'
              } ${formErrors.roleError ? 'mex-dropdown-error' : ''}`}
              style={{ width: '100%' }}
              onChange={event => handleUpdateRole(event.target.value)}
              value={formData.role}
            >
              <option disabled value="">
                Role
              </option>
              {MUSEUM_POSITIONS.map((v, i) => (
                <option key={`museumPosition_${i}`} value={v}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          <div className="mex-mb-30 d-flex">
            <input
              type="checkbox"
              checked={formData.consent}
              onChange={handleUpdateConsent}
              className="donor-museum-application-checkbox"
            />
            <a
              href="# "
              className="link-no-decoration"
              onClick={e => e.preventDefault()}
            >
              <p
                className={`text ${
                  formErrors.consentError ? 'label-error' : ''
                }`}
                onClick={handleUpdateConsent}
              >
                I agree to Museum Exchange's{' '}
                <a
                  href="https://www.museumexchange.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                  className="gen-link"
                >
                  Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href="https://www.museumexchange.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.stopPropagation()}
                  className="gen-link"
                >
                  Privacy Policy
                </a>
              </p>
            </a>
          </div>
          <button
            className="btn btn-primary mex-btn-primary mex-btn-primary-fill"
            style={{ width: '100%' }}
            onClick={validateFormAndSubmit}
          >
            Submit Application
          </button>
          {postApplicationRequest.error && (
            <div className="alert alert-danger mex-alert-danger mex-mt-9">
              Please try again.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
