import { formatPhoneNumber } from '../../lib';

export const parseProposalIntoFormData = proposal => {
  const formData = {
    id: proposal.id,
    museumId: proposal.museumId,
    objectId: proposal.objectId,
    cycleId: proposal.cycleId,
    letterOfInterest: proposal.letterOfInterest
      ? proposal.letterOfInterest
      : '',
    images: proposal.images,
    conditionsClarification: proposal.conditionsClarification,
    museumRequiresDonorPayShipping: proposal.museumRequiresDonorPayShipping,
  };

  if (proposal.pointOfContact) {
    formData.pointOfContactId = proposal.pointOfContact.userId;
    formData.pointOfContactTel = proposal.pointOfContact.tel
      ? formatPhoneNumber(proposal.pointOfContact.tel)
      : '';
  }

  return formData;
};
