import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ProposalReview from '../proposals/ProposalReview.js';
import {
  propClearProposal,
  propPutApproveClear,
  propSetApproveModal,
  propSetSelectedObject,
  propSetSelectedProposalId,
} from '../../actions';
import { putProposalApprove } from '../../requests';
import { addDays, convertToMMDDYY, validateUserMuseumAdmin } from '../../lib';
import ApprovedCheck from '../../images/approved-check.png';
import PencilIcon from '../../images/pencil-icon.png';
import WhiteAlert from '../../images/alert-white.png';
import '../../styles/errors.css';
import '../../styles/general.css';
import '../../styles/proposal-review.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import { getLastDateInCatalog } from './ObjectTimeBadge.js';
import { getDaysLeftInCatalog } from '../../lib';
import { isDate } from 'date-fns';
import { selectCurrentCycle } from 'selectors/cyclesSelectors.js';
import {
  useGetMuseumAccountUserQuery,
  useGetObjMuseumQuery,
  useGetProposalQuery,
} from 'requests/api.js';
import { useMuseumGetObjectSummariesInCurrentCycle } from 'components/_shared/useMuseumGetObjectSummariesInCurrentCycle.js';

export default function MuseumProposalReview() {
  const [mounted, setMounted] = useState(false);
  const [editNextDestination, setEditNextDestination] = useState(true);

  const confirmApproveModalOpen = useSelector(
    state => state.proposalsState.confirmApproveModalOpen,
  );
  const currentCycle = useSelector(state => selectCurrentCycle(state));

  const isMuseum = useSelector(state => selectIsMuseum(state));
  const {
    activeObjectSummaries = [],
    isLoading: isLoadingActiveObjectSumaries,
  } = useMuseumGetObjectSummariesInCurrentCycle();

  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const putProposalApproveRequest = useSelector(
    state => state.proposalsState.putProposalApproveRequest,
  );

  const selectedProposalId = useSelector(
    state => state.proposalsState.selectedProposalId,
  );
  const token = useSelector(state => state.authState.token);
  const user = useSelector(state => state.userState.user);
  const { data: proposal, isLoading: isLoadingProposal } =
    useGetProposalQuery(selectedProposalId);
  const { data: obj, isLoading: isLoadingObj } = useGetObjMuseumQuery(
    { objectId: proposal?.objectId },
    { skip: !proposal },
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(propClearProposal());
      dispatch(propPutApproveClear());
      dispatch(propSetApproveModal(false));

      if (!editNextDestination) {
        dispatch(propSetSelectedObject(null));
        dispatch(propSetSelectedProposalId(null));
      }
    };
  }, []);

  const makeHeaderBadge = userIsAdmin => {
    if (proposal.status === 'approved') {
      return (
        <div className="pro-rev-header-badge" style={{ width: '187px' }}>
          <p className="pro-rev-header-badge-txt text-center">
            SUBMITTED FOR DONOR REVIEW
          </p>
        </div>
      );
    }

    if (
      !userIsAdmin &&
      proposal.status !== 'approved' &&
      proposal.status !== 'rejected' &&
      proposal.status !== 'accepted'
    ) {
      return (
        <div className="pro-rev-header-badge" style={{ width: '202px' }}>
          <p className="pro-rev-header-badge-txt text-center">
            AWAITING ADMINISTRATOR REVIEW
          </p>
        </div>
      );
    }
  };

  const makeHeaderNote = userIsAdmin => {
    if (userIsAdmin && !proposal.events.approved) {
      let dateString = '';

      if (currentCycle) {
        dateString = convertToMMDDYY(addDays(currentCycle.end, 1));
      }

      if (!isMuseum) {
        // Need to account for the non-museum ongoing cycle, which means different end date.

        const objSummary = activeObjectSummaries.find(
          ({ id }) => id === proposal.objectId,
        );

        const daysLeft = getDaysLeftInCatalog({
          dateAddedToCatalog: objSummary.events.approved.timestamp,
          firstProposalDate: objSummary.firstProposalDate,
        });

        const lastDate = getLastDateInCatalog(daysLeft);

        if (isDate(lastDate)) {
          dateString = convertToMMDDYY(lastDate);
        }
      }

      {
        return (
          <div className="pro-rev-header-note">
            <p className="pro-rev-header-note-txt">
              {`Submit your proposal by ${dateString} for the donor to consider your
            ${
              isMuseum ? 'museum' : 'institution'
            } as the recipient of this object`}
            </p>
          </div>
        );
      }
    }
  };

  if (
    isLoadingObj ||
    isLoadingMuseumAccount ||
    isLoadingActiveObjectSumaries ||
    isLoadingProposal
  ) {
    return <div className="gen-background-nav-museum"></div>;
  }

  if (selectedProposalId && obj && proposal && museumAccount) {
    const userIsAdmin = validateUserMuseumAdmin(user.id, museumAccount);

    return (
      <div className="gen-background-nav-museum">
        <div
          className={`pro-rev-content mx-auto ${
            userIsAdmin && !proposal.events.approved
              ? 'pro-rev-content-footer'
              : ''
          }`}
        >
          <div className="pro-rev-header">
            <p className="txt-header">Donation Proposal</p>
            {makeHeaderBadge(userIsAdmin)}
            {makeHeaderNote(userIsAdmin)}
          </div>
          <ProposalReview
            museumAccount={museumAccount}
            obj={obj}
            proposal={proposal}
          />
        </div>
        {userIsAdmin && !proposal.events.approved ? (
          <div className="pro-rev-footer d-flex justify-content-center">
            <div className="pro-rev-footer-inner d-flex justify-content-end">
              {putProposalApproveRequest.error ? (
                <div className="prop-rev-footer-err-submit">
                  <div className="alert alert-danger err-alert">
                    An error occured. Please try again.
                  </div>
                </div>
              ) : null}
              <Link
                to={`/institution/proposals/edit?objectId=${obj.id}&proposalId=${proposal.id}`}
                className="link-no-decoration"
                onClick={_ => setEditNextDestination(true)}
              >
                <div className="pro-rev-btn-edit d-flex justify-content-center">
                  <img
                    src={PencilIcon}
                    alt="pencil"
                    className="pro-rev-btn-edit-pencil"
                  />
                  <p>EDIT PROPOSAL</p>
                </div>
              </Link>
            </div>
            {!museumAccount.subscribed ? (
              <div className="pro-rev-footer-inner d-flex">
                <div className="pro-rev-paywall-container">
                  <img
                    src={WhiteAlert}
                    alt="alert-white"
                    className="pro-rev-alert-icon"
                  />
                  You need to begin your Museum Exchange subscription to send
                  proposals to donors
                  <br />
                  <br />
                  Need help?{' '}
                  <a
                    href="mailto:museum_liaison@museumexchange.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="gen-link-no-decoration pro-rev-paywall-txt-bold"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            ) : (
              <div className="pro-rev-footer-inner d-flex">
                <a
                  href="# "
                  className="link-no-decoration"
                  onClick={e => {
                    e.preventDefault();
                    dispatch(propSetApproveModal(true));
                  }}
                >
                  <div className="pro-rev-btn-approve">
                    <p style={{ paddingTop: '7px' }}>SEND PROPOSAL TO DONOR</p>
                  </div>
                </a>
              </div>
            )}
          </div>
        ) : null}
        {confirmApproveModalOpen ? (
          <div className="mod">
            <div className="mod-content-container mx-auto">
              {putProposalApproveRequest.data ? (
                <div className="list-int-modal-content spc-mt-30">
                  {' '}
                  <div className="d-flex justify-content-center mx-auto">
                    <img
                      src={ApprovedCheck}
                      alt="approved-check"
                      className="mod-approved-check"
                    />
                    <p className="mod-txt-header">Proposal submitted</p>
                  </div>
                  <div className="mod-content-object-info-container mx-auto">
                    <p className="mod-txt-18">
                      {obj.artistName}, <i>{obj.title}</i>
                    </p>
                  </div>
                  <div className="spc-mb-75">
                    <p className="mod-txt-header">for donor review</p>
                  </div>
                  <div className="mod-btn-container mx-auto d-flex justify-content-center">
                    <Link to="/institution/proposals/active">
                      <button className="btn mod-btn-dark">OKAY</button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="list-int-modal-content spc-mt-30">
                  <p className="mod-txt-header">
                    Are you sure you would like
                    <br />
                    to submit this proposal for
                  </p>
                  <div className="mod-content-object-info-container mx-auto">
                    <p className="mod-txt-18">
                      {obj.artistName}, <i>{obj.title}</i>
                    </p>
                  </div>
                  <p className="mod-txt-header">for donor review?</p>
                  <div className="mod-btn-container mx-auto d-flex justify-content-between">
                    <button
                      className="btn mod-btn-light"
                      onClick={_ => dispatch(propSetApproveModal(false))}
                    >
                      CANCEL
                    </button>
                    <button
                      className="btn mod-btn-dark"
                      onClick={_ =>
                        dispatch(
                          putProposalApprove(token, proposal.id, isMuseum),
                        )
                      }
                    >
                      CONFIRM & SUBMIT
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (!obj && mounted) {
    return (
      <div className="gen-background-nav-museum">
        <div className="pro-rev-content mx-auto">
          <div className="prop-rev-empty-container">
            <p className="prop-rev-empty-txt">
              Please select a proposal to review from the previous page
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <div className="gen-background-nav-museum"></div>;
}
