import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CLASSIFICATIONS, COLLECTING_AREAS } from '../../config';
import {
  objDraftPostClear,
  objDraftPutClear,
  objDraftUpdateAccount,
  objDraftUpdateCollectingArea,
  objDraftUpdateArtistName,
  objDraftUpdateTitle,
  objDraftUpdateYear,
  objDraftUpdateClassification,
  objDraftUpdateMedium,
  objDraftUpdateDimensions,
  objDraftUpdateWeight,
  objDraftUpdateWeightSystem,
  objDraftUpdateEdition,
  objDraftUpdateEditionDescription,
  objDraftUpdateInscriptions,
  objDraftUpdateCondition,
  objDraftUpdateDraftErrors,
  objDraftUpdateSubmitErrors,
} from '../../actions';
import { postObjectDraft, putObjectDraft } from '../../requests';
import {
  dimensionsMapping,
  makeObjectDraftPayload,
  validateFormDraft,
  validateFormSubmit,
} from '../../glue';
import GreyCloseIcon from '../../images/grey-close-icon.png';
import { useParams } from 'react-router-dom';
import { useCurrentDonorAccountUrl } from './useCurrentDonorAccountUrl';

class ObjectDraftOne extends Component {
  static get propTypes() {
    return {
      formData: PropTypes.object,
      formDraftErrors: PropTypes.object,
      formSubmitAttempted: PropTypes.bool,
      formSubmitErrors: PropTypes.object,
      postObjectDraft: PropTypes.func,
      postObjectDraftClear: PropTypes.func,
      postObjectDraftRequest: PropTypes.object,
      putObjectDraft: PropTypes.func,
      putObjectDraftClear: PropTypes.func,
      putObjectDraftRequest: PropTypes.object,
      token: PropTypes.object,
      updateAccount: PropTypes.func,
      updateCollectingArea: PropTypes.func,
      updateArtistName: PropTypes.func,
      updateTitle: PropTypes.func,
      updateYear: PropTypes.func,
      updateClassification: PropTypes.func,
      updateMedium: PropTypes.func,
      updateDimensions: PropTypes.func,
      updateWeight: PropTypes.func,
      updateWeightSystem: PropTypes.func,
      updateEdition: PropTypes.func,
      updateEditionDescription: PropTypes.func,
      updateInscriptions: PropTypes.func,
      updateCondition: PropTypes.func,
      updateDraftErrors: PropTypes.func,
      updateSubmitErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
    if (this.props.match.params.accountId) {
      this.handleUpdateAccount(this.props.match.params.accountId);
    }
  }

  componentWillUnmount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
  }

  handleUpdateAccount = account => {
    this.props.updateAccount({ account });
  };

  handleUpdateCollectingArea = collectingArea => {
    this.props.updateCollectingArea({ collectingArea });
  };

  handleUpdateArtistName = artistName => {
    this.props.updateArtistName({ artistName });
  };

  handleUpdateTitle = title => {
    this.props.updateTitle({ title });
  };

  handleUpdateYear = year => {
    this.props.updateYear({ year });
  };

  handleUpdateClassification = classification => {
    this.props.updateClassification({ classification });
  };

  handleUpdateMedium = medium => {
    this.props.updateMedium({ medium });
  };

  handleUpdateWeight = event => {
    if (event.target.validity.valid) {
      this.props.updateWeight({ weight: event.target.value });
    }
  };

  handleUpdateWeightSystem = weightSystem => {
    this.props.updateWeightSystem({ weightSystem });
  };

  handleUpdateInscriptions = inscriptions => {
    this.props.updateInscriptions({ inscriptions });
  };

  handleUpdateCondition = condition => {
    this.props.updateCondition({ condition });
  };

  handleUpdateEdition = _ => {
    this.props.updateEdition({ edition: !this.props.formData.edition });
    this.props.updateEditionDescription({ editionDescription: '' });
  };

  handleUpdateEditionDescription = editionDescription => {
    this.props.updateEditionDescription({ editionDescription });
  };

  destination = '';
  handleNextStepClick = _ => {
    if (
      this.props.postObjectDraftRequest.loading ||
      this.props.putObjectDraftRequest.loading
    ) {
      return;
    }

    const formDraftErrors = validateFormDraft(this.props.formData);
    this.props.updateDraftErrors({ formDraftErrors });

    if (this.props.formSubmitAttempted) {
      const formSubmitErrors = validateFormSubmit(this.props.formData);
      this.props.updateSubmitErrors({ formSubmitErrors });
    }

    if (formDraftErrors.formError) {
      return;
    }

    let payload = {};
    try {
      payload = makeObjectDraftPayload(this.props.formData);
    } catch (e) {
      console.error(e);
      return;
    }

    if (this.props.formData.id) {
      this.props.putObjectDraft(
        this.props.token,
        this.props.formData.id,
        payload,
      );
    } else {
      this.props.postObjectDraft(this.props.token, payload, this.props.user.id);
    }
  };

  handleRemoveDimensionsCard = index => {
    if (this.props.formData.dimensions[index]) {
      const dimensions = this.props.formData.dimensions.slice();
      dimensions.splice(index, 1);
      if (!dimensions.length) {
        dimensions.push({
          key: '',
          system: '',
          height: '',
          width: '',
          depth: '',
        });
      }
      this.props.updateDimensions({
        dimensions,
      });
    }
  };

  handleUpdateDimensionsType = (index, value) => {
    if (this.props.formData.dimensions[index]) {
      const dimensions = this.props.formData.dimensions.slice();
      if (value === 'dimensionsVariable' || value === 'duration') {
        dimensions[index] = {
          key: value,
          value: '',
        };
      } else {
        if (
          dimensions[index].key === 'dimensionsVariable' ||
          dimensions[index].key === 'duration'
        ) {
          dimensions[index] = {
            key: value,
            system: '',
            height: '',
            width: '',
            depth: '',
          };
        } else {
          dimensions[index].key = value;
        }
      }
      dimensions[index].key = value;
      this.props.updateDimensions({
        dimensions,
      });
    }
  };

  handleUpdateHeight = (index, event) => {
    if (event.target.validity.valid) {
      if (this.props.formData.dimensions[index]) {
        const dimensions = this.props.formData.dimensions.slice();
        dimensions[index].height = event.target.value;
        this.props.updateDimensions({
          dimensions,
        });
      }
    }
  };

  handleUpdateWidth = (index, event) => {
    if (event.target.validity.valid) {
      if (this.props.formData.dimensions[index]) {
        const dimensions = this.props.formData.dimensions.slice();
        dimensions[index].width = event.target.value;
        this.props.updateDimensions({
          dimensions,
        });
      }
    }
  };

  handleUpdateDepth = (index, event) => {
    if (event.target.validity.valid) {
      if (this.props.formData.dimensions[index]) {
        const dimensions = this.props.formData.dimensions.slice();
        dimensions[index].depth = event.target.value;
        this.props.updateDimensions({
          dimensions,
        });
      }
    }
  };

  handleUpdateDimensionsSystem = (index, value) => {
    if (this.props.formData.dimensions[index]) {
      const dimensions = this.props.formData.dimensions.slice();
      dimensions[index].system = value;
      this.props.updateDimensions({
        dimensions,
      });
    }
  };

  handleUpdateValue = (index, value) => {
    if (this.props.formData.dimensions[index]) {
      const dimensions = this.props.formData.dimensions.slice();
      dimensions[index].value = value;
      this.props.updateDimensions({
        dimensions,
      });
    }
  };

  handleAddOtherDimenstion = _ => {
    if (this.props.formData.dimensions.length < 10) {
      const dimensions = this.props.formData.dimensions.slice();
      dimensions.push({
        key: '',
        system: '',
        height: '',
        width: '',
        depth: '',
      });
      this.props.updateDimensions({
        dimensions,
      });
    }
  };

  makeDimensionsCards = _ => {
    const makeNewcard = (index, dimensionsInfo) => {
      const usedSelectors = {};
      this.props.formData.dimensions.forEach(
        v => (usedSelectors[v.key] = true),
      );

      const selectors = dimensionsMapping.filter(v => {
        return !usedSelectors[v.key] || dimensionsInfo.key === v.key;
      });

      return (
        <div
          key={`dimensions_card_${index}`}
          className="new-object-dimensions-card"
        >
          <div className="d-flex justify-content-between mex-mb-18">
            <div>
              <p
                className={`title-small mex-mb-6 ${
                  this.props.formDraftErrors.dimensionsErrors[
                    dimensionsInfo.key
                  ] &&
                  this.props.formDraftErrors.dimensionsErrors[
                    dimensionsInfo.key
                  ].typeError
                    ? 'label-error'
                    : ''
                }`}
              >
                Dimension Type
              </p>
              <select
                className={`btn dropdown-toggle mex-dropdown ${
                  dimensionsInfo && dimensionsInfo.key
                    ? 'mex-dropdown-selection'
                    : 'mex-dropdown-no-selection'
                } ${
                  this.props.formDraftErrors.dimensionsErrors[
                    dimensionsInfo.key
                  ] &&
                  this.props.formDraftErrors.dimensionsErrors[
                    dimensionsInfo.key
                  ].typeError
                    ? 'mex-dropdown-error'
                    : ''
                }`}
                onChange={event =>
                  this.handleUpdateDimensionsType(index, event.target.value)
                }
                value={dimensionsInfo ? dimensionsInfo.key : ''}
                style={{ width: '208px' }}
              >
                <option disabled value="">
                  Dimension Type
                </option>
                {selectors.map(v => (
                  <option key={v.key} value={v.key}>
                    {v.display}
                  </option>
                ))}
              </select>
            </div>
            {this.props.formData.dimensions.length > 1 && (
              <a
                onClick={e => {
                  e.preventDefault();
                  this.handleRemoveDimensionsCard(index);
                }}
                href="# "
                className="link-no-decoration"
              >
                <img
                  src={GreyCloseIcon}
                  alt="grey-close-icon"
                  className="new-object-dimensions-card-close-icon"
                />
              </a>
            )}
          </div>
          <div>
            {dimensionsInfo.key === 'dimensionsVariable' ||
            dimensionsInfo.key === 'duration' ? (
              <div>
                <p className={`title-small title-small mex-mb-6`}>
                  {dimensionsInfo.key === 'dimensionsVariable'
                    ? 'Dimensions Variable'
                    : 'Duration'}
                </p>
                <input
                  type="text"
                  className={`input`}
                  onChange={event =>
                    this.handleUpdateValue(index, event.target.value)
                  }
                  value={dimensionsInfo.value}
                  maxLength="100"
                  placeholder={
                    dimensionsInfo.key === 'dimensionsVariable'
                      ? 'Dimensions Variable'
                      : 'Duration'
                  }
                ></input>
              </div>
            ) : (
              <div className="d-flex justify-content-between">
                <div className="new-object-field-xsmall">
                  <p
                    className={`title-small mex-mb-6 ${
                      (this.props.formDraftErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formDraftErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].heightError) ||
                      (this.props.formSubmitErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formSubmitErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].heightError)
                        ? 'label-error'
                        : ''
                    }`}
                  >
                    Height
                  </p>
                  <input
                    className={`input ${
                      (this.props.formDraftErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formDraftErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].heightError) ||
                      (this.props.formSubmitErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formSubmitErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].heightError)
                        ? 'input-error'
                        : ''
                    }`}
                    type="text"
                    pattern="\d+(\.\d*)?"
                    onChange={event => this.handleUpdateHeight(index, event)}
                    value={dimensionsInfo ? dimensionsInfo.height : ''}
                    placeholder="Height"
                  ></input>
                </div>
                <div className="new-object-field-xsmall">
                  <p
                    className={`title-small mex-mb-6 ${
                      (this.props.formDraftErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formDraftErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].widthError) ||
                      (this.props.formSubmitErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formSubmitErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].widthError)
                        ? 'label-error'
                        : ''
                    }`}
                  >
                    Width
                  </p>
                  <input
                    className={`input ${
                      (this.props.formDraftErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formDraftErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].widthError) ||
                      (this.props.formSubmitErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formSubmitErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].widthError)
                        ? 'input-error'
                        : ''
                    }`}
                    type="text"
                    pattern="\d+(\.\d*)?"
                    onChange={event => this.handleUpdateWidth(index, event)}
                    value={dimensionsInfo ? dimensionsInfo.width : ''}
                    placeholder="Width"
                  ></input>
                </div>
                <div className="new-object-field-xsmall">
                  <p className="title-small mex-mb-6">Depth (optional)</p>
                  <input
                    className="input"
                    type="text"
                    onChange={event => this.handleUpdateDepth(index, event)}
                    value={dimensionsInfo ? dimensionsInfo.depth : ''}
                    placeholder="Depth"
                  ></input>
                </div>
                <div className="new-object-selector-small">
                  <p
                    className={`title-small mex-mb-6 ${
                      (this.props.formDraftErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formDraftErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].systemError) ||
                      (this.props.formSubmitErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formSubmitErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].systemError)
                        ? 'label-error'
                        : ''
                    }`}
                  >
                    Units
                  </p>
                  <select
                    className={`btn dropdown-toggle mex-dropdown ${
                      dimensionsInfo && dimensionsInfo.system
                        ? 'mex-dropdown-selection'
                        : 'mex-dropdown-no-selection'
                    } ${
                      (this.props.formDraftErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formDraftErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].systemError) ||
                      (this.props.formSubmitErrors.dimensionsErrors[
                        dimensionsInfo.key
                      ] &&
                        this.props.formSubmitErrors.dimensionsErrors[
                          dimensionsInfo.key
                        ].systemError)
                        ? 'mex-dropdown-error'
                        : ''
                    }`}
                    onChange={event =>
                      this.handleUpdateDimensionsSystem(
                        index,
                        event.target.value,
                      )
                    }
                    value={dimensionsInfo ? dimensionsInfo.system : ''}
                    style={{ width: '100%' }}
                  >
                    <option disabled value="">
                      Units
                    </option>
                    <option value={'metric'}>cm</option>
                    <option value={'imperial'}>in</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        {this.props.formData.dimensions.map((v, i) => makeNewcard(i, v))}
        {this.props.formData.dimensions.length <= 9 && (
          <button
            className="btn new-object-add-dimensions-btn"
            onClick={this.handleAddOtherDimenstion}
          >
            ADD OTHER DIMENSIONS
          </button>
        )}
      </div>
    );
  };

  render() {
    if (
      (this.props.postObjectDraftRequest.data &&
        !this.props.postObjectDraftRequest.loading &&
        !this.props.postObjectDraftRequest.error) ||
      (this.props.putObjectDraftRequest.data &&
        !this.props.putObjectDraftRequest.loading &&
        !this.props.putObjectDraftRequest.error)
    ) {
      return (
        <NextDestination destination={this.destination} nextPageNumber={2} />
      );
    }

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <div className="back-to-folders-link-container">
          <BackToObjectsLink />
        </div>
        <p className="header text-center mex-mt-47">Submit a New Object</p>
        <div className="sub-header sub-header-wide mx-auto mex-mb-30">
          <p className="text text-center">
            Please complete this form to list your object for donation
            consideration on Museum Exchange
          </p>
        </div>
        <div className="new-object-steps-container mx-auto d-flex justify-content-between mex-mb-26">
          <div
            className={`new-object-step new-object-step-current d-flex justify-content-center align-items-center ${
              this.props.formDraftErrors.errorPages.one ||
              this.props.formSubmitErrors.errorPages.one
                ? 'new-object-step-error'
                : ''
            }`}
          >
            <p className="new-object-step-text new-object-step-current-text">
              OBJECT DETAILS
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p
              className={`new-object-step-text ${
                this.props.formSubmitErrors.errorPages.two
                  ? 'new-object-step-error-text'
                  : ''
              }`}
            >
              CATALOGUING
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p
              className={`new-object-step-text ${
                this.props.formDraftErrors.errorPages.three ||
                this.props.formSubmitErrors.errorPages.three
                  ? 'new-object-step-error-text'
                  : ''
              }`}
            >
              DONATION INFORMATION
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p
              className={`new-object-step-text ${
                this.props.formSubmitErrors.errorPages.four
                  ? 'new-object-step-error-text'
                  : ''
              }`}
            >
              UPLOAD IMAGES
            </p>
          </div>
          <div className="new-object-step d-flex justify-content-center align-items-center">
            <p className="new-object-step-text">REVIEW & SUBMIT</p>
          </div>
        </div>
        <div className="new-object-content-container d-flex justify-content-center mx-auto">
          <div className="new-object-content">
            <div className="new-object-content-inner mx-auto">
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.collectingAreaError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Collecting Area
                </p>
                <select
                  className={`btn dropdown-toggle mex-dropdown ${
                    this.props.formData.collectingArea
                      ? 'mex-dropdown-selection'
                      : 'mex-dropdown-no-selection'
                  } ${
                    this.props.formSubmitErrors.collectingAreaError
                      ? 'mex-dropdown-error'
                      : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateCollectingArea(event.target.value)
                  }
                  value={this.props.formData.collectingArea}
                  style={{ width: '100%' }}
                >
                  <option disabled value="">
                    Select a Collecting Area
                  </option>
                  {COLLECTING_AREAS.map((v, i) => (
                    <option key={`collectingArea_${i}`} value={v}>
                      {v}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.artistNameError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Artist
                </p>
                <input
                  type="text"
                  className={`input ${
                    this.props.formSubmitErrors.artistNameError
                      ? 'input-error'
                      : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateArtistName(event.target.value)
                  }
                  value={this.props.formData.artistName}
                  maxLength="100"
                  placeholder="Artist"
                ></input>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formDraftErrors.titleError ||
                    this.props.formSubmitErrors.titleError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Title
                </p>
                <input
                  type="text"
                  className={`input ${
                    this.props.formDraftErrors.titleError ||
                    this.props.formSubmitErrors.titleError
                      ? 'input-error'
                      : ''
                  }`}
                  onChange={event => this.handleUpdateTitle(event.target.value)}
                  value={this.props.formData.title}
                  maxLength="200"
                  placeholder="Title"
                ></input>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.yearError ? 'label-error' : ''
                  }`}
                >
                  Year
                </p>
                <input
                  type="text"
                  className={`input ${
                    this.props.formSubmitErrors.yearError ? 'input-error' : ''
                  }`}
                  onChange={event => this.handleUpdateYear(event.target.value)}
                  value={this.props.formData.year}
                  maxLength="20"
                  placeholder="Year"
                ></input>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.classificationError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Classification
                </p>
                <select
                  className={`btn dropdown-toggle mex-dropdown ${
                    this.props.formData.classification
                      ? 'mex-dropdown-selection'
                      : 'mex-dropdown-no-selection'
                  } ${
                    this.props.formSubmitErrors.classificationError
                      ? 'mex-dropdown-error'
                      : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateClassification(event.target.value)
                  }
                  value={this.props.formData.classification}
                  style={{ width: '100%' }}
                >
                  <option disabled value="">
                    Select a Classification
                  </option>
                  {CLASSIFICATIONS.map((v, i) => (
                    <option key={`classification_${i}`} value={v}>
                      {v}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.mediumError ? 'label-error' : ''
                  }`}
                >
                  Medium
                </p>
                <input
                  type="text"
                  className={`input ${
                    this.props.formSubmitErrors.mediumError ? 'input-error' : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateMedium(event.target.value)
                  }
                  value={this.props.formData.medium}
                  maxLength="100"
                  placeholder="Medium"
                ></input>
              </div>
              <div className="mex-mb-22">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formDraftErrors.dimensionsError ||
                    this.props.formSubmitErrors.dimensionsError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Dimensions
                </p>
                {this.makeDimensionsCards()}
              </div>
              <div className="mex-mb-30">
                <p className="label-bold mex-mb-6">Weight</p>
                <p className="title-small mex-mb-12" style={{ width: '371px' }}>
                  OPTIONAL
                </p>
                <div>
                  <div className="d-flex justify-content-start">
                    <div className="new-object-field-small mex-mr-16">
                      <input
                        className="input"
                        type="text"
                        pattern="\d+(\.\d*)?"
                        onChange={event => this.handleUpdateWeight(event)}
                        value={this.props.formData.weight}
                        placeholder="Weight"
                      ></input>
                    </div>
                    <div className="new-object-selector-small">
                      <select
                        className={`btn dropdown-toggle mex-dropdown ${
                          this.props.formData.weightSystem
                            ? 'mex-dropdown-selection'
                            : 'mex-dropdown-no-selection'
                        } ${
                          this.props.formDraftErrors.weightSystemError ||
                          this.props.formSubmitErrors.weightSystemError
                            ? 'mex-dropdown-error'
                            : ''
                        }`}
                        onChange={event =>
                          this.handleUpdateWeightSystem(event.target.value)
                        }
                        value={this.props.formData.weightSystem}
                        style={{ width: '100%' }}
                      >
                        <option disabled value="">
                          Units
                        </option>
                        <option value={'metric'}>kg</option>
                        <option value={'imperial'}>lb</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mex-mb-30">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formDraftErrors.editionError ||
                    this.props.formSubmitErrors.editionError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Edition
                </p>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    className="donor-museum-application-checkbox"
                    checked={this.props.formData.edition}
                    onChange={this.handleUpdateEdition}
                  />
                  <p
                    className={`text ${
                      this.props.formData.edition ? 'mex-mb-12' : ''
                    }`}
                    onClick={this.handleUpdateEdition}
                  >
                    This object is an edition or multiple
                  </p>
                </div>
                {this.props.formData.edition && (
                  <input
                    type="text"
                    className={`input ${
                      this.props.formDraftErrors.editionError ||
                      this.props.formSubmitErrors.editionError
                        ? 'input-error'
                        : ''
                    }`}
                    onChange={event =>
                      this.handleUpdateEditionDescription(event.target.value)
                    }
                    value={this.props.formData.editionDescription}
                    maxLength="100"
                    placeholder="Edition Number"
                  ></input>
                )}
              </div>
              <div className="mex-mb-22">
                <p className="label-bold mex-mb-6">Signature / Inscriptions</p>
                <p className="title-small mex-mb-12" style={{ width: '371px' }}>
                  OPTIONAL
                </p>
                <textarea
                  className="input-textarea"
                  onChange={event =>
                    this.handleUpdateInscriptions(event.target.value)
                  }
                  value={this.props.formData.inscriptions}
                  maxLength="1000"
                  placeholder="Describe signature or inscriptions and the corresponding location(s) "
                ></textarea>
              </div>
              <div className="mex-mb-22">
                <p
                  className={`label-bold mex-mb-12 ${
                    this.props.formSubmitErrors.conditionError
                      ? 'label-error'
                      : ''
                  }`}
                >
                  Condition
                </p>
                <textarea
                  className={`input-textarea ${
                    this.props.formSubmitErrors.conditionError
                      ? 'input-error'
                      : ''
                  }`}
                  onChange={event =>
                    this.handleUpdateCondition(event.target.value)
                  }
                  value={this.props.formData.condition}
                  maxLength="1000"
                  placeholder="Describe object condition"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar fixed-bottom d-flex justify-content-center new-object-nav-bottom align-items-end">
          <div className="new-object-nav-bottom-container d-flex justify-content-end">
            <div className="new-object-nav-bottom-alert-container">
              {(this.props.putObjectDraftRequest.error ||
                this.props.postObjectDraftRequest.error ||
                this.props.formDraftErrors.formError ||
                this.props.formSubmitErrors.formError) && (
                <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
                  {this.props.putObjectDraftRequest.error ||
                  this.props.postObjectDraftRequest.error
                    ? 'Please try again'
                    : 'Please complete missing field(s)'}
                </div>
              )}
            </div>
            <button
              className="btn btn-primary new-object-nav-bottom-save-button"
              onClick={_ => {
                this.destination = 'folders';
                this.handleNextStepClick();
              }}
            >
              SAVE DRAFT & EXIT
            </button>
          </div>
          <div className="new-object-nav-bottom-container d-flex justify-content-start">
            <div>
              <p className="title-small text-center mex-mb-10">NEXT STEP</p>
              <button
                className="btn btn-primary new-object-nav-bottom-next-button"
                onClick={_ => {
                  this.destination = 'next';
                  this.handleNextStepClick();
                }}
              >
                CATALOGUING
              </button>
            </div>
            <div className="new-object-nav-bottom-note-container"></div>
          </div>
        </div>
      </div>
    );
  }
}

export function BackToObjectsLink() {
  const url = useCurrentDonorAccountUrl();
  return (
    <Link className="back-to-folders-link" to={url}>
      &lt; BACK TO OBJECTS
    </Link>
  );
}

export function NextDestination({ destination, nextPageNumber }) {
  const { accountId } = useParams();
  const accountUrl = useCurrentDonorAccountUrl();

  if (destination === 'next' && nextPageNumber) {
    return (
      <Redirect
        push
        to={`/donor/folders/${accountId}/object/draft/${nextPageNumber}`}
      />
    );
  } else if (destination === 'folders') {
    return <Redirect push to={accountUrl} />;
  }
  return null;
}

const mapStateToProps = (state, props) => {
  return {
    formData: state.objectDraftState.formData,
    formDraftErrors: state.objectDraftState.formDraftErrors,
    formSubmitAttempted: state.objectDraftState.formSubmitAttempted,
    formSubmitErrors: state.objectDraftState.formSubmitErrors,
    postObjectDraftRequest: state.objectDraftState.objDraftPostRequest,
    putObjectDraftRequest: state.objectDraftState.objDraftPutRequest,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postObjectDraft: (token, payload, userId) =>
      dispatch(postObjectDraft(token, payload, userId)),
    postObjectDraftClear: _ => dispatch(objDraftPostClear()),
    putObjectDraft: (token, id, payload) =>
      dispatch(putObjectDraft(token, id, payload)),
    putObjectDraftClear: _ => dispatch(objDraftPutClear()),
    updateAccount: payload => dispatch(objDraftUpdateAccount(payload)),
    updateCollectingArea: payload =>
      dispatch(objDraftUpdateCollectingArea(payload)),
    updateArtistName: payload => dispatch(objDraftUpdateArtistName(payload)),
    updateTitle: payload => dispatch(objDraftUpdateTitle(payload)),
    updateYear: payload => dispatch(objDraftUpdateYear(payload)),
    updateClassification: payload =>
      dispatch(objDraftUpdateClassification(payload)),
    updateMedium: payload => dispatch(objDraftUpdateMedium(payload)),
    updateDimensions: payload => dispatch(objDraftUpdateDimensions(payload)),
    updateWeight: payload => dispatch(objDraftUpdateWeight(payload)),
    updateWeightSystem: payload =>
      dispatch(objDraftUpdateWeightSystem(payload)),
    updateEdition: payload => dispatch(objDraftUpdateEdition(payload)),
    updateEditionDescription: payload =>
      dispatch(objDraftUpdateEditionDescription(payload)),
    updateInscriptions: payload =>
      dispatch(objDraftUpdateInscriptions(payload)),
    updateCondition: payload => dispatch(objDraftUpdateCondition(payload)),
    updateDraftErrors: payload => dispatch(objDraftUpdateDraftErrors(payload)),
    updateSubmitErrors: payload =>
      dispatch(objDraftUpdateSubmitErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDraftOne);
