import { dacctReducer } from 'features/donorAccounts/donorAccountsSlice';
import { macctReducer } from 'features/museumAccounts/museumAccountsSlice';
import { combineReducers } from 'redux';
import { api } from 'requests/api';
import {
  authReducer,
  catalogueReducer,
  confirmEmailReducer,
  cyclesReducer,
  donorApplicationReducer,
  donorDonationsMatchReducer,
  donorProposalsReducer,
  donorSettingsReducer,
  imageCarouselReducer,
  listsReducer,
  loginReducer,
  museumApplicationReducer,
  museumDonationsMatchReducer,
  museumSettingsReducer,
  objectDraftReducer,
  pageReducer,
  proposalsReducer,
  resetReducer,
  signupReducer,
  telReducer,
  userReducer,
} from '../reducers';

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  authState: authReducer,
  catalogueState: catalogueReducer,
  confirmEmailState: confirmEmailReducer,
  cyclesState: cyclesReducer,
  donorAccountsState: dacctReducer,
  donorApplicationState: donorApplicationReducer,
  donorDonationsMatchState: donorDonationsMatchReducer,
  donorProposalsState: donorProposalsReducer,
  donorSettingsState: donorSettingsReducer,
  imageCarouselState: imageCarouselReducer,
  listsState: listsReducer,
  loginState: loginReducer,
  museumAccounts: macctReducer,
  museumApplicationState: museumApplicationReducer,
  museumSettingsState: museumSettingsReducer,
  objectDraftState: objectDraftReducer,
  pageState: pageReducer,
  proposalsState: proposalsReducer,
  resetState: resetReducer,
  signupState: signupReducer,
  telState: telReducer,
  userState: userReducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === 'AUTH_PUT_LOGOUT_SUCCESS' ||
    action.type === 'PAGE_APP_RESET'
  ) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
