import { dimensionsMapping } from '../';

export const parseDraftIntoFormData = draft => {
  const formData = {
    id: draft.id,
    account: draft.accountId,
    collectingArea: draft.collectingArea,
    artistName: draft.artistName,
    title: draft.title,
    year: draft.year,
    classification: draft.classification,
    medium: draft.medium,
    dimensions: [],
    condition: draft.condition,
    provenance: draft.provenance,
    locationCity: draft.locationCity,
    locationState: draft.locationState,
    locationZip: draft.locationZip,
    primaryImage: draft.primaryImage,
    supportingImages: draft.supportingImages,
    submissionHash: draft.submissionHash,
    privacyPolicyHash: draft.privacyPolicyHash,
    tosHash: draft.tosHash,
    rawDimensions: {},
    rawWeight: {},
  };

  dimensionsMapping.forEach(v => {
    if (draft.dimensions[v.key]) {
      if (v.key === 'dimensionsVariable' || v.key === 'duration') {
        formData.dimensions.push({
          key: v.key,
          value: draft.dimensions[v.key],
        });
      } else {
        formData.dimensions.push({
          key: v.key,
          height: String(draft.dimensions[v.key].heightImperial),
          width: String(draft.dimensions[v.key].widthImperial),
          system: 'imperial',
          depth: draft.dimensions[v.key].depthImperial
            ? String(draft.dimensions[v.key].depthImperial)
            : '',
        });
      }
    }
  });

  if (!formData.dimensions.length) {
    formData.dimensions = [
      {
        key: '',
        system: '',
        height: '',
        width: '',
        depth: '',
      },
    ];
  }

  if (draft.weightImperial) {
    formData.weight = String(draft.weightImperial);
    formData.weightSystem = 'imperial';
  } else {
    formData.weight = '';
    formData.weightSystem = '';
  }

  if (draft.edition) {
    formData.edition = true;
    formData.editionDescription = draft.edition;
  } else {
    formData.edition = false;
    formData.editionDescription = '';
  }

  if (draft.inscriptions) {
    formData.inscriptions = draft.inscriptions;
  } else {
    formData.inscriptions = '';
  }

  if (draft.exhibitionHistory) {
    formData.exhibitionHistory = draft.exhibitionHistory;
  } else {
    formData.exhibitionHistory = '';
  }

  if (draft.literature) {
    formData.literature = draft.literature;
  } else {
    formData.literature = '';
  }

  if (draft.conservationHistory) {
    formData.conservationHistory = draft.conservationHistory;
  } else {
    formData.conservationHistory = '';
  }

  if (draft.restrictions) {
    formData.restrictions = true;
    formData.restrictionsDescription = draft.restrictions;
  } else {
    formData.restrictions = false;
    formData.restrictionsDescription = '';
  }

  if (draft.notes) {
    formData.notes = draft.notes;
  } else {
    formData.notes = '';
  }

  formData.rawDimensions = draft.dimensions;

  if (draft.weightImperial) {
    formData.rawWeight.weightImperial = draft.weightImperial;
  }

  if (draft.weightMetric) {
    formData.rawWeight.weightMetric = draft.weightMetric;
  }

  return formData;
};
