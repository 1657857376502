import add from 'date-fns/add';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parseJSON from 'date-fns/parseJSON';
import { ONGOING_CYCLE_POST_PROPOSAL_DAYS } from '../../config';

export const getDaysLeftInCatalog = ({
  dateAddedToCatalog,
  firstProposalDate,
}) => {
  // TODO: Ideally we should set all this time logic on the backend and have one source of truth.
  const endDate = firstProposalDate
    ? add(parseJSON(firstProposalDate).setHours(0, 0, 0, 0), {
        days: ONGOING_CYCLE_POST_PROPOSAL_DAYS,
      })
    : add(parseJSON(dateAddedToCatalog).setHours(0, 0, 0, 0), { months: 6 });

  return differenceInCalendarDays(endDate, new Date().setHours(0, 0, 0, 0));
};
