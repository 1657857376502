import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propSetSelectedObject } from '../../actions';
import GreySpinner from '../../images/spinner-grey.png';
import '../../styles/general.css';
import '../../styles/ma-card-view.css';
import '../../styles/text.css';
import { selectIsMuseum } from 'features/museumAccounts/museumAccountsSlice';
import {
  useGetDonationsWithObjectsMuseumAccountQuery,
  useGetMuseumAccountUserQuery,
} from 'requests/api';
import { ObjectCardMiddle } from './ObjectCardMiddle';

export default function MuseumDonationsActive() {
  const { data: museumAccount, isLoading: isLoadingMuseumAccount } =
    useGetMuseumAccountUserQuery();

  const { data: donations, isLoading: isLoadingDonations } =
    useGetDonationsWithObjectsMuseumAccountQuery(museumAccount?.id, {
      skip: !museumAccount,
    });

  const isMuseum = useSelector(selectIsMuseum);
  const dispatch = useDispatch();
  const setSelectedObject = payload => dispatch(propSetSelectedObject(payload));

  if (
    museumAccount &&
    typeof museumAccount === 'object' &&
    !isLoadingDonations
  ) {
    return (
      <div className="gen-background-nav-museum">
        <div className="mcv-content mx-auto mex-mb-30">
          <div className="mcv-header d-flex">
            <img
              src={GreySpinner}
              alt="grey-spinner"
              className="mcv-header-icon-1"
            />
            <div>
              <div className="mcv-header-super-txt">
                <p className="txt-16">{isMuseum ? 'MUSEUM' : 'INSTITUTION'}</p>
              </div>
              <p className="txt-header">Active Donations</p>
            </div>
          </div>
          {makeContent({ donations, setSelectedObject })}
        </div>
      </div>
    );
  }

  return null;
}

const makeContent = ({ donations, setSelectedObject }) => {
  if (!donations || donations.length === 0) {
    return (
      <div className="mcv-empty-container">
        <p className="mcv-empty-txt">No Objects</p>
      </div>
    );
  }

  const cards = donations.map(donationObject => {
    const { cycle, donation, object, list } = donationObject;
    if (!cycle || !object || !donation) {
      return null;
    }
    if (
      !donation?.events?.acceptedComplete ||
      donation?.events?.completed ||
      donation?.events?.closed
    ) {
      return null;
    }

    return (
      <div key={`my_obj_${object.id}`} className="mcv-obj-lst-card d-flex">
        <img
          src={`https://res.cloudinary.com/ex-tech/image/fetch/h_200,w_150,c_fill,q_auto/https://${object.primaryImage.bucket}.s3.amazonaws.com/${object.primaryImage.imageId}`}
          alt={`obj_${object.id}`}
          className="mcv-obj-lst-card-img"
        />
        <div className="mcv-obj-lst-card-sec-1">
          <div className="mcv-obj-lst-card-sec-1-content">
            <div className="mcv-obj-lst-card-main-txt-container">
              <p className="mcv-obj-lst-card-txt gen-crop-txt-3">
                {object.artistName}
              </p>
            </div>
            <p className="mcv-obj-lst-card-txt-sm">
              <span className="gen-crop-txt-2">
                <i>{object.title}</i>
                {', '}
              </span>
              {object.year}
            </p>
          </div>
        </div>
        <ObjectCardMiddle
          cycle={cycle}
          donation={donation}
          object={object}
          list={list}
          setSelectedObject={setSelectedObject}
        />
      </div>
    );
  });

  return (
    <div className="mcv-obj-lst-container">
      <div className="mcv-obj-lst-outer">{cards}</div>
    </div>
  );
};
