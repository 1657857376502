import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../../images/arrow-back.png';
import '../../styles/donation.css';
import '../../styles/general.css';
import '../../styles/spacing.css';
import { useGetMuseumAccountsSimpleQuery } from 'requests/api';
import { useDonorGetDonation } from 'components/_shared/useDonorGetDonation';
import { useParams } from 'react-router-dom';
import { useDonorGetObject } from 'components/_shared/useDonorGetObject';
import { DonationTopSection } from 'components/_shared/DonationTopSection';
import { useCurrentDonorAccountUrl } from 'components/objectDraft/useCurrentDonorAccountUrl';
import { ShippingArrangementsSection } from 'components/_shared/Donations/ShippingArrangementsSection';
import { DonationInformationSection } from 'components/_shared/Donations/DonationInformationSection';
import { PreShippingInspectionSection } from 'components/_shared/Donations/PreShippingInspectionSection';
import { LoanAgreementSection } from 'components/_shared/Donations/LoanAgreementSection';
import { AppraisalSection } from 'components/_shared/Donations/AppraisalSection';
import { DeedOfGiftSection } from 'components/_shared/Donations/DeedOfGiftSection';
import { FormIRSSection } from 'components/_shared/Donations/FormIRSSection';

export default function DonorDonation() {
  const { accountId, donationId } = useParams();
  const url = useCurrentDonorAccountUrl();
  const {
    data: donation,
    isError: isErrorDonation,
    isFetching: isFetchingDonation,
  } = useDonorGetDonation({
    donationId,
    accountId,
  });
  const { data: object, isError: isErrorObject } = useDonorGetObject({
    objectId: donation?.objectId,
    accountId,
  });
  const { data: museums, isError: isErrorMuseums } =
    useGetMuseumAccountsSimpleQuery();

  const makeContent = museum => {
    const isPreliminaryInfoComplete =
      donation.museumInfo.approvalDatesSubmitted &&
      donation.museumInfo.preshippingRequirementsSubmitted &&
      donation.objectInfo.objectInfoSubmitted &&
      donation.donorInfo.shippingInfoSubmitted;

    return (
      <React.Fragment>
        <div
          className={`tw-mb-16 tw-grid tw-gap-16 ${
            isFetchingDonation ? 'tw-opacity-40' : ''
          }`}
        >
          <DonationInformationSection
            donation={donation}
            donorAccountId={accountId}
          />
        </div>
        {isPreliminaryInfoComplete ? (
          <div className="tw-grid tw-gap-16 tw-pb-24">
            <PreShippingInspectionSection donation={donation} />
            <LoanAgreementSection donation={donation} />
            <ShippingArrangementsSection donation={donation} />
            <AppraisalSection donation={donation} />
            <DeedOfGiftSection donation={donation} />
            <FormIRSSection donation={donation} />
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  if (isErrorDonation || isErrorObject || isErrorMuseums) {
    return (
      <div className="gen-background-nav-donor">
        <div className="d-header mx-auto d-flex">
          <Link to={url}>
            <img
              src={BackIcon}
              alt="back-arrow"
              className="d-header-icon-back gen-cursor-hover-link"
            />
          </Link>
          <div class-name="d-header-back-container-content">
            <p className="d-header-title">Donation Overview</p>
          </div>
        </div>
        <p className="text-center d-special-text">
          <em>Something went wrong.</em>
        </p>
      </div>
    );
  }

  if (object && donation && Array.isArray(museums) && museums.length) {
    let museum = null;
    museums.forEach(m => {
      if (donation.museumId === m.id) {
        museum = m;
      }
    });

    return (
      <div className="gen-background-nav-donor">
        <div className="d-header mx-auto">
          <div className="d-flex">
            <Link to={url}>
              <img
                src={BackIcon}
                alt="back-arrow"
                className="d-header-icon-back gen-cursor-hover-link"
              />
            </Link>
            <div class-name="d-header-back-container-content">
              <p className="d-header-title">Donation Overview</p>
            </div>
          </div>
          <div className="d-header-bottom d-flex">
            <DonationTopSection
              object={object}
              donation={donation}
              museum={museum}
            />
          </div>
        </div>
        {makeContent(museum)}
      </div>
    );
  }

  return <div className="gen-background-nav-donor"></div>;
}
