import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import {
  CURRENT_OBJECT_SUBMISSION_HASH,
  CURRENT_PRIVACY_POLICY_HASH,
  CURRENT_TOS_HASH,
} from '../../config';
import {
  objDraftPostClear,
  objDraftPutClear,
  objDraftPutSubmitClear,
  objDraftSetSelectedImg,
  objDraftUpdateDraftErrors,
  objDraftUpdateSubmissionHash,
  objDraftUpdatePrivacyTOSHash,
  objDraftUpdateSubmitAttempted,
  objDraftUpdateSubmitErrors,
} from '../../actions';
import {
  postObjectDraft,
  putObjectDraft,
  putObjectSubmit,
} from '../../requests';
import {
  makeObjectDraftPayload,
  validateFormDraft,
  validateFormSubmit,
} from '../../glue';
import 'react-image-lightbox/style.css';
import {
  ApproveTermsOfServiceCard,
  CataloguingCard,
  DonationInformationCard,
  ObjectDetailsCard,
  UploadedImagesCard,
} from './DraftSummarySections';
import { useCurrentDonorAccountRoleMatches } from 'components/_shared/useDonorAccountRole';
import { DONOR_ACCESS_ROLES } from 'constants/donors';
import { BackToObjectsLink, NextDestination } from './ObjectDraftOne';

class ObjectDraftFive extends Component {
  static get propTypes() {
    return {
      formData: PropTypes.object,
      formDraftErrors: PropTypes.object,
      formSubmitAttempted: PropTypes.bool,
      formSubmitErrors: PropTypes.object,
      postObjectDraft: PropTypes.func,
      postObjectDraftClear: PropTypes.func,
      postObjectDraftRequest: PropTypes.object,
      putObjectDraft: PropTypes.func,
      putObjectDraftClear: PropTypes.func,
      putObjectDraftRequest: PropTypes.object,
      putObjectSubmit: PropTypes.func,
      putObjectSubmitClear: PropTypes.func,
      putObjectSubmitRequest: PropTypes.object,
      selectedImg: PropTypes.object,
      setSelectedImg: PropTypes.func,
      token: PropTypes.object,
      updateDraftErrors: PropTypes.func,
      updateSubmissionHash: PropTypes.func,
      updatePrivacyTOSHash: PropTypes.func,
      updateSubmitAttempted: PropTypes.func,
      updateSubmitErrors: PropTypes.func,
      user: PropTypes.object,
    };
  }

  componentDidMount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
    this.props.putObjectSubmitClear();
  }

  componentWillUnmount() {
    this.props.postObjectDraftClear();
    this.props.putObjectDraftClear();
    this.props.putObjectSubmitClear();
    this.props.setSelectedImg(null);
  }

  handleUpdateSubmissionHash = _ => {
    if (this.props.formData.submissionHash === CURRENT_OBJECT_SUBMISSION_HASH) {
      this.props.updateSubmissionHash({ submissionHash: null });
    } else {
      this.props.updateSubmissionHash({
        submissionHash: CURRENT_OBJECT_SUBMISSION_HASH,
      });
    }
  };

  handleUpdatePrivacyTOSHash = _ => {
    if (
      this.props.formData.privacyPolicyHash === CURRENT_PRIVACY_POLICY_HASH ||
      this.props.formData.tosHash === CURRENT_TOS_HASH
    ) {
      this.props.updatePrivacyTOSHash({
        privacyPolicyHash: null,
        tosHash: null,
      });
    } else {
      this.props.updatePrivacyTOSHash({
        privacyPolicyHash: CURRENT_PRIVACY_POLICY_HASH,
        tosHash: CURRENT_TOS_HASH,
      });
    }
  };

  destination = '';
  handleNextStepClick = _ => {
    if (
      this.props.postObjectDraftRequest.loading ||
      this.props.putObjectDraftRequest.loading ||
      this.props.putObjectSubmitRequest.loading
    ) {
      return;
    }
    const formDraftErrors = validateFormDraft(this.props.formData);
    this.props.updateDraftErrors({ formDraftErrors });

    if (this.props.formSubmitAttempted) {
      const formSubmitErrors = validateFormSubmit(this.props.formData);
      this.props.updateSubmitErrors({ formSubmitErrors });
    }

    if (formDraftErrors.formError) {
      return;
    }

    let payload = {};
    try {
      payload = makeObjectDraftPayload(this.props.formData);
    } catch (e) {
      console.error(e);
      return;
    }

    if (this.props.formData.id) {
      this.props.putObjectDraft(
        this.props.token,
        this.props.formData.id,
        payload,
      );
    } else {
      this.props.postObjectDraft(this.props.token, payload, this.props.user.id);
    }
  };

  handleSubmitClick = _ => {
    if (
      this.props.postObjectDraftRequest.loading ||
      this.props.putObjectDraftRequest.loading ||
      this.props.putObjectSubmitRequest.loading
    ) {
      return;
    }

    this.props.updateSubmitAttempted({ formSubmitAttempted: true });

    const formDraftErrors = validateFormDraft(this.props.formData);
    this.props.updateDraftErrors({ formDraftErrors });

    const formSubmitErrors = validateFormSubmit(this.props.formData);
    this.props.updateSubmitErrors({ formSubmitErrors });

    if (formSubmitErrors.formError) {
      return;
    }

    let payload = {};
    try {
      payload = makeObjectDraftPayload(this.props.formData);
    } catch (e) {
      console.error(e);
      return;
    }

    this.props.putObjectSubmit(
      this.props.token,
      this.props.formData.id,
      payload,
      this.props.user.id,
    );
  };

  render() {
    if (
      (this.props.postObjectDraftRequest.data &&
        !this.props.postObjectDraftRequest.loading &&
        !this.props.postObjectDraftRequest.error) ||
      (this.props.putObjectDraftRequest.data &&
        !this.props.putObjectDraftRequest.loading &&
        !this.props.putObjectDraftRequest.error) ||
      (this.props.putObjectSubmitRequest.data &&
        !this.props.putObjectSubmitRequest.loading &&
        !this.props.putObjectSubmitRequest.error)
    ) {
      return <NextDestination destination={this.destination} />;
    }

    const { accountId } = this.props.match.params;

    return (
      <div className={`${this.props.header ? 'background-nav' : 'background'}`}>
        <div className="back-to-folders-link-container">
          <BackToObjectsLink />
        </div>
        <p className="text text-center mex-mt-47 mex-mb-14">FINAL STEP</p>
        <p className="header text-center mex-mb-30">Review & Submit</p>
        <div className="new-object-content-container d-flex justify-content-between mx-auto">
          <div className="new-object-summary-content mx-auto">
            <ApproveTermsOfServiceCard
              accountId={accountId}
              formData={this.props.formData}
              formSubmitErrors={this.props.formSubmitErrors}
              handleUpdatePrivacyTOSHash={this.handleUpdatePrivacyTOSHash}
              handleUpdateSubmissionHash={this.handleUpdateSubmissionHash}
            />
            <ObjectDetailsCard
              accountId={accountId}
              formData={this.props.formData}
              formSubmitErrors={this.props.formSubmitErrors}
              formDraftErrors={this.props.formDraftErrors}
            />
            <CataloguingCard
              accountId={accountId}
              formData={this.props.formData}
              formSubmitErrors={this.props.formSubmitErrors}
            />
            <DonationInformationCard
              accountId={accountId}
              formData={this.props.formData}
              formSubmitErrors={this.props.formSubmitErrors}
              formDraftErrors={this.props.formDraftErrors}
            />
            <UploadedImagesCard
              accountId={accountId}
              formData={this.props.formData}
              formSubmitErrors={this.props.formSubmitErrors}
              formDraftErrors={this.props.formDraftErrors}
              setSelectedImg={this.setSelectedImg}
            />
          </div>
        </div>
        <BottomNav
          setDestination={() => {
            this.destination = 'folders';
          }}
          handleNextStepClick={this.handleNextStepClick}
          handleSubmitClick={this.handleSubmitClick}
        />
        {this.props.selectedImg && (
          <Lightbox
            mainSrc={`https://res.cloudinary.com/ex-tech/image/fetch/q_auto/https://${this.props.selectedImg.bucket}.s3.amazonaws.com/${this.props.selectedImg.imageId}`}
            onCloseRequest={_ => this.props.setSelectedImg(null)}
            imagePadding={65}
            clickOutsideToClose={false}
          />
        )}
      </div>
    );
  }
}

function BottomNav({ handleNextStepClick, handleSubmitClick, setDestination }) {
  const formDraftErrors = useSelector(
    state => state.objectDraftState.formDraftErrors,
  );

  const formSubmitErrors = useSelector(
    state => state.objectDraftState.formSubmitErrors,
  );
  const postObjectDraftRequest = useSelector(
    state => state.objectDraftState.objDraftPostRequest,
  );
  const putObjectDraftRequest = useSelector(
    state => state.objectDraftState.objDraftPutRequest,
  );
  const putObjectSubmitRequest = useSelector(
    state => state.objectDraftState.objDraftPutSubmitRequest,
  );

  const canEdit = useCurrentDonorAccountRoleMatches([
    DONOR_ACCESS_ROLES.editor,
    DONOR_ACCESS_ROLES.owner,
  ]);

  if (!canEdit) return null;

  return (
    <div className="navbar fixed-bottom d-flex justify-content-center new-object-nav-bottom align-items-end">
      <div
        className="new-object-nav-bottom-container d-flex justify-content-end"
        style={{ marginBottom: '25px' }}
      >
        <div className="new-object-nav-bottom-alert-container">
          {(putObjectDraftRequest.error ||
            postObjectDraftRequest.error ||
            putObjectSubmitRequest.error ||
            formDraftErrors.formError ||
            formSubmitErrors.formError) && (
            <div className="alert alert-danger mex-alert-danger mex-mt-9 mex-mb-12">
              {putObjectDraftRequest.error ||
              postObjectDraftRequest.error ||
              putObjectSubmitRequest.error
                ? 'Please try again'
                : 'Please complete missing field(s)'}
            </div>
          )}
        </div>
        <button
          className="btn btn-primary new-object-nav-bottom-save-button"
          onClick={_ => {
            setDestination();
            handleNextStepClick();
          }}
        >
          SAVE DRAFT & EXIT
        </button>
      </div>
      <div
        className="new-object-nav-bottom-container d-flex justify-content-start"
        style={{ marginBottom: '25px' }}
      >
        <div>
          <button
            className="btn btn-primary new-object-nav-bottom-next-button"
            onClick={_ => {
              setDestination();
              handleSubmitClick();
            }}
          >
            SUBMIT OBJECT
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    formData: state.objectDraftState.formData,
    formDraftErrors: state.objectDraftState.formDraftErrors,
    formSubmitAttempted: state.objectDraftState.formSubmitAttempted,
    formSubmitErrors: state.objectDraftState.formSubmitErrors,
    postObjectDraftRequest: state.objectDraftState.objDraftPostRequest,
    putObjectDraftRequest: state.objectDraftState.objDraftPutRequest,
    putObjectSubmitRequest: state.objectDraftState.objDraftPutSubmitRequest,
    selectedImg: state.objectDraftState.selectedImg,
    token: state.authState.token,
    user: state.userState.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    postObjectDraft: (token, payload, userId) =>
      dispatch(postObjectDraft(token, payload, userId)),
    postObjectDraftClear: _ => dispatch(objDraftPostClear()),
    putObjectDraft: (token, id, payload) =>
      dispatch(putObjectDraft(token, id, payload)),
    putObjectDraftClear: _ => dispatch(objDraftPutClear()),
    putObjectSubmit: (token, id, data, userId) =>
      dispatch(putObjectSubmit(token, id, data, userId)),
    putObjectSubmitClear: _ => dispatch(objDraftPutSubmitClear()),
    setSelectedImg: payload => dispatch(objDraftSetSelectedImg(payload)),
    updateDraftErrors: payload => dispatch(objDraftUpdateDraftErrors(payload)),
    updateSubmissionHash: payload =>
      dispatch(objDraftUpdateSubmissionHash(payload)),
    updatePrivacyTOSHash: payload =>
      dispatch(objDraftUpdatePrivacyTOSHash(payload)),
    updateSubmitAttempted: payload =>
      dispatch(objDraftUpdateSubmitAttempted(payload)),
    updateSubmitErrors: payload =>
      dispatch(objDraftUpdateSubmitErrors(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDraftFive);
