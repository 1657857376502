import axios from 'axios';
import { BASE_URL, DEFAULT_TIMEOUT } from '../../config';
import {
  cyclesGetCyclesSortedFailure,
  cyclesGetCyclesSortedRequest,
  cyclesGetCyclesSortedSuccess,
  listsGetCuratorialFailure,
  listsGetCuratorialRequest,
  listsGetCuratorialSuccess,
  listsGetCuratorialPastFailure,
  listsGetCuratorialPastRequest,
  listsGetCuratorialPastSuccess,
  listsGetInterestFailure,
  listsGetInterestRequest,
  listsGetInterestSuccess,
  listsGetInterestPastFailure,
  listsGetInterestPastRequest,
  listsGetInterestPastSuccess,
  listsPutCuratorialAddFailure,
  listsPutCuratorialAddRequest,
  listsPutCuratorialAddSuccess,
  listsPutCuratorialApproveFailure,
  listsPutCuratorialApproveRequest,
  listsPutCuratorialApproveSuccess,
  listsPutInterestAddFailure,
  listsPutInterestAddRequest,
  listsPutInterestAddSuccess,
  listsPutCuratorialRemoveFailure,
  listsPutCuratorialRemoveRequest,
  listsPutCuratorialRemoveSuccess,
  listsPutInterestRemoveFailure,
  listsPutInterestRemoveRequest,
  listsPutInterestRemoveSuccess,
} from '../../actions';
import { INSTITUTION_KINDS } from 'constants/institutions';
import { ONGOING_CYCLE_NAME } from 'constants/cycles';

export const getCurrentCycleLists = (
  token,
  museumId,
  institutionKind = INSTITUTION_KINDS.museum,
) => {
  return dispatch => {
    dispatch(cyclesGetCyclesSortedRequest());
    axios({
      method: 'GET',
      url: `${BASE_URL}/cycles/sorted`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        const findFn =
          institutionKind === INSTITUTION_KINDS.other
            ? cycle => cycle.name === ONGOING_CYCLE_NAME
            : cycle => cycle.name !== ONGOING_CYCLE_NAME;

        const currentCycle = response?.data?.current?.find?.(findFn);

        if (!currentCycle) throw new Error('unable to find current cycle');

        dispatch(getListCuratorial(token, museumId, currentCycle.id));
        dispatch(getListInterest(token, currentCycle.id));
        dispatch(getListCuratorialPast(token, museumId));
        dispatch(getListInterestPast(token));
        dispatch(cyclesGetCyclesSortedSuccess(response.data));
      })
      .catch(error => {
        dispatch(cyclesGetCyclesSortedFailure(error.message));
      });
  };
};

export const getListCuratorial = (token, museumId, cycleId) => {
  return dispatch => {
    dispatch(listsGetCuratorialRequest());
    axios({
      method: 'GET',
      url: `${BASE_URL}/lists/curatorial/museums/${museumId}/cycles/${cycleId}`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(listsGetCuratorialSuccess(response.data));
      })
      .catch(error => {
        dispatch(listsGetCuratorialFailure(error.message));
      });
  };
};

export const getListCuratorialPast = (token, museumId) => {
  return dispatch => {
    dispatch(listsGetCuratorialPastRequest());
    axios({
      method: 'GET',
      url: `${BASE_URL}/lists/curatorial/museums/${museumId}/cycles/past`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        const payload = {};
        response.data.map(v => (payload[v.cycleId] = v));
        dispatch(listsGetCuratorialPastSuccess(payload));
      })
      .catch(error => {
        dispatch(listsGetCuratorialPastFailure(error.message));
      });
  };
};

export const getListInterest = (token, cycleId) => {
  return dispatch => {
    dispatch(listsGetInterestRequest());
    axios({
      method: 'GET',
      url: `${BASE_URL}/lists/interest/users/${token.userId}/cycles/${cycleId}`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(listsGetInterestSuccess(response.data));
      })
      .catch(error => {
        dispatch(listsGetInterestFailure(error.message));
      });
  };
};

export const getListInterestPast = token => {
  return dispatch => {
    dispatch(listsGetInterestPastRequest());
    axios({
      method: 'GET',
      url: `${BASE_URL}/lists/interest/users/${token.userId}/cycles/past`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        const payload = {};
        response.data.map(v => (payload[v.cycleId] = v));
        dispatch(listsGetInterestPastSuccess(payload));
      })
      .catch(error => {
        dispatch(listsGetInterestPastFailure(error.message));
      });
  };
};

export const putListAddObject = (token, listType, listId, objectId) => {
  return dispatch => {
    if (listType === 'curatorial') {
      dispatch(listsPutCuratorialAddRequest());
    } else if (listType === 'interest') {
      dispatch(listsPutInterestAddRequest());
    }
    axios({
      method: 'PUT',
      url: `${BASE_URL}/lists/${listType}/${listId}/objects/${objectId}/add`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        if (listType === 'curatorial') {
          dispatch(listsPutCuratorialAddSuccess(response.data));
        } else if (listType === 'interest') {
          dispatch(listsPutInterestAddSuccess(response.data));
        }
      })
      .catch(error => {
        if (listType === 'curatorial') {
          dispatch(listsPutCuratorialAddFailure(error.message));
        } else if (listType === 'interest') {
          dispatch(listsPutInterestAddFailure(error.message));
        }
      });
  };
};

export const putListCuratorialApproveObject = (token, listId, objectId) => {
  return dispatch => {
    dispatch(listsPutCuratorialApproveRequest());
    axios({
      method: 'PUT',
      url: `${BASE_URL}/lists/curatorial/${listId}/objects/${objectId}/approve`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        dispatch(listsPutCuratorialApproveSuccess(response.data));
      })
      .catch(error => {
        dispatch(listsPutCuratorialApproveFailure(error.message));
      });
  };
};

export const putListRemoveObject = (token, listType, listId, objectId) => {
  return dispatch => {
    if (listType === 'curatorial') {
      dispatch(listsPutCuratorialRemoveRequest());
    } else if (listType === 'interest') {
      dispatch(listsPutInterestRemoveRequest());
    }
    axios({
      method: 'PUT',
      url: `${BASE_URL}/lists/${listType}/${listId}/objects/${objectId}/remove`,
      timeout: DEFAULT_TIMEOUT,
      data: {},
      headers: {
        'content-type': 'application/json',
        'x-api-token': token.id,
      },
    })
      .then(response => {
        if (listType === 'curatorial') {
          dispatch(listsPutCuratorialRemoveSuccess(response.data));
        } else if (listType === 'interest') {
          dispatch(listsPutInterestRemoveSuccess(response.data));
        }
      })
      .catch(error => {
        if (listType === 'curatorial') {
          dispatch(listsPutCuratorialRemoveFailure(error.message));
        } else if (listType === 'interest') {
          dispatch(listsPutInterestRemoveFailure(error.message));
        }
      });
  };
};
