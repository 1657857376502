import {
  USER_CLEAR_TEL,
  USER_CLEAR_USER,
  USER_GET_USER_CLEAR,
  USER_GET_USER_FAILURE,
  USER_GET_USER_REQUEST,
  USER_GET_USER_SUCCESS,
  USER_POST_DONOR_APPLICATION_CLEAR,
  USER_POST_DONOR_APPLICATION_FAILURE,
  USER_POST_DONOR_APPLICATION_REQUEST,
  USER_POST_DONOR_APPLICATION_SUCCESS,
  USER_POST_MUSEUM_APPLICATION_CLEAR,
  USER_POST_MUSEUM_APPLICATION_FAILURE,
  USER_POST_MUSEUM_APPLICATION_REQUEST,
  USER_POST_MUSEUM_APPLICATION_SUCCESS,
  USER_POST_TEL_CLEAR,
  USER_POST_TEL_FAILURE,
  USER_POST_TEL_REQUEST,
  USER_POST_TEL_SUCCESS,
  USER_POST_TEL_RESEND_CLEAR,
  USER_POST_TEL_RESEND_FAILURE,
  USER_POST_TEL_RESEND_REQUEST,
  USER_POST_TEL_RESEND_SUCCESS,
  USER_POST_USER_CLEAR,
  USER_POST_USER_FAILURE,
  USER_POST_USER_REQUEST,
  USER_POST_USER_SUCCESS,
  USER_PUT_EMAIL_CLEAR,
  USER_PUT_EMAIL_FAILURE,
  USER_PUT_EMAIL_REQUEST,
  USER_PUT_EMAIL_SUCCESS,
  USER_PUT_NAME_CLEAR,
  USER_PUT_NAME_FAILURE,
  USER_PUT_NAME_REQUEST,
  USER_PUT_NAME_SUCCESS,
  USER_PUT_TEL_CLEAR,
  USER_PUT_TEL_FAILURE,
  USER_PUT_TEL_REQUEST,
  USER_PUT_TEL_SUCCESS,
  USER_UPDATE_USER,
  USER_RESET_NAME_FORM,
  USER_UPDATE_NAME_ERRORS,
  USER_UPDATE_NAME_FIRST,
  USER_UPDATE_NAME_LAST,
  USER_UPDATE_NEW_EMAIL,
  USER_UPDATE_NEW_EMAIL_ERROR,
} from '../../actions/actionTypes';

const initialUserState = {
  user: null,
  telObj: null,
  nameFormData: {
    nameFirst: '',
    nameLast: '',
  },
  nameFormErrors: {
    formError: false,
    nameFirstError: false,
    nameLastError: false,
  },
  newEmail: '',
  newEmailError: false,
  getUserRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postDonorApplicationRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postMuseumApplicationRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postTelRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postTelResendRequest: {
    loading: false,
    data: null,
    error: '',
  },
  postUserRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putEmailRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putNameRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putTelRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case USER_CLEAR_TEL:
      return {
        ...state,
        telObj: null,
      };

    case USER_CLEAR_USER:
      return {
        ...state,
        user: null,
      };

    case USER_GET_USER_CLEAR:
      return {
        ...state,
        getUserRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_GET_USER_FAILURE:
      return {
        ...state,
        getUserRequest: {
          ...state.getUserRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_GET_USER_REQUEST:
      return {
        ...state,
        getUserRequest: {
          ...state.getUserRequest,
          loading: true,
          error: '',
        },
      };

    case USER_GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        getUserRequest: {
          ...state.getUserRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_POST_DONOR_APPLICATION_CLEAR:
      return {
        ...state,
        postDonorApplicationRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_POST_DONOR_APPLICATION_FAILURE:
      return {
        ...state,
        postDonorApplicationRequest: {
          ...state.postDonorApplicationRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_POST_DONOR_APPLICATION_REQUEST:
      return {
        ...state,
        postDonorApplicationRequest: {
          ...state.postDonorApplicationRequest,
          loading: true,
          error: '',
        },
      };

    case USER_POST_DONOR_APPLICATION_SUCCESS:
      return {
        ...state,
        user: action.payload,
        postDonorApplicationRequest: {
          ...state.postDonorApplicationRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_POST_MUSEUM_APPLICATION_CLEAR:
      return {
        ...state,
        postMuseumApplicationRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_POST_MUSEUM_APPLICATION_FAILURE:
      return {
        ...state,
        postMuseumApplicationRequest: {
          ...state.postMuseumApplicationRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_POST_MUSEUM_APPLICATION_REQUEST:
      return {
        ...state,
        postMuseumApplicationRequest: {
          ...state.postMuseumApplicationRequest,
          loading: true,
          error: '',
        },
      };

    case USER_POST_MUSEUM_APPLICATION_SUCCESS:
      return {
        ...state,
        user: action.payload,
        postMuseumApplicationRequest: {
          ...state.postMuseumApplicationRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_POST_TEL_CLEAR:
      return {
        ...state,
        postTelRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_POST_TEL_FAILURE:
      return {
        ...state,
        postTelRequest: {
          ...state.postTelRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_POST_TEL_REQUEST:
      return {
        ...state,
        postTelRequest: {
          ...state.postTelRequest,
          loading: true,
          error: '',
        },
      };

    case USER_POST_TEL_SUCCESS:
      return {
        ...state,
        telObj: action.payload,
        postTelRequest: {
          ...state.postTelRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_POST_TEL_RESEND_CLEAR:
      return {
        ...state,
        postTelResendRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_POST_TEL_RESEND_FAILURE:
      return {
        ...state,
        postTelResendRequest: {
          ...state.postTelResendRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_POST_TEL_RESEND_REQUEST:
      return {
        ...state,
        postTelResendRequest: {
          ...state.postTelResendRequest,
          loading: true,
          error: '',
        },
      };

    case USER_POST_TEL_RESEND_SUCCESS:
      return {
        ...state,
        telObj: action.payload,
        postTelResendRequest: {
          ...state.postTelResendRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_POST_USER_CLEAR:
      return {
        ...state,
        postUserRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_POST_USER_FAILURE:
      return {
        ...state,
        postUserRequest: {
          ...state.postUserRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_POST_USER_REQUEST:
      return {
        ...state,
        postUserRequest: {
          ...state.postUserRequest,
          loading: true,
          error: '',
        },
      };

    case USER_POST_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        postUserRequest: {
          ...state.postUserRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_PUT_EMAIL_CLEAR:
      return {
        ...state,
        putEmailRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_PUT_EMAIL_FAILURE:
      return {
        ...state,
        putEmailRequest: {
          ...state.putEmailRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_PUT_EMAIL_REQUEST:
      return {
        ...state,
        putEmailRequest: {
          ...state.putEmailRequest,
          loading: true,
          error: '',
        },
      };

    case USER_PUT_EMAIL_SUCCESS:
      return {
        ...state,
        user: action.payload,
        putEmailRequest: {
          ...state.putEmailRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_PUT_NAME_CLEAR:
      return {
        ...state,
        putNameRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_PUT_NAME_FAILURE:
      return {
        ...state,
        putNameRequest: {
          ...state.putNameRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_PUT_NAME_REQUEST:
      return {
        ...state,
        putNameRequest: {
          ...state.putNameRequest,
          loading: true,
          error: '',
        },
      };

    case USER_PUT_NAME_SUCCESS:
      return {
        ...state,
        user: action.payload,
        putNameRequest: {
          ...state.putNameRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_PUT_TEL_CLEAR:
      return {
        ...state,
        putTelRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case USER_PUT_TEL_FAILURE:
      return {
        ...state,
        putTelRequest: {
          ...state.putTelRequest,
          loading: false,
          error: action.payload,
        },
      };

    case USER_PUT_TEL_REQUEST:
      return {
        ...state,
        putTelRequest: {
          ...state.putTelRequest,
          loading: true,
          error: '',
        },
      };

    case USER_PUT_TEL_SUCCESS:
      return {
        ...state,
        putTelRequest: {
          ...state.putTelRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case USER_UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };

    case USER_RESET_NAME_FORM:
      return {
        ...state,
        nameFormData: {
          nameFirst: '',
          nameLast: '',
        },
        nameFormErrors: {
          formError: false,
          nameFirstError: false,
          nameLastError: false,
        },
      };

    case USER_UPDATE_NAME_ERRORS:
      return {
        ...state,
        nameFormErrors: action.payload.nameFormErrors,
      };

    case USER_UPDATE_NAME_FIRST:
      return {
        ...state,
        nameFormData: {
          ...state.nameFormData,
          nameFirst: action.payload.nameFirst,
        },
      };

    case USER_UPDATE_NAME_LAST:
      return {
        ...state,
        nameFormData: {
          ...state.nameFormData,
          nameLast: action.payload.nameLast,
        },
      };

    case USER_UPDATE_NEW_EMAIL:
      return {
        ...state,
        newEmail: action.payload.newEmail,
      };

    case USER_UPDATE_NEW_EMAIL_ERROR:
      return {
        ...state,
        newEmailError: action.payload.newEmailError,
      };

    default:
      return state;
  }
};
