import React from 'react';

export function SelectButton({ text, isActive, onSelect }) {
  return (
    <button
      type="button"
      onClick={onSelect}
      className={`tw-flex tw-w-full tw-items-center tw-justify-start tw-gap-4 tw-rounded-md tw-border tw-p-4 ${
        isActive ? 'tw-border-[#4d85cb]' : 'tw-border-[#c2d1d9]'
      }`}
    >
      <div
        className={`tw-h-4 tw-w-4 tw-rounded-full  tw-bg-white ${
          isActive
            ? 'tw-border-4 tw-border-[#4d85cb]'
            : 'tw-border tw-border-[rgba(128,151,177,0.5)]'
        }`}
      ></div>
      <div className={isActive ? 'tw-text-[#4d85cb]' : 'tw-text-gray-500'}>
        {text}
      </div>
    </button>
  );
}
