export const LISTS_CLEAR_CUR = 'LISTS_CLEAR_CUR';
export const LISTS_CLEAR_CUR_PAST = 'LISTS_CLEAR_CUR_PAST';
export const LISTS_CLEAR_INT = 'LISTS_CLEAR_INT';
export const LISTS_CLEAR_INT_PAST = 'LISTS_CLEAR_INT_PAST';
export const LISTS_GET_CUR_CLEAR = 'LISTS_GET_CUR_CLEAR';
export const LISTS_GET_CUR_FAILURE = 'LISTS_GET_CUR_FAILURE';
export const LISTS_GET_CUR_REQUEST = 'LISTS_GET_CUR_REQUEST';
export const LISTS_GET_CUR_SUCCESS = 'LISTS_GET_CUR_SUCCESS';
export const LISTS_GET_CUR_PAST_CLEAR = 'LISTS_GET_CUR_PAST_CLEAR';
export const LISTS_GET_CUR_PAST_FAILURE = 'LISTS_GET_CUR_PAST_FAILURE';
export const LISTS_GET_CUR_PAST_REQUEST = 'LISTS_GET_CUR_PAST_REQUEST';
export const LISTS_GET_CUR_PAST_SUCCESS = 'LISTS_GET_CUR_PAST_SUCCESS';
export const LISTS_GET_INT_CLEAR = 'LISTS_GET_INT_CLEAR';
export const LISTS_GET_INT_FAILURE = 'LISTS_GET_INT_FAILURE';
export const LISTS_GET_INT_REQUEST = 'LISTS_GET_INT_REQUEST';
export const LISTS_GET_INT_SUCCESS = 'LISTS_GET_INT_SUCCESS';
export const LISTS_GET_INT_PAST_CLEAR = 'LISTS_GET_INT_PAST_CLEAR';
export const LISTS_GET_INT_PAST_FAILURE = 'LISTS_GET_INT_PAST_FAILURE';
export const LISTS_GET_INT_PAST_REQUEST = 'LISTS_GET_INT_PAST_REQUEST';
export const LISTS_GET_INT_PAST_SUCCESS = 'LISTS_GET_INT_PAST_SUCCESS';
export const LISTS_PUT_CUR_ADD_CLEAR = 'LISTS_PUT_CUR_ADD_CLEAR';
export const LISTS_PUT_CUR_ADD_FAILURE = 'LISTS_PUT_CUR_ADD_FAILURE';
export const LISTS_PUT_CUR_ADD_REQUEST = 'LISTS_PUT_CUR_ADD_REQUEST';
export const LISTS_PUT_CUR_ADD_SUCCESS = 'LISTS_PUT_CUR_ADD_SUCCESS';
export const LISTS_PUT_CUR_APPROVE_CLEAR = 'LISTS_PUT_CUR_APPROVE_CLEAR';
export const LISTS_PUT_CUR_APPROVE_FAILURE = 'LISTS_PUT_CUR_APPROVE_FAILURE';
export const LISTS_PUT_CUR_APPROVE_REQUEST = 'LISTS_PUT_CUR_APPROVE_REQUEST';
export const LISTS_PUT_CUR_APPROVE_SUCCESS = 'LISTS_PUT_CUR_APPROVE_SUCCESS';
export const LISTS_PUT_INT_ADD_CLEAR = 'LISTS_PUT_INT_ADD_CLEAR';
export const LISTS_PUT_INT_ADD_FAILURE = 'LISTS_PUT_INT_ADD_FAILURE';
export const LISTS_PUT_INT_ADD_REQUEST = 'LISTS_PUT_INT_ADD_REQUEST';
export const LISTS_PUT_INT_ADD_SUCCESS = 'LISTS_PUT_INT_ADD_SUCCESS';
export const LISTS_PUT_CUR_REMOVE_CLEAR = 'LISTS_PUT_CUR_REMOVE_CLEAR';
export const LISTS_PUT_CUR_REMOVE_FAILURE = 'LISTS_PUT_CUR_REMOVE_FAILURE';
export const LISTS_PUT_CUR_REMOVE_REQUEST = 'LISTS_PUT_CUR_REMOVE_REQUEST';
export const LISTS_PUT_CUR_REMOVE_SUCCESS = 'LISTS_PUT_CUR_REMOVE_SUCCESS';
export const LISTS_PUT_INT_REMOVE_CLEAR = 'LISTS_PUT_INT_REMOVE_CLEAR';
export const LISTS_PUT_INT_REMOVE_FAILURE = 'LISTS_PUT_INT_REMOVE_FAILURE';
export const LISTS_PUT_INT_REMOVE_REQUEST = 'LISTS_PUT_INT_REMOVE_REQUEST';
export const LISTS_PUT_INT_REMOVE_SUCCESS = 'LISTS_PUT_INT_REMOVE_SUCCESS';
export const LISTS_SET_CUR_OBJECTS = 'LISTS_SET_CUR_OBJECTS';
export const LISTS_SET_INT_OBJECTS = 'LISTS_SET_INT_OBJECTS';
export const LISTS_CLEAR_SELECTED_OBJECT = 'LISTS_CLEAR_SELECTED_OBJECT';
export const LISTS_SET_SELECTED_OBJECT = 'LISTS_SET_SELECTED_OBJECT';
export const LISTS_CLEAR_CUR_ACTION = 'LISTS_CLEAR_CUR_ACTION';
export const LISTS_SET_CUR_ACTION = 'LISTS_SET_CUR_ACTION';
