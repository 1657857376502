export const getDateString = dateString => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const dt = date.getDate();

  return `${months[month]} ${dt}, ${year}`;
};
