import {
  DSET_PUT_DONOR_LOCATION_CLEAR,
  DSET_PUT_DONOR_LOCATION_FAILURE,
  DSET_PUT_DONOR_LOCATION_REQUEST,
  DSET_PUT_DONOR_LOCATION_SUCCESS,
  DSET_PUT_DONOR_TYPE_CLEAR,
  DSET_PUT_DONOR_TYPE_FAILURE,
  DSET_PUT_DONOR_TYPE_REQUEST,
  DSET_PUT_DONOR_TYPE_SUCCESS,
  DSET_RESET_TYPE_FORM,
  DSET_UPDATE_TYPE_ERRORS,
  DSET_UPDATE_TYPE,
  DSET_UPDATE_PROFESSION,
  DSET_RESET_LOCATION_FORM,
  DSET_UPDATE_LOCATION_ERRORS,
  DSET_UPDATE_LOCATION_CITY,
  DSET_UPDATE_LOCATION_STATE,
} from '../../actions/actionTypes';

const initialDonorSettingsState = {
  donorTypeFormData: {
    donorType: '',
    profession: '',
  },
  donorTypeFormErrors: {
    formError: false,
    donorTypeFormError: false,
    professionFormError: false,
  },
  locationFormData: {
    locationCity: '',
    locationState: '',
  },
  locationFormErrors: {
    formError: false,
    locationCityError: false,
    locationStateError: false,
  },
  putDonorLocationRequest: {
    loading: false,
    data: null,
    error: '',
  },
  putDonorTypeRequest: {
    loading: false,
    data: null,
    error: '',
  },
};

export const donorSettingsReducer = (
  state = initialDonorSettingsState,
  action,
) => {
  switch (action.type) {
    case DSET_PUT_DONOR_LOCATION_CLEAR:
      return {
        ...state,
        putDonorLocationRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case DSET_PUT_DONOR_LOCATION_FAILURE:
      return {
        ...state,
        putDonorLocationRequest: {
          ...state.putDonorLocationRequest,
          loading: false,
          error: action.payload,
        },
      };

    case DSET_PUT_DONOR_LOCATION_REQUEST:
      return {
        ...state,
        putDonorLocationRequest: {
          ...state.putDonorLocationRequest,
          loading: true,
          error: '',
        },
      };

    case DSET_PUT_DONOR_LOCATION_SUCCESS:
      return {
        ...state,
        putDonorLocationRequest: {
          ...state.putDonorLocationRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case DSET_PUT_DONOR_TYPE_CLEAR:
      return {
        ...state,
        putDonorTypeRequest: {
          loading: false,
          data: null,
          error: '',
        },
      };

    case DSET_PUT_DONOR_TYPE_FAILURE:
      return {
        ...state,
        putDonorTypeRequest: {
          ...state.putDonorTypeRequest,
          loading: false,
          error: action.payload,
        },
      };

    case DSET_PUT_DONOR_TYPE_REQUEST:
      return {
        ...state,
        putDonorTypeRequest: {
          ...state.putDonorTypeRequest,
          loading: true,
          error: '',
        },
      };

    case DSET_PUT_DONOR_TYPE_SUCCESS:
      return {
        ...state,
        putDonorTypeRequest: {
          ...state.putDonorTypeRequest,
          loading: false,
          data: action.payload,
          error: '',
        },
      };

    case DSET_RESET_TYPE_FORM:
      return {
        ...state,
        donorTypeFormData: {
          donorType: '',
          profession: '',
        },
        donorTypeFormErrors: {
          formError: false,
          donorTypeFormError: false,
          professionFormError: false,
        },
      };

    case DSET_UPDATE_TYPE_ERRORS:
      return {
        ...state,
        donorTypeFormErrors: action.payload.donorTypeFormErrors,
      };

    case DSET_UPDATE_TYPE:
      return {
        ...state,
        donorTypeFormData: {
          ...state.donorTypeFormData,
          donorType: action.payload.donorType,
        },
      };

    case DSET_UPDATE_PROFESSION:
      return {
        ...state,
        donorTypeFormData: {
          ...state.donorTypeFormData,
          profession: action.payload.profession,
        },
      };

    case DSET_RESET_LOCATION_FORM:
      return {
        ...state,
        locationFormData: {
          locationCity: '',
          locationState: '',
        },
        locationFormErrors: {
          formError: false,
          locationCityError: false,
          locationStateError: false,
        },
      };

    case DSET_UPDATE_LOCATION_ERRORS:
      return {
        ...state,
        locationFormErrors: action.payload.locationFormErrors,
      };

    case DSET_UPDATE_LOCATION_CITY:
      return {
        ...state,
        locationFormData: {
          ...state.locationFormData,
          locationCity: action.payload.locationCity,
        },
      };

    case DSET_UPDATE_LOCATION_STATE:
      return {
        ...state,
        locationFormData: {
          ...state.locationFormData,
          locationState: action.payload.locationState,
        },
      };

    default:
      return state;
  }
};
