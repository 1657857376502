import React from 'react';
import LogoStacked from '../../images/logo-stacked.png';

function MobileBlock() {
  return (
    <div>
      <div className="d-flex justify-content-center mex-mt-14 mex-mb-40">
        <img
          src={LogoStacked}
          alt="logo-stacked"
          className="gen-logo-stacked mex-mb-30 tw-max-w-[200px] tw-pt-12"
        />
      </div>
      <p className="text-center label-bold px-5">
        Please use a desktop or laptop computer to access Museum Exchange.
      </p>
    </div>
  );
}

export default MobileBlock;
