import {
  CURRENT_OBJECT_SUBMISSION_HASH,
  CURRENT_PRIVACY_POLICY_HASH,
  CURRENT_TOS_HASH,
} from '../../config';

export const validateFormSubmit = payload => {
  const errors = {
    formError: false,
    errorPages: {},
    accountError: false,
    collectingAreaError: false,
    artistNameError: false,
    titleError: false,
    yearError: false,
    classificationError: false,
    mediumError: false,
    heightError: false,
    lengthError: false,
    dimensionsError: false,
    dimensionsErrors: {},
    weightSystemError: false,
    editionError: false,
    conditionError: false,
    provenanceError: false,
    restrictionsError: false,
    locationCityError: false,
    locationStateError: false,
    locationZipError: false,
    primaryImageError: false,
    submissionHashError: false,
    privacyTOSHashError: false,
  };

  if (!payload.account) {
    errors.formError = true;
    errors.accountError = true;
    errors.errorPages.one = true;
  }

  if (!payload.collectingArea) {
    errors.formError = true;
    errors.collectingAreaError = true;
    errors.errorPages.one = true;
  }

  if (!payload.artistName) {
    errors.formError = true;
    errors.artistNameError = true;
    errors.errorPages.one = true;
  }

  if (!payload.title) {
    errors.formError = true;
    errors.titleError = true;
    errors.errorPages.one = true;
  }

  if (!payload.year) {
    errors.formError = true;
    errors.yearError = true;
    errors.errorPages.one = true;
  }

  if (!payload.classification) {
    errors.formError = true;
    errors.classificationError = true;
    errors.errorPages.one = true;
  }

  if (!payload.medium) {
    errors.formError = true;
    errors.mediumError = true;
    errors.errorPages.one = true;
  }

  let foundDimensions = false;

  payload.dimensions.forEach(v => {
    const dErrors = {};
    if (v.key !== 'dimensionsVariable' && v.key !== 'duration') {
      dErrors[v.key] = {};
      if (v.key || v.height || v.width || (v.depth && v.depth !== '0')) {
        let vOk = true;
        if (!v.height || v.height === '0') {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].heightError = true;
          errors.errorPages.one = true;
          vOk = false;
        }

        if (!v.width || v.width === '0') {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].widthError = true;
          errors.errorPages.one = true;
          vOk = false;
        }

        if (!v.system) {
          errors.formError = true;
          errors.dimensionsError = true;
          dErrors[v.key].systemError = true;
          errors.errorPages.one = true;
          vOk = false;
        }

        if (vOk) {
          foundDimensions = true;
        }
      }
      errors.dimensionsErrors = dErrors;
    } else {
      if (v.value && v.value.length) {
        foundDimensions = true;
      }
    }
  });

  if (!foundDimensions) {
    errors.formError = true;
    errors.dimensionsError = true;
    errors.errorPages.one = true;
  }

  if (payload.weight && payload.weight !== '0') {
    if (!payload.weightSystem) {
      errors.formError = true;
      errors.weightSystemError = true;
      errors.errorPages.one = true;
    }
  }

  if (payload.edition && !payload.editionDescription) {
    errors.formError = true;
    errors.editionError = true;
    errors.errorPages.one = true;
  }

  if (!payload.condition) {
    errors.formError = true;
    errors.conditionError = true;
    errors.errorPages.one = true;
  }

  if (!payload.provenance) {
    errors.formError = true;
    errors.provenanceError = true;
    errors.errorPages.two = true;
  }

  if (payload.restrictions && !payload.restrictionsDescription) {
    errors.formError = true;
    errors.restrictionsError = true;
    errors.errorPages.three = true;
  }

  if (!payload.locationCity) {
    errors.formError = true;
    errors.locationCityError = true;
    errors.errorPages.three = true;
  }

  if (!payload.locationState || payload.locationState.length !== 2) {
    errors.formError = true;
    errors.locationStateError = true;
    errors.errorPages.three = true;
  }

  if (!payload.locationZip || payload.locationZip.length > 7) {
    errors.formError = true;
    errors.locationZipError = true;
    errors.errorPages.three = true;
  }

  if (!payload.primaryImage) {
    errors.formError = true;
    errors.primaryImageError = true;
    errors.errorPages.four = true;
  }

  if (payload.submissionHash !== CURRENT_OBJECT_SUBMISSION_HASH) {
    errors.formError = true;
    errors.submissionHashError = true;
  }

  if (
    payload.privacyPolicyHash !== CURRENT_PRIVACY_POLICY_HASH ||
    payload.tosHash !== CURRENT_TOS_HASH
  ) {
    errors.formError = true;
    errors.privacyTOSHashError = true;
  }

  return errors;
};
